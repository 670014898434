import {
  AttachmentRequirement,
  ImageType,
} from '../../api/attachment/attachment-client/api'
import { IAuthenticatedApiModel } from '../../utilities/api'
import { optionType } from './imageDetails/components/imageTypeDropdown/dropdownOptions'

export interface IClaimAttachmentList {
  authentication: IAuthenticatedApiModel
  claim: any
  updateClaim: (field: string, value: any) => void
  sentAttachmentDetails?: Array<IAttachment>
  attachmentDetails?: IAttachment
  claimId: string
  setAttachment: (attachment: IAttachment) => void
  attachmentRequirements?: IAttachmentRequirements
  supportsAttachment: boolean
}

export interface IClaimAttachment {
  authentication: IAuthenticatedApiModel
  attachment?: IAttachment
  attachmentRequirements: IAttachmentRequirements
  claim: any
  claimId: string
  setAttachment: (attachment: IAttachment) => void
  showList: () => void
  supportsAttachment: boolean
}

export interface IAttachment {
  isSentAttachment: boolean
  attachmentNumber?: string
  isNeaNumber?: boolean
  sentDate?: string
  attachmentRecordID?: string
  attachmentId?: number
  narrative?: string
  payorRefId?: string
  attachmentImages?: Array<IAttachmentImage>
}

export interface IAttachmentImage {
  imageId?: number
  imageCaptureMethod?: ImageCaptureMethod
  imageTypeName?: string
  imageTypeId?: number
  imageDateTaken?: Date
  imageOrientationType?: ImageOrientationType
  imageData?: File
  isNewImage: boolean
  isLegacyImage?: boolean
  isDeleted?: boolean
  itemNumber?: number
  imagePath?: string
}

export interface IAttachmentImageS3Save {
  imageIndex: number
  imageName: string
}

export interface IAttachmentRequirements {
  attachmentRequirements?: Array<AttachmentRequirement>
  attachmentImageTypeRequirements?: Array<ImageType>
}

export interface IAttachmentRequirement {
  procedureCode: string
  toothNumber: string
  requirementDescription: string
}

export enum ImageCaptureMethod {
  ScreenCapture = 'Screen-Capture',
  FileUpload = 'File-Upload',
  KeyboardCapture = 'Keyboard-Capture',
  ImageSync = 'Image-Sync',
}

export enum ImageOrientationType {
  None = 'NONE',
  LeftIsRight = 'RIGHT',
  LeftIsLeft = 'LEFT',
}

export enum ImageOperationType {
  MIRROR,
  ROTATE,
  CROP,
  BRIGHTNESS,
  SHARPNESS,
  CONTRAST,
  START_CROP,
  END_CROP,
  RESTORE,
}

export interface IImageOperation {
  imageOperationKey: ImageOperationType
  mirror?: boolean
  rotation?: boolean
  brightness?: number
  contrast?: number
  sharpness?: number
  start_crop?: boolean
  end_crop?: boolean
  restore?: boolean
}

export interface IApplicationConstants {
  attachmentImageTypes?: Array<ImageType>
}

export interface IDialogConfirm {
  dialogTitle: string
  dialogText: string
  dialogDenyText: string
  dialogConfirmText: string
  dialogOnClickYes: (props: [...any]) => any
  toggleDialog: () => void
}

export interface ITooltip {
  title: any
  className: string
  iconClass: string
}

export interface IAttachmentGridItem {
  key: React.Key
  rowNumber: number
  recordId: string
  editable: boolean
  date?: Date
  imageTypeName?: string
  imageTypeId?: string
  showOrientation: boolean
  orientation?: string
  attachmentImagePath: string
}

export interface IAttachmentImageGridCommon {
  editable: boolean
  dataSource: Array<IAttachmentGridItem>
  imageOptions: optionType[]
  otherOptions: optionType[]
  onEvent: (
    attachmentImage: IAttachmentGridItem,
    eventType: ImageGridEventType,
    payload: any,
  ) => void
}

export enum ImageGridEventType {
  DateTakenUpdated = 'DateTakenUpdated',
  ImageTypeUpdated = 'ImageTypeUpdated',
  OrientationUpdated = 'OrientationUpdated',
  EditClicked = 'EditClicked',
  DeleteClicked = 'DeleteClicked',
}

export interface ISentAttachmentImageGrid {
  attachmentImages: Array<IAttachmentImage>
}

export interface IAttachmentNarrative {
  narrative: string
  handleUpdateAttachmentNarrative: (props: string) => void
}

export interface IAttachmentRequirementGrid {
  attachmentRequirements: IAttachmentRequirements
  claimId: string
}

export interface IAttachmentImageDisplay {
  imageData: File
  performOperations: IImageOperation
  imageForCanvas: HTMLImageElement
  imgForCanvasCallback: (currImg: HTMLImageElement) => void
  cropImgCallback: (cropImg: HTMLImageElement) => void
  currentCanvasToBlobCallback: (imgBlob: Blob) => void
}

export interface IAttachmentImageView {
  dialogTitle: string
  attachmentImages: Array<IAttachmentImage>
  toggleImageView: (showImageView: boolean) => void
}

export interface IAttachmentImageViewFull {
  image: File
  toggleImageView: (showImageView: boolean) => void
}

export interface IPrintAttachment {
  attachment: IAttachment
  togglePrintAttachment: (showPrintAttachment: boolean) => void
}

export enum ImageQuality {
  MaxUnity = 1.0,
}

/** attachment management */
const urls: string[] = []
export function getUrlForAttachment(attachmentImage: IAttachmentImage): string {
  return getUrl(attachmentImage.imageData)
}

export function getUrl(blob: any): string {
  let result: string = null

  if (blob) {
    result = URL.createObjectURL(blob)
    urls.push(result)
  }

  return result
}

export function cleanupAttachments() {
  while (urls.length) {
    const url = urls.pop()
    URL.revokeObjectURL(url)
  }
}

export interface ProcedureCodeItem {
  procedureCode: string
  toothNumber: string
}

export interface ProcedureListObj {
  procedureCodeList: ProcedureCodeItem[]
}

export interface ITransformedData extends File {
  imgPath?: string
  imageTypeName?: string
  itemNumber?: number
}

/** end attachment management */
