import { AxiosResponse } from 'axios'

import { ApiTypes } from 'trellis:api/claim/claimApiTypes'
import trellisConfiguration from 'trellis:utilities/config'

import { IAuthenticatedApiModel } from '../../utilities/api'
import {
  BatchActionRequest,
  ClaimsQueueApiFactory,
  Configuration,
} from '../claim/claim-client'
import { AbstractClaimApi, IClaimsApi, SendResult } from '../claim/claimApi'
import {
  BuildQueryRequest,
  ClaimsDeleteRequest,
  ClaimsExportRequest,
  ClaimsIgnoreAttachmentRequirementsRequest,
  ClaimsIgnoreRequest,
  ClaimsPmgApiFactory,
  ClaimsStatusRequest,
} from './pmg-client'

const trellisApi: string = trellisConfiguration.trellis_apiUrl
export class PmgApi extends AbstractClaimApi implements IClaimsApi {
  pmgGroupId: number
  pmgRegionId: number

  constructor(authentication: IAuthenticatedApiModel, apiType: ApiTypes) {
    super(authentication, apiType)
    this.pmgGroupId = authentication.PmgId
    this.pmgRegionId = authentication.PmgRegionId
  }

  public async GetClaims(buildQueryRequest: BuildQueryRequest) {
    return await ClaimsPmgApiFactory(this.configuration).claimsPmgGetClaims(
      this.pmgGroupId,
      this.pmgRegionId,
      buildQueryRequest,
    )
  }

  public async GetFilterDropdowns(buildQueryRequest: BuildQueryRequest) {
    return await ClaimsPmgApiFactory(
      this.configuration,
    ).claimsPmgGetFilterDropdowns(
      this.pmgGroupId,
      this.pmgRegionId,
      buildQueryRequest,
    )
  }

  public async UpdateClaimsStatus(claimsStatusRequest: ClaimsStatusRequest) {
    return await ClaimsPmgApiFactory(
      this.configuration,
    ).claimsPmgUpdateClaimsStatus(
      this.pmgGroupId,
      this.pmgRegionId,
      claimsStatusRequest,
    )
  }

  public async UpdateClaimsIgnoreAttachmentRequirements(
    claimsIgnoreAttachmentRequirementsRequest: ClaimsIgnoreAttachmentRequirementsRequest,
  ) {
    return await ClaimsPmgApiFactory(
      this.configuration,
    ).claimsPmgUpdateClaimsIgnoreAttachmentRequirements(
      this.pmgGroupId,
      this.pmgRegionId,
      claimsIgnoreAttachmentRequirementsRequest,
    )
  }

  public async DeleteClaims(claimsDeleteRequest: ClaimsDeleteRequest) {
    return await ClaimsPmgApiFactory(this.configuration).claimsPmgDeleteClaims(
      this.pmgGroupId,
      this.pmgRegionId,
      claimsDeleteRequest,
    )
  }

  public async ExportClaims(claimsExportRequest: ClaimsExportRequest) {
    return await ClaimsPmgApiFactory(
      this.configuration,
    ).claimsPmgCreateClaimsExport(
      this.pmgGroupId,
      this.pmgRegionId,
      claimsExportRequest,
    )
  }

  public async IgnoreClaims(claimsIgnoreRequest: ClaimsIgnoreRequest) {
    return await ClaimsPmgApiFactory(
      this.configuration,
    ).claimsPmgUpdateClaimsIgnore(
      this.pmgGroupId,
      this.pmgRegionId,
      claimsIgnoreRequest,
    )
  }

  public async ProcessClaims(batchActionRequest: BatchActionRequest) {
    const result = {} as SendResult
    result.response = await ClaimsQueueApiFactory(
      new Configuration({
        basePath: trellisApi + ApiTypes.CLAIM,
        accessToken: this.accessToken,
      }),
    ).claimsQueueProcessClaims(batchActionRequest)
    result.isQueuedSend = true
    return result
  }

  PrintClaims: (payload: any) => Promise<AxiosResponse<any>>
}
