import { Button, Form, Row } from 'antd'
import React from 'react'

import { FormPasswordInput } from '../../_siteWide/form/formPasswordInput'
import {
  validateConfirmPassword,
  validatePassword,
} from '../../../utilities/validators/passwordValidators'
import { useProfileContext } from '../context/profileContext'

export const ChangePassword = () => {
  const {
    password,
    setPassword,
    handleFormClose,
    handleUpdatePassword,
    updating,
  } = useProfileContext()

  const [form] = Form.useForm()
  form.setFieldsValue({ ...password })

  return (
    <Form
      form={form}
      name='ChangePassword'
      className='mb-150'
    >
      <FormPasswordInput
        name='CurrentPassword'
        label='Current Password'
        element={password}
        setElement={setPassword}
        initialValue={password.CurrentPassword}
        validator={validatePassword}
        required
      />
      <a href='/account/forgotpassword'>
        <p className='text-link'>Forgot Password?</p>
      </a>
      <FormPasswordInput
        name='NewPassword'
        label='New Password'
        element={password}
        setElement={setPassword}
        initialValue={password.NewPassword}
        validator={validatePassword}
        required
      />
      <FormPasswordInput
        name='ConfirmPassword'
        label='Confirm Password'
        element={password}
        setElement={setPassword}
        initialValue={password.ConfirmPassword}
        validator={validateConfirmPassword}
        required
      />
      <Row justify='end'>
        <Button onClick={() => handleFormClose()}>Cancel</Button>
        <Button
          className='ml-100'
          type='primary'
          onClick={() => handleUpdatePassword()}
          loading={updating}
        >
          Submit
        </Button>
      </Row>
    </Form>
  )
}
