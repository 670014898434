export const columns = [
  {
    id: 0,
    property: 'Details',
    show: true,
  },
  {
    id: 1,
    property: 'TransmitDate',
    show: true,
  },
  {
    id: 2,
    property: 'PatientName',
    show: true,
  },
  {
    id: 3,
    property: 'SubscriberName',
    show: true,
  },
  {
    id: 4,
    property: 'Carrier',
    show: true,
  },
  {
    id: 5,
    property: 'Status',
    show: true,
  },
  {
    id: 6,
    property: 'StatusDescription',
    show: true,
  },
  {
    id: 7,
    property: 'Remarks',
    show: false,
  },
  {
    id: 8,
    property: 'FeeTotal',
    show: false,
  },
  {
    id: 9,
    property: 'Tin',
    show: false,
  },
  {
    id: 10,
    property: 'Predetermination',
    show: false,
  },
  {
    id: 11,
    property: 'ServiceDate',
    show: false,
  },
  {
    id: 12,
    property: 'LastUpdate',
    show: false,
  },
  {
    id: 13,
    property: 'TreatmentAddress',
    show: false,
  },
  {
    id: 14,
    property: 'TreatmentCity',
    show: false,
  },
  {
    id: 15,
    property: 'BillingAddress',
    show: false,
  },
  {
    id: 16,
    property: 'BillingCity',
    show: false,
  },
  {
    id: 17,
    property: 'NpiGroup',
    show: false,
  },
  {
    id: 18,
    property: 'Install',
    show: false,
  },
  {
    id: 19,
    property: 'TreatingSignature',
    show: false,
  },
  {
    id: 20,
    property: 'CobFlag',
    show: false,
  },
  {
    id: 21,
    property: 'Username',
    show: false,
  },
  {
    id: 22,
    property: 'Employer',
    show: false,
  },
]
