import { spacesToProperty } from '../../utilities/general'
import { validateBirthday } from '../../utilities/validators/birthdayValidators'

export const validateCob = (value: string, formData: any, property: string) => {
  const name = 'CobName'
  const dob = 'CobBirthDate'
  const id = 'CobSsn'
  const hasName = formData.CobName && formData.CobName !== ''
  const hasDob = formData.CobBirthDate && formData.CobBirthDate !== ''
  const hasId = formData.CobSsn && formData.CobSsn !== ''

  formData.errors = formData.errors.filter(
    (e: any) => e.property !== name && e.property !== dob && e.property !== id,
  )

  const validateHelper = (
    hasSecondary: boolean,
    secondary: string,
    hasTernary: boolean = false,
    ternary: string = '',
  ) => {
    const addError = (property: string) => {
      const propertyName = property == id ? 'ID/SSN' : property
      formData.errors = formData.errors.filter(
        (e: any) => e.property !== property,
      )
      formData.errors.push({
        property: property,
        message: `${spacesToProperty(propertyName)} is Required`,
        section: 'patient',
      })
    }

    if (!hasSecondary) addError(secondary)

    if (!hasTernary && ternary !== '') addError(ternary)
  }

  if (hasName) validateHelper(hasId, id)

  if (hasId) validateHelper(hasName, name)

  if (hasDob) {
    validateHelper(hasName, name, hasId, id)
    const birthdayError = validateBirthday(formData.CobBirthDate)

    if (birthdayError) {
      formData.errors = formData.errors.filter((e: any) => e.property !== dob)
      formData.errors.push({
        property: dob,
        message: birthdayError,
        section: 'patient',
      })
    }
  }
}
