import { Resizable, ResizeCallbackData } from 'react-resizable'

import './ResizableColumnHeader.scss'

// See the Ant Design docs for more information on implementing react-resizable in the Ant Design table
// https://4x.ant.design/components/table/#components-table-demo-resizable-column

const ResizableColumnHeader = (
  props: React.HTMLAttributes<any> & {
    onResize: (
      e: React.SyntheticEvent<Element>,
      data: ResizeCallbackData,
    ) => void
    onResizeStop: () => void
    width: number
  },
) => {
  const { onResize, onResizeStop, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className='react-resizable-handle'
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
      }
      onResize={onResize}
      onResizeStop={onResizeStop}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  )
}

export default ResizableColumnHeader
