import { WarningOutlined } from '@ant-design/icons'
import { Memo, observer, useObservable } from '@legendapp/state/react'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { FC, ReactNode, useEffect } from 'react'
import {
  useLocation,
  useNavigate,
  useRouteError,
  useSearchParams,
} from 'react-router-dom'

import { Button } from 'ui'
import { LogError, LogFatal, useInterval } from 'utils'

import { logoutUserAndRedirect } from 'trellis:features/authentication/Login/utils/login-helpers'

interface UnexpectedErrorMessageProps {
  error?: Error
  componentStack?: string
  resetError?: () => void
}

const UnexpectedErrorMessage: FC<UnexpectedErrorMessageProps> = observer(({
  resetError,
  error,
}): ReactNode => {
  // package hooks
  const location = useLocation()
  const ldClient = useLDClient()
  const navigate = useNavigate()
  const routeError: Error = useRouteError() as Error
  const [searchParams] = useSearchParams()
  // local state
  const countdown = useObservable<number>(60)

  const handleCountdown = (): void => {
    if (countdown.get()) countdown.set((prev) => prev - 1)
    else logout()
  }
  useInterval(handleCountdown, 1000)

  useEffect(() => {
    LogFatal(error)
    if (routeError)
      LogError(routeError, 'Unexpected Route Error', {
        'Unexpected Route Error': routeError,
      })
  }, [])

  const logout = () => {
    logoutUserAndRedirect(searchParams, location, ldClient, navigate)

    // this dismisses the error that's sitting in front of the page
    // do any routing prior if needed so they go to the correct place
    if (resetError) resetError()
  }

  return (
    <article className='error-boundary'>
      <h1>
        <WarningOutlined
          className='mr-050'
          style={{ color: 'var(--color-error, #f5222d)' }}
        />
        Something unexpected happened.
      </h1>
      <h4>We are working to get to the root of the problem.</h4>
      <p className='mt-200'>Please contact support if this issue continues.</p>
      <Button
        className='mt-200 mb-200'
        label='Go to login'
        onClick={logout}
        type='primary'
      />
      <p>
        or you will be redirected to login in <Memo>{countdown}</Memo> seconds.
      </p>
    </article>
  )
})

export default UnexpectedErrorMessage
