import { CloseOutlined, DownOutlined } from '@ant-design/icons'
import { Dropdown, MenuProps, Tag } from 'antd'
import { FC } from 'react'

import { spacesToProperty } from 'utils'

import { RowSelectionType } from 'trellis:components/_siteWide/table/SmartTableTypes'

import './BatchActionGridControls.scss'

export interface BatchActionGridControlsProps {
  selections: RowSelectionType[]
  selectedCount: number
  handleClearSelection: (keys: []) => void
}

const BatchActionGridControls: FC<BatchActionGridControlsProps> = ({
  selections,
  selectedCount,
  handleClearSelection,
}) => {
  const items: MenuProps['items'] = selections.map((selection) => {
    return {
      key: selection.key,
      label: spacesToProperty(selection.key),
      onClick: () => selection.onSelect(),
      icon: selection.icon,
    }
  })

  // Adds a dividing line above the 'Delete' option for batch actions
  items.splice(-1, 0, { type: 'divider' })

  return (
    <div
      className='batch-action-controls'
      data-testid='batch-action-controls'
    >
      <Dropdown.Button
        menu={{ items }}
        icon={<DownOutlined />}
        className='batch-action-controls__button'
        placement='bottomLeft'
        trigger={['click']}
        data-testid='batch-action-dropdown-trigger'
      >
        <a>Actions</a>
      </Dropdown.Button>
      {selectedCount && (
        <Tag
          closable
          onClose={() => handleClearSelection([])}
          closeIcon={
            <CloseOutlined
              data-testid='batch-action-deselect-all'
              className='batch-action-controls--close-icon'
            />
          }
          color='var(--color-primary-lime-tint, #E8F3D6)'
          className='batch-action-controls__label'
        >
          <span
            className='batch-action-controls__label-bold'
            data-testid='batch-action-selected-keys'
          >
            {selectedCount}
          </span>
          <span> item(s) selected</span>
        </Tag>
      )}
    </div>
  )
}

export default BatchActionGridControls
