import React, { createContext, useContext, useState } from 'react'

import GlobalState, { LDFlags$ } from 'trellis:state/globalState'

import { ClaimsApiFactory } from '../../../api/claim/claimsApiFactory'
import { Errors } from '../../../constants/errors'
import { DetailFilterNames } from '../../../constants/general'
import { NotifyText } from '../../../constants/notifyText'
import { hasProperties, showMessage } from '../../../utilities/general'
import { useClaimGridContext } from './claimGridContext'
import { observer } from '@legendapp/state/react'

const ClaimControlContext = createContext(null)
const store = window.localStorage

export const ClaimControlContextProvider = observer(({ children }: any) => {
  const userName = GlobalState.UserInfo.userName.get()
  const {
    state,
    setState,
    setLoading,
    initialLoad,
    claimRequest,
    handleTransmitDate,
    setDetailsTab,
    authentication,
  } = useClaimGridContext()

  const flags = LDFlags$.get()

  const [pulse, setPulse] = useState(false)

  const setActiveTab = (tab: string) => {
    if (!initialLoad) removeEraEobFilters()

    const copy = { ...state }
    copy.Key = ++copy.Key
    copy.ActiveTab = tab
    copy.Filters.Queued = tab === 'Unsent'
    delete copy.Filters.Config['Status']
    copy.Filters.CurrentPage = 1
    handleTransmitDate(copy)
    copy.SelectedIds = []
    copy.SelectedGridKeys = []
    setState(copy)
    store.removeItem('grid-filter-select-all')
    store.setItem(
      `${userName}-claim-grid-filters`,
      JSON.stringify(copy.Filters.Config),
    )
  }

  const toggleInstall = () => {
    const copy = { ...state }
    let columns = copy.VisibleColumns

    copy.Key = ++copy.Key
    copy.Install.Isolate = !copy.Install.Isolate
    if (copy.Install.Isolate)
      columns = columns.filter((c: any) => c !== 'Install')
    else if (!columns.includes('Install')) columns.push('Install')
    copy.VisibleColumns = columns

    setState(copy)
  }

  const toggleIgnore = () => {
    const copy = { ...state }
    copy.Key = ++copy.Key
    copy.Filters.Ignored = !state.Filters.Ignored
    setState(copy)
  }

  const updateClaims = () => {
    setState({ ...state, Key: ++state.Key })
  }

  const download = () => {
    setLoading(true)
    ClaimsApiFactory()
      .ExportClaims(getPrintDownloadReq())
      .then(({ data }: any) => {
        setLoading(false)
        window.open(data.CsvLink, '_blank')
      })
      .catch(() => showMessage(Errors.somethingUnexpectedError))
  }

  const print = () => {
    if (state.Total < 2500) {
      setLoading(true)
      ClaimsApiFactory()
        .PrintClaims(getPrintDownloadReq())
        .then(({ data }: any) => {
          setLoading(false)
          window.open(data.PdfLink, '_blank')
        })
    } else {
      showMessage(NotifyText.printClaimsError)
    }
  }

  const getPrintDownloadReq = () => {
    // The Details column is always first if visibile, but on print it is empty, so we remove it before generating the PDF
    const columnsToPrint = [...state.VisibleColumns]
    if (columnsToPrint.findIndex((c) => c === 'Details') === 0)
      columnsToPrint.shift()

    const actionsIndex = columnsToPrint.findIndex((c) => c === 'Actions')
    if (actionsIndex !== -1) columnsToPrint.splice(actionsIndex, 1)

    return {
      ...claimRequest(true),
      Queued: state.ActiveTab === 'Unsent',
      ColumnList: columnsToPrint,
    }
  }

  const searchTable = (value: string) => {
    const copy = { ...state }
    copy.Key = ++copy.Key
    copy.Filters.Search = value
    setState(copy)
  }

  const filterTable = (property: string, value: string) => {
    const copy = { ...state }
    copy.Key = ++copy.Key
    copy.Filters.CurrentPage = 1
    copy.Filters.Config[property] = value
    setState(copy)
  }

  const removeEraEobFilters = () => {
    const copy = { ...state }
    copy.Key = ++copy.Key
    delete copy.Filters.Config[DetailFilterNames.hasEra]
    delete copy.Filters.Config[DetailFilterNames.hasEob]
    hasProperties(copy.Filters.Config)
      ? store.setItem(
          `${userName}-claim-grid-filters`,
          JSON.stringify(copy.Filters.Config),
        )
      : store.removeItem(`${userName}-claim-grid-filters`)

    setState(copy)
    setDetailsTab(flags.activityLog ? 'Activity Log' : 'Status')
  }

  return (
    <ClaimControlContext.Provider
      value={{
        setActiveTab,
        toggleInstall,
        toggleIgnore,
        updateClaims,
        download,
        print,
        searchTable,
        filterTable,
        pulse,
        setPulse,
      }}
    >
      {children}
    </ClaimControlContext.Provider>
  )
})

export const useClaimControlContext = () => {
  const context = useContext(ClaimControlContext)
  if (!context) throw new Error('Context must be used within a Provider')
  return context
}
