import { Col, Row, Spin } from 'antd'
import React, { FC } from 'react'

export const DataItem = ({ label, children }: any) => {
  return (
    <>
      <Row className='data-label'>{label}</Row>
      <Row className='pb-150'>
        <Col span={24}>{children}</Col>
      </Row>
    </>
  )
}

type LoadingProps = {
  size?: 'large' | 'small' | 'default'
}

export const Loading: FC<LoadingProps> = ({ size = 'large' }) => (
  <div className='loading-container'>
    <Spin
      className='loading-spin'
      size={size}
    />
  </div>
)
