import { ObservableObject } from '@legendapp/state'
import { Radio, RadioChangeEvent } from 'antd'
import { FC } from 'react'

import {
  EligibilityResponseStateType,
  StructuredBenefit,
} from '../utilities/eligibilityTyping'

import './networkToggle.scss'
import { observer } from '@legendapp/state/react'

export const IN_NETWORK = 'In Network'
export const OUT_OF_NETWORK = 'Out of Network'
export const PREMIER = 'Premier'

type NetworkToggleProps = {
  state: ObservableObject<EligibilityResponseStateType>
}

const NetworkToggle: FC<NetworkToggleProps> = observer(({ state }) => {
  const structuredView = state?.structuredView?.get()

  const handleNetworkToggle = (e: RadioChangeEvent) => {
    let benefits: StructuredBenefit

    switch (e.target.value) {
      case IN_NETWORK:
        benefits = structuredView?.benefits.find(
          (benefit) =>
            benefit?.network === 'in_network' &&
            (!benefit?.payer_specific_description ||
              benefit?.payer_specific_description === 'PPO'),
        )
        break
      case OUT_OF_NETWORK:
        benefits = structuredView?.benefits.find(
          (benefit) => benefit?.network === 'out_of_network',
        )
        break
      case PREMIER:
        benefits = structuredView?.benefits.find(
          (benefit) =>
            benefit?.network === 'in_network' &&
            benefit?.payer_specific_description === 'PREMIER',
        )
        break
      default:
        break
    }

    state.currentBenefits.set(benefits)
    state.currentNetwork.set(e.target.value)
  }

  return (
    <Radio.Group
      defaultValue={IN_NETWORK}
      size='middle'
      className='structured-view-network-toggle'
      onChange={handleNetworkToggle}
    >
      <Radio.Button value={IN_NETWORK}>In Network</Radio.Button>
      <Radio.Button value={OUT_OF_NETWORK}>Out of Network</Radio.Button>
      {structuredView?.benefits?.length > 2 && (
        <Radio.Button value={PREMIER}>Premier</Radio.Button>
      )}
    </Radio.Group>
  )
})

export default NetworkToggle
