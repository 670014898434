import { Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'

import api from '../../../utilities/api'
import { showMessage } from '../../../utilities/general'

export const PasswordExpiration = () => {
  const [passwordExpiration, setPasswordExpiration] = useState<any>()

  useEffect(() => {
    getPasswordExpiration()
  }, [])

  const getPasswordExpiration = async () => {
    const { data } = await api.getPasswordExpiration()
    setPasswordExpiration(data.expirePassword === 1 ? true : false)
  }

  const updatePasswordExpiration = async (event: any) => {
    const setting = {
      expirePassword: event.target.checked === true ? 1 : 0,
    }
    const { data } = await api.updatePasswordExpiration(setting)

    if (data.message) {
      showMessage(data.message)
    } else {
      let message
      if (event.target.checked)
        message = 'Your password will expire every 90 days.'
      else message = 'Your password will not expire.'
      showMessage(message, 'success')
      setPasswordExpiration(event.target.checked)
    }
  }

  return (
    <Checkbox
      checked={passwordExpiration}
      onChange={updatePasswordExpiration}
    >
      Expire password every 90 days
    </Checkbox>
  )
}
