import { Buffer } from 'buffer'

import { Alert, Form, Spin } from 'antd'
import { InternalNamePath } from 'antd/lib/form/interface'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { FormError, handleValuesChange, StateAbbreviations } from 'utils'

import { LEGAL_BUSINESS_CONSTANTS } from 'trellis:components/MyPractice/myPracticeConstants'
import GlobalState from 'trellis:state/globalState'

import { Errors } from '../../../../constants/errors'
import { NotifyText } from '../../../../constants/notifyText'
import api, { LegalBusinessStatusResponse } from '../../../../utilities/api'
import {
  extractTypeProperties,
  showMessage,
} from '../../../../utilities/general'
import LegalBusinessInformation from './controls/LegalBusinessInformation'
import PrimaryContactInformation from './controls/PrimaryContactInformation'

import './LegalBusinessInfo.scss'
import { observer } from '@legendapp/state/react'

type LegalBusinessContentProps = {
  formErrors: FormError[]
  isSaving: Dispatch<SetStateAction<boolean>>
  setFormErrors: Dispatch<SetStateAction<FormError[]>>
}

export interface LegalBusinessInfoFields {
  address1: string
  address2: string
  city: string
  email: string
  employerIdentificationNumber: number
  firstName: string
  jobPosition: number
  lastName: string
  name: string
  phone: string
  state: StateAbbreviations
  title: string
  type: number
  websiteURL: string
  zip: string
}

const emptyLegalBusinessInfoFields: LegalBusinessInfoFields = {
  address1: '',
  address2: null,
  city: '',
  email: '',
  employerIdentificationNumber: null,
  firstName: '',
  jobPosition: null,
  lastName: '',
  name: '',
  phone: '',
  state: '',
  title: '',
  type: null,
  websiteURL: '',
  zip: '',
}

const LegalBusinessInfo: FC<LegalBusinessContentProps> = observer(({
  formErrors,
  isSaving,
  setFormErrors,
}) => {
  const legalBusinessStatus = GlobalState.LegalBusinessStatus.get()

  const [displayWarning, setDisplayWarning] = useState<boolean>(false)
  const [legalBusinessInfo, setLegalBusinessInfo] =
    useState<LegalBusinessInfoFields>(emptyLegalBusinessInfoFields)
  const [loading, setLoading] = useState<boolean>(true)

  const [legalBusinessInfoForm] = Form.useForm()

  useEffect(() => {
    if (legalBusinessStatus) {
      const failedValidation = legalBusinessStatus?.validationFailed
      const {
        validationFailed,
        verificationStatus,
        verificationStatusDescription,
        ...copy
      } = legalBusinessStatus

      Object.values(copy).forEach((value) => {
        if (failedValidation && value) {
          setDisplayWarning(true)
          return
        }
      })

      if (legalBusinessStatus.jobPosition === 0)
        legalBusinessStatus.jobPosition = null
      if (legalBusinessStatus.type === 0) legalBusinessStatus.type = null

      legalBusinessInfoForm.setFieldsValue({
        ...extractTypeProperties<
          LegalBusinessInfoFields,
          LegalBusinessStatusResponse
        >(legalBusinessInfo, legalBusinessStatus),
      })
    }
    setLoading(false)
  }, [legalBusinessStatus])

  const saveLegalBusinessInfo = async () => {
    isSaving(true)

    const copy = { ...legalBusinessInfo }
    if (copy['address2'] === '') copy['address2'] = null
    if (!copy['websiteURL'])
      copy['websiteURL'] = LEGAL_BUSINESS_CONSTANTS.DEFAULT_WEBSITE

    await api
      .saveLegalBusinessDetails(copy)
      .then(({ data }) => {
        let encodedData: string
        switch (data.statusCode) {
          case 200:
          case 204: {
            encodedData = Buffer.from(JSON.stringify(copy)).toString('base64')
            GlobalState.LegalBusinessStatus.set({ ...copy })
            const statusCode = data.statusCode
            showMessage(
              statusCode === 200
                ? NotifyText.legalInfoUpdateSuccess
                : NotifyText.legalInfoNotRequired,
              'success',
            )
            break
          }
          case 500:
            showMessage(
              `${NotifyText.updateLegalInfoError}: \n${data.message}`,
              'error',
            )
            break
        }
      })
      .catch(() => showMessage(NotifyText.updateLegalInfoError, 'error'))
      .finally(() => isSaving(false))
  }

  const onValuesChange = (
    changedValues: { [key in keyof LegalBusinessInfoFields]: string },
    allValues: LegalBusinessInfoFields,
  ) => {
    handleValuesChange<LegalBusinessInfoFields>(
      allValues,
      changedValues,
      formErrors,
      legalBusinessInfoForm,
      setFormErrors,
      setLegalBusinessInfo,
      legalBusinessInfo,
    )
  }

  const onFinishFailed = (
    errorFields: { name: InternalNamePath; errors: string[] }[],
  ) => {
    showMessage(Errors.formValidationErrors)
    setFormErrors(errorFields)
  }

  return (
    <Spin spinning={loading}>
      {displayWarning && (
        <Alert
          className='legal-content__alert'
          description={LEGAL_BUSINESS_CONSTANTS.ALERT_DESCRIPTION}
          type='error'
          showIcon
        />
      )}
      <Form
        colon={false}
        form={legalBusinessInfoForm}
        name='legalBusinessInfoForm'
        onFinish={saveLegalBusinessInfo}
        onFinishFailed={(info) => onFinishFailed(info.errorFields)}
        onValuesChange={(changedValues, allValues) =>
          onValuesChange(changedValues, allValues)
        }
        requiredMark={false}
      >
        <section className='page-section'>
          <p className='page-section__description'>
            To complete your account setup, you must enter your legal business
            information and click Submit. To learn more about the new
            regulations around text messaging,{' '}
            <a
              href={LEGAL_BUSINESS_CONSTANTS.TCR_URL}
              target='_blank'
              rel='noreferrer'
            >
              click here
            </a>
            .
          </p>
          <p className='page-section__description'>
            Verification Status:{' '}
            {legalBusinessStatus?.verificationStatusDescription}
          </p>
        </section>
        <div className='form-section-row form-section-row--default'>
          <LegalBusinessInformation />
          <PrimaryContactInformation />
        </div>
      </Form>
    </Spin>
  )
})

export default LegalBusinessInfo
