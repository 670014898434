import { FC } from 'react'

import { FormInput, FormSelect } from 'ui'
import { validateAlphaNumeric, validateNPI, validateTIN } from 'utils'

import { TaxonomyCodes } from '../../../../../constants/general'

const AddProviderForm: FC = () => {
  return (
    <>
      <section>
        <h2 className='page-section__title page-section__title--modal'>
          Details
        </h2>
        <div className='form-section-row form-section-row--modal'>
          <FormInput
            label='Last or Billing Name'
            name='providerLastName'
            required
          />
          <FormInput
            label='First Name'
            name='providerFirstName'
            required
          />
        </div>
        <div className='form-section-row form-section-row--modal'>
          <FormInput
            label='Tax ID'
            name='providerTaxId'
            required
            validator={validateTIN}
          />
          <FormInput
            label='License No.'
            name='providerLicenseNumber'
            required
            validator={validateAlphaNumeric}
          />
        </div>
        <div className='form-section-row form-section-row--modal'>
          <FormInput
            label='NPI No.'
            name='providerNPI'
            required
            validator={validateNPI}
          />
          {
            <FormSelect
              label='Taxonomy Code'
              name='providerTaxonomyCode'
              options={TaxonomyCodes}
              required
            />
          }
        </div>
      </section>
    </>
  )
}

export default AddProviderForm
