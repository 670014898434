import { Col, Row } from 'antd'
import React from 'react'

import { validateCreditCard } from '../../../utilities/validators/creditCardValidator'
import { validateExpirationMonth } from '../../../utilities/validators/expirationDateValidator'
import { FormInput } from '../form/formInput'
import { FormSelect } from '../form/formSelect'
import { usePaymentContext } from './context/paymentContext'

export const PaymentControls = () => {
  const { payment, setPayment, getMonthOptions, getYearOptions } =
    usePaymentContext()

  return (
    <>
      <Row>
        <Col span={24}>
          <FormInput
            name='billToName'
            label='Cardholder Name'
            element={payment}
            setElement={setPayment}
            initialValue={payment.billToName}
            section='payment'
            required
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormInput
            name='creditCardNumber'
            label='Card Number (without dashes)'
            element={payment}
            setElement={setPayment}
            initialValue={payment.creditCardNumber}
            section='payment'
            validator={validateCreditCard}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <FormSelect
            name='creditCardType'
            label='Card Type'
            element={payment}
            setElement={setPayment}
            initialValue={payment.creditCardType}
            options={[
              { value: 'AMERICAN_EXPRESS', text: 'American Express' },
              { value: 'VISA', text: 'Visa' },
              { value: 'MASTERCARD', text: 'Mastercard' },
            ]}
            required
          />
        </Col>
        <Col
          span={6}
          className='pl-150'
        >
          <FormSelect
            name='creditCardExpireMonth'
            label='Month'
            element={payment}
            setElement={setPayment}
            initialValue={payment.creditCardExpireMonth}
            options={getMonthOptions()}
            validator={validateExpirationMonth}
            section='payment'
            required
          />
        </Col>
        <Col
          span={6}
          className='pl-150'
        >
          <FormSelect
            name='creditCardExpireYear'
            label='Year'
            element={payment}
            setElement={setPayment}
            initialValue={payment.creditCardExpireYear}
            options={getYearOptions()}
            validator={validateExpirationMonth}
            section='payment'
            required
          />
        </Col>
      </Row>
    </>
  )
}
