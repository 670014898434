import { validNpi } from '../general'

export const validateNpi = (value: string) => {
  const npi: any = validNpi(value)
  let error = null

  if (!npi.format) error = 'Must start with a 1 and be 10 numeric digits'
  if (!npi.valid) error = 'Invalid NPI'

  return error
}
