import { isBefore, isValid } from 'date-fns'

export const validateBirthday = (value: string) => {
  const isValidDate: boolean = isValid(new Date(value))
  const isPastDate: boolean = isBefore(new Date(value), new Date())

  let error: string = null
  if (!isValidDate) error = 'Must be a valid date: MM/DD/YYYY'
  else if (!isPastDate) error = 'DOB must be in the past'

  return error
}
