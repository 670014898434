import { compareDesc } from 'date-fns'

import api from 'trellis:utilities/api'

export type ProviderDropdownOption = {
  data: ProviderFields
  key: number
  value: string
}

export interface ProviderFields {
  isDefault: boolean
  licenseNumber: string
  providerFirstName: string
  providerLastName: string
  providerNPI: string
  serviceType: string
  taxId: string
  taxonomyCode: string
}

export interface Provider extends ProviderFields {
  eligibilityDefaultProviderID: number
  globalCustomerID: number
}

export interface ProviderResponse extends Provider {
  addDate: string
  lastUpdateDate: string
}

export const ProvidersAPI = {
  getProviders: async () => await api.getProviders(),
}

export const sortProviders = (
  providers: ProviderResponse[],
): ProviderResponse[] => {
  const sortedProviders: ProviderResponse[] = (providers = providers.sort(
    (providerA: ProviderResponse, providerB: ProviderResponse) => {
      return compareDesc(
        new Date(providerA.addDate),
        new Date(providerB.addDate),
      )
    },
  ))

  return sortedProviders
}
