import { WarningTwoTone } from '@ant-design/icons'
import { Dispatch, FC, SetStateAction, useEffect } from 'react'

import { Button } from 'ui'

import GlobalState from 'trellis:state/globalState'

import './AlertBanner.scss'
import { observer } from '@legendapp/state/react'

interface AlertBannerProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const AlertBanner: FC<AlertBannerProps> = observer(({
  isOpen,
  setIsOpen,
}): JSX.Element => {
  const legalBusinessStatus = GlobalState.LegalBusinessStatus.get()
  const decodedJWT = GlobalState.DecodedJwt.get()

  useEffect(() => {
    if (localStorage.acknowledged_legal_business_info_alert) return
    if (!decodedJWT?.opera_company_id) return
    if (!legalBusinessStatus) return
    if (legalBusinessStatus.bannerSuppressed == null) return

    // The status values for which we don't want to show the banner
    const successStatuses = [1, 2]
    if (
      legalBusinessStatus?.bannerSuppressed ||
      decodedJWT?.active_services.includes('DENTAL_INTEL') ||
      !decodedJWT?.active_services.includes('TRELLIS_CLAIMS') ||
      successStatuses.includes(legalBusinessStatus?.verificationStatus)
    )
      setIsOpen(false)
    else setIsOpen(true)
  }, [legalBusinessStatus])

  const handleClose = () => {
    localStorage.setItem('acknowledged_legal_business_info_alert', 'true')
    setIsOpen(false)
  }

  return (
    <>
      {!isOpen ? (
        <></>
      ) : (
        <article className='alert-banner alert-banner--warning'>
          <p>
            <span>
              <WarningTwoTone
                className='mr-050'
                twoToneColor='#f0a51f'
              />
            </span>
            Action Required: to complete your account setup, you must enter your
            legal business information
            <a href='/Practice/PracticeInfo'>
              {' '}
              <span className='alert-banner__message-link'>here</span>.
            </a>
          </p>
          <Button
            aria-label='close alert'
            className='alert-banner__close'
            label='X'
            onClick={handleClose}
            type='link'
          />
        </article>
      )}
    </>
  )
})

export default AlertBanner
