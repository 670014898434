import { CloseOutlined } from '@ant-design/icons'
import { useObservable } from '@legendapp/state/react'
import { FC, RefObject } from 'react'

import TextFinderCounts from './components/TextFinderCounts'
import TextFinderInput from './components/TextFinderInput'
import TextFinderNav from './components/TextFinderNav'

import './TextFinder.scss'

export interface TextFinderState {
  currentMatchIndex: number
  matchesTotal: number
  textMatches: HTMLCollectionOf<Element> | null
  textToFind: string
}

export interface TextFinderProps {
  elementRef: RefObject<HTMLElement>
}

export const TextFinder: FC<TextFinderProps> = ({ elementRef }) => {
  const state = useObservable<TextFinderState>({
    currentMatchIndex: 0,
    matchesTotal: 0,
    textMatches: null,
    textToFind: '',
  })

  return (
    <div className='text-finder'>
      <TextFinderInput
        elementRef={elementRef}
        state={state}
      />
      <TextFinderCounts
        currentMatchIndex={state.currentMatchIndex}
        matchesTotal={state.matchesTotal}
      />
      <div className='text-finder__divider'></div>
      <TextFinderNav
        currentMatchIndex={state.currentMatchIndex}
        matchesTotal={state.matchesTotal}
        textMatches={state.textMatches}
      />
      <CloseOutlined
        aria-label='clear search input'
        onClick={() => state.textToFind.set('')}
      />
    </div>
  )
}
