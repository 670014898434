import { InfoCircleFilled } from '@ant-design/icons'
import { Button, Col, Form, Row, Tooltip } from 'antd'
import { format } from 'date-fns'
import React, { FC, useState } from 'react'

import { FormInput } from '../../_siteWide/form/formInput'
import api from '../../../utilities/api'
import { showMessage } from '../../../utilities/general'
import { validateProperty } from '../../../utilities/validators/baseValidator'
import { validateEmail } from '../../../utilities/validators/emailValidator'

import './send-invite.scss'

import { RoleHelper$ } from 'trellis:utilities/roleHelper'

import { useGlobalContext } from '../../../context/GlobalContextProvider'

const SendInvite: FC = () => {
  const { authentication, userInfo } = useGlobalContext()

  const [invite, setInvite] = useState<any>({
    NewUserEmail: null,
    errors: [],
  })
  const [code, setCode] = useState('N/A')
  const [sending, setSending] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [isSso, setIsSso] = useState(false)

  const [form] = Form.useForm()
  form.setFieldsValue({ ...invite })

  const info =
    'Enter an email address to send an invite to a new user on your account.'

  const handleSendInvite = () => {
    setSending(true)
    validateInvite(invite)
    if (invite.isValid) sendInvite()
  }

  const validateInvite = (formData: any) => {
    formData.isValid = true
    formData.errors = []
    validateProperty(validateEmail, formData, 'NewUserEmail', null, true)

    if (formData.errors.length > 0) {
      formData.isValid = false
      setSending(false)
      showMessage(
        'There was a problem sending the invite. Please fix the errors and try again.',
      )
    }

    setInvite({ ...invite, ...formData })
  }

  const sendInvite = () => {
    const inviteDetails = {
      newUserEmail: invite.NewUserEmail,
      customerEmail: userInfo.userName,
      userId: Number(authentication.AuthToken.split('|')[1]),
      appCompanyName: RoleHelper$.isDentalIntel.peek() ? 2 : 0,
    }

    api
      .inviteNewUser(inviteDetails)
      .then(({ data }) => {
        if (data.success) {
          showMessage(
            'An account creation invite was sent to the email you provided. Please have the user follow the steps in the email to set up a new account.',
            'success',
          )
          setCode(data.verificationCode)
          if (
            data.verificationCode == null ||
            data.verificationCode.length == 0
          ) {
            setIsSso(true)
          }
          setShowInfo(true)
        } else {
          showMessage(
            'There was a problem sending the invite. Please try again later',
          )
        }

        setSending(false)
      })
      .catch(() => {
        showMessage(
          'There was a problem sending the invite. Please try again later',
        )
        setSending(false)
      })
  }

  return (
    <>
      <div className='page-section'>
        <Row className='page-section__title add-user'>
          <p>Add a new user</p>
          <Tooltip
            title={info}
            placement='right'
          >
            <InfoCircleFilled className='info-label pl-050' />
          </Tooltip>
        </Row>
        <Form
          form={form}
          name='AddUser'
          className='mb-150'
        >
          <Row
            justify='space-between'
            gutter={13}
          >
            <Col flex='1 0 75%'>
              <FormInput
                name='NewUserEmail'
                label='New User Email'
                element={invite}
                setElement={setInvite}
                initialValue={invite.NewUserEmail}
                validator={validateEmail}
                required
              />
            </Col>
            <Col flex='1 0 0'>
              <Button
                className='mt-050'
                type='primary'
                onClick={handleSendInvite}
                loading={sending}
              >
                Invite
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      {showInfo && (
        <div className='page-section'>
          <Row className='page-section__title'>
            <h4>Pending Invite</h4>
          </Row>
          <Row>
            NOTE: For security, invites will expire after 24 hours.
            {!isSso ? '  Additional requests will cancel pending invites.' : ''}
          </Row>
          <Row>
            Email invite sent at{' '}
            <b className='pl-050'>{format(new Date(), 'MM/dd/yyyy h:mm a')}</b>
          </Row>
          {!isSso && (
            <>
              <Row>
                Customer ID:{' '}
                <b className='pl-050'>{userInfo.globalCustomerID}</b>
              </Row>
              <Row>
                Verification Code: <b className='pl-050'>{code}</b>
              </Row>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default SendInvite
