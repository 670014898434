import * as Sentry from '@sentry/react'
import axios from 'axios'

import { ErrorCodes } from '../constants'

export const SetSentryUser = (
  username: string,
  customerId: number,
  userId: number,
  activeServices?: string[],
) => {
  try {
    Sentry.setUser({
      username: userId?.toString(),
      id: customerId,
    })
    setTag('CustomerUserId', userId)
    setTag('GCID', customerId)

    if (activeServices) {
      for (let i = 0; i < activeServices.length; i++) {
        setTag(activeServices[i], true)
      }
    }
  } catch (error) {
    console.error('Failed to initialize sentry', error)
  }
}

export const setTag = (key: string, value: any) => {
  try {
    //Sentry complains if we put empty values into tags
    if (value && value) Sentry.setTag(key, value)
  } catch (error) {
    console.error('Failed to set sentry tag', error)
  }
}

export const ClearSentryUser = () => {
  try {
    Sentry.setUser(null)
  } catch (error) {
    console.error('Failed to reset sentry', error)
  }
}

const LogException = (
  level: Sentry.SeverityLevel,
  exception: Error,
  message?: string,
  tags?: { [key: string]: any },
) => {
  //TODO: add error filtering here to filter out unneeded axios data
  try {
    Sentry.withScope(function (scope) {
      scope.setLevel(level)
      if (message) scope.setTransactionName(message)
      if (tags) scope.setTags(tags)
      Sentry.captureException(exception)
    })
  } catch (error) {
    console.error('Failed to log to sentry', error)
  }
}

const LogMessage = (
  level: Sentry.SeverityLevel,
  message: string,
  tags?: { [key: string]: any },
) => {
  try {
    Sentry.captureMessage(message, (scope) => {
      if (message) scope.setTransactionName(message)
      if (tags) scope.setTags(tags)
      scope.setLevel(level)
      return scope
    })
  } catch (error) {
    console.error('Failed to log message to sentry', error)
  }
}

/**
 * Log a fatal exception to sentry, DO NOT LOG PHI/PII
 * @param exception error that occured
 * @param message message to be used as the transaction name, keep it generic and put unique values in the tags so they're grouped together
 * @param tags tags to be added to the event
 */
export const LogFatal = (
  exception: Error,
  message?: string,
  tags?: { [key: string]: any },
) => {
  LogException('fatal', exception, message, tags)
}

/**
 * Log an error to sentry, DO NOT LOG PHI/PII
 * @param exception error that occured
 * @param message message to be used as the transaction name, keep it generic and put unique values in the tags so they're grouped together
 * @param tags tags to be added to the event
 */
export const LogError = (
  exception: Error,
  message?: string,
  tags?: { [key: string]: any },
) => {
  LogException('error', exception, message, tags)
}

/**
 * Log an error as a warning to sentry, DO NOT LOG PHI/PII
 * @param exception error that occured
 * @param message message to be used as the transaction name, keep it generic and put unique values in the tags so they're grouped together
 * @param tags tags to be added to the event
 */
export const LogWarning = (
  exception: Error,
  message?: string,
  tags?: { [key: string]: any },
) => {
  LogException('warning', exception, message, tags)
}

/**
 * Log a warning message to sentry, DO NOT LOG PHI/PII
 * @param message message to be used as the transaction name, keep it generic and put unique values in the tags so they're grouped together
 * @param tags tags to be added to the event
 */
export const LogWarningMessage = (
  message: string,
  tags?: { [key: string]: any },
) => {
  LogMessage('warning', message, tags)
}

/**
 * Log a breadcrumb to sentry that will appear in the trace of any errors that appear after, DO NOT LOG PHI/PII
 */
export const LogBreadcrumb = (
  message: string,
  category: string,
  data: any,
  level: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug',
) => {
  try {
    Sentry.addBreadcrumb({
      message: message,
      category: category,
      level: level,
      data: data,
    })
  } catch (error) {
    console.error('Failed to add breadcrumb to sentry', error)
  }
}

const claimAttachmentRegex: RegExp =
  /^https?:\/\/[^/]+\/claim\/\d+\/attachment(?:\?.*)?$/

export const shouldIgnoreError = (exception: unknown): boolean => {
  try {
    if (!exception) return true

    const exceptionType = typeof exception

    if (exceptionType == 'string') {
      const error = exception as string
      const messageLower = error?.toLowerCase()
      if (!messageLower) return false

      if (messageLower.includes('antd') && messageLower.includes('deprecated'))
        return true //antd

      if (messageLower.includes('status code 404')) return true //api gateway error, 99% come from trying to fetch attachments in the old system when they don't exist, they don't come back with a normal code to be filtered

      if (error?.includes(ErrorCodes.auth_refresh_failed)) return true
    } else if (
      axios.isAxiosError(exception) &&
      (exception?.status || exception?.response?.status)
    ) {
      const statusCode = exception?.status || exception?.response?.status

      if (
        statusCode == 404 &&
        claimAttachmentRegex.test(exception?.config?.url)
      )
        return true //Filter out not found on attachments
    } else if (exceptionType) {
      const error = exception as Error
      const messageLower = error?.message?.toLowerCase()

      if (!messageLower) return false

      if (error?.message?.includes(ErrorCodes.auth_refresh_failed)) return true
      else if (
        messageLower.startsWith('[hmr]') ||
        messageLower.includes('while rendering a different component')
      )
        return true //local dev errors
    }
  } catch {
    //do nothing, logging might trigger an infinite loop
  }

  return false
}

/**
 * removes sensitive data from axios errors
 */
export const cleanAxiosError = (error) => {
  try {
    if (error?.config?.data) {
      error.config.data = null
    }

    if (error?.request) {
      error.request = {
        method: error.request?.method,
        requestURL: error.request?.requestURL,
        response: error.request?.response,
        status: error.request?.status,
      }
    }
  } catch (e) {
    LogWarning(e, 'Failed to clean axios error')
  }

  return error
}

export const getProcessedSentryEvent = (
  event: Sentry.Event,
  hint: Sentry.EventHint,
) => {
  const error = hint.originalException || hint.syntheticException
  if (shouldIgnoreError(error)) {
    // returning `null` will drop the event
    return null
  }

  if (error && axios.isAxiosError(error)) {
    try {
      cleanAxiosError(event?.contexts?.AxiosError)
      const path = getGenericUrlPath(error?.config?.url)

      const status = getAxiosResponseCode(error)

      if (path && status) {
        if (event.tags) {
          event.tags.ApiPath = path
          if (error?.response?.statusText)
            event.tags.statusText = error?.response?.statusText
          event.tags.status = status
        }

        //try to break up the events by the url path and error code so they don't all end up under a single AxiosError item
        event.fingerprint = ['{{ default }}', path, status.toString()]

        //give it a transaction name if it wasn't specified
        if (!event.transaction) {
          event.transaction = `Api returned ${status} when calling ${path}`
        }
      }
    } catch {
      //do nothing, let it use the default fingerprint
    }
  }

  return event
}

export const addSentryEventProcessor = () => {
  Sentry.addGlobalEventProcessor((event, hint) => {
    return getProcessedSentryEvent(event, hint)
  })
}

export const getAxiosResponseCode = (e: unknown) => {
  if (e && axios.isAxiosError(e)) {
    return e.status || e.response?.status
  }

  return null
}

/**
 * Returns true if the error is axios and the status is in the provided array of safe codes
 * @param e
 * @param safeResponseCodes
 * @returns
 */
export const isSafeApiError = (
  e: Error,
  safeResponseCodes: number[],
): boolean => {
  const responseCode = getAxiosResponseCode(e)
  return (
    (responseCode &&
      safeResponseCodes?.length > 0 &&
      safeResponseCodes.includes(responseCode)) == true
  )
}

/**
 * Gets a generic path for the given url to be used in log grouping, any sections with numbers are converted to {id}
 */
export const getGenericUrlPath = (url: string): string => {
  let path = null

  try {
    if (url) {
      const guidPattern =
        /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/

      path = new URL(url)?.pathname
      if (!path || path == '/') return null

      //replace any sections with numbers with {id}
      path = path.replace(guidPattern, '{guid}')
      path = path.replace(/\/[-a-zA-Z]*\d+[a-zA-Z\d]*(\b|$)/g, '/{id}$1')
    }
  } catch {
    //do nothing, logging might trigger an infinite loop
  }

  return path
}
