import React from 'react'
import ReactDOM from 'react-dom'

import '../attachment.scss'

import { drawDOM, exportPDF } from '@progress/kendo-drawing'
import { renderToString } from 'react-dom/server'

import { IAttachment, ImageOrientationType } from '../attachment.interface'

export const AttachmentPrint = function (
  attachment: IAttachment,
  claimDetails: any,
) {
  const element = document.createElement('div')
  element.setAttribute('id', 'print-attachment')
  const staticElement = renderToString(generatePdf(attachment, claimDetails))
  element.innerHTML = staticElement
  const domElement = ReactDOM.findDOMNode(element)
  document.body.appendChild(domElement)

  drawDOM(element, { paperSize: 'A4' })
    .then((group) => {
      return exportPDF(group)
    })
    .then((dataUri) => {
      document.querySelector('#print-attachment').remove()
      const data = dataUri.split(';base64,')[1]
      const byteString = atob(data)
      let n = byteString.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = byteString.charCodeAt(n)
      }

      const blob = new Blob([u8arr], { type: 'application/pdf' })
      const link = window.URL.createObjectURL(blob)
      window.open(link)
    })
}

const getDate = (existingDate: Date): string => {
  if (!existingDate) {
    return null
  }
  return new Date(existingDate).toLocaleDateString()
}

const datesOfService = (claimDetails: any): string => {
  let dosMin = new Date()
  let dosMax = new Date()

  if (claimDetails.LineItems != null && claimDetails.LineItems.length > 0) {
    claimDetails.LineItems.forEach((e: any) => {
      const procDate = new Date(e.ProcedureDate)

      if (dosMin == null || procDate < dosMin) {
        dosMin = procDate
      }

      if (dosMax == null || procDate > dosMax) {
        dosMax = procDate
      }
    })
  }

  return dosMin.toLocaleDateString() + '-' + dosMax.toLocaleDateString()
}

const generatePdf = function (attachment: IAttachment, claimDetails: any) {
  return (
    <div className='print-attachment-container'>
      <table>
        <thead>
          <tr>
            <th
              colSpan={4}
              className='print-bold'
            >
              <b>Attachment Details</b>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='print-bold'>
              {attachment.attachmentNumber.slice(0, 4)}
            </td>
            <td className='print-normal'>
              {attachment.attachmentNumber.slice(4)}
            </td>
            <td className='print-bold'>Date Sent</td>
            <td className='print-normal'>
              {getDate(claimDetails.TransmitDate)}
            </td>
          </tr>
          <tr>
            <td className='print-bold'>Patient Name</td>
            <td className='print-normal'>{claimDetails.PatientName}</td>
            <td className='print-bold'>DOB</td>
            <td className='print-normal'>
              {claimDetails.PatientBirthDate ??
                claimDetails.SubscriberBirthDate}
            </td>
          </tr>
          <tr>
            <td className='print-bold'>Sending Provider</td>
            <td className='print-normal'>{claimDetails.TreatingName}</td>
            <td className='print-bold'>Date Of Service</td>
            <td className='print-normal'>{datesOfService(claimDetails)}</td>
          </tr>
          <tr>
            <td className='print-bold'>Member ID</td>
            <td className='print-normal'>{claimDetails.SubscriberSsn}</td>
            <td className='print-bold'>Health Plan</td>
            <td className='print-normal'>{claimDetails.Carrier}</td>
          </tr>
        </tbody>
      </table>

      {attachment.narrative && (
        <table>
          <thead>
            <tr>
              <th
                colSpan={4}
                className='print-bold'
              >
                Narrative
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className='print-normal'
                colSpan={4}
              >
                {attachment.narrative}
              </td>
            </tr>
          </tbody>
        </table>
      )}

      {attachment.attachmentImages.map((attachmentImage) => (
        <table key={attachmentImage.imageId}>
          <tbody>
            <tr>
              <td
                style={{ textAlign: 'center' }}
                colSpan={4}
              >
                <div className='print-image-container'>
                  <img src={URL.createObjectURL(attachmentImage.imageData)} />
                  {attachmentImage.imageOrientationType !==
                    ImageOrientationType.None && (
                    <span className={'image-orientation'}>
                      {attachmentImage.imageOrientationType ==
                      ImageOrientationType.LeftIsLeft
                        ? 'L'
                        : 'R'}
                    </span>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td className='print-bold'>Date Taken</td>
              <td className='print-normal'>
                {getDate(attachmentImage.imageDateTaken)}
              </td>
              <td className='print-bold'>Image Type</td>
              <td className='print-normal'>{attachmentImage.imageTypeName}</td>
            </tr>
          </tbody>
        </table>
      ))}
    </div>
  )
}
