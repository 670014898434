import { observer } from '@legendapp/state/react'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConfigProvider } from 'antd'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

// import reportWebVitals from 'reportWebVitals';
import { trellisRoutes } from 'trellis:routes/routes'

import 'trellis:theme/_dental-intel-theme.scss'
import 'trellis:theme/_rpractice-theme.scss'
import 'trellis:theme/_trellis-theme.scss'

// TODO: return and finish reportWebVitals for better insight
// reportWebVitals(console.log);

const queryClient = new QueryClient()

const App = observer(() => {
  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  const router = sentryCreateBrowserRouter(trellisRoutes)

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Roboto',
          },
        }}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
    </QueryClientProvider>
  )
})

export default App
