import { Col, Row } from 'antd'
import { FC } from 'react'

import { FormInput, FormMaskInput } from 'ui'
import { validateEmail, validatePhone } from 'utils'

const ContactInfo: FC = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <FormInput
            label='Practice Name'
            name='officeName'
            required
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormInput
            label='Contact Name'
            name='contactName'
            required
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <FormInput
            label='Email'
            name='customerEmail'
            required
            validator={validateEmail}
          />
        </Col>
        <Col
          span={12}
          className='pl-150'
        >
          <FormMaskInput
            format='(###)###-####'
            label='Phone'
            mask='_'
            name='customerPhone'
            required
            validator={validatePhone}
          />
        </Col>
      </Row>
    </>
  )
}

export default ContactInfo
