import { Col, RadioChangeEvent, Row } from 'antd'
import React from 'react'

import { FormRadioInput } from 'trellis:components/_siteWide/form/formRadioInput'

import { AddressControls } from '../../../../_siteWide/address/addressControls'
import { FormInput } from '../../../../_siteWide/form/formInput'
import { FormMaskInput } from '../../../../_siteWide/form/formMaskInput'
import { FormSelect } from '../../../../_siteWide/form/formSelect'
import { Genders, Relationships } from '../../../../../constants/general'
import { validateBirthday } from '../../../../../utilities/validators/birthdayValidators'
import { useClaimDetailContext } from '../../../context/claimDetailContext'

export const PatientControls = () => {
  const { claim, setClaim } = useClaimDetailContext()

  const handleRelationshipToPolicyholder = (event: RadioChangeEvent) => {
    const claimCopy = { ...claim }
    claimCopy.RELATIONSHIP = event.target.value
    setClaim(claimCopy)
  }

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <FormInput
            data-testid='PatientName'
            name='PatientName'
            label='Last, First'
            element={claim}
            setElement={setClaim}
            initialValue={claim.PatientName}
            section='patient'
            required
          />
        </Col>
        <Col span={6}>
          <FormMaskInput
            element={claim}
            format='##/##/####'
            initialValue={claim.PatientBirthDate}
            label='DOB'
            mask='_'
            name='PatientBirthDate'
            required
            section='patient'
            setElement={setClaim}
            validator={validateBirthday}
          />
        </Col>
        <Col span={6}>
          <FormSelect
            name='PAT_SEX'
            label='Gender'
            element={claim}
            setElement={setClaim}
            initialValue={claim.PAT_SEX}
            options={Genders}
            section='patient'
          />
        </Col>
      </Row>
      <AddressControls
        prefix='Patient'
        state={claim}
        setState={setClaim}
        section='patient'
      />
      <div className='sub-section mb-200'>
        <div className='sub-section-title mb-050'>
          Relationship to Policyholder/Subscriber
        </div>
        <FormRadioInput
          name='PatientRelationship'
          onChange={handleRelationshipToPolicyholder}
          options={Relationships}
          initialValue={claim.RELATIONSHIP || 'SELF'}
          element={claim}
          setElement={setClaim}
        />
      </div>
    </>
  )
}
