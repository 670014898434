import { observer, Show, useObservable } from '@legendapp/state/react'
import { Modal, Tooltip } from 'antd'
import { FC } from 'react'

import { Button } from 'ui'
import { LogError } from 'utils'

import { PracticeInfo } from 'trellis:api/practice/practice-client'
import { UpdatePmsCustomerToken } from 'trellis:api/practice/practiceApi'
import GlobalState from 'trellis:state/globalState'

import styles from './_pmsCustomerToken.module.scss'
import {
  GenerateTokenButtonProps,
  PMSCustomerTokenState,
} from './PmsCustomerTokenTypes'

import CheckCircle from 'trellis:assets/check_circle.svg?react'
import CopyToken from 'trellis:assets/copy.svg?react'
import HideToken from 'trellis:assets/hide.svg?react'
import ShowToken from 'trellis:assets/show.svg?react'

type PMSCustomerTokenProps = {
  practiceInfo: PracticeInfo
}

export const PMSCustomerToken: FC<PMSCustomerTokenProps> = observer(
  ({ practiceInfo }) => {
    const state$ = useObservable<PMSCustomerTokenState>({
      loading: false,
      showConfirmationModal: false,
      showCopySuccess: false,
      tokenIsVisible: false,
    })

    const copyToken = (token: string) => {
      state$.showCopySuccess.set(true)
      navigator.clipboard.writeText(token)
      setTimeout(() => {
        state$.showCopySuccess.set(false)
      }, 1500)
    }

    const getToken = async () => {
      state$.loading.set(true)

      try {
        const response = await UpdatePmsCustomerToken()
        if (response.data) {
          GlobalState.PracticeInfo.pmsCustomerToken.set(
            response.data?.pmsCustomerToken,
          )
        }
      } catch (error) {
        LogError(error, 'Failed to generate new token.')
      } finally {
        state$.showConfirmationModal.set(false)
        state$.tokenIsVisible.set(true)
        state$.loading.set(false)
      }
    }

    const handleGenerateToken = async () => {
      if (practiceInfo?.pmsCustomerToken) state$.showConfirmationModal.set(true)
      else await getToken()
    }

    const ModalContent = (
      <>
        <p className={styles['pms-token__modal-warning']}>
          Generating a new token will void your current token.
        </p>
        <p>Are you sure you want to generate a new token?</p>
      </>
    )

    const ContentCopied = (
      <div className={styles['pms-token__alert']}>
        <CheckCircle />
        <p>Content copied</p>
      </div>
    )

    return (
      <>
        <Modal
          okText='Generate New Token'
          onCancel={() => state$.showConfirmationModal.set(false)}
          open={state$.showConfirmationModal.get()}
          title='Generate New Token'
          footer={[
            <Button
              key='cancel'
              onClick={() => state$.showConfirmationModal.set(false)}
            >
              Cancel
            </Button>,
            <GenerateTokenButton
              key='ok'
              hasToken={practiceInfo?.pmsCustomerToken ? true : false}
              handleClick={getToken}
              isLoading={state$.loading.get()}
            />,
          ]}
        >
          {ModalContent}
        </Modal>
        <div className={styles['pms-token']}>
          <p className={styles['pms-token__header']}>PMS Customer Token</p>
          <p className={styles['pms-token__info']}>
            Please provide this token to your participating Practice Management
            System vendor for claim upload to Vyne Trellis.
          </p>
          <Show
            if={practiceInfo?.pmsCustomerToken}
            else={() => (
              <GenerateTokenButton
                hasToken={practiceInfo?.pmsCustomerToken ? true : false}
                handleClick={handleGenerateToken}
                isLoading={
                  !state$.showConfirmationModal.get()
                    ? state$.loading.get()
                    : false
                }
              />
            )}
          >
            {() => (
              <div className={styles['pms-token__container']}>
                <div className={styles['pms-token__controls']}>
                  <div className={styles['pms-token__controls--token']}>
                    <span
                      className={styles['pms-token__controls--token-label']}
                    >
                      Token:
                    </span>
                    <span
                      className={styles['pms-token__controls--token-text']}
                      data-testid='token-text'
                    >
                      {` ${
                        state$.tokenIsVisible.get()
                          ? practiceInfo?.pmsCustomerToken
                          : '***********************************'
                      }`}
                    </span>
                  </div>
                  <Button
                    label={state$.tokenIsVisible.get() ? 'Hide' : 'Show'}
                    type='text'
                    icon={
                      state$.tokenIsVisible.get() ? (
                        <HideToken />
                      ) : (
                        <ShowToken />
                      )
                    }
                    className={styles['pms-token__controls--button']}
                    onClick={() =>
                      state$.tokenIsVisible.set(!state$.tokenIsVisible.peek())
                    }
                    data-testid='show-hide-button'
                  />
                  <Tooltip
                    color='var(--white-000, #fff)'
                    showArrow={false}
                    trigger={'click'}
                    title={ContentCopied}
                    open={state$.showCopySuccess.get()}
                    overlayInnerStyle={{
                      border: '1px solid var(--color-primary)',
                      borderLeft: '5px solid var(--color-primary)',
                      borderRadius: '5px',
                      boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    <Button
                      label='Copy'
                      type='text'
                      icon={<CopyToken />}
                      className={styles['pms-token__controls--button']}
                      onClick={() => copyToken(practiceInfo?.pmsCustomerToken)}
                      data-testid='copy-button'
                    />
                  </Tooltip>
                </div>
                <GenerateTokenButton
                  hasToken={practiceInfo?.pmsCustomerToken ? true : false}
                  handleClick={handleGenerateToken}
                  isLoading={
                    !state$.showConfirmationModal.get()
                      ? state$.loading.get()
                      : false
                  }
                />
              </div>
            )}
          </Show>
        </div>
      </>
    )
  },
)

const GenerateTokenButton: FC<GenerateTokenButtonProps> = ({
  hasToken,
  handleClick,
  isLoading,
}) => {
  return (
    <Button
      label={hasToken ? 'Generate New Token' : 'Generate Token'}
      type='primary'
      onClick={handleClick}
      loading={isLoading}
      data-testid='generate-token-button'
    />
  )
}
