export const validateEmail = (value: string) => {
  const isValid =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value,
    )
  return value && !isValid && `e.g. abc@test.com`
}

export const validateConfirmEmail = (value: string, formData: any) => {
  if (!value) return

  let error: string = null
  if (value !== formData.Email) error = 'Emails do not match'

  return error
}
