import { AxiosResponse } from 'axios'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { LogError, LogWarning } from 'utils'

import GlobalState from 'trellis:state/globalState'

import {
  Invoice,
  RpReceiptContent,
} from '../../../../api/billing/billing-client'
import { GetInvoices } from '../../../../api/billing/billingApi'
import { showMessage } from '../../../../utilities/general'
import {
  addItemToLocalStorage,
  addItemToTempLocalStorage,
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
  storageVariables,
} from '../../../../utilities/localStorageHelpers'
import { observer } from '@legendapp/state/react'

export type InvoiceContextType = {
  selectedInvoice: Invoice
  selectedTin: string
  invoices: Invoice[]
  invoicesLoading: boolean
  setInvoicesLoading: Dispatch<SetStateAction<boolean>>
  updateSelectedTin: (tin: string) => void
  saveReceipt: (receiptIdentifier: string, receipt: RpReceiptContent) => void
  getReceipt: (receiptIdentifier: string) => RpReceiptContent
  refreshInvoices: () => void
}

export const InvoiceContext = createContext<InvoiceContextType>(null)

export const InvoiceContextProvider = observer(({ children }: any) => {
  const authentication = GlobalState.Auth.get()
  const [selectedTin, setSelectedTin] = useState<string>(null)
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice>()
  const [invoices, setInvoices] = useState<Invoice[]>()
  const [invoicesLoading, setInvoicesLoading] = useState(true)

  useEffect(() => {
    if (authentication?.AccessToken) {
      loadInvoiceData()
    }
  }, [authentication?.AccessToken])

  useEffect(() => {
    if (invoices && !selectedTin) {
      const loadedSelectedTin = getItemFromLocalStorage<string>('selectedTin')
      updateSelectedTin(loadedSelectedTin)
    }
  }, [invoices])

  const loadInvoiceData = () => {
    const loadedInvoices = getItemFromLocalStorage<Invoice[]>(
      storageVariables.Invoices,
    )
    if (loadedInvoices && loadedInvoices.length) {
      updateInvoices(loadedInvoices)
      const tin =
        getItemFromLocalStorage<string>(storageVariables.SelectedTin) ??
        loadedInvoices[0].Tin
      updateSelectedTin(tin)
      setInvoicesLoading(false)
    } else {
      getInvoices()
    }
  }

  const refreshInvoices = () => {
    removeItemFromLocalStorage(storageVariables.Invoices)
    getInvoices()
  }

  const getInvoices = () => {
    if (!GlobalState.ActiveServices.TRELLIS_CLAIMS.get()) {
      setInvoicesLoading(true)
      GetInvoices(authentication)
        .then((response: AxiosResponse<Invoice[]>) => {
          if (response?.data && response.data.length) {
            updateInvoices(response.data)
            const tin =
              selectedTin ??
              getItemFromLocalStorage<string>('selectedTin') ??
              response.data[0].Tin
            updateSelectedTin(tin)
            return
          }
          LogWarning(new Error('Api returned no invoices'))
          showMessage(
            'There was an error getting the invoice information. Please try again later.',
          )
        })
        .catch((e) => {
          LogError(e, 'Failed to get invoices', { Method: 'GetInvoices' })
          showMessage(
            'There was an error getting the invoice information. Please try again later.',
          )
        })
        .finally(() => {
          setInvoicesLoading(false)
        })
    }
  }

  const saveReceipt = (
    receiptIdentifier: string,
    receipt: RpReceiptContent,
  ) => {
    let receipts = getItemFromLocalStorage<RpReceiptContent[]>(
      storageVariables.RpReceipts,
    )
    if (!receipts) {
      receipts = []
    }

    //The "identifier" is the invoice entity id so THERE CAN BE ONLY ONE
    receipts = receipts.filter(
      (x) => x.ReceiptIdentifier != receipt.ReceiptIdentifier,
    )

    receipts.push(receipt)
    addItemToLocalStorage(storageVariables.RpReceipts, receipts)
  }

  const getReceipt = (receiptIdentifier: string): RpReceiptContent => {
    const receipts = getItemFromLocalStorage<RpReceiptContent[]>(
      storageVariables.RpReceipts,
    )
    if (receipts?.length) {
      return receipts.find((x) => x.ReceiptIdentifier === receiptIdentifier)
    }
    return null
  }

  const InvoiceMap = useRef<Map<string, Invoice>>(null)
  const updateInvoices = (newInvoices: Invoice[]): void => {
    InvoiceMap.current = new Map(newInvoices.map((i) => [i.Tin, i]))
    addItemToTempLocalStorage<Invoice[]>(storageVariables.Invoices, newInvoices)
    setInvoices(newInvoices)
  }

  const updateSelectedTin = (newTin: string): void => {
    if (InvoiceMap?.current && InvoiceMap.current.get(newTin)) {
      addItemToTempLocalStorage<string>(storageVariables.SelectedTin, newTin)
      setSelectedTin(newTin)
      setSelectedInvoice(InvoiceMap.current.get(newTin))
    }
  }

  return (
    <InvoiceContext.Provider
      value={{
        // state
        selectedInvoice,
        selectedTin,
        invoices,
        invoicesLoading,
        setInvoicesLoading,
        updateSelectedTin,
        saveReceipt,
        getReceipt,
        refreshInvoices,
      }}
    >
      {children}
    </InvoiceContext.Provider>
  )
})

export const useInvoiceContext = () => {
  const context = useContext(InvoiceContext)
  if (context === undefined) {
    throw new Error('Context must be used within a Provider')
  }
  return context
}
