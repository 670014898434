import { Observable, ObservableObject } from '@legendapp/state'

import { SummaryCardsContainerItem } from 'ui'

import { ClaimGridState } from '../claimTypes'
import { QueuedStatusSummary } from '../context/claimGridContext'

import CheckCircleIcon from 'trellis:assets/check-circle-icon.svg?react'
import ExclamationCircleIcon from 'trellis:assets/exclamation-circle-icon.svg?react'
import InboxIcon from 'trellis:assets/inbox-icon.svg?react'

export type QueuedSummaryCardItems = {
  failed: number
  hold: number
  needsAttention: number
  ready: number
  total: number
  warning: number
}

export const handleActiveSummaryCard = (
  state: any,
  activeSummaryCard: Observable<string | number>,
) => {
  switch (state.Filters.Config.Status) {
    case '':
    case null:
    case undefined:
      activeSummaryCard.set('default')
      break
    case 'Failed Validation,Pending,Other Coverage':
      activeSummaryCard.set('attention')
      break
    case 'In Queue':
      activeSummaryCard.set('ready')
      break
    default:
      activeSummaryCard.set('')
      break
  }
}

export const handleSummaryFilter = (
  filter: string | number,
  activeSummaryCard: Observable<string | number>,
  state$: ObservableObject<ClaimGridState>,
) => {
  const copy = { ...state$.peek() }
  copy.ActiveTab = 'Unsent'
  copy.Key = ++copy.Key
  copy.Filters.CurrentPage = 1

  if (filter === 'reset' || activeSummaryCard.peek() === filter) {
    delete copy.Filters.Config['Status']
  } else if (filter === 'attention') {
    copy.Filters.Config['Status'] = 'Failed Validation,Pending,Other Coverage'
  } else if (filter === 'ready') {
    copy.Filters.Config['Status'] = 'In Queue'
  }

  state$.set(copy)
}

export const getQueuedSummaryCardItems = (
  queuedStatusSummary: QueuedStatusSummary,
  activeSummaryCard: Observable<string | number>,
  state$: ObservableObject<ClaimGridState>,
): Promise<SummaryCardsContainerItem[]> => {
  const summary: QueuedSummaryCardItems = {
    failed: 0,
    hold: 0,
    needsAttention: 0,
    ready: 0,
    total: queuedStatusSummary.total,
    warning: 0,
  }

  queuedStatusSummary?.summary?.forEach((item) => {
    switch (item.Status) {
      case 'Hold':
        summary.hold = item.Count
        break
      case 'Failed Validation':
        summary.failed = item.Count
        summary.needsAttention = summary.needsAttention + item.Count
        break
      case 'Other Coverage':
      case 'Pending':
      case 'Rejected':
        summary.needsAttention = summary.needsAttention + item.Count
        break
      case 'Accepted':
      case 'In Process':
      case 'In Queue':
        summary.ready = item.Count
        break
    }
  })

  const summaryCardsData: SummaryCardsContainerItem[] = [
    {
      action: () => handleSummaryFilter('reset', activeSummaryCard, state$),
      color: 'blue',
      icon: <InboxIcon />,
      id: 'default',
      summary: `Holding ${summary?.hold}`,
      title: 'All Unsent',
      total: summary?.total,
      testId: 'summary-default',
    },
    {
      action: () => handleSummaryFilter('attention', activeSummaryCard, state$),
      color: 'red',
      icon: <ExclamationCircleIcon />,
      id: 'attention',
      summary: `Failed Validation ${summary?.failed}`,
      title: 'Needs Attention',
      total: summary?.needsAttention,
      testId: 'summary-attention',
    },
    {
      action: () => handleSummaryFilter('ready', activeSummaryCard, state$),
      color: 'green',
      icon: <CheckCircleIcon />,
      id: 'ready',
      summary: 'Ready to Send',
      title: 'Ready',
      total: summary?.ready,
      testId: 'summary-ready',
    },
  ]

  return Promise.resolve(summaryCardsData)
}
