/* tslint:disable */
/* eslint-disable */
/**
 * CarrierDirectory
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CarrierDto
 */
export interface CarrierDto {
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'Id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'PrimaryPhone'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierDto
     */
    'RtClaims'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierDto
     */
    'Eligibility'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'NeaMasterId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierDto
     */
    'Era'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'PrimaryAddress1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'PrimaryAddress2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'PrimaryCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'PrimaryState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'PrimaryZip'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierDto
     */
    'BatchClaims'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'ChcPayerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'DxcPayerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'PayerTxtId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'ClaimSupportEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'ClaimProcessingVendor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'Fax'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'PrimaryEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'ClaimsSupportPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'OnederfulPayerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'ProviderRelationsPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'ProviderPortalWebsite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'PublicWebsiteUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'ProviderRelationsEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierDto
     */
    'ProviderServicesFax'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierDto
     */
    'IsFavorite'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CarrierDto
     */
    'Aliases'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierDto
     */
    'EssentialViewSupported'?: boolean | null;
}
/**
 * 
 * @export
 * @interface DocumentCode
 */
export interface DocumentCode {
    /**
     * 
     * @type {DocumentTypeEnum}
     * @memberof DocumentCode
     */
    'DocumentType'?: DocumentTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DocumentCode
     */
    '_DocumentCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentCode
     */
    'DocumentDescription'?: string | null;
    /**
     * 
     * @type {DocumentCodeRequirements}
     * @memberof DocumentCode
     */
    'Requirements'?: DocumentCodeRequirements;
}
/**
 * 
 * @export
 * @interface DocumentCodeRequirements
 */
export interface DocumentCodeRequirements {
    /**
     * 
     * @type {boolean}
     * @memberof DocumentCodeRequirements
     */
    'DocumentDate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentCodeRequirements
     */
    'DocumentOrientation'?: boolean;
}
/**
 * 
 * @export
 * @interface DocumentFormat
 */
export interface DocumentFormat {
    /**
     * 
     * @type {string}
     * @memberof DocumentFormat
     */
    'Format'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentTypeEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type DocumentTypeEnum = typeof DocumentTypeEnum[keyof typeof DocumentTypeEnum];


/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ProcedureCodeRequirement
 */
export interface ProcedureCodeRequirement {
    /**
     * 
     * @type {string}
     * @memberof ProcedureCodeRequirement
     */
    'ProcedureCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProcedureCodeRequirement
     */
    'ProcedureCodeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProcedureCodeRequirement
     */
    'ProcedureCodeComment'?: string | null;
    /**
     * 
     * @type {Array<DocumentCode>}
     * @memberof ProcedureCodeRequirement
     */
    'DocumentCodes'?: Array<DocumentCode> | null;
}
/**
 * 
 * @export
 * @interface Recipient
 */
export interface Recipient {
    /**
     * 
     * @type {number}
     * @memberof Recipient
     */
    'MasterId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    'VendorPayorId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    'PayorName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Recipient
     */
    'AcceptsElectronicAttachments'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Recipient
     */
    'RequiresPayorReferenceNumber'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Recipient
     */
    'AllowsPayorReferenceNumber'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Recipient
     */
    'AllowsNarrative'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    'PayorComments'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    'PayorReturnPolicy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    'LastUpdatedDate'?: string;
    /**
     * 
     * @type {Array<DocumentFormat>}
     * @memberof Recipient
     */
    'DocumentFormats'?: Array<DocumentFormat> | null;
    /**
     * 
     * @type {Array<ProcedureCodeRequirement>}
     * @memberof Recipient
     */
    'ProcedureCodeRequirements'?: Array<ProcedureCodeRequirement> | null;
}
/**
 * 
 * @export
 * @interface RecipientResponse
 */
export interface RecipientResponse {
    /**
     * 
     * @type {number}
     * @memberof RecipientResponse
     */
    'VendorPayorType'?: number;
    /**
     * 
     * @type {Array<Recipient>}
     * @memberof RecipientResponse
     */
    'Recipients'?: Array<Recipient> | null;
}

/**
 * CarrierDirectoryApi - axios parameter creator
 * @export
 */
export const CarrierDirectoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} neaMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierDirectoryCarrierRequirements: async (neaMasterId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'neaMasterId' is not null or undefined
            assertParamExists('carrierDirectoryCarrierRequirements', 'neaMasterId', neaMasterId)
            const localVarPath = `/nea-carrier/{nea-master-id}/requirements`
                .replace(`{${"nea-master-id"}}`, encodeURIComponent(String(neaMasterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierDirectoryCarriers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/carriers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} carrierid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierDirectoryToggleCarrierPreference: async (carrierid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'carrierid' is not null or undefined
            assertParamExists('carrierDirectoryToggleCarrierPreference', 'carrierid', carrierid)
            const localVarPath = `/carrier/{carrierid}/prefer`
                .replace(`{${"carrierid"}}`, encodeURIComponent(String(carrierid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CarrierDirectoryApi - functional programming interface
 * @export
 */
export const CarrierDirectoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CarrierDirectoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} neaMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carrierDirectoryCarrierRequirements(neaMasterId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.carrierDirectoryCarrierRequirements(neaMasterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carrierDirectoryCarriers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarrierDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.carrierDirectoryCarriers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} carrierid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carrierDirectoryToggleCarrierPreference(carrierid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.carrierDirectoryToggleCarrierPreference(carrierid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CarrierDirectoryApi - factory interface
 * @export
 */
export const CarrierDirectoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CarrierDirectoryApiFp(configuration)
    return {
        /**
         * 
         * @param {number} neaMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierDirectoryCarrierRequirements(neaMasterId: number, options?: any): AxiosPromise<RecipientResponse> {
            return localVarFp.carrierDirectoryCarrierRequirements(neaMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierDirectoryCarriers(options?: any): AxiosPromise<Array<CarrierDto>> {
            return localVarFp.carrierDirectoryCarriers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} carrierid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierDirectoryToggleCarrierPreference(carrierid: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.carrierDirectoryToggleCarrierPreference(carrierid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CarrierDirectoryApi - object-oriented interface
 * @export
 * @class CarrierDirectoryApi
 * @extends {BaseAPI}
 */
export class CarrierDirectoryApi extends BaseAPI {
    /**
     * 
     * @param {number} neaMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierDirectoryApi
     */
    public carrierDirectoryCarrierRequirements(neaMasterId: number, options?: AxiosRequestConfig) {
        return CarrierDirectoryApiFp(this.configuration).carrierDirectoryCarrierRequirements(neaMasterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierDirectoryApi
     */
    public carrierDirectoryCarriers(options?: AxiosRequestConfig) {
        return CarrierDirectoryApiFp(this.configuration).carrierDirectoryCarriers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} carrierid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierDirectoryApi
     */
    public carrierDirectoryToggleCarrierPreference(carrierid: string, options?: AxiosRequestConfig) {
        return CarrierDirectoryApiFp(this.configuration).carrierDirectoryToggleCarrierPreference(carrierid, options).then((request) => request(this.axios, this.basePath));
    }
}


