import { Buffer } from 'buffer'

import { addMinutes } from 'date-fns'

import { LogError } from 'utils'

export const storageVariables = {
  Invoices: 'invoices',
  SelectedTin: 'selectedTin',
  RpReceipts: 'rpReceipts',
  tsepJson: 'tsep',
}

export type LocalStorageItem<T> = {
  Value: T
  Expiration?: Date
}

export const destroyStorage = (user: string) => {
  localStorage.removeItem('acknowledged_legal_business_info_alert')
  localStorage.removeItem('at_res')
  localStorage.removeItem('claim-grid-filters')
  localStorage.removeItem('jsoncache')
  localStorage.removeItem('lastUpdateDate')
  localStorage.removeItem('localPollDate')
  localStorage.removeItem('pd_res')
  localStorage.removeItem('pollDate')
  localStorage.removeItem('serialPoll')
  localStorage.removeItem('storedDateMax')
  localStorage.removeItem('storedDateMin')
  localStorage.removeItem('trellis-cust-hasPmsData')
  localStorage.removeItem('trellis-last-messages-call-time')
  localStorage.removeItem('trellis-total-unread-messages')
  localStorage.removeItem('ui_res')
  localStorage.removeItem('vdsHeaders')
  localStorage.removeItem('Pms-Sync-Status-Info')
  localStorage.removeItem(storageVariables.tsepJson)
  localStorage.removeItem(storageVariables.RpReceipts)
  localStorage.removeItem(storageVariables.Invoices)
  localStorage.removeItem(storageVariables.SelectedTin)

  if (user) {
    localStorage.removeItem(user + '-patient-grid-filters')
    localStorage.removeItem(user + '-claim-grid-filters')
  }
}

export const removeItemFromLocalStorage = (key: string): void => {
  localStorage.removeItem(key)
}

const defaultExpirationMinutes: number = 30

export const addItemToTempLocalStorage = function <T>(
  key: string,
  item: T,
  minutesToStore?: number,
): void {
  const storageItem: LocalStorageItem<T> = {
    Value: item,
    Expiration: addMinutes(
      new Date(),
      minutesToStore ?? defaultExpirationMinutes,
    ),
  }
  encodeItemAndStore(key, storageItem)
}

export const addItemToLocalStorage = function <T>(key: string, item: T): void {
  const storageItem: LocalStorageItem<T> = {
    Value: item,
    Expiration: null,
  }
  encodeItemAndStore(key, storageItem)
}

const encodeItemAndStore = function <T>(
  key: string,
  storageItem: LocalStorageItem<T>,
) {
  try {
    const encodedData = Buffer.from(JSON.stringify(storageItem)).toString(
      'base64',
    )
    localStorage.setItem(key, encodedData)
  } catch (e) {
    LogError(e, 'Failed to encode item and add to storage')
  }
}

export const getItemFromLocalStorage = function <T>(key: string): T | null {
  const storedValue = localStorage.getItem(key)
  if (storedValue) {
    try {
      const buffer = Buffer.from(storedValue, 'base64')
      const parsedItem = JSON.parse(buffer.toString()) as LocalStorageItem<T>

      if (parsedItem?.Expiration && parsedItem.Expiration < new Date()) {
        localStorage.removeItem(key)
        return null
      }

      return parsedItem?.Value
    } catch (e) {
      LogError(e, 'Failed to get item from local storage')
    }
  }

  return null
}
