import { LeftCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { observer } from '@legendapp/state/react'
import { Col, Modal, Row, Tooltip } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'

import { Button, StatusDisplay } from 'ui'

import { getPopoverText } from 'trellis:components/claims/util/status-code-helpers'
import { getStatusOverride } from 'trellis:constants/claimStatusDescriptionData'

import { handleBackButtonClick } from '../../../../utilities/general'
import { useClaimDetailContext } from '../../context/claimDetailContext'
import { useClaimGridContext } from '../../context/claimGridContext'
import SendAttachmentModal from '../sendAttachmentModal/sendAttachmentModal'

const DetailHeader = observer(() => {
  const { ClaimState$, detailsTab, state } = useClaimGridContext()
  const {
    allowsPayorRefGlobal,
    claim,
    initialClaimState,
    globalAttachmentImages,
    globalNarrativeRef,
    globalPayorRef,
    isCreatedFromSentAttachment,
    isLegacyGlobal,
    returnToClaims,
    sending,
    setIsCreatedFromSentAttachment,
    submitClaim,
    submitType,
    claimDetailStatusCodes$,
  } = useClaimDetailContext()

  const claimDetailStatusCodes = claimDetailStatusCodes$.get()

  const [holdClaim, setHoldClaim] = useState<boolean>(
    initialClaimState.STATUS === 'Hold',
  )
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [showUnsavedChangesAlert, setShowUnsavedChangesAlert] =
    useState<boolean>(false)

  const hasAttachmentImages = globalAttachmentImages?.length > 0
  const hasCarrierRef = globalPayorRef != null && globalPayorRef != ''
  const hasNarrative = globalNarrativeRef != null && globalNarrativeRef != ''

  useEffect(() => {
    handleBackButtonClick(returnToClaims)
  }, [])

  const backButtonHandler = () => {
    if (
      state.ActiveTab === 'Unsent' &&
      ((initialClaimState.STATUS !== 'Hold' && holdClaim) ||
        (initialClaimState.STATUS === 'Hold' && !holdClaim) ||
        !_.isEqual(initialClaimState, claim))
    )
      setShowUnsavedChangesAlert(true)
    else returnToClaims()

    if (isCreatedFromSentAttachment) setIsCreatedFromSentAttachment(false)
  }

  const tooltipText = claimDetailStatusCodes
    ? getPopoverText(claimDetailStatusCodes)
    : null

  return (
    <>
      <SendAttachmentModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <Modal
        footer={[
          <Button
            label='Exit without Saving'
            key='exit'
            onClick={returnToClaims}
          />,
          <Button
            key='save'
            label='Save & Exit'
            loading={submitType === 'savingUnsavedChanges'}
            onClick={() =>
              submitClaim('savingUnsavedChanges', holdClaim, claim)
            }
            type='primary'
          />,
        ]}
        onCancel={() => setShowUnsavedChangesAlert(false)}
        title={
          <Row align='middle'>
            <WarningOutlined
              className='mr-050'
              style={{ color: 'var(--color-error, #F5222D)' }}
            />
            <p>Unsaved Changes</p>
          </Row>
        }
        open={showUnsavedChangesAlert}
      >
        <p>Would you like to save your changes?</p>
      </Modal>
      <Row>
        <Col flex={1}>
          <LeftCircleOutlined
            className='back-to-all float-left fs-150'
            onClick={backButtonHandler}
          />
          <span className='float-left pl-050'>Back</span>
        </Col>
        {state.ActiveTab === 'Unsent' && (
          <section className='flex'>
            <Button
              className='ml-100'
              label='Cancel'
              onClick={backButtonHandler}
              size='large'
            />
            {!ClaimState$.isNewClaim.peek() && (
              <Button
                className='ml-100'
                label={holdClaim ? 'Release' : 'Hold'}
                onClick={() => setHoldClaim(!holdClaim)}
                size='large'
              />
            )}
            <Button
              className='ml-100'
              data-testid='claim-save-and-exit'
              label='Save and Exit'
              loading={submitType === 'saving'}
              onClick={() => submitClaim('saving', holdClaim)}
              size='large'
            />
          </section>
        )}
        <Col>
          {!isLegacyGlobal &&
            isCreatedFromSentAttachment &&
            detailsTab === 'Attachments' &&
            allowsPayorRefGlobal &&
            _.isEqual(initialClaimState, claim) && (
              <Tooltip
                placement='bottom'
                title={
                  hasCarrierRef
                    ? undefined
                    : 'Add a Carrier Reference No. below.'
                }
              >
                <Button
                  className='detailheader__send-attachment'
                  disabled={
                    (!hasCarrierRef || !hasNarrative) &&
                    (!hasCarrierRef || !hasAttachmentImages)
                  }
                  label='Send Attachment'
                  loading={sending}
                  onClick={() => setIsModalOpen(true)}
                  size='large'
                />
              </Tooltip>
            )}
          <Button
            className='ml-100'
            disabled={holdClaim}
            label={`${claim && claim.Resend ? 'Resend' : 'Send'} Claim`}
            loading={submitType === 'sending'}
            onClick={() => submitClaim('sending')}
            size='large'
            type='primary'
          />
        </Col>
      </Row>
      <Row
        align='middle'
        className='claim-title-container'
      >
        {ClaimState$.isNewClaim.peek() ? (
          <p className='claim-title-container__title'>Create a New Claim</p>
        ) : (
          <>
            <h1 className='page-content-header__title'>
              {initialClaimState.PatientName}
            </h1>
            <Col className='status-badge-container'>
              <StatusDisplay
                status={initialClaimState.STATUS}
                toolTip={tooltipText}
                type='badge'
                queued={initialClaimState.Queued}
                getStatusOverride={getStatusOverride}
                whiteBackgroundOverride={true}
              />
            </Col>
          </>
        )}
      </Row>
    </>
  )
})

export default DetailHeader
