export const validateZip = (value: string) => {
  if (!value) return

  const isValid = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)
  const error: any = null

  if (!isValid) return `e.g. 12345 or 12345-1234`

  return error
}
