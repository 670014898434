import { Form, Input, InputRef } from 'antd'
import { Rule } from 'antd/lib/form'
import React, { ChangeEvent, forwardRef, Ref, useEffect, useState } from 'react'

import { spacesToProperty } from 'utils'

import '../../_formStyles.scss'

import { validateRequired } from './Validators/validateRequired'

// TODO: update to handle nested form items

// NOTE: form items also accept dependencies if validation is dependent on another field
// TODO: update to handle dependencies (e.g. confirm password is dependent on password)

interface FormInputProps {
  className?: string
  disabled?: boolean
  floatLabel?: boolean
  label?: string
  name: string
  placeholder?: string
  ref?: Ref<InputRef>
  required?: boolean
  validator?: (value: string) => void
}

export const FormInput = forwardRef<InputRef, FormInputProps>(
  (
    {
      className,
      disabled = false,
      floatLabel = true,
      label,
      name,
      placeholder = null,
      required = false,
      validator,
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState<string>('')
    const [isLabelFloating, setIsLabelFloating] = useState<boolean>(false)

    const form = Form.useFormInstance()

    useEffect(() => {
      if (!isLabelFloating && (form.getFieldValue(name) || placeholder))
        setIsLabelFloating(true)
      else if (!form.getFieldValue(name) && !placeholder)
        setIsLabelFloating(false)

      setInputValue(form.getFieldValue(name))
    }, [form.getFieldValue(name)])

    const floatLabelClasses = `
        ant-form-item__float-label 
        ${
          isLabelFloating
            ? 'ant-form-item__float-label--true'
            : 'ant-form-item__float-label--false'
        } 
    `

    const getRules = () => {
      const rules = []

      rules.push({
        message: `${spacesToProperty(label || name)} is required`,
        required: required,
      })

      if (required && !disabled) {
        rules.push({
          validator: (_: Rule, value: string) => validateRequired(value, label),
        })
      }

      if (validator && inputValue && !disabled)
        rules.push({ validator: (_: Rule, value: string) => validator(value) })

      return rules
    }

    const handleOnBlur = () => {
      if (floatLabel && (inputValue || placeholder)) setIsLabelFloating(true)
      else setIsLabelFloating(false)
    }

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (floatLabel && event.target.value) setIsLabelFloating(true)
    }

    return (
      <div className='form-item-validation-container'>
        <Form.Item
          className={`${className} ${floatLabel && floatLabelClasses}`}
          label={label}
          name={name}
          rules={getRules()}
          validateFirst={true}
        >
          <Input
            className={`${!floatLabel ? 'no-float' : ''}`}
            disabled={disabled}
            onBlur={handleOnBlur}
            onFocus={() => setIsLabelFloating(floatLabel ? true : false)}
            onChange={handleOnChange}
            placeholder={placeholder}
            ref={ref}
          />
        </Form.Item>
      </div>
    )
  },
)
