import { LogError } from 'utils'

import { Carrier as FaCarrier } from '../api/attachment-standalone/attachment-standalone-client'
import { ParticipatingCarrier } from '../components/Eligibility/shared/utilities/eligibilityTyping'

export const getFaCarrierById = (
  carrierList: FaCarrier[],
  masterId?: number,
  payerId?: string,
): FaCarrier => {
  let matchedCarrier: FaCarrier = null
  if (masterId) {
    matchedCarrier = carrierList?.find(
      (carrier) => carrier.neaMasterId === masterId,
    )
  }

  if (payerId && !matchedCarrier) {
    matchedCarrier = carrierList?.find(
      (carrier) => carrier.payerTxtId === payerId,
    )
  }
  return matchedCarrier
}

export const getEligibilityCarrierById = (
  carrierList: ParticipatingCarrier[],
  payerId: string,
) => {
  try {
    return carrierList?.find((carrier) => carrier.CarrierId === payerId)
  } catch (error) {
    LogError(error, 'Failed to map patient carrier')
  }
  return null
}
