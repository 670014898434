import { Spin } from 'antd'
import { FC } from 'react'

export interface LoadingSpinnerProps {}

export const LoadingSpinner: FC<LoadingSpinnerProps> = () => {
  return (
    <div className='flex justify-center mt-200'>
      <Spin />
    </div>
  )
}
