import { Dialog } from '@progress/kendo-react-dialogs'
import React, { FC } from 'react'

import { IAttachmentImageViewFull } from '../../attachment.interface'

const AttachmentImageViewFull: FC<IAttachmentImageViewFull> = ({
  image,
  toggleImageView,
}) => {
  return (
    <Dialog
      title=' '
      className='sent-attachment-image-full-container'
      onClose={() => toggleImageView(false)}
    >
      {
        <div className='container'>
          <img src={URL.createObjectURL(image)} />
        </div>
      }
    </Dialog>
  )
}

export default AttachmentImageViewFull
