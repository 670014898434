import { Col, Row } from 'antd'
import React from 'react'

import HtmlChunk from '../htmlChunk'

const EobContent = ({ data }: any) => (
  <Row justify='center'>
    <Col
      span={16}
      className='form-detail-section'
    >
      <div className='page-section'>
        <div className='page-section__title'>EOB</div>
        <HtmlChunk data={data} />
      </div>
    </Col>
  </Row>
)

export default EobContent
