import { Dispatch, FC, SetStateAction } from 'react'

import { spacesToProperty } from 'utils'

import { RequestStateType } from '../../../shared/context/EligibilityContext'
import styles from './SummaryCards.module.scss'

interface AppointmentsSummary {
  Appointments: number
  Eligible: number
  NeedsAttention: number
  PendingPatientResponse: number
}

const emptySummary = {
  Appointments: 0,
  Eligible: 0,
  NeedsAttention: 0,
  PendingPatientResponse: 0,
}

interface SummaryCardsProps {
  filterState: RequestStateType
  setFilterState: Dispatch<SetStateAction<RequestStateType>>
  summary: AppointmentsSummary
}

const SummaryCards: FC<SummaryCardsProps> = ({
  filterState,
  setFilterState,
  summary,
}) => {
  return (
    <article className={styles['summary-cards-container']}>
      {Object.entries(summary ? summary : { ...emptySummary }).map(
        ([key, value]) => {
          const item = { name: key, total: value }
          return (
            <SummaryCard
              {...item}
              filterState={filterState}
              key={key}
              setFilterState={setFilterState}
            />
          )
        },
      )}
    </article>
  )
}

interface SummaryItemProps {
  name: string
  filterState: RequestStateType
  setFilterState: Dispatch<SetStateAction<RequestStateType>>
  total: number
}

const SummaryCard: FC<SummaryItemProps> = ({
  name,
  filterState,
  setFilterState,
  total,
}) => {
  const handleSummaryFilter = () => {
    let statuses: string = null

    if (name === 'NeedsAttention') {
      statuses = 'Needs Attention'
    } else statuses = spacesToProperty(name)

    const copy = { ...filterState }

    copy.Key = ++copy.Key
    copy.Filters.CurrentPage = 1

    if (name === 'Appointments') {
      copy.Filters.Config = []
      setFilterState(copy)
    } else if (statuses.includes(copy.Filters.Config['Status'])) {
      delete copy.Filters.Config['Status']
    } else {
      copy.Filters.Config['Status'] = statuses
      setFilterState(copy)
    }
  }

  return (
    <section
      className={`${styles['summary-card']} ${styles[`summary-card--${name.toLocaleLowerCase()}`]}`}
      onClick={handleSummaryFilter}
    >
      <h1 className={styles['summary-card__total']}>{total}</h1>
      <h4 className={styles['summary-card__title']}>
        {spacesToProperty(name)}
      </h4>
    </section>
  )
}

export default SummaryCards
