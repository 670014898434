/* tslint:disable */
/* eslint-disable */
/**
 * AW.Application, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'zip'?: string | null;
}
/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'attachmentId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'statusId': number;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'facilityId': string;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'patientId': number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'providerId': number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'patientInsuranceId': number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'masterId': number;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'dmsReferenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'recipientReferenceNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Attachment
     */
    'priorAuthorization': boolean;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'serviceDateFrom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'serviceDateTo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'narrative'?: string | null;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Attachment
     */
    'documents'?: Array<Document> | null;
    /**
     * 
     * @type {Array<ProcedureCodeRequirement>}
     * @memberof Attachment
     */
    'procedureCodeRequirements'?: Array<ProcedureCodeRequirement> | null;
}
/**
 * 
 * @export
 * @interface AttachmentListItem
 */
export interface AttachmentListItem {
    /**
     * 
     * @type {number}
     * @memberof AttachmentListItem
     */
    'attachmentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AttachmentListItem
     */
    'masterId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    'attachmentFacilityId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    'patientFacilityId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    'location'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AttachmentListItem
     */
    'attachmentStatusId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    'attachmentStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    'patientFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    'patientLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    'providerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    'providerTaxId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    'recipientName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    'datesOfService'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    'createDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    'lastModifiedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    'lastModifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    'subscriberFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    'subscriberLastName'?: string | null;
    /**
     * 
     * @type {RelationshipTypes}
     * @memberof AttachmentListItem
     */
    'subscriberRelationshipType'?: RelationshipTypes;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    'memberId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AttachmentListItem
     */
    'hmfId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    'sentDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AttachmentListItem
     */
    'patientId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentListItem
     */
    'innImport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentListItem
     */
    'inUse'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    'inUseBy'?: string | null;
}
/**
 * 
 * @export
 * @interface AttachmentStatus
 */
export interface AttachmentStatus {
    /**
     * 
     * @type {number}
     * @memberof AttachmentStatus
     */
    'attachmentId'?: number;
    /**
     * 
     * @type {AttachmentStatusTypes}
     * @memberof AttachmentStatus
     */
    'statusType'?: AttachmentStatusTypes;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AttachmentStatusTypes = {
    Hold: 'Hold',
    NeedsAttentionAddRequired: 'NeedsAttentionAddRequired',
    HoldAttentionRequired: 'HoldAttentionRequired',
    HoldForReview: 'HoldForReview'
} as const;

export type AttachmentStatusTypes = typeof AttachmentStatusTypes[keyof typeof AttachmentStatusTypes];


/**
 * 
 * @export
 * @interface Carrier
 */
export interface Carrier {
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Carrier
     */
    'neaMasterId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'primaryAddress1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'primaryAddress2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'primaryCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'primaryState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'primaryZip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'primaryPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'fax'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Carrier
     */
    'batchClaims'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Carrier
     */
    'rtClaims'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Carrier
     */
    'eligibility'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Carrier
     */
    'era'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'chcPayerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'dxcPayerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'payerTxtId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'claimSupportEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'claimProcessingVendor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'primaryEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'claimsSupportPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'onederfulPayerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'providerRelationsPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'providerPortalWebsite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'publicWebsiteUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'providerRelationsEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'providerServicesFax'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Carrier
     */
    'aliases'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ClearinghouseRecipient
 */
export interface ClearinghouseRecipient {
    /**
     * 
     * @type {string}
     * @memberof ClearinghouseRecipient
     */
    'clearinghouseName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClearinghouseRecipient
     */
    'clearinghousePayorId'?: string | null;
}
/**
 * 
 * @export
 * @interface ConvertImageRequest
 */
export interface ConvertImageRequest {
    /**
     * 
     * @type {string}
     * @memberof ConvertImageRequest
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof ConvertImageRequest
     */
    'destType': string;
    /**
     * 
     * @type {string}
     * @memberof ConvertImageRequest
     */
    'facilityId'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomerService
 */
export interface CustomerService {
    /**
     * 
     * @type {string}
     * @memberof CustomerService
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerService
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'documentId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Document
     */
    'overwriteDocument': boolean;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'patientId': number;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'documentCode': number;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'documentFileName': string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'documentDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'acquisitionMethod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'createDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'orientationMethodId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'toothLeft'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'toothRight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'orientationTypeId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'documentNote'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'imageStatusId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'imageStatusChangeDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'productionStorageUuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'md5'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'documentSize'?: number | null;
}
/**
 * 
 * @export
 * @interface DocumentAccessDetails
 */
export interface DocumentAccessDetails {
    /**
     * 
     * @type {string}
     * @memberof DocumentAccessDetails
     */
    'documentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentAccessDetails
     */
    'documentPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentAccessDetails
     */
    'expirationTime'?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentCode
 */
export interface DocumentCode {
    /**
     * 
     * @type {number}
     * @memberof DocumentCode
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentCode
     */
    'documentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentCode
     */
    'documentDescription'?: string | null;
    /**
     * 
     * @type {DocumentCodeRequirements}
     * @memberof DocumentCode
     */
    'requirements'?: DocumentCodeRequirements;
}
/**
 * 
 * @export
 * @interface DocumentCodeRequirements
 */
export interface DocumentCodeRequirements {
    /**
     * 
     * @type {boolean}
     * @memberof DocumentCodeRequirements
     */
    'documentDate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentCodeRequirements
     */
    'documentOrientation'?: boolean;
}
/**
 * 
 * @export
 * @interface DocumentType
 */
export interface DocumentType {
    /**
     * 
     * @type {number}
     * @memberof DocumentType
     */
    'documentCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentType
     */
    'documentDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentType
     */
    'documentTypeId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentType
     */
    'documentTypeGroupDescription'?: string | null;
    /**
     * 
     * @type {DocumentCodeRequirements}
     * @memberof DocumentType
     */
    'requirements'?: DocumentCodeRequirements;
}
/**
 * 
 * @export
 * @interface ImageSettings
 */
export interface ImageSettings {
    /**
     * 
     * @type {number}
     * @memberof ImageSettings
     */
    'minImageHeightPixels'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageSettings
     */
    'minImageWidthPixels'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageSettings
     */
    'minImageSizeBytes'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageSettings
     */
    'maxImageSizeBytes'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InsuranceTypes = {
    Primary: 'Primary',
    Secondary: 'Secondary'
} as const;

export type InsuranceTypes = typeof InsuranceTypes[keyof typeof InsuranceTypes];


/**
 * 
 * @export
 * @interface InsuranceVds
 */
export interface InsuranceVds {
    /**
     * 
     * @type {string}
     * @memberof InsuranceVds
     */
    'carrierName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsuranceVds
     */
    'carrierId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsuranceVds
     */
    'patIsSub'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof InsuranceVds
     */
    'memberId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsuranceVds
     */
    'isPrimary'?: boolean | null;
}
/**
 * 
 * @export
 * @interface OrientationMethod
 */
export interface OrientationMethod {
    /**
     * 
     * @type {number}
     * @memberof OrientationMethod
     */
    'orientationMethodId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrientationMethod
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface OrientationType
 */
export interface OrientationType {
    /**
     * 
     * @type {number}
     * @memberof OrientationType
     */
    'orientationTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrientationType
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface Patient
 */
export interface Patient {
    /**
     * 
     * @type {number}
     * @memberof Patient
     */
    'patientId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Patient
     */
    'providerId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'facilityId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'dateOfBirth'?: string | null;
}
/**
 * 
 * @export
 * @interface PatientAssociationRequest
 */
export interface PatientAssociationRequest {
    /**
     * 
     * @type {string}
     * @memberof PatientAssociationRequest
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientAssociationRequest
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientAssociationRequest
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientAssociationRequest
     */
    'memberId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatientAssociationRequest
     */
    'providerId'?: number | null;
}
/**
 * 
 * @export
 * @interface PatientAssociationResponse
 */
export interface PatientAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof PatientAssociationResponse
     */
    'patientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatientAssociationResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientAssociationResponse
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientAssociationResponse
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientAssociationResponse
     */
    'memberId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatientAssociationResponse
     */
    'providerId'?: number | null;
}
/**
 * 
 * @export
 * @interface PatientInsuranceRequest
 */
export interface PatientInsuranceRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientInsuranceRequest
     */
    'masterId': number;
    /**
     * 
     * @type {string}
     * @memberof PatientInsuranceRequest
     */
    'memberId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsuranceRequest
     */
    'subscriberFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof PatientInsuranceRequest
     */
    'subscriberLastName': string;
    /**
     * 
     * @type {TrellisRelationshipTypes}
     * @memberof PatientInsuranceRequest
     */
    'relationshipType': TrellisRelationshipTypes;
    /**
     * 
     * @type {InsuranceTypes}
     * @memberof PatientInsuranceRequest
     */
    'insuranceType': InsuranceTypes;
}
/**
 * 
 * @export
 * @interface PatientInsuranceResponse
 */
export interface PatientInsuranceResponse {
    /**
     * 
     * @type {number}
     * @memberof PatientInsuranceResponse
     */
    'patientInsuranceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatientInsuranceResponse
     */
    'patientId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatientInsuranceResponse
     */
    'masterId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsuranceResponse
     */
    'memberId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsuranceResponse
     */
    'subscriberFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientInsuranceResponse
     */
    'subscriberLastName'?: string | null;
    /**
     * 
     * @type {TrellisRelationshipTypes}
     * @memberof PatientInsuranceResponse
     */
    'relationshipType'?: TrellisRelationshipTypes;
    /**
     * 
     * @type {InsuranceTypes}
     * @memberof PatientInsuranceResponse
     */
    'insuranceType'?: InsuranceTypes;
}
/**
 * 
 * @export
 * @interface PatientVds
 */
export interface PatientVds {
    /**
     * 
     * @type {string}
     * @memberof PatientVds
     */
    'vdsPatientId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientVds
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientVds
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientVds
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {Array<InsuranceVds>}
     * @memberof PatientVds
     */
    'insurance'?: Array<InsuranceVds> | null;
}
/**
 * 
 * @export
 * @interface PatientsSearchVdsRequest
 */
export interface PatientsSearchVdsRequest {
    /**
     * 
     * @type {string}
     * @memberof PatientsSearchVdsRequest
     */
    'memberId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientsSearchVdsRequest
     */
    'patientName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientsSearchVdsRequest
     */
    'patientDateOfBirth'?: string | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ProcedureCodeRequirement
 */
export interface ProcedureCodeRequirement {
    /**
     * 
     * @type {number}
     * @memberof ProcedureCodeRequirement
     */
    'procedureCodeId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProcedureCodeRequirement
     */
    'procedureCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProcedureCodeRequirement
     */
    'procedureTooth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProcedureCodeRequirement
     */
    'procedureRequirement'?: string | null;
}
/**
 * 
 * @export
 * @interface Provider
 */
export interface Provider {
    /**
     * 
     * @type {number}
     * @memberof Provider
     */
    'providerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'facilityId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'taxId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'licenseNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'npi'?: string | null;
    /**
     * 
     * @type {ProviderSpecialtyTypes}
     * @memberof Provider
     */
    'specialtyType'?: ProviderSpecialtyTypes;
}
/**
 * 
 * @export
 * @interface ProviderAssociationResponse
 */
export interface ProviderAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof ProviderAssociationResponse
     */
    'fastAttachProviderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProviderAssociationResponse
     */
    'trellisProviderId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderAssociationResponse
     */
    'facilityId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderAssociationResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderAssociationResponse
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderAssociationResponse
     */
    'taxId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderAssociationResponse
     */
    'licenseNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderAssociationResponse
     */
    'npi'?: string | null;
    /**
     * 
     * @type {ProviderSpecialtyTypes}
     * @memberof ProviderAssociationResponse
     */
    'specialtyType'?: ProviderSpecialtyTypes;
}
/**
 * 
 * @export
 * @interface ProviderRequest
 */
export interface ProviderRequest {
    /**
     * 
     * @type {string}
     * @memberof ProviderRequest
     */
    'facilityId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderRequest
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderRequest
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderRequest
     */
    'taxId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderRequest
     */
    'licenseNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderRequest
     */
    'npi'?: string | null;
    /**
     * 
     * @type {ProviderSpecialtyTypes}
     * @memberof ProviderRequest
     */
    'specialtyType'?: ProviderSpecialtyTypes;
}
/**
 * 
 * @export
 * @interface ProviderSpecialty
 */
export interface ProviderSpecialty {
    /**
     * 
     * @type {number}
     * @memberof ProviderSpecialty
     */
    'specialtyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProviderSpecialty
     */
    'specialtyName'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProviderSpecialtyTypes = {
    OralSurgeon: 'OralSurgeon',
    GeneralDentist: 'GeneralDentist',
    Endodontist: 'Endodontist',
    Pedodontist: 'Pedodontist',
    Periodontist: 'Periodontist',
    Prosthodontist: 'Prosthodontist',
    Orthodontist: 'Orthodontist'
} as const;

export type ProviderSpecialtyTypes = typeof ProviderSpecialtyTypes[keyof typeof ProviderSpecialtyTypes];


/**
 * 
 * @export
 * @interface Recipient
 */
export interface Recipient {
    /**
     * 
     * @type {number}
     * @memberof Recipient
     */
    'masterId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    'carrierId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    'payorId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    'payorName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    'recipientName'?: string | null;
    /**
     * 
     * @type {Array<Address>}
     * @memberof Recipient
     */
    'addresses'?: Array<Address> | null;
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    'comments'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    'submissionReturnPolicy'?: string | null;
    /**
     * 
     * @type {Array<CustomerService>}
     * @memberof Recipient
     */
    'customerServices'?: Array<CustomerService> | null;
    /**
     * 
     * @type {Array<ClearinghouseRecipient>}
     * @memberof Recipient
     */
    'clearinghouseRecipients'?: Array<ClearinghouseRecipient> | null;
}
/**
 * 
 * @export
 * @interface RecipientAttachmentRules
 */
export interface RecipientAttachmentRules {
    /**
     * 
     * @type {number}
     * @memberof RecipientAttachmentRules
     */
    'masterId'?: number;
    /**
     * 
     * @type {Array<DocumentType>}
     * @memberof RecipientAttachmentRules
     */
    'documentTypes'?: Array<DocumentType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof RecipientAttachmentRules
     */
    'narrativeDisabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RecipientAttachmentRules
     */
    'recipientReferenceNumberDisabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RecipientAttachmentRules
     */
    'recipientReferenceNumberRequired'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RecipientAttachmentRules
     */
    'claimPriorAuthorizationDisabled'?: boolean;
}
/**
 * 
 * @export
 * @interface Relationship
 */
export interface Relationship {
    /**
     * 
     * @type {number}
     * @memberof Relationship
     */
    'relationshipId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Relationship
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RelationshipTypes = {
    Self: 'Self',
    Spouse: 'Spouse',
    Child: 'Child',
    Employee: 'Employee',
    Unknown: 'Unknown',
    HandicappedDependent: 'HandicappedDependent',
    SignficantOther: 'SignficantOther',
    InjuredPlaintiff: 'InjuredPlaintiff',
    LifePartner: 'LifePartner',
    Dependent: 'Dependent'
} as const;

export type RelationshipTypes = typeof RelationshipTypes[keyof typeof RelationshipTypes];


/**
 * 
 * @export
 * @interface ResendAttachment
 */
export interface ResendAttachment {
    /**
     * 
     * @type {number}
     * @memberof ResendAttachment
     */
    'hmfId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResendAttachment
     */
    'payorReferenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResendAttachment
     */
    'customerServiceStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResendAttachment
     */
    'customerServiceRequestDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResendAttachment
     */
    'customServiceResponseDate'?: string | null;
}
/**
 * 
 * @export
 * @interface SendAttachment
 */
export interface SendAttachment {
    /**
     * 
     * @type {number}
     * @memberof SendAttachment
     */
    'hmfId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SendAttachment
     */
    'attachmentId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SendAttachment
     */
    'archived'?: boolean;
}
/**
 * 
 * @export
 * @interface SentAttachment
 */
export interface SentAttachment {
    /**
     * 
     * @type {number}
     * @memberof SentAttachment
     */
    'hmfId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SentAttachment
     */
    'facilityId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachment
     */
    'location'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachment
     */
    'providerFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachment
     */
    'providerLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachment
     */
    'providerLicense'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachment
     */
    'providerTaxId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachment
     */
    'patientFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachment
     */
    'patientLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachment
     */
    'patientDateOfBirth'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SentAttachment
     */
    'masterId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachment
     */
    'recipientName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachment
     */
    'memberFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachment
     */
    'memberLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachment
     */
    'memberId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SentAttachment
     */
    'relationshipId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachment
     */
    'relationship'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachment
     */
    'dmsReferenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachment
     */
    'recipientReferenceNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SentAttachment
     */
    'priorAuthorization'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SentAttachment
     */
    'serviceDateFrom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachment
     */
    'serviceDateTo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachment
     */
    'sentDate'?: string | null;
    /**
     * 
     * @type {Array<SentAttachmentProcedureCode>}
     * @memberof SentAttachment
     */
    'procedureCodeRequirements'?: Array<SentAttachmentProcedureCode> | null;
    /**
     * 
     * @type {Array<SentAttachmentDocument>}
     * @memberof SentAttachment
     */
    'documents'?: Array<SentAttachmentDocument> | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachment
     */
    'narrative'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SentAttachment
     */
    'messageIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface SentAttachmentDocument
 */
export interface SentAttachmentDocument {
    /**
     * 
     * @type {number}
     * @memberof SentAttachmentDocument
     */
    'hmfId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SentAttachmentDocument
     */
    'imageSequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentDocument
     */
    'fileSystemDocumentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentDocument
     */
    'documentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentDocument
     */
    'documentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentDocument
     */
    'dateTaken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentDocument
     */
    'dateCreated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentDocument
     */
    'captureMethod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentDocument
     */
    'md5'?: string | null;
}
/**
 * 
 * @export
 * @interface SentAttachmentListItem
 */
export interface SentAttachmentListItem {
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentListItem
     */
    'hmfId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentListItem
     */
    'masterId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentListItem
     */
    'facilityId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentListItem
     */
    'location'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentListItem
     */
    'patientName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentListItem
     */
    'subscriberFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentListItem
     */
    'subscriberLastName'?: string | null;
    /**
     * 
     * @type {RelationshipTypes}
     * @memberof SentAttachmentListItem
     */
    'subscriberRelationshipType'?: RelationshipTypes;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentListItem
     */
    'insuredsId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentListItem
     */
    'providerFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentListItem
     */
    'providerLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentListItem
     */
    'providerTaxId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentListItem
     */
    'recipientName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentListItem
     */
    'serviceDateFrom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentListItem
     */
    'serviceDateTo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentListItem
     */
    'sentDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentListItem
     */
    'serviceDateDisplay'?: string | null;
}
/**
 * 
 * @export
 * @interface SentAttachmentProcedureCode
 */
export interface SentAttachmentProcedureCode {
    /**
     * 
     * @type {number}
     * @memberof SentAttachmentProcedureCode
     */
    'procedureRequirementSequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentProcedureCode
     */
    'procedureCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentProcedureCode
     */
    'procedureTooth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentProcedureCode
     */
    'procedureRequirement'?: string | null;
}
/**
 * 
 * @export
 * @interface SentAttachmentsRequest
 */
export interface SentAttachmentsRequest {
    /**
     * 
     * @type {number}
     * @memberof SentAttachmentsRequest
     */
    'masterId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentsRequest
     */
    'patientFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentsRequest
     */
    'patientLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentsRequest
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentsRequest
     */
    'sentAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentAttachmentsRequest
     */
    'sentBefore'?: string | null;
}
/**
 * 
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'accessToken'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TokenResponse
     */
    'expiresInSeconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'customerFacilityId'?: string | null;
}
/**
 * 
 * @export
 * @interface ToothDefinition
 */
export interface ToothDefinition {
    /**
     * 
     * @type {number}
     * @memberof ToothDefinition
     */
    'toothDefinitionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ToothDefinition
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TrellisRelationshipTypes = {
    Self: 'Self',
    Spouse: 'Spouse',
    DependentChild: 'DependentChild',
    Other: 'Other'
} as const;

export type TrellisRelationshipTypes = typeof TrellisRelationshipTypes[keyof typeof TrellisRelationshipTypes];



/**
 * ApplicationApi - axios parameter creator
 * @export
 */
export const ApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationDocumentCodesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/application/document-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationImageSettingsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/application/image-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationOrientationMethodsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/application/orientation-methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationOrientationTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/application/orientation-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationProviderSpecialtiesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/application/provider-specialties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationRelationshipsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/application/relationships`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationStatesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/application/states`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationToothDefinitionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/application/tooth-definitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationApi - functional programming interface
 * @export
 */
export const ApplicationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationDocumentCodesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentCode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationDocumentCodesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationImageSettingsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationImageSettingsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationOrientationMethodsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrientationMethod>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationOrientationMethodsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationOrientationTypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrientationType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationOrientationTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationProviderSpecialtiesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProviderSpecialty>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationProviderSpecialtiesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationRelationshipsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Relationship>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationRelationshipsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationStatesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationStatesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationToothDefinitionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ToothDefinition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationToothDefinitionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationApi - factory interface
 * @export
 */
export const ApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationDocumentCodesGet(options?: any): AxiosPromise<Array<DocumentCode>> {
            return localVarFp.applicationDocumentCodesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationImageSettingsGet(options?: any): AxiosPromise<ImageSettings> {
            return localVarFp.applicationImageSettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationOrientationMethodsGet(options?: any): AxiosPromise<Array<OrientationMethod>> {
            return localVarFp.applicationOrientationMethodsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationOrientationTypesGet(options?: any): AxiosPromise<Array<OrientationType>> {
            return localVarFp.applicationOrientationTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationProviderSpecialtiesGet(options?: any): AxiosPromise<Array<ProviderSpecialty>> {
            return localVarFp.applicationProviderSpecialtiesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationRelationshipsGet(options?: any): AxiosPromise<Array<Relationship>> {
            return localVarFp.applicationRelationshipsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationStatesGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.applicationStatesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationToothDefinitionsGet(options?: any): AxiosPromise<Array<ToothDefinition>> {
            return localVarFp.applicationToothDefinitionsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationApi - object-oriented interface
 * @export
 * @class ApplicationApi
 * @extends {BaseAPI}
 */
export class ApplicationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationDocumentCodesGet(options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).applicationDocumentCodesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationImageSettingsGet(options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).applicationImageSettingsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationOrientationMethodsGet(options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).applicationOrientationMethodsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationOrientationTypesGet(options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).applicationOrientationTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationProviderSpecialtiesGet(options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).applicationProviderSpecialtiesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationRelationshipsGet(options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).applicationRelationshipsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationStatesGet(options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).applicationStatesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationToothDefinitionsGet(options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).applicationToothDefinitionsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AttachmentApi - axios parameter creator
 * @export
 */
export const AttachmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentAttachmentIdDelete: async (attachmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('attachmentAttachmentIdDelete', 'attachmentId', attachmentId)
            const localVarPath = `/attachment/{attachmentId}`
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {number} documentId 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentAttachmentIdDocumentsDocumentIdGet: async (attachmentId: number, documentId: number, facilityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('attachmentAttachmentIdDocumentsDocumentIdGet', 'attachmentId', attachmentId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('attachmentAttachmentIdDocumentsDocumentIdGet', 'documentId', documentId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('attachmentAttachmentIdDocumentsDocumentIdGet', 'facilityId', facilityId)
            const localVarPath = `/attachment/{attachmentId}/documents/{documentId}`
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (facilityId !== undefined) {
                localVarQueryParameter['facilityId'] = facilityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentAttachmentIdDocumentsGet: async (attachmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('attachmentAttachmentIdDocumentsGet', 'attachmentId', attachmentId)
            const localVarPath = `/attachment/{attachmentId}/documents`
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentAttachmentIdGet: async (attachmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('attachmentAttachmentIdGet', 'attachmentId', attachmentId)
            const localVarPath = `/attachment/{attachmentId}`
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {boolean} ignoreAttachmentRequirements 
         * @param {Attachment} attachment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentAttachmentIdPut: async (attachmentId: number, ignoreAttachmentRequirements: boolean, attachment: Attachment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('attachmentAttachmentIdPut', 'attachmentId', attachmentId)
            // verify required parameter 'ignoreAttachmentRequirements' is not null or undefined
            assertParamExists('attachmentAttachmentIdPut', 'ignoreAttachmentRequirements', ignoreAttachmentRequirements)
            // verify required parameter 'attachment' is not null or undefined
            assertParamExists('attachmentAttachmentIdPut', 'attachment', attachment)
            const localVarPath = `/attachment/{attachmentId}`
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ignoreAttachmentRequirements !== undefined) {
                localVarQueryParameter['ignoreAttachmentRequirements'] = ignoreAttachmentRequirements;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attachment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentAttachmentIdSendPost: async (attachmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('attachmentAttachmentIdSendPost', 'attachmentId', attachmentId)
            const localVarPath = `/attachment/{attachmentId}/send`
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentAttachmentIdStatusPut: async (attachmentId: number, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('attachmentAttachmentIdStatusPut', 'attachmentId', attachmentId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('attachmentAttachmentIdStatusPut', 'body', body)
            const localVarPath = `/attachment/{attachmentId}/status`
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentFacilityFacilityIdGet: async (facilityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('attachmentFacilityFacilityIdGet', 'facilityId', facilityId)
            const localVarPath = `/attachment/facility/{facilityId}`
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} ignoreAttachmentRequirements 
         * @param {Attachment} attachment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentPost: async (ignoreAttachmentRequirements: boolean, attachment: Attachment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ignoreAttachmentRequirements' is not null or undefined
            assertParamExists('attachmentPost', 'ignoreAttachmentRequirements', ignoreAttachmentRequirements)
            // verify required parameter 'attachment' is not null or undefined
            assertParamExists('attachmentPost', 'attachment', attachment)
            const localVarPath = `/attachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ignoreAttachmentRequirements !== undefined) {
                localVarQueryParameter['ignoreAttachmentRequirements'] = ignoreAttachmentRequirements;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attachment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttachmentApi - functional programming interface
 * @export
 */
export const AttachmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AttachmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentAttachmentIdDelete(attachmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentAttachmentIdDelete(attachmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {number} documentId 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentAttachmentIdDocumentsDocumentIdGet(attachmentId: number, documentId: number, facilityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentAccessDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentAttachmentIdDocumentsDocumentIdGet(attachmentId, documentId, facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentAttachmentIdDocumentsGet(attachmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Document>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentAttachmentIdDocumentsGet(attachmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentAttachmentIdGet(attachmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentAttachmentIdGet(attachmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {boolean} ignoreAttachmentRequirements 
         * @param {Attachment} attachment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentAttachmentIdPut(attachmentId: number, ignoreAttachmentRequirements: boolean, attachment: Attachment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentAttachmentIdPut(attachmentId, ignoreAttachmentRequirements, attachment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentAttachmentIdSendPost(attachmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendAttachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentAttachmentIdSendPost(attachmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentAttachmentIdStatusPut(attachmentId: number, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachmentStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentAttachmentIdStatusPut(attachmentId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentFacilityFacilityIdGet(facilityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttachmentListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentFacilityFacilityIdGet(facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} ignoreAttachmentRequirements 
         * @param {Attachment} attachment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentPost(ignoreAttachmentRequirements: boolean, attachment: Attachment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentPost(ignoreAttachmentRequirements, attachment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AttachmentApi - factory interface
 * @export
 */
export const AttachmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AttachmentApiFp(configuration)
    return {
        /**
         * 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentAttachmentIdDelete(attachmentId: number, options?: any): AxiosPromise<void> {
            return localVarFp.attachmentAttachmentIdDelete(attachmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {number} documentId 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentAttachmentIdDocumentsDocumentIdGet(attachmentId: number, documentId: number, facilityId: string, options?: any): AxiosPromise<DocumentAccessDetails> {
            return localVarFp.attachmentAttachmentIdDocumentsDocumentIdGet(attachmentId, documentId, facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentAttachmentIdDocumentsGet(attachmentId: number, options?: any): AxiosPromise<Array<Document>> {
            return localVarFp.attachmentAttachmentIdDocumentsGet(attachmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentAttachmentIdGet(attachmentId: number, options?: any): AxiosPromise<Attachment> {
            return localVarFp.attachmentAttachmentIdGet(attachmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {boolean} ignoreAttachmentRequirements 
         * @param {Attachment} attachment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentAttachmentIdPut(attachmentId: number, ignoreAttachmentRequirements: boolean, attachment: Attachment, options?: any): AxiosPromise<Attachment> {
            return localVarFp.attachmentAttachmentIdPut(attachmentId, ignoreAttachmentRequirements, attachment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentAttachmentIdSendPost(attachmentId: number, options?: any): AxiosPromise<SendAttachment> {
            return localVarFp.attachmentAttachmentIdSendPost(attachmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentAttachmentIdStatusPut(attachmentId: number, body: string, options?: any): AxiosPromise<AttachmentStatus> {
            return localVarFp.attachmentAttachmentIdStatusPut(attachmentId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentFacilityFacilityIdGet(facilityId: string, options?: any): AxiosPromise<Array<AttachmentListItem>> {
            return localVarFp.attachmentFacilityFacilityIdGet(facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} ignoreAttachmentRequirements 
         * @param {Attachment} attachment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentPost(ignoreAttachmentRequirements: boolean, attachment: Attachment, options?: any): AxiosPromise<Attachment> {
            return localVarFp.attachmentPost(ignoreAttachmentRequirements, attachment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AttachmentApi - object-oriented interface
 * @export
 * @class AttachmentApi
 * @extends {BaseAPI}
 */
export class AttachmentApi extends BaseAPI {
    /**
     * 
     * @param {number} attachmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentAttachmentIdDelete(attachmentId: number, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentAttachmentIdDelete(attachmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} attachmentId 
     * @param {number} documentId 
     * @param {string} facilityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentAttachmentIdDocumentsDocumentIdGet(attachmentId: number, documentId: number, facilityId: string, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentAttachmentIdDocumentsDocumentIdGet(attachmentId, documentId, facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} attachmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentAttachmentIdDocumentsGet(attachmentId: number, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentAttachmentIdDocumentsGet(attachmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} attachmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentAttachmentIdGet(attachmentId: number, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentAttachmentIdGet(attachmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} attachmentId 
     * @param {boolean} ignoreAttachmentRequirements 
     * @param {Attachment} attachment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentAttachmentIdPut(attachmentId: number, ignoreAttachmentRequirements: boolean, attachment: Attachment, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentAttachmentIdPut(attachmentId, ignoreAttachmentRequirements, attachment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} attachmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentAttachmentIdSendPost(attachmentId: number, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentAttachmentIdSendPost(attachmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} attachmentId 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentAttachmentIdStatusPut(attachmentId: number, body: string, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentAttachmentIdStatusPut(attachmentId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} facilityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentFacilityFacilityIdGet(facilityId: string, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentFacilityFacilityIdGet(facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} ignoreAttachmentRequirements 
     * @param {Attachment} attachment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentPost(ignoreAttachmentRequirements: boolean, attachment: Attachment, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentPost(ignoreAttachmentRequirements, attachment, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AttachmentSentApi - axios parameter creator
 * @export
 */
export const AttachmentSentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} facilityId 
         * @param {SentAttachmentsRequest} sentAttachmentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentSentFacilityFacilityIdPost: async (facilityId: string, sentAttachmentsRequest: SentAttachmentsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('attachmentSentFacilityFacilityIdPost', 'facilityId', facilityId)
            // verify required parameter 'sentAttachmentsRequest' is not null or undefined
            assertParamExists('attachmentSentFacilityFacilityIdPost', 'sentAttachmentsRequest', sentAttachmentsRequest)
            const localVarPath = `/attachment-sent/facility/{facilityId}`
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sentAttachmentsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} hmfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentSentHmfIdDocumentsGet: async (hmfId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hmfId' is not null or undefined
            assertParamExists('attachmentSentHmfIdDocumentsGet', 'hmfId', hmfId)
            const localVarPath = `/attachment-sent/{hmfId}/documents`
                .replace(`{${"hmfId"}}`, encodeURIComponent(String(hmfId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} hmfId 
         * @param {number} imageSequence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentSentHmfIdDocumentsImageSequenceGet: async (hmfId: number, imageSequence: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hmfId' is not null or undefined
            assertParamExists('attachmentSentHmfIdDocumentsImageSequenceGet', 'hmfId', hmfId)
            // verify required parameter 'imageSequence' is not null or undefined
            assertParamExists('attachmentSentHmfIdDocumentsImageSequenceGet', 'imageSequence', imageSequence)
            const localVarPath = `/attachment-sent/{hmfId}/documents/{imageSequence}`
                .replace(`{${"hmfId"}}`, encodeURIComponent(String(hmfId)))
                .replace(`{${"imageSequence"}}`, encodeURIComponent(String(imageSequence)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} hmfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentSentHmfIdGet: async (hmfId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hmfId' is not null or undefined
            assertParamExists('attachmentSentHmfIdGet', 'hmfId', hmfId)
            const localVarPath = `/attachment-sent/{hmfId}`
                .replace(`{${"hmfId"}}`, encodeURIComponent(String(hmfId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} hmfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentSentHmfIdReceiptGet: async (hmfId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hmfId' is not null or undefined
            assertParamExists('attachmentSentHmfIdReceiptGet', 'hmfId', hmfId)
            const localVarPath = `/attachment-sent/{hmfId}/receipt`
                .replace(`{${"hmfId"}}`, encodeURIComponent(String(hmfId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} hmfId 
         * @param {string} referenceNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentSentHmfIdResendPost: async (hmfId: number, referenceNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hmfId' is not null or undefined
            assertParamExists('attachmentSentHmfIdResendPost', 'hmfId', hmfId)
            // verify required parameter 'referenceNumber' is not null or undefined
            assertParamExists('attachmentSentHmfIdResendPost', 'referenceNumber', referenceNumber)
            const localVarPath = `/attachment-sent/{hmfId}/resend`
                .replace(`{${"hmfId"}}`, encodeURIComponent(String(hmfId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttachmentSentApi - functional programming interface
 * @export
 */
export const AttachmentSentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AttachmentSentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} facilityId 
         * @param {SentAttachmentsRequest} sentAttachmentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentSentFacilityFacilityIdPost(facilityId: string, sentAttachmentsRequest: SentAttachmentsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SentAttachmentListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentSentFacilityFacilityIdPost(facilityId, sentAttachmentsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} hmfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentSentHmfIdDocumentsGet(hmfId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SentAttachmentDocument>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentSentHmfIdDocumentsGet(hmfId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} hmfId 
         * @param {number} imageSequence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentSentHmfIdDocumentsImageSequenceGet(hmfId: number, imageSequence: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentAccessDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentSentHmfIdDocumentsImageSequenceGet(hmfId, imageSequence, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} hmfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentSentHmfIdGet(hmfId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SentAttachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentSentHmfIdGet(hmfId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} hmfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentSentHmfIdReceiptGet(hmfId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentAccessDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentSentHmfIdReceiptGet(hmfId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} hmfId 
         * @param {string} referenceNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentSentHmfIdResendPost(hmfId: number, referenceNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResendAttachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentSentHmfIdResendPost(hmfId, referenceNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AttachmentSentApi - factory interface
 * @export
 */
export const AttachmentSentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AttachmentSentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} facilityId 
         * @param {SentAttachmentsRequest} sentAttachmentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentSentFacilityFacilityIdPost(facilityId: string, sentAttachmentsRequest: SentAttachmentsRequest, options?: any): AxiosPromise<Array<SentAttachmentListItem>> {
            return localVarFp.attachmentSentFacilityFacilityIdPost(facilityId, sentAttachmentsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} hmfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentSentHmfIdDocumentsGet(hmfId: number, options?: any): AxiosPromise<Array<SentAttachmentDocument>> {
            return localVarFp.attachmentSentHmfIdDocumentsGet(hmfId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} hmfId 
         * @param {number} imageSequence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentSentHmfIdDocumentsImageSequenceGet(hmfId: number, imageSequence: number, options?: any): AxiosPromise<DocumentAccessDetails> {
            return localVarFp.attachmentSentHmfIdDocumentsImageSequenceGet(hmfId, imageSequence, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} hmfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentSentHmfIdGet(hmfId: number, options?: any): AxiosPromise<SentAttachment> {
            return localVarFp.attachmentSentHmfIdGet(hmfId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} hmfId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentSentHmfIdReceiptGet(hmfId: number, options?: any): AxiosPromise<DocumentAccessDetails> {
            return localVarFp.attachmentSentHmfIdReceiptGet(hmfId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} hmfId 
         * @param {string} referenceNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentSentHmfIdResendPost(hmfId: number, referenceNumber: string, options?: any): AxiosPromise<ResendAttachment> {
            return localVarFp.attachmentSentHmfIdResendPost(hmfId, referenceNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AttachmentSentApi - object-oriented interface
 * @export
 * @class AttachmentSentApi
 * @extends {BaseAPI}
 */
export class AttachmentSentApi extends BaseAPI {
    /**
     * 
     * @param {string} facilityId 
     * @param {SentAttachmentsRequest} sentAttachmentsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentSentApi
     */
    public attachmentSentFacilityFacilityIdPost(facilityId: string, sentAttachmentsRequest: SentAttachmentsRequest, options?: AxiosRequestConfig) {
        return AttachmentSentApiFp(this.configuration).attachmentSentFacilityFacilityIdPost(facilityId, sentAttachmentsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} hmfId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentSentApi
     */
    public attachmentSentHmfIdDocumentsGet(hmfId: number, options?: AxiosRequestConfig) {
        return AttachmentSentApiFp(this.configuration).attachmentSentHmfIdDocumentsGet(hmfId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} hmfId 
     * @param {number} imageSequence 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentSentApi
     */
    public attachmentSentHmfIdDocumentsImageSequenceGet(hmfId: number, imageSequence: number, options?: AxiosRequestConfig) {
        return AttachmentSentApiFp(this.configuration).attachmentSentHmfIdDocumentsImageSequenceGet(hmfId, imageSequence, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} hmfId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentSentApi
     */
    public attachmentSentHmfIdGet(hmfId: number, options?: AxiosRequestConfig) {
        return AttachmentSentApiFp(this.configuration).attachmentSentHmfIdGet(hmfId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} hmfId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentSentApi
     */
    public attachmentSentHmfIdReceiptGet(hmfId: number, options?: AxiosRequestConfig) {
        return AttachmentSentApiFp(this.configuration).attachmentSentHmfIdReceiptGet(hmfId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} hmfId 
     * @param {string} referenceNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentSentApi
     */
    public attachmentSentHmfIdResendPost(hmfId: number, referenceNumber: string, options?: AxiosRequestConfig) {
        return AttachmentSentApiFp(this.configuration).attachmentSentHmfIdResendPost(hmfId, referenceNumber, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationTrellisPost: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('authenticationTrellisPost', 'customerId', customerId)
            const localVarPath = `/authentication/trellis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationTrellisPost(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationTrellisPost(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationTrellisPost(customerId: number, options?: any): AxiosPromise<TokenResponse> {
            return localVarFp.authenticationTrellisPost(customerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationTrellisPost(customerId: number, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationTrellisPost(customerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {ConvertImageRequest} convertImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentConvertImageS3Post: async (customerId: number, convertImageRequest: ConvertImageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('documentConvertImageS3Post', 'customerId', customerId)
            // verify required parameter 'convertImageRequest' is not null or undefined
            assertParamExists('documentConvertImageS3Post', 'convertImageRequest', convertImageRequest)
            const localVarPath = `/document/convert-image-s3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(convertImageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} documentId 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentDocumentIdStoragePathGet: async (documentId: number, facilityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('documentDocumentIdStoragePathGet', 'documentId', documentId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('documentDocumentIdStoragePathGet', 'facilityId', facilityId)
            const localVarPath = `/document/{documentId}/storage-path`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (facilityId !== undefined) {
                localVarQueryParameter['facilityId'] = facilityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentStoragePathGet: async (customerId: number, facilityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('documentStoragePathGet', 'customerId', customerId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('documentStoragePathGet', 'facilityId', facilityId)
            const localVarPath = `/document/storage-path`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (facilityId !== undefined) {
                localVarQueryParameter['facilityId'] = facilityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {ConvertImageRequest} convertImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentConvertImageS3Post(customerId: number, convertImageRequest: ConvertImageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentAccessDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentConvertImageS3Post(customerId, convertImageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} documentId 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentDocumentIdStoragePathGet(documentId: number, facilityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentAccessDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentDocumentIdStoragePathGet(documentId, facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentStoragePathGet(customerId: number, facilityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentAccessDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentStoragePathGet(customerId, facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentApiFp(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {ConvertImageRequest} convertImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentConvertImageS3Post(customerId: number, convertImageRequest: ConvertImageRequest, options?: any): AxiosPromise<DocumentAccessDetails> {
            return localVarFp.documentConvertImageS3Post(customerId, convertImageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} documentId 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentDocumentIdStoragePathGet(documentId: number, facilityId: string, options?: any): AxiosPromise<DocumentAccessDetails> {
            return localVarFp.documentDocumentIdStoragePathGet(documentId, facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentStoragePathGet(customerId: number, facilityId: string, options?: any): AxiosPromise<DocumentAccessDetails> {
            return localVarFp.documentStoragePathGet(customerId, facilityId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
    /**
     * 
     * @param {number} customerId 
     * @param {ConvertImageRequest} convertImageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentConvertImageS3Post(customerId: number, convertImageRequest: ConvertImageRequest, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentConvertImageS3Post(customerId, convertImageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} documentId 
     * @param {string} facilityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentDocumentIdStoragePathGet(documentId: number, facilityId: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentDocumentIdStoragePathGet(documentId, facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {string} facilityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentStoragePathGet(customerId: number, facilityId: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentStoragePathGet(customerId, facilityId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientApi - axios parameter creator
 * @export
 */
export const PatientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientPatientIdGet: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('patientPatientIdGet', 'patientId', patientId)
            const localVarPath = `/patient/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} insuranceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientPatientIdInsuranceInsuranceIdGet: async (patientId: number, insuranceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('patientPatientIdInsuranceInsuranceIdGet', 'patientId', patientId)
            // verify required parameter 'insuranceId' is not null or undefined
            assertParamExists('patientPatientIdInsuranceInsuranceIdGet', 'insuranceId', insuranceId)
            const localVarPath = `/patient/{patientId}/insurance/{insuranceId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"insuranceId"}}`, encodeURIComponent(String(insuranceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} insuranceId 
         * @param {PatientInsuranceRequest} patientInsuranceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientPatientIdInsuranceInsuranceIdPut: async (patientId: number, insuranceId: number, patientInsuranceRequest: PatientInsuranceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('patientPatientIdInsuranceInsuranceIdPut', 'patientId', patientId)
            // verify required parameter 'insuranceId' is not null or undefined
            assertParamExists('patientPatientIdInsuranceInsuranceIdPut', 'insuranceId', insuranceId)
            // verify required parameter 'patientInsuranceRequest' is not null or undefined
            assertParamExists('patientPatientIdInsuranceInsuranceIdPut', 'patientInsuranceRequest', patientInsuranceRequest)
            const localVarPath = `/patient/{patientId}/insurance/{insuranceId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"insuranceId"}}`, encodeURIComponent(String(insuranceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientInsuranceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {PatientInsuranceRequest} patientInsuranceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientPatientIdInsurancePost: async (patientId: number, patientInsuranceRequest: PatientInsuranceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('patientPatientIdInsurancePost', 'patientId', patientId)
            // verify required parameter 'patientInsuranceRequest' is not null or undefined
            assertParamExists('patientPatientIdInsurancePost', 'patientInsuranceRequest', patientInsuranceRequest)
            const localVarPath = `/patient/{patientId}/insurance`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientInsuranceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Patient} patient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientPatientIdPut: async (patientId: number, patient: Patient, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('patientPatientIdPut', 'patientId', patientId)
            // verify required parameter 'patient' is not null or undefined
            assertParamExists('patientPatientIdPut', 'patient', patient)
            const localVarPath = `/patient/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} facilityId 
         * @param {PatientAssociationRequest} patientAssociationRequest 
         * @param {string} [vdsPatientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientPost: async (customerId: number, facilityId: string, patientAssociationRequest: PatientAssociationRequest, vdsPatientId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patientPost', 'customerId', customerId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('patientPost', 'facilityId', facilityId)
            // verify required parameter 'patientAssociationRequest' is not null or undefined
            assertParamExists('patientPost', 'patientAssociationRequest', patientAssociationRequest)
            const localVarPath = `/patient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (facilityId !== undefined) {
                localVarQueryParameter['facilityId'] = facilityId;
            }

            if (vdsPatientId !== undefined) {
                localVarQueryParameter['vdsPatientId'] = vdsPatientId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientApi - functional programming interface
 * @export
 */
export const PatientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientPatientIdGet(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Patient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientPatientIdGet(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} insuranceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientPatientIdInsuranceInsuranceIdGet(patientId: number, insuranceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientInsuranceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientPatientIdInsuranceInsuranceIdGet(patientId, insuranceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} insuranceId 
         * @param {PatientInsuranceRequest} patientInsuranceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientPatientIdInsuranceInsuranceIdPut(patientId: number, insuranceId: number, patientInsuranceRequest: PatientInsuranceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientInsuranceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientPatientIdInsuranceInsuranceIdPut(patientId, insuranceId, patientInsuranceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {PatientInsuranceRequest} patientInsuranceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientPatientIdInsurancePost(patientId: number, patientInsuranceRequest: PatientInsuranceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientInsuranceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientPatientIdInsurancePost(patientId, patientInsuranceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Patient} patient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientPatientIdPut(patientId: number, patient: Patient, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Patient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientPatientIdPut(patientId, patient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} facilityId 
         * @param {PatientAssociationRequest} patientAssociationRequest 
         * @param {string} [vdsPatientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientPost(customerId: number, facilityId: string, patientAssociationRequest: PatientAssociationRequest, vdsPatientId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientPost(customerId, facilityId, patientAssociationRequest, vdsPatientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientApi - factory interface
 * @export
 */
export const PatientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientApiFp(configuration)
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientPatientIdGet(patientId: number, options?: any): AxiosPromise<Patient> {
            return localVarFp.patientPatientIdGet(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} insuranceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientPatientIdInsuranceInsuranceIdGet(patientId: number, insuranceId: number, options?: any): AxiosPromise<PatientInsuranceResponse> {
            return localVarFp.patientPatientIdInsuranceInsuranceIdGet(patientId, insuranceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} insuranceId 
         * @param {PatientInsuranceRequest} patientInsuranceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientPatientIdInsuranceInsuranceIdPut(patientId: number, insuranceId: number, patientInsuranceRequest: PatientInsuranceRequest, options?: any): AxiosPromise<PatientInsuranceResponse> {
            return localVarFp.patientPatientIdInsuranceInsuranceIdPut(patientId, insuranceId, patientInsuranceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {PatientInsuranceRequest} patientInsuranceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientPatientIdInsurancePost(patientId: number, patientInsuranceRequest: PatientInsuranceRequest, options?: any): AxiosPromise<PatientInsuranceResponse> {
            return localVarFp.patientPatientIdInsurancePost(patientId, patientInsuranceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Patient} patient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientPatientIdPut(patientId: number, patient: Patient, options?: any): AxiosPromise<Patient> {
            return localVarFp.patientPatientIdPut(patientId, patient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} facilityId 
         * @param {PatientAssociationRequest} patientAssociationRequest 
         * @param {string} [vdsPatientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientPost(customerId: number, facilityId: string, patientAssociationRequest: PatientAssociationRequest, vdsPatientId?: string, options?: any): AxiosPromise<PatientAssociationResponse> {
            return localVarFp.patientPost(customerId, facilityId, patientAssociationRequest, vdsPatientId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientApi - object-oriented interface
 * @export
 * @class PatientApi
 * @extends {BaseAPI}
 */
export class PatientApi extends BaseAPI {
    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientPatientIdGet(patientId: number, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).patientPatientIdGet(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {number} insuranceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientPatientIdInsuranceInsuranceIdGet(patientId: number, insuranceId: number, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).patientPatientIdInsuranceInsuranceIdGet(patientId, insuranceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {number} insuranceId 
     * @param {PatientInsuranceRequest} patientInsuranceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientPatientIdInsuranceInsuranceIdPut(patientId: number, insuranceId: number, patientInsuranceRequest: PatientInsuranceRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).patientPatientIdInsuranceInsuranceIdPut(patientId, insuranceId, patientInsuranceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {PatientInsuranceRequest} patientInsuranceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientPatientIdInsurancePost(patientId: number, patientInsuranceRequest: PatientInsuranceRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).patientPatientIdInsurancePost(patientId, patientInsuranceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {Patient} patient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientPatientIdPut(patientId: number, patient: Patient, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).patientPatientIdPut(patientId, patient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {string} facilityId 
     * @param {PatientAssociationRequest} patientAssociationRequest 
     * @param {string} [vdsPatientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientPost(customerId: number, facilityId: string, patientAssociationRequest: PatientAssociationRequest, vdsPatientId?: string, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).patientPost(customerId, facilityId, patientAssociationRequest, vdsPatientId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProviderApi - axios parameter creator
 * @export
 */
export const ProviderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerFacilityFacilityIdGet: async (facilityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('providerFacilityFacilityIdGet', 'facilityId', facilityId)
            const localVarPath = `/provider/facility/{facilityId}`
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} trellisProviderId 
         * @param {ProviderRequest} providerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerPost: async (customerId: number, trellisProviderId: number, providerRequest: ProviderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('providerPost', 'customerId', customerId)
            // verify required parameter 'trellisProviderId' is not null or undefined
            assertParamExists('providerPost', 'trellisProviderId', trellisProviderId)
            // verify required parameter 'providerRequest' is not null or undefined
            assertParamExists('providerPost', 'providerRequest', providerRequest)
            const localVarPath = `/provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (trellisProviderId !== undefined) {
                localVarQueryParameter['trellisProviderId'] = trellisProviderId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(providerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerProviderIdDelete: async (providerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('providerProviderIdDelete', 'providerId', providerId)
            const localVarPath = `/provider/{providerId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} providerId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerProviderIdGet: async (providerId: number, customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('providerProviderIdGet', 'providerId', providerId)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('providerProviderIdGet', 'customerId', customerId)
            const localVarPath = `/provider/{providerId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} providerId 
         * @param {ProviderRequest} providerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerProviderIdPut: async (providerId: number, providerRequest: ProviderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('providerProviderIdPut', 'providerId', providerId)
            // verify required parameter 'providerRequest' is not null or undefined
            assertParamExists('providerProviderIdPut', 'providerRequest', providerRequest)
            const localVarPath = `/provider/{providerId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(providerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProviderApi - functional programming interface
 * @export
 */
export const ProviderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProviderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerFacilityFacilityIdGet(facilityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Provider>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerFacilityFacilityIdGet(facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} trellisProviderId 
         * @param {ProviderRequest} providerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerPost(customerId: number, trellisProviderId: number, providerRequest: ProviderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerPost(customerId, trellisProviderId, providerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerProviderIdDelete(providerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerProviderIdDelete(providerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} providerId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerProviderIdGet(providerId: number, customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerProviderIdGet(providerId, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} providerId 
         * @param {ProviderRequest} providerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerProviderIdPut(providerId: number, providerRequest: ProviderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Provider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerProviderIdPut(providerId, providerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProviderApi - factory interface
 * @export
 */
export const ProviderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProviderApiFp(configuration)
    return {
        /**
         * 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerFacilityFacilityIdGet(facilityId: string, options?: any): AxiosPromise<Array<Provider>> {
            return localVarFp.providerFacilityFacilityIdGet(facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} trellisProviderId 
         * @param {ProviderRequest} providerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerPost(customerId: number, trellisProviderId: number, providerRequest: ProviderRequest, options?: any): AxiosPromise<ProviderAssociationResponse> {
            return localVarFp.providerPost(customerId, trellisProviderId, providerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerProviderIdDelete(providerId: number, options?: any): AxiosPromise<void> {
            return localVarFp.providerProviderIdDelete(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} providerId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerProviderIdGet(providerId: number, customerId: number, options?: any): AxiosPromise<ProviderAssociationResponse> {
            return localVarFp.providerProviderIdGet(providerId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} providerId 
         * @param {ProviderRequest} providerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerProviderIdPut(providerId: number, providerRequest: ProviderRequest, options?: any): AxiosPromise<Provider> {
            return localVarFp.providerProviderIdPut(providerId, providerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProviderApi - object-oriented interface
 * @export
 * @class ProviderApi
 * @extends {BaseAPI}
 */
export class ProviderApi extends BaseAPI {
    /**
     * 
     * @param {string} facilityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerFacilityFacilityIdGet(facilityId: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerFacilityFacilityIdGet(facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {number} trellisProviderId 
     * @param {ProviderRequest} providerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerPost(customerId: number, trellisProviderId: number, providerRequest: ProviderRequest, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerPost(customerId, trellisProviderId, providerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerProviderIdDelete(providerId: number, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerProviderIdDelete(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} providerId 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerProviderIdGet(providerId: number, customerId: number, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerProviderIdGet(providerId, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} providerId 
     * @param {ProviderRequest} providerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerProviderIdPut(providerId: number, providerRequest: ProviderRequest, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerProviderIdPut(providerId, providerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RecipientApi - axios parameter creator
 * @export
 */
export const RecipientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} carrierId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recipientConvertCarrierIdGet: async (carrierId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'carrierId' is not null or undefined
            assertParamExists('recipientConvertCarrierIdGet', 'carrierId', carrierId)
            const localVarPath = `/recipient/convert/{carrierId}`
                .replace(`{${"carrierId"}}`, encodeURIComponent(String(carrierId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [facilityId] 
         * @param {boolean} [isNeaOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recipientListGet: async (facilityId?: string, isNeaOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/recipient/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (facilityId !== undefined) {
                localVarQueryParameter['facilityId'] = facilityId;
            }

            if (isNeaOnly !== undefined) {
                localVarQueryParameter['isNeaOnly'] = isNeaOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} masterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recipientMasterIdAttachmentRulesGet: async (masterId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'masterId' is not null or undefined
            assertParamExists('recipientMasterIdAttachmentRulesGet', 'masterId', masterId)
            const localVarPath = `/recipient/{masterId}/attachment-rules`
                .replace(`{${"masterId"}}`, encodeURIComponent(String(masterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} masterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recipientMasterIdGet: async (masterId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'masterId' is not null or undefined
            assertParamExists('recipientMasterIdGet', 'masterId', masterId)
            const localVarPath = `/recipient/{masterId}`
                .replace(`{${"masterId"}}`, encodeURIComponent(String(masterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecipientApi - functional programming interface
 * @export
 */
export const RecipientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecipientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} carrierId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recipientConvertCarrierIdGet(carrierId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recipient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recipientConvertCarrierIdGet(carrierId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [facilityId] 
         * @param {boolean} [isNeaOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recipientListGet(facilityId?: string, isNeaOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Carrier>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recipientListGet(facilityId, isNeaOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} masterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recipientMasterIdAttachmentRulesGet(masterId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipientAttachmentRules>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recipientMasterIdAttachmentRulesGet(masterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} masterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recipientMasterIdGet(masterId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recipient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recipientMasterIdGet(masterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecipientApi - factory interface
 * @export
 */
export const RecipientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecipientApiFp(configuration)
    return {
        /**
         * 
         * @param {string} carrierId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recipientConvertCarrierIdGet(carrierId: string, options?: any): AxiosPromise<Recipient> {
            return localVarFp.recipientConvertCarrierIdGet(carrierId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [facilityId] 
         * @param {boolean} [isNeaOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recipientListGet(facilityId?: string, isNeaOnly?: boolean, options?: any): AxiosPromise<Array<Carrier>> {
            return localVarFp.recipientListGet(facilityId, isNeaOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} masterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recipientMasterIdAttachmentRulesGet(masterId: number, options?: any): AxiosPromise<RecipientAttachmentRules> {
            return localVarFp.recipientMasterIdAttachmentRulesGet(masterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} masterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recipientMasterIdGet(masterId: number, options?: any): AxiosPromise<Recipient> {
            return localVarFp.recipientMasterIdGet(masterId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecipientApi - object-oriented interface
 * @export
 * @class RecipientApi
 * @extends {BaseAPI}
 */
export class RecipientApi extends BaseAPI {
    /**
     * 
     * @param {string} carrierId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipientApi
     */
    public recipientConvertCarrierIdGet(carrierId: string, options?: AxiosRequestConfig) {
        return RecipientApiFp(this.configuration).recipientConvertCarrierIdGet(carrierId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [facilityId] 
     * @param {boolean} [isNeaOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipientApi
     */
    public recipientListGet(facilityId?: string, isNeaOnly?: boolean, options?: AxiosRequestConfig) {
        return RecipientApiFp(this.configuration).recipientListGet(facilityId, isNeaOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} masterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipientApi
     */
    public recipientMasterIdAttachmentRulesGet(masterId: number, options?: AxiosRequestConfig) {
        return RecipientApiFp(this.configuration).recipientMasterIdAttachmentRulesGet(masterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} masterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipientApi
     */
    public recipientMasterIdGet(masterId: number, options?: AxiosRequestConfig) {
        return RecipientApiFp(this.configuration).recipientMasterIdGet(masterId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScreenCaptureApi - axios parameter creator
 * @export
 */
export const ScreenCaptureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {string} documentName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        screenCaptureDocumentNameGet: async (customerId: number, documentName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('screenCaptureDocumentNameGet', 'customerId', customerId)
            // verify required parameter 'documentName' is not null or undefined
            assertParamExists('screenCaptureDocumentNameGet', 'documentName', documentName)
            const localVarPath = `/screen-capture/{documentName}`
                .replace(`{${"documentName"}}`, encodeURIComponent(String(documentName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        screenCaptureStoragePathGet: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('screenCaptureStoragePathGet', 'customerId', customerId)
            const localVarPath = `/screen-capture/storage-path`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScreenCaptureApi - functional programming interface
 * @export
 */
export const ScreenCaptureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScreenCaptureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {string} documentName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async screenCaptureDocumentNameGet(customerId: number, documentName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.screenCaptureDocumentNameGet(customerId, documentName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async screenCaptureStoragePathGet(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentAccessDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.screenCaptureStoragePathGet(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScreenCaptureApi - factory interface
 * @export
 */
export const ScreenCaptureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScreenCaptureApiFp(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {string} documentName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        screenCaptureDocumentNameGet(customerId: number, documentName: string, options?: any): AxiosPromise<void> {
            return localVarFp.screenCaptureDocumentNameGet(customerId, documentName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        screenCaptureStoragePathGet(customerId: number, options?: any): AxiosPromise<DocumentAccessDetails> {
            return localVarFp.screenCaptureStoragePathGet(customerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScreenCaptureApi - object-oriented interface
 * @export
 * @class ScreenCaptureApi
 * @extends {BaseAPI}
 */
export class ScreenCaptureApi extends BaseAPI {
    /**
     * 
     * @param {number} customerId 
     * @param {string} documentName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreenCaptureApi
     */
    public screenCaptureDocumentNameGet(customerId: number, documentName: string, options?: AxiosRequestConfig) {
        return ScreenCaptureApiFp(this.configuration).screenCaptureDocumentNameGet(customerId, documentName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreenCaptureApi
     */
    public screenCaptureStoragePathGet(customerId: number, options?: AxiosRequestConfig) {
        return ScreenCaptureApiFp(this.configuration).screenCaptureStoragePathGet(customerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VdsPatientApi - axios parameter creator
 * @export
 */
export const VdsPatientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {PatientsSearchVdsRequest} patientsSearchVdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vdsPatientsSearchPost: async (customerId: number, patientsSearchVdsRequest: PatientsSearchVdsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('vdsPatientsSearchPost', 'customerId', customerId)
            // verify required parameter 'patientsSearchVdsRequest' is not null or undefined
            assertParamExists('vdsPatientsSearchPost', 'patientsSearchVdsRequest', patientsSearchVdsRequest)
            const localVarPath = `/vds/patients/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientsSearchVdsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VdsPatientApi - functional programming interface
 * @export
 */
export const VdsPatientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VdsPatientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {PatientsSearchVdsRequest} patientsSearchVdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vdsPatientsSearchPost(customerId: number, patientsSearchVdsRequest: PatientsSearchVdsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientVds>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vdsPatientsSearchPost(customerId, patientsSearchVdsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VdsPatientApi - factory interface
 * @export
 */
export const VdsPatientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VdsPatientApiFp(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {PatientsSearchVdsRequest} patientsSearchVdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vdsPatientsSearchPost(customerId: number, patientsSearchVdsRequest: PatientsSearchVdsRequest, options?: any): AxiosPromise<Array<PatientVds>> {
            return localVarFp.vdsPatientsSearchPost(customerId, patientsSearchVdsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VdsPatientApi - object-oriented interface
 * @export
 * @class VdsPatientApi
 * @extends {BaseAPI}
 */
export class VdsPatientApi extends BaseAPI {
    /**
     * 
     * @param {number} customerId 
     * @param {PatientsSearchVdsRequest} patientsSearchVdsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VdsPatientApi
     */
    public vdsPatientsSearchPost(customerId: number, patientsSearchVdsRequest: PatientsSearchVdsRequest, options?: AxiosRequestConfig) {
        return VdsPatientApiFp(this.configuration).vdsPatientsSearchPost(customerId, patientsSearchVdsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


