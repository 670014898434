import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { FC, ReactElement } from 'react'
import { Link } from 'react-router-dom'

import { Form, NewFormInput } from 'ui'
import { newValidateEmail } from 'utils'

import { LoginFormProps } from '../../Login'
import LoginErrorBox from '../LoginErrorBox/LoginErrorBox'
import styles from './DentalIntelligenceLoginForm.module.scss'

import dentalIntelligenceLogo from 'trellis:assets/di-dark.png'
import loginMarketingImgDI from 'trellis:assets/di-login-marketing-image.jpg'

const DentalIntelligenceLogin: FC<LoginFormProps> = ({
  formData,
  formErrors,
  handleFormChange,
  handleFormSubmit,
  loginError,
  setFormValidations,
  verifying,
}): ReactElement | null => {
  const openDICustomerCommunity = () => {
    const url = 'https://dentalintel.my.site.com/DiCustomerCommunity/s/'
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')

    if (newWindow) {
      newWindow.opener = null
    }
  }

  return (
    <main className={styles['login--dental-intelligence']}>
      <section className={styles['login-form-wrapper']}>
        <img
          className={`${styles['login-logo']} ${styles['login-logo--di']}`}
          alt='Dental Intelligence insurance'
          src={dentalIntelligenceLogo}
        />
        <p className={styles['login-title']}>Log into Insurance</p>
        <LoginErrorBox loginError={loginError} />
        <Form
          autoComplete='on'
          data-testid='dental-intelligence-login-form'
          method='post'
          name='loginForm'
          onSubmit={handleFormSubmit}
        >
          <NewFormInput
            autoFocus
            className={styles['form__input--dental-intelligence']}
            errors={formErrors}
            label='User name or email'
            name='username'
            onChange={handleFormChange('username')}
            required
            setValidations={setFormValidations}
            validator={newValidateEmail}
            value={formData.username}
            data-testid='username'
          />
          <NewFormInput
            errors={formErrors}
            name='password'
            type='password'
            label='Password'
            onChange={handleFormChange('password')}
            required
            setValidations={setFormValidations}
            showPasswordVisibilityIcon={true}
            value={formData.password}
            data-testid='password'
          />
          <button
            className={`${styles['di-button']} ${
              verifying ? styles['di-button--loading'] : ''
            }`}
            disabled={verifying}
            type='submit'
          >
            {verifying && (
              <Spin
                className='mr-050'
                indicator={
                  <LoadingOutlined
                    style={{ color: '#fff' }}
                    spin
                  />
                }
              />
            )}
            Login
          </button>
        </Form>
        <Link
          className={`${styles['text-link']} ${styles['forgot-password']}`}
          to='/Account/ForgotPassword'
        >
          Forgot My Password?
        </Link>
        <p className={styles['login-link']}>
          By clicking Login you agree to our
          <a
            className={`${styles['text-link']} ml-025`}
            href='https://vynedental.com/terms/'
            rel='noreferrer'
            target='_blank'
          >
            Terms of Service
          </a>
        </p>
      </section>
      <section className={styles['login-marketing-wrapper']}>
        <img
          className={styles['login-marketing-image']}
          onClick={openDICustomerCommunity}
          src={loginMarketingImgDI}
        />
      </section>
    </main>
  )
}

export default DentalIntelligenceLogin
