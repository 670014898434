import { AutoComplete, Form } from 'antd'
import { Rule } from 'antd/lib/form'
import React, { FC, useEffect, useState } from 'react'

import { SelectOption, spacesToProperty } from 'utils'

import '../../_formStyles.scss'

// TODO: update to handle nested form items

// NOTE: form items also accept dependencies if validation is dependent on another field
// TODO: update to handle dependencies (e.g. confirm password is dependent on password)

interface FormAutoCompleteProps {
  className?: string
  customHandler?: (
    value: string,
    selectedOptions?: SelectOption | SelectOption[],
  ) => void
  disabled?: boolean
  floatLabel?: boolean
  label?: string
  name: string
  onSearchHandler?: (value: string) => void
  options: SelectOption[]
  placeholder?: string
  required?: boolean
  validator?: (value: string, options: SelectOption[]) => void
  valueHandler?: (value: string) => string
}

export const FormAutoComplete: FC<FormAutoCompleteProps> = ({
  className,
  customHandler,
  disabled = false,
  floatLabel = true,
  label,
  name,
  onSearchHandler,
  options,
  placeholder = null,
  required = false,
  validator,
  valueHandler,
}) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [initialLoad, setInitialLoad] = useState<boolean>(true)
  const [isLabelFloating, setIsLabelFloating] = useState<boolean>(false)

  const form = Form.useFormInstance()

  useEffect(() => {
    if (initialLoad && (form.getFieldValue(name) || placeholder)) {
      setInitialLoad(false)
      setIsLabelFloating(true)
    }
    setInputValue(form.getFieldValue(name))
  }, [form.getFieldValue(name)])

  const floatLabelClasses = `
        ant-form-item__float-label 
        ${
          isLabelFloating
            ? 'ant-form-item__float-label--true'
            : 'ant-form-item__float-label--false'
        } 
    `

  const getRules = () => {
    let rules = []

    rules.push({
      message: `${spacesToProperty(label || (name as string))} is required`,
      required: required,
    })

    if (validator && !disabled)
      rules.push({
        validator: (_: Rule, value: string) => validator(value, options),
      })

    return rules
  }

  const handleOnBlur = () => {
    if (floatLabel && (inputValue || placeholder)) setIsLabelFloating(true)
    else setIsLabelFloating(false)
  }

  const handleOnChange = (
    value: string,
    selectedOptions: SelectOption | SelectOption[],
  ) => {
    if (floatLabel && value) setIsLabelFloating(true)

    const newValue: string = valueHandler ? valueHandler(value) : value
    customHandler && customHandler(newValue as string, selectedOptions)
  }

  return (
    <div className='form-item-validation-container'>
      <Form.Item
        className={`${className} ${floatLabel && floatLabelClasses}`}
        label={label}
        name={name}
        rules={getRules()}
        validateFirst={true}
      >
        <AutoComplete
          className={`${!floatLabel ? 'no-float' : ''}`}
          disabled={disabled}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onFocus={() => setIsLabelFloating(floatLabel ? true : false)}
          onSearch={onSearchHandler}
          options={options}
          placeholder={placeholder}
        />
      </Form.Item>
    </div>
  )
}
