export const Labels = {
  achOrPaypal: '(ACH or PayPal)',
  actions: 'Actions',
  addPatient: 'Add Patient',
  addPaymentMessagePart1: 'Need assistance? Please contact us at',
  addPaymentMessagePart2: 'If you do not wish to continue, please',
  addPaymentMessagePart3: 'or close this window.',
  addPaymentMethod: 'Add a Payment Method',
  addProvider: 'Add Provider',
  americanExpress: 'American Express',
  attachments: 'Attachments',
  attachmentDefaultImageDropdownText: 'Select Type',
  patientTableFiltered:
    'Showing {0} - {1} of {2} Entries (Filtered from {3} Entries)',
  patientTableTotal: 'Showing {0} - {1} of {2} Entries',
  billing: 'Vyne Dental Billing',
  billingAddress: 'Billing Address',
  billingCity: 'Billing City',
  billingDetails: 'Billing Details',
  billingInfoNoCard:
    'If you are currently setup on ACH or PayPal and require assistance changing your payment or billing info, please contact ',
  billingLicense: 'Billing License',
  billingName: 'Billing Name',
  billingPhone: '866-712-9584',
  birthDate: 'Birthdate',
  cancel: 'Cancel',
  cardholderName: 'Cardholder Name',
  cardInfo: 'Card Info',
  cardNumberPlaceholder: 'Card Number',
  cardType: 'Card Type',
  carrier: 'Carrier',
  carrierAddress: 'Carrier Address',
  carrierName: 'Carrier Name',
  city: 'City',
  claimDetails: 'Claim Details',
  claimHistory: 'Claim History',
  claimManagement: 'Claim Management',
  claimStatus: 'Claim Status',
  claimTips: 'Claim Tips',
  close: 'Close',
  cobFlag: 'COB Flag',
  cobName: 'COB Name',
  cobCarrierName: 'COB Carrier Name',
  code: 'Code',
  codeListQualifier: 'Code List Qualifier',
  columnOptions: 'Column Options',
  confirm: 'Confirm',
  contactInfo: 'Contact Info',
  contactName: 'Contact Name',
  dateFilterLast15: 'Last 15 Days',
  dateFilterLast30: 'Last 30 Days',
  dateFilterLast60: 'Last 60 Days',
  dateFilterLast7: 'Last 7 Days',
  dateFilterLast90: 'Last 90 Days',
  dateFilterToday: 'Today',
  dateOfService: 'Date of Service',
  defaultVerificationProvider: 'Default Verification Provider',
  description: 'Description',
  details: 'Details',
  delete: 'Delete',
  diagCodeA: 'Diag Code A',
  diagCodeB: 'Diag Code B',
  diagCodeC: 'Diag Code C',
  diagCodeD: 'Diag Code D',
  diagPointer: 'Diag Pointer',
  dob: 'Date of Birth',
  edit: 'Edit',
  eligible: 'Eligible',
  eligibilityResponse: 'Eligibility Response',
  email: 'Email',
  endingIn: 'ending in',
  entries: 'Entries',
  eob: 'EOB',
  era: 'ERA',
  exp: 'Exp',
  expirationPlaceholder: 'MM/YYYY',
  fee: 'Fee',
  feeTotal: 'Fee Total',
  firstName: 'First',
  filterAccepted: ' Accepted',
  filterAll: ' All',
  filterDuplicate: ' Duplicate',
  filterFailed: ' Failed',
  filterInProcess: ' In Process',
  filterInQueue: ' In Queue',
  filterOther: 'Other ',
  filterPrinted: ' Printed',
  filterRejected: ' Rejected',
  gender: 'Gender',
  groupNpi: 'Group Npi',
  groupNum: 'Group #',
  groupNumber: 'Group Number',
  idSsn: 'ID/SSN',
  individualNpi: 'Individual Npi',
  install: 'Install',
  installName: 'Computer name',
  insurance: 'Insurance',
  lastFirst: 'Last, First',
  lastName: 'Last Name',
  lastUpdate: 'Last Update',
  line: 'Line',
  logout: 'logout',
  mastercard: 'Mastercard',
  middle: 'Middle',
  missingInfo: 'Missing Info',
  mobileFilterInProcess: ' Prcs',
  mobileFilterRejected: ' Rjct',
  month: 'Month',
  newAttachment: 'New Attachment',
  nameInstall: 'Please name this computer for easier access.',
  newInstallNotice:
    'We noticed that you are accessing RPractice for the first time from this computer.',
  noDataAvailable: 'No Data Available',
  noTableData: 'No data available in table',
  notEligible: 'Not Eligible',
  npiGroup: 'NPI Group',
  only: 'Only',
  onlyShowComputerClaims: 'Only show claims from this computer',
  oralCavityCode: 'Oral Cavity',
  patient: 'Patient',
  patientAddress: 'Patient Address',
  patientBirthday: 'Patient Birthday',
  patientDetails: 'Patient Details',
  patientName: 'Patient Name',
  eligibility: 'Patient Verification',
  eligibilityInfo: 'View and manage patient insurance verification requests.',
  eligibilityInstructions:
    'Complete the fields below to verify patient eligibility.',
  payer: 'Payer',
  paymentInformation: 'Payment Information',
  paymentMethod: 'Payment Method',
  patientIsSub: 'Patient is Subscriber',
  phone: 'Phone',
  pleaseAddPayment: 'Please add a payment method to continue.',
  pleaseAddProvider: 'Please add a provider.',
  practiceInfo: 'Practice Info',
  practiceManagementSystem: 'Practice Management System',
  practiceName: 'Practice Name',
  predetermination: 'Predetermination',
  primaryPayment: 'Primary Payment',
  print: 'Print',
  procedures: 'Procedures',
  provFirst: 'First',
  provId: 'Provider NPI',
  provider: 'Provider',
  providerId: 'Provider ID',
  providerVerification: 'Provider Verification',
  provLast: 'Last or Billing Name',
  provName: 'Provider',
  qty: 'Qty',
  relationship: 'Relationship',
  remarks: 'Remarks',
  remarks1: 'Remarks 1',
  requestDate: 'Request Date',
  resend: 'Resend',
  resetColumns: 'Reset Columns',
  resetFilters: 'Reset Filters',
  response: 'Response',
  lastVerified: 'Last Verified',
  save: 'Save',
  saveAndClose: 'Save and Close',
  saveCardForFuture: 'Save Card for Future Use',
  saving: 'Saving',
  search: 'Search',
  searchPlaceholder: 'Search...',
  selectPayer: 'Select Payer',
  selectProvider: 'Select Provider',
  selectPracticeManagementSystem: 'Select PMS',
  selectVerificationProvider:
    'Select a provider to be used for eligibility verification or add a new provider below.',
  send: 'Send',
  sent: 'Sent',
  sendAll: 'Send All',
  serviceDate: 'Service Date',
  show: 'Show',
  state: 'State',
  status: 'Status',
  statusEligible: 'Eligible',
  statusFailed: 'Failed',
  statusNeedsAttention: 'Needs Attention',
  statusNotEligible: 'Not Eligible',
  statusPending: 'Pending',
  statusUnsupported: 'Unsupported',
  statusUnverified: 'Unverified',
  statusInvalidCarrierInfo: 'Invalid Carrier Info',
  statusDescription: 'Status Description',
  streetAddress: 'Street Address',
  street1: 'Street 1',
  street2: 'Street 2',
  submission: 'Submission',
  submit: 'Submit',
  submitDate: 'Submit Date',
  subscriberAddress: 'Subscriber Address',
  subscriberName: 'Subscriber Name',
  surface: 'Surface',
  taxId: 'Tax ID',
  tooth: 'Tooth',
  transmitDate: 'Transmit Date',
  treatmentAddress: 'Treatment Address',
  treatmentCity: 'Treatment City',
  treatingAddress: 'Treating Address',
  treatingLicense: 'Treating License',
  treatingName: 'Treating Name',
  treatingSignature: 'Treating Signature',
  unsent: 'Unsent',
  unverified: 'Unverified',
  updateClaims: 'Update',
  username: 'Username',
  subId: 'Subscriber ID',
  subscriber: 'Subscriber',
  suffix: 'Suffix',
  taxonomyCode: 'Taxonomy Code',
  tin: 'Tin',
  update: 'Update',
  responseHistory: 'Response History',
  verify: 'Verify',
  view: 'View',
  viewHidden: 'Show Hidden',
  visa: 'Visa',
  year: 'Year',
  zipCode: 'Zip Code',
  zipPlaceholder: 'Zip',
  success: 'Success!',
  required: 'Required',
  verifyFailed: 'Unable to verify patient, please fix any errors.',
  updateFormButtonDisabledTooltip:
    'Add patient and provider info to send a request for updated insurance information.',
}
