import * as Sentry from '@sentry/react'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { FC, ReactElement, useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { Button } from 'ui'
import { LogWarning } from 'utils'

import {
  getPostLoginQueryString,
  logoutUser,
} from 'trellis:features/authentication/Login/utils/login-helpers'
import GlobalState from 'trellis:state/globalState'

import tooth from 'trellis:assets/tooth-icon.png'

const NotFoundErrorMessage: FC = (): ReactElement => {
  const LDClient = useLDClient()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const getButtonLabel = () => {
    if (GlobalState?.IsAuthenticated.peek()) return 'Go to claims'
    else return 'Go to login'
  }

  useEffect(() => {
    try {
      Sentry.withScope(function (scope) {
        scope.setTag('Path', location?.pathname)
        LogWarning(new Error('404 Encountered'), '404 encountered')
      })
    } catch (err) {
      console.error('Failed to log 404', err)
    }
  }, [])

  const handleNavigate = () => {
    const queryString = getPostLoginQueryString(searchParams)

    if (GlobalState?.IsAuthenticated.peek())
      navigate(`/${queryString}`, { replace: true })
    else {
      logoutUser(LDClient)
      navigate(`/Account/Login${queryString}`, { replace: true })
    }
  }

  return (
    <article className='error-boundary'>
      <h1
        className='error-boundary__header'
        data-testid='not-found-error-heading'
      >
        <span>4</span>
        <img
          className='error-boundary__header-icon'
          src={tooth}
        />
        <span>4</span>
      </h1>
      <h3>Oops! This page was not found.</h3>
      <p className='mt-200'>
        If you feel this is a mistake, please contact support at:{' '}
        <a href='tel:(866)712-9584'>(866) 712-9584</a>.
      </p>
      <Button
        className='mt-200'
        data-testid='not-found-error-back-button'
        label={getButtonLabel()}
        onClick={handleNavigate}
        type='primary'
      />
    </article>
  )
}

export default NotFoundErrorMessage
