import { message } from 'antd'

import { GetSentAttachmentReceipt } from '../../../api/attachment-standalone/attachmentStandaloneApi'

export const HandleSentAttachmentPrint = (
  hmfId: number,
  setLoading: (arg0: boolean) => void,
) => {
  setLoading(true)

  GetSentAttachmentReceipt(hmfId)
    .then(({ data }) => {
      // Using fetch to control content-type to enforce file load as pdf
      fetch(data.documentPath)
        .then((response) => response.blob())
        .then((blob) => {
          const filename = hmfId + '.pdf'
          const file = new File([blob], filename, { type: 'application/pdf' })
          const exportUrl = window.URL.createObjectURL(file)
          window.open(exportUrl, '_blank', 'download=' + filename)
        })
        .catch((error) => {
          message.error('Failed to print attachment receipt')
          throw new Error(error)
        })
    })
    .catch((error) => {
      message.error('Failed to retrieve attachment receipt')
      throw new Error(error)
    })
    .finally(() => {
      setLoading(false)
    })
}
