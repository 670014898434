import GlobalState from 'trellis:state/globalState'

import { CustomerTypes } from '../../constants/general'
import { PmgApi } from '../pmg/pmgApi'
import { IClaimsApi } from './claimApi'
import { ApiTypes } from './claimApiTypes'
import { CustomerApi } from './customerApi'

export const ClaimsApiFactory = (
  pmgOverride: boolean = false,
  standardOverride: boolean = false,
): IClaimsApi => {
  const isPmg: boolean =
    GlobalState.Auth.CustomerTypeId.peek() !== CustomerTypes.Standard
  if ((isPmg || pmgOverride) && !standardOverride) {
    return new PmgApi(GlobalState.Auth.peek(), ApiTypes.PMG)
  } else {
    return new CustomerApi(GlobalState.Auth.peek(), ApiTypes.CLAIM)
  }
}
