import { InfoCircleFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { FC, useRef, useState } from 'react'

import { FormInput } from '../../../_siteWide/form/formInput'
import { useClaimDetailContext } from '../../../claims/context/claimDetailContext'

import './attachmentCarrierReference.scss'

const toolTipText =
  'The Carrier Reference Number may be identified on an EOB, ERA, or letter as: Claim Number, DCN, Document Control Number, Claim ID, File Reference Number, or File Control Number.'

type AttachementsCarrierReferenceProps = {
  isDisabled: boolean
  payorRefId: string
  handleUpdateAttachmentPayorRef: (props: string) => void
}

const AttachementsCarrierReference: FC<AttachementsCarrierReferenceProps> = ({
  handleUpdateAttachmentPayorRef,
  payorRefId,
  isDisabled,
}) => {
  const { setGlobalPayorRef } = useClaimDetailContext()
  const [value, setValue] = useState(payorRefId ?? null)
  const timeoutRef = useRef(null)

  //handle change in narrative
  React.useEffect(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
    }

    //we won't update attachment till after timeout to prevent flicker
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null
      handleUpdateAttachmentPayorRef(value)
    }, 500)
    setGlobalPayorRef(value)
  }, [value])

  const handleOnChange = (e: any) => {
    setValue(e.target.value)
  }

  return (
    <div className='attachment-reference'>
      <p className='attachment-title'>Carrier Reference</p>
      <section className='attachment-reference-box'>
        <p>
          Responding to a carrier request?
          <Tooltip title={toolTipText}>
            <InfoCircleFilled className='reference-info' />
          </Tooltip>
        </p>
        <FormInput
          className='attachment-reference__input'
          name='payorRefId'
          label='Payer Reference No.'
          onChange={handleOnChange}
          initialValue={payorRefId}
          defaultValue={payorRefId}
          noFloat={true}
          placeholder='Carrier Reference No. / DCN'
          disabled={isDisabled}
        />
      </section>
    </div>
  )
}

export default AttachementsCarrierReference
