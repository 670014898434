import { EyeOutlined } from '@ant-design/icons'
import { Col, Modal, Row } from 'antd'
import { differenceInDays, endOfDay, format } from 'date-fns'
import React, { FC, ReactInstance, useRef } from 'react'
import ReactToPrint from 'react-to-print'

import { Button } from 'ui'

import api from '../../../utilities/api'
import { useMessageContext } from '../context/messageContext'

import '../messages.scss'

import { Buffer } from 'buffer'

export const MessageModal: FC = () => {
  const { message, setMessage, dateFormat } = useMessageContext()

  const printRef = useRef<ReactInstance>(null)

  const fetchFile = async (attachment: any) => {
    const { data } = await api.getMessageAttachment(
      attachment.messageID,
      attachment.attachmentID,
    )
    const buffer = Buffer.from(data, 'base64')
    const url = window.URL.createObjectURL(new Blob([buffer]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${attachment.name}`)
    document.body.appendChild(link)
    link.click()

    // Clean up and remove the link
    link.parentNode.removeChild(link)
  }

  const messageModalTitle = (
    <Row
      align='middle'
      wrap={false}
    >
      <Col flex={1}>
        <span className='fs-100'>{message.messageFrom}</span>
      </Col>
    </Row>
  )

  const messageModalFooter = (
    <Row
      key={`message-${message.messageID}`}
      justify='end'
      align='middle'
      gutter={16}
    >
      <ReactToPrint
        content={() => printRef.current}
        key='reactToPrint'
        trigger={() => (
          <Button
            key='print'
            label='Print'
          />
        )}
      />
      <Button
        key='submit'
        label='Close'
        onClick={() => setMessage(null)}
        type='primary'
      />
    </Row>
  )

  return (
    <Modal
      footer={messageModalFooter}
      onCancel={() => setMessage(null)}
      onOk={() => setMessage(null)}
      title={messageModalTitle}
      open={Object.keys(message).length !== 0}
      width='900px'
    >
      <div>
        <Row
          justify='start'
          className='message-modal-date'
        >
          {format(new Date(message.messageDate), dateFormat)}
        </Row>
        <Row className='message-modal-subject'>{message.messageSubject}</Row>
      </div>
      {message.messageBody?.length && (
        <div
          className='message-modal-body'
          dangerouslySetInnerHTML={{
            __html: message.messageBody || 'No Message Body Found',
          }}
          ref={(node) => (printRef.current = node)}
        ></div>
      )}
      {message.attachments?.length && (
        <Row
          align='middle'
          gutter={16}
        >
          {message.attachments.map((attachment: any) => (
            <Col key={`attachment-${attachment.attachmentID}`}>
              <Button
                className='mr-100'
                label={attachment.name}
                key={`${attachment.name}-${attachment.attachmentID}`}
                onClick={() => fetchFile(attachment)}
                type='primary'
              />
            </Col>
          ))}
        </Row>
      )}
      {differenceInDays(
        endOfDay(new Date()),
        endOfDay(new Date(message.messageDate)),
      ) <= 180 &&
        message.neaAttachmentId &&
        message.claimId && (
          <Button
            className='mt-100'
            href={`/Claims?claimId=${message.claimId}&neaAttachmentId=${message.neaAttachmentId}`}
            icon={<EyeOutlined />}
            key={message.neaAttachmentId}
            label='View Attachment'
            target='_blank'
            type='primary'
          />
        )}
    </Modal>
  )
}
