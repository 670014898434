import { InfoCircleOutlined, StarOutlined } from '@ant-design/icons'
import { Steps } from 'antd'
import React from 'react'

import { StatusIcon } from 'ui'

import { formatColumnDate } from '../../../../../utilities/general'
import { useClaimDetailContext } from '../../../context/claimDetailContext'

const { Step } = Steps

export const ClaimStatus = () => {
  const { claim } = useClaimDetailContext()

  let history = claim.StatusHistory
  const currentStatusDate = history && history.length != 0 && history[history.length - 1].StatusDate
  history = history ? history.slice(0, -1) : []

  const statusDescription = (
    <>
      <div>{formatColumnDate(currentStatusDate, true)}</div>
      <div>{claim.StatusTooltip && claim.StatusTooltip.replace('"', '')}</div>
    </>
  )

  return (
    <div className='claim-detail-steps'>
      <Steps
        current={1}
        direction='vertical'
        size='small'
      >
        <Step
          title={claim.STATUS === 'In Queue' ? 'Ready' : claim.STATUS}
          description={statusDescription}
          icon={
            <StatusIcon
              status={claim.STATUS}
              showLabel={false}
              extraClasses='status-step-icon'
            />
          }
        />
        {claim.StatusDescription ? (
          <Step
            title='Status Description'
            description={claim.StatusDescription}
            icon={<InfoCircleOutlined className='status-step-icon' />}
          />
        ) : null}
        {claim.StatusDescriptionTooltip ? (
          <Step
            title='Claim Tip'
            description={claim.StatusDescriptionTooltip}
            icon={<StarOutlined className='status-step-icon' />}
          />
        ) : null}
        {history.map((h: any, i: number, array: any) => {
          const item = array[array.length - 1 - i]
          const description = (
            <>
              <div>{formatColumnDate(item.StatusDate, true)}</div>
              {item.Tooltip && <div>{item.Tooltip.replace('"', '')}</div>}
            </>
          )
          return (
            <Step
              key={i}
              title={item.Status == 'Transmit' ? 'Received' : item.Status}
              icon={
                <StatusIcon
                  status={item.Status}
                  showLabel={false}
                />
              }
              description={description}
            ></Step>
          )
        })}
      </Steps>
    </div>
  )
}
