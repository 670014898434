import { Outlet } from 'react-router-dom'

const AdditionalServices = () => {
  return (
    <article className='page-content__container page-content__container--flexible-rows'>
      <Outlet />
    </article>
  )
}

export default AdditionalServices
