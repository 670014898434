import { Buffer } from 'buffer'

import axios from 'axios'

import { LogError } from 'utils'

import trellisConfiguration from 'trellis:utilities/config'

import { ConvertImageRequest } from '../attachment/attachment-client'
import {
  AttachmentApiFactory,
  AuthenticationApiFactory,
  Configuration,
} from './attachment-standalone-client'
import {
  ApplicationApiFactory,
  Attachment,
  AttachmentSentApiFactory,
  DocumentApiFactory,
  Patient,
  PatientApiFactory,
  PatientAssociationRequest,
  PatientInsuranceRequest,
  PatientsSearchVdsRequest,
  ProviderApiFactory,
  ProviderRequest,
  RecipientApiFactory,
  ScreenCaptureApiFactory,
  SentAttachmentsRequest,
  VdsPatientApiFactory,
} from './attachment-standalone-client/api'
import GlobalState from 'trellis:state/globalState'

const attachmentWrapperApi: string =
  trellisConfiguration.attachmentWrapper_apiUrl

const getSettingsConfig = () =>
  new Configuration({
    basePath: attachmentWrapperApi,
    accessToken: GlobalState.AttachmentWrapperAccessToken.peek(),
  })

// Authentication
export const GetToken = async () => {
  return await AuthenticationApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    })
  ).authenticationTrellisPost(GlobalState.Auth.CustomerIdNumber.peek())
}

// Unsent attachments
export const GetAttachmentList = async (facilityId: string,) => {
  return await AttachmentApiFactory(
    getSettingsConfig()
  ).attachmentFacilityFacilityIdGet(facilityId)
}

export const GetAttachment = async (
  attachmentId: number,
) => {
  return await AttachmentApiFactory(
    getSettingsConfig()
  ).attachmentAttachmentIdGet(attachmentId)
}

export const GetAttachmentImage = async (
  attachmentId: number,
  documentId: number,
  facilityId: string,
) => {
  return await AttachmentApiFactory(
    getSettingsConfig()
  ).attachmentAttachmentIdDocumentsDocumentIdGet(
    attachmentId,
    documentId,
    facilityId,
  )
}

export const GetAttachmentRules = async (
  masterId: number,
) => {
  return await RecipientApiFactory(
    getSettingsConfig()
  ).recipientMasterIdAttachmentRulesGet(masterId)
}

export const CreateAttachment = async (
  ignoreRequirements: boolean,
  attachment: Attachment,
) => {
  return await AttachmentApiFactory(
    getSettingsConfig()
  ).attachmentPost(ignoreRequirements, attachment)
}

export const UpdateAttachment = async (
  attachmentId: number,
  ignoreRequirements: boolean,
  attachment: Attachment,
) => {
  return await AttachmentApiFactory(
    getSettingsConfig()
  ).attachmentAttachmentIdPut(attachmentId, ignoreRequirements, attachment)
}

export const SendingAttachment = async (
  attachmentId: number,
) => {
  return await AttachmentApiFactory(
    getSettingsConfig()
  ).attachmentAttachmentIdSendPost(attachmentId)
}

export const HoldAttachment = async (
  attachmentId: number,
) => {
  return await AttachmentApiFactory(
    getSettingsConfig()
  ).attachmentAttachmentIdStatusPut(attachmentId, 'Hold')
}

export const DeleteAttachment = async (
  attachmentId: number,
) => {
  return await AttachmentApiFactory(
    getSettingsConfig()
  ).attachmentAttachmentIdDelete(attachmentId)
}

export const GetRecipientList = async (
  facilityId: string,
  isNeaOnly: boolean,
) => {
  return await RecipientApiFactory(
    getSettingsConfig()
  ).recipientListGet(facilityId, isNeaOnly)
}

export const GetRecipient = async (
  masterId: number,
) => {
  return await RecipientApiFactory(
    getSettingsConfig()
  ).recipientMasterIdGet(masterId)
}

export const GetRecipientMasterId = async (
  carrierId: string,
) => {
  return await RecipientApiFactory(
    getSettingsConfig()
  ).recipientConvertCarrierIdGet(carrierId)
}

// Sent attachments
export const GetSentAttachmentList = async (
  facilityId: string,
  request: SentAttachmentsRequest,
) => {
  return await AttachmentSentApiFactory(
    getSettingsConfig()
  ).attachmentSentFacilityFacilityIdPost(facilityId, request)
}

export const GetSentAttachment = async (
  hmfId: string,
) => {
  return await AttachmentSentApiFactory(
    getSettingsConfig()
  ).attachmentSentHmfIdGet(parseInt(hmfId))
}

export const GetSentAttachmentImage = async (
  hmfId: number,
  imageSequence: number,
) => {
  return await AttachmentSentApiFactory(
    getSettingsConfig()
  ).attachmentSentHmfIdDocumentsImageSequenceGet(hmfId, imageSequence)
}

export const GetSentAttachmentReceipt = async (
  hmfId: number,
) => {
  return await AttachmentSentApiFactory(
    getSettingsConfig()
  ).attachmentSentHmfIdReceiptGet(hmfId)
}

// Attachment images
export const GetImageTypes = async function () {
  return await ApplicationApiFactory(
    getSettingsConfig()
  ).applicationDocumentCodesGet()
}

export const ScreenCaptureStoragePath = async function (
  customerId: number,
) {
  return await ScreenCaptureApiFactory(
    getSettingsConfig()
  ).screenCaptureStoragePathGet(customerId)
}

export const ScreenCaptureGetImage = async function (
  imageName: string,
  customerId: number,
) {
  return (await ScreenCaptureApiFactory(
    getSettingsConfig()
  ).screenCaptureDocumentNameGet(customerId, imageName)) as any
}

export const GetImageFromS3 = async function (presignedS3Url: string) {
  return await axios
    .get(presignedS3Url, {
      responseType: 'arraybuffer',
    })
    .then((response) => Buffer.from(response.data, 'binary').toString('base64'))
    .catch((error) => {
      LogError(error, 'Failed to download attachment image')
    })
}

export const UploadImageToS3 = async function (
  presignedS3Url: string,
  imageData?: File,
) {
  return await axios
    .put(presignedS3Url, imageData, {
      headers: { 'Content-Type': 'application/octet-stream' },
    })
    .catch((error) => {
      LogError(error, 'Failed to upload attachment image')
    })
}

export const ConvertImageS3Bytes = async (
  request: ConvertImageRequest,
  customerId: number,
) => {
  return await DocumentApiFactory(
    getSettingsConfig()
  ).documentConvertImageS3Post(customerId, request)
}

export const DocumentStoragePath = async function (
  facilityId: string,
  customerId: number,
) {
  return await DocumentApiFactory(
    getSettingsConfig()
  ).documentStoragePathGet(customerId, facilityId)
}

export const DocumentByIdStoragePath = async function (
  facilityId: string,
  documentId: number,
) {
  return await DocumentApiFactory(
    getSettingsConfig()
  ).documentDocumentIdStoragePathGet(documentId, facilityId)
}

// VDS Patients
export const GetVDSPatients = async function (
  patientSearchVdsRequest: PatientsSearchVdsRequest,
  customerId: number,
) {
  return await VdsPatientApiFactory(
    getSettingsConfig()
  ).vdsPatientsSearchPost(customerId, patientSearchVdsRequest)
}

// Fast Attach Provider
export const GetFastAttachProvider = async (
  customerId: number,
  providerId: number,
) => {
  return await ProviderApiFactory(
    getSettingsConfig()
  ).providerProviderIdGet(providerId, customerId)
}

export const CreateFastAttachProvider = async function (
  customerId: number,
  trellisProviderId: number,
  providerRequest: ProviderRequest,
) {
  return await ProviderApiFactory(
    getSettingsConfig()
  ).providerPost(customerId, trellisProviderId, providerRequest)
}

export const UpdateFastAttachProvider = async function (
  providerId: number,
  providerRequest: ProviderRequest,
) {
  return await ProviderApiFactory(
    getSettingsConfig()
  ).providerProviderIdPut(providerId, providerRequest)
}

// Fast Attach Patient
export const GetFastAttachPatient = async (
  patientId: number,
) => {
  return await PatientApiFactory(
    getSettingsConfig()
  ).patientPatientIdGet(patientId)
}

export const CreateFastAttachPatient = async function (
  customerId: number,
  facilityId: string,
  patientAssociationRequest: PatientAssociationRequest,
  vdsPatientId: string,
) {
  return await PatientApiFactory(
    getSettingsConfig()
  ).patientPost(customerId, facilityId, patientAssociationRequest, vdsPatientId)
}

export const UpdateFastAttachPatient = async function (
  patientId: number,
  patient: Patient,
) {
  return await PatientApiFactory(
    getSettingsConfig()
  ).patientPatientIdPut(patientId, patient)
}

export const GetFastAttachPatientInsurance = async (
  patientId: number,
  insuranceId: number,
) => {
  return await PatientApiFactory(
    getSettingsConfig()
  ).patientPatientIdInsuranceInsuranceIdGet(patientId, insuranceId)
}

export const CreateFastAttachPatientInsurance = async function (
  patientId: number,
  patientInsuranceRequest: PatientInsuranceRequest,
) {
  return await PatientApiFactory(
    getSettingsConfig()
  ).patientPatientIdInsurancePost(patientId, patientInsuranceRequest)
}

export const UpdateFastAttachPatientInsurance = async function (
  patientId: number,
  insuranceId: number,
  patientInsuranceRequest: PatientInsuranceRequest,
) {
  return await PatientApiFactory(
    getSettingsConfig()
  ).patientPatientIdInsuranceInsuranceIdPut(
    patientId,
    insuranceId,
    patientInsuranceRequest,
  )
}
