import { CopyOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { FC } from 'react'

import { showMessage } from '../../../utilities/general'

const CopyAttachmentIdContent: FC<{ attachmentId: number }> = ({
  attachmentId,
}) => {
  const copyAttachmentId = (attachmentId: number) => {
    navigator.clipboard.writeText(`NEA#${attachmentId}`)
    showMessage('Attachment ID copied successfully', 'success')
  }

  return (
    <Tooltip title='Copy Attachment ID'>
      <span>
        <CopyOutlined onClick={() => copyAttachmentId(attachmentId)} />
      </span>
    </Tooltip>
  )
}

export default CopyAttachmentIdContent
