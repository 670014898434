import { LogError } from 'utils'

import { CustomerUserModel } from 'trellis:api/account/account-client'
import { PracticeInfo } from 'trellis:api/practice/practice-client'

import { LegalBusinessStatusResponse } from './api'

declare const pendo

//Documentation can be found at https://support.pendo.io/hc/en-us/articles/360031862272-Install-Pendo-on-a-single-page-web-application
// to test if it's working run pendo.validateEnvironment() in the console

export const initializePendo = () => {
  pendo.initialize({
    visitor: {
      id: null,
    },
  })
}

export const identifyPendoAuthedUser = (
  userInfo: CustomerUserModel,
  practiceInfo: PracticeInfo,
  activeServicesString: string,
  legalBusinessInfo: LegalBusinessStatusResponse,
) => {
  try {
    const pendoRequest = getPendoObject(
      userInfo,
      practiceInfo,
      activeServicesString,
      legalBusinessInfo,
    )
    pendo.identify(pendoRequest)
  } catch (error) {
    LogError(error)
  }
}

export const clearPendo = () => {
  try {
    pendo.clearSession()
  } catch (error) {
    LogError(error)
  }
}

const getPendoObject = (
  userInfo: CustomerUserModel,
  practiceInfo: PracticeInfo,
  activeServicesString: string,
  legalBusinessInfo: LegalBusinessStatusResponse,
) => {
  const requestSource = window.location.host
  const pendoRequest = {
    visitor: {
      id: userInfo?.userName,
      source: requestSource,
      createdOn: userInfo?.addDate,
      full_name: userInfo?.firstName + ' ' + userInfo?.lastName,
    },
    account: {
      source: requestSource,
      customerID: userInfo?.globalCustomerID,
      createdOn: userInfo?.addDate,
      id: practiceInfo?.facilityID,
      pmsIntegration: practiceInfo?.p_Software,
      activeServices: activeServicesString,
      legalBusinessInfoVerificationStatus:
        legalBusinessInfo?.verificationStatus,
      maxBrandRegistrationUpdateAttemptsReached:
        legalBusinessInfo?.maxBrandRegistrationUpdateAttemptsReached,
    },
  }
  return pendoRequest
}
