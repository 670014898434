import { observer, useObservable } from '@legendapp/state/react'
import { FC, useEffect } from 'react'

import { LogError } from 'utils'

import { Loading } from 'trellis:components/_siteWide/loader/dataDisplay'
import { Errors } from 'trellis:constants/errors'
import styles from 'trellis:features/downloads/_downloads.module.scss'
import { DownloadItem } from 'trellis:features/downloads/DownloadItem'
import { Download } from 'trellis:features/downloads/utils/downloads-helpers'
import api from 'trellis:utilities/api'
import { showMessage } from 'trellis:utilities/general'

export const Downloads: FC = observer(() => {
  const additionalDownloads = useObservable<Download[] | null>(null)
  const hasDownloads = useObservable<boolean>(false)
  const loading = useObservable<boolean>(true)
  const mainDownloads = useObservable<Download[] | null>(null)

  useEffect(() => {
    handleGetDownloads()
  }, [])

  const handleGetDownloads = async () => {
    try {
      const { data } = await api.getDownloads()

      if (data.downloads) {
        additionalDownloads.set(
          data.downloads.filter((item: Download) => item.inAdditionalUtilities),
        )
        mainDownloads.set(
          data.downloads.filter(
            (item: Download) => !item.inAdditionalUtilities,
          ),
        )

        hasDownloads.set(true)
      }
    } catch (err) {
      LogError(err)
      showMessage(Errors.getDownloadsError)
    } finally {
      loading.set(false)
    }
  }

  return (
    <div className='page-content'>
      <section className={styles['downloads-page__header']}>
        <h1 className={styles['downloads-page__title']}>Downloads</h1>
      </section>
      <div className={styles['downloads-page__content-container']}>
        {loading.get() ? (
          <Loading />
        ) : !hasDownloads.get() ? (
          <p className={styles['downloads-page__no-downloads']}>
            No Downloads are currently available
          </p>
        ) : (
          <>
            {mainDownloads.peek().map((item: Download, index: number) => (
              <DownloadItem
                key={index}
                {...item}
              />
            ))}
            {additionalDownloads.peek().length > 0 && (
              <>
                <p className={styles['download-item__tagline']}>
                  Additional Utilities
                </p>
                <div className={styles['download-item--additional-container']}>
                  {additionalDownloads
                    .peek()
                    .map((item: Download, index: number) => (
                      <DownloadItem
                        key={index}
                        {...item}
                      />
                    ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
})
