import { Buffer } from 'buffer'

import axios from 'axios'

import { isSafeApiError, LogError } from 'utils'

import trellisConfiguration from 'trellis:utilities/config'

import {
  ImageCaptureMethod,
  ProcedureListObj,
} from '../../components/attachment/attachment.interface'
import { Configuration } from './attachment-client'
import {
  ApplicationApiFactory,
  AttachmentApiFactory,
  AttachmentRequest,
  CarrierApiFactory,
  ClaimApiFactory,
  RefCarrierRequest,
  ScreenCaptureApiFactory,
} from './attachment-client/api'
import GlobalState from 'trellis:state/globalState'

const attachmentApi: string = trellisConfiguration.attachments_apiUrl

export const ScreenCaptureStoragePath = async function () {
  return await ScreenCaptureApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).screenCaptureGetStoragePath()
}

export const ScreenCaptureGetImage = async function (imageName: string) {
  return await ScreenCaptureApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).screenCaptureGetImage(imageName)
}

export const GetImageTypes = async function () {
  return await ApplicationApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).applicationGetImageTypes()
}

export const ConvertImageS3 = async function (
  fileName: string,
  destType: string,
) {
  return await ApplicationApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).applicationConvertS3Image({ fileName, destType })
}

export const GetClaimAttachment = async function (
  claimId: number,
  isLegacy: boolean = false,
  customerId: number,
) {
  return await ClaimApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).claimGetClaimAttachment(claimId, isLegacy, customerId)
}

export const CreateClaimAttachment = async function (
  claimId: number,
  request: AttachmentRequest,
  isLegacy: boolean,
  customerId: number,
) {
  return await ClaimApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).claimCreateClaimAttachment(claimId, request, isLegacy, customerId)
}

export const IgnoreAttachmentRequirements = async function (
  claimId: number,
  customerId: number,
) {
  return await ClaimApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).claimIgnoreAttachmentRequirements(claimId, customerId)
}

export const ClearAttachmentRequirements = async function (
  claimId: number,
  customerId: number,
) {
  return await ClaimApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).claimClearAttachmentRequirements(claimId, customerId)
}

export const GetAttachmentRequirementsIgnoredState = async function (
  claimId: number,
  customerId: number,
) {
  return await ClaimApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).claimGetAttachmentRequirementsIgnoredState(claimId, customerId)
}

export const GetAttachmentRequirements = async function (
  claimId: number,
  procedureListReq: ProcedureListObj,
  matchId: string,
  isLegacy: boolean,
) {
  return await ClaimApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).claimGetAttachmentRequirements(claimId, matchId, isLegacy, procedureListReq)
}

export const GetSentAttachments = async function (
  claimId: number,
  customerId: number,
) {
  return await ClaimApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).claimGetClaimAttachmentSent(claimId, customerId)
}

export const UpdateClaimAttachment = async function (
  attachmentId: number,
  request: AttachmentRequest,
  isLegacy: boolean,
  customerId: number,
) {
  return await AttachmentApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).attachmentUpdateAttachment(attachmentId, request, isLegacy, customerId)
}

export const DeleteClaimAttachment = async function (
  attachmentId: number,
  isLegacy: boolean = false,
  customerId: number,
) {
  return await AttachmentApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).attachmentDeleteAttachment(attachmentId, isLegacy, customerId)
}

export const GetClaimAttachmentImage = async function (
  attachmentId: number,
  imageId: number,
  isLegacy: boolean,
  customerId: number,
) {
  return await AttachmentApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).attachmentGetImage(attachmentId, imageId, isLegacy, customerId)
}

export const CreateClaimAttachmentImage = async function (
  attachmentId: number,
  imageType: string,
  imageDate: string,
  orientation: string,
  acquisitionType: ImageCaptureMethod,
  imageName: string,
  isLegacy: boolean,
  customerId: number,
) {
  return await AttachmentApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).attachmentCreateAttachmentImage(
    attachmentId,
    { imageType, imageName, imageDate, orientation, acquisitionType },
    isLegacy,
    customerId,
  )
}

export const GetAttachmentImageStoragePath = async function (
  customerId: number,
) {
  return await AttachmentApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).attachmentGetStoragePath(customerId)
}

export const GetAttachmentImageStoragePathConversion = async function () {
  return await AttachmentApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).attachmentGetStoragePathConversion()
}

export const DeleteClaimAttachmentImage = async function (
  attachmentId: number,
  imageId: number,
  isLegacy: boolean,
  customerId: number,
) {
  return await AttachmentApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).attachmentDeleteImage(attachmentId, imageId, isLegacy, customerId)
}

export const GetSentImage = async function (
  neaNumber: string,
  imageId: number,
  isLegacy: boolean,
  customerId: number,
) {
  return await AttachmentApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).attachmentGetImageSent(neaNumber, imageId, isLegacy, customerId)
}

export const GetCarrierMatch = async function (
  claimId: string,
  refCarrierRequest: RefCarrierRequest,
) {
  return await CarrierApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).carrierMatch(refCarrierRequest, claimId)
}

export const GetCarrierList = async function () {
  return await CarrierApiFactory(
    new Configuration({
      basePath: attachmentApi,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).carrierGetCarrierList()
}

export const UploadImageToS3 = async function (
  presignedS3Url: string,
  imageData?: File,
): Promise<boolean> {
  try {
    await axios.put(presignedS3Url, imageData, {
      headers: { 'Content-Type': 'application/octet-stream' },
    })
  } catch (error) {
    LogError(error, 'Failed to upload attachment image')
    return false
  }
  return true
}

export const GetImageFromS3 = async function (presignedS3Url: string) {
  return await axios
    .get(presignedS3Url, {
      responseType: 'arraybuffer',
    })
    .then((response) => Buffer.from(response.data, 'binary').toString('base64'))
    .catch((error) => {
      if (!isSafeApiError(error, [404])) {
        LogError(error, 'Failed to download attachment image')
      }
    })
}
