/* tslint:disable */
/* eslint-disable */
/**
 * Eligibility
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'Street1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'Street2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'City'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'Zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'State'?: string | null;
}
/**
 * 
 * @export
 * @interface AgeLimit
 */
export interface AgeLimit {
    /**
     * 
     * @type {string}
     * @memberof AgeLimit
     */
    'AgeHighValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgeLimit
     */
    'AgeLowValue'?: string | null;
}
/**
 * 
 * @export
 * @interface Benefit
 */
export interface Benefit {
    /**
     * 
     * @type {string}
     * @memberof Benefit
     */
    'Network'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Benefit
     */
    'Category'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Benefit
     */
    'InsuranceTypr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Benefit
     */
    'ServiceType'?: string | null;
    /**
     * 
     * @type {PlanPeriod}
     * @memberof Benefit
     */
    'PlanPeriod'?: PlanPeriod;
    /**
     * 
     * @type {string}
     * @memberof Benefit
     */
    'Amount'?: string | null;
    /**
     * 
     * @type {CoverageLevel}
     * @memberof Benefit
     */
    'CoverageLevel'?: CoverageLevel;
    /**
     * 
     * @type {string}
     * @memberof Benefit
     */
    'Percent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Benefit
     */
    'ProcedureCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Benefit
     */
    'Limitation'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Benefit
     */
    'LimitationAppliesTo'?: Array<string> | null;
    /**
     * 
     * @type {Array<ServiceHistory>}
     * @memberof Benefit
     */
    'ServiceDates'?: Array<ServiceHistory> | null;
    /**
     * 
     * @type {number}
     * @memberof Benefit
     */
    'ServicesRemaining'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Benefit
     */
    'Disclaimers'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Benefit
     */
    'Quantity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Benefit
     */
    'QuantityQualifier'?: string | null;
    /**
     * 
     * @type {Array<HealthServiceDescription>}
     * @memberof Benefit
     */
    'HealthServiceDescriptions'?: Array<HealthServiceDescription> | null;
    /**
     * 
     * @type {string}
     * @memberof Benefit
     */
    'PayerSpecificDescription'?: string | null;
    /**
     * 
     * @type {AgeLimit}
     * @memberof Benefit
     */
    'AgeLimit'?: AgeLimit;
}
/**
 * 
 * @export
 * @interface Coverage
 */
export interface Coverage {
    /**
     * 
     * @type {string}
     * @memberof Coverage
     */
    'EffectiveDate'?: string | null;
    /**
     * 
     * @type {CoverageStatus}
     * @memberof Coverage
     */
    'Status'?: CoverageStatus;
    /**
     * 
     * @type {string}
     * @memberof Coverage
     */
    'EndDate'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CoverageLevel = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10
} as const;

export type CoverageLevel = typeof CoverageLevel[keyof typeof CoverageLevel];


/**
 * 
 * @export
 * @enum {string}
 */

export const CoverageStatus = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type CoverageStatus = typeof CoverageStatus[keyof typeof CoverageStatus];


/**
 * 
 * @export
 * @interface EligPatient
 */
export interface EligPatient {
    /**
     * 
     * @type {number}
     * @memberof EligPatient
     */
    'CustomerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'PatientFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'PatientMiddleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'PatientLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'PatientSuffix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'PatientBirthdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'PatientGender'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EligPatient
     */
    'PatientIsSub'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'SubscriberId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'SubscriberIdSource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'SubscriberFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'SubscriberMiddleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'SubscriberLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'SubscriberSuffix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'SubscriberBirthdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'SubscriberGender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'ProviderLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'ProviderFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'IndividualNpi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'CarrierName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'GroupNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'CarrierId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'SyncId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'TaxonomyCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EligPatient
     */
    'FastAttachPatientId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EligPatient
     */
    'FastAttachInsuranceId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EligPatient
     */
    'FastAttachProviderId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'RelationshipToSubscriber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EligPatient
     */
    'CarrierMasterId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EligPatient
     */
    'PatientId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EligPatient
     */
    'Automated'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EligPatient
     */
    'FormId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'TaxId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligPatient
     */
    'Pms'?: string | null;
}
/**
 * 
 * @export
 * @interface EligbilityStatusMapping
 */
export interface EligbilityStatusMapping {
    /**
     * 
     * @type {string}
     * @memberof EligbilityStatusMapping
     */
    'Status'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EligbilityStatusMapping
     */
    'StatusCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EligbilityStatusMapping
     */
    'StatusDisplay'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligbilityStatusMapping
     */
    'ToolTip'?: string | null;
}
/**
 * 
 * @export
 * @interface EligibilityHistory
 */
export interface EligibilityHistory {
    /**
     * 
     * @type {number}
     * @memberof EligibilityHistory
     */
    'HistoryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EligibilityHistory
     */
    'CarrierName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityHistory
     */
    'GroupNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityHistory
     */
    'RequestDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EligibilityHistory
     */
    'Status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityHistory
     */
    'Response'?: string | null;
}
/**
 * 
 * @export
 * @interface EligibilityPatient
 */
export interface EligibilityPatient {
    /**
     * 
     * @type {number}
     * @memberof EligibilityPatient
     */
    'CustomerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'PatientFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'PatientMiddleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'PatientLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'PatientSuffix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'PatientBirthdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'PatientGender'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EligibilityPatient
     */
    'PatientIsSub'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'SubscriberId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'SubscriberIdSource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'SubscriberFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'SubscriberMiddleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'SubscriberLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'SubscriberSuffix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'SubscriberBirthdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'SubscriberGender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'ProviderLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'ProviderFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'IndividualNpi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'CarrierName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'GroupNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'CarrierId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'SyncId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'TaxonomyCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EligibilityPatient
     */
    'FastAttachPatientId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EligibilityPatient
     */
    'FastAttachInsuranceId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EligibilityPatient
     */
    'FastAttachProviderId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'RelationshipToSubscriber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EligibilityPatient
     */
    'CarrierMasterId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'PatientId'?: string | null;
}
/**
 * 
 * @export
 * @interface EligibilityPatients
 */
export interface EligibilityPatients {
    /**
     * 
     * @type {number}
     * @memberof EligibilityPatients
     */
    'TotalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof EligibilityPatients
     */
    'HistoryId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'PatientId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'CarrierName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'RequestError'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'HtmlResult'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'ResponseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'ResponseError'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'AddDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'PatientFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'PatientLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'Status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'AppointmentDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'PatientBirthdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'SyncId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'PatientFormResponse'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EligibilityPatients
     */
    'PatientUpdateForm'?: boolean;
}
/**
 * 
 * @export
 * @interface EligibilityPatientsRequest
 */
export interface EligibilityPatientsRequest {
    /**
     * 
     * @type {number}
     * @memberof EligibilityPatientsRequest
     */
    'CurrentPage'?: number;
    /**
     * 
     * @type {number}
     * @memberof EligibilityPatientsRequest
     */
    'PageSize'?: number;
    /**
     * 
     * @type {SortColumn}
     * @memberof EligibilityPatientsRequest
     */
    'SortColumn'?: SortColumn;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EligibilityPatientsRequest
     */
    'Filters'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface EligibilityResponseObject
 */
export interface EligibilityResponseObject {
    /**
     * 
     * @type {string}
     * @memberof EligibilityResponseObject
     */
    'Response_271_HTML'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityResponseObject
     */
    'CarrierId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EligibilityResponseObject
     */
    'FormId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof EligibilityResponseObject
     */
    'UnableToSendForm'?: boolean;
    /**
     * 
     * @type {PatientEligibilityForm}
     * @memberof EligibilityResponseObject
     */
    'PatientFormResponse'?: PatientEligibilityForm;
}
/**
 * 
 * @export
 * @interface EligibilitySettings
 */
export interface EligibilitySettings {
    /**
     * 
     * @type {boolean}
     * @memberof EligibilitySettings
     */
    'SendUpdateForm'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EligibilitySettings
     */
    'AutomationTermDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof EligibilitySettings
     */
    'StartHour'?: number;
    /**
     * 
     * @type {number}
     * @memberof EligibilitySettings
     */
    'EndHour'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EligibilitySettings
     */
    'SendText'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EligibilitySettings
     */
    'SendEmail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EligibilitySettings
     */
    'MessageText'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EligibilitySettings
     */
    'MessageIsCustom'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ErrorCode = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6
} as const;

export type ErrorCode = typeof ErrorCode[keyof typeof ErrorCode];


/**
 * 
 * @export
 * @enum {string}
 */

export const ErrorFollowup = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10
} as const;

export type ErrorFollowup = typeof ErrorFollowup[keyof typeof ErrorFollowup];


/**
 * 
 * @export
 * @enum {string}
 */

export const ErrorReason = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23,
    NUMBER_24: 24,
    NUMBER_25: 25,
    NUMBER_26: 26,
    NUMBER_27: 27,
    NUMBER_28: 28,
    NUMBER_29: 29,
    NUMBER_30: 30,
    NUMBER_31: 31,
    NUMBER_32: 32,
    NUMBER_33: 33,
    NUMBER_34: 34,
    NUMBER_35: 35,
    NUMBER_36: 36,
    NUMBER_37: 37,
    NUMBER_38: 38,
    NUMBER_39: 39,
    NUMBER_40: 40,
    NUMBER_41: 41,
    NUMBER_42: 42,
    NUMBER_43: 43,
    NUMBER_44: 44,
    NUMBER_45: 45,
    NUMBER_46: 46,
    NUMBER_47: 47,
    NUMBER_48: 48,
    NUMBER_49: 49,
    NUMBER_50: 50,
    NUMBER_51: 51,
    NUMBER_52: 52,
    NUMBER_53: 53,
    NUMBER_54: 54,
    NUMBER_55: 55,
    NUMBER_56: 56,
    NUMBER_57: 57,
    NUMBER_58: 58,
    NUMBER_59: 59,
    NUMBER_60: 60,
    NUMBER_61: 61,
    NUMBER_62: 62,
    NUMBER_63: 63,
    NUMBER_64: 64,
    NUMBER_65: 65,
    NUMBER_66: 66,
    NUMBER_67: 67,
    NUMBER_68: 68,
    NUMBER_69: 69
} as const;

export type ErrorReason = typeof ErrorReason[keyof typeof ErrorReason];


/**
 * 
 * @export
 * @interface GetPatientEligibilityInfoResponse
 */
export interface GetPatientEligibilityInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'PatientId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'SyncId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'CustomerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'PatientFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'PatientMiddleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'PatientLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'PatientSuffix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'PatientBirthdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'PatientGender'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'PatientIsSub'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'SubscriberId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'SubscriberIdSource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'SubscriberFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'SubscriberMiddleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'SubscriberLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'SubscriberSuffix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'SubscriberBirthdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'SubscriberGender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'CarrierName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'GroupNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'CarrierId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'ProviderLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'ProviderFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'IndividualNpi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'Status'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'FastAttachPatientId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'FastAttachInsuranceId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'FastAttachProviderId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'RelationshipToSubscriber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'CarrierMasterId'?: number | null;
    /**
     * 
     * @type {Array<EligibilityHistory>}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'VerificationHistory'?: Array<EligibilityHistory> | null;
}
/**
 * 
 * @export
 * @interface GetPatientsResponse
 */
export interface GetPatientsResponse {
    /**
     * 
     * @type {number}
     * @memberof GetPatientsResponse
     */
    'TotalCount'?: number;
    /**
     * 
     * @type {Array<EligibilityPatients>}
     * @memberof GetPatientsResponse
     */
    'Data'?: Array<EligibilityPatients> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPatientsResponse
     */
    'Statuses'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPatientsResponse
     */
    'Carriers'?: Array<string> | null;
    /**
     * 
     * @type {SummaryData}
     * @memberof GetPatientsResponse
     */
    'Summary'?: SummaryData;
}
/**
 * 
 * @export
 * @interface HealthServiceDescription
 */
export interface HealthServiceDescription {
    /**
     * 
     * @type {string}
     * @memberof HealthServiceDescription
     */
    'QuantityQualifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HealthServiceDescription
     */
    'Quantity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HealthServiceDescription
     */
    'UnitQualifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HealthServiceDescription
     */
    'Unit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HealthServiceDescription
     */
    'TimePeriodQualifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HealthServiceDescription
     */
    'TimePeriodValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HealthServiceDescription
     */
    'DeliveryFrequency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HealthServiceDescription
     */
    'DeliveryPatternTime'?: string | null;
}
/**
 * 
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'Type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'Value'?: string | null;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {ErrorReason}
     * @memberof ModelError
     */
    'Reason'?: ErrorReason;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'ValidRequestIndicator'?: string | null;
    /**
     * 
     * @type {ErrorFollowup}
     * @memberof ModelError
     */
    'FollowUp'?: ErrorFollowup;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'PayerSpecificMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'Message'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelError
     */
    'Path'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface OnederfulEligibilityHistory
 */
export interface OnederfulEligibilityHistory {
    /**
     * 
     * @type {string}
     * @memberof OnederfulEligibilityHistory
     */
    'StructuredView'?: string | null;
    /**
     * 
     * @type {EligPatient}
     * @memberof OnederfulEligibilityHistory
     */
    'EligibilityParams'?: EligPatient;
    /**
     * 
     * @type {string}
     * @memberof OnederfulEligibilityHistory
     */
    'OnederfulError'?: string | null;
}
/**
 * 
 * @export
 * @interface OnederfulEligibilityResponse
 */
export interface OnederfulEligibilityResponse {
    /**
     * 
     * @type {string}
     * @memberof OnederfulEligibilityResponse
     */
    'OnederfulId'?: string | null;
    /**
     * 
     * @type {PatientResponse}
     * @memberof OnederfulEligibilityResponse
     */
    'Subscriber'?: PatientResponse;
    /**
     * 
     * @type {Array<PatientResponse>}
     * @memberof OnederfulEligibilityResponse
     */
    'Dependents'?: Array<PatientResponse> | null;
    /**
     * 
     * @type {PatientResponse}
     * @memberof OnederfulEligibilityResponse
     */
    'Patient'?: PatientResponse;
    /**
     * 
     * @type {OnederfulProviderResponse}
     * @memberof OnederfulEligibilityResponse
     */
    'Provider'?: OnederfulProviderResponse;
    /**
     * 
     * @type {Plan}
     * @memberof OnederfulEligibilityResponse
     */
    'Plan'?: Plan;
    /**
     * 
     * @type {PayerResponse}
     * @memberof OnederfulEligibilityResponse
     */
    'Payer'?: PayerResponse;
    /**
     * 
     * @type {Array<Benefit>}
     * @memberof OnederfulEligibilityResponse
     */
    'ActiveCoverage'?: Array<Benefit> | null;
    /**
     * 
     * @type {Array<Benefit>}
     * @memberof OnederfulEligibilityResponse
     */
    'Deductible'?: Array<Benefit> | null;
    /**
     * 
     * @type {Array<Benefit>}
     * @memberof OnederfulEligibilityResponse
     */
    'Maximums'?: Array<Benefit> | null;
    /**
     * 
     * @type {Array<Benefit>}
     * @memberof OnederfulEligibilityResponse
     */
    'Coinsurance'?: Array<Benefit> | null;
    /**
     * 
     * @type {Array<Benefit>}
     * @memberof OnederfulEligibilityResponse
     */
    'WaitingPeriod'?: Array<Benefit> | null;
    /**
     * 
     * @type {Array<Benefit>}
     * @memberof OnederfulEligibilityResponse
     */
    'Limitations'?: Array<Benefit> | null;
    /**
     * 
     * @type {Array<Benefit>}
     * @memberof OnederfulEligibilityResponse
     */
    'NotCovered'?: Array<Benefit> | null;
    /**
     * 
     * @type {Array<Benefit>}
     * @memberof OnederfulEligibilityResponse
     */
    'PayerSpecificInfo'?: Array<Benefit> | null;
    /**
     * 
     * @type {Array<Benefit>}
     * @memberof OnederfulEligibilityResponse
     */
    'Copayment'?: Array<Benefit> | null;
    /**
     * 
     * @type {Array<Benefit>}
     * @memberof OnederfulEligibilityResponse
     */
    'OutOfPocket'?: Array<Benefit> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnederfulEligibilityResponse
     */
    'Disclaimers'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof OnederfulEligibilityResponse
     */
    'PayerUuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnederfulEligibilityResponse
     */
    'Message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnederfulEligibilityResponse
     */
    'PrimaryReason'?: string | null;
    /**
     * 
     * @type {ErrorCode}
     * @memberof OnederfulEligibilityResponse
     */
    'ErrorCode'?: ErrorCode;
    /**
     * 
     * @type {Array<Error>}
     * @memberof OnederfulEligibilityResponse
     */
    'Errors'?: Array<Error> | null;
}
/**
 * 
 * @export
 * @interface OnederfulProviderResponse
 */
export interface OnederfulProviderResponse {
    /**
     * 
     * @type {string}
     * @memberof OnederfulProviderResponse
     */
    'Npi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnederfulProviderResponse
     */
    'TaxId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnederfulProviderResponse
     */
    'FirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnederfulProviderResponse
     */
    'LastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnederfulProviderResponse
     */
    'OrganizationName'?: string | null;
}
/**
 * 
 * @export
 * @interface OnetimeEligibility
 */
export interface OnetimeEligibility {
    /**
     * 
     * @type {number}
     * @memberof OnetimeEligibility
     */
    'HistoryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OnetimeEligibility
     */
    'HtmlResult'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnetimeEligibility
     */
    'ResponseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnetimeEligibility
     */
    'Status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnetimeEligibility
     */
    'PatientFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnetimeEligibility
     */
    'PatientLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnetimeEligibility
     */
    'CarrierName'?: string | null;
}
/**
 * 
 * @export
 * @interface PatientEligibilityForm
 */
export interface PatientEligibilityForm {
    /**
     * 
     * @type {number}
     * @memberof PatientEligibilityForm
     */
    'PatientId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatientEligibilityForm
     */
    'CustomerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatientEligibilityForm
     */
    'FormRequestId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientEligibilityForm
     */
    'PatientFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientEligibilityForm
     */
    'PatientMiddleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientEligibilityForm
     */
    'PatientLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientEligibilityForm
     */
    'PatientSuffix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientEligibilityForm
     */
    'PatientBirthdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientEligibilityForm
     */
    'PatientGender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientEligibilityForm
     */
    'SubscriberId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientEligibilityForm
     */
    'SubscriberFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientEligibilityForm
     */
    'SubscriberMiddleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientEligibilityForm
     */
    'SubscriberLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientEligibilityForm
     */
    'SubscriberSuffix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientEligibilityForm
     */
    'SubscriberBirthdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientEligibilityForm
     */
    'SubscriberGender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientEligibilityForm
     */
    'OtherInsurance'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientEligibilityForm
     */
    'CarrierName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientEligibilityForm
     */
    'GroupNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientEligibilityForm
     */
    'CarrierId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientEligibilityForm
     */
    'PatientIsSubscriber'?: string | null;
}
/**
 * 
 * @export
 * @interface PatientResponse
 */
export interface PatientResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'FirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'LastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'DateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'MemberId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'GroupNumber'?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof PatientResponse
     */
    'Address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'Gender'?: string | null;
    /**
     * 
     * @type {Coverage}
     * @memberof PatientResponse
     */
    'Coverage'?: Coverage;
}
/**
 * 
 * @export
 * @interface PayerResponse
 */
export interface PayerResponse {
    /**
     * 
     * @type {string}
     * @memberof PayerResponse
     */
    'Id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayerResponse
     */
    'Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayerResponse
     */
    'Phone'?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof PayerResponse
     */
    'ClaimAddress'?: Address;
}
/**
 * 
 * @export
 * @interface Plan
 */
export interface Plan {
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    'Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    'Number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    'Type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    'GroupNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    'GroupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    'State'?: string | null;
    /**
     * 
     * @type {Array<Metadata>}
     * @memberof Plan
     */
    'metadata'?: Array<Metadata> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PlanPeriod = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8
} as const;

export type PlanPeriod = typeof PlanPeriod[keyof typeof PlanPeriod];


/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ProviderResponse
 */
export interface ProviderResponse {
    /**
     * 
     * @type {string}
     * @memberof ProviderResponse
     */
    'ProviderLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderResponse
     */
    'ProviderFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderResponse
     */
    'ProviderNPI'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderResponse
     */
    'OptionId'?: string | null;
}
/**
 * 
 * @export
 * @interface ServiceHistory
 */
export interface ServiceHistory {
    /**
     * 
     * @type {string}
     * @memberof ServiceHistory
     */
    'ProcedureCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceHistory
     */
    'ServiceDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceHistory
     */
    'ToothCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceHistory
     */
    'ToothSurfaces'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceHistory
     */
    'Type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceHistory
     */
    'Value'?: string | null;
}
/**
 * 
 * @export
 * @interface SortColumn
 */
export interface SortColumn {
    /**
     * 
     * @type {string}
     * @memberof SortColumn
     */
    'Column'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SortColumn
     */
    'Sort'?: string | null;
}
/**
 * 
 * @export
 * @interface SummaryData
 */
export interface SummaryData {
    /**
     * 
     * @type {number}
     * @memberof SummaryData
     */
    'Appointments'?: number;
    /**
     * 
     * @type {number}
     * @memberof SummaryData
     */
    'Eligible'?: number;
    /**
     * 
     * @type {number}
     * @memberof SummaryData
     */
    'NeedsAttention'?: number;
    /**
     * 
     * @type {number}
     * @memberof SummaryData
     */
    'PendingPatientResponse'?: number;
}
/**
 * 
 * @export
 * @interface UpdateEligibilitySettingsRequest
 */
export interface UpdateEligibilitySettingsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEligibilitySettingsRequest
     */
    'SendUpdateForm'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateEligibilitySettingsRequest
     */
    'AutomationTermDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateEligibilitySettingsRequest
     */
    'StartHour'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateEligibilitySettingsRequest
     */
    'EndHour'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEligibilitySettingsRequest
     */
    'SendText'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEligibilitySettingsRequest
     */
    'SendEmail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateEligibilitySettingsRequest
     */
    'MessageText'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEligibilitySettingsRequest
     */
    'MessageIsCustom'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateEligibilitySettingsRequest
     */
    'CustomerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateEligibilitySettingsRequest
     */
    'UserId'?: number;
}
/**
 * 
 * @export
 * @interface VerifyEligibilityResponse
 */
export interface VerifyEligibilityResponse {
    /**
     * 
     * @type {string}
     * @memberof VerifyEligibilityResponse
     */
    'HtmlResult'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VerifyEligibilityResponse
     */
    'EligibilityId'?: number;
    /**
     * 
     * @type {number}
     * @memberof VerifyEligibilityResponse
     */
    'StatusCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VerifyEligibilityResponse
     */
    'Status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerifyEligibilityResponse
     */
    'StatusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerifyEligibilityResponse
     */
    'ResponseErrorMessage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VerifyEligibilityResponse
     */
    'PatientId'?: number;
    /**
     * 
     * @type {OnederfulEligibilityResponse}
     * @memberof VerifyEligibilityResponse
     */
    'OnederfulResponse'?: OnederfulEligibilityResponse;
    /**
     * 
     * @type {boolean}
     * @memberof VerifyEligibilityResponse
     */
    'StructuredViewEnabled'?: boolean;
}

/**
 * EligibilityApi - axios parameter creator
 * @export
 */
export const EligibilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {EligibilityPatient} eligibilityPatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityCreatePatientDetails: async (customerId: number, eligibilityPatient: EligibilityPatient, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('eligibilityCreatePatientDetails', 'customerId', customerId)
            // verify required parameter 'eligibilityPatient' is not null or undefined
            assertParamExists('eligibilityCreatePatientDetails', 'eligibilityPatient', eligibilityPatient)
            const localVarPath = `/{customerId}/patient`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eligibilityPatient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} historyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityGet271Response: async (customerId: number, historyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('eligibilityGet271Response', 'customerId', customerId)
            // verify required parameter 'historyId' is not null or undefined
            assertParamExists('eligibilityGet271Response', 'historyId', historyId)
            const localVarPath = `/{customerId}/271-response/{historyId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"historyId"}}`, encodeURIComponent(String(historyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {EligibilityPatientsRequest} eligibilityPatientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityGetAppointments: async (customerId: number, eligibilityPatientsRequest: EligibilityPatientsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('eligibilityGetAppointments', 'customerId', customerId)
            // verify required parameter 'eligibilityPatientsRequest' is not null or undefined
            assertParamExists('eligibilityGetAppointments', 'eligibilityPatientsRequest', eligibilityPatientsRequest)
            const localVarPath = `/{customerId}/appointments`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eligibilityPatientsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {EligibilityPatientsRequest} eligibilityPatientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityGetEligibilityPatients: async (customerId: number, eligibilityPatientsRequest: EligibilityPatientsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('eligibilityGetEligibilityPatients', 'customerId', customerId)
            // verify required parameter 'eligibilityPatientsRequest' is not null or undefined
            assertParamExists('eligibilityGetEligibilityPatients', 'eligibilityPatientsRequest', eligibilityPatientsRequest)
            const localVarPath = `/{customerId}/patients`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eligibilityPatientsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityGetEligibilitySettings: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('eligibilityGetEligibilitySettings', 'customerId', customerId)
            const localVarPath = `/{customerId}/eligibility-settings`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityGetOnetimeHistories: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('eligibilityGetOnetimeHistories', 'customerId', customerId)
            const localVarPath = `/{customerId}/onetime-histories`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityGetPatientDetails: async (customerId: number, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('eligibilityGetPatientDetails', 'customerId', customerId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('eligibilityGetPatientDetails', 'patientId', patientId)
            const localVarPath = `/{customerId}/patient/{patientId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} formId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityGetPatientFormResponse: async (customerId: number, formId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('eligibilityGetPatientFormResponse', 'customerId', customerId)
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('eligibilityGetPatientFormResponse', 'formId', formId)
            const localVarPath = `/{customerId}/patient-form-response/{formId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityGetProviders: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('eligibilityGetProviders', 'customerId', customerId)
            const localVarPath = `/{customerId}/providers`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} [code] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityGetStatusMappings: async (customerId: string, code?: number, status?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('eligibilityGetStatusMappings', 'customerId', customerId)
            const localVarPath = `/{customerId}/status-mappings`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} historyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityGetStructuredView: async (customerId: number, historyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('eligibilityGetStructuredView', 'customerId', customerId)
            // verify required parameter 'historyId' is not null or undefined
            assertParamExists('eligibilityGetStructuredView', 'historyId', historyId)
            const localVarPath = `/{customerId}/structured-view/{historyId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"historyId"}}`, encodeURIComponent(String(historyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {UpdateEligibilitySettingsRequest} updateEligibilitySettingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityUpdateEligibilitySettings: async (customerId: number, updateEligibilitySettingsRequest: UpdateEligibilitySettingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('eligibilityUpdateEligibilitySettings', 'customerId', customerId)
            // verify required parameter 'updateEligibilitySettingsRequest' is not null or undefined
            assertParamExists('eligibilityUpdateEligibilitySettings', 'updateEligibilitySettingsRequest', updateEligibilitySettingsRequest)
            const localVarPath = `/{customerId}/eligibility-settings`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEligibilitySettingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} patientId 
         * @param {EligibilityPatient} eligibilityPatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityUpdatePatientDetails: async (customerId: number, patientId: string, eligibilityPatient: EligibilityPatient, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('eligibilityUpdatePatientDetails', 'customerId', customerId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('eligibilityUpdatePatientDetails', 'patientId', patientId)
            // verify required parameter 'eligibilityPatient' is not null or undefined
            assertParamExists('eligibilityUpdatePatientDetails', 'eligibilityPatient', eligibilityPatient)
            const localVarPath = `/{customerId}/patient/{patientId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eligibilityPatient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} commonPatientId 
         * @param {EligibilityPatient} eligibilityPatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityVerifyPatientEligibility: async (customerId: number, commonPatientId: string, eligibilityPatient: EligibilityPatient, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('eligibilityVerifyPatientEligibility', 'customerId', customerId)
            // verify required parameter 'commonPatientId' is not null or undefined
            assertParamExists('eligibilityVerifyPatientEligibility', 'commonPatientId', commonPatientId)
            // verify required parameter 'eligibilityPatient' is not null or undefined
            assertParamExists('eligibilityVerifyPatientEligibility', 'eligibilityPatient', eligibilityPatient)
            const localVarPath = `/{customerId}/verify/{commonPatientId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"commonPatientId"}}`, encodeURIComponent(String(commonPatientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eligibilityPatient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EligibilityApi - functional programming interface
 * @export
 */
export const EligibilityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EligibilityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {EligibilityPatient} eligibilityPatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eligibilityCreatePatientDetails(customerId: number, eligibilityPatient: EligibilityPatient, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPatientEligibilityInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eligibilityCreatePatientDetails(customerId, eligibilityPatient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} historyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eligibilityGet271Response(customerId: number, historyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EligibilityResponseObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eligibilityGet271Response(customerId, historyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {EligibilityPatientsRequest} eligibilityPatientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eligibilityGetAppointments(customerId: number, eligibilityPatientsRequest: EligibilityPatientsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPatientsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eligibilityGetAppointments(customerId, eligibilityPatientsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {EligibilityPatientsRequest} eligibilityPatientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eligibilityGetEligibilityPatients(customerId: number, eligibilityPatientsRequest: EligibilityPatientsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPatientsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eligibilityGetEligibilityPatients(customerId, eligibilityPatientsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eligibilityGetEligibilitySettings(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EligibilitySettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eligibilityGetEligibilitySettings(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eligibilityGetOnetimeHistories(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OnetimeEligibility>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eligibilityGetOnetimeHistories(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eligibilityGetPatientDetails(customerId: number, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPatientEligibilityInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eligibilityGetPatientDetails(customerId, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} formId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eligibilityGetPatientFormResponse(customerId: number, formId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientEligibilityForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eligibilityGetPatientFormResponse(customerId, formId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eligibilityGetProviders(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProviderResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eligibilityGetProviders(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} [code] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eligibilityGetStatusMappings(customerId: string, code?: number, status?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EligbilityStatusMapping>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eligibilityGetStatusMappings(customerId, code, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} historyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eligibilityGetStructuredView(customerId: number, historyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OnederfulEligibilityHistory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eligibilityGetStructuredView(customerId, historyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {UpdateEligibilitySettingsRequest} updateEligibilitySettingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eligibilityUpdateEligibilitySettings(customerId: number, updateEligibilitySettingsRequest: UpdateEligibilitySettingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eligibilityUpdateEligibilitySettings(customerId, updateEligibilitySettingsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} patientId 
         * @param {EligibilityPatient} eligibilityPatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eligibilityUpdatePatientDetails(customerId: number, patientId: string, eligibilityPatient: EligibilityPatient, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPatientEligibilityInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eligibilityUpdatePatientDetails(customerId, patientId, eligibilityPatient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} commonPatientId 
         * @param {EligibilityPatient} eligibilityPatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eligibilityVerifyPatientEligibility(customerId: number, commonPatientId: string, eligibilityPatient: EligibilityPatient, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyEligibilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eligibilityVerifyPatientEligibility(customerId, commonPatientId, eligibilityPatient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EligibilityApi - factory interface
 * @export
 */
export const EligibilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EligibilityApiFp(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {EligibilityPatient} eligibilityPatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityCreatePatientDetails(customerId: number, eligibilityPatient: EligibilityPatient, options?: any): AxiosPromise<GetPatientEligibilityInfoResponse> {
            return localVarFp.eligibilityCreatePatientDetails(customerId, eligibilityPatient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} historyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityGet271Response(customerId: number, historyId: number, options?: any): AxiosPromise<EligibilityResponseObject> {
            return localVarFp.eligibilityGet271Response(customerId, historyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {EligibilityPatientsRequest} eligibilityPatientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityGetAppointments(customerId: number, eligibilityPatientsRequest: EligibilityPatientsRequest, options?: any): AxiosPromise<GetPatientsResponse> {
            return localVarFp.eligibilityGetAppointments(customerId, eligibilityPatientsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {EligibilityPatientsRequest} eligibilityPatientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityGetEligibilityPatients(customerId: number, eligibilityPatientsRequest: EligibilityPatientsRequest, options?: any): AxiosPromise<GetPatientsResponse> {
            return localVarFp.eligibilityGetEligibilityPatients(customerId, eligibilityPatientsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityGetEligibilitySettings(customerId: number, options?: any): AxiosPromise<EligibilitySettings> {
            return localVarFp.eligibilityGetEligibilitySettings(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityGetOnetimeHistories(customerId: number, options?: any): AxiosPromise<Array<OnetimeEligibility>> {
            return localVarFp.eligibilityGetOnetimeHistories(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityGetPatientDetails(customerId: number, patientId: string, options?: any): AxiosPromise<GetPatientEligibilityInfoResponse> {
            return localVarFp.eligibilityGetPatientDetails(customerId, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} formId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityGetPatientFormResponse(customerId: number, formId: number, options?: any): AxiosPromise<PatientEligibilityForm> {
            return localVarFp.eligibilityGetPatientFormResponse(customerId, formId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityGetProviders(customerId: number, options?: any): AxiosPromise<Array<ProviderResponse>> {
            return localVarFp.eligibilityGetProviders(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} [code] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityGetStatusMappings(customerId: string, code?: number, status?: string, options?: any): AxiosPromise<Array<EligbilityStatusMapping>> {
            return localVarFp.eligibilityGetStatusMappings(customerId, code, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} historyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityGetStructuredView(customerId: number, historyId: number, options?: any): AxiosPromise<OnederfulEligibilityHistory> {
            return localVarFp.eligibilityGetStructuredView(customerId, historyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {UpdateEligibilitySettingsRequest} updateEligibilitySettingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityUpdateEligibilitySettings(customerId: number, updateEligibilitySettingsRequest: UpdateEligibilitySettingsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.eligibilityUpdateEligibilitySettings(customerId, updateEligibilitySettingsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} patientId 
         * @param {EligibilityPatient} eligibilityPatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityUpdatePatientDetails(customerId: number, patientId: string, eligibilityPatient: EligibilityPatient, options?: any): AxiosPromise<GetPatientEligibilityInfoResponse> {
            return localVarFp.eligibilityUpdatePatientDetails(customerId, patientId, eligibilityPatient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} commonPatientId 
         * @param {EligibilityPatient} eligibilityPatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityVerifyPatientEligibility(customerId: number, commonPatientId: string, eligibilityPatient: EligibilityPatient, options?: any): AxiosPromise<VerifyEligibilityResponse> {
            return localVarFp.eligibilityVerifyPatientEligibility(customerId, commonPatientId, eligibilityPatient, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EligibilityApi - object-oriented interface
 * @export
 * @class EligibilityApi
 * @extends {BaseAPI}
 */
export class EligibilityApi extends BaseAPI {
    /**
     * 
     * @param {number} customerId 
     * @param {EligibilityPatient} eligibilityPatient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EligibilityApi
     */
    public eligibilityCreatePatientDetails(customerId: number, eligibilityPatient: EligibilityPatient, options?: AxiosRequestConfig) {
        return EligibilityApiFp(this.configuration).eligibilityCreatePatientDetails(customerId, eligibilityPatient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {number} historyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EligibilityApi
     */
    public eligibilityGet271Response(customerId: number, historyId: number, options?: AxiosRequestConfig) {
        return EligibilityApiFp(this.configuration).eligibilityGet271Response(customerId, historyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {EligibilityPatientsRequest} eligibilityPatientsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EligibilityApi
     */
    public eligibilityGetAppointments(customerId: number, eligibilityPatientsRequest: EligibilityPatientsRequest, options?: AxiosRequestConfig) {
        return EligibilityApiFp(this.configuration).eligibilityGetAppointments(customerId, eligibilityPatientsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {EligibilityPatientsRequest} eligibilityPatientsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EligibilityApi
     */
    public eligibilityGetEligibilityPatients(customerId: number, eligibilityPatientsRequest: EligibilityPatientsRequest, options?: AxiosRequestConfig) {
        return EligibilityApiFp(this.configuration).eligibilityGetEligibilityPatients(customerId, eligibilityPatientsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EligibilityApi
     */
    public eligibilityGetEligibilitySettings(customerId: number, options?: AxiosRequestConfig) {
        return EligibilityApiFp(this.configuration).eligibilityGetEligibilitySettings(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EligibilityApi
     */
    public eligibilityGetOnetimeHistories(customerId: number, options?: AxiosRequestConfig) {
        return EligibilityApiFp(this.configuration).eligibilityGetOnetimeHistories(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EligibilityApi
     */
    public eligibilityGetPatientDetails(customerId: number, patientId: string, options?: AxiosRequestConfig) {
        return EligibilityApiFp(this.configuration).eligibilityGetPatientDetails(customerId, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {number} formId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EligibilityApi
     */
    public eligibilityGetPatientFormResponse(customerId: number, formId: number, options?: AxiosRequestConfig) {
        return EligibilityApiFp(this.configuration).eligibilityGetPatientFormResponse(customerId, formId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EligibilityApi
     */
    public eligibilityGetProviders(customerId: number, options?: AxiosRequestConfig) {
        return EligibilityApiFp(this.configuration).eligibilityGetProviders(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customerId 
     * @param {number} [code] 
     * @param {string} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EligibilityApi
     */
    public eligibilityGetStatusMappings(customerId: string, code?: number, status?: string, options?: AxiosRequestConfig) {
        return EligibilityApiFp(this.configuration).eligibilityGetStatusMappings(customerId, code, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {number} historyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EligibilityApi
     */
    public eligibilityGetStructuredView(customerId: number, historyId: number, options?: AxiosRequestConfig) {
        return EligibilityApiFp(this.configuration).eligibilityGetStructuredView(customerId, historyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {UpdateEligibilitySettingsRequest} updateEligibilitySettingsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EligibilityApi
     */
    public eligibilityUpdateEligibilitySettings(customerId: number, updateEligibilitySettingsRequest: UpdateEligibilitySettingsRequest, options?: AxiosRequestConfig) {
        return EligibilityApiFp(this.configuration).eligibilityUpdateEligibilitySettings(customerId, updateEligibilitySettingsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {string} patientId 
     * @param {EligibilityPatient} eligibilityPatient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EligibilityApi
     */
    public eligibilityUpdatePatientDetails(customerId: number, patientId: string, eligibilityPatient: EligibilityPatient, options?: AxiosRequestConfig) {
        return EligibilityApiFp(this.configuration).eligibilityUpdatePatientDetails(customerId, patientId, eligibilityPatient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {string} commonPatientId 
     * @param {EligibilityPatient} eligibilityPatient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EligibilityApi
     */
    public eligibilityVerifyPatientEligibility(customerId: number, commonPatientId: string, eligibilityPatient: EligibilityPatient, options?: AxiosRequestConfig) {
        return EligibilityApiFp(this.configuration).eligibilityVerifyPatientEligibility(customerId, commonPatientId, eligibilityPatient, options).then((request) => request(this.axios, this.basePath));
    }
}


