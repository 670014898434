import { observer } from '@legendapp/state/react'
import { createContext, useContext, useState } from 'react'

import GlobalState, { LDFlags$ } from 'trellis:state/globalState'
import api from 'trellis:utilities/api'
import { showMessage } from 'trellis:utilities/general'
import { RoleHelper$ } from 'trellis:utilities/roleHelper'
import { validateProperty } from 'trellis:utilities/validators/baseValidator'
import {
  validateConfirmPassword,
  validatePassword,
} from 'trellis:utilities/validators/passwordValidators'

const ProfileContext = createContext(null)
const initialFormData: any = {
  CurrentPassword: null,
  NewPassword: null,
  ConfirmPassword: null,
  errors: [],
}

export const ProfileContextProvider = observer(({ children }: any) => {
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [saving, setSaving] = useState(false)
  const [password, setPassword] = useState<any>(initialFormData)

  const user = {
    firstName: GlobalState.UserInfo.firstName.get(),
    lastName: GlobalState.UserInfo.lastName.get(),
    username: GlobalState.UserInfo.userName.get(),
  }

  const handleFormClose = () => {
    setShowChangePassword(false)
    setPassword(initialFormData)
  }

  const handleUpdatePassword = () => {
    setSaving(true)
    validateForm(password)
    if (password.isValid) {
      updatePassword(fixupPassword(password))
    }
  }

  const fixupPassword = (password: any) => {
    return {
      newPassword: password.NewPassword,
      oldPassword: password.CurrentPassword,
      username: user.username,
      appCompanyName: RoleHelper$.isDentalIntel.peek() ? 2 : 0,
    }
  }

  const validateForm = (formData: any) => {
    formData.isValid = true
    formData.errors = []
    validateProperty(validatePassword, formData, 'CurrentPassword', null, true)
    validateProperty(validatePassword, formData, 'NewPassword', null, true)
    validateProperty(
      validateConfirmPassword,
      formData,
      'ConfirmPassword',
      null,
      true,
    )

    if (formData.errors?.length) {
      formData.isValid = false
      setSaving(false)
      showMessage(
        'There was a problem saving your password. Please fix the errors and try again.',
      )
    }

    setPassword({ ...password, ...formData })
  }

  const updatePassword = async (passwordRequest: any) => {
    const { data } = await api.changePassword(passwordRequest)

    if (data.message) {
      // NOTE: Username is always known when logged in, so we are doing this check on data.message to remove Username from the message. This is a temporary fix until the backend can update logic to handle these as separate messages
      if (data.message === 'A valid Username and Password is required')
        showMessage('Current Password does not match your existing Password.')
      else showMessage(data.message)
    } else {
      showMessage('Your password has been changed.', 'success')
      handleFormClose()
    }

    setSaving(false)
  }

  return (
    <ProfileContext.Provider
      value={{
        // state
        showChangePassword,
        setShowChangePassword,
        password,
        setPassword,
        saving,
        setSaving,

        // functions
        handleFormClose,
        handleUpdatePassword,

        // constants
        user,
      }}
    >
      {children}
    </ProfileContext.Provider>
  )
})

export const useProfileContext = () => {
  const context = useContext(ProfileContext)
  if (context === undefined) {
    throw new Error('Context must be used within a Provider')
  }
  return context
}
