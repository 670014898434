import trellisConfiguration from 'trellis:utilities/config.ts'

import { ContactUsProps } from './ContactUs.tsx'

export const CONTACT_VYNE_DENTAL: ContactUsProps = {
  email: trellisConfiguration.customer_support_vyne_email,
  liveChat: trellisConfiguration.customer_support_vyne_live_chat,
  phone: trellisConfiguration.customer_support_vyne_phone,
}

export const CONTACT_DENTAL_INTEL: ContactUsProps = {
  email: trellisConfiguration.customer_support_di_email,
  liveChat: trellisConfiguration.customer_support_di_live_chat,
  phone: trellisConfiguration.customer_support_di_phone,
}

export const CONTACT_PATTERSON: ContactUsProps = {
  email: trellisConfiguration.customer_support_patterson_email,
  liveChat: trellisConfiguration.customer_support_patterson_live_chat,
  phone: trellisConfiguration.customer_support_patterson_phone,
}
