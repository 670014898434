import GlobalState from 'trellis:state/globalState'
import trellisConfiguration from 'trellis:utilities/config'

import { IAuthenticatedApiModel } from '../../utilities/api'
import {
  Configuration,
  EligibilityApiFactory,
  EligibilityPatient,
  EligibilityPatientsRequest,
  EligibilitySettings,
} from './eligibility-client'

const trellisApi: string = trellisConfiguration.trellis_apiUrl
export const GetAppointments = async (eligibilityPatientsRequest: EligibilityPatientsRequest) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).eligibilityGetAppointments(GlobalState.Auth.CustomerIdNumber.peek(), eligibilityPatientsRequest)
}

export const GetPatients = async (eligibilityPatientsRequest: EligibilityPatientsRequest) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).eligibilityGetEligibilityPatients(GlobalState.Auth.CustomerIdNumber.peek(), eligibilityPatientsRequest)
}

export const GetPatient = async (patientId: string) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).eligibilityGetPatientDetails(GlobalState.Auth.CustomerIdNumber.peek(), patientId)
}

export const CreatePatient = async (patient: EligibilityPatient) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).eligibilityCreatePatientDetails(GlobalState.Auth.CustomerIdNumber.peek(), patient)
}

export const UpdatePatient = async (
  patientId: string,
  patient: EligibilityPatient,
) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).eligibilityUpdatePatientDetails(GlobalState.Auth.CustomerIdNumber.peek(), patientId, patient)
}

export const VerifyPatient = async (
  patientId: string,
  patient: EligibilityPatient,
) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).eligibilityVerifyPatientEligibility(GlobalState.Auth.CustomerIdNumber.peek(), patientId, patient)
}

export const GetProviders = async () => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).eligibilityGetProviders(GlobalState.Auth.CustomerIdNumber.peek())
}

export const Get271Response = async (historyId: number) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).eligibilityGet271Response(GlobalState.Auth.CustomerIdNumber.peek(), historyId)
}

export const GetOnetimeHistories = async () => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).eligibilityGetOnetimeHistories(GlobalState.Auth.CustomerIdNumber.peek())
}

export const GetInsuranceFormResponse = async (formId: number) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).eligibilityGetPatientFormResponse(GlobalState.Auth.CustomerIdNumber.peek(), formId)
}

export const SaveSettings = async (eligibilitySettings: EligibilitySettings) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).eligibilityUpdateEligibilitySettings(GlobalState.Auth.CustomerIdNumber.peek(), eligibilitySettings)
}

export const GetSettings = async () => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).eligibilityGetEligibilitySettings(GlobalState.Auth.CustomerIdNumber.peek())
}

export const GetStructuredView = async (historyId: number) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).eligibilityGetStructuredView(GlobalState.Auth.CustomerIdNumber.peek(), historyId)
}

export const GetStatusMapping = async () => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).eligibilityGetStatusMappings(GlobalState.Auth.CustomerId.peek())
}
