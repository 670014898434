import { Col, Row } from 'antd'
import { FC } from 'react'

import { FormInput, FormSelect } from 'ui'
import { stateSelectOptions, validateZip } from 'utils'

const BillingAddress: FC = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <FormInput
            label='Address'
            name='customerAddress1'
            required
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormInput
            label='Address Line 2'
            name='customerAddress2'
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <FormInput
            label='City'
            name='customerCity'
            required
          />
        </Col>
        <Col span={6}>
          <FormSelect
            label='State'
            name='customerState'
            options={stateSelectOptions}
            required
          />
        </Col>
        <Col span={6}>
          <FormInput
            label='Zip Code'
            name='customerZip'
            required
            validator={validateZip}
          />
        </Col>
      </Row>
    </>
  )
}

export default BillingAddress
