import { FC } from 'react'

import { FormSelect } from 'ui'

import GlobalState from 'trellis:state/globalState'
import React from 'react'
import { SelectOption } from 'utils'
import { observer } from '@legendapp/state/react'

const getOptions = ():SelectOption[]=>{
  const items = GlobalState.Constants.supportedPMS.get()?.map<SelectOption>((pms)=>({
    text:pms.pmsName,
    value:pms.pmsName
  }))
  return items ?? [];
}

const PracticeManagementSystem: FC = observer(() => {
  return (
    <FormSelect
      label='Select PMS'
      name='p_Software'
      options={getOptions()}
      required
    />
  )
})

export default PracticeManagementSystem
