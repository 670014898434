import { Tag } from 'antd'
import { FC } from 'react'

import { DetailFilterNames } from '../../../constants/general'
import { spacesToProperty } from '../../../utilities/general'
import { DetermineTagConfigType, EntryDisplayType } from './SmartTableTypes'

type FilterTagsProps = {
  entries: [string, EntryDisplayType][]
  removeFilter: (entry: string) => void
}

const FilterTags: FC<FilterTagsProps> = ({ entries, removeFilter }) => {
  const determineTagConfig = (
    entry: [string, EntryDisplayType],
  ): DetermineTagConfigType => {
    let property: string = entry[0]
    let entryDisplay: EntryDisplayType = entry[1]
    let hasColon: boolean = true
    let propertyDisplay: string = spacesToProperty(property)

    switch (property) {
      case 'Birthdate':
        property = 'Date of Birth'
        break
      case 'PatientName':
        propertyDisplay = 'Patient'
        break
      case 'SubscriberName':
        propertyDisplay = 'Subscriber'
        break
      case DetailFilterNames.hasAttachment:
        propertyDisplay = 'Includes Attachments'
        hasColon = false
        entryDisplay = ''
        break
      case DetailFilterNames.hasEob:
        propertyDisplay = 'Includes EOB'
        hasColon = false
        entryDisplay = ''
        break
      case DetailFilterNames.hasEra:
        propertyDisplay = 'Includes ERA'
        hasColon = false
        entryDisplay = ''
        break
      default:
        break
    }

    if (property === 'Status' && entryDisplay === 'In Queue')
      entryDisplay = 'Ready'

    if (typeof entryDisplay == 'boolean')
      entryDisplay = entryDisplay ? 'Yes' : 'No'

    return {
      property,
      propertyDisplay,
      entryDisplay,
      hasColon,
    }
  }

  return (
    <>
      {entries.map((entry: [string, EntryDisplayType], index: number) => {
        if (entry[0] === 'TransmitDate') return null

        const { property, propertyDisplay, hasColon, entryDisplay } =
          determineTagConfig(entry)

        return (
          <Tag
            key={index}
            closable
            onClose={() => removeFilter(property)}
          >
            {`${propertyDisplay} ${hasColon ? ':' : ''} ${entryDisplay || ''}`}
          </Tag>
        )
      })}
    </>
  )
}

export default FilterTags
