import { EyeOutlined, PlusOutlined } from '@ant-design/icons'
import { Checkbox as AntCheckbox, Button, Col, Modal, Row, Table } from 'antd'
import { format } from 'date-fns'
import React, { FC, useEffect } from 'react'

import { Labels } from '../../constants/labels'
import { useClaimDetailContext } from '../claims/context/claimDetailContext'
import Attachment from './attachment'
import { IAttachment, IAttachmentImage } from './attachment.interface'

import '@progress/kendo-theme-bootstrap/dist/all.scss'
import './attachment.scss'

const { Column } = Table

const AttachmentsList: FC = () => {
  const {
    attachment,
    setAttachment,
    sentAttachments,
    setIsCreatedFromSentAttachment,
  } = useClaimDetailContext()

  const [visibleAttachment, setVisibleAttachment] =
    React.useState<IAttachment>(null)
  const [showConfirmation, setShowConfirmation] = React.useState<boolean>(false)
  const [useLastAttachment, setUseLastAttachment] =
    React.useState<boolean>(true)

  const newAttachment = () => {
    if (sentAttachments != null && sentAttachments.length > 0) {
      setShowConfirmation(true)
    } else {
      setUseLastAttachment(false)
      newAttachmentWithConfirmation()
    }
  }

  const newAttachmentWithConfirmation = () => {
    let newAttachment: IAttachment
    if (useLastAttachment) {
      newAttachment = getLastAttachment()
    } else {
      newAttachment = getNewAttachment()
    }
    setVisibleAttachment(newAttachment)
    setAttachment(newAttachment)
    setShowConfirmation(false)
    setIsCreatedFromSentAttachment(true)
  }

  const showList = () => {
    setVisibleAttachment(null)
  }

  function getNewAttachment(): IAttachment {
    const newAttachment: IAttachment = {
      attachmentImages: new Array<IAttachmentImage>(),
      isSentAttachment: false,
      attachmentNumber: null,
      attachmentRecordID: null,
      attachmentId: null,
      narrative: null,
      payorRefId: null,
    }

    return newAttachment
  }

  function getLastAttachment(): IAttachment {
    //get latest nea attachment
    const sortedAttachments: IAttachment[] = sentAttachments?.sort(
      (a: IAttachment, b: IAttachment) => (a.sentDate > b.sentDate ? -1 : 1),
    )

    //could be the case of only having RES attachments
    if (sortedAttachments == null || sortedAttachments.length == 0) {
      return getNewAttachment()
    }

    const lastAttachment: IAttachment = sortedAttachments[0]
    const cloneImages: IAttachmentImage[] = []

    //create clone of images
    let counter: number = 1
    for (const img of lastAttachment.attachmentImages) {
      const newImage: IAttachmentImage = {
        imageId: counter,
        imageCaptureMethod: img.imageCaptureMethod,
        imageTypeName: img.imageTypeName,
        imageTypeId: img.imageTypeId,
        imageDateTaken: img.imageDateTaken,
        imageOrientationType: img.imageOrientationType,
        imageData: img.imageData,
        isNewImage: true,
        isLegacyImage: false,
        isDeleted: false,
      }
      counter++
      cloneImages.push(newImage)
    }

    //create clone of attachment
    const newAttachment: IAttachment = {
      attachmentImages: cloneImages,
      isSentAttachment: false,
      attachmentNumber: null,
      attachmentRecordID: null,
      attachmentId: null,
      narrative: lastAttachment.narrative,
      payorRefId: null,
    }

    return newAttachment
  }

  const sentClaimHasUnsentAttachment = () => {
    return (
      attachment !== null &&
      !attachment.isSentAttachment &&
      (attachment.narrative ||
        attachment.attachmentImages?.length > 0 ||
        attachment.payorRefId != null)
    )
  }

  useEffect(() => {
    //if there are sent attachments, we show the list, otherwise show either current attachment or new attachment
    if (sentAttachments !== null && sentAttachments.length > 0) {
      if (sentClaimHasUnsentAttachment()) {
        setVisibleAttachment(attachment)
      }
    } else {
      setShowConfirmation(false)
      setAttachment(attachment ?? getNewAttachment())
      setVisibleAttachment(attachment ?? getNewAttachment())
    }
  }, [visibleAttachment])

  const getAttachment = (
    attachment: IAttachment,
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setVisibleAttachment(attachment)
    setAttachment(attachment)
  }

  const handleChange = async () => {
    setUseLastAttachment(!useLastAttachment)
  }

  return (
    <>
      <div>
        {showConfirmation && (
          <Modal
            title='Create a New Attachment for this Claim'
            open={showConfirmation}
            onCancel={(e: any) => setShowConfirmation(false)}
            width={575}
            footer={[
              <Button
                key='submit'
                type='primary'
                onClick={() => newAttachmentWithConfirmation()}
              >
                Continue
              </Button>,
            ]}
          >
            <Col>
              <Col className='sent-attachments-modal-text'>
                <div>
                  Choose if you would like to include previous attachment images
                  when resending?
                </div>
              </Col>
              <Col>
                <AntCheckbox
                  checked={useLastAttachment}
                  onChange={handleChange}
                >
                  Include previous attachment images.
                </AntCheckbox>
              </Col>
            </Col>
          </Modal>
        )}
        {visibleAttachment !== null ? (
          <Attachment
            attachment={visibleAttachment}
            setAttachment={setAttachment}
            showList={showList}
          />
        ) : (
          <div className='sent-attachments-container'>
            <Row className='sent-attachments-header'>
              <Col className='sent-attachments-subheader'>
                <div>Sent Attachments</div>
              </Col>
              <Col>
                <Button
                  className='sent-attachments-button'
                  onClick={newAttachment}
                >
                  <PlusOutlined />
                  Create New Attachment
                </Button>
              </Col>
            </Row>
            <Table
              className='sent-attachments-table'
              size='middle'
              pagination={false}
              dataSource={sentAttachments?.sort(
                (a: IAttachment, b: IAttachment) =>
                  a.sentDate > b.sentDate ? -1 : 1,
              )}
              onRow={(record: IAttachment) => {
                return {
                  onClick: (event: any) => getAttachment(record, event),
                }
              }}
            >
              <Column
                title='Attachment No.'
                dataIndex='attachmentNumber'
                render={(attachmentNumber: string, attachment: IAttachment) => (
                  <>
                    {attachment.attachmentId ? Labels.unsent : attachmentNumber}
                  </>
                )}
              />
              <Column
                title='Date of Service'
                dataIndex='sentDate'
                key='DateOfService'
                render={(sentDate: string, attachment: IAttachment) => (
                  <>
                    {attachment.attachmentId
                      ? ''
                      : format(new Date(sentDate), 'MM/dd/yyyy')}
                  </>
                )}
              />
              <Column
                title='Time'
                dataIndex='sentDate'
                key='Time'
                render={(sentDate: string, attachment: IAttachment) => (
                  <>
                    {attachment.attachmentId
                      ? ''
                      : format(new Date(sentDate), 'hh:mm a')}{' '}
                  </>
                )}
              />
              <Column
                title=''
                dataIndex='ViewAttachment'
                key=''
                render={() => <EyeOutlined />}
              />
            </Table>
          </div>
        )}
      </div>
    </>
  )
}

export default AttachmentsList
