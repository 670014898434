import { isBefore, isValid } from 'date-fns'

export const validateBirthday = (value: string) => {
  const isValidDate: boolean = isValid(new Date(value))
  const isPastDate: boolean = isBefore(new Date(value), new Date())

  let error: string = null
  if (!isValidDate || value.length !== 10) error = 'e.g. MM/DD/YYYY'
  else if (!isPastDate) error = 'DOB must be in the past'

  if (error) return Promise.reject(new Error(error))
  else return Promise.resolve()
}
