import { AxiosRequestHeaders } from 'axios'

import { trellisRefreshTokenPath } from 'trellis:api/authentication/authenticationApi'

import { IAuthenticatedApiModel } from './api'
import trellisConfiguration from './config'

/** endpoints that we should ignore auth failure on, like preventing the logout or token refresh call from trying to refresh the token */
export const isIgnoreAuthFailureEndpoint = (url: string) => {
  const lowerUrl = url?.toLowerCase()
  return (
    lowerUrl.endsWith('/logout') ||
    lowerUrl.includes(trellisRefreshTokenPath?.toLowerCase())
  )
}

export const isTrellisApi = (url: string) => {
  return (
    (url && url.includes(trellisConfiguration.trellis_apiUrl)) ||
    url.includes(trellisConfiguration.attachments_apiUrl)
  )
}

export const isAuthedTrellisApi = (
  url: string,
  headers: AxiosRequestHeaders,
) => {
  return (
    (isTrellisApi(url) &&
      headers &&
      (headers['Authorization'] !== undefined ||
        headers['authtoken'] !== undefined)) == true
  )
}

export const updateTrellisAuthHeaders = (
  headers: AxiosRequestHeaders,
  newAuth: IAuthenticatedApiModel,
) => {
  if (headers['Authorization'])
    headers['Authorization'] = `Bearer ${newAuth.AccessToken}`
  if (headers['authtoken']) headers['authtoken'] = newAuth.AuthToken
}

export const getPathWithQueryString = (path: string, queryParameters: any) : string => {
  if(!path || !queryParameters){
    return
  }

  const queryString = new URLSearchParams(queryParameters)
  if(!queryString) {
    return
  }

  return path + '?' + queryString.toString()
}
