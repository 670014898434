import { Form, Switch } from 'antd'
import React, { FC } from 'react'

interface FormSwitchProps {
  checked: boolean
  checkedChildren?: string
  className?: string
  disabled?: boolean
  name: string
  onChangeHandler?: () => void
  unCheckedChildren?: string
}

export const FormSwitch: FC<FormSwitchProps> = ({
  checked,
  checkedChildren = 'YES',
  className,
  disabled = false,
  name,
  onChangeHandler,
  unCheckedChildren = 'NO',
}) => {
  return (
    <Form.Item
      className={className}
      name={name}
    >
      <Switch
        checked={checked}
        checkedChildren={checkedChildren}
        disabled={disabled}
        onChange={onChangeHandler}
        unCheckedChildren={unCheckedChildren}
      />
    </Form.Item>
  )
}
