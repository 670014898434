import { createContext, FC, useContext, useState } from 'react'

const PostAuthLayoutContext = createContext(null)

const PostAuthLayoutProvider: FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [totalUnreadMessages, setTotalUnreadMessages] = useState<number>(0)

  return (
    <PostAuthLayoutContext.Provider
      value={{
        setTotalUnreadMessages,
        totalUnreadMessages,
      }}
    >
      {children}
    </PostAuthLayoutContext.Provider>
  )
}

export const usePostAuthLayoutContext = () => {
  const context = useContext(PostAuthLayoutContext)
  if (context === undefined)
    throw new Error('Context must be used within a Provider')

  return context
}

export default PostAuthLayoutProvider
