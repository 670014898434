import { observer, useObservable } from '@legendapp/state/react'
import { FC, ReactElement, ReactNode } from 'react'

import styles from './Tabs.module.scss'

// TODO: needs some sort of identifier that can be referenced outside of the Tabs
export type TabItem = {
  content: ReactElement | JSX.Element
  render?: ReactElement | JSX.Element | string
  title: string
  /**
   * Id for reference by automated tests
   */
  testId: string
}

export interface TabsProps {
  defaultActiveIndex?: number
  items: TabItem[]
  onChange?: (value: string) => void
  extraContent?: ReactNode
}

export const Tabs: FC<TabsProps> = observer(
  ({ defaultActiveIndex = 0, items, onChange = null, extraContent }) => {
    const activeIndex$ = useObservable<number>(defaultActiveIndex)

    const root: HTMLElement = document.querySelector(':root')
    root?.style?.setProperty('--tab-count', `${items.length}`)

    const handleChange = (item: TabItem, index: number) => {
      root?.style?.setProperty(
        '--active-tab-location',
        `${index !== 0 ? (1 / items.length) * index * 100 : index}%`,
      )

      activeIndex$.set(index)

      if (onChange) onChange(item.title)
    }

    return (
      <div className={styles['vyne-tabs']}>
        <div className='flex justify-between align-center'>
          <div className={styles['vyne-tabs__main-content']}>
            <div className={styles['vyne-tabs__wrapper']}>
              <ul className={styles['vyne-tabs__container']}>
                {items.map((item, index) => (
                  <li
                    data-testid={item.testId + `-tab`}
                    className={`${styles['vyne-tab']} ${
                      styles[
                        `vyne-tab--${
                          activeIndex$.get() === index ? 'active' : 'default'
                        }`
                      ]
                    }`}
                    key={`vyne-tab--${item.title}`}
                  >
                    <div
                      className={styles['vyne-tab__title']}
                      onClick={() => handleChange(item, index)}
                    >
                      {item.render ? item.render : item.title}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div
              className={styles['vyne-tabs__slider']}
              role='presentation'
            />
          </div>
          <div className={styles['vyne-tabs__extra-content']}>
            {extraContent}
          </div>
        </div>
        <div className={styles['vyne-tabs__content']}>
          {items[activeIndex$.get()].content}
        </div>
      </div>
    )
  },
)
