export const validatePassword = (
  value: string,
  formData: any,
  property: string,
) => {
  if (!value) return

  const isValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(
    value,
  )
  let error: any = null

  if (!isValid) error = `Password is missing requirements`

  return error
}

export const validateConfirmPassword = (
  value: string,
  formData: any,
  property: string,
) => {
  if (!value) return

  let error: any = null
  if (value !== formData.NewPassword) error = 'Passwords do not match'

  return error
}
