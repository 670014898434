/* tslint:disable */
/* eslint-disable */
/**
 * GetClaimsPMG
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BuildQueryRequest
 */
export interface BuildQueryRequest {
    /**
     * 
     * @type {boolean}
     * @memberof BuildQueryRequest
     */
    'Queued'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof BuildQueryRequest
     */
    'Hidden'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BuildQueryRequest
     */
    'Range'?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildQueryRequest
     */
    'Offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildQueryRequest
     */
    'SortColumn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuildQueryRequest
     */
    'SortDirection'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof BuildQueryRequest
     */
    'Filters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof BuildQueryRequest
     */
    'SearchText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuildQueryRequest
     */
    'Serial'?: string | null;
}
/**
 * 
 * @export
 * @interface ClaimModel
 */
export interface ClaimModel {
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'CustomerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'ClaimID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'PatientName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'SubscriberName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'Carrier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'CarrierAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'CarrierCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'CarrierState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'CarrierZip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'Remarks'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'FeeTotal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'Tin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'TransmitDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'BillingAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'BillingCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'NpiGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'Predetermination'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'ServiceDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'LastUpdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'TreatmentAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'TreatmentCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'Install'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'Status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'StatusTooltip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'StatusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'StatusDescriptionTooltip'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimModel
     */
    'HasEob'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimModel
     */
    'HasEra'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimModel
     */
    'HasAttachment'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'AttachmentId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimModel
     */
    'Ignored'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimModel
     */
    'Queued'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'CobFlag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'TreatingSignature'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'Username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'Employer'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimModel
     */
    'FilterTotal'?: number;
}
/**
 * 
 * @export
 * @interface ClaimsDeleteRequest
 */
export interface ClaimsDeleteRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsDeleteRequest
     */
    'Queued'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsDeleteRequest
     */
    'Hidden'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClaimsDeleteRequest
     */
    'Range'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClaimsDeleteRequest
     */
    'Offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClaimsDeleteRequest
     */
    'SortColumn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsDeleteRequest
     */
    'SortDirection'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ClaimsDeleteRequest
     */
    'Filters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsDeleteRequest
     */
    'SearchText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsDeleteRequest
     */
    'Serial'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsDeleteRequest
     */
    'ProcessAll': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClaimsDeleteRequest
     */
    'ClaimList'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface ClaimsDeleteResponse
 */
export interface ClaimsDeleteResponse {
    /**
     * 
     * @type {Array<DeleteClaimResponse>}
     * @memberof ClaimsDeleteResponse
     */
    'claimResponses'?: Array<DeleteClaimResponse> | null;
}
/**
 * 
 * @export
 * @interface ClaimsExportRequest
 */
export interface ClaimsExportRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsExportRequest
     */
    'Queued'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsExportRequest
     */
    'Hidden'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClaimsExportRequest
     */
    'Range'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClaimsExportRequest
     */
    'Offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClaimsExportRequest
     */
    'SortColumn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsExportRequest
     */
    'SortDirection'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ClaimsExportRequest
     */
    'Filters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsExportRequest
     */
    'SearchText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsExportRequest
     */
    'Serial'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClaimsExportRequest
     */
    'ColumnList': Array<string>;
}
/**
 * 
 * @export
 * @interface ClaimsExportResponse
 */
export interface ClaimsExportResponse {
    /**
     * 
     * @type {string}
     * @memberof ClaimsExportResponse
     */
    'CsvLink'?: string | null;
}
/**
 * 
 * @export
 * @interface ClaimsIgnoreAttachmentRequirementsRequest
 */
export interface ClaimsIgnoreAttachmentRequirementsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'Queued'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'Hidden'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'Range'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'Offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'SortColumn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'SortDirection'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'Filters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'SearchText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'Serial'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'ProcessAll': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'ClaimList'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface ClaimsIgnoreRequest
 */
export interface ClaimsIgnoreRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsIgnoreRequest
     */
    'Queued'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsIgnoreRequest
     */
    'Hidden'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClaimsIgnoreRequest
     */
    'Range'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClaimsIgnoreRequest
     */
    'Offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIgnoreRequest
     */
    'SortColumn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIgnoreRequest
     */
    'SortDirection'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ClaimsIgnoreRequest
     */
    'Filters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIgnoreRequest
     */
    'SearchText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIgnoreRequest
     */
    'Serial'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsIgnoreRequest
     */
    'MarkAsVisible': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsIgnoreRequest
     */
    'ProcessAll': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClaimsIgnoreRequest
     */
    'ClaimList'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface ClaimsStatusRequest
 */
export interface ClaimsStatusRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsStatusRequest
     */
    'Queued'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsStatusRequest
     */
    'Hidden'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClaimsStatusRequest
     */
    'Range'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClaimsStatusRequest
     */
    'Offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClaimsStatusRequest
     */
    'SortColumn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsStatusRequest
     */
    'SortDirection'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ClaimsStatusRequest
     */
    'Filters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsStatusRequest
     */
    'SearchText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsStatusRequest
     */
    'Serial'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsStatusRequest
     */
    'HoldClaims': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsStatusRequest
     */
    'ProcessAll': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClaimsStatusRequest
     */
    'ClaimList'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface DeleteClaimResponse
 */
export interface DeleteClaimResponse {
    /**
     * 
     * @type {number}
     * @memberof DeleteClaimResponse
     */
    'claimId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeleteClaimResponse
     */
    'result'?: number;
}
/**
 * 
 * @export
 * @interface FilterDropdowns
 */
export interface FilterDropdowns {
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'Carrier'?: Array<string> | null;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof FilterDropdowns
     */
    'Queued'?: Array<boolean> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'BillingAddress'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'Tin'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'Install'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'TreatmentAddress'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'TreatmentCity'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'BillingCity'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'TreatingSignature'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'CobFlag'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'NpiGroup'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'Predetermination'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'Username'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'Status'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface GetClaimsFiltersResponse
 */
export interface GetClaimsFiltersResponse {
    /**
     * 
     * @type {number}
     * @memberof GetClaimsFiltersResponse
     */
    'FilteredClaims'?: number;
    /**
     * 
     * @type {FilterDropdowns}
     * @memberof GetClaimsFiltersResponse
     */
    'FilterDropdowns'?: FilterDropdowns;
}
/**
 * 
 * @export
 * @interface GetPaginatedClaimsResponse
 */
export interface GetPaginatedClaimsResponse {
    /**
     * 
     * @type {Array<ClaimModel>}
     * @memberof GetPaginatedClaimsResponse
     */
    'Claims'?: Array<ClaimModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedClaimsResponse
     */
    'FilteredClaims'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedClaimsResponse
     */
    'TotalClaims'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetPaginatedClaimsResponse
     */
    'HasUnsentClaims'?: boolean;
    /**
     * 
     * @type {FilterDropdowns}
     * @memberof GetPaginatedClaimsResponse
     */
    'FilterDropdowns'?: FilterDropdowns;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}

/**
 * ClaimsPmgApi - axios parameter creator
 * @export
 */
export const ClaimsPmgApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {ClaimsExportRequest} claimsExportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsPmgCreateClaimsExport: async (pmgId: number, pmgRegionId: number, claimsExportRequest: ClaimsExportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pmgId' is not null or undefined
            assertParamExists('claimsPmgCreateClaimsExport', 'pmgId', pmgId)
            // verify required parameter 'pmgRegionId' is not null or undefined
            assertParamExists('claimsPmgCreateClaimsExport', 'pmgRegionId', pmgRegionId)
            // verify required parameter 'claimsExportRequest' is not null or undefined
            assertParamExists('claimsPmgCreateClaimsExport', 'claimsExportRequest', claimsExportRequest)
            const localVarPath = `/{pmgId}/region/{pmgRegionId}/claims/export`
                .replace(`{${"pmgId"}}`, encodeURIComponent(String(pmgId)))
                .replace(`{${"pmgRegionId"}}`, encodeURIComponent(String(pmgRegionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(claimsExportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {ClaimsDeleteRequest} claimsDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsPmgDeleteClaims: async (pmgId: number, pmgRegionId: number, claimsDeleteRequest: ClaimsDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pmgId' is not null or undefined
            assertParamExists('claimsPmgDeleteClaims', 'pmgId', pmgId)
            // verify required parameter 'pmgRegionId' is not null or undefined
            assertParamExists('claimsPmgDeleteClaims', 'pmgRegionId', pmgRegionId)
            // verify required parameter 'claimsDeleteRequest' is not null or undefined
            assertParamExists('claimsPmgDeleteClaims', 'claimsDeleteRequest', claimsDeleteRequest)
            const localVarPath = `/{pmgId}/region/{pmgRegionId}/claims`
                .replace(`{${"pmgId"}}`, encodeURIComponent(String(pmgId)))
                .replace(`{${"pmgRegionId"}}`, encodeURIComponent(String(pmgRegionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(claimsDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {BuildQueryRequest} buildQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsPmgGetClaims: async (pmgId: number, pmgRegionId: number, buildQueryRequest: BuildQueryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pmgId' is not null or undefined
            assertParamExists('claimsPmgGetClaims', 'pmgId', pmgId)
            // verify required parameter 'pmgRegionId' is not null or undefined
            assertParamExists('claimsPmgGetClaims', 'pmgRegionId', pmgRegionId)
            // verify required parameter 'buildQueryRequest' is not null or undefined
            assertParamExists('claimsPmgGetClaims', 'buildQueryRequest', buildQueryRequest)
            const localVarPath = `/{pmgId}/region/{pmgRegionId}/claims`
                .replace(`{${"pmgId"}}`, encodeURIComponent(String(pmgId)))
                .replace(`{${"pmgRegionId"}}`, encodeURIComponent(String(pmgRegionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildQueryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {BuildQueryRequest} buildQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsPmgGetFilterDropdowns: async (pmgId: number, pmgRegionId: number, buildQueryRequest: BuildQueryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pmgId' is not null or undefined
            assertParamExists('claimsPmgGetFilterDropdowns', 'pmgId', pmgId)
            // verify required parameter 'pmgRegionId' is not null or undefined
            assertParamExists('claimsPmgGetFilterDropdowns', 'pmgRegionId', pmgRegionId)
            // verify required parameter 'buildQueryRequest' is not null or undefined
            assertParamExists('claimsPmgGetFilterDropdowns', 'buildQueryRequest', buildQueryRequest)
            const localVarPath = `/{pmgId}/region/{pmgRegionId}/claims/filter-dropdowns`
                .replace(`{${"pmgId"}}`, encodeURIComponent(String(pmgId)))
                .replace(`{${"pmgRegionId"}}`, encodeURIComponent(String(pmgRegionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildQueryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {ClaimsIgnoreRequest} claimsIgnoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsPmgUpdateClaimsIgnore: async (pmgId: number, pmgRegionId: number, claimsIgnoreRequest: ClaimsIgnoreRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pmgId' is not null or undefined
            assertParamExists('claimsPmgUpdateClaimsIgnore', 'pmgId', pmgId)
            // verify required parameter 'pmgRegionId' is not null or undefined
            assertParamExists('claimsPmgUpdateClaimsIgnore', 'pmgRegionId', pmgRegionId)
            // verify required parameter 'claimsIgnoreRequest' is not null or undefined
            assertParamExists('claimsPmgUpdateClaimsIgnore', 'claimsIgnoreRequest', claimsIgnoreRequest)
            const localVarPath = `/{pmgId}/region/{pmgRegionId}/claims/ignore-claim`
                .replace(`{${"pmgId"}}`, encodeURIComponent(String(pmgId)))
                .replace(`{${"pmgRegionId"}}`, encodeURIComponent(String(pmgRegionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(claimsIgnoreRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {ClaimsIgnoreAttachmentRequirementsRequest} claimsIgnoreAttachmentRequirementsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsPmgUpdateClaimsIgnoreAttachmentRequirements: async (pmgId: number, pmgRegionId: number, claimsIgnoreAttachmentRequirementsRequest: ClaimsIgnoreAttachmentRequirementsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pmgId' is not null or undefined
            assertParamExists('claimsPmgUpdateClaimsIgnoreAttachmentRequirements', 'pmgId', pmgId)
            // verify required parameter 'pmgRegionId' is not null or undefined
            assertParamExists('claimsPmgUpdateClaimsIgnoreAttachmentRequirements', 'pmgRegionId', pmgRegionId)
            // verify required parameter 'claimsIgnoreAttachmentRequirementsRequest' is not null or undefined
            assertParamExists('claimsPmgUpdateClaimsIgnoreAttachmentRequirements', 'claimsIgnoreAttachmentRequirementsRequest', claimsIgnoreAttachmentRequirementsRequest)
            const localVarPath = `/{pmgId}/region/{pmgRegionId}/claims/ignore-attachment-requirements`
                .replace(`{${"pmgId"}}`, encodeURIComponent(String(pmgId)))
                .replace(`{${"pmgRegionId"}}`, encodeURIComponent(String(pmgRegionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(claimsIgnoreAttachmentRequirementsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {ClaimsStatusRequest} claimsStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsPmgUpdateClaimsStatus: async (pmgId: number, pmgRegionId: number, claimsStatusRequest: ClaimsStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pmgId' is not null or undefined
            assertParamExists('claimsPmgUpdateClaimsStatus', 'pmgId', pmgId)
            // verify required parameter 'pmgRegionId' is not null or undefined
            assertParamExists('claimsPmgUpdateClaimsStatus', 'pmgRegionId', pmgRegionId)
            // verify required parameter 'claimsStatusRequest' is not null or undefined
            assertParamExists('claimsPmgUpdateClaimsStatus', 'claimsStatusRequest', claimsStatusRequest)
            const localVarPath = `/{pmgId}/region/{pmgRegionId}/claims/status`
                .replace(`{${"pmgId"}}`, encodeURIComponent(String(pmgId)))
                .replace(`{${"pmgRegionId"}}`, encodeURIComponent(String(pmgRegionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(claimsStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClaimsPmgApi - functional programming interface
 * @export
 */
export const ClaimsPmgApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClaimsPmgApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {ClaimsExportRequest} claimsExportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimsPmgCreateClaimsExport(pmgId: number, pmgRegionId: number, claimsExportRequest: ClaimsExportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClaimsExportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimsPmgCreateClaimsExport(pmgId, pmgRegionId, claimsExportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {ClaimsDeleteRequest} claimsDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimsPmgDeleteClaims(pmgId: number, pmgRegionId: number, claimsDeleteRequest: ClaimsDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClaimsDeleteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimsPmgDeleteClaims(pmgId, pmgRegionId, claimsDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {BuildQueryRequest} buildQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimsPmgGetClaims(pmgId: number, pmgRegionId: number, buildQueryRequest: BuildQueryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaginatedClaimsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimsPmgGetClaims(pmgId, pmgRegionId, buildQueryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {BuildQueryRequest} buildQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimsPmgGetFilterDropdowns(pmgId: number, pmgRegionId: number, buildQueryRequest: BuildQueryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClaimsFiltersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimsPmgGetFilterDropdowns(pmgId, pmgRegionId, buildQueryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {ClaimsIgnoreRequest} claimsIgnoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimsPmgUpdateClaimsIgnore(pmgId: number, pmgRegionId: number, claimsIgnoreRequest: ClaimsIgnoreRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimsPmgUpdateClaimsIgnore(pmgId, pmgRegionId, claimsIgnoreRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {ClaimsIgnoreAttachmentRequirementsRequest} claimsIgnoreAttachmentRequirementsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimsPmgUpdateClaimsIgnoreAttachmentRequirements(pmgId: number, pmgRegionId: number, claimsIgnoreAttachmentRequirementsRequest: ClaimsIgnoreAttachmentRequirementsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimsPmgUpdateClaimsIgnoreAttachmentRequirements(pmgId, pmgRegionId, claimsIgnoreAttachmentRequirementsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {ClaimsStatusRequest} claimsStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimsPmgUpdateClaimsStatus(pmgId: number, pmgRegionId: number, claimsStatusRequest: ClaimsStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimsPmgUpdateClaimsStatus(pmgId, pmgRegionId, claimsStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClaimsPmgApi - factory interface
 * @export
 */
export const ClaimsPmgApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClaimsPmgApiFp(configuration)
    return {
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {ClaimsExportRequest} claimsExportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsPmgCreateClaimsExport(pmgId: number, pmgRegionId: number, claimsExportRequest: ClaimsExportRequest, options?: any): AxiosPromise<ClaimsExportResponse> {
            return localVarFp.claimsPmgCreateClaimsExport(pmgId, pmgRegionId, claimsExportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {ClaimsDeleteRequest} claimsDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsPmgDeleteClaims(pmgId: number, pmgRegionId: number, claimsDeleteRequest: ClaimsDeleteRequest, options?: any): AxiosPromise<ClaimsDeleteResponse> {
            return localVarFp.claimsPmgDeleteClaims(pmgId, pmgRegionId, claimsDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {BuildQueryRequest} buildQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsPmgGetClaims(pmgId: number, pmgRegionId: number, buildQueryRequest: BuildQueryRequest, options?: any): AxiosPromise<GetPaginatedClaimsResponse> {
            return localVarFp.claimsPmgGetClaims(pmgId, pmgRegionId, buildQueryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {BuildQueryRequest} buildQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsPmgGetFilterDropdowns(pmgId: number, pmgRegionId: number, buildQueryRequest: BuildQueryRequest, options?: any): AxiosPromise<GetClaimsFiltersResponse> {
            return localVarFp.claimsPmgGetFilterDropdowns(pmgId, pmgRegionId, buildQueryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {ClaimsIgnoreRequest} claimsIgnoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsPmgUpdateClaimsIgnore(pmgId: number, pmgRegionId: number, claimsIgnoreRequest: ClaimsIgnoreRequest, options?: any): AxiosPromise<void> {
            return localVarFp.claimsPmgUpdateClaimsIgnore(pmgId, pmgRegionId, claimsIgnoreRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {ClaimsIgnoreAttachmentRequirementsRequest} claimsIgnoreAttachmentRequirementsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsPmgUpdateClaimsIgnoreAttachmentRequirements(pmgId: number, pmgRegionId: number, claimsIgnoreAttachmentRequirementsRequest: ClaimsIgnoreAttachmentRequirementsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.claimsPmgUpdateClaimsIgnoreAttachmentRequirements(pmgId, pmgRegionId, claimsIgnoreAttachmentRequirementsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pmgId 
         * @param {number} pmgRegionId 
         * @param {ClaimsStatusRequest} claimsStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsPmgUpdateClaimsStatus(pmgId: number, pmgRegionId: number, claimsStatusRequest: ClaimsStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.claimsPmgUpdateClaimsStatus(pmgId, pmgRegionId, claimsStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClaimsPmgApi - object-oriented interface
 * @export
 * @class ClaimsPmgApi
 * @extends {BaseAPI}
 */
export class ClaimsPmgApi extends BaseAPI {
    /**
     * 
     * @param {number} pmgId 
     * @param {number} pmgRegionId 
     * @param {ClaimsExportRequest} claimsExportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsPmgApi
     */
    public claimsPmgCreateClaimsExport(pmgId: number, pmgRegionId: number, claimsExportRequest: ClaimsExportRequest, options?: AxiosRequestConfig) {
        return ClaimsPmgApiFp(this.configuration).claimsPmgCreateClaimsExport(pmgId, pmgRegionId, claimsExportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pmgId 
     * @param {number} pmgRegionId 
     * @param {ClaimsDeleteRequest} claimsDeleteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsPmgApi
     */
    public claimsPmgDeleteClaims(pmgId: number, pmgRegionId: number, claimsDeleteRequest: ClaimsDeleteRequest, options?: AxiosRequestConfig) {
        return ClaimsPmgApiFp(this.configuration).claimsPmgDeleteClaims(pmgId, pmgRegionId, claimsDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pmgId 
     * @param {number} pmgRegionId 
     * @param {BuildQueryRequest} buildQueryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsPmgApi
     */
    public claimsPmgGetClaims(pmgId: number, pmgRegionId: number, buildQueryRequest: BuildQueryRequest, options?: AxiosRequestConfig) {
        return ClaimsPmgApiFp(this.configuration).claimsPmgGetClaims(pmgId, pmgRegionId, buildQueryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pmgId 
     * @param {number} pmgRegionId 
     * @param {BuildQueryRequest} buildQueryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsPmgApi
     */
    public claimsPmgGetFilterDropdowns(pmgId: number, pmgRegionId: number, buildQueryRequest: BuildQueryRequest, options?: AxiosRequestConfig) {
        return ClaimsPmgApiFp(this.configuration).claimsPmgGetFilterDropdowns(pmgId, pmgRegionId, buildQueryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pmgId 
     * @param {number} pmgRegionId 
     * @param {ClaimsIgnoreRequest} claimsIgnoreRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsPmgApi
     */
    public claimsPmgUpdateClaimsIgnore(pmgId: number, pmgRegionId: number, claimsIgnoreRequest: ClaimsIgnoreRequest, options?: AxiosRequestConfig) {
        return ClaimsPmgApiFp(this.configuration).claimsPmgUpdateClaimsIgnore(pmgId, pmgRegionId, claimsIgnoreRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pmgId 
     * @param {number} pmgRegionId 
     * @param {ClaimsIgnoreAttachmentRequirementsRequest} claimsIgnoreAttachmentRequirementsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsPmgApi
     */
    public claimsPmgUpdateClaimsIgnoreAttachmentRequirements(pmgId: number, pmgRegionId: number, claimsIgnoreAttachmentRequirementsRequest: ClaimsIgnoreAttachmentRequirementsRequest, options?: AxiosRequestConfig) {
        return ClaimsPmgApiFp(this.configuration).claimsPmgUpdateClaimsIgnoreAttachmentRequirements(pmgId, pmgRegionId, claimsIgnoreAttachmentRequirementsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pmgId 
     * @param {number} pmgRegionId 
     * @param {ClaimsStatusRequest} claimsStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsPmgApi
     */
    public claimsPmgUpdateClaimsStatus(pmgId: number, pmgRegionId: number, claimsStatusRequest: ClaimsStatusRequest, options?: AxiosRequestConfig) {
        return ClaimsPmgApiFp(this.configuration).claimsPmgUpdateClaimsStatus(pmgId, pmgRegionId, claimsStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


