import { Observable } from '@legendapp/state'
import { observer, useComputed, useObservable } from '@legendapp/state/react'
import { FC } from 'react'

import { Button } from 'ui'

import styles from './_downloads.module.scss'
import { DownloadItemConfirmationModal } from './DownloadItemConfirmationModal'
import { Download } from './utils/downloads-helpers'

import DownloadIcon from 'trellis:assets/cloud-download-icon.svg?react'
import SendIcon from 'trellis:assets/send-icon.svg?react'

interface DownloadItemContentProps {
  download: Download
  isAdditional?: boolean
}

const dlButton = ({ hasConfirmationModal, url, isOpen }) => {
  return (
    <Button
      href={!hasConfirmationModal.get() ? url : null}
      icon={
        <span
          className={`anticon ${styles['download-item__icons--vertical-align']}`}
        >
          <DownloadIcon />
        </span>
      }
      label='Download'
      onClick={hasConfirmationModal.get() ? () => isOpen.set(true) : null}
      type='primary'
    />
  )
}

export const DownloadItemContent: FC<DownloadItemContentProps> = observer(
  ({ download, isAdditional = false }) => {
    const {
      confirmationPromptMessage,
      confirmationPromptTitle,
      installGuideUrl,
      logoUrl,
      mailToBody,
      mailToSubject,
      notes,
      systemReqsUrl,
      title,
      url,
      versionNumber,
    } = download

    const hasConfirmationModal = useComputed(
      () => confirmationPromptTitle && confirmationPromptMessage,
    )
    const isConfirmationModalOpen = useObservable<boolean>(false)

    return (
      <>
        <DownloadItemConfirmationModal
          confirmationPromptMessage={confirmationPromptMessage}
          confirmationPromptTitle={confirmationPromptTitle}
          isOpen={isConfirmationModalOpen}
          title={title}
          url={url}
        />
        <section
          className={`${styles['download-item__card']} ${isAdditional ? styles['download-item__card--additional'] : ''}`}
        >
          <img
            alt={`${title} logo`}
            className={styles['download-item__img']}
            src={logoUrl}
          />
          <div className='flex-column gap-050'>
            <div className='flex-row items-center gap-050'>
              <h6 className={styles['download-item__title']}>{title}</h6>
              <p className={styles['download-item__version-tag']}>
                Version: {versionNumber}
              </p>
            </div>
            {notes && <p className={styles['download-item__notes']}>{notes}</p>}
            {confirmationPromptTitle && (
              <p className={styles['download-item__prompt-title']}>
                <span>NOTE:</span> {confirmationPromptTitle}
              </p>
            )}
            <div className={styles['download-item__guide-container']}>
              {systemReqsUrl && (
                <a
                  className={styles['download-item__guide-link']}
                  href={systemReqsUrl}
                  rel='noreferrer'
                  target='_blank'
                >
                  System Requirements
                </a>
              )}
              {installGuideUrl && (
                <>
                  <span></span>
                  <a
                    className={styles['download-item__guide-link']}
                    href={installGuideUrl}
                    rel='noreferrer'
                    target='_blank'
                  >
                    Installation Guide
                  </a>
                </>
              )}
              {isAdditional && (
                <div
                  className={
                    styles['download-item__card--additional-button-container']
                  }
                >
                  {dlButton({
                    hasConfirmationModal,
                    url,
                    isOpen: isConfirmationModalOpen,
                  })}
                </div>
              )}
            </div>
          </div>
          {!isAdditional && (
            <div className='flex-row gap-050'>
              {mailToBody && mailToSubject && (
                <Button
                  href={`mailto:?body=${mailToBody}&subject=${mailToSubject}`}
                  icon={
                    <span
                      className={`anticon ${styles['download-item__icons--vertical-align']}`}
                    >
                      <SendIcon />
                    </span>
                  }
                  label='Share Download'
                />
              )}

              {dlButton({
                hasConfirmationModal,
                url,
                isOpen: isConfirmationModalOpen,
              })}
            </div>
          )}
        </section>
      </>
    )
  },
)
