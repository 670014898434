import { Form, Switch } from 'antd'
import React from 'react'

export const FormSwitch = ({
  name,
  checked,
  disabled,
  checkedChildren = 'YES',
  unCheckedChildren = 'NO',
  onChange,
  ...others
}: any) => {
  return (
    <Form.Item name={name}>
      <Switch
        checked={checked}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        onChange={() => onChange()}
        disabled={disabled}
        {...others}
      />
    </Form.Item>
  )
}
