import { ImageType } from '../../api/attachment/attachment-client/api'
import { IAttachment, IAttachmentImage } from './attachment.interface'
import { MaxAttachmentSize } from './constants'

export const overMaxSize = (
  attachment: IAttachment,
  attachmentImage: IAttachmentImage,
): boolean => {
  const fileNames: string[] = []

  let totalSize: number = 0

  for (const img of attachment.attachmentImages) {
    if (img && img.imageData && !img.isDeleted) {
      fileNames.push(img.imageData.name)
      totalSize += img.imageData.size
    }
  }

  if (!(fileNames.indexOf(attachmentImage.imageData.name) > -1)) {
    totalSize += attachmentImage.imageData.size
  }

  return totalSize > MaxAttachmentSize
}

export const isAttachmentTypeXray = (
  imageTypes: ImageType[],
  attachmentTypeId: string,
): boolean => {
  const typeMatch = imageTypes?.find(
    (imageType) => imageType.imageTypeId.toString() === attachmentTypeId,
  )
  if (typeMatch?.isXray) {
    return true
  }
  return false
}
