import { FormInput } from '../../../../_siteWide/form/formInput'
import { useClaimDetailContext } from '../../../context/claimDetailContext'

import '../../content/ProceduresContent.scss'

export const DiagnosisControls = () => {
  const { claim, setClaim } = useClaimDetailContext()
  return (
    <div className='diagnosis-controls'>
      <FormInput
        name='CodeListQualifier'
        element={claim}
        setElement={setClaim}
        initialValue={claim.CodeListQualifier}
        label='Code List Qualifier'
      />
      <div className='diagnosis-controls__row'>
        <FormInput
          name='DiagCodeA'
          element={claim}
          setElement={setClaim}
          initialValue={claim.DiagCodeA}
          label='Diag Code A'
        />
        <FormInput
          name='DiagCodeB'
          element={claim}
          setElement={setClaim}
          initialValue={claim.DiagCodeB}
          label='Diag Code B'
        />
      </div>
      <div className='diagnosis-controls__row'>
        <FormInput
          name='DiagCodeC'
          element={claim}
          setElement={setClaim}
          initialValue={claim.DiagCodeC}
          label='Diag Code C'
        />
        <FormInput
          name='DiagCodeD'
          element={claim}
          setElement={setClaim}
          initialValue={claim.DiagCodeD}
          label='Diag Code D'
        />
      </div>
    </div>
  )
}
