import { ClaimArchiveGrid } from './components/ClaimArchiveGrid'
import { ClaimArchiveToolbar } from './components/ClaimArchiveToolbar'
import { ClaimArchiveContextProvider } from './context/ClaimArchiveContext'

const ClaimArchiveContent = () => {
  return (
    <article className='page-content__container page-content__container--with-page-header'>
      <h1 className='page-content-header__title'>Claim Search</h1>
      <section>
        <ClaimArchiveGrid toolbar={<ClaimArchiveToolbar />} />
      </section>
    </article>
  )
}

const ClaimArchive = () => {
  return (
    <ClaimArchiveContextProvider>
      <ClaimArchiveContent />
    </ClaimArchiveContextProvider>
  )
}

export default ClaimArchive
