import {
  Document,
  DocumentType,
  TrellisRelationshipTypes,
} from '../../api/attachment-standalone/attachment-standalone-client'

export type ImageRules = {
  narrativeDisabled: boolean
  recipientReferenceNumberDisabled: boolean
  recipientReferenceNumberRequired: boolean
  claimPriorAuthorizationDisabled: boolean
}

export type ImageDataProps = {
  rowId?: number
  rowKey?: string
  imageDataThumbnail: string
  imageData: File
  dateTaken?: string
  imageType?: DocumentType
  orientation?: string
  imageChanged?: boolean
  document?: Document
  acquisitionType?: string
}

export type AttachmentStatusFilters = {
  currentPage: number
  pageSize: number
  status: string
}

export type SentImageDataProps = {
  rowId: number
  imageData: File
  dateTaken: string
  imageType: string
}

export type CarrierInfo = {
  carrierReferenceNumber: string
  carrierName: string
  carrierId?: string
  carrierMasterId?: number
}

export type PatientInfo = {
  patientId: string
  fastAttachPatientId?: number
  fastAttachInsuranceId?: number
  fastAttachProviderId?: number
  syncId?: string
  patientFirstName: string
  patientMiddleName: string
  patientLastName: string
  patientDateOfBirth: string
  patientGender: string
  patientSuffix: string

  subscriberRelationship: string
  subscriberFirstName: string
  subscriberMiddleName: string
  subscriberLastName: string
  subscriberBirthDate: string
  subscriberGender: string
  subscriberSuffix: string

  insuranceIsPrimary: boolean
  carrierName: string
  carrierId: string
  masterId: number
  memberId: string
  groupNumber: string
  providerId: number
  providerFullName: string
  providerFirstName: string
  providerLastName: string
  providerNPI: string
  providerServiceType: string
  providerLicenseNumber: string
  providerTaxId: string
  providerTaxonomyCode: string
  providerIsDefault: boolean
}

export const emptyPatientInfo: PatientInfo = {
  patientId: '',
  patientFirstName: '',
  patientMiddleName: '',
  patientLastName: '',
  patientDateOfBirth: '',
  patientGender: '',
  patientSuffix: '',

  subscriberRelationship: TrellisRelationshipTypes.Self,
  subscriberFirstName: '',
  subscriberMiddleName: '',
  subscriberLastName: '',
  subscriberBirthDate: '',
  subscriberGender: '',
  subscriberSuffix: '',

  insuranceIsPrimary: true,
  carrierName: '',
  carrierId: '',
  masterId: null,
  memberId: '',
  groupNumber: '',
  providerId: null,
  providerFullName: '',
  providerFirstName: '',
  providerLastName: '',
  providerNPI: '',
  providerServiceType: '',
  providerLicenseNumber: '',
  providerTaxId: '',
  providerTaxonomyCode: '',
  providerIsDefault: false,
}

export interface AttachmentData {
  attachmentId?: number
  attachmentFacilityId?: string
  patientFacilityId?: string
  location?: string
  attachmentStatusId?: number
  attachmentStatus?: string
  patientFirstName?: string
  patientLastName?: string
  providerName?: string
  providerTaxId?: string
  recipientName?: string
  datesOfService?: string
  createDate?: string
}

export const isXrayImageType = (documentTypeGroupDescription: string) => {
  return (
    documentTypeGroupDescription != null &&
    documentTypeGroupDescription === '-- X-Ray Types --'
  )
}
