export const getAncillaryClaimState = (claim: any) => {
  const accidentType = claim?.ACCIDENT_TYPE?.toUpperCase()

  return {
    replacementOfProsthesis:
      claim?.PROSTHESIS_INITIAL_PLACEMENT === 'Y'
        ? 'N'
        : claim?.PROSTHESIS_INITIAL_PLACEMENT === 'N'
          ? 'Y'
          : null,
    forOrthodontics: claim?.ORTHO_FLAG,
    accidentType: claim?.ACCIDENT_TYPE || 'NONE',
    placementDateDisabled: claim?.PROSTHESIS_INITIAL_PLACEMENT !== 'N',
    orthoDateDisabled: claim?.ORTHO_FLAG !== 'Y',
    accidentDetailsDisabled:
      accidentType !== 'AUTO' && accidentType !== 'OTHER',
    releaseOfInformation: claim?.SIGNATURE || null,
    assignmentOfPayment: claim?.ASSIGNMENT_SIGNATURE || null,
  }
}
