import { ObservableObject } from '@legendapp/state'
import * as Sentry from '@sentry/react'
import { Dispatch, SetStateAction } from 'react'

import { LogError } from 'utils'

import {
  ClaimStatusCodes,
  ClaimValidationCode,
} from 'trellis:api/claim/claim-client'

import { ClaimErrorType } from '../claimTypes'
import { ClaimDetailsModelExtended } from '../context/claimDetailContext'
import { ClaimErrorsType } from './claimTypes'

export interface ClaimStatusCodesExtended extends ClaimStatusCodes {
  ServiceLine?: number
}

export const mapStatusCodeDetails = (
  validationResults: ClaimValidationCode[],
  claimStatusCodes: ClaimStatusCodes[],
) => {
  // Claim level error codes, meaning they are generic and don't map to a specific field
  // Should always be accompanied by a second, more detailed code
  const claimLevelErrorCodes: string[] = ['TLM', 'TLR']

  const mappedStatusCodes: ClaimStatusCodesExtended[] = []

  validationResults?.forEach((vr: ClaimValidationCode) => {
    if (claimLevelErrorCodes.includes(vr.Code)) {
      return
    }

    const match = claimStatusCodes.find((statusCode) => {
      return vr.Code === statusCode.Code
    })

    if (!match) {
      Sentry.withScope(function (scope) {
        scope.setTag('MissingClaimValidationStatusCode', vr.Code)
        LogError(
          new Error('Missing Claim Validation Status Code'),
          'Missing Claim Validation Status Code',
        )
      })
    } else mappedStatusCodes.push({ ...match, ServiceLine: vr.ServiceLine })
  })

  if (mappedStatusCodes.length > 0) return mappedStatusCodes
  else return null
}

export const getPopoverText = (
  mappedStatusCodes: ClaimStatusCodesExtended[],
) => {
  return mappedStatusCodes?.length > 0 ? (
    <>
      {mappedStatusCodes?.map((code) => (
        <p key={code?.Code}>{code?.DescriptionOverride}</p>
      ))}
    </>
  ) : null
}

export const mapClaimFieldErrors = (
  data: ClaimDetailsModelExtended,
  claim: ClaimDetailsModelExtended,
  setClaim: Dispatch<SetStateAction<ClaimDetailsModelExtended>>,
  mappedStatusCodes: ClaimStatusCodesExtended[],
  attachmentNarrativeStatusCodeError$: ObservableObject<{
    code: string
    fieldName: string
    message: string
  }>,
) => {
  const validationErrors: ClaimErrorsType[] = []

  mappedStatusCodes.forEach((code) => {
    // Status code for attachment narrative, which isn't part of the claim object
    if (code.Code === 'TLI') {
      attachmentNarrativeStatusCodeError$.set({
        code: code.Code,
        fieldName: code.FieldNameAttribute,
        message: code.DescriptionOverride,
      })
    } else if (code.Code === 'CCD') {
      // Is line item specific but doesn't include a service line
      const numberOfLineItems = data.LineItems?.length

      for (let i = 0; i < numberOfLineItems; i++) {
        validationErrors.push({
          property: code.FieldNameAttribute,
          parentProperty: 'LineItems',
          message: code.DescriptionOverride,
          section: code.VyneTrellisTab,
          // ServiceLine isn't 0 based, but line item indices are
          index: i,
        })
      }
    } else if (code.ServiceLine) {
      // ServiceLine is the line item number related to the status code
      validationErrors.push({
        property: code.FieldNameAttribute,
        parentProperty: 'LineItems',
        message: code.DescriptionOverride,
        section: code.VyneTrellisTab,
        // ServiceLine isn't 0 based, but line item indices are
        index: code.ServiceLine - 1,
      })
    } else {
      validationErrors.push({
        property: code.FieldNameAttribute,
        parentProperty: null,
        message: code.DescriptionOverride,
        section: code.VyneTrellisTab,
        index: null,
      })
    }
  })

  // TODO: move to shared util
  const onlyInLeft = <T,>(
    left: T[],
    right: T[],
    compareFunction: (a: T, b: T) => boolean,
  ): T[] =>
    left.filter(
      (leftValue) =>
        !right.some((rightValue) => compareFunction(leftValue, rightValue)),
    )

  const isSameError = (a: ClaimErrorType, b: ClaimErrorType) =>
    a.property === b.property

  const onlyInValidationErrors: ClaimErrorsType[] = onlyInLeft<ClaimErrorsType>(
    validationErrors,
    data.errors,
    isSameError,
  )

  data.errors = [...data.errors, ...onlyInValidationErrors]
  const newClaim = { ...claim, ...data }
  setClaim(newClaim)

  return newClaim
}
