import { Observable } from '@legendapp/state'
import {
  observer,
  useObservable,
  useObserveEffect,
} from '@legendapp/state/react'
import { Dispatch, FC, SetStateAction } from 'react'

import { FormAutocomplete } from 'trellis:components/_siteWide/form/formAutocomplete'
import { ProviderDropdownOption } from 'trellis:features/providers/utils/provider-helpers'
import { validateProperty } from 'trellis:utilities/validators/baseValidator'
import { validateNpi } from 'trellis:utilities/validators/npiValidators'
import { validateTin } from 'trellis:utilities/validators/tinValidator'

interface ProviderAutoCompleteProps {
  name: string
  options: Observable<ProviderDropdownOption[]>
  setState: Dispatch<SetStateAction<any>>
  state: any
}

export const ProviderAutoComplete: FC<ProviderAutoCompleteProps> = observer(
  ({ name, options, setState, state }) => {
    const filteredOptions = useObservable<ProviderDropdownOption[]>([])

    useObserveEffect(options, () => {
      filteredOptions.set(options.peek())
    })

    const handleSelect = (value: string, stateCopy: any) => {
      if (!value) return

      if (value.includes('- NPI')) value = value.split('- NPI')[0].trim()

      const providerMatch: ProviderDropdownOption = options
        .peek()
        .find((provider: ProviderDropdownOption) => {
          const providerName =
            provider.data.providerFirstName +
            ' ' +
            provider.data.providerLastName
          if (providerName === value) return provider
        })

      if (providerMatch) {
        if (name === 'BillingName') {
          stateCopy.BillingLicense = providerMatch.data.licenseNumber
          stateCopy.NpiGroup = providerMatch.data.providerNPI
          stateCopy.Tin = providerMatch.data.taxId
          validateProperty(
            validateNpi,
            stateCopy,
            'NpiGroup',
            null,
            false,
            'provider',
          )
          validateProperty(
            validateTin,
            stateCopy,
            'Tin',
            null,
            true,
            'provider',
          )
        } else if (name === 'TreatingName') {
          stateCopy.TreatingLicense = providerMatch.data.licenseNumber
          stateCopy.IndividualNpi = providerMatch.data.providerNPI
          stateCopy.SPECIALTY = providerMatch.data.taxonomyCode

          validateProperty(
            null,
            stateCopy,
            'TreatingLicense',
            null,
            true,
            'provider',
          )
          validateProperty(
            validateNpi,
            stateCopy,
            'IndividualNpi',
            null,
            false,
            'provider',
          )
          validateProperty(
            null,
            stateCopy,
            'SPECIALTY',
            null,
            false,
            'provider',
          )
        }

        setState(stateCopy)
      }

      filteredOptions.set(options.peek())
    }

    const onSearch = (value: string) => {
      if (value) {
        value = value.toLocaleLowerCase()
        const matches: ProviderDropdownOption[] = options
          .peek()
          .filter((provider: ProviderDropdownOption) =>
            provider.value.toLocaleLowerCase().includes(value),
          )
        filteredOptions.set(matches)
      } else {
        filteredOptions.set(options.peek())
      }
    }

    const onValueChange = (value: string) => {
      return value.includes('- NPI') ? value.split('- NPI')[0].trim() : value
    }

    return (
      <FormAutocomplete
        customHandler={handleSelect}
        element={state}
        initialValue={state[name]}
        label='Name'
        name={name}
        onSearch={onSearch}
        options={filteredOptions.get()}
        required
        section='provider'
        setElement={setState}
        valueHandler={onValueChange}
      />
    )
  },
)
