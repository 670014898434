import { Col, Row } from 'antd'

import { FirstLastName } from './changeFirstLastName/changeFirstLastName'
import { ProfileContextProvider } from './context/profileContext'
import { Profile } from './profile/profile'

import './account.scss'

import SendInvite from './sendInvite/sendInvite'

export const Account = () => {
  return (
    <article className='page-content__container page-content__container--with-page-header'>
      <h1 className='page-content-header__title'>Account</h1>
      <Row className='profile-container mt-100'>
        <Col span={12}>
          <div className='page-section'>
            <h4 className='page-section__title profile-title'>Profile</h4>
            <FirstLastName />
            <ProfileContextProvider>
              <Profile />
            </ProfileContextProvider>
          </div>
        </Col>
        <Col span={12}>
          <div className='new-user-container'>
            <SendInvite />
          </div>
        </Col>
      </Row>
    </article>
  )
}

const content = <Account />
