import GlobalState from 'trellis:state/globalState'
import trellisConfiguration from 'trellis:utilities/config'

import {
  AccountApiFactory,
  Configuration,
  EulaAcceptRequest,
  EulaApiFactory,
} from './account-client'

const apiEndpoint: string =
  trellisConfiguration.trellis_apiUrl + 'services/account'

export const GetUser = async (userId: number) => {
  return AccountApiFactory(
    new Configuration({
      basePath: apiEndpoint,
      accessToken: GlobalState?.Auth?.AccessToken?.peek(),
    }),
  ).accountGetUser(
    userId,
    GlobalState?.Auth?.AuthToken?.peek(),
    GlobalState?.Auth?.RegistrationToken?.peek(),
    0,
  )
}

export const GetEula = async () => {
  return EulaApiFactory(
    new Configuration({
      basePath: apiEndpoint,
      accessToken: GlobalState?.Auth?.AccessToken?.peek(),
    }),
  ).eulaGetEula(
    GlobalState?.Auth?.AuthToken?.peek(),
    GlobalState?.Auth?.RegistrationToken?.peek(),
    0,
  )
}

export const AcceptEula = async (req: EulaAcceptRequest) => {
  return EulaApiFactory(
    new Configuration({
      basePath: apiEndpoint,
      accessToken: GlobalState?.Auth?.AccessToken?.peek(),
    }),
  ).eulaAcceptEula(
    GlobalState?.Auth?.AuthToken?.peek(),
    GlobalState?.Auth?.RegistrationToken?.peek(),
    0,
    req,
  )
}
