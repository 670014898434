import { FC } from 'react'

import ReadOnlyInput from './readOnlyInput'

import './structuredViewPatientSubscriber.scss'

import { StructuredPatSubDetails } from '../utilities/eligibilityTyping'

type StructuredViewPatientSubscriberProps = {
  type: 'Patient' | 'Subscriber'
  details: StructuredPatSubDetails
}

const StructuredViewPatientSubscriber: FC<
  StructuredViewPatientSubscriberProps
> = ({ type, details }) => {
  return (
    <section className='structured-pat-sub'>
      <header className='structured-pat-sub__header'>{type}</header>
      <article className='structured-pat-sub__row--last-name'>
        <ReadOnlyInput
          label='Last'
          text={details.lastName || '--'}
        />
        <ReadOnlyInput
          label='First'
          text={details.firstName || '--'}
        />
        <ReadOnlyInput
          label='DOB'
          text={details.birthDate || '--'}
        />
      </article>
      <ReadOnlyInput
        label='Member ID'
        text={details.memberId || '--'}
        style={{ width: '100%', paddingTop: '1.5em' }}
      />
      <ReadOnlyInput
        label='Address'
        text={details.address || '--'}
        style={{ width: '100%', paddingTop: '1.5em' }}
      />
      <article className='structured-pat-sub__row--city'>
        <ReadOnlyInput
          label='City'
          text={details.city || '--'}
        />
        <ReadOnlyInput
          label='State'
          text={details.state || '--'}
        />
        <ReadOnlyInput
          label='Zip Code'
          text={details.zipCode || '--'}
        />
      </article>
    </section>
  )
}

export default StructuredViewPatientSubscriber
