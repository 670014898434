import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Col, Form, Input, Popover, Row } from 'antd'
import React, { useState } from 'react'

import { spacesToProperty } from '../../../utilities/general'
import { validateProperty } from '../../../utilities/validators/baseValidator'
import { FormFloatLabel } from './formFloatLabel'
import { getError, getRules, handleFormChange } from './util'

export const FormPasswordInput = ({
  name,
  element,
  setElement,
  initialValue,
  validator,
  disabled,
  label = null,
  parentProperty = null,
  section = null,
  noFloat = false,
  required = false,
  width = '100%',
  ...others
}: any) => {
  const [passwordRequirements, setPasswordRequirements] = useState({
    hasMinLength: false,
    hasLowercase: false,
    hasUppercase: false,
    hasSymbol: false,
  })

  const error = element && getError(name, element)

  const onChange = (e: any) => {
    const value = e.target.value
    const updatedElement = handleFormChange(
      name,
      parentProperty,
      value,
      element,
    )
    checkPasswordRequirements(value)
    validateProperty(
      validator,
      updatedElement,
      name,
      parentProperty,
      required,
      section,
    )
    setElement(updatedElement)
  }

  const checkPasswordRequirements = (value: string) => {
    setPasswordRequirements({
      hasMinLength: value.length >= 8 ? true : false,
      hasLowercase: /(?=.*?[a-z])/.test(value),
      hasUppercase: /(?=.*?[A-Z])/.test(value),
      hasSymbol: /(?=.*?[#?!@$%^&*-])/.test(value),
    })
  }

  const requirementsContent = (
    <>
      <Col>
        <Row align='middle'>
          {passwordRequirements.hasMinLength ? (
            <CheckCircleOutlined className='color--success mr-050' />
          ) : (
            <CloseCircleOutlined
              className='mr-050'
              style={{ color: 'var(--color-error, #f5222d)' }}
            />
          )}
          <div>8 Characters Long</div>
        </Row>
      </Col>
      <Col>
        <Row align='middle'>
          {passwordRequirements.hasLowercase ? (
            <CheckCircleOutlined className='color--success mr-050' />
          ) : (
            <CloseCircleOutlined
              className='mr-050'
              style={{ color: 'var(--color-error, #f5222d)' }}
            />
          )}
          <div>One Lowercase</div>
        </Row>
      </Col>
      <Col>
        <Row align='middle'>
          {passwordRequirements.hasUppercase ? (
            <CheckCircleOutlined className='color--success mr-050' />
          ) : (
            <CloseCircleOutlined
              className='mr-050'
              style={{ color: 'var(--color-error, #f5222d)' }}
            />
          )}
          <div>One Uppercase</div>
        </Row>
      </Col>
      <Col>
        <Row align='middle'>
          {passwordRequirements.hasSymbol ? (
            <CheckCircleOutlined className='color--success mr-050' />
          ) : (
            <CloseCircleOutlined
              className='mr-050'
              style={{ color: 'var(--color-error, #f5222d)' }}
            />
          )}
          <div>One Symbol</div>
        </Row>
      </Col>
    </>
  )

  let inputContent

  if (name === 'NewPassword') {
    inputContent = (
      <Popover
        title='Requirements'
        content={requirementsContent}
        placement='rightTop'
        trigger='focus'
      >
        <Input.Password
          autoComplete='off'
          className={`${noFloat ? 'no-float' : ''}`}
          style={{ width: width }}
          value={initialValue}
          onChange={onChange}
          {...others}
        />
      </Popover>
    )
  } else {
    inputContent = (
      <Input.Password
        autoComplete='off'
        className={`${noFloat ? 'no-float' : ''}`}
        style={{ width: width }}
        value={initialValue}
        onChange={onChange}
        {...others}
      />
    )
  }

  let input

  if (noFloat) {
    input = inputContent
  } else {
    input = (
      <FormFloatLabel
        label={label || spacesToProperty(name)}
        name={name}
        inputValue={initialValue}
      >
        {inputContent}
      </FormFloatLabel>
    )
  }

  if (!error && !validator) {
    return (
      <div className='form-item-validation-container'>
        <Form.Item name={name}>{input}</Form.Item>
      </div>
    )
  }

  if (error) {
    return (
      <div className='form-item-validation-container'>
        <Form.Item
          name={name}
          validateStatus='error'
          help={error.message}
        >
          {input}
        </Form.Item>
      </div>
    )
  }

  return (
    <div className='form-item-validation-container'>
      <Form.Item
        name={name}
        rules={[getRules(name, required)]}
      >
        {input}
      </Form.Item>
    </div>
  )
}
