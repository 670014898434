import { isValid } from 'date-fns'

import { DocumentType } from '../../../api/attachment-standalone/attachment-standalone-client'
import { ImageType } from '../../../api/attachment/attachment-client/api'
import {
  PearlImageOrientationTypes,
  PearlImageTypes,
} from '../../../api/pearl/pearl-client/api'
import {
  dateFromString,
  formatDateFromString,
} from '../../../utilities/general'
import {
  IAttachmentImage,
  ImageCaptureMethod,
  ImageOrientationType,
} from '../../attachment/attachment.interface'
import { ImageDataProps } from '../../Attachments/AttachmentsTypes'
import { ImageSyncImage } from '../imageSync/imageSync.interface'
import { PatientName } from './shared.interface'

export const handleDateOfServiceDisplay = (
  dateOfServiceFrom: string,
  dateOfServiceTo: string,
): string => {
  let dateOfService: string = 'Prior Authorization'

  if (!dateOfServiceFrom || !dateOfServiceTo) {
    return dateOfService
  }

  if (
    isValid(new Date(dateOfServiceFrom)) &&
    isValid(new Date(dateOfServiceTo))
  ) {
    dateOfService =
      formatDateFromString(dateOfServiceFrom, 'MM/dd/yyy') +
      ' - ' +
      formatDateFromString(dateOfServiceTo, 'MM/dd/yyy')
  }
  return dateOfService
}

export const handleDocumentType = (
  imageTypes: DocumentType[],
  imageTypeDescription: string,
): DocumentType => {
  let documentType = {} as DocumentType
  switch (imageTypeDescription) {
    case PearlImageTypes.Periapical:
      documentType = imageTypes.find(
        (imageType) => imageType.documentCode === 2,
      )
      break
    case PearlImageTypes.Pano:
      documentType = imageTypes.find(
        (imageType) => imageType.documentCode === 5,
      )
      break
    case PearlImageTypes.Bitewing:
      documentType = imageTypes.find(
        (imageType) => imageType.documentCode === 1,
      )
      break
    case PearlImageTypes.Other:
    default:
      documentType = null
      break
  }
  return documentType
}

export const handleImageType = (
  imageTypes: ImageType[],
  imageTypeDescription: string,
): ImageType => {
  let imageType = {} as ImageType
  switch (imageTypeDescription) {
    case PearlImageTypes.Periapical:
      imageType = imageTypes.find((imageType) => imageType.imageTypeId === 2)
      break
    case PearlImageTypes.Pano:
      imageType = imageTypes.find((imageType) => imageType.imageTypeId === 5)
      break
    case PearlImageTypes.Bitewing:
      imageType = imageTypes.find((imageType) => imageType.imageTypeId === 1)
      break
    case PearlImageTypes.Other:
    default:
      imageType = null
      break
  }
  return imageType
}

export const handleImageOrientation = (
  imageOrientation: PearlImageOrientationTypes,
): string => {
  let orientation = {} as string
  switch (imageOrientation) {
    case PearlImageOrientationTypes.PatientLeft:
      orientation = 'Patient Left'
      break
    case PearlImageOrientationTypes.PatientRight:
    case PearlImageOrientationTypes.None:
    default:
      orientation = 'Patient Right'
      break
  }
  return orientation
}

export const getImageDataPropsArray = (
  attachmentInformation: any,
  imageSyncImages: ImageSyncImage[],
): ImageDataProps[] => {
  const imagesArray = [] as ImageDataProps[]
  let imageIndex = 0
  for (const imageSyncImage of imageSyncImages) {
    const acquisitionType = 'Image-Sync'
    const rowKey = new Date().getTime().toString() + ++imageIndex
    const dateTakenFromString = dateFromString(imageSyncImage?.imageDateTaken)
    const dateTaken = isValid(dateTakenFromString)
      ? dateTakenFromString.toString()
      : null

    const image: ImageDataProps = {
      imageDataThumbnail: URL.createObjectURL(imageSyncImage.imageData),
      imageData: imageSyncImage.imageData,
      acquisitionType,
      rowKey,
      imageChanged: true,
      imageType: attachmentInformation.handleImageType(
        attachmentInformation.imageTypes,
        imageSyncImage.imageType,
      ),
      dateTaken: dateTaken,
      orientation:
        imageSyncImage.orientation == 'Patient Left' ? 'Left' : 'Right',
    }

    imagesArray.push(image)
  }
  return imagesArray
}

export const getAttachmentImageArray = (
  attachmentInformation: any,
  imageSyncImages: ImageSyncImage[],
): IAttachmentImage[] => {
  const imagesArray = [] as IAttachmentImage[]
  for (const imageSyncImage of imageSyncImages) {
    const dateTakenFromString = dateFromString(imageSyncImage?.imageDateTaken)
    const imageType: ImageType = attachmentInformation.handleImageType(
      attachmentInformation.imageTypes,
      imageSyncImage.imageType,
    )
    const image: IAttachmentImage = {
      imageCaptureMethod: ImageCaptureMethod.ImageSync,
      imageData: imageSyncImage.imageData,
      isNewImage: true,
      imageTypeId: imageType?.imageTypeId,
      imageTypeName: imageType?.imageTypeName,
      imageDateTaken: dateTakenFromString,
      imageOrientationType:
        imageSyncImage.orientation == 'Patient Left'
          ? ImageOrientationType.LeftIsLeft
          : ImageOrientationType.LeftIsRight,
    }

    imagesArray.push(image)
  }
  return imagesArray
}

export const patientNameParse = (patientFullName: string): PatientName => {
  const patientName = {} as PatientName

  const patientNameParts: string[] = patientFullName?.split(',')
  if (patientNameParts.length > 0) {
    patientName.lastName = patientNameParts[0].trim()
  }

  if (patientNameParts.length > 1) {
    patientName.firstName = patientNameParts[1].trim()
  }

  return patientName
}

export const canvasImageConversion = async (
  file: File,
  filename: string,
  mimeType: string,
  imageQuality: number,
): Promise<File> => {
  const convertedFile = new Promise<File>((resolve) => {
    const canvas = document.createElement('canvas')
    const image = new Image()
    image.src = URL.createObjectURL(file)
    image.onload = () => {
      canvas.width = image.width
      canvas.height = image.height
      canvas.getContext('2d').drawImage(image, 0, 0)
      canvas.toBlob(
        function (blob) {
          resolve(new File([blob], filename, { type: mimeType }))
        },
        mimeType,
        imageQuality,
      )
    }
  }).catch()
  return convertedFile
}
