import { EyeOutlined, QuestionCircleFilled } from '@ant-design/icons'
import { Button, Col, Row, Table, Tooltip } from 'antd'
import React, { FC, useEffect, useState } from 'react'

import { useClaimDetailContext } from '../../../claims/context/claimDetailContext'
import {
  getUrl,
  IAttachment,
  IAttachmentImage,
  ITransformedData,
} from '../../attachment.interface'
import { NeaNumberTooltip } from '../../constants'
import AttachmentImageView from '../attachmentImageView/attachmentImageView'

import './attachmentImageGridSent.scss'

type SentAttachmentImageGridProps = {
  attachmentImages: Array<IAttachmentImage>
  toggleSentImageView: React.Dispatch<React.SetStateAction<boolean>>
  showAttachmentList: () => void
  attachment: IAttachment
}

const SentAttachmentImageGrid: FC<SentAttachmentImageGridProps> = ({
  attachmentImages,
  toggleSentImageView,
  showAttachmentList,
  attachment,
}) => {
  const { claim } = useClaimDetailContext()
  const [showImageView, toggleImageView] = useState<boolean>(false)
  const [attachmentImage, setAttachmentImage] = useState<IAttachmentImage>()
  const [tableData, setTableData] = useState([])

  const showImage = (attachmentImage: IAttachmentImage) => {
    setAttachmentImage(attachmentImage)
    toggleImageView(true)
  }

  useEffect(() => {
    if (attachmentImages) {
      const transformedData = attachmentImages.map((data, idx) => {
        const transformedData: ITransformedData = data.imageData
        transformedData.imgPath = getUrl(transformedData)
        transformedData.imageTypeName = data.imageTypeName
        transformedData.itemNumber = idx + 1
        return transformedData
      })
      setTableData(transformedData)
    }
  }, [])

  const columns = [
    {
      title: '',
      key: 'itemNumber',
      dataIndex: 'itemNumber',
      width: '48px',
    },
    {
      title: 'Preview',
      key: 'imgPath',
      dataIndex: 'imgPath',
      width: '131px',
      render: (imgPath) => (
        <img
          alt={imgPath}
          src={imgPath}
          className='img-preview'
        />
      ),
    },
    {
      title: 'Image Type',
      key: 'imageTypeName',
      dataIndex: 'imageTypeName',
      width: '212px',
    },
    {
      title: '',
      key: 'preview',
      width: '75px',
      render: (_text, _record, idx) => {
        return (
          <EyeOutlined
            onClick={() => {
              showImage(attachmentImages[idx])
            }}
          />
        )
      },
    },
  ]

  return (
    <Row>
      <Col
        span={24}
        className='nea-container mb-150 mt-050'
      >
        <a
          className='return-link'
          onClick={() => showAttachmentList()}
        >
          Return to List
        </a>
        <h3 className='nea-number mb-025'>
          {attachment.attachmentNumber}
          <Tooltip
            placement='topRight'
            title={NeaNumberTooltip}
          >
            <QuestionCircleFilled className='nea-info-circle' />
          </Tooltip>
        </h3>
        {claim?.LineItems[0]?.SERVICE_DATE_String && (
          <p className='date-of-service'>
            Date of Service: {claim?.LineItems[0]?.SERVICE_DATE_String}
          </p>
        )}
      </Col>
      <Col
        span={24}
        className='grid-wrapper-column'
      >
        <Button
          type='link'
          className='view-all-button'
          onClick={() => toggleSentImageView(true)}
        >
          View All
        </Button>
        <Table
          dataSource={tableData}
          columns={columns}
          pagination={false}
          locale={{ emptyText: 'Capture or upload an attachment image.' }}
          className='attachment-table'
        />
      </Col>
      {showImageView && (
        <AttachmentImageView
          dialogTitle={'View Image'}
          attachmentImages={[attachmentImage]}
          toggleImageView={toggleImageView}
        />
      )}
    </Row>
  )
}

export default SentAttachmentImageGrid
