import { Col, Row } from 'antd'
import React from 'react'

import { FormInput } from '../../../../_siteWide/form/formInput'
import { FormMaskInput } from '../../../../_siteWide/form/formMaskInput'
import { FormSelect } from '../../../../_siteWide/form/formSelect'
import { Relationships } from '../../../../../constants/general'
import { validateCob } from '../../../../../utilities/validators/cobValidators'
import { useClaimDetailContext } from '../../../context/claimDetailContext'

export const CobControls = () => {
  const { claim, setClaim } = useClaimDetailContext()
  return (
    <>
      <Row gutter={16}>
        <Col span={16}>
          <FormInput
            name='CobName'
            label='Last, First'
            element={claim}
            setElement={setClaim}
            initialValue={claim.CobName}
            section='patient'
            validator={validateCob}
          />
        </Col>
        <Col span={8}>
          <FormMaskInput
            element={claim}
            format='##/##/####'
            initialValue={claim.CobBirthDate}
            label='DOB'
            mask='_'
            name='CobBirthDate'
            section='patient'
            setElement={setClaim}
            validator={validateCob}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormInput
            name='CobSsn'
            label='ID/SSN'
            element={claim}
            setElement={setClaim}
            initialValue={claim.CobSsn}
            section='patient'
            validator={validateCob}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <FormInput
            name='CobCarrier'
            label='Name'
            element={claim}
            setElement={setClaim}
            initialValue={claim.CobCarrier}
          />
        </Col>
        <Col span={24}>
          <FormSelect
            name='COB_RELATIONSHIP'
            label='Relationship to Subscriber'
            element={claim}
            setElement={setClaim}
            initialValue={claim.COB_RELATIONSHIP}
            options={Relationships}
          />
        </Col>
      </Row>
    </>
  )
}
