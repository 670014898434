import { Component, ErrorInfo, ReactElement } from 'react'

import './ErrorBoundary.scss'

interface ErrorBoundaryProps {
  children: ReactElement
  fallBack: ReactElement
  key: string
}

interface ErrorState {
  hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps> {
  state: ErrorState = { hasError: false }

  static getDerivedStateFromError(): ErrorState {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('error :>> ', error)
    console.error('errorInfo :>> ', errorInfo)
  }

  componentDidUpdate(prevProps: Readonly<ErrorBoundaryProps>): void {
    if (prevProps.key !== this.props.key) this.setState({ hasError: false })
  }

  render() {
    if (this.state.hasError) return this.props.fallBack

    return this.props.children
  }
}

export default ErrorBoundary
