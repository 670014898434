import { Slider } from '@progress/kendo-react-inputs'
import React, {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useRef,
} from 'react'
import { Col, Row } from 'react-bootstrap'

import {
  getUrlForAttachment,
  IAttachmentImage,
  IImageOperation,
  ImageOperationType,
} from '../../../attachment.interface'
import { SliderConstraints } from '../../../constants'
import SliderControl from './sliderControls'

import './editImage.scss'

type EditImageProps = {
  attachmentImage: IAttachmentImage
  keyId: MutableRefObject<number>
  operations: IImageOperation
  croppedImageRef: React.MutableRefObject<HTMLImageElement>
  setImgForCanvas: Dispatch<SetStateAction<HTMLImageElement>>
  setOperations: (value: SetStateAction<IImageOperation>) => void
}

const EditImage: FC<EditImageProps> = ({
  attachmentImage,
  operations,
  croppedImageRef,
  keyId,
  setImgForCanvas,
  setOperations,
}) => {
  const {
    contrastMin,
    contrastMax,
    sharpenMin,
    sharpenMax,
    brightenMin,
    brightenMax,
  } = SliderConstraints
  const { ROTATE, MIRROR, SHARPNESS, BRIGHTNESS, CONTRAST } = ImageOperationType
  const brightnessRef = useRef<number>(0)
  const contrastRef = useRef<number>(0)
  const sharpnessRef = useRef<number>(0)
  const brightnessSliderRef = useRef<Slider>()
  const contrastSliderRef = useRef<Slider>()
  const sharpnessSliderRef = useRef<Slider>()
  const operationsStack = useRef<Array<IImageOperation>>([])
  const isCroppingRef = useRef<boolean>()

  const submitOperation = (imageOperationKey: ImageOperationType) => {
    keyId.current = keyId.current === 0 ? 1 : 0

    setOperations({
      imageOperationKey: imageOperationKey,
      brightness: brightnessRef.current,
      contrast: contrastRef.current,
      sharpness: sharpnessRef.current,
      mirror: imageOperationKey === ImageOperationType.MIRROR,
      rotation: imageOperationKey === ImageOperationType.ROTATE,
      start_crop: imageOperationKey === ImageOperationType.START_CROP,
      end_crop: imageOperationKey === ImageOperationType.END_CROP,
      restore: imageOperationKey === ImageOperationType.RESTORE,
    })

    if (imageOperationKey === ImageOperationType.RESTORE) {
      operationsStack.current = new Array<IImageOperation>()
    }

    // Keep track of operations performed
    operationsStack.current.push(operations)
  }

  const updateCrop = () => {
    if ((isCroppingRef.current = !isCroppingRef.current)) {
      submitOperation(ImageOperationType.START_CROP)
    } else {
      setImgForCanvas(croppedImageRef.current)
      submitOperation(ImageOperationType.END_CROP)
    }
  }

  const resetSliderRefValues = () => {
    brightnessRef.current = 0
    contrastRef.current = 0
    sharpnessRef.current = 0
    brightnessSliderRef.current.state.value = 0
    contrastSliderRef.current.state.value = 0
    sharpnessSliderRef.current.state.value = 0
  }

  const updateRestore = () => {
    const restoreOrigImg = new Image()
    restoreOrigImg.src = getUrlForAttachment(attachmentImage)
    restoreOrigImg.onload = () => {
      setImgForCanvas(restoreOrigImg)
    }

    isCroppingRef.current = false
    resetSliderRefValues()
    submitOperation(ImageOperationType.RESTORE)
  }

  const updateOperationList = (
    imageOperationKey: ImageOperationType,
    refObject?: MutableRefObject<number>,
    value?: number,
  ) => {
    if (refObject && refObject.current !== value) {
      refObject.current = value
    }

    isCroppingRef.current = false
    submitOperation(imageOperationKey)
  }

  return (
    <Row className='edit-image-section control-row-spacing'>
      <Col lg={12}>
        <Row>
          <Col lg={12}>
            <h4 className='white-title'>Edit Image</h4>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={4}>
                <div className='center-operation'>
                  <i
                    className={'fa fa-crop fa-2x'}
                    onClick={() => updateCrop()}
                  >
                    <h6 className='white-title'>Crop</h6>
                  </i>
                </div>
              </Col>
              <Col lg={4}>
                <div className='center-operation'>
                  <i
                    className={'fa fa-redo fa-2x'}
                    onClick={() => updateOperationList(ROTATE)}
                  >
                    <h6 className='white-title'>Rotate</h6>
                  </i>
                </div>
              </Col>
              <Col lg={4}>
                <div className='center-operation'>
                  <i
                    className={'fa fa-adjust fa-2x'}
                    onClick={() => updateOperationList(MIRROR)}
                  >
                    <h6 className='white-title'>Flip</h6>
                  </i>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={12}>
                <SliderControl
                  title='Brightness'
                  valueMin={brightenMin}
                  valueMax={brightenMax}
                  operationType={BRIGHTNESS}
                  valueRef={brightnessRef}
                  updateOperationList={updateOperationList}
                  valueSliderRef={brightnessSliderRef}
                />
                <SliderControl
                  title='Sharpness'
                  valueMin={sharpenMin}
                  valueMax={sharpenMax}
                  operationType={SHARPNESS}
                  valueRef={sharpnessRef}
                  updateOperationList={updateOperationList}
                  valueSliderRef={sharpnessSliderRef}
                />
                <SliderControl
                  title='Contrast'
                  valueMin={contrastMin}
                  valueMax={contrastMax}
                  operationType={CONTRAST}
                  valueRef={contrastRef}
                  updateOperationList={updateOperationList}
                  valueSliderRef={contrastSliderRef}
                />
              </Col>
            </Row>
            <Row className='edit-image-section'>
              <Col lg={12}>
                <div className='center-operation'>
                  <i
                    className={'fa fa-undo fa-2x'}
                    onClick={() => updateRestore()}
                  >
                    <h6 className='white-title'>Restore Image</h6>
                  </i>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default EditImage
