import React from 'react'

import { ChangePassword } from '../changePassword/changePassword'
import { useProfileContext } from '../context/profileContext'
import { PasswordExpiration } from '../passwordExpiration/passwordExpiration'

export const Profile = () => {
  const { showChangePassword, setShowChangePassword, user } =
    useProfileContext()

  return (
    <>
      <div className='sub-section'>
        <p className='sub-section-title'>Username (email)</p>
        <p>{user.username}</p>
      </div>
      <div className='sub-section'>
        <div className='sub-section-title'>Password</div>
        {showChangePassword && <ChangePassword />}
        {!showChangePassword && (
          <p
            className='text-link'
            onClick={() => setShowChangePassword(true)}
          >
            Change Password
          </p>
        )}
        <PasswordExpiration />
      </div>
    </>
  )
}
