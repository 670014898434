import { Observable } from '@legendapp/state'
import { Memo } from '@legendapp/state/react'
import { FC } from 'react'

interface TextFinderCountsProps {
  currentMatchIndex: Observable<number>
  matchesTotal: Observable<number>
}

const TextFinderCounts: FC<TextFinderCountsProps> = ({
  currentMatchIndex,
  matchesTotal,
}) => (
  <p className='text-finder__counts'>
    <Memo>{currentMatchIndex}</Memo> of <Memo>{matchesTotal}</Memo>
  </p>
)

export default TextFinderCounts
