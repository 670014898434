export const validatePriorAuthorization = (value: string) => {
  let error: string | null = null

  if (value) {
    const format: RegExp = /^[A-Za-z0-9]+$/
    if (!format.test(value)) {
      error = 'Must only contain numbers and letters'
    } else if (value.length > 25) error = 'Must be 25 characters or less'
  }

  return error
}
