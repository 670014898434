import { FC, FormHTMLAttributes } from 'react'

interface FormProps extends FormHTMLAttributes<HTMLFormElement> {}

export const Form: FC<FormProps> = ({ children, className, ...props }) => {
  return (
    <form
      className={`form__container ${className}`}
      {...props}
    >
      {children}
    </form>
  )
}
