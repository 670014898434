/* tslint:disable */
/* eslint-disable */
/**
 * Attachment
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AttachmentCarrier
 */
export interface AttachmentCarrier {
    /**
     * 
     * @type {string}
     * @memberof AttachmentCarrier
     */
    'carrierName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentCarrier
     */
    'carrierId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentCarrier
     */
    'neaMasterId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentCarrier
     */
    'carrierAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentCarrier
     */
    'carrierCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentCarrier
     */
    'carrierState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentCarrier
     */
    'carrierZip'?: string | null;
}
/**
 * 
 * @export
 * @interface AttachmentRequest
 */
export interface AttachmentRequest {
    /**
     * 
     * @type {string}
     * @memberof AttachmentRequest
     */
    'narrative'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentRequest
     */
    'payorRefId'?: string | null;
}
/**
 * 
 * @export
 * @interface AttachmentRequirement
 */
export interface AttachmentRequirement {
    /**
     * 
     * @type {string}
     * @memberof AttachmentRequirement
     */
    'procedureCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentRequirement
     */
    'toothNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentRequirement
     */
    'requiresAttachment'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentRequirement
     */
    'requiresNarrative'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentRequirement
     */
    'requiresPerioCharting'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentRequirement
     */
    'requiresFmXray'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentRequirement
     */
    'requiresPanXray'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentRequirement
     */
    'requiresXray'?: boolean;
}
/**
 * 
 * @export
 * @interface AttachmentRequirementsIgnoredResponse
 */
export interface AttachmentRequirementsIgnoredResponse {
    /**
     * 
     * @type {number}
     * @memberof AttachmentRequirementsIgnoredResponse
     */
    'claimId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AttachmentRequirementsIgnoredResponse
     */
    'customerId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentRequirementsIgnoredResponse
     */
    'ignoreAttachmentRequirements'?: boolean | null;
}
/**
 * 
 * @export
 * @interface AttachmentRequirementsResponse
 */
export interface AttachmentRequirementsResponse {
    /**
     * 
     * @type {Array<AttachmentRequirement>}
     * @memberof AttachmentRequirementsResponse
     */
    'attachmentRequirements'?: Array<AttachmentRequirement> | null;
    /**
     * 
     * @type {Array<ImageType>}
     * @memberof AttachmentRequirementsResponse
     */
    'imageTypesRequired'?: Array<ImageType> | null;
}
/**
 * 
 * @export
 * @interface AttachmentResponse
 */
export interface AttachmentResponse {
    /**
     * 
     * @type {number}
     * @memberof AttachmentResponse
     */
    'attachmentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentResponse
     */
    'narrative'?: string | null;
    /**
     * 
     * @type {Array<ImageResponse>}
     * @memberof AttachmentResponse
     */
    'images'?: Array<ImageResponse> | null;
}
/**
 * 
 * @export
 * @interface AttachmentSent
 */
export interface AttachmentSent {
    /**
     * 
     * @type {string}
     * @memberof AttachmentSent
     */
    'attachmentNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentSent
     */
    'isNeaNumber'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AttachmentSent
     */
    'sentDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentSent
     */
    'narrative'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentSent
     */
    'payorRefId'?: string | null;
    /**
     * 
     * @type {Array<ImageResponse>}
     * @memberof AttachmentSent
     */
    'images'?: Array<ImageResponse> | null;
}
/**
 * 
 * @export
 * @interface AttachmentSentResponse
 */
export interface AttachmentSentResponse {
    /**
     * 
     * @type {Array<AttachmentSent>}
     * @memberof AttachmentSentResponse
     */
    'sentAttachmentList'?: Array<AttachmentSent> | null;
}
/**
 * 
 * @export
 * @interface CarrierListResponse
 */
export interface CarrierListResponse {
    /**
     * 
     * @type {Array<AttachmentCarrier>}
     * @memberof CarrierListResponse
     */
    'attachmentCarriers'?: Array<AttachmentCarrier> | null;
}
/**
 * 
 * @export
 * @interface ConvertImageRequest
 */
export interface ConvertImageRequest {
    /**
     * 
     * @type {string}
     * @memberof ConvertImageRequest
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof ConvertImageRequest
     */
    'destType': string;
}
/**
 * 
 * @export
 * @interface CreateAttachmentResponse
 */
export interface CreateAttachmentResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateAttachmentResponse
     */
    'attachmentId'?: number;
}
/**
 * 
 * @export
 * @interface ImageRequest
 */
export interface ImageRequest {
    /**
     * 
     * @type {string}
     * @memberof ImageRequest
     */
    'imageType': string;
    /**
     * 
     * @type {string}
     * @memberof ImageRequest
     */
    'imageName': string;
    /**
     * 
     * @type {string}
     * @memberof ImageRequest
     */
    'imageDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageRequest
     */
    'orientation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageRequest
     */
    'acquisitionType'?: string | null;
}
/**
 * 
 * @export
 * @interface ImageResponse
 */
export interface ImageResponse {
    /**
     * 
     * @type {number}
     * @memberof ImageResponse
     */
    'imageId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ImageResponse
     */
    'isLegacy'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ImageResponse
     */
    'imageTypeId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ImageResponse
     */
    'imageType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageResponse
     */
    'acquisitionType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageResponse
     */
    'imageDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageResponse
     */
    'orientation'?: string | null;
}
/**
 * 
 * @export
 * @interface ImageType
 */
export interface ImageType {
    /**
     * 
     * @type {number}
     * @memberof ImageType
     */
    'imageTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageType
     */
    'imageTypeName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ImageType
     */
    'isXray'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ImageType
     */
    'alias'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ImageType
     */
    'imageTypePaperOrFilm'?: number | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ProcedureListRequest
 */
export interface ProcedureListRequest {
    /**
     * 
     * @type {Array<ProcedurePerTooth>}
     * @memberof ProcedureListRequest
     */
    'procedureCodeList'?: Array<ProcedurePerTooth> | null;
}
/**
 * 
 * @export
 * @interface ProcedurePerTooth
 */
export interface ProcedurePerTooth {
    /**
     * 
     * @type {string}
     * @memberof ProcedurePerTooth
     */
    'procedureCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProcedurePerTooth
     */
    'toothNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface RefCarrierRequest
 */
export interface RefCarrierRequest {
    /**
     * 
     * @type {string}
     * @memberof RefCarrierRequest
     */
    'carrierName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RefCarrierRequest
     */
    'carrierAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RefCarrierRequest
     */
    'carrierCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RefCarrierRequest
     */
    'carrierState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RefCarrierRequest
     */
    'carrierZip'?: string | null;
}
/**
 * 
 * @export
 * @interface RefCarrierResponse
 */
export interface RefCarrierResponse {
    /**
     * 
     * @type {string}
     * @memberof RefCarrierResponse
     */
    'carrierId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RefCarrierResponse
     */
    'neaMasterId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RefCarrierResponse
     */
    'requiresPayorRef'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RefCarrierResponse
     */
    'allowsPayorRef'?: boolean;
}
/**
 * 
 * @export
 * @interface StoragePathResponse
 */
export interface StoragePathResponse {
    /**
     * 
     * @type {string}
     * @memberof StoragePathResponse
     */
    'imageName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoragePathResponse
     */
    'storagePath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoragePathResponse
     */
    'expirationTime'?: string;
}

/**
 * ApplicationApi - axios parameter creator
 * @export
 */
export const ApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ConvertImageRequest} convertImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationConvertS3Image: async (convertImageRequest: ConvertImageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'convertImageRequest' is not null or undefined
            assertParamExists('applicationConvertS3Image', 'convertImageRequest', convertImageRequest)
            const localVarPath = `/application/convert-image-s3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(convertImageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationGetImageTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/application/image-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationApi - functional programming interface
 * @export
 */
export const ApplicationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ConvertImageRequest} convertImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationConvertS3Image(convertImageRequest: ConvertImageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationConvertS3Image(convertImageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationGetImageTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationGetImageTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationApi - factory interface
 * @export
 */
export const ApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationApiFp(configuration)
    return {
        /**
         * 
         * @param {ConvertImageRequest} convertImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationConvertS3Image(convertImageRequest: ConvertImageRequest, options?: any): AxiosPromise<any> {
            return localVarFp.applicationConvertS3Image(convertImageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationGetImageTypes(options?: any): AxiosPromise<Array<ImageType>> {
            return localVarFp.applicationGetImageTypes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationApi - object-oriented interface
 * @export
 * @class ApplicationApi
 * @extends {BaseAPI}
 */
export class ApplicationApi extends BaseAPI {
    /**
     * 
     * @param {ConvertImageRequest} convertImageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationConvertS3Image(convertImageRequest: ConvertImageRequest, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).applicationConvertS3Image(convertImageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationGetImageTypes(options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).applicationGetImageTypes(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AttachmentApi - axios parameter creator
 * @export
 */
export const AttachmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} attachmentId 
         * @param {ImageRequest} imageRequest 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentCreateAttachmentImage: async (attachmentId: number, imageRequest: ImageRequest, isLegacy?: boolean, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('attachmentCreateAttachmentImage', 'attachmentId', attachmentId)
            // verify required parameter 'imageRequest' is not null or undefined
            assertParamExists('attachmentCreateAttachmentImage', 'imageRequest', imageRequest)
            const localVarPath = `/attachment/{attachmentId}/image`
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isLegacy !== undefined) {
                localVarQueryParameter['isLegacy'] = isLegacy;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentDeleteAttachment: async (attachmentId: number, isLegacy?: boolean, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('attachmentDeleteAttachment', 'attachmentId', attachmentId)
            const localVarPath = `/attachment/{attachmentId}`
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isLegacy !== undefined) {
                localVarQueryParameter['isLegacy'] = isLegacy;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {number} imageId 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentDeleteImage: async (attachmentId: number, imageId: number, isLegacy?: boolean, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('attachmentDeleteImage', 'attachmentId', attachmentId)
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('attachmentDeleteImage', 'imageId', imageId)
            const localVarPath = `/attachment/{attachmentId}/image/{imageId}`
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)))
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isLegacy !== undefined) {
                localVarQueryParameter['isLegacy'] = isLegacy;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {number} imageId 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentGetImage: async (attachmentId: number, imageId: number, isLegacy?: boolean, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('attachmentGetImage', 'attachmentId', attachmentId)
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('attachmentGetImage', 'imageId', imageId)
            const localVarPath = `/attachment/{attachmentId}/image/{imageId}`
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)))
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isLegacy !== undefined) {
                localVarQueryParameter['isLegacy'] = isLegacy;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} attachmentNumber 
         * @param {number} imageId 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentGetImageSent: async (attachmentNumber: string, imageId: number, isLegacy?: boolean, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentNumber' is not null or undefined
            assertParamExists('attachmentGetImageSent', 'attachmentNumber', attachmentNumber)
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('attachmentGetImageSent', 'imageId', imageId)
            const localVarPath = `/attachment/sent/{attachmentNumber}/image/{imageId}`
                .replace(`{${"attachmentNumber"}}`, encodeURIComponent(String(attachmentNumber)))
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isLegacy !== undefined) {
                localVarQueryParameter['isLegacy'] = isLegacy;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentGetStoragePath: async (customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/attachment/storage-path`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentGetStoragePathConversion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/attachment/storage-path-conversion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {AttachmentRequest} attachmentRequest 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentUpdateAttachment: async (attachmentId: number, attachmentRequest: AttachmentRequest, isLegacy?: boolean, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('attachmentUpdateAttachment', 'attachmentId', attachmentId)
            // verify required parameter 'attachmentRequest' is not null or undefined
            assertParamExists('attachmentUpdateAttachment', 'attachmentRequest', attachmentRequest)
            const localVarPath = `/attachment/{attachmentId}`
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isLegacy !== undefined) {
                localVarQueryParameter['isLegacy'] = isLegacy;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attachmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttachmentApi - functional programming interface
 * @export
 */
export const AttachmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AttachmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} attachmentId 
         * @param {ImageRequest} imageRequest 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentCreateAttachmentImage(attachmentId: number, imageRequest: ImageRequest, isLegacy?: boolean, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentCreateAttachmentImage(attachmentId, imageRequest, isLegacy, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentDeleteAttachment(attachmentId: number, isLegacy?: boolean, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentDeleteAttachment(attachmentId, isLegacy, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {number} imageId 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentDeleteImage(attachmentId: number, imageId: number, isLegacy?: boolean, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentDeleteImage(attachmentId, imageId, isLegacy, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {number} imageId 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentGetImage(attachmentId: number, imageId: number, isLegacy?: boolean, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoragePathResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentGetImage(attachmentId, imageId, isLegacy, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} attachmentNumber 
         * @param {number} imageId 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentGetImageSent(attachmentNumber: string, imageId: number, isLegacy?: boolean, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoragePathResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentGetImageSent(attachmentNumber, imageId, isLegacy, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentGetStoragePath(customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoragePathResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentGetStoragePath(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentGetStoragePathConversion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoragePathResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentGetStoragePathConversion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {AttachmentRequest} attachmentRequest 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentUpdateAttachment(attachmentId: number, attachmentRequest: AttachmentRequest, isLegacy?: boolean, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentUpdateAttachment(attachmentId, attachmentRequest, isLegacy, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AttachmentApi - factory interface
 * @export
 */
export const AttachmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AttachmentApiFp(configuration)
    return {
        /**
         * 
         * @param {number} attachmentId 
         * @param {ImageRequest} imageRequest 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentCreateAttachmentImage(attachmentId: number, imageRequest: ImageRequest, isLegacy?: boolean, customerId?: number, options?: any): AxiosPromise<ImageResponse> {
            return localVarFp.attachmentCreateAttachmentImage(attachmentId, imageRequest, isLegacy, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentDeleteAttachment(attachmentId: number, isLegacy?: boolean, customerId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.attachmentDeleteAttachment(attachmentId, isLegacy, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {number} imageId 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentDeleteImage(attachmentId: number, imageId: number, isLegacy?: boolean, customerId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.attachmentDeleteImage(attachmentId, imageId, isLegacy, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {number} imageId 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentGetImage(attachmentId: number, imageId: number, isLegacy?: boolean, customerId?: number, options?: any): AxiosPromise<StoragePathResponse> {
            return localVarFp.attachmentGetImage(attachmentId, imageId, isLegacy, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} attachmentNumber 
         * @param {number} imageId 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentGetImageSent(attachmentNumber: string, imageId: number, isLegacy?: boolean, customerId?: number, options?: any): AxiosPromise<StoragePathResponse> {
            return localVarFp.attachmentGetImageSent(attachmentNumber, imageId, isLegacy, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentGetStoragePath(customerId?: number, options?: any): AxiosPromise<StoragePathResponse> {
            return localVarFp.attachmentGetStoragePath(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentGetStoragePathConversion(options?: any): AxiosPromise<StoragePathResponse> {
            return localVarFp.attachmentGetStoragePathConversion(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {AttachmentRequest} attachmentRequest 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentUpdateAttachment(attachmentId: number, attachmentRequest: AttachmentRequest, isLegacy?: boolean, customerId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.attachmentUpdateAttachment(attachmentId, attachmentRequest, isLegacy, customerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AttachmentApi - object-oriented interface
 * @export
 * @class AttachmentApi
 * @extends {BaseAPI}
 */
export class AttachmentApi extends BaseAPI {
    /**
     * 
     * @param {number} attachmentId 
     * @param {ImageRequest} imageRequest 
     * @param {boolean} [isLegacy] 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentCreateAttachmentImage(attachmentId: number, imageRequest: ImageRequest, isLegacy?: boolean, customerId?: number, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentCreateAttachmentImage(attachmentId, imageRequest, isLegacy, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} attachmentId 
     * @param {boolean} [isLegacy] 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentDeleteAttachment(attachmentId: number, isLegacy?: boolean, customerId?: number, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentDeleteAttachment(attachmentId, isLegacy, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} attachmentId 
     * @param {number} imageId 
     * @param {boolean} [isLegacy] 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentDeleteImage(attachmentId: number, imageId: number, isLegacy?: boolean, customerId?: number, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentDeleteImage(attachmentId, imageId, isLegacy, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} attachmentId 
     * @param {number} imageId 
     * @param {boolean} [isLegacy] 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentGetImage(attachmentId: number, imageId: number, isLegacy?: boolean, customerId?: number, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentGetImage(attachmentId, imageId, isLegacy, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} attachmentNumber 
     * @param {number} imageId 
     * @param {boolean} [isLegacy] 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentGetImageSent(attachmentNumber: string, imageId: number, isLegacy?: boolean, customerId?: number, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentGetImageSent(attachmentNumber, imageId, isLegacy, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentGetStoragePath(customerId?: number, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentGetStoragePath(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentGetStoragePathConversion(options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentGetStoragePathConversion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} attachmentId 
     * @param {AttachmentRequest} attachmentRequest 
     * @param {boolean} [isLegacy] 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentUpdateAttachment(attachmentId: number, attachmentRequest: AttachmentRequest, isLegacy?: boolean, customerId?: number, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentUpdateAttachment(attachmentId, attachmentRequest, isLegacy, customerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CarrierApi - axios parameter creator
 * @export
 */
export const CarrierApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierGetCarrierList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/attachment-carrier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefCarrierRequest} refCarrierRequest 
         * @param {string} [claimId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierMatch: async (refCarrierRequest: RefCarrierRequest, claimId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refCarrierRequest' is not null or undefined
            assertParamExists('carrierMatch', 'refCarrierRequest', refCarrierRequest)
            const localVarPath = `/attachment-carrier/match`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (claimId !== undefined) {
                localVarQueryParameter['claimId'] = claimId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refCarrierRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CarrierApi - functional programming interface
 * @export
 */
export const CarrierApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CarrierApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carrierGetCarrierList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarrierListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.carrierGetCarrierList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RefCarrierRequest} refCarrierRequest 
         * @param {string} [claimId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carrierMatch(refCarrierRequest: RefCarrierRequest, claimId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefCarrierResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.carrierMatch(refCarrierRequest, claimId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CarrierApi - factory interface
 * @export
 */
export const CarrierApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CarrierApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierGetCarrierList(options?: any): AxiosPromise<CarrierListResponse> {
            return localVarFp.carrierGetCarrierList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefCarrierRequest} refCarrierRequest 
         * @param {string} [claimId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carrierMatch(refCarrierRequest: RefCarrierRequest, claimId?: string, options?: any): AxiosPromise<RefCarrierResponse> {
            return localVarFp.carrierMatch(refCarrierRequest, claimId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CarrierApi - object-oriented interface
 * @export
 * @class CarrierApi
 * @extends {BaseAPI}
 */
export class CarrierApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public carrierGetCarrierList(options?: AxiosRequestConfig) {
        return CarrierApiFp(this.configuration).carrierGetCarrierList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefCarrierRequest} refCarrierRequest 
     * @param {string} [claimId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public carrierMatch(refCarrierRequest: RefCarrierRequest, claimId?: string, options?: AxiosRequestConfig) {
        return CarrierApiFp(this.configuration).carrierMatch(refCarrierRequest, claimId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClaimApi - axios parameter creator
 * @export
 */
export const ClaimApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} claimId 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimClearAttachmentRequirements: async (claimId: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('claimClearAttachmentRequirements', 'claimId', claimId)
            const localVarPath = `/claim/{claimId}/clear-attachment-requirements`
                .replace(`{${"claimId"}}`, encodeURIComponent(String(claimId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} claimId 
         * @param {AttachmentRequest} attachmentRequest 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimCreateClaimAttachment: async (claimId: number, attachmentRequest: AttachmentRequest, isLegacy?: boolean, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('claimCreateClaimAttachment', 'claimId', claimId)
            // verify required parameter 'attachmentRequest' is not null or undefined
            assertParamExists('claimCreateClaimAttachment', 'attachmentRequest', attachmentRequest)
            const localVarPath = `/claim/{claimId}/attachment`
                .replace(`{${"claimId"}}`, encodeURIComponent(String(claimId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isLegacy !== undefined) {
                localVarQueryParameter['isLegacy'] = isLegacy;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attachmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} claimId 
         * @param {string} matchId 
         * @param {boolean} isLegacy 
         * @param {ProcedureListRequest} [procedureListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimGetAttachmentRequirements: async (claimId: number, matchId: string, isLegacy: boolean, procedureListRequest?: ProcedureListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('claimGetAttachmentRequirements', 'claimId', claimId)
            // verify required parameter 'matchId' is not null or undefined
            assertParamExists('claimGetAttachmentRequirements', 'matchId', matchId)
            // verify required parameter 'isLegacy' is not null or undefined
            assertParamExists('claimGetAttachmentRequirements', 'isLegacy', isLegacy)
            const localVarPath = `/claim/{claimId}/attachment-requirements`
                .replace(`{${"claimId"}}`, encodeURIComponent(String(claimId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (matchId !== undefined) {
                localVarQueryParameter['matchId'] = matchId;
            }

            if (isLegacy !== undefined) {
                localVarQueryParameter['isLegacy'] = isLegacy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(procedureListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} claimId 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimGetAttachmentRequirementsIgnoredState: async (claimId: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('claimGetAttachmentRequirementsIgnoredState', 'claimId', claimId)
            const localVarPath = `/claim/{claimId}/ignore-attachment-requirements`
                .replace(`{${"claimId"}}`, encodeURIComponent(String(claimId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} claimId 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimGetClaimAttachment: async (claimId: number, isLegacy?: boolean, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('claimGetClaimAttachment', 'claimId', claimId)
            const localVarPath = `/claim/{claimId}/attachment`
                .replace(`{${"claimId"}}`, encodeURIComponent(String(claimId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isLegacy !== undefined) {
                localVarQueryParameter['isLegacy'] = isLegacy;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} claimId 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimGetClaimAttachmentSent: async (claimId: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('claimGetClaimAttachmentSent', 'claimId', claimId)
            const localVarPath = `/claim/{claimId}/attachment/sent`
                .replace(`{${"claimId"}}`, encodeURIComponent(String(claimId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} claimId 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimIgnoreAttachmentRequirements: async (claimId: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('claimIgnoreAttachmentRequirements', 'claimId', claimId)
            const localVarPath = `/claim/{claimId}/ignore-attachment-requirements`
                .replace(`{${"claimId"}}`, encodeURIComponent(String(claimId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClaimApi - functional programming interface
 * @export
 */
export const ClaimApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClaimApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} claimId 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimClearAttachmentRequirements(claimId: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimClearAttachmentRequirements(claimId, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} claimId 
         * @param {AttachmentRequest} attachmentRequest 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimCreateClaimAttachment(claimId: number, attachmentRequest: AttachmentRequest, isLegacy?: boolean, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAttachmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimCreateClaimAttachment(claimId, attachmentRequest, isLegacy, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} claimId 
         * @param {string} matchId 
         * @param {boolean} isLegacy 
         * @param {ProcedureListRequest} [procedureListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimGetAttachmentRequirements(claimId: number, matchId: string, isLegacy: boolean, procedureListRequest?: ProcedureListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachmentRequirementsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimGetAttachmentRequirements(claimId, matchId, isLegacy, procedureListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} claimId 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimGetAttachmentRequirementsIgnoredState(claimId: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachmentRequirementsIgnoredResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimGetAttachmentRequirementsIgnoredState(claimId, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} claimId 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimGetClaimAttachment(claimId: number, isLegacy?: boolean, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimGetClaimAttachment(claimId, isLegacy, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} claimId 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimGetClaimAttachmentSent(claimId: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachmentSentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimGetClaimAttachmentSent(claimId, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} claimId 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimIgnoreAttachmentRequirements(claimId: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimIgnoreAttachmentRequirements(claimId, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClaimApi - factory interface
 * @export
 */
export const ClaimApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClaimApiFp(configuration)
    return {
        /**
         * 
         * @param {number} claimId 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimClearAttachmentRequirements(claimId: number, customerId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.claimClearAttachmentRequirements(claimId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} claimId 
         * @param {AttachmentRequest} attachmentRequest 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimCreateClaimAttachment(claimId: number, attachmentRequest: AttachmentRequest, isLegacy?: boolean, customerId?: number, options?: any): AxiosPromise<CreateAttachmentResponse> {
            return localVarFp.claimCreateClaimAttachment(claimId, attachmentRequest, isLegacy, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} claimId 
         * @param {string} matchId 
         * @param {boolean} isLegacy 
         * @param {ProcedureListRequest} [procedureListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimGetAttachmentRequirements(claimId: number, matchId: string, isLegacy: boolean, procedureListRequest?: ProcedureListRequest, options?: any): AxiosPromise<AttachmentRequirementsResponse> {
            return localVarFp.claimGetAttachmentRequirements(claimId, matchId, isLegacy, procedureListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} claimId 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimGetAttachmentRequirementsIgnoredState(claimId: number, customerId?: number, options?: any): AxiosPromise<AttachmentRequirementsIgnoredResponse> {
            return localVarFp.claimGetAttachmentRequirementsIgnoredState(claimId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} claimId 
         * @param {boolean} [isLegacy] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimGetClaimAttachment(claimId: number, isLegacy?: boolean, customerId?: number, options?: any): AxiosPromise<AttachmentResponse> {
            return localVarFp.claimGetClaimAttachment(claimId, isLegacy, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} claimId 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimGetClaimAttachmentSent(claimId: number, customerId?: number, options?: any): AxiosPromise<AttachmentSentResponse> {
            return localVarFp.claimGetClaimAttachmentSent(claimId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} claimId 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimIgnoreAttachmentRequirements(claimId: number, customerId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.claimIgnoreAttachmentRequirements(claimId, customerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClaimApi - object-oriented interface
 * @export
 * @class ClaimApi
 * @extends {BaseAPI}
 */
export class ClaimApi extends BaseAPI {
    /**
     * 
     * @param {number} claimId 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimApi
     */
    public claimClearAttachmentRequirements(claimId: number, customerId?: number, options?: AxiosRequestConfig) {
        return ClaimApiFp(this.configuration).claimClearAttachmentRequirements(claimId, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} claimId 
     * @param {AttachmentRequest} attachmentRequest 
     * @param {boolean} [isLegacy] 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimApi
     */
    public claimCreateClaimAttachment(claimId: number, attachmentRequest: AttachmentRequest, isLegacy?: boolean, customerId?: number, options?: AxiosRequestConfig) {
        return ClaimApiFp(this.configuration).claimCreateClaimAttachment(claimId, attachmentRequest, isLegacy, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} claimId 
     * @param {string} matchId 
     * @param {boolean} isLegacy 
     * @param {ProcedureListRequest} [procedureListRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimApi
     */
    public claimGetAttachmentRequirements(claimId: number, matchId: string, isLegacy: boolean, procedureListRequest?: ProcedureListRequest, options?: AxiosRequestConfig) {
        return ClaimApiFp(this.configuration).claimGetAttachmentRequirements(claimId, matchId, isLegacy, procedureListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} claimId 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimApi
     */
    public claimGetAttachmentRequirementsIgnoredState(claimId: number, customerId?: number, options?: AxiosRequestConfig) {
        return ClaimApiFp(this.configuration).claimGetAttachmentRequirementsIgnoredState(claimId, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} claimId 
     * @param {boolean} [isLegacy] 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimApi
     */
    public claimGetClaimAttachment(claimId: number, isLegacy?: boolean, customerId?: number, options?: AxiosRequestConfig) {
        return ClaimApiFp(this.configuration).claimGetClaimAttachment(claimId, isLegacy, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} claimId 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimApi
     */
    public claimGetClaimAttachmentSent(claimId: number, customerId?: number, options?: AxiosRequestConfig) {
        return ClaimApiFp(this.configuration).claimGetClaimAttachmentSent(claimId, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} claimId 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimApi
     */
    public claimIgnoreAttachmentRequirements(claimId: number, customerId?: number, options?: AxiosRequestConfig) {
        return ClaimApiFp(this.configuration).claimIgnoreAttachmentRequirements(claimId, customerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScreenCaptureApi - axios parameter creator
 * @export
 */
export const ScreenCaptureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} imageName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        screenCaptureDeleteImage: async (imageName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageName' is not null or undefined
            assertParamExists('screenCaptureDeleteImage', 'imageName', imageName)
            const localVarPath = `/screen-capture/image/{imageName}`
                .replace(`{${"imageName"}}`, encodeURIComponent(String(imageName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} imageName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        screenCaptureGetImage: async (imageName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageName' is not null or undefined
            assertParamExists('screenCaptureGetImage', 'imageName', imageName)
            const localVarPath = `/screen-capture/image/{imageName}`
                .replace(`{${"imageName"}}`, encodeURIComponent(String(imageName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        screenCaptureGetStoragePath: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/screen-capture/storage-path`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScreenCaptureApi - functional programming interface
 * @export
 */
export const ScreenCaptureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScreenCaptureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} imageName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async screenCaptureDeleteImage(imageName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.screenCaptureDeleteImage(imageName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} imageName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async screenCaptureGetImage(imageName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.screenCaptureGetImage(imageName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async screenCaptureGetStoragePath(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoragePathResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.screenCaptureGetStoragePath(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScreenCaptureApi - factory interface
 * @export
 */
export const ScreenCaptureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScreenCaptureApiFp(configuration)
    return {
        /**
         * 
         * @param {string} imageName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        screenCaptureDeleteImage(imageName: string, options?: any): AxiosPromise<void> {
            return localVarFp.screenCaptureDeleteImage(imageName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} imageName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        screenCaptureGetImage(imageName: string, options?: any): AxiosPromise<any> {
            return localVarFp.screenCaptureGetImage(imageName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        screenCaptureGetStoragePath(options?: any): AxiosPromise<StoragePathResponse> {
            return localVarFp.screenCaptureGetStoragePath(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScreenCaptureApi - object-oriented interface
 * @export
 * @class ScreenCaptureApi
 * @extends {BaseAPI}
 */
export class ScreenCaptureApi extends BaseAPI {
    /**
     * 
     * @param {string} imageName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreenCaptureApi
     */
    public screenCaptureDeleteImage(imageName: string, options?: AxiosRequestConfig) {
        return ScreenCaptureApiFp(this.configuration).screenCaptureDeleteImage(imageName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} imageName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreenCaptureApi
     */
    public screenCaptureGetImage(imageName: string, options?: AxiosRequestConfig) {
        return ScreenCaptureApiFp(this.configuration).screenCaptureGetImage(imageName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreenCaptureApi
     */
    public screenCaptureGetStoragePath(options?: AxiosRequestConfig) {
        return ScreenCaptureApiFp(this.configuration).screenCaptureGetStoragePath(options).then((request) => request(this.axios, this.basePath));
    }
}


