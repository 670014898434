import React, { FC } from 'react'

import FileUpload from './fileUpload'
import KeyboardCapture from './keyboardCapture/keyboardCapture'
import ScreenCapture from './screenCapture'

import './AttachmentImageCapture.scss'

import ImageSync from '../../../attachmentsShared/imageSync/imageSync'
import { IImageCaptureClaimAttachment } from '../../../attachmentsShared/shared/shared.interface'

const AttachmentImageCapture: FC<IImageCaptureClaimAttachment> = ({
  attachmentInformation,
  updateAttachmentImage,
  updateAttachmentImages,
  handleAttachmentImageAssembly,
}) => {
  return (
    <div className='image-capture__row mb-100'>
      <ScreenCapture updateAttachmentImage={updateAttachmentImage} />
      <FileUpload updateAttachmentImage={updateAttachmentImage} />
      <KeyboardCapture updateAttachmentImage={updateAttachmentImage} />
      <ImageSync
        attachmentInformation={attachmentInformation}
        updateAttachmentImages={updateAttachmentImages}
        handleAttachmentImageAssembly={handleAttachmentImageAssembly}
      />
    </div>
  )
}

export default AttachmentImageCapture
