import { format } from 'date-fns'
import { FC } from 'react'

import { getEligibilityStatusInfo, StatusDisplay } from 'ui'

import { getStatusOverride } from 'trellis:constants/claimStatusDescriptionData'
import GlobalState, { LDFlags$ } from 'trellis:state/globalState'

import { SmartTable } from '../../_siteWide/table/SmartTable'
import { ColumnType } from '../../_siteWide/table/SmartTableTypes'
import { OnetimeEligibility } from '../../../api/eligibility/eligibility-client'
import { GetOnetimeHistories } from '../../../api/eligibility/eligibilityApi'
import { NotifyText } from '../../../constants/notifyText'
import { useGlobalContext } from '../../../context/GlobalContextProvider'
import { showMessage } from '../../../utilities/general'
import {
  OneTimeRequestStateType,
  useEligibilityContext,
} from '../shared/context/EligibilityContext'
import EligibilityActionCell from './shared/EligibilityActionCell/EligibilityActionCell'

const defaultSearch = { Name: '' }

const OneTimeRequestsGrid: FC<{ toolbar: JSX.Element }> = ({ toolbar }) => {
  const { authentication } = useGlobalContext()
  const {
    loading,
    oneTimeRequestState,
    setLoading,
    setOneTimeRequestState,
    setShowEligibilityResponseModal,
    setVerificationResponse,
  } = useEligibilityContext()

  const getRequests = () => {
    setLoading(true)

    const copy = { ...oneTimeRequestState }
    if (copy.OriginalData.length && copy.Filters.Name) {
      const filteredRequests = copy.OriginalData.filter(
        (item: OnetimeEligibility) => {
          const hasFirstName = item.PatientFirstName?.toLowerCase()
            ?.trim()
            .includes(copy.Filters.Name?.toLowerCase()?.trim())
          const hasLastName = item.PatientLastName?.toLowerCase()
            ?.trim()
            .includes(copy.Filters.Name?.toLowerCase()?.trim())

          if (hasFirstName || hasLastName) return item
          else return null
        },
      )

      copy.Data = filteredRequests
      copy.Total = filteredRequests.length

      setOneTimeRequestState(copy)
      setLoading(false)
    } else getOneTimeHistories()
  }

  const getOneTimeHistories = async () => {
    await GetOnetimeHistories()
      .then(({ data }) => {
        const copy: OneTimeRequestStateType = { ...oneTimeRequestState }
        copy.Data = data
        copy.OriginalData = data
        copy.Total = data.length

        setOneTimeRequestState(copy)
      })
      .catch(() => showMessage(NotifyText.oneTimeHistoryError))
      .finally(() => setLoading(false))
  }

  const columns: ColumnType[] = [
    {
      dataIndex: ['PatientFirstName', 'PatientLastName'],
      noSort: true,
      render: (_value, tableItem: OnetimeEligibility) => (
        <p>
          {tableItem.PatientFirstName} {tableItem.PatientLastName}
        </p>
      ),
      title: 'Patient Name',
    },
    {
      dataIndex: 'CarrierName',
      noFilter: true,
      noSort: true,
      title: 'Carrier',
    },
    {
      dataIndex: 'Status',
      noFilter: true,
      noSort: true,
      render: (value: string) => {
        const info = getEligibilityStatusInfo(
          value,
          GlobalState.EligibilityStatusMapping.peek(),
        )
        return (
          <StatusDisplay
            status={info.status}
            toolTip={info.toolTip}
            getStatusOverride={getStatusOverride}
          />
        )
      },
    },
    {
      dataIndex: 'ResponseDate',
      noFilter: true,
      noSort: true,
      render: (dateString: string, tableItem: OnetimeEligibility) => (
        <EligibilityActionCell<OnetimeEligibility>
          authentication={authentication}
          tableItem={tableItem}
          label={format(new Date(dateString), 'MM/dd/yyy')}
          setShowEligibilityResponseModal={setShowEligibilityResponseModal}
          setVerificationResponse={setVerificationResponse}
        />
      ),
      title: 'Last Verified',
    },
  ]

  return (
    <SmartTable
      columns={columns}
      defaultSearch={defaultSearch}
      getData={getRequests}
      isFullViewport={true}
      loading={loading}
      rowKey='HistoryId'
      setState={setOneTimeRequestState}
      state={oneTimeRequestState}
      tableClass='one-time-requests-table'
      toolbar={toolbar}
    />
  )
}

export default OneTimeRequestsGrid
