export type StateAbbreviations =
  | 'AL'
  | 'AK'
  | 'AS'
  | 'AZ'
  | 'AR'
  | 'CA'
  | 'CO'
  | 'CT'
  | 'DE'
  | 'DC'
  | 'FM'
  | 'FL'
  | 'GA'
  | 'GU'
  | 'HI'
  | 'ID'
  | 'IL'
  | 'IN'
  | 'IA'
  | 'KS'
  | 'KY'
  | 'LA'
  | 'ME'
  | 'MH'
  | 'MD'
  | 'MA'
  | 'MI'
  | 'MN'
  | 'MS'
  | 'MO'
  | 'MT'
  | 'NE'
  | 'NV'
  | 'NH'
  | 'NJ'
  | 'NM'
  | 'NY'
  | 'NC'
  | 'ND'
  | 'MP'
  | 'OH'
  | 'OK'
  | 'OR'
  | 'PW'
  | 'PA'
  | 'PR'
  | 'RI'
  | 'SC'
  | 'SD'
  | 'TN'
  | 'TX'
  | 'UT'
  | 'VT'
  | 'VI'
  | 'VA'
  | 'WA'
  | 'WV'
  | 'WI'
  | 'WY'
  | ''

export const stateSelectOptions = [
  { text: 'Alabama', label: 'Alabama', value: 'AL' },
  { text: 'Alaska', label: 'Alaska', value: 'AK' },
  { text: 'Arizona', label: 'Arizona', value: 'AZ' },
  { text: 'Arkansas', label: 'Arkansas', value: 'AR' },
  { text: 'California', label: 'California', value: 'CA' },
  { text: 'Colorado', label: 'Colorado', value: 'CO' },
  { text: 'Connecticut', label: 'Connecticut', value: 'CT' },
  { text: 'District of Columbia', label: 'District of Columbia', value: 'DC' },
  { text: 'Delaware', label: 'Delaware', value: 'DE' },
  { text: 'Florida', label: 'Florida', value: 'FL' },
  { text: 'Georgia', label: 'Georgia', value: 'GA' },
  { text: 'Hawaii', label: 'Hawaii', value: 'HI' },
  { text: 'Idaho', label: 'Idaho', value: 'ID' },
  { text: 'Illinois', label: 'Illinois', value: 'IL' },
  { text: 'Indiana', label: 'Indiana', value: 'IN' },
  { text: 'Iowa', label: 'Iowa', value: 'IA' },
  { text: 'Kansas', label: 'Kansas', value: 'KS' },
  { text: 'Kentucky', label: 'Kentucky', value: 'KY' },
  { text: 'Louisiana', label: 'Louisiana', value: 'LA' },
  { text: 'Maine', label: 'Maine', value: 'ME' },
  { text: 'Maryland', label: 'Maryland', value: 'MD' },
  { text: 'Massachusetts', label: 'Massachusetts', value: 'MA' },
  { text: 'Michigan', label: 'Michigan', value: 'MI' },
  { text: 'Minnesota', label: 'Minnesota', value: 'MN' },
  { text: 'Mississippi', label: 'Mississippi', value: 'MS' },
  { text: 'Missouri', label: 'Missouri', value: 'MO' },
  { text: 'Montana', label: 'Montana', value: 'MT' },
  { text: 'Nebraska', label: 'Nebraska', value: 'NE' },
  { text: 'Nevada', label: 'Nevada', value: 'NV' },
  { text: 'New Hampshire', label: 'New Hampshire', value: 'NH' },
  { text: 'New Jersey', label: 'New Jersey', value: 'NJ' },
  { text: 'New Mexico', label: 'New Mexico', value: 'NM' },
  { text: 'New York', label: 'New York', value: 'NY' },
  { text: 'North Carolina', label: 'North Carolina', value: 'NC' },
  { text: 'North Dakota', label: 'North Dakota', value: 'ND' },
  { text: 'Ohio', label: 'Ohio', value: 'OH' },
  { text: 'Oklahoma', label: 'Oklahoma', value: 'OK' },
  { text: 'Oregon', label: 'Oregon', value: 'OR' },
  { text: 'Pennsylvania', label: 'Pennsylvania', value: 'PA' },
  { text: 'Rhode Island', label: 'Rhode Island', value: 'RI' },
  { text: 'South Carolina', label: 'South Carolina', value: 'SC' },
  { text: 'South Dakota', label: 'South Dakota', value: 'SD' },
  { text: 'Tennessee', label: 'Tennessee', value: 'TN' },
  { text: 'Texas', label: 'Texas', value: 'TX' },
  { text: 'Utah', label: 'Utah', value: 'UT' },
  { text: 'Vermont', label: 'Vermont', value: 'VT' },
  { text: 'Virgin Islands', label: 'Virgin Islands', value: 'VI' },
  { text: 'Virginia', label: 'Virginia', value: 'VA' },
  { text: 'Washington', label: 'Washington', value: 'WA' },
  { text: 'West Virginia', label: 'West Virginia', value: 'WV' },
  { text: 'Wisconsin', label: 'Wisconsin', value: 'WI' },
  { text: 'Wyoming', label: 'Wyoming', value: 'WY' },
]
