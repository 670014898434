import { Col, Row } from 'antd'
import { FC } from 'react'

import { Button } from 'ui'

import { handleDateOfServiceDisplay } from '../../shared/sharedFunctions'
import { ImageListPatientDetailsProps } from '../imageSync.interface'

import './imageListPatientDetails.scss'

const ImageListPatientDetails: FC<ImageListPatientDetailsProps> = ({
  loading,
  attachmentInformation,
  imagesSelectedCount,
  handleAddAndClose,
}) => {
  return (
    <>
      <Row className='patient-details__patient-info'>
        <Col span={12}>
          <p className='fs-125'>
            <b>
              {attachmentInformation.patientFirstName}{' '}
              {attachmentInformation.patientLastName}
            </b>
          </p>
        </Col>
        <Col
          span={12}
          className='patient-details__header-actions'
        >
          <Button
            type='primary'
            disabled={loading || imagesSelectedCount == 0}
            label={
              imagesSelectedCount > 0
                ? `Add (${imagesSelectedCount}) Images`
                : `Select Images`
            }
            onClick={() => handleAddAndClose()}
          />
        </Col>
      </Row>
      <Row className='patient-details__patient-info'>
        <Col span={12}>
          <p className='patient-details__patient-dob fs-075'>
            Date of Service:{' '}
            {handleDateOfServiceDisplay(
              attachmentInformation.dateOfServiceFrom,
              attachmentInformation.dateOfServiceTo,
            )}
          </p>
        </Col>
      </Row>
      <Row>&nbsp;</Row>
    </>
  )
}

export default ImageListPatientDetails
