import { spacesToProperty } from '../../../utilities/general'

// NOTE: may want to move common interfaces into own file
export interface FormInput {
  disabled?: boolean
  element: {}
  initialValue: string | number // NOTE: should only be one type; update when moving to SPA and enforcing typing
  label?: string
  name: string | [number, string] // NOTE: should only be one type; update when moving to SPA and enforcing typing
  noFloat?: boolean
  parentProperty?: string
  required?: boolean
  section?: string
  setElement: (element: {}) => void
  validator?: (value: string | number, formData: {}, name: string) => void
  width?: string
}

export interface FormError {
  index: number
  message: string
  parentProperty?: string
  property: string
  section?: string
}

export const getRules = (property: string, required: boolean) => {
  let propertyName = property
  if (Array.isArray(property)) propertyName = property[1]

  return {
    required: required,
    message: required && `${spacesToProperty(propertyName)} is required`,
  }
}

export const getError = (property: any, element: any) => {
  if (!element) return null
  if (!element.errors) element.errors = []

  let error
  let propertyName = property
  if (Array.isArray(property)) {
    const index = property[0]
    propertyName = property[1]
    error = element.errors.filter(
      (e: any) => e.property === propertyName && e.index === index,
    )[0]
  } else {
    error = element.errors.filter((e: any) => e.property === propertyName)[0]
  }

  return error
}

export const validateRequiredFields = (formData: any, optionalFields: any) => {
  Object.keys(formData).forEach((k: any) => {
    if (optionalFields.includes(k) || k === 'errors') return

    const value = formData[k]
    if (!value) {
      formData.errors.push({
        property: k,
        message: `${spacesToProperty(k)} is required`,
      })
    }
  })
}

export const handleFormChange = (
  name: any,
  parentProperty: any,
  value: any,
  element: any,
) => {
  const copy = { ...element }

  if (Array.isArray(name)) {
    const index = name[0]
    const arrayProperty = name[1]
    copy[parentProperty][index][arrayProperty] = value
  } else {
    copy[name] = value
  }

  return copy
}
