import { PlusCircleOutlined } from '@ant-design/icons'
import { Col, Form, FormListFieldData, Row } from 'antd'
import React, { FC, useEffect } from 'react'

import { FormInput } from '../../../../../_siteWide/form/formInput'
import { addCommasToNumber } from '../../../../../../utilities/general'
import { useClaimDetailContext } from '../../../../context/claimDetailContext'
import { LineItemType } from '../../../../util/claimTypes'
import LineItem from './lineItem'

const LineItemControls: FC = () => {
  const { claim, setClaim, validate, feeTotal, setFeeTotal } =
    useClaimDetailContext()

  useEffect(() => {
    const fee: number = claim.LineItems?.reduce(
      (accumulator: number, currentLineItem: LineItemType) => {
        const current = currentLineItem?.ProcedureChargeAmount
          ? parseFloat(currentLineItem.ProcedureChargeAmount)
          : 0.0
        return accumulator + current
      },
      0,
    )
    setFeeTotal(`${fee.toFixed(2)}`)
  })

  const handleAdd = () => {
    if (claim.LineItems.length === 10) return

    const copy = { ...claim }

    copy.LineItems.push({
      DiagPointer: null,
      LineNumber: copy.LineItems.length + 1,
      OralCavityCode: null,
      ProcedureChargeAmount: '0',
      ProcedureCode: '',
      ProcedureCodeDesc: '',
      ProcedureDate: '',
      SERVICE_DATE_String: '',
      Quantity: '1',
      ToothNumber: null,
      ToothSurfaces: null,
      ToothSystem: null,
    })

    copy.LineItems[0].LineNumber = 1

    setClaim(copy)
  }

  return (
    <Form.List name='LineItems'>
      {(fields: FormListFieldData[]) => {
        return (
          <>
            <div className='form-items-container'>
              <LineItemsHeader />
              {fields.map((_value: FormListFieldData, index: number) => (
                <LineItem
                  key={index}
                  index={index}
                />
              ))}
              <PlusCircleOutlined
                className='fs-125 ml-100'
                data-testid='add-line-item'
                onClick={handleAdd}
              />
              <Row
                justify='end'
                align='middle'
                className='total-row mt-200 pr-200'
              >
                <span className='info-label fs-125 pr-050'>Total:</span>
                <strong className='fs-175'>
                  ${addCommasToNumber(feeTotal)}
                </strong>
              </Row>
            </div>
            <Row className='pt-200'>
              <Col span={24}>
                <FormInput
                  name='Remarks'
                  label='Remarks'
                  element={claim}
                  setElement={setClaim}
                  initialValue={claim.Remarks}
                />
              </Col>
            </Row>
          </>
        )
      }}
    </Form.List>
  )
}

export default LineItemControls

const LineItemsHeader = () => (
  <Row
    className='form-row-header'
    gutter={16}
    wrap={false}
  >
    <Col span={1}>Line</Col>
    <Col span={3}>Date</Col>
    <Col span={2}>Oral Cavity</Col>
    <Col span={2}>Tooth</Col>
    <Col span={2}>Surface</Col>
    <Col span={3}>Code</Col>
    <Col span={2}>Pointer</Col>
    <Col span={2}>Qty</Col>
    <Col span={4}>Description</Col>
    <Col>Fee</Col>
  </Row>
)
