import { compareDesc } from 'date-fns'

import api from '../../utilities/api'

export interface ProviderFields {
  isDefault: boolean
  licenseNumber: string
  providerFirstName: string
  providerLastName: string
  providerNPI: string
  serviceType: string
  taxId: string
  taxonomyCode: string
}

export interface Provider extends ProviderFields {
  eligibilityDefaultProviderID: number
  globalCustomerID: number
}
export interface TrellisProvider extends Provider {
  addDate: string
  lastUpdateDate: string
  fastAttachProviderID: number
}

export interface TrellisProviderInfo {
  providers?: TrellisProvider[]
  treatingProviders?: TrellisProvider[]
  defaultProvider?: TrellisProvider
  defaultTreatingProvider?: TrellisProvider //this might not be the same as the default provider if the default provider is billing
}

export const ProviderType = {
  Treatment: 'Treatment',
  Billing: 'Billing',
}

export const fetchTrellisProviderDetails = async () => {
  return api.getProviders().then(({ data }) => {
    const newProviderInfo: TrellisProviderInfo = {}

    const sortedProviders: TrellisProvider[] = data.providers.sort(
      (providerA: TrellisProvider, providerB: TrellisProvider) => {
        return compareDesc(
          new Date(providerA.addDate),
          new Date(providerB.addDate),
        )
      },
    )

    //Remove dupes if there are any
    newProviderInfo.providers = sortedProviders.filter(
      (provider, index, self) => {
        return (
          self.findIndex(
            (providerA) =>
              providerA.eligibilityDefaultProviderID ===
              provider.eligibilityDefaultProviderID,
          ) === index
        )
      },
    )

    newProviderInfo.treatingProviders = newProviderInfo.providers.filter(
      (provider: TrellisProvider) => {
        return provider.serviceType == ProviderType.Treatment
      },
    )

    newProviderInfo.defaultProvider = newProviderInfo.providers.find(
      (provider) => provider.isDefault,
    )

    if (
      newProviderInfo.defaultProvider &&
      newProviderInfo.defaultProvider.serviceType == ProviderType.Treatment
    ) {
      newProviderInfo.defaultTreatingProvider = newProviderInfo.defaultProvider
    } else if (
      newProviderInfo.treatingProviders &&
      newProviderInfo.treatingProviders.length === 1
    ) {
      newProviderInfo.defaultTreatingProvider =
        newProviderInfo.treatingProviders[0] //they only have one treating provider so treat it like it's default
    }
    return newProviderInfo
  })
}
