import Fuse from 'fuse.js'

interface FuseHighlightProps<ListType> {
  hit: Fuse.FuseResult<ListType>
  attribute: string
}

const FuseHighlight = <ListType,>({
  hit,
  attribute,
}: FuseHighlightProps<ListType>) => {
  const matches =
    typeof hit.item === 'string'
      ? hit.matches?.[0]
      : hit.matches?.find(
          (match: Fuse.FuseResultMatch) => match.key === attribute,
        )
  const fallback =
    typeof hit.item === 'string'
      ? hit.item
      : resolveAttribute<ListType>(hit.item, attribute)
  return highlight(matches?.value || fallback, matches?.indices)
}

const resolveAttribute = <ListType,>(obj: ListType, key: string) => {
  const keyArray = key.split('.')
  if (keyArray.length === 2)
    return obj?.[keyArray[1] as keyof typeof obj] as string

  return obj?.[keyArray[0] as keyof typeof obj] as string
}

const highlight = (
  value: string,
  indices: readonly Fuse.RangeTuple[] = [],
  i = 1,
): JSX.Element => {
  const pair = indices[indices.length - i]
  return !pair ? (
    <>{value}</>
  ) : (
    <>
      {highlight(value.substring(0, pair[0]), indices, i + 1)}
      <span style={{ backgroundColor: 'var(--color-primary-hover, #BFBFBF)' }}>
        {value.substring(pair[0], pair[1] + 1)}
      </span>
      {value.substring(pair[1] + 1)}
    </>
  )
}

export default FuseHighlight
