import GlobalState from 'trellis:state/globalState'
import trellisConfiguration from 'trellis:utilities/config'

import {
  Configuration,
  PracticeApiFactory,
  PracticeInfo,
  UpdateBillingRequest,
} from './practice-client'

const trellisApi: string = trellisConfiguration.trellis_apiUrl
export const GetBillingDetails = async () => {
  return await PracticeApiFactory(
    new Configuration({
      basePath: trellisApi + 'services',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).practiceGetBillingDetails(
    GlobalState.Auth.CustomerId.peek(),
    GlobalState.Auth.AuthToken.peek(),
    GlobalState.Auth.RegistrationToken.peek(),
    0,
  )
}

export const UpdateBillingDetails = async (req: UpdateBillingRequest) => {
  return await PracticeApiFactory(
    new Configuration({
      basePath: trellisApi + 'services',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).practiceUpdateBillingDetails(
    GlobalState.Auth.CustomerId.peek(),
    GlobalState.Auth.AuthToken.peek(),
    GlobalState.Auth.RegistrationToken.peek(),
    0,
    req,
  )
}

export const GetPmsDataStatus = async () => {
  return await PracticeApiFactory(
    new Configuration({
      basePath: trellisApi + 'services',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).practiceGetPmsDataStatus(
    GlobalState.Auth.AuthToken.peek(),
    GlobalState.Auth.RegistrationToken.peek(),
    GlobalState.Auth.CustomerId.peek(),
  )
}

export const GetPracticeInfo = async () => {
  return await PracticeApiFactory(
    new Configuration({
      basePath: trellisApi + 'services',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).practiceGetPracticeInfo(
    GlobalState.Auth.AuthToken.peek(),
    GlobalState.Auth.RegistrationToken.peek(),
    0,
    GlobalState.Auth.CustomerId.peek(),
  )
}

export const UpdatePmsCustomerToken = async () => {
  return await PracticeApiFactory(
    new Configuration({
      basePath: trellisApi + 'services',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).practicePutPmsCustomerToken(
    GlobalState.Auth.CustomerId.peek(),
    GlobalState.Auth.AuthToken.peek(),
    GlobalState.Auth.RegistrationToken.peek(),
    0,
  )
}

export const GetConstants = async () => {
  return await PracticeApiFactory(
    new Configuration({
      basePath: trellisApi + 'services',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).practiceGetConstants(
    GlobalState.Auth.CustomerId.peek(),
    GlobalState.Auth.AuthToken.peek(),
    GlobalState.Auth.RegistrationToken.peek(),
    0
  )
}
