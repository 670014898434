import {
  BorderHorizontalOutlined,
  RedoOutlined,
  ScissorOutlined,
  UndoOutlined,
} from '@ant-design/icons'
import { Col, Row } from 'antd'
import Slider from 'antd/lib/slider'
import * as React from 'react'

import {
  IImageOperation,
  ImageOperationType,
} from '../../attachment/attachment.interface'
import { SliderConstraints } from '../../attachment/constants'

import './editImageControls.scss'

type EditImageProps = {
  attachmentImage: File
  keyId: React.MutableRefObject<number>
  operations: IImageOperation
  croppedImageRef: React.MutableRefObject<HTMLImageElement>
  setImgForCanvas: React.Dispatch<React.SetStateAction<HTMLImageElement>>
  setOperations: (value: React.SetStateAction<IImageOperation>) => void
}

const EditImageControls: React.FC<EditImageProps> = ({
  attachmentImage,
  operations,
  croppedImageRef,
  keyId,
  setImgForCanvas,
  setOperations,
}) => {
  const {
    contrastMin,
    contrastMax,
    sharpenMin,
    sharpenMax,
    brightenMin,
    brightenMax,
  } = SliderConstraints
  const { ROTATE, MIRROR, SHARPNESS, BRIGHTNESS, CONTRAST } = ImageOperationType
  const brightnessRef = React.useRef<number>(0)
  const contrastRef = React.useRef<number>(0)
  const sharpnessRef = React.useRef<number>(0)
  const operationsStack = React.useRef<Array<IImageOperation>>([])
  const isCroppingRef = React.useRef<boolean>(false)

  const submitOperation = (imageOperationKey: ImageOperationType) => {
    keyId.current = keyId.current === 0 ? 1 : 0

    setOperations({
      imageOperationKey: imageOperationKey,
      brightness: brightnessRef.current,
      contrast: contrastRef.current,
      sharpness: sharpnessRef.current,
      mirror: imageOperationKey === ImageOperationType.MIRROR,
      rotation: imageOperationKey === ImageOperationType.ROTATE,
      start_crop: imageOperationKey === ImageOperationType.START_CROP,
      end_crop: imageOperationKey === ImageOperationType.END_CROP,
      restore: imageOperationKey === ImageOperationType.RESTORE,
    })

    if (imageOperationKey === ImageOperationType.RESTORE) {
      operationsStack.current = new Array<IImageOperation>()
    }

    // Keep track of operations performed
    operationsStack.current.push(operations)
  }

  const updateCrop = () => {
    if (!isCroppingRef.current) {
      submitOperation(ImageOperationType.START_CROP)
    } else {
      setImgForCanvas(croppedImageRef.current)
      submitOperation(ImageOperationType.END_CROP)
    }

    isCroppingRef.current = !isCroppingRef.current
  }

  const resetSliderRefValues = () => {
    brightnessRef.current = 0
    contrastRef.current = 0
    sharpnessRef.current = 0
  }

  const updateRestore = () => {
    const restoreOrigImg = new Image()
    restoreOrigImg.src = URL.createObjectURL(attachmentImage)
    restoreOrigImg.onload = () => {
      setImgForCanvas(restoreOrigImg)
    }

    isCroppingRef.current = false
    resetSliderRefValues()
    submitOperation(ImageOperationType.RESTORE)
  }

  const updateOperationList = (
    imageOperationKey: ImageOperationType,
    refObject?: React.MutableRefObject<number>,
    value?: number,
  ) => {
    if (refObject && refObject.current !== value) {
      refObject.current = value
    }

    isCroppingRef.current = false
    submitOperation(imageOperationKey)
  }

  return (
    <Row className='edit-img-control-background'>
      <Col className='edit-img-end-row-left'>
        <ScissorOutlined
          className='edit-img-button-outline'
          onClick={() => updateCrop()}
        />
        <BorderHorizontalOutlined
          className='edit-img-button-outline'
          onClick={() => updateOperationList(MIRROR)}
        />
      </Col>
      <Col className='edit-img-slider-row'>
        <h6>Brightness</h6>
        <Slider
          defaultValue={0}
          min={brightenMin}
          max={brightenMax}
          step={0.5}
          trackStyle={{ backgroundColor: '#D5D5D5' }}
          onChange={(val: number) =>
            updateOperationList(BRIGHTNESS, brightnessRef, val)
          }
          value={brightnessRef.current}
        />
      </Col>
      <Col className='edit-img-slider-row'>
        <h6>Sharpness</h6>
        <Slider
          defaultValue={0}
          min={sharpenMin}
          max={sharpenMax}
          step={0.05}
          trackStyle={{ backgroundColor: '#D5D5D5' }}
          onChange={(val: number) =>
            updateOperationList(SHARPNESS, sharpnessRef, val)
          }
          value={sharpnessRef.current}
        />
      </Col>
      <Col className='edit-img-slider-row'>
        <h6>Contrast</h6>
        <Slider
          defaultValue={0}
          min={contrastMin}
          max={contrastMax}
          step={0.5}
          trackStyle={{ backgroundColor: '#D5D5D5' }}
          onChange={(val: number) =>
            updateOperationList(CONTRAST, contrastRef, val)
          }
          value={contrastRef.current}
        />
      </Col>
      <Col className='edit-img-end-row-right'>
        <UndoOutlined
          className='edit-img-button-outline'
          onClick={() => updateRestore()}
        />
        <RedoOutlined
          className='edit-img-button-outline'
          onClick={() => updateOperationList(ROTATE)}
        />
      </Col>
    </Row>
  )
}

export default EditImageControls
