import { Buffer } from 'buffer'

import axios, { AxiosPromise } from 'axios'

import { ErrorCodes, getGenericUrlPath, LogError } from 'utils'

import { PracticeInfo } from 'trellis:api/practice/practice-client'
import { LegalBusinessInfoFields } from 'trellis:components/MyPractice/tabs/LegalBusinessInfo/LegalBusinessInfo'
import { Provider } from 'trellis:components/MyPractice/tabs/PracticeInfo/ProviderList/AddProviderModal/AddProviderModal'
import {
  isTrellisAuthExpired,
  logoutUserExpiredSession,
  refreshTrellisAuth,
} from 'trellis:features/authentication/Login/utils/login-helpers'
import { GlobalState } from 'trellis:state/globalState'

import { Errors } from '../constants/errors'
import {
  isAuthedTrellisApi,
  isIgnoreAuthFailureEndpoint,
  updateTrellisAuthHeaders,
} from './apiHelpers'
import trellisConfiguration from './config'
import { buildQueryString, showMessage } from './general'

const trellisApi: string = trellisConfiguration.trellis_apiUrl
const attachmentApi: string = trellisConfiguration.attachments_apiUrl
const netSuiteIntegrationApi: string = trellisConfiguration.netSuite_apiUrl

export interface IAuthenticatedApiModel {
  ActiveServices: string[]
  AccessToken: string
  AuthToken: string
  RegistrationToken: string
  CustomerId: any //TODO: change this to number and fix all locations referencing it
  CustomerIdNumber: number
  CustomerTypeId: number
  PmgId: number
  PmgRegionId: number
}

const store = window.localStorage

let netSuiteHeader: any = null

const apis = {
  trellis: 'trellis',
  attachment: 'attachment',
}

const post = (
  endpoint: string,
  apiType: string,
  data: {} | string,
  accessToken: string = null,
) => {
  const config: any = getConfig(apiType)
  if (typeof data === 'string')
    config.headers['Content-Type'] = 'application/json-patch+json'
  if (accessToken) config.headers['Authorization'] = `Bearer ${accessToken}`

  return axios.post(
    `${config.url}${endpoint}`,
    typeof data === 'string' ? JSON.stringify(data) : data,
    { headers: config.headers },
  )
}

const put = (endpoint: string, apiType: string, data: any = {}) => {
  const config: any = getConfig(apiType)
  return axios.put(`${config.url}${endpoint}`, data, {
    headers: config.headers,
  })
}

const changeNamePut = (endpoint: string, apiType: string, data: any = {}) => {
  const updatedFields = buildQueryString(data)
  const config: any = getConfig(apiType)
  return axios.put(`${config.url}${endpoint}${updatedFields}`, null, {
    headers: config.headers,
  })
}

const get = (
  endpoint: string,
  apiType: string,
  params: any = {},
  accessToken: string = null,
) => {
  const config: any = getConfig(apiType)
  if (accessToken) config.headers['Authorization'] = `Bearer ${accessToken}`
  return axios.get(`${config.url}${endpoint}`, {
    headers: config.headers,
    params: params,
  })
}

const restDelete = (endpoint: string, apiType: string) => {
  const config: any = getConfig(apiType)
  return axios.delete(`${config.url}${endpoint}`, { headers: config.headers })
}

export const GetImageFromS3 = async (url: string) => {
  return await axios
    .get(url, {
      responseType: 'arraybuffer',
    })
    .then((response) => Buffer.from(response.data, 'binary').toString('base64'))
    .catch((ex) => showMessage('Error finding images.'))
}

axios.interceptors.request.use(
  async function (request) {
    //check for expired jwt and refresh if needed
    if (
      isAuthedTrellisApi(request.url, request.headers) &&
      !isIgnoreAuthFailureEndpoint(request.url) &&
      isTrellisAuthExpired()
    ) {
      const newAuth = await refreshTrellisAuth(GlobalState.Auth.peek()).catch(
        (e) => Promise.reject(e),
      )
      updateTrellisAuthHeaders(request.headers, newAuth)
    }

    return Promise.resolve(request)
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    if (error?.message == ErrorCodes.auth_refresh_failed)
      return Promise.reject(error) //If this happened then they're being logged out so no need to go further

    const isNetworkError = error?.message == 'Network Error' //API gateway doesn't give us back any errors on auth failed, just throws a useless cors error
    const status = error?.response?.status //TODO: update to use getAxiosResponseCode and retest
    const resData = error?.response?.data

    const originalRequest = error ? error.config : null

    const requestPath = getGenericUrlPath(originalRequest?.url)

    if (!status && !isNetworkError) {
      //unknown error
      LogError(error, 'Unknown Api Error')
    }

    if (status === 500 || status === 504) {
      if (originalRequest?.url?.includes('pms-sync-status')) {
        /*dont show error*/
      } else if (originalRequest?.url?.includes('attachment-requirements')) {
        showMessage(Errors.invalidProcedureCodeError)
      } else {
        showMessage(Errors.somethingUnexpectedError)
        LogError(error)
      }
    } else if (status === 422) {
      /*dont show error*/
    } else if (status === 401) {
      if (!originalRequest.retry) {
        originalRequest.retry = true
        resData?.message && showMessage(resData.message)
      } else {
        showMessage(resData.message)
      }
    } else if (isIgnoreAuthFailureEndpoint(originalRequest?.url)) {
      return Promise.reject(error)
    } else if (status === 403 || isNetworkError) {
      if (originalRequest.url.includes('patientservices')) {
        logoutUserExpiredSession()
      }
      //don't retry if it's a url that's related to refresing the token or logging them out
      else if (isIgnoreAuthFailureEndpoint(originalRequest.url)) {
        return Promise.reject(error)
      } else if (
        !originalRequest.retry &&
        isAuthedTrellisApi(originalRequest?.url, originalRequest.headers)
      ) {
        //we can end up here if the user logs out on another tab or if it expires mid request
        originalRequest.retry = true
        const newAuth = await refreshTrellisAuth(GlobalState.Auth.peek())
        updateTrellisAuthHeaders(originalRequest.headers, newAuth)
        return axios(originalRequest)
      }
    }
    return Promise.reject(error)
  },
)

const getConfig = (apiType: string) => {
  let config = {}

  switch (apiType) {
    case 'trellis':
      config = {
        url: trellisApi,
        headers: {
          authtoken: GlobalState.Auth.AuthToken.peek(),
          registrationtoken: GlobalState.Auth.RegistrationToken.peek(),
        },
      }
      break
    case 'trellis-no-auth':
      config = {
        url: trellisApi,
        headers: {},
      }
      break
    case 'attach':
      config = {
        url: attachmentApi,
        headers: {
          authtoken: GlobalState?.Auth?.AuthToken.peek(),
          registrationtoken: GlobalState?.Auth?.RegistrationToken.peek(),
          ignoretokenrefresh: false,
        },
      }
      break
    case 'netSuite':
      config = {
        url: netSuiteIntegrationApi,
        headers: netSuiteHeader,
      }
      break
    default:
      config = {
        url: '',
        headers: {},
      }
      break
  }

  return config
}

const checkVyneSyncHeaders = async () => {
  const headers: any = store.getItem('vdsHeaders')

  if (!headers) {
    const resp = await q.getVyneSyncHeaders()
    setVyneSyncHeaders(resp && resp.data)
  }
}

const setVyneSyncHeaders = (headers: any) => {
  headers = {
    'Access-Token': headers.AccessToken,
    client: headers.Client,
    expiry: headers.Expiry,
    Uid: headers.Uid,
    'Token-Type': headers.TokenType,
    cache: false,
  }

  store.setItem('vdsHeaders', JSON.stringify(headers))
}

const checkNetSuiteHeaders = async () => {
  if (!netSuiteHeader) {
    const resp = await q.getPaymentHeaders()
    netSuiteHeader = {
      Authorization: `Bearer ${resp?.data?.accessToken}`,
    }
  }
}

const netSuiteTokenBody: any = {
  grantType: 'client-credentials',
  clientId: trellisConfiguration.netSuite_clientId,
  clientSecret: trellisConfiguration.netSuite_clientSecret,
  clientVersion: trellisConfiguration.netSuite_clientVersion,
}

const q = {
  // Claims
  processClaims: (payload: {}, accessToken: string) =>
    post('claimservices/claims/process', 'trellis', payload, accessToken),

  getCarriers: (): AxiosPromise<ClaimsServiceCarrierResponse> =>
    get('claimservices/claims/carriers', 'trellis'),
  getSecondaryCoverageRequirements: (carrierId: string) =>
    get(
      `services/claims/carriers/${carrierId}/secondary-coverage-requirements`,
      'trellis',
    ),
  getInstallSettings: (serialId: string) =>
    get(`claimservices/install/${serialId}`, 'trellis'),
  saveInstallSettings: (serialId: string, payload: {}) =>
    post(`claimservices/install/${serialId}`, 'trellis', payload),
  checkTinHold: (payload: any) =>
    post('claimservices/claims/tinhold', 'trellis', payload),

  // Patient Verification
  getParticipatingCarriers: () => get('patientservices/carriers', 'trellis'),

  // Patient Request
  sendPatientUpdateEmail: (
    customerId: number,
    patientId: number,
    emailAddress: string,
    payload: {},
    name: string,
    patientGuid: string,
  ) =>
    post(
      `services/eligibility/patient-request/email?customerId=${customerId}&patientId=${patientId}&emailAddress=${emailAddress}&name=${name}&patientGuid=${patientGuid}`,
      'trellis',
      payload,
    ),
  sendPatientUpdateSMS: (
    customerId: number,
    patientId: number,
    phoneNumber: string,
    payload: {},
    name: string,
    patientGuid: string,
  ) =>
    post(
      `services/eligibility/patient-request/sms?customerId=${customerId}&patientId=${patientId}&phoneNumber=${phoneNumber}&name=${name}&patientGuid=${patientGuid}`,
      'trellis',
      payload,
    ),

  // Authentication
  verifyUserRegistration: (payload: {}) =>
    post(
      'services/authentication/account/verify-user-registration',
      'trellis-no-auth',
      payload,
    ),
  createUser: (payload: {}) =>
    post(
      'services/authentication/account/create-user',
      'trellis-no-auth',
      payload,
    ),
  verifyPasswordToken: (payload: {}) =>
    post(
      'services/authentication/account/verify-password-token',
      'trellis-no-auth',
      payload,
    ),
  resetPassword: (payload: {}) =>
    put('services/authentication/account/password', 'trellis-no-auth', payload),
  forgotPassword: (payload: { AppCompanyName: number; UserName: string }) =>
    post(
      'services/authentication/account/forgot-password',
      'trellis-no-auth',
      payload,
    ),

  // SSO user management
  getSsoEnabledAppWide: () =>
    get('services/authentication/SsoEnabledAppWide', 'trellis'),
  getVyneSsoToken: (payload: {
    customerId: number
    userId: number
    destinationProduct: string
    emailAddress: string
  }) => post('services/authentication/SsoToken', 'trellis', payload),
  getOperaApiBaseUrl: () =>
    get('services/authentication/OperaApiBaseUrl', 'trellis'),
  getOperaSsoUrl: (payload: {
    customerId: number
    userId: number
    destinationProduct: string
    emailAddress: string
  }) => post('services/authentication/OperaSsoUrl', 'trellis', payload),

  // Account
  changePassword: (payload: any) =>
    put('services/account/passwords', 'trellis', payload),
  contactSupport: (payload: any, accessToken: string) =>
    post('services/account/ContactSupport', 'trellis', payload, accessToken),
  getPasswordExpiration: () =>
    get('services/account/passwords/expiration-setting', 'trellis'),
  updatePasswordExpiration: (payload: any) =>
    put('services/account/passwords/expiration-setting', 'trellis', payload),
  updateUserFirstAndLastName: (
    customerUserID: number,
    payload: { firstName: string; lastName: string },
  ) =>
    changeNamePut(
      `services/account/user/${customerUserID}/firstname-lastname`,
      'trellis',
      payload,
    ), //put my need to be make more generic
  confirmPassword: (payload: any) =>
    post('account/confirmpassword', 'trellis', payload),
  inviteNewUser: (payload: {}) =>
    post('services/account/add-user', 'trellis', payload),

  // Resources
  getResources: () => get('services/Practice/resources', 'trellis'),

  //Messages
  getMessages: (payload: any) =>
    post('services/practice/messages', 'trellis', payload),
  updateMessage: (messageId: any) =>
    put(`services/practice/messages/${messageId}`, 'trellis'),
  updateMessages: (payload: {}) =>
    put('services/practice/messages/ignore', 'trellis', payload),
  getMessageAttachment: (messageID: number, attachmentID: number) =>
    get(
      `services/practice/messages/${messageID}/attachment/${attachmentID}`,
      'trellis',
    ),
  getVyneSyncHeaders: () => get('patientservices/masontoken', 'trellis'),

  // Payment
  getPaymentHeaders: () =>
    post(
      'authenticate/token?vendorTransactionId=TrellisAuthentication',
      'netSuite',
      netSuiteTokenBody,
    ),

  tokenizeCard: async (payload: any) => {
    await checkNetSuiteHeaders()
    return post(
      `facility/${payload.facilityId}/tokenize-card?vendorTransactionId=TrellisTokenizeCard`,
      'netSuite',
      payload,
    )
  },

  // Practice
  addProvider: (payload: Provider) =>
    put('services/Practice/provider', 'trellis', payload),
  deleteProvider: (providerId: number) =>
    restDelete(`services/Practice/provider/${providerId}`, 'trellis'),
  getDownloads: () => get('services/Practice/downloads', 'trellis'),
  getLegalBusinessStatus: (): AxiosPromise<{
    data: LegalBusinessStatusResponse
    statusCode: number
    message: string
  }> => get('services/Practice/tcr-info', 'trellis'),
  getPracticeDetails: (): AxiosPromise<PracticeInfoResponse> =>
    get('services/Practice', 'trellis'),
  getProviders: () => get('services/Practice/providers', 'trellis'),
  saveLegalBusinessDetails: (payload: LegalBusinessInfoFields) =>
    post('services/Practice/tcr-info', 'trellis', payload),
  savePracticeDetails: (payload: {}) =>
    post('services/Practice', 'trellis', payload),
  sendFeedback: (payload: {}) =>
    post('services/Practice/feedback', 'trellis', payload),
  getPmsSyncStatus: () => get('services/Practice/pms-sync-status', 'trellis'),

  // Controller Actions - need lambdas
  login: (payload: { Password: string; Username: string }) =>
    post('/Account/Login', '', payload),
  cancelRecurringPayment: (recurringID: string) =>
    get(`/Payment/CancelRecurringPayment?recurringID=${recurringID}`, ''),

  //Not moving
  updateInstallFilterName: (payload: any) =>
    post('/Rlo/SetSerialOptions', '', payload),
  updateFilterClaimsByInstall: (payload: any) =>
    post('/Rlo/UpdateCustomerIsolate', '', payload),
  resendAccountActivationLink: (payload: any) =>
    post(`/Account/ResendRegistration`, '', payload), //sso is gunna sso this until it's sso'd to the point of being sso
}

export interface LegalBusinessStatusResponse extends LegalBusinessInfoFields {
  campaignRegistrationStarted?: boolean
  validationFailed?: boolean
  verificationStatus?: number
  verificationStatusDescription?: string
  bannerSuppressed?: boolean
  maxBrandRegistrationUpdateAttemptsReached?: boolean
}

export interface PracticeInfoResponse {
  trellisPracticeInfo: PracticeInfo
  statusCode: number
  message: string
}

export interface ClaimsServiceCarrierResponse {
  Carriers: ClaimCarrierData[]
}

export type ClaimCarrierData = {
  Carrier: string
  CarrierAddress: string
  CarrierCity: string
  CarrierId: string
  CarrierState: string
  CarrierZip: string
}

export default q
