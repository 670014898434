import { LDClient, LDContext } from 'launchdarkly-react-client-sdk'

import { LogError } from 'utils'

const getLdContext = (
  hasPmsData: boolean,
  activeServices: string,
  globalCustomerID: number,
): LDContext => {
  activeServices = activeServices ?? ''
  const userKey =
    globalCustomerID && globalCustomerID != 0
      ? globalCustomerID.toString()
      : 'preauthuser'

  const newContext: LDContext = {
    ActiveServices: activeServices,
    HasPmsData: hasPmsData,
    key: userKey.toString(),
    kind: 'user',
  }
  return newContext
}

export const updateLdContext = async (
  client: LDClient,
  hasPmsData: boolean,
  activeServices: string,
  globalCustomerID: number,
) => {
  const newContext: LDContext = getLdContext(
    hasPmsData,
    activeServices,
    globalCustomerID,
  )
  await client.identify(newContext, null)
}

//for when the user logs out
export const getEmptyLdContext = (): LDContext => {
  const newContext: LDContext = {
    ActiveServices: '',
    HasPmsData: false,
    key: 'preauthuser',
    kind: 'user',
  }
  return newContext
}

export const clearLdContext = async (client: LDClient) => {
  try {
    const newContext: LDContext = getEmptyLdContext()
    await client.identify(newContext, null)
  } catch (error) {
    LogError(error)
  }
}
