import { MenuOutlined } from '@ant-design/icons'
import { Button, Col, Row, Switch } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'

import { arrayMove, spacesToProperty } from '../../../../utilities/general'
import { useClaimGridContext } from '../../context/claimGridContext'
import { columns } from './columnSelectionList'

import './columnSelection.scss'

import { ObservablePrimitive } from '@legendapp/state'

const store = window.localStorage

type ColumnSelectionProps = {
  showClaimColumnSelector$: ObservablePrimitive<boolean>
}

export const ColumnSelection: FC<ColumnSelectionProps> = ({
  showClaimColumnSelector$,
}) => {
  const { state, setState, defaultColumns, storageColumns } =
    useClaimGridContext()
  const [items, setItems] = useState<any>([])

  const resetIds = (options: any) => {
    let counter = 0
    options.map((o: any) => {
      o.id = counter++
    })

    return options
  }

  useEffect(() => {
    let orderedColumns: any = []

    if (storageColumns) {
      storageColumns.map((d: any) => {
        const match = columns.filter((c: any) => c.property === d)[0]
        match.show = true
        orderedColumns.push(match)
      })
      orderedColumns = orderedColumns.concat(
        columns.filter((c: any) => !orderedColumns.includes(c)),
      )
    } else {
      orderedColumns = columns
    }
    if (state.Install.Isolate)
      orderedColumns = orderedColumns.filter(
        (c: any) => c.property !== 'Install',
      )

    setItems(resetIds(orderedColumns))
  }, [state.Install.Isolate])

  if (!items.length) return null

  const onChange = (property: number, show: boolean) => {
    const copy = [...items]
    const match = copy.filter((c) => c.property === property)[0]
    match.show = !show
    setItems(copy)
  }

  const setDefaultColumns = (columns: any) => {
    const copy = { ...state }
    copy.VisibleColumns = columns
    setState(copy)
    showClaimColumnSelector$.set(false)
    store.setItem('claim-default-columns', JSON.stringify(columns))
  }

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    let newOrder = arrayMove(items, oldIndex, newIndex)
    newOrder = resetIds(newOrder)
    setItems(newOrder)
  }

  const resetItems = () => {
    const values: any = []
    items.map((c: any) => {
      c.show = defaultColumns.includes(c.property)
      values.push(c)
    })

    resetIds(columns)
    setItems(columns)
    setDefaultColumns(defaultColumns)
    store.removeItem('claim-default-columns')
  }

  const apply = () => {
    const values: any = []
    items.map((c: any) => c.show && values.push(c.property))
    setDefaultColumns(values)
  }

  const DragHandle = SortableHandle(() => (
    <MenuOutlined className='column-menu-grab' />
  ))

  const SortableItem = SortableElement(({ property, show }: any) => {
    let display = property
    if (property === 'TransmitDate')
      display = `${state.ActiveTab === 'Unsent' ? 'Received' : 'Sent'} Date`
    return (
      <Row className='column-select-row'>
        <Col>
          <DragHandle />
        </Col>
        <Col
          flex={1}
          className='pl-100'
        >
          {spacesToProperty(display)}
        </Col>
        <Col className='pl-100'>
          <Switch
            checkedChildren='Yes'
            unCheckedChildren='No'
            checked={show}
            onChange={() => onChange(property, show)}
          />
        </Col>
      </Row>
    )
  })

  const SortableListContainer = SortableContainer(() => (
    <div>
      {items.map(({ id, property, show }: any) => (
        <SortableItem
          key={id}
          index={id}
          // @ts-expect-error TODO: fix this
          property={property}
          show={show}
        />
      ))}
      <Row className='claim-setting-actions'>
        <Col flex={1}>
          <Button onClick={resetItems}>Reset</Button>
        </Col>
        <Col>
          <Button
            onClick={apply}
            type='primary'
          >
            Apply
          </Button>
        </Col>
      </Row>
    </div>
  ))

  return (
    <SortableListContainer
      onSortEnd={onSortEnd}
      useDragHandle={true}
      lockAxis='y'
    />
  )
}
