/* tslint:disable */
/* eslint-disable */
/**
 * Patients
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface EligibilityHistory
 */
export interface EligibilityHistory {
    /**
     * 
     * @type {number}
     * @memberof EligibilityHistory
     */
    'HistoryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EligibilityHistory
     */
    'CarrierName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityHistory
     */
    'GroupNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityHistory
     */
    'RequestDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EligibilityHistory
     */
    'Status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityHistory
     */
    'Response'?: string | null;
}
/**
 * 
 * @export
 * @interface EligibilityPatient
 */
export interface EligibilityPatient {
    /**
     * 
     * @type {number}
     * @memberof EligibilityPatient
     */
    'CustomerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'PatientFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'PatientMiddleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'PatientLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'PatientSuffix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'PatientBirthdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'PatientGender'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EligibilityPatient
     */
    'PatientIsSub'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'SubscriberId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'SubscriberFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'SubscriberMiddleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'SubscriberLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'SubscriberSuffix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'SubscriberBirthdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'SubscriberGender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'ProviderLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'ProviderFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'IndividualNpi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'CarrierName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'GroupNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'CarrierId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'SyncId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'TaxonomyCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EligibilityPatient
     */
    'FastAttachPatientId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EligibilityPatient
     */
    'FastAttachInsuranceId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EligibilityPatient
     */
    'FastAttachProviderId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'RelationshipToSubscriber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EligibilityPatient
     */
    'CarrierMasterId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatient
     */
    'PatientId'?: string | null;
}
/**
 * 
 * @export
 * @interface EligibilityPatients
 */
export interface EligibilityPatients {
    /**
     * 
     * @type {number}
     * @memberof EligibilityPatients
     */
    'TotalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof EligibilityPatients
     */
    'HistoryId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'PatientId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'CarrierName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'RequestError'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'HtmlResult'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'ResponseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'ResponseError'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'AddDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'PatientFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'PatientLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'Status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'AppointmentDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'PatientBirthdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'SyncId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityPatients
     */
    'PatientFormResponse'?: string | null;
}
/**
 * 
 * @export
 * @interface EligibilityPatientsRequest
 */
export interface EligibilityPatientsRequest {
    /**
     * 
     * @type {number}
     * @memberof EligibilityPatientsRequest
     */
    'CurrentPage'?: number;
    /**
     * 
     * @type {number}
     * @memberof EligibilityPatientsRequest
     */
    'PageSize'?: number;
    /**
     * 
     * @type {SortColumn}
     * @memberof EligibilityPatientsRequest
     */
    'SortColumn'?: SortColumn;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EligibilityPatientsRequest
     */
    'Filters'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface GetPatientEligibilityInfoResponse
 */
export interface GetPatientEligibilityInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'PatientId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'SyncId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'CustomerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'PatientFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'PatientMiddleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'PatientLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'PatientSuffix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'PatientBirthdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'PatientGender'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'PatientIsSub'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'SubscriberId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'SubscriberFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'SubscriberMiddleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'SubscriberLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'SubscriberSuffix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'SubscriberBirthdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'SubscriberGender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'CarrierName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'GroupNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'CarrierId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'ProviderLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'ProviderFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'IndividualNpi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'Status'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'FastAttachPatientId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'FastAttachInsuranceId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'FastAttachProviderId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'RelationshipToSubscriber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'CarrierMasterId'?: number | null;
    /**
     * 
     * @type {Array<EligibilityHistory>}
     * @memberof GetPatientEligibilityInfoResponse
     */
    'VerificationHistory'?: Array<EligibilityHistory> | null;
}
/**
 * 
 * @export
 * @interface GetPatientsResponse
 */
export interface GetPatientsResponse {
    /**
     * 
     * @type {number}
     * @memberof GetPatientsResponse
     */
    'TotalCount'?: number;
    /**
     * 
     * @type {Array<EligibilityPatients>}
     * @memberof GetPatientsResponse
     */
    'Data'?: Array<EligibilityPatients> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPatientsResponse
     */
    'Statuses'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPatientsResponse
     */
    'Carriers'?: Array<string> | null;
    /**
     * 
     * @type {SummaryData}
     * @memberof GetPatientsResponse
     */
    'Summary'?: SummaryData;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface SortColumn
 */
export interface SortColumn {
    /**
     * 
     * @type {string}
     * @memberof SortColumn
     */
    'Column'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SortColumn
     */
    'Sort'?: string | null;
}
/**
 * 
 * @export
 * @interface SummaryData
 */
export interface SummaryData {
    /**
     * 
     * @type {number}
     * @memberof SummaryData
     */
    'Appointments'?: number;
    /**
     * 
     * @type {number}
     * @memberof SummaryData
     */
    'Eligible'?: number;
    /**
     * 
     * @type {number}
     * @memberof SummaryData
     */
    'NeedsAttention'?: number;
    /**
     * 
     * @type {number}
     * @memberof SummaryData
     */
    'PendingPatientResponse'?: number;
}

/**
 * PatientsApi - axios parameter creator
 * @export
 */
export const PatientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsGet: async (customerId: number, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patientsGet', 'customerId', customerId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('patientsGet', 'patientId', patientId)
            const localVarPath = `/{customerId}/patient/{patientId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {EligibilityPatient} eligibilityPatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsPost: async (customerId: number, eligibilityPatient: EligibilityPatient, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patientsPost', 'customerId', customerId)
            // verify required parameter 'eligibilityPatient' is not null or undefined
            assertParamExists('patientsPost', 'eligibilityPatient', eligibilityPatient)
            const localVarPath = `/{customerId}/patient`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eligibilityPatient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} patientId 
         * @param {EligibilityPatient} eligibilityPatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsPut: async (customerId: number, patientId: string, eligibilityPatient: EligibilityPatient, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patientsPut', 'customerId', customerId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('patientsPut', 'patientId', patientId)
            // verify required parameter 'eligibilityPatient' is not null or undefined
            assertParamExists('patientsPut', 'eligibilityPatient', eligibilityPatient)
            const localVarPath = `/{customerId}/patient/{patientId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eligibilityPatient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {EligibilityPatientsRequest} eligibilityPatientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsSearchPatients: async (customerId: number, eligibilityPatientsRequest: EligibilityPatientsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patientsSearchPatients', 'customerId', customerId)
            // verify required parameter 'eligibilityPatientsRequest' is not null or undefined
            assertParamExists('patientsSearchPatients', 'eligibilityPatientsRequest', eligibilityPatientsRequest)
            const localVarPath = `/{customerId}/patients/search`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eligibilityPatientsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientsApi - functional programming interface
 * @export
 */
export const PatientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsGet(customerId: number, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPatientEligibilityInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsGet(customerId, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {EligibilityPatient} eligibilityPatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsPost(customerId: number, eligibilityPatient: EligibilityPatient, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPatientEligibilityInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsPost(customerId, eligibilityPatient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} patientId 
         * @param {EligibilityPatient} eligibilityPatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsPut(customerId: number, patientId: string, eligibilityPatient: EligibilityPatient, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPatientEligibilityInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsPut(customerId, patientId, eligibilityPatient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {EligibilityPatientsRequest} eligibilityPatientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsSearchPatients(customerId: number, eligibilityPatientsRequest: EligibilityPatientsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPatientsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsSearchPatients(customerId, eligibilityPatientsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientsApi - factory interface
 * @export
 */
export const PatientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsGet(customerId: number, patientId: string, options?: any): AxiosPromise<GetPatientEligibilityInfoResponse> {
            return localVarFp.patientsGet(customerId, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {EligibilityPatient} eligibilityPatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsPost(customerId: number, eligibilityPatient: EligibilityPatient, options?: any): AxiosPromise<GetPatientEligibilityInfoResponse> {
            return localVarFp.patientsPost(customerId, eligibilityPatient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} patientId 
         * @param {EligibilityPatient} eligibilityPatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsPut(customerId: number, patientId: string, eligibilityPatient: EligibilityPatient, options?: any): AxiosPromise<GetPatientEligibilityInfoResponse> {
            return localVarFp.patientsPut(customerId, patientId, eligibilityPatient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {EligibilityPatientsRequest} eligibilityPatientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsSearchPatients(customerId: number, eligibilityPatientsRequest: EligibilityPatientsRequest, options?: any): AxiosPromise<GetPatientsResponse> {
            return localVarFp.patientsSearchPatients(customerId, eligibilityPatientsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientsApi - object-oriented interface
 * @export
 * @class PatientsApi
 * @extends {BaseAPI}
 */
export class PatientsApi extends BaseAPI {
    /**
     * 
     * @param {number} customerId 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsGet(customerId: number, patientId: string, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsGet(customerId, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {EligibilityPatient} eligibilityPatient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsPost(customerId: number, eligibilityPatient: EligibilityPatient, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsPost(customerId, eligibilityPatient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {string} patientId 
     * @param {EligibilityPatient} eligibilityPatient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsPut(customerId: number, patientId: string, eligibilityPatient: EligibilityPatient, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsPut(customerId, patientId, eligibilityPatient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {EligibilityPatientsRequest} eligibilityPatientsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsSearchPatients(customerId: number, eligibilityPatientsRequest: EligibilityPatientsRequest, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsSearchPatients(customerId, eligibilityPatientsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


