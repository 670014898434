import { Col, Row } from 'antd'
import { FC } from 'react'

import { FormInput, FormSelect } from 'ui'
import {
  SelectOption,
  stateSelectOptions,
  validateTIN,
  validateURL,
  validateZip,
} from 'utils'

const businessOptions: SelectOption[] = [
  { text: 'Sole Proprietorship', value: 1 },
  { text: 'Partnership', value: 2 },
  { text: 'LLC', value: 3 },
  { text: 'Co-operative', value: 4 },
  { text: 'Non-profit Corporation', value: 5 },
  { text: 'Corporation', value: 6 },
]

const LegalBusinessInformation: FC = () => {
  return (
    <section className='page-section'>
      <h2 className='page-section__title'>Legal Business Information</h2>
      <Row>
        <Col span={24}>
          <FormInput
            label='Business Name on Tax Records'
            name='name'
            required
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormInput
            label='Tax ID (9-digit EIN)'
            name='employerIdentificationNumber'
            required
            validator={validateTIN}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormInput
            label='Address'
            name='address1'
            required
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormInput
            label='Address Line 2'
            name='address2'
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <FormInput
            label='City'
            name='city'
            required
          />
        </Col>
        <Col span={6}>
          <FormSelect
            label='State'
            name='state'
            options={stateSelectOptions}
            required
          />
        </Col>
        <Col span={6}>
          <FormInput
            label='Zip Code'
            name='zip'
            required
            validator={validateZip}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormSelect
            label='Business Type'
            name='type'
            options={businessOptions}
            required
          />
        </Col>
        <Col span={24}>
          <FormInput
            label='Website Address'
            name='websiteURL'
            placeholder='https://www.vynedental.com'
            validator={validateURL}
          />
        </Col>
      </Row>
    </section>
  )
}

export default LegalBusinessInformation
