import { AutoComplete, Form } from 'antd'
import React from 'react'

import { spacesToProperty } from '../../../utilities/general'
import { validateProperty } from '../../../utilities/validators/baseValidator'
import { FormFloatLabel } from './formFloatLabel'
import { getError, handleFormChange } from './util'

export const FormAutocomplete = ({
  name,
  element,
  setElement,
  initialValue,
  message,
  validator,
  customHandler,
  valueHandler,
  label = null,
  parentProperty = null,
  section = null,
  options = [],
  required = false,
  width = '100%',
  ...others
}: any) => {
  const error = element && getError(name, element)

  const onChange = (value: string) => {
    const newValue = valueHandler ? valueHandler(value) : value
    const updatedElement = handleFormChange(
      name,
      parentProperty,
      newValue,
      element,
    )
    customHandler && customHandler(newValue, updatedElement)
    validateProperty(
      validator,
      updatedElement,
      name,
      parentProperty,
      required,
      section,
    )
    setElement(updatedElement)
  }

  return (
    <div className='form-item-validation-container'>
      <Form.Item
        key={name}
        name={name}
        validateStatus={error ? 'error' : 'validating'}
        help={error ? error.message : ''}
        rules={[{ required: required, message: message }]}
      >
        <FormFloatLabel
          label={label || spacesToProperty(name)}
          name={name}
          inputValue={initialValue}
          select
        >
          <AutoComplete
            id={`${name}_dropdown`}
            value={initialValue}
            options={options}
            onChange={onChange}
            style={{ width: width }}
            {...others}
          />
        </FormFloatLabel>
      </Form.Item>
    </div>
  )
}
