import { EyeOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Modal, Radio, Row } from 'antd'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { FormInput } from '../../../_siteWide/form/formInput'
import { FormMaskInput } from '../../../_siteWide/form/formMaskInput'
import { validateRequiredFields } from '../../../_siteWide/form/util'
import {
  CreatePatient,
  UpdatePatient,
} from '../../../../api/eligibility/eligibilityApi'
import InsuranceUpdateForm from '../../../../assets/InsuranceUpdateForm.pdf'
import api from '../../../../utilities/api'
import { showMessage } from '../../../../utilities/general'
import { validateEmail } from '../../../../utilities/validators/emailValidator'
import { validatePhone } from '../../../../utilities/validators/phoneValidator'
import { useEligibilityContext } from '../../shared/context/EligibilityContext'
import { usePatientEligibilityDetailContext } from '../../shared/context/PatientEligibilityDetailContext'
import { PatientUpdateMessage } from '../../shared/utilities/eligibilityTyping'

import '../../shared/styles/_patient-update-modals.scss'

import { LogError } from 'utils'

import GlobalState, { LDFlags$ } from 'trellis:state/globalState'
import { RoleHelper$ } from 'trellis:utilities/roleHelper'
import { observer } from '@legendapp/state/react'

interface PatientUpdateFormProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

type MessageProps = {
  type: string
  Message: string
  Text: string
  Email: string
  isValid?: boolean
  errors?: []
}

// TODO: update form to new form handling
const PatientUpdateForm: FC<PatientUpdateFormProps> = observer(({
  isOpen,
  setIsOpen,
}) => {
  const flags = LDFlags$.get()

  const legalBusinessStatus = GlobalState.LegalBusinessStatus.get()
  const practiceDetails = GlobalState.PracticeInfo.get()

  const { isActionCalledFromTable$, setRequiresTableRefresh } =
    useEligibilityContext()
  const { patient, setPatient, updatePatientDisplay } =
    usePatientEligibilityDetailContext()

  const [message, setMessage] = useState<MessageProps>({
    type: 'text',
    Message: '',
    Text: '',
    Email: '',
  })
  const [sendingForm, setSendingForm] = useState<boolean>(false)
  const [showTcrFailedModal, setShowTcrFailedModal] = useState<boolean>(false)
  const [tcrStatus, setTcrStatus] = useState<boolean>(false)

  const isTrellis = RoleHelper$.isTrellis.get()

  useEffect(() => {
    if (legalBusinessStatus) {
      const tcrValidStatus: boolean =
        legalBusinessStatus.verificationStatus == 2 //While it is true that for the banner we don't want to nag them if it is pending, pending is not valid for sending.
      setTcrStatus(!tcrValidStatus)
    }
  }, [legalBusinessStatus])

  useEffect(() => {
    //Only reset the form if it isn't open
    if (
      patient?.PatientFirstName &&
      patient?.PatientLastName &&
      practiceDetails
    ) {
      const messageText = `Hi ${patient.PatientFirstName} ${patient.PatientLastName},\nThis is ${practiceDetails.officeName}, please follow the link to update your insurance information. \nThanks!\n`
      setMessage({
        ...message,
        Message: messageText,
        Email: '',
        Text: '',
      })
    }
  }, [patient, practiceDetails])

  const savePatient = async () => {
    // patient comes from sync so we can't update them
    if (flags.operaVynesyncAutomatedeligibility) return patient
    else {
      // They're not a sync customer, we need to save/update them
      if (!patient.PatientId || patient.PatientId === '0') {
        // New patient
        const response = await CreatePatient(patient)
        const patientCopy = { ...patient }
        patientCopy.PatientId = response.data.PatientId
        patientCopy.Status = response.data.Status

        updatePatientDisplay(
          patientCopy.PatientLastName,
          patientCopy.PatientFirstName,
          patientCopy.Status,
        )
        setPatient(patientCopy)

        return patientCopy
      } else {
        await UpdatePatient(
          patient.PatientId,
          patient,
        )

        return patient
      }
    }
  }

  const handleSendSMS = async (
    payload: any,
    phoneNumber: string,
    name: string,
  ) => {
    setSendingForm(true)

    const newPhoneNumber = phoneNumber.replace('(', '').replace(')', '-')

    try {
      const newPatient = await savePatient()
      await api.sendPatientUpdateSMS(
        GlobalState.Auth.CustomerId.peek(),
        newPatient?.PatientId,
        newPhoneNumber,
        payload,
        name,
        newPatient.SyncId ?? '00000000-0000-0000-0000-000000000000',
      )
      sendUpdateFormSuccess(newPatient)
    } catch (error) {
      if (tcrStatus) setShowTcrFailedModal(true)
      LogError(error)
    } finally {
      setSendingForm(false)
    }
  }

  const handleSendEmail = async (
    payload: any,
    emailAddress: string,
    name: string,
  ) => {
    setSendingForm(true)

    try {
      const newPatient = await savePatient()
      await api.sendPatientUpdateEmail(
        GlobalState.Auth.CustomerId.peek(),
        newPatient?.PatientId,
        emailAddress,
        payload,
        name,
        newPatient.SyncId ?? '00000000-0000-0000-0000-000000000000',
      )
      sendUpdateFormSuccess(newPatient)
    } catch (error) {
      showMessage('Something went wrong. Please try again.')
      LogError(error)
    } finally {
      setSendingForm(false)
    }
  }

  const formCleanup = () => {
    if (isActionCalledFromTable$.peek()) setRequiresTableRefresh(true)
    setSendingForm(false)
    setIsOpen(false)
    //reset the inputs on cleanup
    setMessage({
      ...message,
      type: 'text',
      Email: '',
      Text: '',
    })
  }

  const sendUpdateFormSuccess = (sentPatientDetails: any) => {
    const patientCopy = { ...sentPatientDetails }
    patientCopy.Status = '7' // pending response
    updatePatientDisplay(
      patientCopy.PatientLastName,
      patientCopy.PatientFirstName,
      patientCopy.Status,
    )
    setPatient(patientCopy)

    showMessage('Patient Update form was successfully sent.', 'success')
    formCleanup()
  }

  const onSubmitPatientUpdateModal = (message: PatientUpdateMessage) => {
    const type = message?.type
    const requestBody =
      type === 'text'
        ? message
        : {
            ...message,
            Subject: `Insurance Update Form from ${practiceDetails?.officeName}`,
          }
    const name = `${patient.PatientFirstName} ${patient.PatientLastName}`

    if (type === 'text') handleSendSMS(requestBody, message?.Text, name)
    else if (type === 'email')
      handleSendEmail(requestBody, message?.Email, name)
  }

  const selectMessageType = (e: any) => {
    setMessage({ ...message, type: e.target.value })
  }

  const validateForm = (formData: any) => {
    formData.isValid = true
    formData.errors = []

    validateRequiredFields(formData, [
      formData.type === 'text' ? 'Email' : 'Text',
    ])

    let fieldInfo = null
    formData.type === 'text'
      ? (fieldInfo = validatePhone(formData.Text))
      : (fieldInfo = validateEmail(formData.Email))

    if (formData.errors.length > 0 || typeof fieldInfo === 'string') {
      formData.isValid = false
      setSendingForm(false)
      showMessage('Please provide a valid phone number or email.')
      return
    }

    if (message.isValid) onSubmitPatientUpdateModal(message)
  }

  const handlePreview = () => {
    const openWindow = window.open()
    openWindow.document.body.innerHTML = `<iframe src="${InsuranceUpdateForm}#view=Fit" style="height:100%;position:fixed;top:0;bottom:0;left:0;right:0;width:100%;border:none;overflow:hidden"></iframe>`
  }

  const redirectToMyPractice = () => {
    window.location.href = '/Practice/PracticeInfo'
  }

  return (
    <>
      <Modal
        destroyOnClose
        footer={null}
        onCancel={() => setShowTcrFailedModal(false)}
        title='SMS Unavailable'
        open={showTcrFailedModal}
      >
        <div className='tcr-modal'>
          <div className='fs-0875 mb-200'>
            Due to SMS regulations, you must successfully verify your office as
            a legal business entity by supplying your information in the My
            Practice Section.
          </div>
          <Button
            type='primary'
            onClick={redirectToMyPractice}
          >
            Take me there!
          </Button>
        </div>
      </Modal>
      <Modal
        destroyOnClose
        footer={[
          <Button
            key='preview'
            type='text'
            className='preview-button'
            onClick={handlePreview}
          >
            <EyeOutlined className='fs-150 preview-icon' />
            <span className='preview-text'>Preview Form</span>
          </Button>,
          <Button
            key='cancel'
            type='default'
            onClick={() => setIsOpen(false)}
            className={
              isTrellis ? 'trellis-theme--button' : 'default-theme--button'
            }
          >
            Close
          </Button>,
          <Button
            key='Submit'
            type='primary'
            loading={sendingForm}
            onClick={() => validateForm(message)}
            className={
              isTrellis ? 'trellis-theme--button' : 'default-theme--button'
            }
          >
            Send
          </Button>,
        ]}
        onCancel={() => setIsOpen(false)}
        title='Patient Update Form'
        open={isOpen}
        width={600}
      >
        <Form className='patient-update-forms'>
          <Row>
            <Col style={{ width: '60%' }}>
              <div style={{ marginBottom: '0.5rem' }}>
                <span style={{ fontSize: '0.75rem', fontWeight: 700 }}>
                  Contact Info
                </span>
              </div>
              <FormInput
                name='patientName'
                label='Patient Name'
                initialValue={`${patient.PatientFirstName} ${patient.PatientLastName}`}
                required
                disabled
                className={
                  isTrellis ? 'trellis-theme--input' : 'default-theme--input'
                }
              />
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '60%' }}>
              <div style={{ marginBottom: '-0.5rem' }}>
                <span style={{ fontSize: '0.75rem', fontWeight: 700 }}>
                  Select message type:
                </span>
              </div>
              <Radio.Group
                onChange={selectMessageType}
                value={message.type}
                style={{ marginBottom: '0.5rem' }}
              >
                <Radio value={'text'}>Text</Radio>
                <Radio value={'email'}>Email</Radio>
              </Radio.Group>
              {message.type === 'email' ? (
                <FormInput
                  name='Email'
                  label='Enter patient email'
                  element={message}
                  setElement={setMessage}
                  initialValue={message.Email}
                  validator={validateEmail}
                  required
                  className={
                    isTrellis ? 'trellis-theme--input' : 'default-theme--input'
                  } // NOTE: this className may not be needed here
                />
              ) : (
                <FormMaskInput
                  element={message}
                  format='(###)###-####'
                  initialValue={message.Text}
                  label='Enter patient phone number'
                  mask='_'
                  name='Text'
                  required
                  setElement={setMessage}
                  validator={validatePhone}
                />
              )}
            </Col>
          </Row>
          <Row className='message-preview'>
            <Col className='full-width'>
              <div style={{ marginBottom: '0.5rem' }}>
                <span style={{ fontSize: '0.75rem', fontWeight: 700 }}>
                  Message Preview
                </span>
              </div>
              <Input.TextArea
                rows={3}
                maxLength={3}
                value={message.Message}
                disabled
                className={
                  isTrellis ? 'trellis-theme--input' : 'default-theme--input'
                }
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
})

export default PatientUpdateForm
