import { Dispatch, SetStateAction } from 'react'

import { spacesToProperty } from '../../../../utilities/general'
import {
  ColumnFilterType,
  ColumnHeaderType,
  DirectionType,
  ObjAnyPropsType,
  sortHeaderDateRangeType,
  StateFiltersType,
} from '../SmartTableTypes'
import columnFilterControls from './columnFilterControls/ColumnFilterControls'
import SortingControls from './ColumnSortingControls'

import './SortHeader.scss'

const sortHeader = (
  stateFilters: StateFiltersType,
  searchInput: React.MutableRefObject<HTMLElement>,
  search: ObjAnyPropsType,
  setSearch: Dispatch<React.SetStateAction<ObjAnyPropsType>>,
  openDate: boolean,
  setOpenDate: Dispatch<React.SetStateAction<boolean>>,
  dateRange: sortHeaderDateRangeType,
  setDateRange: Dispatch<SetStateAction<sortHeaderDateRangeType>>,
  filterTable: (property: string, value: string) => void,
  removeTag: (property: string) => void,
  updateSorting: (column: string, direction: DirectionType) => void,
  property: string,
  title: string = null,
  noSort: boolean = false,
  type: string = 'text',
  options: [] = [],
  filter: ColumnFilterType,
  noFilter: boolean,
  rangeType: string,
  filterLoading: boolean,
): ColumnHeaderType => {
  const propertyDisplay: string = title || spacesToProperty(property)
  let columnHeader: ColumnHeaderType = { title: <></> }

  if (property === 'Birthdate') property = 'Date of Birth'
  else if (property === 'Predetermination') property = 'Pre-D'

  if (noSort) {
    columnHeader.title = (
      <div className='column-no-filter-container'>
        <div className='column-no-filter-title color--column--title'>
          {propertyDisplay}
        </div>
      </div>
    )
  } else {
    const direction: string = stateFilters.SortDirection
    let nextSort: DirectionType = 'asc'
    let activeSort: DirectionType | '' = ''

    if (stateFilters.SortColumn === property) {
      const sortDirection: DirectionType = direction === 'asc' ? 'desc' : 'asc'
      nextSort = sortDirection
      activeSort = sortDirection
    }

    const columnFilter = columnFilterControls(
      searchInput,
      search,
      setSearch,
      property,
      type,
      filterTable,
      removeTag,
      options,
      filter,
      noFilter,
      openDate,
      setOpenDate,
      dateRange,
      setDateRange,
      rangeType,
      filterLoading,
      propertyDisplay,
    )

    const columnHeaderTitle = (
      <div
        className='column-no-filter-container'
        onClick={() => updateSorting(property, nextSort)}
      >
        {!columnFilter && (
          <div className='column-no-filter-title color--column--title'>
            {propertyDisplay}
          </div>
        )}
        <SortingControls direction={activeSort} />
      </div>
    )

    columnHeader = { title: columnHeaderTitle, ...columnFilter }
  }

  return columnHeader
}

export default sortHeader
