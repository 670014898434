import { FormRadioInput } from 'ui'
import { validatePriorAuthorization } from 'utils'

import { FormInput } from 'trellis:components/_siteWide/form/formInput'
import { useClaimDetailContext } from 'trellis:components/claims/context/claimDetailContext'

export const ClaimServiceInformation = () => {
  const { claim, setClaim } = useClaimDetailContext()

  const handleClaimTypeChange = (e: React.FormEvent<HTMLInputElement>) => {
    const claimCopy = { ...claim }

    switch (e.currentTarget.value) {
      case 'CLAIM':
        setClaim({ ...claimCopy, CLAIM: 'X', PRE_D: null })
        break
      case 'PRE_D':
        setClaim({ ...claimCopy, CLAIM: null, PRE_D: 'X' })
        break
    }
  }

  return (
    <div className='form-detail-section'>
      <section className='page-section'>
        <h1 className='page-section__title'>Service Information</h1>
        <div className='flex-row gap-150'>
          <div className='mt-0375'>
            <FormRadioInput
              checked={claim?.CLAIM?.length > 0}
              label='Claim'
              name='claim-type'
              onChange={handleClaimTypeChange}
              value='CLAIM'
            />
          </div>
          <div className='mt-0375'>
            <FormRadioInput
              checked={claim?.PRE_D?.length > 0}
              label='Prior Authorization'
              name='claim-type'
              onChange={handleClaimTypeChange}
              value='PRE_D'
            />
          </div>
          <FormInput
            element={claim}
            initialValue={claim.PRIOR_AUTH}
            label='Prior Authorization #'
            name='PRIOR_AUTH'
            noFloat
            placeholder='Prior Authorization #'
            section='procedures'
            setElement={setClaim}
            validator={validatePriorAuthorization}
            width={'13rem'}
          />
        </div>
      </section>
    </div>
  )
}
