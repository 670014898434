import { createContext, FC, useContext, useState } from 'react'

import { getEligibilityStatusInfo } from 'ui'
import { FormError } from 'utils'

import GlobalState from 'trellis:state/globalState'

import {
  EligibilityPatient,
  EligibilityPatients,
  PatientEligibilityForm,
} from '../../../../api/eligibility/eligibility-client/api'
import { VerifyPatient } from '../../../../api/eligibility/eligibilityApi'
import { Errors } from '../../../../constants/errors'
import { NotifyText } from '../../../../constants/notifyText'
import { useGlobalContext } from '../../../../context/GlobalContextProvider'
import { showMessage } from '../../../../utilities/general'
import { emptyPatient } from '../../shared/form/utilities/eligibilityFormDataHandling'
import {
  EligibilityHistory,
  ParticipatingCarrier,
} from '../../shared/utilities/eligibilityTyping'
import { getEligibilityResponse } from '../utilities/getEligibilityResponse'
import { useEligibilityContext } from './EligibilityContext'

export type PatientResponse = {
  CARRIER_ID: string
  CARRIER_NAME: string
  GROUP_NUMBER: string
  PAT_DOB: string
  PAT_FIRST_NAME: string
  PAT_LAST_NAME: string
  PAT_MID_NAME: string
  PAT_SEX: string
  PAT_SUFFIX: string
  PT_IS_SUBSCRIBER: boolean
  SUB_DOB: string
  SUB_FIRST_NAME: string
  SUB_ID: string
  SUB_LAST_NAME: string
  SUB_MID_NAME: string
  SUB_SEX: string
  SUB_SUFFIX: string
}

const defaultPatientResponse: PatientResponse = {
  CARRIER_ID: '',
  CARRIER_NAME: '',
  GROUP_NUMBER: '',
  PAT_DOB: '',
  PAT_FIRST_NAME: '',
  PAT_LAST_NAME: '',
  PAT_MID_NAME: '',
  PAT_SEX: '',
  PAT_SUFFIX: '',
  PT_IS_SUBSCRIBER: false,
  SUB_DOB: '',
  SUB_FIRST_NAME: '',
  SUB_ID: '',
  SUB_LAST_NAME: '',
  SUB_MID_NAME: '',
  SUB_SEX: '',
  SUB_SUFFIX: '',
}

const PatientEligibilityDetailContext = createContext(null)

const PatientEligibilityDetailContextProvider: FC<{
  children: JSX.Element
}> = ({ children }) => {
  const { authentication } = useGlobalContext()
  const { setVerificationResponse } = useEligibilityContext()

  const [activeTab, setActiveTab] = useState<string>('Patient')
  const [loading, setLoading] = useState<boolean>(false)
  const [verifying, setVerifying] = useState<boolean>(false)
  const [patient, setPatient] = useState<EligibilityPatient>({
    ...emptyPatient,
  })
  const [patientDisplayName, setPatientDisplayName] =
    useState<string>('New Patient')
  const [patientStatus, setPatientStatus] = useState<{
    status: string
    toolTip: string
  }>(null)
  const [eligibilityHistory, setEligibilityHistory] = useState<
    EligibilityHistory[]
  >([])
  const [isPatientReadyToVerify, setIsPatientReadyToVerify] =
    useState<boolean>(false)
  const [formErrors, setFormErrors] = useState<FormError[]>([])
  const [patientResponse, setPatientResponse] = useState<PatientResponse>()
  const [patientEligibilityResponse, setPatientEligibilityResponse] =
    useState<PatientEligibilityForm>(null)
  const [showingPatientResponseModal, setShowingPatientResponseModal] =
    useState<boolean>(false)
  const [showPatientDetail, setShowPatientDetail] = useState<boolean>(false)
  const { participatingCarriers } = useEligibilityContext()

  const handleGetPatient = (row: {
    PatientFirstName?: string
    PatientId: string
    PatientLastName?: string
    Status?: string
  }) => {
    setLoading(true)
    updatePatientDisplay(row.PatientFirstName, row.PatientLastName, row.Status)
    setPatient({ ...patient, PatientId: row.PatientId })
    setShowPatientDetail(true)
  }

  const updatePatientDisplay = (
    PatientFirstName?: string,
    PatientLastName?: string,
    Status?: string,
  ) => {
    setPatientDisplayName(`${PatientLastName}, ${PatientFirstName}`)
    setPatientStatus(
      getEligibilityStatusInfo(
        Status,
        GlobalState.EligibilityStatusMapping.peek(),
      ),
    )
  }

  const clearPatientResponseData = () => {
    setPatientResponse(defaultPatientResponse)
  }

  const formatBirthdate = (timestamp: string) => {
    if (timestamp === '') return timestamp

    const date = new Date(timestamp)
    let day = date.getDate().toString()
    if (day.length === 1) day = `0${day}`
    let month = (date.getMonth() + 1).toString()
    if (month.length === 1) month = `0${month}`
    return `${month}/${day}/${date.getFullYear().toString()}`
  }

  const formatPatientResponse = (response: any) => {
    const copy = { ...response }

    if (copy?.PAT_DOB) copy.PAT_DOB = formatBirthdate(copy.PAT_DOB)
    if (copy?.SUB_DOB) copy.SUB_DOB = formatBirthdate(copy.SUB_DOB)
    if (copy?.PAT_SEX)
      copy.PAT_SEX =
        copy.PAT_SEX.charAt(0) === 'N' ? 'U' : copy.PAT_SEX.charAt(0)
    if (copy?.SUB_SEX)
      copy.SUB_SEX =
        copy.SUB_SEX.charAt(0) === 'N' ? 'U' : copy.SUB_SEX.charAt(0)

    setPatientResponse(copy)
  }

  const updatePatientWithPatientResponse = async () => {
    const patientCopy = { ...patient }

    patientCopy['PatientFirstName'] = patientResponse['PAT_FIRST_NAME']
    patientCopy['PatientMiddleName'] = patientResponse['PAT_MID_NAME']
    patientCopy['PatientLastName'] = patientResponse['PAT_LAST_NAME']
    patientCopy['PatientSuffix'] = patientResponse['PAT_SUFFIX']
    patientCopy['PatientBirthdate'] = patientResponse['PAT_DOB']
    patientCopy['PatientGender'] = patientResponse['PAT_SEX']
    patientCopy['CarrierId'] = patientResponse['CARRIER_ID']
    patientCopy['CarrierName'] = patientResponse['CARRIER_NAME']
    patientCopy['GroupNumber'] = patientResponse['GROUP_NUMBER']
    patientCopy['PatientIsSub'] = patientResponse['PT_IS_SUBSCRIBER']

    if (!patientResponse.PT_IS_SUBSCRIBER) {
      patientCopy['SubscriberFirstName'] = patientResponse['SUB_FIRST_NAME']
      patientCopy['SubscriberMiddleName'] = patientResponse['SUB_MID_NAME']
      patientCopy['SubscriberLastName'] = patientResponse['SUB_LAST_NAME']
      patientCopy['SubscriberSuffix'] = patientResponse['SUB_SUFFIX']
      patientCopy['SubscriberBirthdate'] = patientResponse['SUB_DOB']
      patientCopy['SubscriberGender'] = patientResponse['SUB_SEX']
      patientCopy['SubscriberId'] = patientResponse['SUB_ID']
    }

    try {
      //Validate carrierID
      if (!patientCopy.CarrierId || patientCopy.CarrierId === '0') {
        const carrierFound = participatingCarriers.filter(
          (carrier: ParticipatingCarrier) =>
            carrier.CarrierName?.toLowerCase() ==
            patientCopy.CarrierName?.toLowerCase(),
        )
        patientCopy.CarrierId = carrierFound[0]?.CarrierId
      }

      const verifyResponse = await VerifyPatient(
        patientCopy.PatientId,
        patientCopy,
      )

      if (verifyResponse) {
        try {
          const eligibilityResponse =
            await getEligibilityResponse<EligibilityPatients>(
              authentication,
              { ...patientCopy, Status: verifyResponse.data.Status },
              verifyResponse.data.HtmlResult,
              verifyResponse.data.EligibilityId,
            )

          if (eligibilityResponse) setVerificationResponse(eligibilityResponse)
        } catch {
          throw new ReferenceError(NotifyText.noEligibilityResponseFound)
        } finally {
          setPatient({ ...patientCopy })
          setPatientDisplayName(
            `${patientCopy.PatientLastName}, ${patientCopy.PatientFirstName}`,
          )
          setPatientStatus({
            status: verifyResponse.data.Status,
            toolTip: verifyResponse.data.StatusDescription,
          })
        }
      }
    } catch (error) {
      if (error instanceof ReferenceError) showMessage(error.message, 'warning')
      else showMessage(Errors.verifyPatientError, 'error')
    } finally {
      return
    }
  }

  return (
    <PatientEligibilityDetailContext.Provider
      value={{
        // state
        activeTab,
        formErrors,
        isPatientReadyToVerify,
        loading,
        patient,
        patientDisplayName,
        patientResponse,
        patientEligibilityResponse,
        setPatientEligibilityResponse,
        patientStatus,
        eligibilityHistory,
        setActiveTab,
        setFormErrors,
        setIsPatientReadyToVerify,
        setPatientDisplayName,
        setEligibilityHistory,
        setLoading,
        setPatient,
        setShowPatientDetail,
        setPatientStatus,
        setShowingPatientResponseModal,
        setVerifying,
        showPatientDetail,
        showingPatientResponseModal,
        verifying,

        // functions
        clearPatientResponseData,
        formatPatientResponse,
        handleGetPatient,
        updatePatientDisplay,
        updatePatientWithPatientResponse,
      }}
    >
      {children}
    </PatientEligibilityDetailContext.Provider>
  )
}

export const usePatientEligibilityDetailContext = () => {
  const context = useContext(PatientEligibilityDetailContext)
  if (context === undefined)
    throw new Error('Context must be used within a Provider')

  return context
}

export default PatientEligibilityDetailContextProvider
