import { PropsWithChildren, ReactNode } from 'react'

import { ErrorRecord } from 'utils'

import styles from './FormControlGroup.module.scss'

interface FormControlGroupProps<DataType> {
  errors?: ErrorRecord<DataType>
  isLabelFloating: boolean
  label?: string
  name: string
}

const FormControlGroup = <DataType,>({
  children,
  errors = {},
  isLabelFloating,
  label = '',
  name,
}: PropsWithChildren<FormControlGroupProps<DataType>>): ReactNode => {
  const labelClassSelectors = `
    ${styles['form-control-group__label']}
    ${
      styles[
        `form-control-group__label--${isLabelFloating ? 'float' : 'no-float'}`
      ]
    }
    ${
      styles[
        `form-control-group__label--${
          errors[name as keyof DataType] ? 'error' : ''
        }`
      ]
    }
  `

  return (
    <div className={styles['form-control-group']}>
      <div className={styles['form-control-group__item']}>
        {label && (
          <label
            className={labelClassSelectors}
            htmlFor={name}
          >
            {label}
          </label>
        )}
        {children}
      </div>
      {errors[name as keyof DataType] && (
        <p className={styles['form-control-group__error']}>
          {errors[name as keyof DataType]}
        </p>
      )}
    </div>
  )
}

export default FormControlGroup
