export const Errors = {
  addressError: 'Address is required.',
  BillingNameError: 'The Billing Name is required',
  billToNameError: 'Cardholder name is required.',
  BillingZipError: 'Billing zip code is not in a recognized zip format.',
  carrierListError:
    'There was a problem retrieving the carriers list. Please try again later.',
  CarrierError: 'The Carrier Name is required',
  CarrierAddressError: 'The Carrier Address is required',
  CarrierCityError: 'The Carrier City is required',
  cityError: 'City is required.',
  claimPrintError: 'There was a problem while printing this claim.',
  CobBirthDateError:
    'The COB DOB is required when either COB Name or ID/SSN are provided',
  CobBirthDateDateError: 'COB DOB must be a valid date in mm/dd/yyyy format',
  CobNameError:
    'The COB Name is required when either COB DOB or ID/SSN are provided',
  CobSsnError:
    'The ID/SSN is required when either COB Name or COB DOB are provided',
  contactNameError: 'Contact name is required.',
  creditCardExpireYearError: 'Expiration year is required.',
  creditCardExpireMonthError: 'Expiration month is required.',
  creditCardExpireDateError: 'A valid expiration date is required.',
  creditCardNumberError: 'Card number is required.',
  creditCardTypeError: 'Card type is required.',
  emailError: 'Contact email is required.',
  emailFormatError: 'Email must be in valid format. e.g. abc@test.com',
  getBillingFilesError:
    'There was an error trying to get billing files. Please try again later.',
  getBillingStatementsError:
    'There was an error trying to get billing statements. Please try again later.',
  getBillingDownloadsError:
    'There was an error generating the csv file. Please try again later.',
  getDownloadsError:
    'There was an error trying to get available downloads. Please try again later.',
  getPatientsError:
    'There was an error trying to get patients data. Please try again later.',
  formValidationErrors: 'Please fix the errors and try again.',
  inactiveUser:
    'Your account is inactive. Please contact support for assistance.',
  patientBirthDateDateError: "Patient Birthdate must be prior to today's date.",
  patientBirthDateError: 'Patient Birthdate is required.',
  PatientBirthDateDateError:
    'Patient Birthdate must be a valid date in mm/dd/yyyy format',
  patientFirstNameError: 'Patient First Name is required.',
  patientGenderError: 'Patient Gender is required.',
  patientInfoError:
    'There was an error getting the patient info. Please try again later.',
  patientLastNameError: 'Patient Last Name is required.',
  PatientNameError: 'Patient Name is required.',
  PatientZipError: 'Patient zip code is not in a recognized zip format.',
  payerError: 'Payer is required.',
  paymentUploadError:
    'There was an error updating your billing info. Please try again later.',
  phoneError: 'Contact phone is required.',
  phoneFormatError: 'Phone format is incorrect.',
  practiceManagementSystemError: 'Practice Management System is required',
  practiceNameError: 'Practice name is required.',
  ProcedureCodeWarning:
    'Procedures with no codes will not be included in the fee total for this claim',
  providerFirstNameError: 'Provider First Name is required.',
  providerIdError: 'Provider NPI is required.',
  providerIdValidationError: 'Provider NPI is invalid - verify NPI entered',
  providerLastNameError: 'Provider Last or Billing Name is required.',
  verifyPatientError:
    'There was an error verifying patient. Please try again later.',
  sendEligibilityError: 'Payer connection unavailable. Please try again later.',
  serialNicknameError:
    'Computer name is required and must not exceed 100 characters',
  stateAbbreviationError: 'State is required.',
  subscriberBirthDateDateError:
    "Subscriber Birthdate must be prior to today's date.",
  SubscriberBirthDateError: 'Subscriber Birthdate is required.',
  SubscriberBirthDateDateError:
    'Subscriber Birthdate must be a valid date in mm/dd/yyyy format',
  subscriberFirstNameError: 'Subscriber First Name is required.',
  subscriberGenderError: 'Subscriber Gender is required.',
  subscriberIdSsnError: 'Subscriber ID is required.',
  SubscriberSsnError: 'Subscriber SSN is requried',
  subscriberLastNameError: 'Subscriber Last Name is required.',
  SubscriberNameError: 'Subscriber name is requried',
  SubscriberZipError: 'Subscriber zip code is not in a recognized zip format.',
  taxonomyCodeError: 'Taxonomy code is required',
  TCRStatusError: 'Something went wrong while retrieving the customer status.',
  TinError: 'The Provider Tax ID is required',
  TreatingLicenseError: 'The Treating License is required',
  TreatmentZipError: 'Treatment zip code is not in a recognized zip format.',
  updateClaimError: 'Failed to update claim. Please try again later.',
  zipError: 'Zip code is required.',
  zipIncorrectFormat: 'Zip format is incorrect.',
  zipLengthError: 'Zip code must have at least 5 numbers.',
  zipSpecialCharacters: 'Zip code must not contain special characters.',
  somethingUnexpectedError:
    'Something unexpected happened. If this issue continues, please contact support.',
  unableToProcessRequest:
    'We were unable to process this request at this time. Please try again later.',
  AttachmentRequirementsError: 'Attachment is required.',
  invalidProcedureCodeError: 'Invalid Procedure Code, must contain one letter.',
  invalidLoginAttempt: 'Invalid login attempt.',
  sessionExpired:
    'You were logged out due to inactivity. Please log in to continue.',
}
