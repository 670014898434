import { Col, Modal, Row } from 'antd'
import { FC } from 'react'

import { Button } from 'ui'

import poweredByPearlLogoGif from '../../../../assets/powered-by-pearl.gif'
import poweredByPearlLogo from '../../../../assets/powered-by-pearl.png'

import './imageSyncPearlProductModal.scss'

import { Urls } from '../../shared/sharedConstants'
import { ImageSyncPearlProductModalProps } from '../imageSync.interface'
import ImageSyncFooter from '../imageSyncFooter'

const ImageSyncPearlProductModal: FC<ImageSyncPearlProductModalProps> = ({
  isVisible,
  setIsVisible,
}) => {
  const handleLearnMoreDisplay = () => {
    window.open(Urls.IMAGE_SYNC_LEARN_MORE, '_blank')
  }

  return (
    <Modal
      className='image-sync-pearl-product-modal'
      title={'Image Sync'}
      open={isVisible}
      onCancel={() => setIsVisible(!isVisible)}
      width={1000}
      footer={
        <ImageSyncFooter
          url={Urls.IMAGE_SYNC_LOGO_LEARN_MORE}
          logoPath={poweredByPearlLogo}
        />
      }
    >
      <Row gutter={[24, 24]}>
        <Col
          span={24}
          className='image-sync-pearl-product-modal__body'
        >
          <img
            alt='Powered By Pearl'
            style={{ width: 250 }}
            src={poweredByPearlLogoGif}
          />
        </Col>
        <Col
          span={24}
          className='image-sync-pearl-product-modal__body'
        >
          <p className='image-sync-pearl-product-modal__body-title'>
            Seamlessly connect your patient images with your claims.
          </p>
        </Col>
        <Col
          span={24}
          className='image-sync-pearl-product-modal__body'
        >
          <Button
            type='primary'
            label='Learn More'
            onClick={() => handleLearnMoreDisplay()}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default ImageSyncPearlProductModal
