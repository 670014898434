import { Form } from 'antd'
import React, { FC } from 'react'
import { NumericFormat } from 'react-number-format'

import { spacesToProperty } from '../../../utilities/general'
import { validateProperty } from '../../../utilities/validators/baseValidator'
import { FormFloatLabel } from './formFloatLabel'
import {
  FormError,
  FormInput,
  getError,
  getRules,
  handleFormChange,
} from './util'

export const FormCurrencyInput: FC<FormInput> = ({
  disabled = false,
  element,
  initialValue,
  label = null,
  name,
  noFloat = false,
  parentProperty = null,
  required = false,
  section = null,
  setElement,
  validator,
  width = '100%',
}) => {

  const error: FormError = element && getError(name, element)

  const handleChange = (value: string) => {
    const updatedElement = handleFormChange(
      name,
      parentProperty,
      value,
      element,
    )
    validateProperty(
      validator,
      updatedElement,
      name,
      parentProperty,
      required,
      section,
    )
    setElement(updatedElement)
  }

  const inputContent = (
    <NumericFormat
      allowNegative={false}
      className={`ant-input ${noFloat ? 'no-float' : ''}`}
      data-testid={name}
      decimalScale={2}
      decimalSeparator='.'
      disabled={disabled}
      displayType='input'
      fixedDecimalScale={true}
      onValueChange={(values: { formattedValue: string; value: string }) => {
        const { value } = values
        handleChange(value)
      }}
      placeholder='$0.00'
      prefix='$'
      style={{ width: width, borderRadius: '0.375em' }}
      thousandsGroupStyle='thousand'
      thousandSeparator={true}
      type='text'
      value={initialValue}
    />
  )

  let input

  if (noFloat) {
    input = inputContent
  } else {
    input = (
      <FormFloatLabel
        label={
          label || spacesToProperty(typeof name === 'string' ? name : name[1])
        }
        name={name}
        inputValue={initialValue ? initialValue : '0'}
      >
        {inputContent}
      </FormFloatLabel>
    )
  }

  if (!error && !validator) {
    return (
      <div className='form-item-validation-container'>
        <Form.Item name={name}>{input}</Form.Item>
      </div>
    )
  }

  if (error) {
    return (
      <div className='form-item-validation-container'>
        <Form.Item
          name={name}
          validateStatus='error'
          help={error.message}
        >
          {input}
        </Form.Item>
      </div>
    )
  }

  return (
    <div className='form-item-validation-container'>
      <Form.Item
        name={name}
        rules={[getRules(typeof name === 'string' ? name : name[1], required)]}
      >
        {input}
      </Form.Item>
    </div>
  )
}
