import { ObservableObject } from '@legendapp/state'
import { Progress } from 'antd'
import { FC, useEffect, useState } from 'react'

import {
  EligibilityResponseStateType,
  StructuredBenefit,
} from '../utilities/eligibilityTyping'

import './benefitsCard.scss'
import { observer } from '@legendapp/state/react'

type BenefitsCardProps = {
  cardType: 'Deductible' | 'Maximums' | 'Ortho'
  state: ObservableObject<EligibilityResponseStateType>
}

const BenefitsCard: FC<BenefitsCardProps> = observer(({ cardType, state }) => {
  const currentBenefits = state?.currentBenefits?.get()

  const [individualRemainder, setIndividualRemainder] = useState<string>()
  const [individualTotal, setIndividualTotal] = useState<string>()
  const [individualUsed, setIndividualUsed] = useState<string>()
  const [familyRemainder, setFamilyRemainder] = useState<string>()
  const [familyTotal, setFamilyTotal] = useState<string>()
  const [familyUsed, setFamilyUsed] = useState<string>()
  const [orthoCoinsurance, setOrthoCoinsurance] = useState<string>()

  const setFieldValues = (
    individualRemaining: string,
    individualAmount: string,
    familyRemaining: string,
    familyAmount: string,
    currentBenefits: StructuredBenefit,
  ) => {
    setIndividualRemainder(individualRemaining)
    setIndividualTotal(individualAmount)
    setFamilyRemainder(familyRemaining)
    setFamilyTotal(familyAmount)
    cardType === 'Ortho' &&
      setOrthoCoinsurance(currentBenefits?.ortho?.coinsurance)

    const individualDiff =
      parseFloat(individualAmount) - parseFloat(individualRemaining)
    setIndividualUsed(individualDiff >= 0 ? individualDiff.toString() : ' -- ')

    const familyDiff = parseFloat(familyAmount) - parseFloat(familyRemaining)
    setFamilyUsed(familyDiff >= 0 ? familyDiff.toString() : ' -- ')
  }

  useEffect(() => {
    let individualRemaining: string, individualAmount: string
    let familyRemaining: string, familyAmount: string

    switch (cardType) {
      case 'Deductible':
        individualRemaining = currentBenefits?.deductible?.individual?.remaining
        individualAmount = currentBenefits?.deductible?.individual?.amount
        familyRemaining = currentBenefits?.deductible?.family?.remaining
        familyAmount = currentBenefits?.deductible?.family?.amount

        setFieldValues(
          individualRemaining,
          individualAmount,
          familyRemaining,
          familyAmount,
          currentBenefits,
        )
        break
      case 'Maximums':
        individualRemaining = currentBenefits?.maximum?.individual?.remaining
        individualAmount = currentBenefits?.maximum?.individual?.amount
        familyRemaining = currentBenefits?.maximum?.family?.remaining
        familyAmount = currentBenefits?.maximum?.family?.amount

        setFieldValues(
          individualRemaining,
          individualAmount,
          familyRemaining,
          familyAmount,
          currentBenefits,
        )
        break
      case 'Ortho':
        individualRemaining = currentBenefits?.ortho?.maximum_remaining
        individualAmount = currentBenefits?.ortho?.maximum

        setFieldValues(
          individualRemaining,
          individualAmount,
          familyRemaining,
          familyAmount,
          currentBenefits,
        )
        break
      default:
        break
    }
  }, [currentBenefits])

  return (
    <div className='structured-benefits-card'>
      <section className='structured-benefits-card__content'>
        <p className='structured-benefits-card__content-header'>{cardType}</p>
        <article className='structured-benefits-card__content-row'>
          <p className='structured-benefits-card__content-row--bold'>
            Individual
          </p>
          <p>{`Remaining: $${individualRemainder || ' -- '}`}</p>
        </article>
        <article className='structured-benefits-card__content-row middle-row'>
          <Progress
            percent={
              (parseFloat(individualUsed) / parseFloat(individualTotal)) *
                100 || 0
            }
            showInfo={false}
            trailColor='#E8F3D6'
          />
          <p className='structured-benefits-card__content-row--used'>{`$${individualUsed}/$${
            individualTotal || ' --'
          }`}</p>
        </article>
        {cardType !== 'Ortho' ? (
          <>
            <article className='structured-benefits-card__content-row'>
              <p className='structured-benefits-card__content-row--bold'>
                Family
              </p>
              <p>{`Remaining: $${familyRemainder || ' -- '}`}</p>
            </article>
            <article className='structured-benefits-card__content-row'>
              <Progress
                percent={
                  (parseFloat(familyUsed) / parseFloat(familyTotal)) * 100 || 0
                }
                showInfo={false}
                trailColor='#E8F3D6'
              />
              <p className='structured-benefits-card__content-row--used'>{`$${familyUsed}/$${
                familyTotal || ' --'
              }`}</p>
            </article>
          </>
        ) : (
          <article className='structured-benefits-card__content-row'>
            <p>{`Coinsurance: ${
              orthoCoinsurance ? orthoCoinsurance + '%' : ' --'
            }`}</p>
          </article>
        )}
      </section>
    </div>
  )
})

export default BenefitsCard
