import { Navigate, RouteObject } from 'react-router-dom'

import AcceptEULA, {
  eulaLoader,
} from 'trellis:components/AcceptEULA/AcceptEULA'
import AcceptEulaExternal from 'trellis:components/account/acceptEulaExternal/acceptEulaExternal'
import { Account } from 'trellis:components/account/account'
import { ForgotPassword } from 'trellis:components/account/forgotPassword/forgotPassword'
import Logout from 'trellis:components/account/logout/logout'
import { ResetPassword } from 'trellis:components/account/resetPassword/resetPassword'
import Attachments from 'trellis:components/Attachments/Attachments'
import BillingDetails from 'trellis:components/billingDetails/billingDetails'
import BillingInfo, {
  billingInfoLoader,
} from 'trellis:components/billingInfo/billingInfo'
import Claims from 'trellis:components/claims/claims'
import Eligibility from 'trellis:components/Eligibility/Eligibility'
import BaseLayout from 'trellis:components/layouts/BaseLayout/baseLayout'
import PostAuthLayout, {
  loaderPostAuthLayout,
} from 'trellis:components/layouts/PostAuthLayout/PostAuthLayout'
import PreAuthLayout, {
  loaderPreAuthLayout,
} from 'trellis:components/layouts/PreAuthLayout/PreAuthLayout'
import Messages from 'trellis:components/messages/messages'
import { MyPractice } from 'trellis:components/MyPractice/MyPractice'
import { PaymentsPortal } from 'trellis:components/paymentsPortal/paymentsPortal'
import { Resource } from 'trellis:components/resource/resource'
import { Settings } from 'trellis:components/settings/settings'
import { UserManagement } from 'trellis:components/userManagement/userManagement'
import { Login } from 'trellis:features/authentication/Login/Login'
import NotFoundErrorMessage from 'trellis:features/error/error-messages/NotFoundError'
import UnexpectedErrorMessage from 'trellis:features/error/error-messages/UnexpectedError'
import ErrorBoundary from 'trellis:features/error/ErrorBoundary/ErrorBoundary'
import {
  AdditionalServiceContent,
  AdditionalServiceContentTypes,
  additionalServiceContentTypes,
} from 'trellis:features/marketing/AdditionalServiceContent'
import { loaderBillingStatements } from 'trellis:features/payments/utils/billing-statements-helpers'
import AdditionalServices from 'trellis:pages/AdditionalServices'
import { BillingStatements } from 'trellis:pages/BillingStatements'
import { CarrierList } from 'trellis:pages/CarrierList'
// ClaimArchive needs to be imported after claims
import ClaimArchive from 'trellis:pages/ClaimArchive/ClaimArchive'
import { Downloads } from 'trellis:pages/Downloads'

import PrivateRoutes from './privateRoutes'

//WARNING: Do not alter existing route paths unless you are 100% sure they're not being used by RL/Trellis plugin

const loginPageElement = (
  <>
    <title>Log in</title>
    <Login />
  </>
)

//post auth layout will redirect them to their default page if they don't have permissions,
//can't get legendstate values reliably in here without odd behavior
const claimsRedirect = <Navigate to='/Claims' />

export const preAuthRoutes: RouteObject[] = [
  {
    element: <PreAuthLayout />,
    loader: loaderPreAuthLayout,
    children: [
      {
        path: '',
        element: loginPageElement,
        index: true,
      },
      {
        path: 'Account',
        children: [
          {
            // default redirect if they're not logged in
            element: (
              <Navigate
                to='Login'
                replace
              />
            ),
            index: true,
          },
          {
            path: 'Login',
            element: loginPageElement,
          },
          {
            path: 'Logout',
            element: (
              <>
                <title>Logout</title>
                <Logout />
              </>
            ),
          },
          {
            path: 'ForgotPassword',
            element: (
              <>
                <title>Password Reset</title>
                <ForgotPassword />
              </>
            ),
          },
          {
            //NEEDED FOR RL
            path: 'ChangePassword',
            element: (
              <>
                <title>Password Reset</title>
                <ForgotPassword />
              </>
            ),
          },
          {
            path: 'ResetPassword',
            element: (
              <>
                <title>New Password</title>
                <ResetPassword />
              </>
            ),
          },
        ],
      },
    ],
  },
]

const practiceRoutes: RouteObject[] = [
  {
    path: 'Practice',
    children: [
      {
        // default redirect if they're authed and go to the root
        element: (
          <Navigate
            to='PracticeInfo'
            replace
          />
        ),
        index: true,
      },
      {
        path: 'PracticeInfo',
        element: (
          <>
            <title>Practice Info</title>
            <MyPractice />
          </>
        ),
        index: true,
      },
      {
        path: 'UserManagement',
        element: (
          <>
            <title>User Management</title>
            <Account />
          </>
        ),
      },
      {
        path: 'UserManagementSso',
        element: (
          <>
            <title>User Management</title>
            <UserManagement />
          </>
        ),
      },
      {
        path: 'BillingDetails',
        element: (
          <>
            <title>BillingDetails</title>
            <BillingDetails />
          </>
        ),
      },
      {
        path: 'Downloads',
        element: (
          <>
            <title>Downloads</title>
            <Downloads />
          </>
        ),
      },
    ],
  },
]

const paymentRoutes: RouteObject[] = [
  {
    path: 'Statements',
    element: (
      <>
        <title>Billing Statements</title>
        <BillingStatements />
      </>
    ),
    loader: loaderBillingStatements,
  },
  {
    children: [
      {
        path: 'Dashboard',
        element: claimsRedirect,
      },
      {
        path: 'Payment',
        children: [
          {
            path: 'PaymentsPortal',
            element: (
              <>
                <title>Payment Processor Portal</title>
                <PaymentsPortal />
              </>
            ),
          },
          {
            //Redirect the old RL billing/payment
            path: '*',
            element: claimsRedirect,
          },
        ],
      },
    ],
  },
]
export const additionalServicesRoutes: RouteObject[] = [
  {
    path: 'AdditionalServices',
    element: <AdditionalServices />,
    children: [
      {
        element: (
          <>
            <title>Additional Services</title>
            {additionalServiceContentTypes.map(
              (type: AdditionalServiceContentTypes) => (
                <AdditionalServiceContent
                  type={type}
                  key={type}
                />
              ),
            )}
          </>
        ),
        index: true,
      },
      {
        path: 'Attachments',
        element: (
          <>
            <title>Additional Services - Attachments</title>
            <AdditionalServiceContent type='attachments' />
          </>
        ),
      },
      {
        path: 'CarrierList',
        element: (
          <>
            <title>Additional Services - CarrierList</title>
            <AdditionalServiceContent type='carrierList' />
          </>
        ),
      },
      {
        path: 'Claims',
        element: (
          <>
            <title>Additional Services - Claims</title>
            <AdditionalServiceContent type='claims' />
          </>
        ),
      },
      {
        path: 'Eligibility',
        element: (
          <>
            <title>Additional Services - Eligibility</title>
            <AdditionalServiceContent type='eligibility' />
          </>
        ),
      },
      {
        path: 'Email',
        element: (
          <>
            <title>Additional Services - Secure Email</title>
            <AdditionalServiceContent type='email' />
          </>
        ),
      },
      {
        path: 'PatientMessaging',
        element: (
          <>
            <title>Additional Services - Patient Messaging</title>
            <AdditionalServiceContent type='patientMessaging' />
          </>
        ),
      },
      {
        path: 'OnlineScheduling',
        element: (
          <>
            <title>Additional Services - Online Scheduling</title>
            <AdditionalServiceContent type='onlineScheduling' />
          </>
        ),
      },
      {
        path: 'Forms',
        element: (
          <>
            <title>Additional Services - Forms</title>
            <AdditionalServiceContent type='forms' />
          </>
        ),
      },
      {
        path: 'Reminders',
        element: (
          <>
            <title>Additional Services - Reminders</title>
            <AdditionalServiceContent type='reminders' />
          </>
        ),
      },
      {
        path: 'TeamChat',
        element: (
          <>
            <title>Additional Services - Team Chat</title>
            <AdditionalServiceContent type='teamChat' />
          </>
        ),
      },
      {
        path: 'Campaigns',
        element: (
          <>
            <title>Additional Services - Campaigns</title>
            <AdditionalServiceContent type='campaigns' />
          </>
        ),
      },
      {
        path: 'Reviews',
        element: (
          <>
            <title>Additional Services - Reviews</title>
            <AdditionalServiceContent type='reviews' />
          </>
        ),
      },
    ],
  },
]

//Legacy post auth routes, DO NOT TOUCH UNTIL RL IS DEAD
export const legacyPostAuthRoutes: RouteObject[] = [
  {
    path: 'Claims/Index', //Path from RL, don't touch
    element: claimsRedirect,
  },
  {
    path: 'Rlo/Client', //Path from RL, don't touch
    element: claimsRedirect,
  },
  {
    path: 'Rlo', //Path from RL, don't touch
    element: claimsRedirect,
  },
]

const privateRoutes: RouteObject[] = [
  {
    element: <PrivateRoutes />,
    children: [
      {
        path: 'Account',
        element: <PreAuthLayout />,
        loader: loaderPreAuthLayout,
        children: [
          {
            path: 'AcceptEula',
            element: (
              <>
                <title>EULA</title>
                <AcceptEULA />
              </>
            ),
            loader: eulaLoader,
          },
          {
            path: 'AcceptEulaExternal',
            element: (
              <>
                <title>EULA</title>
                <AcceptEulaExternal />
              </>
            ),
          },
          {
            path: 'LoginPaymentInfo',
            element: (
              <>
                <title>Payment Info</title>
                <BillingInfo />
              </>
            ),
            loader: billingInfoLoader,
          },
        ],
      },
      {
        element: <PostAuthLayout />,
        loader: loaderPostAuthLayout,
        // This is needed to ensure the route loader for PostAuthLayout is called on each route change
        shouldRevalidate: () => true,
        children: [
          //insurance
          {
            path: 'Eligibility',
            element: (
              <>
                <title>Eligibility</title>
                <Eligibility />
              </>
            ),
          },
          {
            path: 'Claims',
            element: (
              <>
                <title>Claim Management</title>
                <Claims />
              </>
            ),
          },
          {
            path: 'Archive/ClaimArchive',
            element: (
              <>
                <title>Archive</title>
                <ClaimArchive />
              </>
            ),
          },
          {
            path: 'Rlo/Settings',
            element: (
              <>
                <title></title>
                <Settings />
              </>
            ),
          },
          {
            path: 'CarrierList',
            element: (
              <>
                <title>Carrier List</title>
                <CarrierList />
              </>
            ),
          },
          {
            path: 'Attachments',
            element: (
              <>
                <title>Attachments</title>
                <Attachments />
              </>
            ),
          },
          //header links
          {
            path: 'Resource/Index',
            element: (
              <>
                <title>Resource</title>
                <Resource />
              </>
            ),
          },
          {
            path: 'Messages',
            element: (
              <>
                <title>Messages</title>
                <Messages />
              </>
            ),
          },
          ...practiceRoutes,
          ...paymentRoutes,
          ...additionalServicesRoutes,
          ...legacyPostAuthRoutes,
        ],
      },
    ],
  },
]

export const trellisRoutes: RouteObject[] = [
  {
    element: <BaseLayout />,
    errorElement: <UnexpectedErrorMessage />,
    children: [
      {
        path: '*',
        element: (
          <>
            <title>404 Not Found</title>
            <ErrorBoundary
              fallBack={<UnexpectedErrorMessage />}
              key={location?.pathname}
            >
              <NotFoundErrorMessage />
            </ErrorBoundary>
          </>
        ),
      },
      {
        path: 'sso',
        element: <></>,
      },
      ...privateRoutes,
      ...preAuthRoutes,
    ],
  },
  {
    // this route was added for easy access for QA testing
    element: <UnexpectedErrorMessage />,
    path: 'UnexpectedError',
  },
]
