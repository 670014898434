/* tslint:disable */
/* eslint-disable */
/**
 * BillingStatements
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BillingStatementFiles
 */
export interface BillingStatementFiles {
    /**
     * 
     * @type {number}
     * @memberof BillingStatementFiles
     */
    'BillingStatementBatchID'?: number;
    /**
     * 
     * @type {number}
     * @memberof BillingStatementFiles
     */
    'TotalStatements'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BillingStatementFiles
     */
    'AdditionalPages'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BillingStatementFiles
     */
    'TotalMailed'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BillingStatementFiles
     */
    'Undeliverable'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BillingStatementFiles
     */
    'ProcessDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingStatementFiles
     */
    'StatusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BillingStatementFiles
     */
    'TotalCount'?: number;
}
/**
 * 
 * @export
 * @interface BillingStatements
 */
export interface BillingStatements {
    /**
     * 
     * @type {number}
     * @memberof BillingStatements
     */
    'BillingStatementID'?: number;
    /**
     * 
     * @type {string}
     * @memberof BillingStatements
     */
    'Mailed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingStatements
     */
    'AccountNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingStatements
     */
    'Recipient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingStatements
     */
    'Address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingStatements
     */
    'City'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingStatements
     */
    'State'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingStatements
     */
    'Zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingStatements
     */
    'County'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingStatements
     */
    'Country'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BillingStatements
     */
    'AmountDue'?: number;
    /**
     * 
     * @type {number}
     * @memberof BillingStatements
     */
    'TotalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof BillingStatements
     */
    'StatementDate'?: string | null;
}
/**
 * 
 * @export
 * @interface DownloadPresignLinkResponse
 */
export interface DownloadPresignLinkResponse {
    /**
     * 
     * @type {string}
     * @memberof DownloadPresignLinkResponse
     */
    'CsvLink'?: string | null;
}
/**
 * 
 * @export
 * @interface GetPaginatedStatementFilesResponse
 */
export interface GetPaginatedStatementFilesResponse {
    /**
     * 
     * @type {Array<BillingStatementFiles>}
     * @memberof GetPaginatedStatementFilesResponse
     */
    'StatementFiles'?: Array<BillingStatementFiles> | null;
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedStatementFilesResponse
     */
    'FilteredFiles'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedStatementFilesResponse
     */
    'TotalFiles'?: number;
}
/**
 * 
 * @export
 * @interface GetPaginatedStatementsResponse
 */
export interface GetPaginatedStatementsResponse {
    /**
     * 
     * @type {Array<BillingStatements>}
     * @memberof GetPaginatedStatementsResponse
     */
    'Statements'?: Array<BillingStatements> | null;
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedStatementsResponse
     */
    'FilteredStatments'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedStatementsResponse
     */
    'TotalStatements'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentProcessor = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type PaymentProcessor = typeof PaymentProcessor[keyof typeof PaymentProcessor];


/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface StatementSettings
 */
export interface StatementSettings {
    /**
     * 
     * @type {boolean}
     * @memberof StatementSettings
     */
    'CVV': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatementSettings
     */
    'QR': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatementSettings
     */
    'Discover': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatementSettings
     */
    'MC': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatementSettings
     */
    'Amex': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatementSettings
     */
    'Visa': boolean;
    /**
     * 
     * @type {string}
     * @memberof StatementSettings
     */
    'RemitName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatementSettings
     */
    'RemitAddress1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatementSettings
     */
    'RemitAddress2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatementSettings
     */
    'RemitCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatementSettings
     */
    'RemitState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatementSettings
     */
    'RemitZip'?: string | null;
}
/**
 * 
 * @export
 * @interface StatementsGetFacilityResponse
 */
export interface StatementsGetFacilityResponse {
    /**
     * 
     * @type {PaymentProcessor}
     * @memberof StatementsGetFacilityResponse
     */
    'PaymentProcessor'?: PaymentProcessor;
    /**
     * 
     * @type {string}
     * @memberof StatementsGetFacilityResponse
     */
    'FacilityId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StatementsGetFacilityResponse
     */
    'TrellisGlobalCustomerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatementsGetFacilityResponse
     */
    'ActivePMSOperaCompanyID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatementsGetFacilityResponse
     */
    'IsConfigured'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof StatementsGetFacilityResponse
     */
    'ConfigurationProblems'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatementsGetFacilityResponse
     */
    'Active'?: boolean;
}
/**
 * 
 * @export
 * @interface StatementsQueryRequest
 */
export interface StatementsQueryRequest {
    /**
     * 
     * @type {number}
     * @memberof StatementsQueryRequest
     */
    'Range'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StatementsQueryRequest
     */
    'Offset'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StatementsQueryRequest
     */
    'SortColumn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatementsQueryRequest
     */
    'SortDirection'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof StatementsQueryRequest
     */
    'Filters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof StatementsQueryRequest
     */
    'SearchText'?: string | null;
}
/**
 * 
 * @export
 * @interface ValidationProblemDetails
 */
export interface ValidationProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ValidationProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'instance'?: string | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ValidationProblemDetails
     */
    'Errors'?: { [key: string]: Array<string>; } | null;
}

/**
 * BillingStatementsApi - axios parameter creator
 * @export
 */
export const BillingStatementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {StatementsQueryRequest} statementsQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingStatementsGetDownloadStatementFiless: async (customerId: number, statementsQueryRequest: StatementsQueryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('billingStatementsGetDownloadStatementFiless', 'customerId', customerId)
            // verify required parameter 'statementsQueryRequest' is not null or undefined
            assertParamExists('billingStatementsGetDownloadStatementFiless', 'statementsQueryRequest', statementsQueryRequest)
            const localVarPath = `/customer/{customerId}/downloads/files`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statementsQueryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {StatementsQueryRequest} statementsQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingStatementsGetDownloadStatements: async (customerId: number, statementsQueryRequest: StatementsQueryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('billingStatementsGetDownloadStatements', 'customerId', customerId)
            // verify required parameter 'statementsQueryRequest' is not null or undefined
            assertParamExists('billingStatementsGetDownloadStatements', 'statementsQueryRequest', statementsQueryRequest)
            const localVarPath = `/customer/{customerId}/downloads/statements`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statementsQueryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingStatementsGetSettings: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('billingStatementsGetSettings', 'customerId', customerId)
            const localVarPath = `/customer/{customerId}/settings`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {StatementsQueryRequest} statementsQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingStatementsGetStatementFiles: async (customerId: number, statementsQueryRequest: StatementsQueryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('billingStatementsGetStatementFiles', 'customerId', customerId)
            // verify required parameter 'statementsQueryRequest' is not null or undefined
            assertParamExists('billingStatementsGetStatementFiles', 'statementsQueryRequest', statementsQueryRequest)
            const localVarPath = `/customer/{customerId}/files`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statementsQueryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {StatementsQueryRequest} statementsQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingStatementsGetStatements: async (customerId: number, statementsQueryRequest: StatementsQueryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('billingStatementsGetStatements', 'customerId', customerId)
            // verify required parameter 'statementsQueryRequest' is not null or undefined
            assertParamExists('billingStatementsGetStatements', 'statementsQueryRequest', statementsQueryRequest)
            const localVarPath = `/customer/{customerId}/statements`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statementsQueryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingStatementsHasStatements: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('billingStatementsHasStatements', 'customerId', customerId)
            const localVarPath = `/customer/{customerId}/has-statements`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {StatementSettings} [statementSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingStatementsUpdateSettings: async (customerId: number, statementSettings?: StatementSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('billingStatementsUpdateSettings', 'customerId', customerId)
            const localVarPath = `/customer/{customerId}/settings`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statementSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillingStatementsApi - functional programming interface
 * @export
 */
export const BillingStatementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillingStatementsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {StatementsQueryRequest} statementsQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingStatementsGetDownloadStatementFiless(customerId: number, statementsQueryRequest: StatementsQueryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadPresignLinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingStatementsGetDownloadStatementFiless(customerId, statementsQueryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {StatementsQueryRequest} statementsQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingStatementsGetDownloadStatements(customerId: number, statementsQueryRequest: StatementsQueryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadPresignLinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingStatementsGetDownloadStatements(customerId, statementsQueryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingStatementsGetSettings(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatementSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingStatementsGetSettings(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {StatementsQueryRequest} statementsQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingStatementsGetStatementFiles(customerId: number, statementsQueryRequest: StatementsQueryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaginatedStatementFilesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingStatementsGetStatementFiles(customerId, statementsQueryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {StatementsQueryRequest} statementsQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingStatementsGetStatements(customerId: number, statementsQueryRequest: StatementsQueryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaginatedStatementsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingStatementsGetStatements(customerId, statementsQueryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingStatementsHasStatements(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatementsGetFacilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingStatementsHasStatements(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {StatementSettings} [statementSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingStatementsUpdateSettings(customerId: number, statementSettings?: StatementSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingStatementsUpdateSettings(customerId, statementSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BillingStatementsApi - factory interface
 * @export
 */
export const BillingStatementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillingStatementsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {StatementsQueryRequest} statementsQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingStatementsGetDownloadStatementFiless(customerId: number, statementsQueryRequest: StatementsQueryRequest, options?: any): AxiosPromise<DownloadPresignLinkResponse> {
            return localVarFp.billingStatementsGetDownloadStatementFiless(customerId, statementsQueryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {StatementsQueryRequest} statementsQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingStatementsGetDownloadStatements(customerId: number, statementsQueryRequest: StatementsQueryRequest, options?: any): AxiosPromise<DownloadPresignLinkResponse> {
            return localVarFp.billingStatementsGetDownloadStatements(customerId, statementsQueryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingStatementsGetSettings(customerId: number, options?: any): AxiosPromise<StatementSettings> {
            return localVarFp.billingStatementsGetSettings(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {StatementsQueryRequest} statementsQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingStatementsGetStatementFiles(customerId: number, statementsQueryRequest: StatementsQueryRequest, options?: any): AxiosPromise<GetPaginatedStatementFilesResponse> {
            return localVarFp.billingStatementsGetStatementFiles(customerId, statementsQueryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {StatementsQueryRequest} statementsQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingStatementsGetStatements(customerId: number, statementsQueryRequest: StatementsQueryRequest, options?: any): AxiosPromise<GetPaginatedStatementsResponse> {
            return localVarFp.billingStatementsGetStatements(customerId, statementsQueryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingStatementsHasStatements(customerId: number, options?: any): AxiosPromise<StatementsGetFacilityResponse> {
            return localVarFp.billingStatementsHasStatements(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {StatementSettings} [statementSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingStatementsUpdateSettings(customerId: number, statementSettings?: StatementSettings, options?: any): AxiosPromise<void> {
            return localVarFp.billingStatementsUpdateSettings(customerId, statementSettings, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BillingStatementsApi - object-oriented interface
 * @export
 * @class BillingStatementsApi
 * @extends {BaseAPI}
 */
export class BillingStatementsApi extends BaseAPI {
    /**
     * 
     * @param {number} customerId 
     * @param {StatementsQueryRequest} statementsQueryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingStatementsApi
     */
    public billingStatementsGetDownloadStatementFiless(customerId: number, statementsQueryRequest: StatementsQueryRequest, options?: AxiosRequestConfig) {
        return BillingStatementsApiFp(this.configuration).billingStatementsGetDownloadStatementFiless(customerId, statementsQueryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {StatementsQueryRequest} statementsQueryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingStatementsApi
     */
    public billingStatementsGetDownloadStatements(customerId: number, statementsQueryRequest: StatementsQueryRequest, options?: AxiosRequestConfig) {
        return BillingStatementsApiFp(this.configuration).billingStatementsGetDownloadStatements(customerId, statementsQueryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingStatementsApi
     */
    public billingStatementsGetSettings(customerId: number, options?: AxiosRequestConfig) {
        return BillingStatementsApiFp(this.configuration).billingStatementsGetSettings(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {StatementsQueryRequest} statementsQueryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingStatementsApi
     */
    public billingStatementsGetStatementFiles(customerId: number, statementsQueryRequest: StatementsQueryRequest, options?: AxiosRequestConfig) {
        return BillingStatementsApiFp(this.configuration).billingStatementsGetStatementFiles(customerId, statementsQueryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {StatementsQueryRequest} statementsQueryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingStatementsApi
     */
    public billingStatementsGetStatements(customerId: number, statementsQueryRequest: StatementsQueryRequest, options?: AxiosRequestConfig) {
        return BillingStatementsApiFp(this.configuration).billingStatementsGetStatements(customerId, statementsQueryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingStatementsApi
     */
    public billingStatementsHasStatements(customerId: number, options?: AxiosRequestConfig) {
        return BillingStatementsApiFp(this.configuration).billingStatementsHasStatements(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {StatementSettings} [statementSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingStatementsApi
     */
    public billingStatementsUpdateSettings(customerId: number, statementSettings?: StatementSettings, options?: AxiosRequestConfig) {
        return BillingStatementsApiFp(this.configuration).billingStatementsUpdateSettings(customerId, statementSettings, options).then((request) => request(this.axios, this.basePath));
    }
}


