import { Col, Row } from 'antd'
import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react'

import { ImageType } from '../../../api/attachment/attachment-client/api'
import { showMessage } from '../../../utilities/general'
import {
  getUrlForAttachment,
  IAttachmentImage,
  IImageOperation,
  ImageOrientationType,
} from '../attachment.interface'
import AttachmentImageDisplay from './components/attachmentImageDisplay'
import EditImage from './components/editImage/editImage'
import ImageDateTaken from './components/imageDateTaken'
import ImageTypeDropDown from './components/imageTypeDropdown/imageTypeDropdown'
import SaveImage from './components/saveImage'
import XRayOrientation from './components/xRayOrientation'

import './attachmentImageDetails.scss'

interface AttachmentImageDetails {
  attachmentImage: IAttachmentImage
  storeAttachmentImageLocal: (
    attachmentImage: IAttachmentImage,
    openDetailView: boolean,
  ) => void
  attachmentImageTypes: MutableRefObject<ImageType[]>
  overMaxSize: boolean
}

const AttachmentImageDetails: FC<AttachmentImageDetails> = ({
  attachmentImage,
  storeAttachmentImageLocal,
  attachmentImageTypes,
  overMaxSize,
}) => {
  const isMounted = useRef(true)
  const keyId = useRef<number>(0)
  const croppedImageRef = useRef<HTMLImageElement>()

  const currentCanvasBlobRef = useRef<Blob>()

  const [imageType, setImageType] = useState<ImageType>(
    attachmentImage?.imageTypeId == null
      ? null
      : {
          imageTypeId: attachmentImage?.imageTypeId,
          imageTypeName: attachmentImageTypes.current.filter(
            (e) => e.imageTypeId == attachmentImage.imageTypeId,
          )[0]?.imageTypeName,
          isXray: attachmentImageTypes.current.filter(
            (e) => e.imageTypeId == attachmentImage.imageTypeId,
          )[0]?.isXray,
        },
  )

  let imageOrientationType = ImageOrientationType.LeftIsRight
  if (localStorage.getItem('localStorageImageOrientation') == 'LEFT') {
    imageOrientationType = ImageOrientationType.LeftIsLeft
  }
  const [orientation, setOrientation] = useState<ImageOrientationType>(
    attachmentImage?.imageOrientationType ?? imageOrientationType,
  )
  const [imageDate, setImageDate] = useState<Date>(
    attachmentImage.imageDateTaken,
  )
  const [isXrayType, setIsXrayType] = useState<boolean>(
    attachmentImage &&
      attachmentImageTypes.current.filter(
        (e) => e.imageTypeId == attachmentImage.imageTypeId,
      )[0]?.isXray,
  )
  const [operations, setOperations] = useState<IImageOperation>()
  const [originalImg, setOriginalImg] = useState<HTMLImageElement>()
  const [imgForCanvas, setImgForCanvas] = useState<HTMLImageElement>()
  const defaultImageDropdownText = 'Select Type'
  const [activeImageTypeOption, setActiveImageTypeOption] = useState<string>(
    attachmentImage.imageTypeName ?? defaultImageDropdownText,
  )

  useEffect(() => {
    const setInitalSetup = async () => {
      if (isMounted.current) {
        //this is needed in case the image type being loaded is xray, we need to show orientation
        if (imageType && imageType.imageTypeId > 0) {
          await setIsXrayType(imageType.isXray)
          if (!imageType.isXray) {
            setOrientation(ImageOrientationType.None)
          }
        }

        ;() => {
          isMounted.current = false
        }
      }

      if (!originalImg) {
        const origImg = new Image()
        origImg.src = getUrlForAttachment(attachmentImage)
        origImg.onload = () => {
          croppedImageRef.current = origImg
          setOriginalImg(origImg)
          setImgForCanvas(origImg)
        }
      }

      keyId.current = (await (keyId.current === 0)) ? 1 : 0
    }

    setInitalSetup()
  }, [])

  function saveData() {
    if (
      activeImageTypeOption === defaultImageDropdownText ||
      activeImageTypeOption === ''
    ) {
      showMessage('Image type is required', 'error')
      return
    }

    if (isXrayType && !imageDate) {
      showMessage('Date taken is required', 'error')
      return
    }

    if (isXrayType && orientation == null) {
      showMessage('Orientation is required', 'error')
      return
    }

    // Update attachmentImage with changes
    attachmentImage.imageDateTaken = imageDate
    attachmentImage.imageTypeName = imageType && imageType.imageTypeName
    attachmentImage.imageTypeId = imageType && imageType.imageTypeId
    attachmentImage.imageOrientationType = orientation
    if (currentCanvasBlobRef.current) {
      attachmentImage.imageData = new File(
        [currentCanvasBlobRef.current],
        attachmentImage.imageData.name,
        { type: attachmentImage.imageData.type },
      )
    }

    storeAttachmentImageLocal(attachmentImage, false)
  }

  function updateCurrentCanvasImageRef(newCanvasImage: Blob) {
    currentCanvasBlobRef.current = newCanvasImage
  }

  function updateCroppedImg(newCroppedImg: HTMLImageElement) {
    croppedImageRef.current = newCroppedImg
  }

  return (
    <div id='attachment-form'>
      <h3 className='mb-150 fs-125 attachment-image-detail-title'>
        Image Details
      </h3>
      <Row
        gutter={[24, 0]}
        className='attachment-image-detail-container'
      >
        <Col
          xl={6}
          className='edit-image-left-ctrl'
        >
          <ImageTypeDropDown
            setImageType={setImageType}
            setIsXrayType={setIsXrayType}
            setOrientation={setOrientation}
            activeImageTypeOption={activeImageTypeOption}
            setActiveImageTypeOption={setActiveImageTypeOption}
            defaultImageDropdownText={defaultImageDropdownText}
            orientation={orientation}
          />
          {isXrayType && (
            <XRayOrientation
              setOrientation={setOrientation}
              orientation={orientation}
            />
          )}
          <ImageDateTaken
            setImageDate={setImageDate}
            imageDate={imageDate}
          />
          <EditImage
            attachmentImage={attachmentImage}
            keyId={keyId}
            operations={operations}
            setOperations={setOperations}
            setImgForCanvas={setImgForCanvas}
            croppedImageRef={croppedImageRef}
          />
        </Col>
        <Col xl={18}>
          {overMaxSize == false ? (
            <AttachmentImageDisplay
              imageData={attachmentImage.imageData}
              key={keyId.current.toString()}
              performOperations={operations}
              imgForCanvas={imgForCanvas}
              setImgForCanvas={setImgForCanvas}
              cropImgCallback={updateCroppedImg}
              currentCanvasToBlobCallback={updateCurrentCanvasImageRef}
            >
              <SaveImage
                overMaxSize={overMaxSize}
                storeAttachmentImageLocal={storeAttachmentImageLocal}
                saveData={saveData}
              />
            </AttachmentImageDisplay>
          ) : (
            <SaveImage
              overMaxSize={overMaxSize}
              storeAttachmentImageLocal={storeAttachmentImageLocal}
              saveData={saveData}
            />
          )}
        </Col>
      </Row>
    </div>
  )
}

export default AttachmentImageDetails
