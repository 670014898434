import { EllipsisOutlined, LoadingOutlined } from '@ant-design/icons'
import { ObservableBoolean } from '@legendapp/state'
import { observer, useObservable } from '@legendapp/state/react'
import { FC, useEffect } from 'react'
import { createRoot } from 'react-dom/client'

import styles from './RowActionsMenu.module.scss'

interface RowActionsMenuProps {
  index: number
  actions: ActionItem[]
  isActionCalledFromTable$: ObservableBoolean
}

export interface ActionItem {
  clickHandler: () => Promise<boolean | void>
  icon: JSX.Element
  label: string
  isVisible: boolean
  requiresTableRefresh?: boolean
}

export const RowActionsMenu: FC<RowActionsMenuProps> = ({
  index,
  actions,
  isActionCalledFromTable$,
}) => {
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      const actionsMenu = document.getElementById(`row-actions__menu--${index}`)
      if (actionsMenu) actionsMenu.style.display = 'none'
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const handleOutsideClick = ({ target }: MouseEvent): void => {
    const actionsMenu = document.getElementById(`row-actions__menu--${index}`)
    const actionsTrigger = document.getElementById(
      `row-actions__trigger--${index}`,
    )

    if (
      actionsTrigger &&
      !actionsTrigger.contains(target as Node) &&
      actionsMenu &&
      !actionsMenu.contains(target as Node)
    )
      handleCloseMenu()
  }

  const handleShowMenu = () => {
    const existingActionsMenu = document.getElementById(
      `row-actions__menu--${index}`,
    )
    if (existingActionsMenu) {
      handleCloseMenu()
      return
    }

    const antTable = document.querySelector('.ant-table-body')
    if (antTable) antTable.addEventListener('scroll', handleScroll)

    const bodyRect = document.body.getBoundingClientRect()
    const actionsTriggerRect = document
      .getElementById(`row-actions__trigger--${index}`)
      .getBoundingClientRect()
    const actionsMenuContainer = document.createElement('div')
    document.body.appendChild(actionsMenuContainer)

    const actionMenu = (
      <article
        className={styles['row-actions__menu']}
        id={`row-actions__menu--${index}`}
      >
        <ul className={styles['row-actions__menu-list']}>
          {actions.map((action: ActionItem) =>
            action.isVisible ? (
              <RowActionsMenuItem
                handleCloseMenu={handleCloseMenu}
                item={action}
                key={action.label}
                isActionCalledFromTable$={isActionCalledFromTable$}
              />
            ) : null,
          )}
        </ul>
        <span
          className={styles['row-actions__menu-carret']}
          id={`row-actions__menu-carret--${index}`}
        ></span>
      </article>
    )

    createRoot(actionsMenuContainer).render(actionMenu)
    requestIdleCallback(() => {
      const actionsMenu = document.getElementById(`row-actions__menu--${index}`)
      const actionsMenuCarret = document.getElementById(
        `row-actions__menu-carret--${index}`,
      )

      actionsMenu.style.display = 'block'
      actionsMenu.style.right = `${
        bodyRect.right - actionsTriggerRect.left + 12
      }px`

      if (
        document.body.offsetHeight - actionsTriggerRect.bottom <
        actionsMenu.offsetHeight
      ) {
        actionsMenuCarret.style.top = 'unset'
        actionsMenuCarret.style.bottom = '1em'
        actionsMenu.style.top = `${
          actionsTriggerRect.top - actionsMenu.offsetHeight + 40
        }px`
      } else actionsMenu.style.top = `${actionsTriggerRect.top - 12}px`
    })
  }

  const handleScroll = () => {
    const actionsMenu = document.getElementById(`row-actions__menu--${index}`)
    if (actionsMenu) actionsMenu.remove()
  }

  const handleCloseMenu = () => {
    const antTable = document.querySelector('.ant-table-body')
    if (antTable) antTable.removeEventListener('scroll', handleScroll)

    const actionsMenu = document.getElementById(`row-actions__menu--${index}`)
    if (actionsMenu) actionsMenu.remove()
  }

  return (
    <section
      aria-label='open row actions'
      className={styles['row-actions']}
      id={`row-actions__trigger--${index}`}
      onClick={() => handleShowMenu()}
    >
      <EllipsisOutlined
        rotate={90}
        style={{ fontSize: '1.5em' }}
      />
    </section>
  )
}

interface RowActionsMenuItemProps {
  handleCloseMenu: () => void
  item: ActionItem
  isActionCalledFromTable$: ObservableBoolean
}

const RowActionsMenuItem: FC<RowActionsMenuItemProps> = observer(
  ({ handleCloseMenu, item, isActionCalledFromTable$ }) => {
    const isPending$ = useObservable<boolean>(false)

    const handleClick = async () => {
      isPending$.set(true)
      await item.clickHandler()

      if (item.requiresTableRefresh) isActionCalledFromTable$.set(true)

      handleCloseMenu()
      isPending$.set(false)
    }

    return (
      <li
        className={styles['row-actions__menu-item']}
        onClick={handleClick}
      >
        <div className='flex items-center'>
          {item.icon}
          <p className='ml-100'>{item.label}</p>
        </div>
        {isPending$.get() && (
          <LoadingOutlined
            spin
            style={{ fontSize: '1em', color: 'var(--color-primary)' }}
          />
        )}
      </li>
    )
  },
)
