import { ClaimDetailsModel } from 'trellis:api/claim/claim-client'

export const initialClaim: ClaimDetailsModel = {
  ACCIDENT_DATE: null,
  ACCIDENT_ST: null,
  ACCIDENT_TYPE: '',
  ACCOUNT_TIN: '',
  APPLIANCE_DATE: null,
  APPLIANCE_DATE_String: '',
  APP_CLAIM_ID: 0,
  APP_GUID: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  ASSIGNMENT_SIGNATURE: '',
  ASSIGNMENT_SIGNATURE_DATE: '',
  ASSIGNMENT_SIGNATURE_DATE_String: '',
  ATTACHMENT_ID: null,
  ATTACHMENT_REF_ID: null,
  ATTACHMENT_STATUS: null,
  Accident_Date_String: '',
  AdjustmentAmount: '',
  AdjustmentGroup: '',
  AdjustmentReason: '',
  AttachmentCount: 0,
  BILLING_ADD_2: null,
  BILLING_ADD_4: null,
  BillingAddress: '',
  BillingCity: '',
  BillingLicense: '',
  BillingName: '',
  BillingPhone: '',
  BillingProviderId: null,
  BillingState: '',
  BillingZip: '',
  CARRIER_ADD_4: null,
  CARRIER_MATCH_ID: 0,
  CARRIER_METHOD: null,
  CARRIER_PERCENT: null,
  CAS_HOLD: false,
  CLAIM: null,
  CLAIM_ID: 0,
  CLAIM_SOURCE: null,
  CLAIM_SOURCE_ID: null,
  CLEARINGHOUSE_CLAIM_ID: null,
  CLIENT_REJECT_REASON_1: null,
  CLIENT_STATUS: null,
  COB_AMOUNT: '',
  COB_CARRIER_ADDRESS: null,
  COB_CARRIER_CITY: null,
  COB_CARRIER_ST: null,
  COB_CARRIER_ZIP: null,
  COB_DOB_String: '',
  COB_EMPLOYER: null,
  COB_EMPLOYER_ADD: null,
  COB_RELATIONSHIP: null,
  COB_SEX: null,
  CUSTOMER_ID: 0,
  Carrier: '',
  CarrierAddress: '',
  CarrierCity: '',
  CarrierId: '',
  CarrierState: '',
  CarrierZip: '',
  ClaimDetailsString: null,
  Clear_Attachment_Requirements: false,
  CobBirthDate: null,
  CobCarrier: null,
  CobFlag: '',
  CobGroup: null,
  CobName: null,
  CobSsn: null,
  CodeListQualifier: null,
  DEDUCTIBLE: null,
  DENTAL: null,
  DIFFICULTY: null,
  DOS: null,
  DiagCodeA: null,
  DiagCodeB: null,
  DiagCodeC: null,
  DiagCodeD: null,
  EOBAvailable: false,
  EOB_HTML: null,
  EPSDT: null,
  ERA_HTML: null,
  Enqueue: null,
  FIRST_VISIT_DATE: null,
  FIRST_VISIT_DATE_String: '',
  FeeTotal: '',
  HAS_835: 0,
  HAS_ATTACHMENT: 0,
  HDD_SERIAL_ID: '',
  HoldClaim: false,
  INJURY_DATE: null,
  INJURY_DATE_String: '',
  INJURY_FLAG: '',
  IS_EP_ELIGIBILE: false,
  IS_EP_ELIGIBLE: null,
  Id: 0,
  Ignore_Attachment_Requirements: false,
  Ignored: false,
  IndividualNpi: '',
  LAST_UPDATE_DATE: null,
  LEGACY_CLAIM_ID: 0,
  LineItems: [],
  MARITAL_STATUS: '',
  MAX_ALLOWABLE: null,
  MEDICAID: null,
  MEDICAL: null,
  NEA_ATTACHMENT_ID: null,
  NEA_FACILITY_ID: '',
  NpiGroup: '',
  ORTHO_FLAG: '',
  PARTNER_ID: null,
  PATIENT_PAYS: null,
  PAT_ADD_4: null,
  PAT_DOB_String: '',
  PAT_EMPLOYER: null,
  PAT_EMPLOYER_ADD: null,
  PAT_ID: null,
  PAT_SEX: '',
  PAYER_CLAIM_ID: null,
  PERIO_TYPE: null,
  PLACE_OF_TREATMENT: '',
  PRE_D: '',
  PRIOR_AUTH: null,
  PRIOR_PLACEMENT_DATE: '',
  PRIOR_PLACEMENT_DATE_String: '',
  PROCESSED_DATE: null,
  PROSTHESIS_INITIAL_PLACEMENT: null,
  PROSTHESIS_REPLACEMENT_REASON: null,
  PatientAddress: '',
  PatientBirthDate: '',
  PatientCity: '',
  PatientLiability: '',
  PatientName: '',
  PatientPhone: '',
  PatientState: '',
  PatientZip: '',
  PaymentDate: '',
  Process: false,
  Queued: 1,
  RAD_NO: '',
  RAD_NUMBER: null,
  RAD_YES: null,
  REJECT_REASON_1: null,
  REJECT_REASON_2: null,
  REJECT_REASON_3: null,
  RELATIONSHIP: '',
  REL_OTHER_DESC: null,
  REMARKS_2: null,
  REMARKS_3: null,
  REMARKS_4: null,
  Remarks: '',
  Resend: false,
  SEND_METHOD1: null,
  SEND_METHOD2: null,
  SIGNATURE: '',
  SIGNATURE_DATE: '',
  SIGNATURE_DATE_String: '',
  SPECIALTY: '',
  STATUS: '',
  STATUS_CHANGE: null,
  SUB_ADD_4: null,
  SUB_DOB_String: '',
  SUB_EMPLOYED: null,
  SUB_EMPLOYER: null,
  SUB_EMPLOYER_SCHOOL: null,
  SUB_EMPLOYER_SCHOOL_ADD: null,
  SUB_FT_STUDENT: null,
  SUB_PART_TIME: null,
  SUB_PHONE: '',
  SUB_PT_STUDENT: null,
  SUB_SEX: '',
  SURGERY_DATE: null,
  Sent: false,
  StatusDescription: null,
  StatusDescriptionTooltip: null,
  StatusHistory: [],
  StatusInformation: null,
  StatusTooltip: '',
  Status_Description_Tooltip_Id: 0,
  Status_Tooltip_Id: 0,
  SubscriberAddress: '',
  SubscriberBirthDate: '',
  SubscriberCity: '',
  SubscriberGroup: '',
  SubscriberName: '',
  SubscriberSsn: '',
  SubscriberState: '',
  SubscriberZip: '',
  TRANSMIT_DATE: '',
  TRANSMIT_DATE_STRING: '',
  TREATING_SIGNATURE_DATE: '',
  TREATING_SIGNATURE_DATE_String: '',
  TREATMENT_MONTHS: '',
  TREAT_ORTHO: '',
  TREAT_TMJ: null,
  Tin: '',
  TreatingLicense: '',
  TreatingName: '',
  TreatmentAddress: '',
  TreatmentCity: '',
  TreatmentProviderId: '',
  TreatmentState: '',
  TreatmentZip: '',
  USER_ID: 0,
  USER_TYPE: '',
  Username: null,
  WEEK_ID: 0,
  WTF_1: null,
  X12_LOG_ID: 0,
  YEAR_ID: 0,
}
