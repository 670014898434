export const BatchActions = Object.freeze({
  SEND: 'Send',
  HOLD: 'Hold',
  RELEASE: 'Release',
  IGNORE_WARNINGS: 'Ignore Warning(s)',
  DELETE: 'Delete',
  HIDE: 'Hide',
  UNHIDE: 'Unhide',
})

export const RowActions = Object.freeze({
  SEND: 'Send One',
  ADD_ATTACHMENT: 'Add Attachment',
  IGNORE_REQUIREMENT: 'Ignore Requirement',
  HOLD: 'Hold',
  RELEASE: 'Release',
  DELETE: 'Delete',
})
