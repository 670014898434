import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useState,
} from 'react'

export type Message = {
  attachments: []
  claimId: string
  customerID: string
  ignored: boolean
  messageBody: string
  messageCategory: string
  messageDate: string
  messageDeleted: boolean
  messageFrom: string
  messageID: number
  messageRead: boolean
  messageSubject: string
  neaAttachmentId: string
}

type MessageContext = {
  message: Message
  setMessage: (message: Message) => void
  dateFormat: string
}

const MessageContext = createContext({} as MessageContext)

export const MessageContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [message, setMessage] = useState<Message | null>(null)

  const dateFormat = 'MM/dd/yyyy h:mm a'

  return (
    <MessageContext.Provider
      value={{
        // state
        message,
        setMessage,

        // constants
        dateFormat,
      }}
    >
      {children}
    </MessageContext.Provider>
  )
}

export const useMessageContext = (): MessageContext => {
  const context = useContext(MessageContext)

  return context
}
