import {
  observer,
  useObservable,
  useObserveEffect,
} from '@legendapp/state/react'

import { LogError } from 'utils'

import AddProviderModal from 'trellis:components/MyPractice/tabs/PracticeInfo/ProviderList/AddProviderModal/AddProviderModal'
import {
  ProviderDropdownOption,
  ProviderFields,
  ProvidersAPI,
  sortProviders,
} from 'trellis:features/providers/utils/provider-helpers'

import { BillingControls } from '../tabs/provider/billingControls'
import { TreatingControls } from '../tabs/provider/treatingControls'
import styles from './ProviderContent.module.scss'

export const ProviderContent = observer(() => {
  const hasUpdatedProviders = useObservable<boolean>(false)
  const showAddProviderModal = useObservable<boolean>(false)
  const providerOptions = useObservable<ProviderDropdownOption[]>([])

  useObserveEffect(hasUpdatedProviders, () => {
    handleGetProviders()
  })

  const handleGetProviders = async () => {
    try {
      const { data } = await ProvidersAPI.getProviders()

      if (data.providers) {
        const sortedProviders = sortProviders(data.providers)

        providerOptions.set(
          sortedProviders.map((provider: ProviderFields, i: number) => ({
            key: i,
            value: `${provider.providerFirstName} ${provider.providerLastName} - NPI: ${provider.providerNPI}`,
            data: provider,
          })),
        )
      }
    } catch (err) {
      LogError(err, 'error getting providers')
    }
  }

  return (
    <>
      {showAddProviderModal.get() && (
        <AddProviderModal
          hasProviders={providerOptions.get().length > 0}
          hasUpdatedProviders={hasUpdatedProviders}
          isEditMode={false}
          isOpen={showAddProviderModal}
        />
      )}
      <div className={styles['claim-provider-content']}>
        <div className='page-section'>
          <BillingControls
            providerOptions={providerOptions}
            showAddProviderModal={showAddProviderModal}
          />
        </div>
        <div className='page-section'>
          <TreatingControls providerOptions={providerOptions} />
        </div>
      </div>
    </>
  )
})
