import { Button, Row } from 'antd'
import React, { FC, FormEvent } from 'react'

import { IAttachmentImage } from '../../../attachment/attachment.interface'
import { MaxAttachmentSizeError } from '../../constants'

type SaveImageProps = {
  overMaxSize: boolean
  storeAttachmentImageLocal?: (
    attachmentImage: IAttachmentImage,
    showDetailsView: boolean,
  ) => void
  saveData: () => void
}

const SaveImage: FC<SaveImageProps> = ({
  overMaxSize,
  storeAttachmentImageLocal,
  saveData,
}) => {
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    saveData()
  }

  return (
    <Row
      justify='end'
      className='mb-125'
    >
      <Button
        size='large'
        className='button-margin-right'
        onClick={() => storeAttachmentImageLocal(null, false)}
      >
        Cancel
      </Button>
      {overMaxSize == true && (
        <span className='error'>{MaxAttachmentSizeError}</span>
      )}
      {overMaxSize == false && (
        <Button
          size='large'
          type='primary'
          className='ml-100'
          onClick={handleSubmit}
        >
          Save Image
        </Button>
      )}
    </Row>
  )
}

export default SaveImage
