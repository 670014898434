import { FC, ReactNode } from 'react'

import vyneDentalLogo from 'trellis:assets/vyne-dental-logo.png'
import webexIcon from 'trellis:assets/webex.png'

const Footer: FC = (): ReactNode => {
  return (
    <footer className='pre-auth-footer'>
      <img
        className='pre-auth-footer__logo'
        src={vyneDentalLogo}
      />
      <p>
        <span>&copy;</span>
        <span>{new Date().getFullYear()} </span>
        <span>Napa EA/MEDX, LLC. All rights reserved.</span>
      </p>
      <p>
        Having trouble? Contact support at
        <a
          className='text-link ml-025'
          href='tel:(866)712-9584'
        >
          (866) 712-9584
        </a>
      </p>
      <p>
        <a
          className='text-link'
          href='https://vynedental.com/privacy/'
          rel='noreferrer'
          target='_blank'
        >
          Privacy
        </a>
        <span className='mr-025 ml-025'>|</span>
        <a
          className='text-link'
          href='https://vynedental.com/terms/'
          rel='noreferrer'
          target='_blank'
        >
          Terms
        </a>
      </p>
      <a
        className='text-link flex align-center gap-025 mt-050'
        href='https://vynedental.screenconnect.com/'
        id='webex-root'
        rel='noreferrer'
        target='_blank'
      >
        <img
          className='webex-icon'
          src={webexIcon}
        />
        Remote Support
      </a>
    </footer>
  )
}

export default Footer
