export type DescriptionData = {
  description: string
  descriptionOverrideID: number
  descriptionTooltipID: number
}
export const descriptions: DescriptionData[] = [
  {
    description: `Entity's Address`,
    descriptionOverrideID: 4,
    descriptionTooltipID: 6,
  },
  {
    description: `Patient not on file`,
    descriptionOverrideID: 59,
    descriptionTooltipID: 17,
  },
  {
    description: `Line Information`,
    descriptionOverrideID: 12,
    descriptionTooltipID: 7,
  },
  {
    description: `Procedure Code`,
    descriptionOverrideID: 68,
    descriptionTooltipID: 99,
  },
  {
    description: `Missing/Invalid NPI Number`,
    descriptionOverrideID: 63,
    descriptionTooltipID: 32,
  },
  {
    description: `Total Amount not Equal to total Charge`,
    descriptionOverrideID: 44,
    descriptionTooltipID: 95,
  },
  {
    description: `Subscriber and subscriber id not found`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Subscriber ID and/or Policy Number Missing/Invalid`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Entity's ID Number`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Service Charge Error`,
    descriptionOverrideID: 9,
    descriptionTooltipID: 2,
  },
  {
    description: `Submitted charges`,
    descriptionOverrideID: 9,
    descriptionTooltipID: 2,
  },
  {
    description: `Payer Does Not Allow 0 Charge Line Items`,
    descriptionOverrideID: 9,
    descriptionTooltipID: 2,
  },
  {
    description: `Surface Code Not Valid`,
    descriptionOverrideID: 54,
    descriptionTooltipID: 73,
  },
  {
    description: `CNP – Payer Only Accepts Claims for Members`,
    descriptionOverrideID: 26,
    descriptionTooltipID: 3,
  },
  {
    description: `CNP- Provider Not Registered (enrollment Form) for ECS`,
    descriptionOverrideID: 34,
    descriptionTooltipID: 10,
  },
  {
    description: `Printed and Mailed - No EDI Connection`,
    descriptionOverrideID: 1,
    descriptionTooltipID: 15,
  },
  {
    description: `Duplicate Claim`,
    descriptionOverrideID: 19,
    descriptionTooltipID: 16,
  },
  {
    description: `Entity not eligible for benefits for submitted dates of service`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 20,
  },
  {
    description: `Policy canceled`,
    descriptionOverrideID: 11,
    descriptionTooltipID: 20,
  },
  {
    description: `Claim Failed ADA Code Validation - See EOB`,
    descriptionOverrideID: 12,
    descriptionTooltipID: 61,
  },
  {
    description: `PP21000%`,
    descriptionOverrideID: 13,
    descriptionTooltipID: 36,
  },
  {
    description: `Claim Processing Error Claim was Submitted by Paper`,
    descriptionOverrideID: 14,
    descriptionTooltipID: 37,
  },
  {
    description: `CNP -`,
    descriptionOverrideID: 15,
    descriptionTooltipID: 38,
  },
  {
    description: `Secondary Claims Cannot Be Sent ECS`,
    descriptionOverrideID: 14,
    descriptionTooltipID: 97,
  },
  {
    description: `Claim form issue`,
    descriptionOverrideID: 17,
    descriptionTooltipID: 40,
  },
  {
    description: `Service Date Invalid`,
    descriptionOverrideID: 18,
    descriptionTooltipID: 41,
  },
  {
    description: `Duplicate of a previously processed claim/line`,
    descriptionOverrideID: 19,
    descriptionTooltipID: 16,
  },
  {
    description: `PROVIDER IS NOT WITHIN BC OF ID REGION TO SUBMIT EDI`,
    descriptionOverrideID: 20,
    descriptionTooltipID: 42,
  },
  {
    description: `Initial Orthodontic Claims Must be Mailed`,
    descriptionOverrideID: 21,
    descriptionTooltipID: 100,
  },
  {
    description: `Rendering License Number Missing`,
    descriptionOverrideID: 22,
    descriptionTooltipID: 43,
  },
  {
    description: `NPI Missing or Invalid`,
    descriptionOverrideID: 8,
    descriptionTooltipID: 32,
  },
  {
    description: `ACK/ACCEPT-ENTITY ACKNOWLEDGES RECEIPT OF CLAIM/ENCOUNTER`,
    descriptionOverrideID: 23,
    descriptionTooltipID: 85,
  },
  {
    description: `CARRIER ACKNOWLEDGES RECEIPT OF CLAIM`,
    descriptionOverrideID: 23,
    descriptionTooltipID: 85,
  },
  {
    description: `CARRIER ACKNOWLEDGES RECEIPT OF CLAIM, NO FURTHER UPDATES TO FOLLOW`,
    descriptionOverrideID: 23,
    descriptionTooltipID: 85,
  },
  {
    description: `CLAIM FORWARDED ELECTRONICALLY`,
    descriptionOverrideID: 60,
    descriptionTooltipID: null,
  },
  {
    description: `CLAIM HAS BEEN ACCEPTED FOR ADJUDICATION`,
    descriptionOverrideID: 23,
    descriptionTooltipID: 85,
  },
  {
    description: `CLAIM WAS ACCEPTED FOR PROCESSING`,
    descriptionOverrideID: 23,
    descriptionTooltipID: 85,
  },
  {
    description: `ERR  19 -ENTITY ACKNOWLEDGES RECEIPT OF CLAIM. ERR  19 -ENTITY ACKNOWLEDGES RECE`,
    descriptionOverrideID: 23,
    descriptionTooltipID: 85,
  },
  {
    description: `PROCESSED ACCORDING TO CONTRACT PROVISIONS`,
    descriptionOverrideID: 23,
    descriptionTooltipID: 85,
  },
  {
    description: `SAME DAY DUPLICATE CLAIM`,
    descriptionOverrideID: 19,
    descriptionTooltipID: 89,
  },
  {
    description: `EL00061 This procedure is not a benefit under the dental plan`,
    descriptionOverrideID: 64,
    descriptionTooltipID: 92,
  },
  {
    description: `Missing/Invalid Patient DOB%`,
    descriptionOverrideID: 37,
    descriptionTooltipID: 17,
  },
  {
    description: `Patient DOB missing/invalid-fix and resubmit`,
    descriptionOverrideID: 37,
    descriptionTooltipID: 17,
  },
  {
    description: `Payer Only Accepts Claims for Members`,
    descriptionOverrideID: 26,
    descriptionTooltipID: 3,
  },
  {
    description: `Mailing Required For Carrier Pre-Auths/Secondary Claims`,
    descriptionOverrideID: 53,
    descriptionTooltipID: 47,
  },
  {
    description: `MI01004%`,
    descriptionOverrideID: 47,
    descriptionTooltipID: 63,
  },
  {
    description: `CLAIM PRINTED AND MAILED%`,
    descriptionOverrideID: 30,
    descriptionTooltipID: 94,
  },
  {
    description: `Returned to Entity%`,
    descriptionOverrideID: 30,
    descriptionTooltipID: 94,
  },
  {
    description: `EL11017%`,
    descriptionOverrideID: 31,
    descriptionTooltipID: null,
  },
  {
    description: `MI22018%`,
    descriptionOverrideID: 63,
    descriptionTooltipID: 49,
  },
  {
    description: `Entity's health industry id number`,
    descriptionOverrideID: 43,
    descriptionTooltipID: 57,
  },
  {
    description: `MI22002%`,
    descriptionOverrideID: 43,
    descriptionTooltipID: 50,
  },
  {
    description: `Missing/Invalid COB Secondary ID`,
    descriptionOverrideID: 33,
    descriptionTooltipID: 51,
  },
  {
    description: `Provider not registered (enrollment form) for ECS`,
    descriptionOverrideID: 34,
    descriptionTooltipID: 10,
  },
  {
    description: `Missing/Invalid COB DOB%`,
    descriptionOverrideID: 35,
    descriptionTooltipID: 52,
  },
  {
    description: `For more detailed informatio%`,
    descriptionOverrideID: 36,
    descriptionTooltipID: null,
  },
  {
    description: `Entity's Street Address`,
    descriptionOverrideID: 50,
    descriptionTooltipID: 66,
  },
  {
    description: `Missing/Invalid Patient Address`,
    descriptionOverrideID: 37,
    descriptionTooltipID: 93,
  },
  {
    description: `Entity not eligible`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 20,
  },
  {
    description: `Patient eligibility not found with entity`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Missing/Invalid Subscriber ID`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Subscriber and policy number/contract number not found`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Entity's Last Name`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `AP13001%`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `AP13012%`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Entity's contract/member number`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Subscriber and policyholder name mismatched`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Subscriber and policyholder name not found`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Subscriber and subscriber id mismatched`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `SUBSCRIBER ID NOT ON FILE AS ENTERED`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `AP13000%`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 20,
  },
  {
    description: `AP16002%`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 20,
  },
  {
    description: `Entity not eligible for dental benefits for submitted dates of service`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 20,
  },
  {
    description: `AP15014%`,
    descriptionOverrideID: 40,
    descriptionTooltipID: 55,
  },
  {
    description: `AP15002%`,
    descriptionOverrideID: 19,
    descriptionTooltipID: 16,
  },
  {
    description: `Missing/Invalid tooth number or letter`,
    descriptionOverrideID: 42,
    descriptionTooltipID: 86,
  },
  {
    description: `Entity's date of birth`,
    descriptionOverrideID: 37,
    descriptionTooltipID: 53,
  },
  {
    description: `Regence claims must include carrier id in the Carrier Name field`,
    descriptionOverrideID: 45,
    descriptionTooltipID: 58,
  },
  {
    description: `Claim was sent without attachments`,
    descriptionOverrideID: 56,
    descriptionTooltipID: 59,
  },
  {
    description: `DISABLED`,
    descriptionOverrideID: 48,
    descriptionTooltipID: 60,
  },
  {
    description: `Patient/Subscriber Information Issue`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `AP13006%`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `INVALID SUBSCRIBER`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Entity's id number`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `INSURED ID NOT FOUND ON PAYOR FILE`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `INSURED ID NUMBER INVALID`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Claim submitted to incorrect payer`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `MISSING OR INVALID INFORMATION. ENTITY NOT FOUND. - PATIENT`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Subscriber ID Is Invalid`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `SUBSCRIBER AND POLICYHOLDER NAME NOT FOUND. - SUBSCRIBER`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `CNP - THIS PAYER NO LONGER ACCEPTS TRICARE CLAIMS VIA EDI/PAPER;CHK MEMBER CARD`,
    descriptionOverrideID: 3,
    descriptionTooltipID: 70,
  },
  {
    description: `CLM BCBSNE RULE THE SUBSCRIBER ID SUBMITTED IS NOT VALID FOR THE DATE OF SERVI`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Subscriber ID Cannot be all Zeros`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `INITIAL EDITS HAVE FAILED ON THIS CLAIM. SUBSCRIBER NOT FOUND USING SUBMITTED ID`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Member not found`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Entity's policy number`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `SSN/EMPLOYEE NOT FOUND-PLEASE CHECK ID CARD OR CONTACT CLAIM OFFICE`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `L-USE CIN OR BIC-NOT SSN`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `A9=PH LAST NAME & SSN DO NOT MATCH EDI DATA`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `ENTITYS NAME, ADDRESS, PHONE, GENDER, DOB, MARITAL STATUS, EMPLOYMENT STATUS AND`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `MISSING OR INVALID INFORMATION. PATIENT ELIGIBILITY NOT FOUND WITH ENTITY. - PAY`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Insured Name/Address Does Not Match Carrier Files`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Dependent not found`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Entity's address`,
    descriptionOverrideID: 4,
    descriptionTooltipID: 6,
  },
  {
    description: `INSURED ZIP INVALID`,
    descriptionOverrideID: 37,
    descriptionTooltipID: 93,
  },
  {
    description: `Dental Service Charge More than $999999`,
    descriptionOverrideID: 6,
    descriptionTooltipID: 79,
  },
  {
    description: `MORE THEN 8 SERVICE LINES`,
    descriptionOverrideID: 6,
    descriptionTooltipID: 77,
  },
  {
    description: `Tooth number or letter`,
    descriptionOverrideID: 6,
    descriptionTooltipID: 7,
  },
  {
    description: `Procedure Code 1 (D[0-9][0-9][0-9][0-9]) Not on File`,
    descriptionOverrideID: 68,
    descriptionTooltipID: 76,
  },
  {
    description: `Claim Does Not Have Any Procedure Codes`,
    descriptionOverrideID: 6,
    descriptionTooltipID: 31,
  },
  {
    description: `CNP- Entity's health industry id number`,
    descriptionOverrideID: 8,
    descriptionTooltipID: 67,
  },
  {
    description: `PA/GROUP NPI # REQUIRED OR NPI IS INVALID`,
    descriptionOverrideID: 8,
    descriptionTooltipID: 67,
  },
  {
    description: `BILLING PROVIDER NUMBER NOT ON FILE`,
    descriptionOverrideID: 8,
    descriptionTooltipID: 67,
  },
  {
    description: `RENDERING NPI IS REQUIRED`,
    descriptionOverrideID: 8,
    descriptionTooltipID: 67,
  },
  {
    description: `MI07004-1%`,
    descriptionOverrideID: 8,
    descriptionTooltipID: 67,
  },
  {
    description: `I0x8910014 -> BILL - THIS NPI IS NOT ENROLLED WITH SITE ID. PLEASE CONTACT CLEA`,
    descriptionOverrideID: 8,
    descriptionTooltipID: 67,
  },
  {
    description: `CNP- Payer Does Not Allow 0 Charge Line Items`,
    descriptionOverrideID: 9,
    descriptionTooltipID: 2,
  },
  {
    description: `AP15013%`,
    descriptionOverrideID: 9,
    descriptionTooltipID: 2,
  },
  {
    description: `Fee Total (000) Cannot Be Sent EDI`,
    descriptionOverrideID: 9,
    descriptionTooltipID: 2,
  },
  {
    description: `Amount must be greater than zero`,
    descriptionOverrideID: 9,
    descriptionTooltipID: 2,
  },
  {
    description: `INVALID FEE`,
    descriptionOverrideID: 9,
    descriptionTooltipID: 2,
  },
  {
    description: `TOTAL CLAIM CHARGES ARE ZERO`,
    descriptionOverrideID: 9,
    descriptionTooltipID: 2,
  },
  {
    description: `MISSING OR INVALID INFORMATION. POLICY CANCELED.`,
    descriptionOverrideID: 11,
    descriptionTooltipID: 20,
  },
  {
    description: `INVALID PROCEDURE CODE`,
    descriptionOverrideID: 12,
    descriptionTooltipID: 61,
  },
  {
    description: `Procedure code for services rendered`,
    descriptionOverrideID: 12,
    descriptionTooltipID: 61,
  },
  {
    description: `CNP- SAME DAY DUPLICATE CLAIM. Printed and Mailed`,
    descriptionOverrideID: 19,
    descriptionTooltipID: 16,
  },
  {
    description: `AP15032%`,
    descriptionOverrideID: 19,
    descriptionTooltipID: 16,
  },
  {
    description: `CNP- SAME DAY DUPLICATE CLAIM. Provider not regist`,
    descriptionOverrideID: 19,
    descriptionTooltipID: 16,
  },
  {
    description: `INITIAL EDITS HAVE FAILED ON THIS CLAIM. DUPLICATE CLAIM INFORMATION FOUND.`,
    descriptionOverrideID: 19,
    descriptionTooltipID: 16,
  },
  {
    description: `MISSING OR INVALID INFORMATION. -(HEW) THIS CLAIM IS A DUPLICATE OF A CLAIM OR P`,
    descriptionOverrideID: 19,
    descriptionTooltipID: 16,
  },
  {
    description: `MISSING OR INVALID INFORMATION. DUPLICATE OF A PREVIOUSLY PROCESSED CLAIM.`,
    descriptionOverrideID: 19,
    descriptionTooltipID: 88,
  },
  {
    description: `INITIAL EDITS HAVE FAILED ON THIS CLAIM. DUPLICATE CLAIM INFORMATION FOUND`,
    descriptionOverrideID: 19,
    descriptionTooltipID: 87,
  },
  {
    description: `EL27259%`,
    descriptionOverrideID: 64,
    descriptionTooltipID: 68,
  },
  {
    description: `EL12519%`,
    descriptionOverrideID: 24,
    descriptionTooltipID: 69,
  },
  {
    description: `EL96004%`,
    descriptionOverrideID: 24,
    descriptionTooltipID: 71,
  },
  {
    description: `Payer Does Not Accept Pre-D's Electronically`,
    descriptionOverrideID: 14,
    descriptionTooltipID: 97,
  },
  {
    description: `Drop to paper claim`,
    descriptionOverrideID: 14,
    descriptionTooltipID: 94,
  },
  {
    description: `EL11005%`,
    descriptionOverrideID: 31,
    descriptionTooltipID: null,
  },
  {
    description: `EL11207%`,
    descriptionOverrideID: 31,
    descriptionTooltipID: null,
  },
  {
    description: `EL11030%`,
    descriptionOverrideID: 31,
    descriptionTooltipID: null,
  },
  {
    description: `EL11021%`,
    descriptionOverrideID: 31,
    descriptionTooltipID: null,
  },
  {
    description: `COB Info Missing/Invalid`,
    descriptionOverrideID: 33,
    descriptionTooltipID: 51,
  },
  {
    description: `CNP- Entity not approved as an electronic submitter`,
    descriptionOverrideID: 34,
    descriptionTooltipID: 90,
  },
  {
    description: `Missing/Invalid Subscriber DOB`,
    descriptionOverrideID: 37,
    descriptionTooltipID: 53,
  },
  {
    description: `DEPENDENT NOT COVERED`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Patient Not Covered`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `No Coverage for Service Dates Provided`,
    descriptionOverrideID: 58,
    descriptionTooltipID: 17,
  },
  {
    description: `Treating Provider Info Mismatch`,
    descriptionOverrideID: 43,
    descriptionTooltipID: 96,
  },
  {
    description: `Office location does not match-Provider not found`,
    descriptionOverrideID: 43,
    descriptionTooltipID: 50,
  },
  {
    description: `PROVIDER NAME MISMATCH`,
    descriptionOverrideID: 43,
    descriptionTooltipID: 50,
  },
  {
    description: `Other Entity's Adjudication or Payment/Remittance Date`,
    descriptionOverrideID: 47,
    descriptionTooltipID: 63,
  },
  {
    description: `Other Carrier Claim filing indicator is missing or invalid`,
    descriptionOverrideID: 47,
    descriptionTooltipID: 63,
  },
  {
    description: `CNP- PROVIDER TAX ID MUST BE NINE DIGITS Printed a`,
    descriptionOverrideID: 48,
    descriptionTooltipID: 75,
  },
  {
    description: `CNP- PROVIDER TAX ID MUST BE NINE DIGITS Initial O`,
    descriptionOverrideID: 48,
    descriptionTooltipID: 75,
  },
  {
    description: `Group Number Required`,
    descriptionOverrideID: 49,
    descriptionTooltipID: 65,
  },
  {
    description: `Grp Number Failed Payer Edit`,
    descriptionOverrideID: 49,
    descriptionTooltipID: 65,
  },
  {
    description: `GROUP ID REQUIRED`,
    descriptionOverrideID: 49,
    descriptionTooltipID: 65,
  },
  {
    description: `POB is not allowed as the only provider address`,
    descriptionOverrideID: 50,
    descriptionTooltipID: 66,
  },
  {
    description: `COB Subscriber ID is Missing/Invalid`,
    descriptionOverrideID: 51,
    descriptionTooltipID: 72,
  },
  {
    description: `PP80020%`,
    descriptionOverrideID: 52,
    descriptionTooltipID: 78,
  },
  {
    description: `Orthodontics treatment plan`,
    descriptionOverrideID: 52,
    descriptionTooltipID: 78,
  },
  {
    description: `PRE-DETERMINATION CLAIMS CANNOT BE SENT ECS`,
    descriptionOverrideID: 53,
    descriptionTooltipID: 47,
  },
  {
    description: `Mailing Required For Carrier Pre-Auths`,
    descriptionOverrideID: 53,
    descriptionTooltipID: 47,
  },
  {
    description: `Printed and Mailed - No Connection for Attachment(s)`,
    descriptionOverrideID: null,
    descriptionTooltipID: 74,
  },
  {
    description: `Please check your ERA for reject reason`,
    descriptionOverrideID: null,
    descriptionTooltipID: 62,
  },
  {
    description: `No provider match found`,
    descriptionOverrideID: 50,
    descriptionTooltipID: 50,
  },
  {
    description: `Missing/Invalid Insurance address`,
    descriptionOverrideID: 61,
    descriptionTooltipID: 64,
  },
  {
    description: `AP14001%`,
    descriptionOverrideID: 65,
    descriptionTooltipID: 62,
  },
  {
    description: `ADA Claim Form Issue`,
    descriptionOverrideID: 67,
    descriptionTooltipID: 98,
  },
  {
    description: `Please check your EOB for additional details`,
    descriptionOverrideID: 66,
    descriptionTooltipID: 62,
  },
  {
    description: `MI07006%`,
    descriptionOverrideID: 68,
    descriptionTooltipID: 76,
  },
  {
    description: `Carrier requires secondary claims to be mailed`,
    descriptionOverrideID: 57,
    descriptionTooltipID: 80,
  },
  {
    description: `TIN VALIDATION HOLD`,
    descriptionOverrideID: 48,
    descriptionTooltipID: 83,
  },
  {
    description: `INVALID SEGMENT - NM1R (treating provider information missing)`,
    descriptionOverrideID: 62,
    descriptionTooltipID: 91,
  },
  {
    description: `--PAYER REJECTION--THE TRANSACTION HAS BEEN REJECTED AND HAS`,
    descriptionOverrideID: 66,
    descriptionTooltipID: 62,
  },
  {
    description: `Other Coverage Detected`,
    descriptionOverrideID: 70,
    descriptionTooltipID: 102,
  },
  {
    description: `Add Other Coverage Requirements`,
    descriptionOverrideID: 69,
    descriptionTooltipID: 101,
  },
  {
    description: `Carrier not recognized`,
    descriptionOverrideID: 71,
    descriptionTooltipID: 104,
  },
]

export type DescriptionOverrideData = {
  descriptionOverrideID: number
  descriptionOverride: string
}
export const descriptionOverrides: DescriptionOverrideData[] = [
  {
    descriptionOverrideID: 23,
    descriptionOverride: 'Accepted for processing',
  },
  {
    descriptionOverrideID: 9,
    descriptionOverride: `$0 items not accepted by carrier`,
  },
  {
    descriptionOverrideID: 12,
    descriptionOverride: `CDT Code mismatch`,
  },
  {
    descriptionOverrideID: 13,
    descriptionOverride: `Frequency limitation - check EOB`,
  },
  {
    descriptionOverrideID: 14,
    descriptionOverride: `Unable to submit electronically - mailed to carrier`,
  },
  {
    descriptionOverrideID: 15,
    descriptionOverride: `Lost carrier connectivity. Please mail directly to carrier.`,
  },
  {
    descriptionOverrideID: 16,
    descriptionOverride: `Carrier does not accept electronic submission for secondary claims`,
  },
  {
    descriptionOverrideID: 17,
    descriptionOverride: `ADA claim form mismatch`,
  },
  {
    descriptionOverrideID: 18,
    descriptionOverride: `Date of Service (DOS) Issue`,
  },
  {
    descriptionOverrideID: 19,
    descriptionOverride: `Duplicate Claim`,
  },
  {
    descriptionOverrideID: 20,
    descriptionOverride: `Geographic Area Issue: Cannot send electronically`,
  },
  {
    descriptionOverrideID: 49,
    descriptionOverride: `Group Number Mismatch`,
  },
  {
    descriptionOverrideID: 21,
    descriptionOverride: `Initial Orthodontic Claims Must be Mailed`,
  },
  {
    descriptionOverrideID: 54,
    descriptionOverride: `Invalid Surface Code`,
  },
  {
    descriptionOverrideID: 48,
    descriptionOverride: `Tax ID error`,
  },
  {
    descriptionOverrideID: 22,
    descriptionOverride: `License number mismatch`,
  },
  {
    descriptionOverrideID: 53,
    descriptionOverride: `Mailing Required for Pre-Auths/Secondary Claims`,
  },
  {
    descriptionOverrideID: 52,
    descriptionOverride: `Monthly Ortho Claim not required`,
  },
  {
    descriptionOverrideID: 8,
    descriptionOverride: `NPI needed from provider`,
  },
  {
    descriptionOverrideID: 50,
    descriptionOverride: `Provider demographic mismatch`,
  },
  {
    descriptionOverrideID: 24,
    descriptionOverride: `Adult fluoride not benefited`,
  },
  {
    descriptionOverrideID: 25,
    descriptionOverride: `Patient/Subscriber DOB Issue`,
  },
  {
    descriptionOverrideID: 26,
    descriptionOverride: `Carrier only accepts claims for subscriber`,
  },
  {
    descriptionOverrideID: 11,
    descriptionOverride: `Coverage not in effect`,
  },
  {
    descriptionOverrideID: 27,
    descriptionOverride: `Pre-Auth/Secondary Claims cannot be submitted electronically`,
  },
  {
    descriptionOverrideID: 28,
    descriptionOverride: `Primary payment amount issue`,
  },
  {
    descriptionOverrideID: 30,
    descriptionOverride: `Printed and Mailed`,
  },
  {
    descriptionOverrideID: 44,
    descriptionOverride: `Procedure Fees not equal to Total`,
  },
  {
    descriptionOverrideID: 7,
    descriptionOverride: `Procedure code issue - none found`,
  },
  {
    descriptionOverrideID: 6,
    descriptionOverride: `Procedure Information Issue`,
  },
  {
    descriptionOverrideID: 31,
    descriptionOverride: `Prophylaxes (cleanings) are payable twice per calendar year`,
  },
  {
    descriptionOverrideID: 32,
    descriptionOverride: `Provider Info Issue: NPI`,
  },
  {
    descriptionOverrideID: 43,
    descriptionOverride: `Provider Information Issue`,
  },
  {
    descriptionOverrideID: 45,
    descriptionOverride: `Regence claims must include carrier id in the Carrier Name field.`,
  },
  {
    descriptionOverrideID: 10,
    descriptionOverride: `Registration Issue`,
  },
  {
    descriptionOverrideID: 34,
    descriptionOverride: `Enrollment Required`,
  },
  {
    descriptionOverrideID: 1,
    descriptionOverride: `Carrier does not accept electronic claims`,
  },
  {
    descriptionOverrideID: 51,
    descriptionOverride: `Additional Coverage info issue`,
  },
  {
    descriptionOverrideID: 33,
    descriptionOverride: `Additional Coverage info issue`,
  },
  {
    descriptionOverrideID: 35,
    descriptionOverride: `Additional Coverage info issue`,
  },
  {
    descriptionOverrideID: 47,
    descriptionOverride: `Additional Coverage Detected`,
  },
  {
    descriptionOverrideID: 36,
    descriptionOverride: `See EOB`,
  },
  {
    descriptionOverrideID: 4,
    descriptionOverride: `Incomplete address fields`,
  },
  {
    descriptionOverrideID: 37,
    descriptionOverride: `Patient demographic info verification needed`,
  },
  {
    descriptionOverrideID: 38,
    descriptionOverride: `Subscriber/Patient Eligibility Issue`,
  },
  {
    descriptionOverrideID: 39,
    descriptionOverride: `Subscriber/Patient Info Issue: Last Name`,
  },
  {
    descriptionOverrideID: 3,
    descriptionOverride: `Carrier no longer accepts electronic claims`,
  },
  {
    descriptionOverrideID: 5,
    descriptionOverride: `Subscriber/patient not eligible for benefits for submitted dates of service`,
  },
  {
    descriptionOverrideID: 55,
    descriptionOverride: `Tax ID not associated to account`,
  },
  {
    descriptionOverrideID: 40,
    descriptionOverride: `Yearly benefit reached`,
  },
  {
    descriptionOverrideID: 2,
    descriptionOverride: `The system detected that an identical claim has previously been sent`,
  },
  {
    descriptionOverrideID: 41,
    descriptionOverride: `This service has been paid or processed on a previous claim`,
  },
  {
    descriptionOverrideID: 46,
    descriptionOverride: `TIN has been disabled`,
  },
  {
    descriptionOverrideID: 42,
    descriptionOverride: `Carrier reports tooth number mismatch`,
  },
  {
    descriptionOverrideID: 56,
    descriptionOverride: `Carrier does not accept attachments. Claim forwarded without attachments.`,
  },
  {
    descriptionOverrideID: 57,
    descriptionOverride: `Carrier only accepts secondary claims and attachments by mail.`,
  },
  {
    descriptionOverrideID: 58,
    descriptionOverride: `Carrier reports patient ineligibility`,
  },
  {
    descriptionOverrideID: 59,
    descriptionOverride: `Carrier reports patient not on file. Confirm demographic information.`,
  },
  {
    descriptionOverrideID: 60,
    descriptionOverride: `Claim Sent Electronically`,
  },
  {
    descriptionOverrideID: 61,
    descriptionOverride: `Incomplete carrier information`,
  },
  {
    descriptionOverrideID: 62,
    descriptionOverride: `Invalid Treating/Billing Info`,
  },
  {
    descriptionOverrideID: 63,
    descriptionOverride: `NPI number mismatch`,
  },
  {
    descriptionOverrideID: 64,
    descriptionOverride: `One or more procedures not covered`,
  },
  {
    descriptionOverrideID: 65,
    descriptionOverride: `Processing/rejection reasons found on EOB/ERA`,
  },
  {
    descriptionOverrideID: 66,
    descriptionOverride: `Processing/rejection reasons found on ERA`,
  },
  {
    descriptionOverrideID: 67,
    descriptionOverride: `Updated ADA claim form needed`,
  },
  {
    descriptionOverrideID: 68,
    descriptionOverride: `Valid CDT code needed`,
  },
  {
    descriptionOverrideID: 69,
    descriptionOverride: 'Add Additional Coverage Requirements',
  },
  {
    descriptionOverrideID: 70,
    descriptionOverride: 'Additional Coverage Detected',
  },
  {
    descriptionOverrideID: 71,
    descriptionOverride: 'Carrier not recognized',
  },
]

export type DescriptionTooltipData = {
  descriptionTooltipID: number
  descriptionTooltip: string
}
export const descriptionTooltips: DescriptionTooltipData[] = [
  {
    descriptionTooltipID: 104,
    descriptionTooltip: `Pending Carrier identification, verify the carrier name and address on the Patient Tab.`,
  },
  {
    descriptionTooltipID: 103,
    descriptionTooltip: `The Tax ID associated to this claim has been disabled. Please call Vyne (formerly RES) for help.`,
  },
  {
    descriptionTooltipID: 102,
    descriptionTooltip: `If this claim is a secondary or tertiary claim add additional coverage details on the procedures tab`,
  },
  {
    descriptionTooltipID: 101,
    descriptionTooltip: `Add Additional Coverage Requirements on the procedures tab`,
  },
  {
    descriptionTooltipID: 100,
    descriptionTooltip: `Claim must be printed and sent to the carrier.`,
  },
  {
    descriptionTooltipID: 99,
    descriptionTooltip: `In order to submit a claim, one or more procedure codes are required.`,
  },
  {
    descriptionTooltipID: 98,
    descriptionTooltip: `Verify the ADA Claim form version/year used to submit this claim.`,
  },
  {
    descriptionTooltipID: 97,
    descriptionTooltip: `This carrier requires secondary claims to be mailed. This claim was printed and mailed on your behalf.`,
  },
  {
    descriptionTooltipID: 96,
    descriptionTooltip: `This carrier cannot match the provided NPI for the treating dentist. Please resubmit the treating dentist's correct NPI Type 1, or if you believe the number submitted is correct, contact the carrier so your record may be updated.`,
  },
  {
    descriptionTooltipID: 95,
    descriptionTooltip: `The procedure fees listed do not equal the Total Amount of the submitted claim.`,
  },
  {
    descriptionTooltipID: 94,
    descriptionTooltip: `This claim was printed and mailed on your behalf.`,
  },
  {
    descriptionTooltipID: 93,
    descriptionTooltip: `The patient or subscriber's address is missing or incomplete. If address appears valid, visit USPS.com and verify the zip code.`,
  },
  {
    descriptionTooltipID: 92,
    descriptionTooltip: `One or more procedures not covered`,
  },
  {
    descriptionTooltipID: 91,
    descriptionTooltip: `Verify that the treating & billing provider information submitted on this claim is correct and complete`,
  },
  {
    descriptionTooltipID: 90,
    descriptionTooltip: `This carrier requires some form of enrollment from the provider in order to submit electronically via a clearinghouse. Please contact the carrier about your NPI information.`,
  },
  {
    descriptionTooltipID: 89,
    descriptionTooltip: `Same Day Duplicate Claim`,
  },
  {
    descriptionTooltipID: 88,
    descriptionTooltip: `Duplicate of a previously processed claim.`,
  },
  {
    descriptionTooltipID: 87,
    descriptionTooltip: `Initial edits have failed, duplicate claim information found.`,
  },
  {
    descriptionTooltipID: 86,
    descriptionTooltip: `Verify the submitted tooth numbers/letters to ensure that they are all present and valid.`,
  },
  {
    descriptionTooltipID: 85,
    descriptionTooltip: `Carrier acknowledges receipt of claim`,
  },
  {
    descriptionTooltipID: 84,
    descriptionTooltip: `Claim has been placed on hold by your office and will not be sent until it has been released. To release this claim, click "Actions" and "Release"`,
  },
  {
    descriptionTooltipID: 61,
    descriptionTooltip: `A procedure code on this claim either is not a valid code or the carrier may have a requirement for a tooth code or an oral cavity code different than the information submitted. Click on the EOB icon in the "Details" column for more information about this claim.`,
  },
  {
    descriptionTooltipID: 76,
    descriptionTooltip: `A procedure code submitted on this claim is not a valid CDT code.`,
  },
  {
    descriptionTooltipID: 73,
    descriptionTooltip: `A surface code on this claim is invalid. Please resubmit with a valid surface code (M,D,L,B,O)`,
  },
  {
    descriptionTooltipID: 25,
    descriptionTooltip: `Claim submitted successfully for processing. No rejection was issued back from the carrier.`,
  },
  {
    descriptionTooltipID: 82,
    descriptionTooltip: `Claim has one or more errors, claim can be deleted or resolved prior to sending.`,
  },
  {
    descriptionTooltipID: 30,
    descriptionTooltip: `Claim is currently being processed. Check back later for status updates.`,
  },
  {
    descriptionTooltipID: 81,
    descriptionTooltip: `Claim has passed validation.`,
  },
  {
    descriptionTooltipID: 29,
    descriptionTooltip: `Claim was detected as a duplicate claim. To request resubmission contact support.`,
  },
  {
    descriptionTooltipID: 27,
    descriptionTooltip: `Claim was printed and mailed by Vyne (formerly RES). See status description for further detail.`,
  },
  {
    descriptionTooltipID: 28,
    descriptionTooltip: `Claim was rejected electronically; however it has been printed and mailed.`,
  },
  {
    descriptionTooltipID: 26,
    descriptionTooltip: `Claim was rejected, see status description for further detail. Claim should be corrected and resubmitted by the dental office.`,
  },
  {
    descriptionTooltipID: 59,
    descriptionTooltip: `This carrier does not accept electronic attachments. Claim was sent electronically without attachments.`,
  },
  {
    descriptionTooltipID: 62,
    descriptionTooltip: `Click on the EOB or ERA icon in the "Details" column for more information about the status of this claim.`,
  },
  {
    descriptionTooltipID: 68,
    descriptionTooltip: `Crowns containing porcelain or resin on posterior teeth are not a benefit of this dental plan. The allowance shown was established under the optional service provision in the dental plan`,
  },
  {
    descriptionTooltipID: 37,
    descriptionTooltip: `Due to an error in processing this claim, it was printed and mailed by Vyne (formerly RES) on your office's behalf.`,
  },
  {
    descriptionTooltipID: 69,
    descriptionTooltip: `Fluoride treatments are not covered for members over the age of 18.`,
  },
  {
    descriptionTooltipID: 36,
    descriptionTooltip: `For this carrier, Amalgam and composite resin restorations are payable to the same dentist or dental office once within a 24-month period, regardless of the number or combination of restorations placed on a surface. `,
  },
  {
    descriptionTooltipID: 31,
    descriptionTooltip: `One or more procedure codes are required.`,
  },
  {
    descriptionTooltipID: 78,
    descriptionTooltip: `It is not necessary to submit claims for monthly orthodontic treatment.`,
  },
  {
    descriptionTooltipID: 55,
    descriptionTooltip: `Once your patient has reached the benefit period maximum, the patient will generally be responsible for paying any costs for the remainder of the benefit period.`,
  },
  {
    descriptionTooltipID: 63,
    descriptionTooltip: `Additional Coverage appears to be present for this submission, please verify the  Additional Coverage information on the Procedures tab.`,
  },
  {
    descriptionTooltipID: 65,
    descriptionTooltip: `Verify a valid group number was submitted on this claim.`,
  },
  {
    descriptionTooltipID: 50,
    descriptionTooltip: `Verify that the provider information submitted on this claim is correct.`,
  },
  {
    descriptionTooltipID: 40,
    descriptionTooltip: `Verify the ADA Claim form version/year used to submit this claim matches the form setup with Vyne (formerly RES)`,
  },
  {
    descriptionTooltipID: 52,
    descriptionTooltip: `Additional Coverage appears to be present for this submission, please verify the  Additional Coverage information on the Procedures tab.`,
  },
  {
    descriptionTooltipID: 72,
    descriptionTooltip: `Verify the Additional Coverage Subscriber ID is valid.`,
  },
  {
    descriptionTooltipID: 41,
    descriptionTooltip: `Verify the date of service submitted is correct or that the patient was not covered by a different carrier on this date of service.`,
  },
  {
    descriptionTooltipID: 32,
    descriptionTooltip: `Verify the NPI number(s) submitted.`,
  },
  {
    descriptionTooltipID: 45,
    descriptionTooltip: `Please verify the Patient/Subscriber DOB submitted. `,
  },
  {
    descriptionTooltipID: 7,
    descriptionTooltip: `Verify the procedure line information submitted is valid. For example, some codes require tooth numbers vs. oral cavity codes.`,
  },
  {
    descriptionTooltipID: 53,
    descriptionTooltip: `Please verify the Subscriber/Patient date of birth (DOB) Information as it does not match the data expected by the carrier`,
  },
  {
    descriptionTooltipID: 17,
    descriptionTooltip: `Verify the Subscriber/Patient information submitted such as birthdates, Subscriber ID number, and spelling of their name(s).`,
  },
  {
    descriptionTooltipID: 54,
    descriptionTooltip: `Please verify the subscriber/patient information.  The insurance company was not able to match the subscriber/patient last name or other subscriber/patient info submitted.`,
  },
  {
    descriptionTooltipID: 8,
    descriptionTooltip: `Please verify the surface code(s) submitted are valid. `,
  },
  {
    descriptionTooltipID: 75,
    descriptionTooltip: `Verify the Tax ID number submitted on this claim.`,
  },
  {
    descriptionTooltipID: 43,
    descriptionTooltip: `Verify the treating/billing license number submitted.`,
  },
  {
    descriptionTooltipID: 71,
    descriptionTooltip: `Prescription drugs (except intramuscular injectable antibiotics), medicaments/solutions, including those utilized for desensitization and home care, premedications, and relative analgesia are not covered by the dental plan.`,
  },
  {
    descriptionTooltipID: 58,
    descriptionTooltip: `Regence claims share one PO BOX Address and multiple Carrier IDs. In order to ensure proper routing of your claim please include the Carrier ID in the Carrier Name Field on your claim form.`,
  },
  {
    descriptionTooltipID: 51,
    descriptionTooltip: `Additional Coverage appears to be present for this submission, please verify the  Additional Coverage information on the Procedures tab.`,
  },
  {
    descriptionTooltipID: 64,
    descriptionTooltip: `The insurance carrier's addresss is incomplete. Please verify the carrier name/addresss combination.`,
  },
  {
    descriptionTooltipID: 6,
    descriptionTooltip: `Verify the address is complete for Patient/Subscriber and Treating/Billing Provider and check for PO Box issues.`,
  },
  {
    descriptionTooltipID: 20,
    descriptionTooltip: `The patient was not eligible for service with this carrier on the date of service submitted. Please verify the patients coverage on this date of service and resubmit to the proper carrier.`,
  },
  {
    descriptionTooltipID: 16,
    descriptionTooltip: `This claim was detected as a duplicate. To request resubmisson contact support.`,
  },
  {
    descriptionTooltipID: 83,
    descriptionTooltip: `The Tax ID submitted on this claim is not associated with your account. To add the Tax ID to your account contact support.`,
  },
  {
    descriptionTooltipID: 60,
    descriptionTooltip: `The Tax ID associated to this claim has been disabled. Please contact Vyne (formerly RES).`,
  },
  {
    descriptionTooltipID: 48,
    descriptionTooltip: `This appears to be a secondary claim and this insurance company requires that you provide a primary payment amount in the procedure tab prior to submitting.`,
  },
  {
    descriptionTooltipID: 79,
    descriptionTooltip: `This carrier does not accept claims electronically with fee total amounts greater than $9,999. This claim can be resubmitted as two claims or must be mailed by the office.`,
  },
  {
    descriptionTooltipID: 15,
    descriptionTooltip: `This carrier does not accept electronic claims. Due to no electronic connectivity to this carrier, the claim was sent by mail on your behalf.`,
  },
  {
    descriptionTooltipID: 77,
    descriptionTooltip: `This carrier only allows 8 procedure lines to be submitted on an electronic claim. This claim should be split into multiple claims and resubmitted.`,
  },
  {
    descriptionTooltipID: 80,
    descriptionTooltip: `This carrier requires secondary claims to be printed and mailed with the primary EOB. The dental office may submit directly to the carrier via mail.`,
  },
  {
    descriptionTooltipID: 74,
    descriptionTooltip: `This claim and attachment(s) were printed and mailed because the attachment could not be sent electronically to this insurance company.`,
  },
  {
    descriptionTooltipID: 2,
    descriptionTooltip: `This claim has a zero dollar charge or has an incorrect fee total. Please correct and resubmit.`,
  },
  {
    descriptionTooltipID: 3,
    descriptionTooltip: `This claim has been rejected due to the relationship to the patient being something other than "self". For example, with most Medicaid plans a child has their own coverage therefore the relationship to subscriber will always be “self”.`,
  },
  {
    descriptionTooltipID: 49,
    descriptionTooltip: `This carrier cannot match the provided NPI for the treating dentist. Please resubmit the treating dentist's correct NPI Type 1, or if you believe the number submitted is correct, contact the carrier to update their records prior to resubmitting this claim`,
  },
  {
    descriptionTooltipID: 66,
    descriptionTooltip: `This carrier does not accept a PO Box as the only BIlling/Treating addresss. Please include your physical addresss in addition to the PO Box.`,
  },
  {
    descriptionTooltipID: 42,
    descriptionTooltip: `This carrier does not accept electronically submitted claims from offices located outside of their geographic area. This claim has been printed and mailed.`,
  },
  {
    descriptionTooltipID: 67,
    descriptionTooltip: `This carrier does not have your NPI number(s) on file. Please contact the carrier about your NPI information.`,
  },
  {
    descriptionTooltipID: 38,
    descriptionTooltip: `This carrier has requested Vyne (formerly RES) to not print and mail claims and we are unable to send the claim electonically due to no electronic attachment connectivity. Please send the claim by mail or resend it electronically without an attachment.`,
  },
  {
    descriptionTooltipID: 70,
    descriptionTooltip: `This carrier no longer accepts Tricare claims electronically or via paper. Please check the subscribers ID card and resubmit to the correct carrier.`,
  },
  {
    descriptionTooltipID: 47,
    descriptionTooltip: `This carrier only allows pre-auth and secondary claims to be submitted via mail. This claim has been printed and mailed.`,
  },
  {
    descriptionTooltipID: 39,
    descriptionTooltip: `This insurance company requires secondary claims to be mailed. This claim was printed and mailed.  `,
  },
  {
    descriptionTooltipID: 10,
    descriptionTooltip: `This carrier requires some form of enrollment from the provider in order to submit electronically via a clearinghouse. Locate enrollment requirements in Resources or contact support for assistance with enrolling.`,
  },
  {
    descriptionTooltipID: 57,
    descriptionTooltip: `This carrier requires your NPI to be on file, contact the carrier to ensure your NPI number(s) are on their file.`,
  },
]

export const getStatusOverride = (status: string): StatusMatch => {
  if (!status) return null

  const match = statuses.find(
    (item: StatusData) =>
      item.status.toLocaleLowerCase() === status?.toLocaleLowerCase(),
  )

  if (match) {
    return {
      Status: match.visibleStatus,
      StatusTooltip: getStatusTooltip(match.statusTooltipID),
    }
  }
  return null
}

const getStatusTooltip = (tooltipID?: number): string => {
  if (!tooltipID) return null

  const match = descriptionTooltips.find(
    (item: DescriptionTooltipData) => item.descriptionTooltipID === tooltipID,
  )

  if (match) return match.descriptionTooltip

  return null
}

export type StatusMatch = {
  Status: string
  StatusTooltip?: string
}

export type StatusData = {
  status: string
  visibleStatus: string
  statusTooltipID: number
}
export const statuses: StatusData[] = [
  {
    status: 'EDI Accepted',
    visibleStatus: 'Accepted',
    statusTooltipID: 25,
  },
  {
    status: 'EDI Rejected',
    visibleStatus: 'Rejected',
    statusTooltipID: 26,
  },
  {
    status: 'Printed',
    visibleStatus: 'Printed',
    statusTooltipID: 27,
  },
  {
    status: 'EDI Rejected/PRINTED',
    visibleStatus: 'Printed',
    statusTooltipID: 28,
  },
  {
    status: 'EDI Rejected/Duplicate',
    visibleStatus: 'Duplicate',
    statusTooltipID: 29,
  },
  {
    status: 'Payor Mapped',
    visibleStatus: 'In Process',
    statusTooltipID: 30,
  },
  {
    status: 'EDI Submission',
    visibleStatus: 'In Process',
    statusTooltipID: 30,
  },
  {
    status: 'TO PRINT',
    visibleStatus: 'In Process',
    statusTooltipID: 30,
  },
  {
    status: 'REJECTED',
    visibleStatus: 'Rejected',
    statusTooltipID: 26,
  },
  {
    status: 'EDI/Rejected',
    visibleStatus: 'Rejected',
    statusTooltipID: 26,
  },
  {
    status: 'Hold',
    visibleStatus: 'Hold',
    statusTooltipID: 84,
  },
  {
    status: 'PENDED',
    visibleStatus: 'In Process',
    statusTooltipID: 30,
  },
  {
    status: 'TESIART',
    visibleStatus: 'In Process',
    statusTooltipID: 30,
  },
  {
    status: 'TESIA',
    visibleStatus: 'In Process',
    statusTooltipID: 30,
  },
  {
    status: 'XML Loaded',
    visibleStatus: 'In Process',
    statusTooltipID: 30,
  },
  {
    status: 'Mapping Payor',
    visibleStatus: 'In Process',
    statusTooltipID: 30,
  },
  {
    status: 'To Print',
    visibleStatus: 'In Process',
    statusTooltipID: 30,
  },
  {
    status: 'STOPPED',
    visibleStatus: 'In Process',
    statusTooltipID: 30,
  },
  {
    status: 'STOP',
    visibleStatus: 'In Process',
    statusTooltipID: 30,
  },
  {
    status: 'Payor Not Mapped',
    visibleStatus: 'In Process',
    statusTooltipID: 30,
  },
  {
    status: 'Duplicate',
    visibleStatus: 'Duplicate',
    statusTooltipID: 29,
  },
  {
    status: 'DISABLED',
    visibleStatus: 'Disabled',
    statusTooltipID: 60,
  },
  {
    status: 'Failed Validation',
    visibleStatus: 'Failed',
    statusTooltipID: 82,
  },
  {
    status: 'IN QUEUE',
    visibleStatus: 'Ready',
    statusTooltipID: 81,
  },
  {
    status: 'TIN HOLD',
    visibleStatus: 'Tax ID Error',
    statusTooltipID: 103,
  },
  {
    status: 'Customer Hold',
    visibleStatus: 'Hold',
    statusTooltipID: 84,
  },
  {
    status: 'Other Coverage',
    visibleStatus: 'Additional Coverage',
    statusTooltipID: null,
  },
  {
    status: 'PENDING',
    visibleStatus: 'Pending',
    statusTooltipID: null,
  },
  {
    status: 'VALIDATION PENDING',
    visibleStatus: 'Validation Pending',
    statusTooltipID: null,
  },
  {
    status: 'Received',
    visibleStatus: 'Received by Vyne',
    statusTooltipID: null,
  },
  {
    status: 'Sent',
    visibleStatus: 'Claim Sent',
    statusTooltipID: null,
  },
]
