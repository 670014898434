import { computed } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { FC } from 'react'

import styles from '../DataTable.module.scss'
import { DataTableColumnHeaderProps } from '../utils/data-table-types'

export const DataTableColumnHeader: FC<DataTableColumnHeaderProps> = observer(
  ({
    dataField,
    // filters$ = null,
    // filterType = null,
    hasSort = true,
    index,
    title,
    sortBy$ = null,
  }) => {
    const sortBy = sortBy$?.get()

    const isActiveSort$ = computed<boolean>(
      () => sortBy && sortBy.dataField === dataField,
    )
    const sortDirection$ = computed(() =>
      isActiveSort$.get() && sortBy ? sortBy.value : null,
    )
    // const showFilter = useObservable<boolean>(filterType.length > 0)

    // const handleFilter = (value: string) => {
    //   filter.onFilter({ dataField: '', value: value })
    // }

    const handleSort = () => {
      if (sortDirection$.peek() === 'asc') {
        sortBy$.set({ dataField: dataField, value: 'desc' })
      } else sortBy$.set({ dataField: dataField, value: 'asc' })
    }

    return (
      <div>
        <div
          className={`${styles['vyne-data-table-column-header']} ${
            styles[`vyne-data-table-column-header--${index}`]
          }`}
        >
          <div>{title}</div>
          {/* {filter && (
            <div onClick={() => showFilter.set(true)}>
              {filter.type === 'date' && <CalendarIcon />}
              {filter.type === 'search' && <SearchIcon />}
            </div>
          )} */}
          {hasSort && sortBy && (
            <div
              className={styles['vyne-data-table-column-header__sort']}
              onClick={handleSort}
            >
              <svg
                width='0.5625rem'
                height='0.375rem'
                viewBox='0 0 9 6'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M8.94084 4.99499L5.13143 0.104979C5.02239 -0.0349929 4.81243 -0.0349929 4.70223 0.104979L0.892822 4.99499C0.751303 5.17734 0.878902 5.44444 1.10742 5.44444H8.72624C8.95476 5.44444 9.08236 5.17734 8.94084 4.99499Z'
                  fill={
                    sortDirection$.get() === 'asc'
                      ? 'var(--grey-600, #333)'
                      : 'var(--grey-200, #dadada)'
                  }
                />
              </svg>
              <svg
                width='0.5625rem'
                height='0.375rem'
                viewBox='0 0 9 6'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M8.72624 0.944336H1.10742C0.878902 0.944336 0.751303 1.19236 0.892822 1.36168L4.70223 5.90241C4.81127 6.03238 5.02123 6.03238 5.13143 5.90241L8.94084 1.36168C9.08236 1.19236 8.95476 0.944336 8.72624 0.944336Z'
                  fill={
                    sortDirection$.get() === 'desc'
                      ? 'var(--grey-600, #333)'
                      : 'var(--grey-200, #dadada)'
                  }
                />
              </svg>
            </div>
          )}
        </div>
        {/* {filter && (
          <div
            className={`${styles['data-table-column-header__filter']} ${
              styles[
                `data-table-column-header__filter--${
                  showFilter.get() ? 'active' : 'default'
                }`
              ]
            }`}
          >
            {filter.type === 'date' && (
              <DateRangePicker onSelect={handleFilter} />
            )}
            {filter.type === 'search' && <SearchBar onSelect={handleFilter} />}
          </div>
        )} */}
      </div>
    )
  },
)
