import { Input, InputProps, InputRef } from 'antd'
import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react'

import { KeyType } from 'utils'

import './SearchBar.scss'

const { Search } = Input

export interface StateFilters {
  [key: KeyType]: any
  searchText?: string
}

export interface SearchState {
  [key: KeyType]: any
  Filters: {
    [key: KeyType]: any
    Config?: {
      [key: KeyType]: any
      Name?: string
    }
    Name?: string
    Search?: string
  }
  Key?: number
}

export type SearchStates = StateFilters | string

interface SearchProps extends InputProps, React.RefAttributes<InputRef> {
  delaySearch?: boolean
  setState: Dispatch<SetStateAction<SearchStates>>
  state: SearchStates
}

export const SearchBar: FC<SearchProps> = ({
  allowClear = true,
  delaySearch = true,
  maxLength = Infinity,
  placeholder = 'Search',
  state,
  setState,
  ...props
}) => {
  const [search, setSearch] = useState<string>(
    typeof state === 'string'
      ? state?.trim()
      : state?.searchText?.trim() ||
          state?.Filters?.Search?.trim() ||
          state?.Filters?.Name?.trim(),
  )
  const [timeoutId, setTimeoutId] =
    useState<ReturnType<typeof setTimeout>>(null)

  useEffect(() => {
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    if (typeof state === 'string') setSearch(state)
  }, [state])

  const handleSetTimeout = (value: string) => {
    const timeoutId = setTimeout(() => {
      handleSearch(value)
    }, 1500)

    setTimeoutId(timeoutId)
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timeoutId) // stop current search setTimeout
    setSearch(e.target.value)

    if (delaySearch) handleSetTimeout(e.target.value.trim())
    else handleSearch(e.target.value.trim())
  }

  const handleOnPressEnter = () => {
    clearTimeout(timeoutId) // stop current search setTimeout
    handleSearch(search)
  }

  const handleSearch = (value: string) => {
    if (typeof state === 'string') setState(value)
    else {
      const copy = { ...state }

      if (copy.Key >= 0) copy.Key = ++copy.Key

      // needed for claims
      if (copy.Filters?.Search || copy.Filters?.Search === '')
        copy.Filters.Search = value

      // needed for trellis eligibility
      if (copy.Filters?.Name || copy.Filters?.Name === '')
        copy.Filters.Name = value
      if (copy.searchText || copy.searchText === '') copy.searchText = value

      setState({ ...copy })
    }
  }

  return (
    <Search
      allowClear={allowClear}
      maxLength={maxLength}
      onChange={handleOnChange}
      onPressEnter={handleOnPressEnter}
      placeholder={placeholder}
      value={search}
      {...props}
    />
  )
}
