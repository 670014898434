import * as Sentry from '@sentry/react'

const consoleLogEvent = (hint: Sentry.EventHint): boolean => {
  const error = hint.originalException || hint.syntheticException
  console.debug(typeof error, error)
  return false
}

//Antd logs a bunch of strings as error that start with WARNING: for deprecated controls, determine if the message is one and return true so it's changed to a warning instead
const isWarningError = (message: unknown) => {
  try {
    const type = typeof message
    if (type != 'string') return false

    const messageLower = (message as string)?.toLowerCase()

    if (
      messageLower &&
      (messageLower.includes('warning:') || messageLower.includes('deprecated'))
    ) {
      return true
    }
  } catch {
    //what we got likely wasn't a string so we can ignore it
  }

  return false
}

export const setupConsoleErrorOverride = () => {
  const originalConsoleErrorMethod = console.error

  console.error = (message?: any, ...optionalParams: any[]) => {
    if (isWarningError(message)) {
      //some things like antd log warnings as errors
      console.warn(message, ...optionalParams)
    } else {
      originalConsoleErrorMethod(message, ...optionalParams)
    }
  }
}
