import { Slider, SliderHandle } from '@progress/kendo-react-inputs'
import * as React from 'react'

import { ImageOperationType } from '../../../attachment.interface'

type SliderControlProps = {
  title: string
  valueMin: number
  valueMax: number
  operationType: number
  valueRef: React.MutableRefObject<number>
  valueSliderRef: React.MutableRefObject<SliderHandle>
  updateOperationList: (
    imageOperationKey: ImageOperationType,
    refObject?: React.MutableRefObject<number>,
    value?: number,
  ) => void
}

const SliderControl: React.FC<SliderControlProps> = ({
  title,
  valueMin,
  valueMax,
  operationType,
  valueRef,
  valueSliderRef,
  updateOperationList,
}) => {
  return (
    <>
      <h6 className='white-title'>{title}</h6>
      <Slider
        buttons={false}
        className='k-slider-invert'
        style={{ width: '100%' }}
        defaultValue={0}
        min={valueMin}
        max={valueMax}
        onChange={(e) =>
          updateOperationList(operationType, valueRef, Math.round(e.value))
        }
        ref={valueSliderRef}
      />
    </>
  )
}

export default SliderControl
