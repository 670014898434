import { CopyOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { CSSProperties, FC } from 'react'

import { showMessage } from '../../../../utilities/general'

import './readOnlyInput.scss'

type ReadOnlyInputProps = {
  label: string
  text: string
  allowCopy?: boolean
  style?: CSSProperties
}

const ReadOnlyInput: FC<ReadOnlyInputProps> = ({
  label,
  text,
  allowCopy,
  style,
}) => {
  const copyCurrentValue = (value: string) => {
    navigator.clipboard.writeText(value)
    showMessage(
      `'${value.toLocaleUpperCase()}' was successfully copied to clipboard.`,
      'success',
    )
  }

  return (
    <div
      style={style}
      className='read-only-input'
    >
      <p className='fs-0625'>{label}</p>
      <div className='read-only-input--text'>
        <p className='fs-0785'>{text}</p>
        {allowCopy && text && (
          <Tooltip title='Copy'>
            <span>
              <CopyOutlined onClick={() => copyCurrentValue(text)} />
            </span>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export default ReadOnlyInput
