export type optionType = {
  imageTypeId: number
  imageTypeName: string
  isXray: boolean
  alias: string
  imageTypePaperOrFilm: number
}

export const imagesOptions: optionType[] = [
  {
    imageTypeId: 360,
    imageTypeName: 'X-RAY',
    isXray: true,
    alias: 'XRAY',
    imageTypePaperOrFilm: 4,
  },
  {
    imageTypeId: 1,
    imageTypeName: 'Bitewing',
    isXray: true,
    alias: 'BITEWING',
    imageTypePaperOrFilm: 4,
  },
  {
    imageTypeId: 2,
    imageTypeName: 'Periapical / PA',
    isXray: true,
    alias: 'PERIAPICALPA',
    imageTypePaperOrFilm: 4,
  },
  {
    imageTypeId: 3,
    imageTypeName: 'Partial Mount',
    isXray: true,
    alias: 'PARTIALMOUNT',
    imageTypePaperOrFilm: 4,
  },
  {
    imageTypeId: 4,
    imageTypeName: 'Full Mouth Series',
    isXray: true,
    alias: 'FM XRAY',
    imageTypePaperOrFilm: 4,
  },
  {
    imageTypeId: 5,
    imageTypeName: 'Panoramic Film',
    isXray: true,
    alias: 'PAN XRAY',
    imageTypePaperOrFilm: 4,
  },
  {
    imageTypeId: 11,
    imageTypeName: 'Cephalometric',
    isXray: true,
    alias: 'CEPHALOMETRIC',
    imageTypePaperOrFilm: 4,
  },
  {
    imageTypeId: 14,
    imageTypeName: 'Full Arch',
    isXray: true,
    alias: 'FULLARCH',
    imageTypePaperOrFilm: 4,
  },
]

export const otherOptions: optionType[] = [
  {
    imageTypeId: 6,
    imageTypeName: 'Periodontal Charting',
    isXray: false,
    alias: 'PERIO CHART',
    imageTypePaperOrFilm: 3,
  },
  {
    imageTypeId: 7,
    imageTypeName: 'EOB or COB',
    isXray: false,
    alias: 'EOB',
    imageTypePaperOrFilm: 3,
  },
  {
    imageTypeId: 8,
    imageTypeName: 'Intra-oral Photo',
    isXray: false,
    alias: 'INTRA ORAL',
    imageTypePaperOrFilm: 3,
  },
  {
    imageTypeId: 9,
    imageTypeName: 'Student Verification',
    isXray: false,
    alias: 'STUDENT VERIFICATION',
    imageTypePaperOrFilm: 3,
  },

  {
    imageTypeId: 361,
    imageTypeName: 'Narrative',
    isXray: false,
    alias: 'NARRATIVE',
    imageTypePaperOrFilm: 3,
  },
  {
    imageTypeId: 362,
    imageTypeName: 'Report',
    isXray: false,
    alias: 'REPORT',
    imageTypePaperOrFilm: 3,
  },
  {
    imageTypeId: 363,
    imageTypeName: 'Diagnosis',
    isXray: false,
    alias: 'DIAGNOSIS',
    imageTypePaperOrFilm: 3,
  },
]
