import GlobalState from 'trellis:state/globalState'
import trellisConfiguration from 'trellis:utilities/config'

import { IAuthenticatedApiModel } from '../../utilities/api'
import {
  BillingApiFactory,
  CancelRecurringPaymentRequest,
  Configuration,
  MakePaymentRequest,
} from './billing-client'

const trellisApi: string = trellisConfiguration.trellis_apiUrl

export const GetRecurringPayments = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
) => {
  return BillingApiFactory(
    new Configuration({
      basePath: trellisApi + 'services/billing',
      accessToken: authentication.AccessToken,
    }),
  ).billingGetRecurringPayments(customerId)
}

export const GetTransactions = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
) => {
  return BillingApiFactory(
    new Configuration({
      basePath: trellisApi + 'services/billing',
      accessToken: authentication.AccessToken,
    }),
  ).billingGetTransactions(customerId)
}

export const GetInvoices = async (authentication: IAuthenticatedApiModel) => {
  return BillingApiFactory(
    new Configuration({
      basePath: trellisApi + 'services/billing',
      accessToken: authentication.AccessToken,
    }),
  ).billingGetInvoices(authentication.CustomerIdNumber)
}

export const GetTsysPaymentInfo = async (
  authentication: IAuthenticatedApiModel,
) => {
  return BillingApiFactory(
    new Configuration({
      basePath: trellisApi + 'services/billing',
      accessToken: authentication.AccessToken,
    }),
  ).billingGetTsysPaymentInfo(authentication.CustomerIdNumber)
}

export const MakePayment = async (
  authentication: IAuthenticatedApiModel,
  invoiceEntityId: number,
  request: MakePaymentRequest,
) => {
  return BillingApiFactory(
    new Configuration({
      basePath: trellisApi + 'services/billing',
      accessToken: authentication.AccessToken,
    }),
  ).billingMakePayment(
    authentication.CustomerIdNumber,
    invoiceEntityId,
    request,
  )
}

export const CancelRecurringPayment = async (
  customerId: number,
  recurringScheduleId: number,
  request: CancelRecurringPaymentRequest,
) => {
  return BillingApiFactory(
    new Configuration({
      basePath: trellisApi + 'services/billing',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).billingCancelRecurring(customerId, recurringScheduleId, request)
}
