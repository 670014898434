import { Col, Row } from 'antd'

import AncillaryClaimControls from '../tabs/procedures/ancillaryClaimControls'
import { DiagnosisControls } from '../tabs/procedures/diagnosisControls'
import LineItemControls from '../tabs/procedures/lineItemControls/lineItemControls'
import { PaymentControls } from '../tabs/procedures/paymentControls'
import { ClaimServiceInformation } from './ClaimServiceInformation'

const ProceduresContent = () => (
  <>
    <ClaimServiceInformation />
    <Row>
      <Col
        span={24}
        className='form-detail-section'
      >
        <div className='page-section'>
          <div className='page-section__title'>Procedures</div>
          <LineItemControls />
        </div>
      </Col>
    </Row>
    <Row>
      <Col
        span={24}
        className='form-detail-section'
      >
        <div className='page-section'>
          <div className='page-section__title'>Diagnosis</div>
          <DiagnosisControls />
        </div>
      </Col>
    </Row>
    <Row>
      <Col
        span={24}
        className='form-detail-section'
      >
        <div className='page-section'>
          <div className='page-section__title'>Additional Coverage</div>
          <PaymentControls />
        </div>
      </Col>
    </Row>
    <section className='form-detail-section'>
      <div className='page-section__title'>
        Ancillary Claim/Treatment Information
      </div>
      <AncillaryClaimControls />
    </section>
  </>
)

export default ProceduresContent
