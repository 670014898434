export const validateCreditCard = (value: string) => {
  const mastercard =
    value &&
    value.match(
      /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
    )
  const amercianExpress = value && value.match(/^3[47][0-9]{13}$/)
  const visa = value && value.match(/^4[0-9]{12}(?:[0-9]{3})?$/)

  return (
    value &&
    !mastercard &&
    !amercianExpress &&
    !visa &&
    'Must be a valid number'
  )
}
