export const claimArchiveColumns = [
  {
    id: 0,
    property: 'Actions',
    label: 'Actions',
    show: true,
  },
  {
    id: 1,
    property: 'TRANSMIT_DATE',
    label: 'Transmit Date',
    show: true,
  },
  {
    id: 2,
    property: 'PAT_NAME',
    label: 'Patient Name',
    show: true,
  },
  {
    id: 3,
    property: 'SUB_NAME',
    label: 'Subscriber Name',
    show: true,
  },
  {
    id: 4,
    property: 'CARRIER',
    label: 'Carrier',
    show: true,
  },
  {
    id: 5,
    property: 'STATUS',
    label: 'Status',
    show: true,
  },
  {
    id: 6,
    property: 'Status_Description',
    label: 'Status Description',
    show: true,
  },
  {
    id: 7,
    property: 'REMARKS_1',
    label: 'Remarks',
    show: false,
  },
  {
    id: 8,
    property: 'FEE_TOTAL',
    label: 'Fee Total',
    show: false,
  },
  {
    id: 9,
    property: 'TIN',
    label: 'Tin',
    show: false,
  },
  {
    id: 10,
    property: 'PRE_D',
    label: 'Predetermination',
    show: false,
  },
  {
    id: 11,
    property: 'DOS',
    label: 'Service Date',
    show: false,
  },
  {
    id: 12,
    property: 'LAST_UPDATE_DATE',
    label: 'Last Update',
    show: false,
  },
  {
    id: 13,
    property: 'TREATMENT_ADD',
    label: 'Treatment Address',
    show: false,
  },
  {
    id: 14,
    property: 'TREATMENT_CITY',
    label: 'Treatment City',
    show: false,
  },
  {
    id: 15,
    property: 'BILLING_ADD_1',
    label: 'Billing Address',
    show: false,
  },
  {
    id: 16,
    property: 'BILLING_CITY',
    label: 'Billing City',
    show: false,
  },
  {
    id: 17,
    property: 'NPI_GROUP',
    label: 'Npi Group',
    show: false,
  },
  {
    id: 18,
    property: 'HDD_SERIAL_ID',
    label: 'Install',
    show: false,
  },
  {
    id: 19,
    property: 'TREATING_SIGNATURE',
    label: 'Treating Signature',
    show: false,
  },
  {
    id: 20,
    property: 'COB_FLAG',
    label: 'Cob Flag',
    show: false,
  },
  {
    id: 21,
    property: 'RP_USERNAME',
    label: 'Username',
    show: false,
  },
]
