import { QuestionCircleFilled } from '@ant-design/icons'
import { Table, Tooltip } from 'antd'
import React, { FC, useContext } from 'react'

import { AttachmentRequirement } from '../../../../api/attachment/attachment-client'
import { useClaimDetailContext } from '../../../claims/context/claimDetailContext'
import { SavedAttachmentContext } from '../../attachment'
import {
  IAttachmentRequirement,
  IAttachmentRequirements,
} from '../../attachment.interface'
import {
  AttachmentRequirementsText,
  SentAttachmentRequirementsTooltipAnt,
  UnsentAttachmentRequirementsTooltip,
} from '../../constants'

import './attachmentRequirementGrid.scss'
import '../attachmentTable.scss'

type AttachmentRequirementGridProps = {
  attachmentRequirements: IAttachmentRequirements
}

const AttachmentRequirementGrid: FC<AttachmentRequirementGridProps> = ({
  attachmentRequirements,
}) => {
  const {
    isAttachmentRequirementsIgnored,
    ignoreAttachmentRequirements,
    attachment,
  } = useClaimDetailContext()
  const isSavedAttachment = useContext(SavedAttachmentContext)

  const columns = [
    {
      title: 'Code',
      key: 'procedureCode',
      dataIndex: 'procedureCode',
      width: '10%',
    },
    {
      title: 'Tooth#/Quad',
      key: 'toothNumber',
      dataIndex: 'toothNumber',
      width: '10%',
    },
    {
      title: 'Requirement',
      key: 'requirementDescription',
      dataIndex: 'requirementDescription',
      width: '80%',
    },
  ]

  const dataSource = getClaimAttachmentRequirements(attachmentRequirements)

  function groupByField<K, V>(
    list: Array<V>,
    keyGetter: (input: V) => K,
  ): Map<K, Array<V>> {
    const map = new Map()
    list.forEach((item) => {
      const key = keyGetter(item)
      const collection = map.get(key)
      if (!collection) {
        map.set(key, [item])
      } else {
        collection.push(item)
      }
    })
    return map
  }

  function attachmentReqMergedDesc(
    req: AttachmentRequirement,
  ): IAttachmentRequirement {
    const requirementDesc = ''
      .concat(
        req.requiresAttachment
          ? AttachmentRequirementsText.attachment + ','
          : '',
      )
      .concat(req.requiresFmXray ? AttachmentRequirementsText.fmXray + ',' : '')
      .concat(
        req.requiresNarrative ? AttachmentRequirementsText.narrative + ',' : '',
      )
      .concat(
        req.requiresPanXray ? AttachmentRequirementsText.panXray + ',' : '',
      )
      .concat(
        req.requiresPerioCharting
          ? AttachmentRequirementsText.perioCharting + ','
          : '',
      )
      .concat(req.requiresXray ? AttachmentRequirementsText.xray : '')
    const attachReq: IAttachmentRequirement = {
      procedureCode: req.procedureCode,
      toothNumber: req.toothNumber,
      requirementDescription: requirementDesc.replace(/,*$/, ''),
    }
    return attachReq
  }

  function attachmentReqToothNumMerged(
    groupedProc: AttachmentRequirement[],
  ): AttachmentRequirement {
    let toothNumMerged = ''
    groupedProc.forEach((req) => {
      if (req.toothNumber != null && req.toothNumber != '') {
        toothNumMerged += req.toothNumber + ','
      }
    })

    if (toothNumMerged.charAt(toothNumMerged.length - 1) === ',') {
      toothNumMerged = toothNumMerged.substr(0, toothNumMerged.length - 1)
    }

    const singleReq = groupedProc[0]
    const mergedProc: AttachmentRequirement = {
      procedureCode: singleReq.procedureCode,
      requiresAttachment: singleReq.requiresAttachment,
      requiresFmXray: singleReq.requiresFmXray,
      requiresNarrative: singleReq.requiresNarrative,
      requiresPanXray: singleReq.requiresPanXray,
      requiresPerioCharting: singleReq.requiresPerioCharting,
      requiresXray: singleReq.requiresXray,
      toothNumber: toothNumMerged,
    }

    return mergedProc
  }

  function getClaimAttachmentRequirements(
    attachmentRequirements: IAttachmentRequirements,
  ) {
    const attachmentReqs = new Array<IAttachmentRequirement>()
    const requirements = attachmentRequirements?.attachmentRequirements
    if (requirements) {
      const groupRequirementsByProc = groupByField(
        requirements,
        (req) => req.procedureCode,
      )
      if (groupRequirementsByProc) {
        groupRequirementsByProc.forEach((groupedProc, key) => {
          attachmentReqs.push(
            attachmentReqMergedDesc(attachmentReqToothNumMerged(groupedProc)),
          )
        })
      }
    }
    return attachmentReqs
  }

  return (
    <div
      className={`attachment-requirement-grid ${
        attachment?.sentDate ? 'has-sent-date' : ''
      }`}
    >
      <div className='attachment-title-container'>
        <h4 className='attachment-title'>Attachment Requirements</h4>

        <div className='attachment-ignore-text'>
          {!isSavedAttachment &&
            (isAttachmentRequirementsIgnored == null ? (
              <span className='ignore-requirements' />
            ) : !isAttachmentRequirementsIgnored ? (
              <span className='link ignore-requirements'>
                <a onClick={() => ignoreAttachmentRequirements()}>Ignore</a>
              </span>
            ) : (
              <span className='ignore-requirements'>Ignored</span>
            ))}
          <Tooltip
            placement='bottomLeft'
            title={
              !isSavedAttachment
                ? UnsentAttachmentRequirementsTooltip
                : SentAttachmentRequirementsTooltipAnt
            }
          >
            <QuestionCircleFilled className='question-tool-tip' />
          </Tooltip>
        </div>
      </div>

      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        locale={{ emptyText: 'No requirements detected' }}
        className='attachment-table'
      />
    </div>
  )
}
export default AttachmentRequirementGrid
