import React, { useState } from 'react'

import './form.scss'

export const FormFloatLabel = ({ children, label, inputValue }: any) => {
  const [focus, setFocus] = useState(false)
  const labelClass = `form-input-label ${
    focus || inputValue
      ? 'form-input-label--float'
      : 'form-input-label--default'
  }`

  return (
    <div
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label className={labelClass}>{label}</label>
    </div>
  )
}
