import { useLDClient } from 'launchdarkly-react-client-sdk'
import {
  ChangeEvent,
  Dispatch,
  FC,
  FormEvent,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { ErrorRecord, useForm, Validations } from 'utils'

import { Login as loginUser } from 'trellis:api/authentication/authenticationApi'
import GlobalState, { logoutError } from 'trellis:state/globalState'
import { isDiSite } from 'trellis:utilities/general'

import DentalIntelligenceLogin from './components/DentalIntelligenceLoginForm/DentalIntelligenceLoginForm'
import TrellisLoginForm from './components/TrellisLoginForm'
import {
  getLoginError,
  getLoginRedirectURL,
  processLoginResponse,
} from './utils/login-helpers'

export interface LoginFormProps {
  formData: LoginFormData
  formErrors: ErrorRecord<LoginFormData>
  handleFormChange: <S>(
    key: keyof LoginFormData,
    sanitizeFn?: (value: string) => S,
  ) => (e: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => void
  handleFormSubmit: (e: FormEvent<HTMLFormElement>) => void
  loginError: JSX.Element
  setFormValidations: Dispatch<SetStateAction<Validations<LoginFormData>>>
  verifying: boolean
}

export type LoginFormData = {
  password: string
  username: string
}

const emptyLoginFormData: LoginFormData = {
  username: '',
  password: '',
}

export const getLoginUI = (props: LoginFormProps) => {
  const isDentalIntelligence = isDiSite()

  if (isDentalIntelligence) return <DentalIntelligenceLogin {...props} />
  else return <TrellisLoginForm {...props} />
}

export const Login: FC = (): ReactElement | null => {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const ldClient = useLDClient()

  const [loginError, setLoginError] = useState<JSX.Element>(null)
  const [verifying, setVerifying] = useState<boolean>(false)

  useEffect(() => {
    if (logoutError.peek()) setLoginError(<p>{logoutError.peek()}</p>)
    else if (location?.state?.error)
      setLoginError(getLoginError(JSON.parse(location.state.error)))
    else if (
      GlobalState.IsAuthenticated.peek() &&
      !GlobalState.AuthLoading.peek()
    ) {
      const redirectURL = getLoginRedirectURL(searchParams)
      if (redirectURL) {
        navigate(redirectURL, {
          replace: true,
          state: { previousPathname: location.pathname },
        })
      }
    }
  }, [])

  const handleLogin = async (): Promise<void> => {
    GlobalState.AuthLoading.set(true)
    setVerifying(true)

    try {
      const { data } = await loginUser(formData.username, formData.password)
      if (data) {
        await processLoginResponse(data, ldClient)

        const redirectURL = getLoginRedirectURL(searchParams)
        if (redirectURL) {
          navigate(redirectURL, {
            replace: true,
            state: { previousPathname: location.pathname },
          })
        }
      }
    } catch (error) {
      setLoginError(getLoginError(error))
    } finally {
      logoutError.set(null)
      GlobalState.AuthLoading.set(false)
      setVerifying(false)
    }
  }

  const {
    formData,
    formErrors,
    handleFormChange,
    handleFormSubmit,
    setFormValidations,
  } = useForm<LoginFormData>({
    initialValues: emptyLoginFormData,
    onSubmit: handleLogin,
  })

  const loginFormProps: LoginFormProps = {
    formData,
    formErrors,
    handleFormChange,
    handleFormSubmit,
    loginError,
    setFormValidations,
    verifying,
  }

  return getLoginUI(loginFormProps)
}
