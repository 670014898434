/* tslint:disable */
/* eslint-disable */
/**
 * Account
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddUserRequest
 */
export interface AddUserRequest {
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    'newUserEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    'customerEmail'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddUserRequest
     */
    'userId'?: number;
    /**
     * 
     * @type {EmailTemplateType}
     * @memberof AddUserRequest
     */
    'appCompanyName'?: EmailTemplateType;
}
/**
 * 
 * @export
 * @interface AddUserResponse
 */
export interface AddUserResponse {
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof AddUserResponse
     */
    'statusCode'?: HttpStatusCode;
    /**
     * 
     * @type {boolean}
     * @memberof AddUserResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddUserResponse
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddUserResponse
     */
    'verificationCode'?: string | null;
}
/**
 * 
 * @export
 * @interface ChangePasswordReq
 */
export interface ChangePasswordReq {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordReq
     */
    'newPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordReq
     */
    'oldPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordReq
     */
    'username'?: string | null;
    /**
     * 
     * @type {EmailTemplateType}
     * @memberof ChangePasswordReq
     */
    'appCompanyName'?: EmailTemplateType;
}
/**
 * 
 * @export
 * @interface ChangePasswordResponse
 */
export interface ChangePasswordResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ChangePasswordResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordResponse
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface ContactSupportRequest
 */
export interface ContactSupportRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactSupportRequest
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSupportRequest
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSupportRequest
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSupportRequest
     */
    'contactName'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomerUserModel
 */
export interface CustomerUserModel {
    /**
     * 
     * @type {number}
     * @memberof CustomerUserModel
     */
    'customerUserID'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerUserModel
     */
    'globalCustomerID'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerUserModel
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerUserModel
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerUserModel
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerUserModel
     */
    'lastPasswordUpdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerUserModel
     */
    'accountEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerUserModel
     */
    'accountOwnerEmail'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerUserModel
     */
    'activated'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerUserModel
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerUserModel
     */
    'lastLoginDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerUserModel
     */
    'addDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerUserModel
     */
    'failedLoginAttempts'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerUserModel
     */
    'lockedOut'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerUserModel
     */
    'failedTokenLoginAttempts'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerUserModel
     */
    'tokenLoginLockedOut'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerUserModel
     */
    'receiveBillingEmail'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerUserModel
     */
    'expirePassword'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerUserModel
     */
    'isSso'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerUserModel
     */
    'eulaAccepted'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EmailTemplateType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type EmailTemplateType = typeof EmailTemplateType[keyof typeof EmailTemplateType];


/**
 * 
 * @export
 * @interface EulaAcceptRequest
 */
export interface EulaAcceptRequest {
    /**
     * 
     * @type {number}
     * @memberof EulaAcceptRequest
     */
    'eulaVersionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EulaAcceptRequest
     */
    'username'?: string | null;
}
/**
 * 
 * @export
 * @interface EulaModel
 */
export interface EulaModel {
    /**
     * 
     * @type {number}
     * @memberof EulaModel
     */
    'eulaVersionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EulaModel
     */
    'eulaText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EulaModel
     */
    'addDate'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const HttpStatusCode = {
    NUMBER_100: 100,
    NUMBER_101: 101,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_200: 200,
    NUMBER_201: 201,
    NUMBER_202: 202,
    NUMBER_203: 203,
    NUMBER_204: 204,
    NUMBER_205: 205,
    NUMBER_206: 206,
    NUMBER_207: 207,
    NUMBER_208: 208,
    NUMBER_226: 226,
    NUMBER_300: 300,
    NUMBER_301: 301,
    NUMBER_302: 302,
    NUMBER_303: 303,
    NUMBER_304: 304,
    NUMBER_305: 305,
    NUMBER_306: 306,
    NUMBER_307: 307,
    NUMBER_308: 308,
    NUMBER_400: 400,
    NUMBER_401: 401,
    NUMBER_402: 402,
    NUMBER_403: 403,
    NUMBER_404: 404,
    NUMBER_405: 405,
    NUMBER_406: 406,
    NUMBER_407: 407,
    NUMBER_408: 408,
    NUMBER_409: 409,
    NUMBER_410: 410,
    NUMBER_411: 411,
    NUMBER_412: 412,
    NUMBER_413: 413,
    NUMBER_414: 414,
    NUMBER_415: 415,
    NUMBER_416: 416,
    NUMBER_417: 417,
    NUMBER_421: 421,
    NUMBER_422: 422,
    NUMBER_423: 423,
    NUMBER_424: 424,
    NUMBER_426: 426,
    NUMBER_428: 428,
    NUMBER_429: 429,
    NUMBER_431: 431,
    NUMBER_451: 451,
    NUMBER_500: 500,
    NUMBER_501: 501,
    NUMBER_502: 502,
    NUMBER_503: 503,
    NUMBER_504: 504,
    NUMBER_505: 505,
    NUMBER_506: 506,
    NUMBER_507: 507,
    NUMBER_508: 508,
    NUMBER_510: 510,
    NUMBER_511: 511
} as const;

export type HttpStatusCode = typeof HttpStatusCode[keyof typeof HttpStatusCode];


/**
 * 
 * @export
 * @interface LambdaResponse
 */
export interface LambdaResponse {
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof LambdaResponse
     */
    'statusCode'?: HttpStatusCode;
    /**
     * 
     * @type {string}
     * @memberof LambdaResponse
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface PasswordExpirationSettingRequest
 */
export interface PasswordExpirationSettingRequest {
    /**
     * 
     * @type {number}
     * @memberof PasswordExpirationSettingRequest
     */
    'expirePassword'?: number | null;
}
/**
 * 
 * @export
 * @interface PasswordExpirationSettingResponse
 */
export interface PasswordExpirationSettingResponse {
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof PasswordExpirationSettingResponse
     */
    'statusCode'?: HttpStatusCode;
    /**
     * 
     * @type {string}
     * @memberof PasswordExpirationSettingResponse
     */
    'message'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PasswordExpirationSettingResponse
     */
    'expirePassword'?: number | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {ContactSupportRequest} [contactSupportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountContactSupport: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, contactSupportRequest?: ContactSupportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('accountContactSupport', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('accountContactSupport', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('accountContactSupport', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/ContactSupport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactSupportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        accountGetPasswordSettings: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, userId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('accountGetPasswordSettings', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('accountGetPasswordSettings', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('accountGetPasswordSettings', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/passwords/expiration-setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerUserID 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetUser: async (customerUserID: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerUserID' is not null or undefined
            assertParamExists('accountGetUser', 'customerUserID', customerUserID)
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('accountGetUser', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('accountGetUser', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('accountGetUser', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/user/{customerUserID}`
                .replace(`{${"customerUserID"}}`, encodeURIComponent(String(customerUserID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {AddUserRequest} [addUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountPostUser: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, addUserRequest?: AddUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('accountPostUser', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('accountPostUser', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('accountPostUser', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/add-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerUserID 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountPutNames: async (customerUserID: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, firstName?: string, lastName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerUserID' is not null or undefined
            assertParamExists('accountPutNames', 'customerUserID', customerUserID)
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('accountPutNames', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('accountPutNames', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('accountPutNames', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/user/{customerUserID}/firstname-lastname`
                .replace(`{${"customerUserID"}}`, encodeURIComponent(String(customerUserID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {number} [userId] 
         * @param {ChangePasswordReq} [changePasswordReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountPutPassword: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, userId?: number, changePasswordReq?: ChangePasswordReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('accountPutPassword', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('accountPutPassword', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('accountPutPassword', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/passwords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {number} [userId] 
         * @param {PasswordExpirationSettingRequest} [passwordExpirationSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountPutpasswordSettings: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, userId?: number, passwordExpirationSettingRequest?: PasswordExpirationSettingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('accountPutpasswordSettings', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('accountPutpasswordSettings', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('accountPutpasswordSettings', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/passwords/expiration-setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordExpirationSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {ContactSupportRequest} [contactSupportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountContactSupport(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, contactSupportRequest?: ContactSupportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerUserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountContactSupport(authtoken, registrationtoken, ignoretokenrefresh, contactSupportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async accountGetPasswordSettings(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, userId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordExpirationSettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetPasswordSettings(authtoken, registrationtoken, ignoretokenrefresh, customerId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerUserID 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetUser(customerUserID: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerUserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetUser(customerUserID, authtoken, registrationtoken, ignoretokenrefresh, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {AddUserRequest} [addUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountPostUser(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, addUserRequest?: AddUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountPostUser(authtoken, registrationtoken, ignoretokenrefresh, customerId, addUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerUserID 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountPutNames(customerUserID: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, firstName?: string, lastName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerUserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountPutNames(customerUserID, authtoken, registrationtoken, ignoretokenrefresh, firstName, lastName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {number} [userId] 
         * @param {ChangePasswordReq} [changePasswordReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountPutPassword(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, userId?: number, changePasswordReq?: ChangePasswordReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangePasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountPutPassword(authtoken, registrationtoken, ignoretokenrefresh, customerId, userId, changePasswordReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {number} [userId] 
         * @param {PasswordExpirationSettingRequest} [passwordExpirationSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountPutpasswordSettings(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, userId?: number, passwordExpirationSettingRequest?: PasswordExpirationSettingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LambdaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountPutpasswordSettings(authtoken, registrationtoken, ignoretokenrefresh, customerId, userId, passwordExpirationSettingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {ContactSupportRequest} [contactSupportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountContactSupport(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, contactSupportRequest?: ContactSupportRequest, options?: any): AxiosPromise<CustomerUserModel> {
            return localVarFp.accountContactSupport(authtoken, registrationtoken, ignoretokenrefresh, contactSupportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        accountGetPasswordSettings(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, userId?: number, options?: any): AxiosPromise<PasswordExpirationSettingResponse> {
            return localVarFp.accountGetPasswordSettings(authtoken, registrationtoken, ignoretokenrefresh, customerId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerUserID 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetUser(customerUserID: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options?: any): AxiosPromise<CustomerUserModel> {
            return localVarFp.accountGetUser(customerUserID, authtoken, registrationtoken, ignoretokenrefresh, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {AddUserRequest} [addUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountPostUser(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, addUserRequest?: AddUserRequest, options?: any): AxiosPromise<AddUserResponse> {
            return localVarFp.accountPostUser(authtoken, registrationtoken, ignoretokenrefresh, customerId, addUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerUserID 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountPutNames(customerUserID: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, firstName?: string, lastName?: string, options?: any): AxiosPromise<CustomerUserModel> {
            return localVarFp.accountPutNames(customerUserID, authtoken, registrationtoken, ignoretokenrefresh, firstName, lastName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {number} [userId] 
         * @param {ChangePasswordReq} [changePasswordReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountPutPassword(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, userId?: number, changePasswordReq?: ChangePasswordReq, options?: any): AxiosPromise<ChangePasswordResponse> {
            return localVarFp.accountPutPassword(authtoken, registrationtoken, ignoretokenrefresh, customerId, userId, changePasswordReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {number} [userId] 
         * @param {PasswordExpirationSettingRequest} [passwordExpirationSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountPutpasswordSettings(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, userId?: number, passwordExpirationSettingRequest?: PasswordExpirationSettingRequest, options?: any): AxiosPromise<LambdaResponse> {
            return localVarFp.accountPutpasswordSettings(authtoken, registrationtoken, ignoretokenrefresh, customerId, userId, passwordExpirationSettingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {ContactSupportRequest} [contactSupportRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountContactSupport(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, contactSupportRequest?: ContactSupportRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountContactSupport(authtoken, registrationtoken, ignoretokenrefresh, contactSupportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {number} [customerId] 
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetPasswordSettings(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, userId?: number, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountGetPasswordSettings(authtoken, registrationtoken, ignoretokenrefresh, customerId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerUserID 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetUser(customerUserID: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountGetUser(customerUserID, authtoken, registrationtoken, ignoretokenrefresh, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {number} [customerId] 
     * @param {AddUserRequest} [addUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountPostUser(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, addUserRequest?: AddUserRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountPostUser(authtoken, registrationtoken, ignoretokenrefresh, customerId, addUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerUserID 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountPutNames(customerUserID: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, firstName?: string, lastName?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountPutNames(customerUserID, authtoken, registrationtoken, ignoretokenrefresh, firstName, lastName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {number} [customerId] 
     * @param {number} [userId] 
     * @param {ChangePasswordReq} [changePasswordReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountPutPassword(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, userId?: number, changePasswordReq?: ChangePasswordReq, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountPutPassword(authtoken, registrationtoken, ignoretokenrefresh, customerId, userId, changePasswordReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {number} [customerId] 
     * @param {number} [userId] 
     * @param {PasswordExpirationSettingRequest} [passwordExpirationSettingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountPutpasswordSettings(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, userId?: number, passwordExpirationSettingRequest?: PasswordExpirationSettingRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountPutpasswordSettings(authtoken, registrationtoken, ignoretokenrefresh, customerId, userId, passwordExpirationSettingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EulaApi - axios parameter creator
 * @export
 */
export const EulaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {EulaAcceptRequest} [eulaAcceptRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eulaAcceptEula: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, eulaAcceptRequest?: EulaAcceptRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('eulaAcceptEula', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('eulaAcceptEula', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('eulaAcceptEula', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/eula`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eulaAcceptRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eulaGetEula: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('eulaGetEula', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('eulaGetEula', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('eulaGetEula', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/eula`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EulaApi - functional programming interface
 * @export
 */
export const EulaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EulaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {EulaAcceptRequest} [eulaAcceptRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eulaAcceptEula(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, eulaAcceptRequest?: EulaAcceptRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eulaAcceptEula(authtoken, registrationtoken, ignoretokenrefresh, eulaAcceptRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eulaGetEula(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EulaModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eulaGetEula(authtoken, registrationtoken, ignoretokenrefresh, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EulaApi - factory interface
 * @export
 */
export const EulaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EulaApiFp(configuration)
    return {
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {EulaAcceptRequest} [eulaAcceptRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eulaAcceptEula(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, eulaAcceptRequest?: EulaAcceptRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.eulaAcceptEula(authtoken, registrationtoken, ignoretokenrefresh, eulaAcceptRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eulaGetEula(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options?: any): AxiosPromise<EulaModel> {
            return localVarFp.eulaGetEula(authtoken, registrationtoken, ignoretokenrefresh, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EulaApi - object-oriented interface
 * @export
 * @class EulaApi
 * @extends {BaseAPI}
 */
export class EulaApi extends BaseAPI {
    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {EulaAcceptRequest} [eulaAcceptRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EulaApi
     */
    public eulaAcceptEula(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, eulaAcceptRequest?: EulaAcceptRequest, options?: AxiosRequestConfig) {
        return EulaApiFp(this.configuration).eulaAcceptEula(authtoken, registrationtoken, ignoretokenrefresh, eulaAcceptRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EulaApi
     */
    public eulaGetEula(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options?: AxiosRequestConfig) {
        return EulaApiFp(this.configuration).eulaGetEula(authtoken, registrationtoken, ignoretokenrefresh, options).then((request) => request(this.axios, this.basePath));
    }
}


