export const Urls = {
  IMAGE_SYNC_CHAT_NOW:
    'https://17245.livehelpnow.net/17245/sp/pop/chat?did=48433&wid=42172',
  IMAGE_SYNC_LEARN_MORE: 'https://www2.vynedental.com/imagesync',
  IMAGE_SYNC_LOGO_LEARN_MORE: 'https://pages.hellopearl.com/vyne',
}

export const AttachmentDatePickerConstants = {
  MINIMUM_DISABLED_DATE: '1970-01-01',
}
