import { Col, Row } from 'antd'
import { FC } from 'react'

import { ImageSyncFooterProps } from './imageSync.interface'

import './imageSyncFooter.scss'

const ImageSyncFooter: FC<ImageSyncFooterProps> = ({ url, logoPath }) => {
  const handleLogoLearnMoreDisplay = (url: string) => {
    window.open(url, '_blank')
  }

  return (
    <Row>
      <Col
        flex={24}
        className='image-sync-footer'
      >
        <img
          alt='Powered By Pearl'
          className='image-sync-footer__image'
          src={logoPath}
          onClick={() => handleLogoLearnMoreDisplay(url)}
        />
      </Col>
    </Row>
  )
}

export default ImageSyncFooter
