import { ExclamationCircleOutlined, EyeOutlined } from '@ant-design/icons'
import { Col, Empty, Modal, Row, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { isValid } from 'date-fns'
import { FC, useEffect, useState } from 'react'

import {
  GetPearlPatientImages,
  GetPearlPatients,
} from '../../../../api/pearl/pearlApiService'
import poweredByPearlLogo from '../../../../assets/powered-by-pearl.png'
import { useGlobalContext } from '../../../../context/GlobalContextProvider'
import { formatDateFromString } from '../../../../utilities/general'
import ImageDetailsModal from '../imageDetails/imageDetailsModal'
import {
  ImageListModalProps,
  ImageSyncMultiPatient as ImageSyncPatient,
  ImageSyncImage as ImageSyncPatientImage,
} from '../imageSync.interface'
import ImageListPatientDetails from './imageListPatientDetails'

import './imageListModal.scss'

import { PearlConfiguration } from '../../../../api/pearl/pearl-client'
import { IAuthenticatedApiModel } from '../../../../utilities/api'
import { Urls } from '../../shared/sharedConstants'
import ImageSyncFooter from '../imageSyncFooter'
import ImageSyncMultiPatientModal from './imageSyncMultiPatientModal'

const { confirm } = Modal

const ImageListModal: FC<ImageListModalProps> = ({
  customerSettings,
  attachmentInformation,
  updateAttachmentImages,
  handleAttachmentImageAssembly,
  isVisible,
  setIsVisible,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [imagesSelectedCount, setImagesSelectedCount] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [imageSyncImageData, setImageSyncImageData] = useState<
    ImageSyncPatientImage[]
  >([])
  const [imageSyncMultiPatientData, setImageSyncMultiPatient] = useState<
    ImageSyncPatient[]
  >([])
  const [isImageDetailsVisible, setImageDetailsVisible] =
    useState<boolean>(false)
  const [viewImageSyncImage, setviewImageSyncImage] =
    useState<ImageSyncPatientImage>(null)
  const [isPatientSelectVisible, setPatientSelectVisible] =
    useState<boolean>(false)

  const { authentication: trellisAuthentication } = useGlobalContext()

  useEffect(() => {
    ;(async () => {
      setLoading(true)

      let pearlPatients = {} as ImageSyncPatient[]
      try {
        pearlPatients = await GetPearlPatients(
          trellisAuthentication,
          trellisAuthentication.CustomerId,
          customerSettings.organizationId,
          customerSettings.officeId,
          attachmentInformation.patientFirstName,
          attachmentInformation.patientLastName,
          attachmentInformation.patientDateOfBirth,
        )
      } catch (error) {}

      if (pearlPatients?.length > 1) {
        setImageSyncMultiPatient(pearlPatients)
        setPatientSelectVisible(!isPatientSelectVisible)
      } else if (pearlPatients?.length === 1) {
        const pearlPatientId = pearlPatients[0].patientPearlId
        await handleGetPearlPatientImages(
          trellisAuthentication,
          customerSettings,
          pearlPatientId,
        )
      }

      setLoading(false)
    })()
  }, [])

  const handleGetPearlPatientImages = async (
    authentication: IAuthenticatedApiModel,
    customerSettings: PearlConfiguration,
    pearlPatientId: string,
  ) => {
    setLoading(true)

    let imageSyncImageList = {} as ImageSyncPatientImage[]
    try {
      imageSyncImageList = await GetPearlPatientImages(
        authentication,
        parseInt(authentication.CustomerId),
        customerSettings.organizationId,
        customerSettings.officeId,
        pearlPatientId,
      )
    } catch (error) {}

    if (imageSyncImageList) {
      setImageSyncImageData(imageSyncImageList)
    }

    setLoading(false)
  }

  const gridColumns: ColumnsType<ImageSyncPatientImage> = [
    {
      title: 'Image',
      dataIndex: 'imageThumbnail',
      key: 'imageThumbnail',
      width: '15%',
      align: 'center',
      render: (value: string) => (
        <img
          src={value}
          width='98px'
          height='64px'
        />
      ),
    },
    {
      title: 'Date Taken',
      dataIndex: 'imageDateTaken',
      key: 'imageDateTaken',
      width: '15%',
      defaultSortOrder: 'descend',
      render: (imageDateTaken: string) =>
        isValid(new Date(imageDateTaken))
          ? formatDateFromString(imageDateTaken, 'MM/dd/yyy')
          : '',
      sorter: (a, b) =>
        new Date(a.imageDateTaken).valueOf() -
        new Date(b.imageDateTaken).valueOf(),
    },
    {
      title: 'Image Type',
      dataIndex: 'imageType',
      key: 'imageType',
      width: '15%',
      sorter: (a, b) =>
        a.imageType.toString().localeCompare(b.imageType.toString()),
    },
    {
      title: 'Orientation',
      dataIndex: 'orientation',
      key: 'orientation',
      width: '15%',
      sorter: (a, b) => a.orientation.localeCompare(b.orientation),
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      width: '20%',
      sorter: (a, b) => a.notes.localeCompare(b.notes),
    },
    {
      title: '',
      dataIndex: 'imageData',
      key: 'imageData',
      width: '10%',
      align: 'center',
      render: (_: File, record: ImageSyncPatientImage) => (
        <EyeOutlined
          onClick={() => {
            setviewImageSyncImage(record),
              setImageDetailsVisible(!isImageDetailsVisible)
          }}
        />
      ),
    },
  ]

  const handleAddAndClose = () => {
    if (selectedRowKeys?.length > 0) {
      const filteredImageSyncData: ImageSyncPatientImage[] =
        imageSyncImageData.filter((imageSyncImage) =>
          selectedRowKeys.some(
            (selectedRow) => imageSyncImage.key === selectedRow,
          ),
        )

      if (filteredImageSyncData) {
        const images = handleAttachmentImageAssembly(
          attachmentInformation,
          filteredImageSyncData,
        )
        updateAttachmentImages(images)
      }
    }
    setIsVisible(!isVisible)
  }

  const handleCancelConfirm = () => {
    if (selectedRowKeys?.length > 0) {
      confirm({
        title: 'You have unsaved changes',
        icon: <ExclamationCircleOutlined className='sa-edit__cancel-icon' />,
        content: 'Would you like to add your images?',
        okText: 'Save & Close',
        okType: 'primary',
        cancelText: 'Cancel without Saving',
        closable: true,
        onOk() {
          handleAddAndClose()
        },
        onCancel() {
          setIsVisible(!isVisible)
        },
      })
    } else {
      setIsVisible(!isVisible)
    }
  }

  const handleRowSelection = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys)
    setImagesSelectedCount(selectedRowKeys.length)
  }

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: ImageSyncPatientImage[],
    ) => {
      handleRowSelection(selectedRowKeys)
    },
  }

  return (
    <>
      <Modal
        title='Add from Image Sync'
        className='image-sync-image-list-modal'
        open={isVisible}
        closable
        maskClosable={false}
        onCancel={handleCancelConfirm}
        width={1000}
        footer={
          <ImageSyncFooter
            url={Urls.IMAGE_SYNC_LOGO_LEARN_MORE}
            logoPath={poweredByPearlLogo}
          />
        }
      >
        <div>
          <Row>
            <Col flex={1}>
              <ImageListPatientDetails
                loading={loading}
                attachmentInformation={attachmentInformation}
                imagesSelectedCount={imagesSelectedCount}
                handleAddAndClose={() => handleAddAndClose()}
              />
              <Table
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description='No images available for this patient'
                    />
                  ),
                }}
                size='middle'
                columns={gridColumns}
                dataSource={imageSyncImageData}
                loading={loading}
                rowClassName={(_, i) => i % 2 !== 0 && 'table__row--gray'}
                pagination={false}
                scroll={imageSyncImageData?.length > 5 && { y: 435 }}
                rowSelection={{
                  ...rowSelection,
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>
      {isImageDetailsVisible && (
        <ImageDetailsModal
          attachmentInformation={attachmentInformation}
          viewImageSyncImage={viewImageSyncImage}
          selectedRowKeys={selectedRowKeys}
          handleRowSelection={handleRowSelection}
          isImageDetailsVisible={isImageDetailsVisible}
          setImageDetailsVisible={setImageDetailsVisible}
        />
      )}
      {isPatientSelectVisible && (
        <ImageSyncMultiPatientModal
          customerSettings={customerSettings}
          imageSyncMultiPatientData={imageSyncMultiPatientData}
          handleGetPearlPatientImages={handleGetPearlPatientImages}
          isImageSyncListVisible={isVisible}
          setImageSyncListVisible={setIsVisible}
          isVisible={isPatientSelectVisible}
          setIsVisible={setPatientSelectVisible}
        />
      )}
    </>
  )
}

export default ImageListModal
