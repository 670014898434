export const validateExpirationMonth = (value: string, formData: any) => {
  if (!value) return

  let month = formData.CreditCardExpireMonth
  let year = formData.CreditCardExpireYear
  const monthProp = 'CreditCardExpireMonth'
  const yearProp = 'CreditCardExpireYear'

  if (month && year) {
    month = parseInt(month)
    year = parseInt(year)
    formData.errors = formData.errors.filter(
      (e: any) => e.property !== monthProp && e.property !== yearProp,
    )

    const today = new Date()
    const currentMonth = today.getMonth() + 1
    const currentYear = today.getFullYear()

    if (currentYear === year) {
      if (month < currentMonth) {
        formData.errors.push({
          property: monthProp,
          message: 'Invalid Exp. Month',
          section: 'payment',
        })
      }
    }
  }
}
