import GlobalState from 'trellis:state/globalState'
import trellisConfiguration from 'trellis:utilities/config'

import {
  AuthenticationApiFactory,
  Configuration,
  SsoApiFactory,
  SsoTokenUrlRequest,
} from './authentication-client'

const apiEndpoint: string =
  trellisConfiguration.trellis_apiUrl + 'services/authentication'
/** the api path for refreshing the trellis token, used to prevent us from calling the token refresh for token refresh */
export const trellisRefreshTokenPath: string = `${apiEndpoint}/token`

export const Login = async (username: string, password: string) => {
  return AuthenticationApiFactory(
    new Configuration({
      basePath: apiEndpoint,
    }),
  ).authenticationSsoLogin({ username: username, password: password })
}

//Used by the other sso sites
export const SsoTokenLogin = async (ssoToken: string) => {
  return AuthenticationApiFactory(
    new Configuration({
      basePath: apiEndpoint,
    }),
  ).authenticationSsoTokenLogin({ ssoToken })
}

//Used by RL/VDP
export const SingleUseTokenLogin = async (singleUseToken: string) => {
  return AuthenticationApiFactory(
    new Configuration({
      basePath: apiEndpoint,
    }),
  ).authenticationSingleUseLogin({ singleUseToken })
}

export const TokenLogin = async () => {
  return AuthenticationApiFactory(
    new Configuration({
      basePath: apiEndpoint,
    }),
  ).authenticationCreateAccessToken(
    GlobalState?.Auth?.AuthToken?.peek(),
    GlobalState?.Auth?.RegistrationToken?.peek(),
  )
}

//pass in params so we can do a fire and forget and avoid a race condition
export const Logout = async (
  accessToken: string,
  regToken: string,
  authToken: string,
) => {
  return AuthenticationApiFactory(
    new Configuration({
      basePath: apiEndpoint,
      accessToken,
    }),
  ).authenticationLogout(regToken, authToken)
}

export const GetSsoUrl = async (ssoTokenUrlRequest: SsoTokenUrlRequest) => {
  return SsoApiFactory(
    new Configuration({
      basePath: apiEndpoint,
      accessToken: GlobalState?.Auth?.AccessToken?.peek(),
    }),
  ).ssoGetSsoUrl(
    GlobalState?.Auth?.AuthToken?.peek(),
    GlobalState?.Auth?.RegistrationToken?.peek(),
    ssoTokenUrlRequest,
  )
}
