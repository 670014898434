import { Environment } from 'utils'

/**
 * Runtime configuration comes from public/config.js, which is NOT processed by bundling and minifying, so that it can be
 * altered during deployment, which happens after the build has already produced an artifact.
 */
export interface RuntimeConfig {
  environment: string
  version: string
  dist: string
  ldKey: string

  rpRegistrationToken: string
  trellis_apiUrl: string

  attachmentWrapper_apiUrl: string
  attachments_apiUrl: string

  websocket_apiUrl: string

  opera_apiUrl: string

  netSuite_apiUrl: string
  netSuite_clientId: string
  netSuite_clientSecret: string
  netSuite_clientVersion: string

  payments_baseUrl: string

  customer_support_vyne_email: string
  customer_support_vyne_live_chat: string
  customer_support_vyne_phone: string | undefined
  customer_support_di_email: string
  customer_support_di_live_chat: string
  customer_support_di_phone: string | undefined
  customer_support_patterson_email: string
  customer_support_patterson_live_chat: string
  customer_support_patterson_phone: string | undefined

  sentry_url: string
  sentry_traceSampleRate: number
  sentry_environment: string
  sentry_enabled: boolean

  //LOCAL TEST STUFF
  localhost_di?: boolean
  localhost_rp?: boolean
}

// config.js puts the runtime configuration on this window property

declare const window: Window &
  typeof globalThis & {
    __runtime_configuration: RuntimeConfig
  }

/** Configuration provided to the system at runtime. */
export class Configuration {
  constructor(rawConfig: RuntimeConfig) {
    this.environment = new Environment(rawConfig.environment)
    this.version = rawConfig.version
    this.dist = rawConfig.dist
    this.ldKey = rawConfig.ldKey

    this.rpRegistrationToken = rawConfig.rpRegistrationToken
    this.trellis_apiUrl = rawConfig.trellis_apiUrl

    this.attachmentWrapper_apiUrl = rawConfig.attachmentWrapper_apiUrl
    this.attachments_apiUrl = rawConfig.attachments_apiUrl
    this.websocket_apiUrl = rawConfig.websocket_apiUrl

    this.opera_apiUrl = rawConfig.opera_apiUrl

    this.netSuite_apiUrl = rawConfig.netSuite_apiUrl
    this.netSuite_clientId = rawConfig.netSuite_clientId
    this.netSuite_clientSecret = rawConfig.netSuite_clientSecret
    this.netSuite_clientVersion = rawConfig.netSuite_clientVersion
    this.payments_baseUrl = rawConfig.payments_baseUrl

    this.customer_support_vyne_email = rawConfig.customer_support_vyne_email
    this.customer_support_vyne_phone = rawConfig.customer_support_vyne_phone
    this.customer_support_vyne_live_chat =
      rawConfig.customer_support_vyne_live_chat
    this.customer_support_di_email = rawConfig.customer_support_di_email
    this.customer_support_di_phone = rawConfig.customer_support_di_phone
    this.customer_support_di_live_chat = rawConfig.customer_support_di_live_chat
    this.customer_support_patterson_email =
      rawConfig.customer_support_patterson_email
    this.customer_support_patterson_phone =
      rawConfig.customer_support_patterson_phone
    this.customer_support_patterson_live_chat =
      rawConfig.customer_support_patterson_live_chat

    this.sentry_url = rawConfig.sentry_url
    this.sentry_traceSampleRate = rawConfig.sentry_traceSampleRate
    this.sentry_environment = rawConfig.sentry_environment
    this.sentry_enabled = rawConfig.sentry_enabled

    //LOCAL TEST STUFF
    this.localhost_di = rawConfig.localhost_di ?? false
    this.localhost_rp = rawConfig.localhost_rp ?? false
  }
  readonly version: string
  readonly dist: string

  readonly environment: Environment

  readonly ldKey: string

  readonly rpRegistrationToken: string
  readonly trellis_apiUrl: string

  readonly attachmentWrapper_apiUrl: string
  readonly attachments_apiUrl: string

  readonly websocket_apiUrl: string

  readonly opera_apiUrl: string

  readonly netSuite_apiUrl: string
  readonly netSuite_clientId: string
  readonly netSuite_clientSecret: string
  readonly netSuite_clientVersion: string

  readonly payments_baseUrl: string

  readonly customer_support_vyne_email: string
  readonly customer_support_vyne_phone: string | undefined
  readonly customer_support_vyne_live_chat: string
  readonly customer_support_di_email: string
  readonly customer_support_di_phone: string | undefined
  readonly customer_support_di_live_chat: string
  readonly customer_support_patterson_email: string
  readonly customer_support_patterson_phone: string | undefined
  readonly customer_support_patterson_live_chat: string

  readonly sentry_url: string
  readonly sentry_traceSampleRate: number
  readonly sentry_environment: string
  readonly sentry_enabled: boolean

  //LOCAL TEST STUFF
  readonly localhost_di: boolean
  readonly localhost_rp: boolean
}

const getTestConfig = (): RuntimeConfig => {
  return {
    environment: 'production',
    version: '',
    dist: '',
    ldKey: '',

    rpRegistrationToken: '',
    trellis_apiUrl: 'https://faketrellis.cypress-vynetrellis.com/',

    attachmentWrapper_apiUrl:
      'https://attachment-wrapper.cypress-vynetrellis.com',
    attachments_apiUrl: 'https://fakeattachments.cypress-vynetrellis.com',

    websocket_apiUrl: 'wss://fakewebsocket.cypress-vynetrellis.com/',

    opera_apiUrl: 'https://app.cypress-operadds.com/api',

    netSuite_apiUrl:
      'https://api.vendor.cypress-nea-fast.com/nea-vynedental-api/api/',
    netSuite_clientId: 'clientid',
    netSuite_clientSecret: 'clientsecret',
    netSuite_clientVersion: '1.0',

    payments_baseUrl: 'https://fake-payments.cypress-vynetrellis.com/',

    //------------Customer Support Config------
    customer_support_vyne_email: 'fake.email@email.com',
    customer_support_vyne_phone: '(555) 867-5309',
    customer_support_vyne_live_chat: 'https://vynedental.com/',
    customer_support_di_email: 'fake.email+di@email.com',
    customer_support_di_phone: undefined,
    customer_support_di_live_chat: 'https://vynedental.com/',
    customer_support_patterson_email: 'fake.email+patterson@email.com',
    customer_support_patterson_phone: '(555) 867-5309',
    customer_support_patterson_live_chat: 'https://vynedental.com/',

    //-------------SENTRY----------------------
    sentry_url: '',
    sentry_traceSampleRate: 0,
    sentry_environment: 'cypress',
    sentry_enabled: false,
  }
}

export const getTrellisConfig = () => {
  if (window.Cypress) {
    return getTestConfig()
  }
  return window.__runtime_configuration
}

/** The shared system configuration data. */
const trellisConfiguration = new Configuration(getTrellisConfig())

export default trellisConfiguration
