import { Observable } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { Col, Row } from 'antd'
import { FC } from 'react'

import { AddressControls } from 'trellis:components/_siteWide/address/addressControls'
import { FormInput } from 'trellis:components/_siteWide/form/formInput'
import { ProviderAutoComplete } from 'trellis:components/ProviderAutoComplete/ProviderAutoComplete'
import { ProviderDropdownOption } from 'trellis:features/providers/utils/provider-helpers'
import { validateNpi } from 'trellis:utilities/validators/npiValidators'

import { useClaimDetailContext } from '../../../context/claimDetailContext'

interface ClaimTreatingProviderProps {
  providerOptions: Observable<ProviderDropdownOption[]>
}

export const TreatingControls: FC<ClaimTreatingProviderProps> = observer(
  ({ providerOptions }) => {
    const { claim, setClaim } = useClaimDetailContext()

    return (
      <>
        <div className='page-section__title'>Treating Info</div>
        <Row>
          <Col span={24}>
            <ProviderAutoComplete
              name='TreatingName'
              options={providerOptions}
              setState={setClaim}
              state={claim}
            />
          </Col>
        </Row>
        <AddressControls
          prefix='Treatment'
          state={claim}
          setState={setClaim}
          section='provider'
        />
        <Row>
          <Col span={12}>
            <FormInput
              name='TreatingLicense'
              label='License'
              element={claim}
              setElement={setClaim}
              initialValue={claim.TreatingLicense}
              section='provider'
              required
            />
          </Col>
          <Col
            span={12}
            className='pl-100'
          >
            <FormInput
              name='IndividualNpi'
              label='Individual NPI'
              element={claim}
              setElement={setClaim}
              initialValue={claim.IndividualNpi}
              section='provider'
              validator={validateNpi}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <FormInput
              name='TreatmentProviderId'
              label='Provider ID'
              element={claim}
              setElement={setClaim}
              initialValue={claim.TreatmentProviderId}
            />
          </Col>
          <Col
            span={12}
            className='pl-100'
          >
            <FormInput
              name='SPECIALTY'
              label='Provider Specialty Code'
              element={claim}
              setElement={setClaim}
              initialValue={claim.SPECIALTY}
            />
          </Col>
        </Row>
      </>
    )
  },
)
