import { Col, Row, Space } from 'antd'
import React, { FC, ReactNode, useEffect, useRef } from 'react'
import { Cropper } from 'react-cropper'

import {
  getUrl,
  IImageOperation,
  ImageQuality,
} from '../../attachment.interface'
import { CropperConfiguration } from '../../constants'

import './attachmentImageDisplay.scss'

type AttachmentImageDisplayProps = {
  children: ReactNode
  imageData: File
  performOperations: IImageOperation
  imgForCanvas: HTMLImageElement
  setImgForCanvas: (currImg: HTMLImageElement) => void
  cropImgCallback: (cropImg: HTMLImageElement) => void
  currentCanvasToBlobCallback: (imgBlob: Blob) => void
}

const AttachmentImageDisplay: FC<AttachmentImageDisplayProps> = ({
  children,
  imageData,
  performOperations,
  imgForCanvas,
  setImgForCanvas,
  cropImgCallback,
  currentCanvasToBlobCallback,
}) => {
  const cropperRef = useRef(null)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  const mimeType = 'image/jpeg'

  const sendCurrentCanvasImgToDetailsPage = (canvas: HTMLCanvasElement) => {
    const img = new Image()
    img.src = canvas.toDataURL(mimeType)
    img.onload = () => {
      setImgForCanvas(img)
    }
  }

  const setCanvasDimensions = (
    canvas: HTMLCanvasElement,
    width: number,
    height: number,
  ) => {
    canvas.width = width
    canvas.height = height
  }

  const mirror = (context: CanvasRenderingContext2D) => {
    // Horizontal mirroring
    context.setTransform(-1, 0, 0, 1, 0, 0)
    context.drawImage(
      imgForCanvas,
      0,
      0,
      imgForCanvas.naturalWidth * -1,
      imgForCanvas.naturalHeight,
    )
    context.setTransform(1, 0, 0, 1, 0, 0)
  }

  const rotateCanvas = (context: CanvasRenderingContext2D) => {
    const newCanvasWidth = imgForCanvas.naturalHeight
    const newCanvasHeight = imgForCanvas.naturalWidth

    setCanvasDimensions(context.canvas, newCanvasWidth, newCanvasHeight)

    // Rotate clockwise 90 degrees
    context.setTransform(0, 1, -1, 0, imgForCanvas.naturalHeight, 0)
    context.drawImage(
      imgForCanvas,
      0,
      0,
      imgForCanvas.naturalWidth,
      imgForCanvas.naturalHeight,
    )
    context.setTransform(1, 0, 0, 1, 0, 0)
  }

  const truncate = (pixelValue: number): number => {
    if (pixelValue < 0) {
      return 0
    }
    if (pixelValue > 255) {
      return 255
    }

    return pixelValue
  }

  const brightenPixels = (
    context: CanvasRenderingContext2D,
    brightness: number,
  ) => {
    const imageData = context.getImageData(
      0,
      0,
      context.canvas.width,
      context.canvas.height,
    )
    const pixelDataFromImg = imageData.data

    for (let i: number = 0; i < pixelDataFromImg.length; i++) {
      if ((i + 1) % 4 !== 0) {
        pixelDataFromImg[i] = truncate(pixelDataFromImg[i] + brightness)
      }
    }

    context.putImageData(imageData, 0, 0)
  }

  const contrastPixels = (
    context: CanvasRenderingContext2D,
    contrastLevel: number,
  ) => {
    const imageData = context.getImageData(
      0,
      0,
      context.canvas.width,
      context.canvas.height,
    )
    const pixelDataFromImg = imageData.data
    const contrastFactor =
      (259.0 * (contrastLevel + 255.0)) / (255.0 * (259.0 - contrastLevel))

    for (let i: number = 0; i < pixelDataFromImg.length; i++) {
      if ((i + 1) % 4 !== 0) {
        pixelDataFromImg[i] = truncate(
          contrastFactor * (pixelDataFromImg[i] - 128) + 128,
        )
      }
    }

    context.putImageData(imageData, 0, 0)
  }

  const sharpenPixels = (
    context: CanvasRenderingContext2D,
    sharpness: number,
  ) => {
    const imgData = context.getImageData(
      0,
      0,
      context.canvas.width,
      context.canvas.height,
    )
    const pixelDataFromImg = imgData.data

    const mix = sharpness
    const w = imgData.width
    const h = imgData.height
    let x,
      sx,
      sy,
      r,
      g,
      b,
      dstOff,
      srcOff,
      wt,
      cx,
      cy,
      scy,
      scx,
      weights = [0, -1, 0, -1, 5, -1, 0, -1, 0],
      katet = 3, //Math.round(Math.sqrt(weights.length)),
      half = 1, //(katet * 0.5) | 0,
      dstData = canvasRef.current.getContext('2d').createImageData(w, h),
      dstBuff = dstData.data,
      srcBuff = pixelDataFromImg,
      y = h

    while (y--) {
      x = w
      while (x--) {
        sy = y
        sx = x
        dstOff = (y * w + x) * 4
        r = 0
        g = 0
        b = 0

        for (cy = 0; cy < katet; cy++) {
          for (cx = 0; cx < katet; cx++) {
            scy = sy + cy - half
            scx = sx + cx - half

            srcOff = (scy * w + scx) * 4
            wt = weights[cy * katet + cx]

            r += srcBuff[srcOff] * wt
            g += srcBuff[srcOff + 1] * wt
            b += srcBuff[srcOff + 2] * wt
          }
        }

        const inverseMix = 1 - mix
        dstBuff[dstOff] = r * mix + srcBuff[dstOff] * inverseMix
        dstBuff[dstOff + 1] = g * mix + srcBuff[dstOff + 1] * inverseMix
        dstBuff[dstOff + 2] = b * mix + srcBuff[dstOff + 2] * inverseMix
        dstBuff[dstOff + 3] = srcBuff[dstOff + 3]
      }
    }

    context.putImageData(dstData, 0, 0)
  }

  const createOrRestoreImgIntoCanvas = (context: CanvasRenderingContext2D) => {
    const localImage = new Image()
    localImage.src = getUrl(imageData)
    localImage.onload = () => {
      setCanvasDimensions(
        context.canvas,
        localImage.naturalWidth,
        localImage.naturalHeight,
      )
      context.drawImage(localImage, 0, 0)
      setImgForCanvas(localImage)
      sendCanvasImgBackToDetailsPage(context.canvas)
      updateCropperWithCanvasData(context)
    }
  }

  const addOriginalImgIntoCanvas = (context: CanvasRenderingContext2D) => {
    setCanvasDimensions(
      context.canvas,
      imgForCanvas.naturalWidth,
      imgForCanvas.naturalHeight,
    )

    context.setTransform(1, 0, 0, 1, 0, 0)
    context.drawImage(imgForCanvas, 0, 0)
    context.setTransform(1, 0, 0, 1, 0, 0)
  }

  const applyMatrixOpsSendImgBackToDetailsPage = (
    operations: IImageOperation,
    context: CanvasRenderingContext2D,
  ) => {
    if (operations) {
      if (operations.mirror) {
        mirror(context)
      }
      if (operations.rotation) {
        rotateCanvas(context)
      }

      sendCurrentCanvasImgToDetailsPage(context.canvas)
    }
  }

  const applyPixelOpsUpdateCropper = (
    operations: IImageOperation,
    context: CanvasRenderingContext2D,
  ) => {
    if (operations) {
      if (operations.sharpness !== 0) {
        sharpenPixels(context, operations.sharpness)
      }
      if (operations.contrast !== 0) {
        contrastPixels(context, operations.contrast)
      }
      if (operations.brightness !== 0) {
        brightenPixels(context, operations.brightness)
      }
    }
  }

  const updateCropperWithCanvasData = (context: CanvasRenderingContext2D) => {
    cropperRef.current.cropper.replace(context.canvas.toDataURL(mimeType))
  }

  const sendCroppedImgBackToDetailsPage = (
    startX: number,
    startY: number,
    boxWidth: number,
    boxHeight: number,
  ) => {
    const tmpCanvas: HTMLCanvasElement = document.createElement('canvas')
    tmpCanvas.width = boxWidth
    tmpCanvas.height = boxHeight

    const ctx = tmpCanvas.getContext('2d')
    ctx.drawImage(
      imgForCanvas,
      startX,
      startY,
      boxWidth,
      boxHeight,
      0,
      0,
      boxWidth,
      boxHeight,
    )
    const tmpImg = new Image()
    tmpImg.src = tmpCanvas.toDataURL(mimeType)
    tmpImg.onload = () => {
      cropImgCallback(tmpImg)
    }
  }

  const sendCanvasImgBackToDetailsPage = (canvas: HTMLCanvasElement) => {
    canvas.toBlob(currentCanvasToBlobCallback, mimeType, ImageQuality.MaxUnity)
  }

  const onCropEndSendCropBoxImgBack = () => {
    const cropperBoxData = cropperRef.current.cropper.getData(true)
    sendCroppedImgBackToDetailsPage(
      cropperBoxData.x,
      cropperBoxData.y,
      cropperBoxData.width,
      cropperBoxData.height,
    )
  }

  const onReady = () => {
    if (performOperations?.start_crop) {
      cropperRef.current.cropper.crop()
    } else {
      cropperRef.current.cropper.disable()
    }
  }

  useEffect(() => {
    const initializedCanvas = canvasRef.current

    // Initialize
    if (initializedCanvas) {
      const context = initializedCanvas.getContext('2d')

      if (!imgForCanvas || performOperations?.restore) {
        createOrRestoreImgIntoCanvas(context)
      } else {
        addOriginalImgIntoCanvas(context)
        applyMatrixOpsSendImgBackToDetailsPage(performOperations, context)
        applyPixelOpsUpdateCropper(performOperations, context)
        updateCropperWithCanvasData(context)
        sendCanvasImgBackToDetailsPage(initializedCanvas)

        if (performOperations?.start_crop) {
          sendCroppedImgBackToDetailsPage(
            0,
            0,
            imgForCanvas.width,
            imgForCanvas.height,
          )
        }
      }
    }
  }, [])

  return (
    <Row justify='start'>
      <Col
        span={24}
        className='attachment-image-display-container'
      >
        <Space
          direction='vertical'
          className='attachment-image-display-container__inner'
        >
          {children}
          <Space
            direction='vertical'
            align='center'
            className='attachment-image-display'
          >
            <h5 className='attachment-image-display__title'>Image Preview</h5>
            <Cropper
              background={false}
              zoomable={false}
              responsive={true}
              restore={true}
              autoCrop={false}
              scalable={false}
              viewMode={2}
              minContainerWidth={CropperConfiguration.minWidthConstraint}
              minContainerHeight={CropperConfiguration.minHeightConstraints}
              minCropBoxWidth={CropperConfiguration.minWidthConstraint}
              minCropBoxHeight={CropperConfiguration.minHeightConstraints}
              autoCropArea={CropperConfiguration.autoCropAreaSizeUnity}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              cropend={onCropEndSendCropBoxImgBack}
              ready={onReady}
              ref={cropperRef}
              className='cropper-image'
            />
          </Space>
        </Space>
      </Col>
      <Col span={24}>
        <canvas
          className='hidden-drawing-canvas'
          ref={canvasRef}
        />
      </Col>
    </Row>
  )
}

export default AttachmentImageDisplay
