import { ColumnsType } from 'antd/lib/table'
import Fuse from 'fuse.js'
import { ReactNode } from 'react'

import { capitalize } from 'utils'

import {
  DocumentCode,
  ProcedureCodeRequirement,
} from 'trellis:api/carrier/carrier-client'
import FuseHighlight from 'trellis:utilities/fuzzySearch/FuseHighlight'

import { TableDataType } from './_carrierAttachmentTypes'

export const transformRecipientsData = (
  ProcedureCodeRequirements: ProcedureCodeRequirement[],
): TableDataType[] => {
  return ProcedureCodeRequirements.map((ProcedureCodeRequirement) => {
    const transFormedData: TableDataType = {
      code: ProcedureCodeRequirement.ProcedureCode,
      description: ProcedureCodeRequirement.ProcedureCodeDescription,
      requirements: requirementsStrBuilder(
        ProcedureCodeRequirement.DocumentCodes,
      ),
      comments: ProcedureCodeRequirement.ProcedureCodeComment,
    }

    return transFormedData
  })
}

const requirementsStrBuilder = (documentCodes: DocumentCode[]): string => {
  return documentCodes.reduce(
    (codeStrAccumulator, currDocumentCode, currentIndex) => {
      let stringBuilder = codeStrAccumulator

      if (currentIndex === 0)
        stringBuilder = `${currDocumentCode.DocumentDescription}`
      else
        stringBuilder = `${codeStrAccumulator}, ${currDocumentCode.DocumentDescription}`

      return stringBuilder
    },
    '',
  )
}

const getFuseHighlight = (
  value: string,
  hits: Fuse.FuseResult<TableDataType>[],
  attribute: string,
) => {
  let element: ReactNode = <>{value}</>

  hits.forEach((hit: Fuse.FuseResult<TableDataType>) => {
    if (hit.item[attribute as keyof TableDataType] === value) {
      element = (
        <FuseHighlight
          hit={hit}
          attribute={attribute}
        />
      )
    }
  })

  return element
}

export const getColumns = (hits: Fuse.FuseResult<TableDataType>[]) => {
  const attachmentTableColumns: ColumnsType<TableDataType> = [
    {
      dataIndex: 'code',
      render: (value: string) => getFuseHighlight(value, hits, 'code'),
      title: 'Code',
      width: '4em',
    },
    {
      dataIndex: 'description',
      render: (value: string) =>
        getFuseHighlight(capitalize(value), hits, 'description'),
      title: 'Description',
      width: '12em',
    },
    {
      dataIndex: 'requirements',
      render: (value: string) => getFuseHighlight(value, hits, 'requirements'),
      title: 'Req.',
      width: '7em',
    },
    {
      dataIndex: 'comments',
      render: (value: string) => getFuseHighlight(value, hits, 'comments'),
      title: 'Comments',
      width: '9em',
    },
  ]

  return attachmentTableColumns
}
