export const validateName = (value: string) => {
  const isEmptyOrOnlySpaces = /^\s*$/.test(value)
  const isValid = /^[A-Za-z0-9 \-\']+$/.test(value)
  const isLength = /^.{0,36}$/.test(value)

  if (isEmptyOrOnlySpaces) {
    return 'Field cannot be empty'
  } else if (!isValid && isLength) {
    return 'Only word characters allowed'
  } else if (isValid && !isLength) {
    return 'Max name length is 36 characters'
  } else if (!isValid && !isLength) {
    return 'Only word chracters only and max length 36 characters alowed'
  } else {
    return ''
  }
}
