import { Form } from 'antd'
import { Rule } from 'antd/lib/form'
import { FC, FormEvent, useEffect, useState } from 'react'
import { PatternFormat } from 'react-number-format'

import { spacesToProperty } from 'utils'

import '../../_formStyles.scss'

// TODO: update to handle nested form items

// NOTE: form items also accept dependencies if validation is dependent on another field
// TODO: update to handle dependencies (e.g. confirm password is dependent on password)

interface FormMaskInputProps {
  className?: string
  disabled?: boolean
  floatLabel?: boolean
  format: string
  label?: string
  mask: string
  name: string
  placeholder?: string
  required?: boolean
  validator?: (value: string) => void
}

export const FormMaskInput: FC<FormMaskInputProps> = ({
  className,
  disabled = false,
  floatLabel = true,
  format,
  label,
  mask,
  name,
  placeholder = undefined,
  required = false,
  validator,
}) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [isLabelFloating, setIsLabelFloating] = useState<boolean>(false)

  const form = Form.useFormInstance()

  useEffect(() => {
    if (!isLabelFloating && (form.getFieldValue(name) || placeholder))
      setIsLabelFloating(true)
    else if (!form.getFieldValue(name) && !placeholder)
      setIsLabelFloating(false)

    setInputValue(form.getFieldValue(name))
  }, [form.getFieldValue(name)])

  const numberFormatClasses = ` 
        ant-input 
        ${!floatLabel ? 'no-float' : ''}
    `

  const floatLabelClasses = `
        ant-form-item__float-label 
        ${
          isLabelFloating
            ? 'ant-form-item__float-label--true'
            : 'ant-form-item__float-label--false'
        } 
    `

  const getRules = () => {
    let rules = []

    rules.push({
      message: `${spacesToProperty(label || (name as string))} is required`,
      required: required,
    })

    if (validator && inputValue && !disabled)
      rules.push({ validator: (_: Rule, value: string) => validator(value) })

    return rules
  }

  const handleOnBlur = () => {
    if (floatLabel && (inputValue || placeholder)) setIsLabelFloating(true)
    else setIsLabelFloating(false)
  }

  const handleOnChange = (event: FormEvent<HTMLInputElement>) => {
    if (floatLabel && event.currentTarget.value) setIsLabelFloating(true)
  }

  return (
    <div className='form-item-validation-container'>
      <Form.Item
        className={`${className} ${floatLabel && floatLabelClasses}`}
        label={label}
        name={name}
        rules={getRules()}
        validateFirst={true}
      >
        <PatternFormat
          className={numberFormatClasses}
          disabled={disabled}
          format={format}
          mask={mask}
          onBlur={handleOnBlur}
          onFocus={() => setIsLabelFloating(floatLabel ? true : false)}
          onChange={handleOnChange}
          placeholder={placeholder}
        />
      </Form.Item>
    </div>
  )
}
