import { createContext, FC, ReactNode, useContext, useState } from 'react'

import { LogError } from 'utils'

import { CustomerUserModel } from 'trellis:api/account/account-client'
import { PracticeInfo } from 'trellis:api/practice/practice-client'
import GlobalState, { TrellisJWT } from 'trellis:state/globalState'

import {
  fetchTrellisProviderDetails,
  TrellisProviderInfo,
} from '../api/provider/providerApi'
import { NotifyText } from '../constants/notifyText'
import { IAuthenticatedApiModel } from '../utilities/api'
import { showMessage } from '../utilities/general'
import { observer } from '@legendapp/state/react'

export type PracticeDetailsResponse = {
  trellisPracticeInfo: PracticeInfo
}

export interface ProviderFields {
  isDefault: boolean
  licenseNumber: string
  providerFirstName: string
  providerLastName: string
  providerNPI: string
  serviceType: string
  taxId: string
  taxonomyCode: string
}

type GlobalContextType = {
  authentication: IAuthenticatedApiModel
  decodedJWT: TrellisJWT
  practiceDetails: PracticeInfo
  updatePracticeDetails: (practiceInfo: PracticeInfo) => void
  trellisProviderDetails: TrellisProviderInfo
  getTrellisProviderDetails: (refresh?: boolean) => Promise<void>
  userInfo: CustomerUserModel
}

const GlobalContext = createContext<GlobalContextType>(null)

export const GlobalContextProvider: FC<{ children: ReactNode }> = observer(({
  children,
}) => {
  const authentication = GlobalState.Auth.get()
  const decodedJWT = GlobalState.DecodedJwt.get()
  const practiceDetails = GlobalState.PracticeInfo.get()
  const userInfo = GlobalState.UserInfo.get()

  const [trellisProviderDetails, setTrellisProviderDetails] =
    useState<TrellisProviderInfo>()

  const updatePracticeDetails = (practiceInfo: PracticeInfo) => {
    GlobalState.PracticeInfo.set(practiceInfo)
  }

  const getTrellisProviderDetails = async (refresh: boolean = false) => {
    if (!trellisProviderDetails || refresh) {
      return fetchTrellisProviderDetails()
        .then((data) => {
          setTrellisProviderDetails(data)
        })
        .catch((ex) => {
          LogError(ex, 'error getting providers')
          showMessage(NotifyText.getProvidersError, 'error')
        })
    }
  }

  return (
    <GlobalContext.Provider
      value={{
        authentication,
        decodedJWT,
        practiceDetails,
        updatePracticeDetails,
        trellisProviderDetails,
        getTrellisProviderDetails,
        userInfo,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
})

export const useGlobalContext = () => {
  const context = useContext(GlobalContext)

  if (context === undefined)
    throw new Error('Context must be used within a provider')

  return context
}
