import { DownOutlined } from '@ant-design/icons'
import { Col, Dropdown, MenuProps, Tag } from 'antd'
import React, { FC, ReactNode } from 'react'

import {
  QuickFilters,
  setQuickFilterClasses,
  setTagBorderColor,
  StatusFilterOption,
} from '../../../constants/statusFilters'

import './statusFilter.scss'

import { hasProperties } from 'trellis:utilities/general'

import { EntryDisplayType, ObjAnyPropsType } from '../table/SmartTableTypes'

type MenuItem = Required<MenuProps>['items'][number]

type StatusFilterProps = {
  filterState: any
  setFilterState: any
  loading: boolean
  filterOptions: StatusFilterOption[]
  hideTags: boolean
  quickFilters?: QuickFilters[]
}

export const StatusFilter: FC<StatusFilterProps> = ({
  filterState,
  setFilterState,
  loading,
  quickFilters,
  filterOptions,
  hideTags,
}) => {
  const gridFilters: ObjAnyPropsType = filterState.Filters
  const gridFilterProperties: boolean = hasProperties(gridFilters.Config)
  const gridFilterEntries: [string, EntryDisplayType][] = Object.entries(
    gridFilters.Config,
  )

  const onStatusFilterClick = (filter: string) => {
    const copy = { ...filterState }
    copy.Key = ++copy.Key
    copy.Filters.CurrentPage = 1
    if (copy.Filters.Config['Status'] === filter) resetStatusFilter()
    else {
      copy.Filters.Config['Status'] = filter
      setFilterState(copy)
    }
  }

  const resetStatusFilter = () => {
    const copy = { ...filterState }
    copy.Key = ++copy.Key
    copy.Filters.CurrentPage = 1
    delete copy.Filters.Config['Status']
    setFilterState(copy)
  }

  const getMenuItem = (key: string, icon: ReactNode): MenuItem => {
    return {
      key,
      icon,
    }
  }

  const getMenuItems = (): MenuItem[] => {
    const items = filterOptions
      .filter((item) => !item?.Hidden)
      .map((option: StatusFilterOption) =>
        getMenuItem(
          option.Value,
          <div className='status-popover-option'>
            {option?.Icon}
            <p
              className='option-title'
              onClick={() => onStatusFilterClick(option?.Value)}
            >
              {option.Label}
            </p>
          </div>,
        ),
      )
    items.push(
      getMenuItem(
        'reset',
        <div className='reset-filters'>
          <p
            onClick={resetStatusFilter}
            className='reset-filters__link'
          >
            Reset
          </p>
        </div>,
      ),
    )

    return items
  }

  const items: MenuItem[] = getMenuItems()

  const removeFilter = (property: string) => {
    if (!loading) {
      const copy = { ...filterState }
      copy.Key = ++copy.Key
      delete copy.Filters.Config[property]
      setFilterState(copy)
    }
  }

  return (
    filterOptions.length > 0 && (
      <Col
        flex={1}
        className='status-filter-container'
      >
        <Dropdown
          menu={{ className: 'status-popover-container', items }}
          trigger={['click']}
          disabled={loading}
        >
          <button
            className='status-filter-button filter-by'
            disabled={loading}
          >
            <p style={{ fontSize: '0.875rem' }}>Filter By</p>
            <DownOutlined style={{ width: 12, height: 12 }} />
          </button>
        </Dropdown>
        {quickFilters?.map((quickFilter: string) => {
          return (
            <button
              className={setQuickFilterClasses(
                quickFilter,
                filterState?.Filters?.Config['Status'],
              )}
              disabled={loading}
              key={quickFilter}
              onClick={() => onStatusFilterClick(quickFilter)}
            >
              {
                filterOptions.find((filter) => filter.Value === quickFilter)
                  ?.Icon
              }
              {
                filterOptions.find((filter) => filter.Value === quickFilter)
                  ?.Label
              }
            </button>
          )
        })}
        {gridFilterProperties &&
          filterState?.Filters?.Config['Status'] &&
          !hideTags &&
          gridFilterEntries.map(
            (entry: [string, EntryDisplayType], index: number) => {
              if (entry[0] === 'Status') {
                let entryDisplay: string

                switch (entry[1]) {
                  case 'In Queue':
                    entryDisplay = 'Ready'
                    break
                  case 'Failed Validation,Pending,Other Coverage':
                    entryDisplay = 'Needs Attention'
                    break
                  case 'Hold':
                    entryDisplay = 'Holding'
                    break
                  default:
                    entryDisplay = entry[1]
                }

                return (
                  <Tag
                    key={index}
                    closable
                    onClose={() => removeFilter('Status')}
                    className='status-filter-tag'
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      borderRadius: '1.5em',
                      border: `1px solid ${setTagBorderColor(entry[1])}`,
                      height: '2rem',
                    }}
                  >
                    <p className='status-filter-tag__text'>{entryDisplay}</p>
                  </Tag>
                )
              }
            },
          )}
      </Col>
    )
  )
}
