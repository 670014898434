import { MenuOutlined } from '@ant-design/icons'
import { Button, Col, Row, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'

import { arrayMove } from '../../../../utilities/general'
import { useAttachmentsContext } from '../../Context/AttachmentsContext'

import './columnSelection.scss'

const store = window.localStorage

export interface DisplayColumn {
  id: number
  property: string
  title: string
  show: boolean
}

export const ColumnSelection = ({ setShowingSelection }: any) => {
  const {
    visibleColumns,
    setVisibleColumns,
    getFullColumnList,
    getDefaultColumns,
    activeTab,
  } = useAttachmentsContext()
  const [items, setItems] = useState<DisplayColumn[]>([])

  useEffect(() => {
    const orderedColumns: DisplayColumn[] = []
    const fullColumnList: any = getFullColumnList()

    visibleColumns.forEach((dataIndex: string) => {
      const column = fullColumnList.find((d: any) => d.dataIndex === dataIndex)
      if (column) {
        orderedColumns.push({
          id: 0,
          property: column.dataIndex,
          title: column.title,
          show: true,
        })
      }
    })

    fullColumnList.forEach((column: any) => {
      if (!visibleColumns.includes(column.dataIndex)) {
        orderedColumns.push({
          id: 0,
          property: column.dataIndex,
          title: column.title,
          show: false,
        })
      }
    })

    setItems(resetIds(orderedColumns))
  }, [visibleColumns])

  const resetIds = (options: any) => {
    let counter = 0
    options.map((o: any) => {
      o.id = counter++
    })
    return options
  }

  const onChange = (property: string, show: boolean) => {
    const copy = [...items]
    const match = copy.filter((c) => c.property === property)[0]
    match.show = !show
    setItems(copy)
  }

  const setDefaultColumns = (columns: any) => {
    setVisibleColumns(columns)
    setShowingSelection(false)
    store.setItem(
      `attachment-${activeTab}-default-columns`,
      JSON.stringify(columns),
    )
  }

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    let newOrder = arrayMove(items, oldIndex, newIndex)
    newOrder = resetIds(newOrder)
    setItems(newOrder)
  }

  const resetItems = () => {
    const values: any = []
    const defaultColumns = getDefaultColumns()
    items.map((c: any) => {
      c.show = defaultColumns.includes(c.property)
      values.push(c)
    })

    resetIds(values)
    setItems(values)
    setDefaultColumns(getDefaultColumns())
    store.removeItem(`attachment-${activeTab}-default-columns`)
  }

  const apply = () => {
    const values: any = []
    items.map((c: any) => c.show && values.push(c.property))
    setDefaultColumns(values)
  }

  const DragHandle = SortableHandle(() => (
    <MenuOutlined className='column-menu-grab' />
  ))

  const SortableItem = SortableElement(({ property, show, title }: any) => {
    return (
      <Row className='column-select-row'>
        <Col>
          <DragHandle />
        </Col>
        <Col
          flex={1}
          className='pl-100'
        >
          {title}
        </Col>
        <Col className='pl-100'>
          <Switch
            checkedChildren='Yes'
            unCheckedChildren='No'
            checked={show}
            onChange={() => onChange(property, show)}
          />
        </Col>
      </Row>
    )
  })

  const SortableListContainer = SortableContainer(() => (
    <div>
      {items.map(({ id, property, title, show }: any) => (
        <SortableItem
          key={id}
          index={id}
          // @ts-expect-error TODO: fix this
          property={property}
          title={title}
          show={show}
        />
      ))}
      <Row className='claim-setting-actions'>
        <Col flex={1}>
          <Button onClick={resetItems}>Reset</Button>
        </Col>
        <Col>
          <Button
            onClick={apply}
            type='primary'
          >
            Apply
          </Button>
        </Col>
      </Row>
    </div>
  ))

  return (
    <SortableListContainer
      onSortEnd={onSortEnd}
      useDragHandle={true}
      lockAxis='y'
    />
  )
}
