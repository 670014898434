export const spacesToProperty = (property: string) => {
  let propertyName = property
  if (Array.isArray(property)) propertyName = property[1]
  if (property.includes('_')) propertyName = propertyName.replace('_', ' ')
  return capitalize(propertyName.replace(/([a-z])([A-Z])/g, '$1 $2'))
}

export const capitalize = (property: string) => {
  return property && property.charAt(0).toUpperCase() + property.slice(1)
}

/**
Formats a zip, if it's 9 characters long it'll format it as XXXXX-XXXX
*/
export const formatZip = (zip?: string | number) => {
  if (!zip) return ''

  // if zip is a number, convert it to a string
  const zipText = zip.toString().trim()

  return zipText.length === 9
    ? `${zipText.slice(0, 5)}-${zipText.slice(5)}`
    : zipText
}
