import { ObservablePrimitive } from '@legendapp/state'
import { observer, useObservable } from '@legendapp/state/react'
import { syncObservable } from '@legendapp/state/sync'
import { useForm } from '@tanstack/react-form'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Modal, Radio, Switch } from 'antd'
import { FC, useEffect } from 'react'

import { Button, LoadingSpinner } from 'ui'

import {
  SerialOption,
  UpdateClaimSettingsRequest,
} from 'trellis:api/claim/claim-client'
import {
  GetClaimSettings,
  UpdateClaimSettings,
  UpdateSerialOption,
} from 'trellis:api/claim/claimApi'
import GlobalState from 'trellis:state/globalState'
import { showMessage } from 'trellis:utilities/general'

import styles from './ClaimSettingsModal.module.scss'

type ClaimSettingsModalProps = {
  showClaimSettingsModal$: ObservablePrimitive<boolean>
}

export const ClaimSettingsModal: FC<ClaimSettingsModalProps> = observer(
  ({ showClaimSettingsModal$ }) => {
    const historySetting$ = useObservable<string>('')
    syncObservable(historySetting$, {
      persist: {
        name: 'historySetting',
      },
    })

    const updatedSerials$ = useObservable<SerialOption[]>([])

    const getClaimSettings = async () => {
      const { data } = await GetClaimSettings()
      GlobalState.ClaimSettings.set(data)
      return data
    }

    const {
      data: claimSettingsData,
      isError,
      isFetching,
      isFetched,
    } = useQuery({
      queryKey: ['claimSettingsData', GlobalState.ClaimSettings.get()],
      queryFn: getClaimSettings,
      refetchOnWindowFocus: false,
    })

    useEffect(() => {
      if (claimSettingsData) {
        GlobalState.ClaimSettings.set(claimSettingsData)
      }
    }, [isFetched])

    const { mutate: updateSerialOption } = useMutation({
      mutationFn: (serial: SerialOption) => UpdateSerialOption(serial),
    })

    const { mutate: updateClaimSettings } = useMutation({
      mutationFn: (request: UpdateClaimSettingsRequest) =>
        UpdateClaimSettings(request),
    })

    const form = useForm({
      defaultValues: {
        serials: claimSettingsData
          ? GlobalState.ClaimSettings.Serials?.get()
          : [],
      },
      onSubmit: async ({ value }) => {
        const updatedSerials = updatedSerials$.peek()

        updatedSerials.map((serial) => {
          const request: SerialOption = {
            Serial_ID: serial.Serial_ID,
            Serial_Nickname: serial.Serial_Nickname,
          }
          updateSerialOption(request)
        })

        GlobalState.ClaimSettings.Serials.set(value.serials)
        updatedSerials$.set([])

        showMessage(
          `Successfully updated ${updatedSerials.length} install names`,
          'success',
        )
      },
    })

    const handleIsolateChange = async (checked: boolean) => {
      const request: UpdateClaimSettingsRequest = {
        Isolate: checked,
        ManualClaimEntry: claimSettingsData?.ManualClaimEntry as boolean,
      }
      updateClaimSettings(request)
      GlobalState.ClaimSettings.Isolate.set(checked)

      showMessage(
        `Filter claims by install has been turned ${checked ? 'on' : 'off'}`,
        'success',
      )
    }

    const handleManualClaimEntryChange = async (checked: boolean) => {
      const request: UpdateClaimSettingsRequest = {
        Isolate: claimSettingsData?.Isolate,
        ManualClaimEntry: checked,
      }
      updateClaimSettings(request)
      GlobalState.ClaimSettings.ManualClaimEntry.set(checked)

      showMessage(
        `Manual claim entry has been turned ${checked ? 'on' : 'off'}`,
        'success',
      )
    }

    if (isError) {
      showClaimSettingsModal$.set(false)
      showMessage('Claim Settings failed to load. Please try again.')
    }

    return (
      <Modal
        styles={{
          body: {
            backgroundColor: 'var(--grey-100, #fafcff)',
            height: window.innerHeight - 100,
            maxHeight: window.innerHeight - 100,
          }
        }}
        destroyOnClose
        title='Claim Settings'
        onCancel={() => showClaimSettingsModal$.set(false)}
        open={showClaimSettingsModal$.get()}
        width={900}
        footer={[
          <Button
            key='close'
            onClick={() => showClaimSettingsModal$.set(false)}
            label='Close'
          />,
        ]}
      >
        {isFetching ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className={styles['claim-settings__row']}>
              <div className={styles['claim-settings__row--section']}>
                <p className={styles['claim-settings__row--section-header']}>
                  Date Filter
                </p>
                <p className={styles['claim-settings__row--section-text']}>
                  Choose a preferred date range to view claim data, with
                  additional customizing options in the date selector.
                </p>
                <div
                  className={
                    styles['claim-settings__row--section-radio-content']
                  }
                >
                  <Radio.Group
                    defaultValue={historySetting$.peek() || 15}
                    onChange={(e) =>
                      historySetting$.set(e.target.value as string)
                    }
                  >
                    <Radio value={0}>Today</Radio>
                    <Radio value={7}>Last 7 Days</Radio>
                    <Radio value={15}>Last 15 Days</Radio>
                  </Radio.Group>
                </div>
              </div>
              <div className={styles['claim-settings__row--section']}>
                <p className={styles['claim-settings__row--section-header']}>
                  Filter Claims
                </p>
                <p className={styles['claim-settings__row--section-text']}>
                  Add an option to claims toolbar, for viewing claims submitted
                  from this computer.
                </p>
                <div className={styles['claim-settings__row--section-content']}>
                  <p>Show install filter</p>
                  <Switch
                    checkedChildren='ON'
                    unCheckedChildren='OFF'
                    defaultChecked={GlobalState.ClaimSettings.get()?.Isolate}
                    onChange={async (checked) => handleIsolateChange(checked)}
                  />
                </div>
              </div>
            </div>
            <div className={styles['claim-settings__row']}>
              <div className={styles['claim-settings__row--section']}>
                <p className={styles['claim-settings__row--section-header']}>
                  Manual Claim Entry
                </p>
                <p className={styles['claim-settings__row--section-text']}>
                  Activate manual entry. A 'Create Manual Claim,' option will
                  display in the top right of the Claim Management screen.
                </p>
                <div className={styles['claim-settings__row--section-content']}>
                  <p>Enable manual claim entry</p>
                  <Switch
                    checkedChildren='ON'
                    unCheckedChildren='OFF'
                    defaultChecked={
                        GlobalState.ClaimSettings.get()
                            ?.ManualClaimEntry as boolean
                    }
                    onChange={async (checked) =>
                      handleManualClaimEntryChange(checked)
                    }
                  />
                </div>
              </div>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                e.stopPropagation()
                form.handleSubmit()
              }}
            >
              <div>
                <p className={styles['claim-settings__row--section-header']}>
                  Trellis Installs
                </p>
                <p className={styles['claim-settings__row--section-text']}>
                  Manage Trellis install information
                </p>
                <table className={styles['claim-settings__table']}>
                  <thead>
                    <tr className={styles['claim-settings__table-row-header']}>
                      <th
                        className={
                          styles['claim-settings__table-row-header-item']
                        }
                      >
                        Serial
                      </th>
                      <th
                        className={
                          styles['claim-settings__table-row-header-item']
                        }
                      >
                        Computer Name
                      </th>
                      <th
                        className={
                          styles['claim-settings__table-row-header-item']
                        }
                      >
                        Install Name
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <form.Field
                      name='serials'
                      mode='array'
                    >
                      {(field) => {
                        return (
                          <>
                            {field.state.value.map((serial, i) => {
                              return (
                                <tr
                                  key={i}
                                  className={
                                    styles['claim-settings__table-row-body']
                                  }
                                >
                                  <td>{serial.Serial_ID}</td>
                                  <td>{serial.PC_Name}</td>
                                  <td>
                                    <form.Field
                                      key={i}
                                      name={`serials[${i}].Serial_Nickname`}
                                      validators={{
                                        onChange: ({ value }) =>
                                          !value &&
                                          'Serial Nickname is required',
                                      }}
                                    >
                                      {(installName) => {
                                        return (
                                          <div>
                                            <input
                                              id={installName.name}
                                              name={installName.name}
                                              className={
                                                styles[
                                                  'claim-settings__table--input-nickname'
                                                ]
                                              }
                                              value={
                                                installName.state
                                                  .value as string
                                              }
                                              onChange={(e) => {
                                                installName.handleChange(
                                                  e.target.value,
                                                )
                                              }}
                                              onBlur={(e) => {
                                                const { isDirty } =
                                                  installName.state.meta
                                                if (isDirty) {
                                                  const serials =
                                                    updatedSerials$.peek()
                                                  !serials.includes(serial) &&
                                                    serials.push({
                                                      ...serial,
                                                      Serial_Nickname:
                                                        e.target.value,
                                                    })
                                                  updatedSerials$.set(serials)
                                                }
                                              }}
                                            />
                                            {installName.state.meta.errors ? (
                                              <p
                                                role='alert'
                                                className={
                                                  styles[
                                                    'claim-settings__form-error'
                                                  ]
                                                }
                                              >
                                                {installName.state.meta.errors.join(
                                                  ', ',
                                                )}
                                              </p>
                                            ) : null}
                                          </div>
                                        )
                                      }}
                                    </form.Field>
                                  </td>
                                </tr>
                              )
                            })}
                          </>
                        )
                      }}
                    </form.Field>
                  </tbody>
                </table>
              </div>
              <form.Subscribe
                selector={(state) => [state.canSubmit]}
                children={([canSubmit]) => (
                  <>
                    <Button
                      disabled={!canSubmit}
                      type='primary'
                      htmlType='submit'
                      label='Save'
                      className={styles['claim-settings--save']}
                    />
                  </>
                )}
              />
            </form>
          </>
        )}
      </Modal>
    )
  },
)
