import { List } from 'antd'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { SearchBar, SearchState } from 'ui'

import { EligibilityPatient } from '../../../../../api/eligibility/eligibility-client'
import { useEligibilityContext } from '../../../shared/context/EligibilityContext'
import { ParticipatingCarrier } from '../../../shared/utilities/eligibilityTyping'

interface SearchCarrierListProps {
  patient: EligibilityPatient
  setPatient: Dispatch<SetStateAction<EligibilityPatient>>
}

const SearchCarrierList: FC<SearchCarrierListProps> = ({
  patient,
  setPatient,
}) => {
  const { participatingCarriers } = useEligibilityContext()

  const [loading, setLoading] = useState<boolean>(false)
  const [carriers, setCarriers] = useState<ParticipatingCarrier[]>([])
  const [requestState, setRequestState] = useState<SearchState>({
    Filters: {
      Name: '',
    },
  })

  useEffect(() => {
    getCarriers()
  }, [requestState.Filters.Name])

  const getCarriers = () => {
    setLoading(true)

    const filteredOptions = participatingCarriers.filter(
      (carrier: ParticipatingCarrier) =>
        carrier.CarrierName?.toLowerCase().includes(requestState.Filters.Name),
    )

    setTimeout(() => {
      setCarriers(filteredOptions)
      setLoading(false)
    }, 500)
  }

  const handleRowClick = (item: ParticipatingCarrier) => {
    setPatient({
      ...patient,
      CarrierId: item.CarrierId,
      CarrierName: item.CarrierName,
      PatientId: '0',
    })
  }

  return (
    <>
      <div className='search-list__header'>
        <h4 className='mb-100'>
          To add a new patient, first select an eligible carrier
        </h4>
        <SearchBar
          placeholder='Search by carrier name or group'
          state={requestState}
          setState={setRequestState}
        />
      </div>
      <List
        dataSource={carriers}
        itemLayout='horizontal'
        loading={loading}
        renderItem={(item: ParticipatingCarrier) => (
          <List.Item onClick={() => handleRowClick(item)}>
            <List.Item.Meta title={item.CarrierName} />
          </List.Item>
        )}
      />
    </>
  )
}

export default SearchCarrierList
