import { ChangeEvent, FC, useEffect, useState } from 'react'

import styles from './SearchBox.module.scss'

interface SearchBoxProps {
  allowClear?: boolean
  maxLength?: number
  onSearch: (value: string) => void
  placeholder?: string
  searchOnClick?: boolean
}

export const SearchBox: FC<SearchBoxProps> = ({
  allowClear = true,
  maxLength = Infinity,
  onSearch,
  placeholder = 'Search',
  searchOnClick = false,
}) => {
  const [searchText, setSearchText] = useState<string>('')

  useEffect(() => {
    if (!searchOnClick) {
      const delayDebounceFn = setTimeout(() => {
        onSearch(searchText)
      }, 1500)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [searchText])

  return (
    <div className={styles['vyne-search-box']}>
      <div className={styles['vyne-search-box__input-container']}>
        <input
          autoComplete='off'
          maxLength={maxLength}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setSearchText(e.target.value)
          }
          placeholder={placeholder}
          type='text'
          value={searchText}
        />
        {allowClear && searchText && (
          <div
            aria-label='clear search'
            className={styles['vyne-search-box__clear']}
            onClick={() => setSearchText('')}
          >
            <p>x</p>
          </div>
        )}
      </div>
      <div
        aria-label='trigger search'
        className={styles['vyne-search-box__search-icon-container']}
        onClick={() => onSearch(searchText)}
      >
        <svg
          width='0.75rem'
          height='0.75rem'
          viewBox='0 0 12 12'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M8.50326 7.50335H7.97659L7.78992 7.32335C8.44326 6.56335 8.83659 5.57668 8.83659 4.50335C8.83659 2.11001 6.89659 0.170013 4.50326 0.170013C2.10992 0.170013 0.169922 2.11001 0.169922 4.50335C0.169922 6.89668 2.10992 8.83668 4.50326 8.83668C5.57659 8.83668 6.56326 8.44335 7.32326 7.79001L7.50326 7.97668V8.50335L10.8366 11.83L11.8299 10.8367L8.50326 7.50335ZM4.50326 7.50335C2.84326 7.50335 1.50326 6.16335 1.50326 4.50335C1.50326 2.84335 2.84326 1.50335 4.50326 1.50335C6.16326 1.50335 7.50326 2.84335 7.50326 4.50335C7.50326 6.16335 6.16326 7.50335 4.50326 7.50335Z'
            fill='#5D5D5D'
          />
        </svg>
      </div>
    </div>
  )
}
