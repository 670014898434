import { Form, Select } from 'antd'

import { spacesToProperty } from '../../../utilities/general'
import { validateProperty } from '../../../utilities/validators/baseValidator'
import { FormFloatLabel } from './formFloatLabel'
import { getError, getRules, handleFormChange } from './util'

export const FormSelect = ({
  name,
  element,
  setElement,
  initialValue,
  validator,
  label = null,
  section = null,
  parentProperty = null,
  width = '100%',
  options = [],
  required = false,
  ...others
}: any) => {
  const { Option } = Select
  const error = element && getError(name, element)

  const onChange = (value: string) => {
    const updatedElement = handleFormChange(
      name,
      parentProperty,
      value,
      element,
    )
    validateProperty(
      validator,
      updatedElement,
      name,
      parentProperty,
      required,
      section,
    )

    setElement(updatedElement)
  }

  const select = (
    <FormFloatLabel
      label={label || spacesToProperty(name)}
      name={name}
      inputValue={initialValue}
      select
    >
      <Select
        value={initialValue}
        style={{ width: width }}
        onChange={onChange}
        {...others}
        data-testid={name}
      >
        {options.map((option: any) => (
          <Option
            key={option.value || option.CodeValue}
            value={option.value || option.CodeValue}
          >
            {option.text || option.CodeDescription}
          </Option>
        ))}
      </Select>
    </FormFloatLabel>
  )

  if (!error) {
    return (
      <div className='form-item-validation-container'>
        <Form.Item name={name}>{select}</Form.Item>
      </div>
    )
  }

  if (error) {
    return (
      <div className='form-item-validation-container'>
        <Form.Item
          name={name}
          validateStatus='error'
          help={error.message}
        >
          {select}
        </Form.Item>
      </div>
    )
  }

  return (
    <div className='form-item-validation-container'>
      <Form.Item
        name={name}
        rules={[getRules(name, required)]}
      >
        {select}
      </Form.Item>
    </div>
  )
}
