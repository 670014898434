/* tslint:disable */
/* eslint-disable */
/**
 * Practice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BillingDetailsResponse
 */
export interface BillingDetailsResponse {
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof BillingDetailsResponse
     */
    'statusCode'?: HttpStatusCode;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailsResponse
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailsResponse
     */
    'cardType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailsResponse
     */
    'expDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailsResponse
     */
    'facilityId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailsResponse
     */
    'lastFour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailsResponse
     */
    'paymentType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BillingDetailsResponse
     */
    'customerId'?: number;
}
/**
 * 
 * @export
 * @interface CreateUpdateProviderResponse
 */
export interface CreateUpdateProviderResponse {
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof CreateUpdateProviderResponse
     */
    'statusCode'?: HttpStatusCode;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProviderResponse
     */
    'message'?: string | null;
    /**
     * 
     * @type {EligibilityDefaultProvider}
     * @memberof CreateUpdateProviderResponse
     */
    'provider'?: EligibilityDefaultProvider;
}
/**
 * 
 * @export
 * @interface CustomerAddress
 */
export interface CustomerAddress {
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    'postalCode'?: string | null;
}
/**
 * 
 * @export
 * @interface DownloadSoftwareItem
 */
export interface DownloadSoftwareItem {
    /**
     * 
     * @type {string}
     * @memberof DownloadSoftwareItem
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DownloadSoftwareItem
     */
    'releaseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DownloadSoftwareItem
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DownloadSoftwareItem
     */
    'version'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DownloadSoftwareItem
     */
    'url'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DownloadSoftwareItem
     */
    'beta'?: boolean;
    /**
     * 
     * @type {Version}
     * @memberof DownloadSoftwareItem
     */
    'versionNumber'?: Version;
    /**
     * 
     * @type {string}
     * @memberof DownloadSoftwareItem
     */
    'productName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DownloadSoftwareItem
     */
    'systemReqsUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DownloadSoftwareItem
     */
    'installGuideUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DownloadSoftwareItem
     */
    'mailToSubject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DownloadSoftwareItem
     */
    'mailToBody'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DownloadSoftwareItem
     */
    'confirmationPromptTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DownloadSoftwareItem
     */
    'confirmationPromptMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DownloadSoftwareItem
     */
    'tagline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DownloadSoftwareItem
     */
    'logoUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DownloadSoftwareItem
     */
    'inAdditionalUtilities'?: boolean;
}
/**
 * 
 * @export
 * @interface DownloadsResponse
 */
export interface DownloadsResponse {
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof DownloadsResponse
     */
    'statusCode'?: HttpStatusCode;
    /**
     * 
     * @type {string}
     * @memberof DownloadsResponse
     */
    'message'?: string | null;
    /**
     * 
     * @type {Array<DownloadSoftwareItem>}
     * @memberof DownloadsResponse
     */
    'downloads'?: Array<DownloadSoftwareItem> | null;
}
/**
 * 
 * @export
 * @interface EligibilityDefaultProvider
 */
export interface EligibilityDefaultProvider {
    /**
     * 
     * @type {number}
     * @memberof EligibilityDefaultProvider
     */
    'eligibilityDefaultProviderID'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EligibilityDefaultProvider
     */
    'fastAttachProviderID'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EligibilityDefaultProvider
     */
    'globalCustomerID'?: number;
    /**
     * 
     * @type {string}
     * @memberof EligibilityDefaultProvider
     */
    'providerLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityDefaultProvider
     */
    'providerFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityDefaultProvider
     */
    'providerNPI'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityDefaultProvider
     */
    'taxonomyCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityDefaultProvider
     */
    'taxId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityDefaultProvider
     */
    'licenseNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityDefaultProvider
     */
    'serviceType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EligibilityDefaultProvider
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EligibilityDefaultProvider
     */
    'addDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityDefaultProvider
     */
    'lastUpdateDate'?: string | null;
}
/**
 * 
 * @export
 * @interface GetConstantsResponse
 */
export interface GetConstantsResponse {
    /**
     * 
     * @type {Array<SupportedPMS>}
     * @memberof GetConstantsResponse
     */
    'supportedPMS'?: Array<SupportedPMS> | null;
}
/**
 * 
 * @export
 * @interface GetPaginatedMessagesResponse
 */
export interface GetPaginatedMessagesResponse {
    /**
     * 
     * @type {Array<Message>}
     * @memberof GetPaginatedMessagesResponse
     */
    'messages'?: Array<Message> | null;
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedMessagesResponse
     */
    'filteredMessages'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedMessagesResponse
     */
    'totalMessages'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedMessagesResponse
     */
    'totalUnreadMessages'?: number;
    /**
     * 
     * @type {MessageFilterDropdowns}
     * @memberof GetPaginatedMessagesResponse
     */
    'filterDropdowns'?: MessageFilterDropdowns;
}
/**
 * 
 * @export
 * @interface HealthCheckResponse
 */
export interface HealthCheckResponse {
    /**
     * 
     * @type {string}
     * @memberof HealthCheckResponse
     */
    'currentEndpoint'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof HealthCheckResponse
     */
    'currentEndpointResolved'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HealthCheckResponse
     */
    'prodEndpoint'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof HealthCheckResponse
     */
    'prodEndpointResolved'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const HttpStatusCode = {
    NUMBER_100: 100,
    NUMBER_101: 101,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_200: 200,
    NUMBER_201: 201,
    NUMBER_202: 202,
    NUMBER_203: 203,
    NUMBER_204: 204,
    NUMBER_205: 205,
    NUMBER_206: 206,
    NUMBER_207: 207,
    NUMBER_208: 208,
    NUMBER_226: 226,
    NUMBER_300: 300,
    NUMBER_301: 301,
    NUMBER_302: 302,
    NUMBER_303: 303,
    NUMBER_304: 304,
    NUMBER_305: 305,
    NUMBER_306: 306,
    NUMBER_307: 307,
    NUMBER_308: 308,
    NUMBER_400: 400,
    NUMBER_401: 401,
    NUMBER_402: 402,
    NUMBER_403: 403,
    NUMBER_404: 404,
    NUMBER_405: 405,
    NUMBER_406: 406,
    NUMBER_407: 407,
    NUMBER_408: 408,
    NUMBER_409: 409,
    NUMBER_410: 410,
    NUMBER_411: 411,
    NUMBER_412: 412,
    NUMBER_413: 413,
    NUMBER_414: 414,
    NUMBER_415: 415,
    NUMBER_416: 416,
    NUMBER_417: 417,
    NUMBER_421: 421,
    NUMBER_422: 422,
    NUMBER_423: 423,
    NUMBER_424: 424,
    NUMBER_426: 426,
    NUMBER_428: 428,
    NUMBER_429: 429,
    NUMBER_431: 431,
    NUMBER_451: 451,
    NUMBER_500: 500,
    NUMBER_501: 501,
    NUMBER_502: 502,
    NUMBER_503: 503,
    NUMBER_504: 504,
    NUMBER_505: 505,
    NUMBER_506: 506,
    NUMBER_507: 507,
    NUMBER_508: 508,
    NUMBER_510: 510,
    NUMBER_511: 511
} as const;

export type HttpStatusCode = typeof HttpStatusCode[keyof typeof HttpStatusCode];


/**
 * 
 * @export
 * @enum {string}
 */

export const JobPositionEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7
} as const;

export type JobPositionEnum = typeof JobPositionEnum[keyof typeof JobPositionEnum];


/**
 * 
 * @export
 * @interface LambdaResponse
 */
export interface LambdaResponse {
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof LambdaResponse
     */
    'statusCode'?: HttpStatusCode;
    /**
     * 
     * @type {string}
     * @memberof LambdaResponse
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface MarkAsReadResponse
 */
export interface MarkAsReadResponse {
    /**
     * 
     * @type {boolean}
     * @memberof MarkAsReadResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'customerID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    'messageID'?: number;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'messageDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'messageFrom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'messageSubject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'messageBody'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'messageCategory'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    'messageRead'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    'messageDeleted'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    'ignored'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    'neaMessageId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'neaAttachmentId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    'claimId'?: number | null;
    /**
     * 
     * @type {Array<MessageAttachments>}
     * @memberof Message
     */
    'attachments'?: Array<MessageAttachments> | null;
}
/**
 * 
 * @export
 * @interface MessageAttachments
 */
export interface MessageAttachments {
    /**
     * 
     * @type {number}
     * @memberof MessageAttachments
     */
    'attachmentID'?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageAttachments
     */
    'messageID'?: number;
    /**
     * 
     * @type {string}
     * @memberof MessageAttachments
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageAttachments
     */
    'binaryContents'?: string | null;
}
/**
 * 
 * @export
 * @interface MessageBuildQueryRequest
 */
export interface MessageBuildQueryRequest {
    /**
     * 
     * @type {string}
     * @memberof MessageBuildQueryRequest
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageBuildQueryRequest
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MessageBuildQueryRequest
     */
    'range'?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageBuildQueryRequest
     */
    'offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof MessageBuildQueryRequest
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageBuildQueryRequest
     */
    'sortDirection'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MessageBuildQueryRequest
     */
    'exactFilters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MessageBuildQueryRequest
     */
    'searchFilters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof MessageBuildQueryRequest
     */
    'searchText'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageBuildQueryRequest
     */
    'ignored'?: boolean;
}
/**
 * 
 * @export
 * @interface MessageFilterDropdowns
 */
export interface MessageFilterDropdowns {
    /**
     * 
     * @type {Array<string>}
     * @memberof MessageFilterDropdowns
     */
    'messageFrom'?: Array<string> | null;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof MessageFilterDropdowns
     */
    'read'?: Array<boolean> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MessageFilterDropdowns
     */
    'messageCategory'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MessageFilterDropdowns
     */
    'messageSubject'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface NetSuiteViewModel
 */
export interface NetSuiteViewModel {
    /**
     * 
     * @type {string}
     * @memberof NetSuiteViewModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NetSuiteViewModel
     */
    'facilityId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NetSuiteViewModel
     */
    'netSuiteIntegrationApi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NetSuiteViewModel
     */
    'netSuiteClientId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NetSuiteViewModel
     */
    'netSuiteClientSecret'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NetSuiteViewModel
     */
    'netSuiteClientVersion'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NetSuiteViewModel
     */
    'paymentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NetSuiteViewModel
     */
    'cardType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NetSuiteViewModel
     */
    'lastFour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NetSuiteViewModel
     */
    'expDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NetSuiteViewModel
     */
    'error'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NetSuiteViewModel
     */
    'customerId'?: number;
}
/**
 * 
 * @export
 * @interface PmsCustomerTokenResponse
 */
export interface PmsCustomerTokenResponse {
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof PmsCustomerTokenResponse
     */
    'statusCode'?: HttpStatusCode;
    /**
     * 
     * @type {string}
     * @memberof PmsCustomerTokenResponse
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PmsCustomerTokenResponse
     */
    'pmsCustomerToken'?: string | null;
}
/**
 * 
 * @export
 * @interface PmsDataResponse
 */
export interface PmsDataResponse {
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof PmsDataResponse
     */
    'statusCode'?: HttpStatusCode;
    /**
     * 
     * @type {string}
     * @memberof PmsDataResponse
     */
    'message'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PmsDataResponse
     */
    'hasPMSData'?: boolean;
}
/**
 * 
 * @export
 * @interface PmsSyncStatusResponse
 */
export interface PmsSyncStatusResponse {
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof PmsSyncStatusResponse
     */
    'statusCode'?: HttpStatusCode;
    /**
     * 
     * @type {string}
     * @memberof PmsSyncStatusResponse
     */
    'message'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PmsSyncStatusResponse
     */
    'pmsSyncIsExpired'?: boolean;
}
/**
 * 
 * @export
 * @interface PracticeInfo
 */
export interface PracticeInfo {
    /**
     * 
     * @type {string}
     * @memberof PracticeInfo
     */
    'contactName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfo
     */
    'customerEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfo
     */
    'customerAddress1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfo
     */
    'customerAddress2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfo
     */
    'customerCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfo
     */
    'customerState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfo
     */
    'customerZip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfo
     */
    'customerPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfo
     */
    'customerFax'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfo
     */
    'p_Software'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfo
     */
    'officeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfo
     */
    'providerLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfo
     */
    'providerFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfo
     */
    'providerNPI'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfo
     */
    'taxonomyCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfo
     */
    'facilityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfo
     */
    'pmsCustomerToken'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PracticeInfo
     */
    'partnerClaimsSupported'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PracticeInfo
     */
    'statementsSupported'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PracticeInfo
     */
    'syncSupported'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PracticeInfo
     */
    'vdpSupported'?: boolean;
}
/**
 * 
 * @export
 * @interface PracticeInfoReq
 */
export interface PracticeInfoReq {
    /**
     * 
     * @type {string}
     * @memberof PracticeInfoReq
     */
    'contactName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfoReq
     */
    'customerAddress1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfoReq
     */
    'customerAddress2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfoReq
     */
    'customerCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfoReq
     */
    'customerState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfoReq
     */
    'customerZip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfoReq
     */
    'officeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfoReq
     */
    'customerPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfoReq
     */
    'customerFax'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfoReq
     */
    'customerEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeInfoReq
     */
    'p_Software'?: string | null;
}
/**
 * 
 * @export
 * @interface PracticeProvidersResponse
 */
export interface PracticeProvidersResponse {
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof PracticeProvidersResponse
     */
    'statusCode'?: HttpStatusCode;
    /**
     * 
     * @type {string}
     * @memberof PracticeProvidersResponse
     */
    'message'?: string | null;
    /**
     * 
     * @type {Array<EligibilityDefaultProvider>}
     * @memberof PracticeProvidersResponse
     */
    'providers'?: Array<EligibilityDefaultProvider> | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'group'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'version'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'downloadUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    'hideWhiteLabel'?: boolean;
}
/**
 * 
 * @export
 * @interface SupportedPMS
 */
export interface SupportedPMS {
    /**
     * 
     * @type {string}
     * @memberof SupportedPMS
     */
    'pmsName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SupportedPMS
     */
    'vdpSupported'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupportedPMS
     */
    'syncSupported'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupportedPMS
     */
    'partnerClaimsSupported'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupportedPMS
     */
    'statementsSupported'?: boolean;
}
/**
 * 
 * @export
 * @interface TCRInfo
 */
export interface TCRInfo {
    /**
     * 
     * @type {boolean}
     * @memberof TCRInfo
     */
    'validationFailed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TCRInfo
     */
    'campaignRegistrationStarted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TCRInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TCRInfo
     */
    'type'?: number;
    /**
     * 
     * @type {string}
     * @memberof TCRInfo
     */
    'employerIdentificationNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TCRInfo
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TCRInfo
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TCRInfo
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TCRInfo
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TCRInfo
     */
    'zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TCRInfo
     */
    'websiteURL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TCRInfo
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TCRInfo
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TCRInfo
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TCRInfo
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TCRInfo
     */
    'phone'?: string | null;
    /**
     * 
     * @type {JobPositionEnum}
     * @memberof TCRInfo
     */
    'jobPosition'?: JobPositionEnum;
    /**
     * 
     * @type {VerificationStatusEnum}
     * @memberof TCRInfo
     */
    'verificationStatus'?: VerificationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TCRInfo
     */
    'verificationStatusDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TCRInfo
     */
    'bannerSuppressed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TCRInfo
     */
    'maxBrandRegistrationUpdateAttemptsReached'?: boolean;
}
/**
 * 
 * @export
 * @interface TCRResponse
 */
export interface TCRResponse {
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof TCRResponse
     */
    'statusCode'?: HttpStatusCode;
    /**
     * 
     * @type {string}
     * @memberof TCRResponse
     */
    'message'?: string | null;
    /**
     * 
     * @type {TCRInfo}
     * @memberof TCRResponse
     */
    'data'?: TCRInfo;
}
/**
 * 
 * @export
 * @interface TrellisPracticeInfoResponse
 */
export interface TrellisPracticeInfoResponse {
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof TrellisPracticeInfoResponse
     */
    'statusCode'?: HttpStatusCode;
    /**
     * 
     * @type {string}
     * @memberof TrellisPracticeInfoResponse
     */
    'message'?: string | null;
    /**
     * 
     * @type {PracticeInfo}
     * @memberof TrellisPracticeInfoResponse
     */
    'trellisPracticeInfo'?: PracticeInfo;
}
/**
 * 
 * @export
 * @interface TrellisUserInfoResponse
 */
export interface TrellisUserInfoResponse {
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof TrellisUserInfoResponse
     */
    'statusCode'?: HttpStatusCode;
    /**
     * 
     * @type {string}
     * @memberof TrellisUserInfoResponse
     */
    'message'?: string | null;
    /**
     * 
     * @type {UserInfo}
     * @memberof TrellisUserInfoResponse
     */
    'trellisUserInfo'?: UserInfo;
}
/**
 * 
 * @export
 * @interface UpdateBillingRequest
 */
export interface UpdateBillingRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingRequest
     */
    'paymentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingRequest
     */
    'token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingRequest
     */
    'billToName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingRequest
     */
    'creditCardType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateBillingRequest
     */
    'creditCardExpireYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBillingRequest
     */
    'creditCardExpireMonth'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingRequest
     */
    'lastFour'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateIgnoredMessageRequest
 */
export interface UpdateIgnoredMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateIgnoredMessageRequest
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateIgnoredMessageRequest
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateIgnoredMessageRequest
     */
    'range'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateIgnoredMessageRequest
     */
    'offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateIgnoredMessageRequest
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateIgnoredMessageRequest
     */
    'sortDirection'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateIgnoredMessageRequest
     */
    'exactFilters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateIgnoredMessageRequest
     */
    'searchFilters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateIgnoredMessageRequest
     */
    'searchText'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIgnoredMessageRequest
     */
    'ignored'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateIgnoredMessageRequest
     */
    'messageIds'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIgnoredMessageRequest
     */
    'processAll'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateIgnoredMessageResponse
 */
export interface UpdateIgnoredMessageResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIgnoredMessageResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface UserFeedbackReq
 */
export interface UserFeedbackReq {
    /**
     * 
     * @type {string}
     * @memberof UserFeedbackReq
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserFeedbackReq
     */
    'feedback'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserFeedbackReq
     */
    'pageName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserFeedbackReq
     */
    'browser'?: string | null;
}
/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {number}
     * @memberof UserInfo
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserInfo
     */
    'globalCustomerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'userName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfo
     */
    'isSso'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'customerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'address_Address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'address_Address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'address_City'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'address_State'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'address_PostalCode'?: string | null;
    /**
     * 
     * @type {CustomerAddress}
     * @memberof UserInfo
     */
    'address'?: CustomerAddress;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'masonLocationID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'masonAdminAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'operaCompanyID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'operaCustomerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'facilityId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'activeServices'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const VerificationStatusEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type VerificationStatusEnum = typeof VerificationStatusEnum[keyof typeof VerificationStatusEnum];


/**
 * 
 * @export
 * @interface Version
 */
export interface Version {
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    'major'?: number;
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    'minor'?: number;
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    'build'?: number;
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    'revision'?: number;
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    'majorRevision'?: number;
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    'minorRevision'?: number;
}

/**
 * HealthcheckApi - axios parameter creator
 * @export
 */
export const HealthcheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheckResolve: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('healthcheckResolve', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('healthcheckResolve', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('healthcheckResolve', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/healthcheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthcheckApi - functional programming interface
 * @export
 */
export const HealthcheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthcheckApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthcheckResolve(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthCheckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthcheckResolve(authtoken, registrationtoken, ignoretokenrefresh, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthcheckApi - factory interface
 * @export
 */
export const HealthcheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthcheckApiFp(configuration)
    return {
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheckResolve(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options?: any): AxiosPromise<HealthCheckResponse> {
            return localVarFp.healthcheckResolve(authtoken, registrationtoken, ignoretokenrefresh, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthcheckApi - object-oriented interface
 * @export
 * @class HealthcheckApi
 * @extends {BaseAPI}
 */
export class HealthcheckApi extends BaseAPI {
    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthcheckApi
     */
    public healthcheckResolve(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options?: AxiosRequestConfig) {
        return HealthcheckApiFp(this.configuration).healthcheckResolve(authtoken, registrationtoken, ignoretokenrefresh, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PracticeApi - axios parameter creator
 * @export
 */
export const PracticeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} providerId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceDeletePracticeProvider: async (providerId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('practiceDeletePracticeProvider', 'providerId', providerId)
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practiceDeletePracticeProvider', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practiceDeletePracticeProvider', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practiceDeletePracticeProvider', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/Practice/provider/{providerId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {UserFeedbackReq} [userFeedbackReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceFeedback: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, userFeedbackReq?: UserFeedbackReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practiceFeedback', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practiceFeedback', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practiceFeedback', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/Practice/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userFeedbackReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGet: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practiceGet', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practiceGet', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practiceGet', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/Practice/downloads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetBillingDetails: async (customerId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('practiceGetBillingDetails', 'customerId', customerId)
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practiceGetBillingDetails', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practiceGetBillingDetails', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practiceGetBillingDetails', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/Practice/{customerId}/BillingDetails`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        practiceGetBillingDetailsObsolete: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practiceGetBillingDetailsObsolete', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practiceGetBillingDetailsObsolete', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practiceGetBillingDetailsObsolete', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/Practice/BillingDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetConstants: async (customerId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('practiceGetConstants', 'customerId', customerId)
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practiceGetConstants', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practiceGetConstants', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practiceGetConstants', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/Practice/{customerId}/Constants`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {MessageBuildQueryRequest} messageBuildQueryRequest 
         * @param {number} [customerId] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetMessage: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, messageBuildQueryRequest: MessageBuildQueryRequest, customerId?: number, userId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practiceGetMessage', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practiceGetMessage', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practiceGetMessage', 'ignoretokenrefresh', ignoretokenrefresh)
            // verify required parameter 'messageBuildQueryRequest' is not null or undefined
            assertParamExists('practiceGetMessage', 'messageBuildQueryRequest', messageBuildQueryRequest)
            const localVarPath = `/Practice/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageBuildQueryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} messageId 
         * @param {number} attachmentId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetMessageAttachment: async (messageId: number, attachmentId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('practiceGetMessageAttachment', 'messageId', messageId)
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('practiceGetMessageAttachment', 'attachmentId', attachmentId)
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practiceGetMessageAttachment', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practiceGetMessageAttachment', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practiceGetMessageAttachment', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/Practice/messages/{messageId}/attachment/{attachmentId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)))
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetPmsDataStatus: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practiceGetPmsDataStatus', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practiceGetPmsDataStatus', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practiceGetPmsDataStatus', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/Practice/has-pms-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetPmsSyncStatus: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practiceGetPmsSyncStatus', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practiceGetPmsSyncStatus', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practiceGetPmsSyncStatus', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/Practice/pms-sync-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetPracticeInfo: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practiceGetPracticeInfo', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practiceGetPracticeInfo', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practiceGetPracticeInfo', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/Practice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetPracticeProviders: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practiceGetPracticeProviders', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practiceGetPracticeProviders', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practiceGetPracticeProviders', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/Practice/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetResources: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practiceGetResources', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practiceGetResources', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practiceGetResources', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/Practice/resources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetTCRInfo: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practiceGetTCRInfo', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practiceGetTCRInfo', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practiceGetTCRInfo', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/Practice/tcr-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetUserInfo: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, userId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practiceGetUserInfo', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practiceGetUserInfo', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practiceGetUserInfo', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/Practice/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} messageId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [userId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceMarkAsRead: async (messageId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, userId?: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('practiceMarkAsRead', 'messageId', messageId)
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practiceMarkAsRead', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practiceMarkAsRead', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practiceMarkAsRead', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/Practice/messages/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {PracticeInfoReq} [practiceInfoReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practicePost: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, practiceInfoReq?: PracticeInfoReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practicePost', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practicePost', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practicePost', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/Practice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(practiceInfoReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {TCRInfo} [tCRInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practicePostTCRInfo: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, tCRInfo?: TCRInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practicePostTCRInfo', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practicePostTCRInfo', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practicePostTCRInfo', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/Practice/tcr-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tCRInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practicePutPmsCustomerToken: async (customerId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('practicePutPmsCustomerToken', 'customerId', customerId)
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practicePutPmsCustomerToken', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practicePutPmsCustomerToken', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practicePutPmsCustomerToken', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/Practice/{customerId}/PmsCustomerToken`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {EligibilityDefaultProvider} [eligibilityDefaultProvider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practicePutPracticeProvider: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, eligibilityDefaultProvider?: EligibilityDefaultProvider, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practicePutPracticeProvider', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practicePutPracticeProvider', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practicePutPracticeProvider', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/Practice/provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eligibilityDefaultProvider, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {UpdateBillingRequest} [updateBillingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceUpdateBillingDetails: async (customerId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, updateBillingRequest?: UpdateBillingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('practiceUpdateBillingDetails', 'customerId', customerId)
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practiceUpdateBillingDetails', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practiceUpdateBillingDetails', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practiceUpdateBillingDetails', 'ignoretokenrefresh', ignoretokenrefresh)
            const localVarPath = `/Practice/{customerId}/BillingDetails`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBillingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {UpdateIgnoredMessageRequest} updateIgnoredMessageRequest 
         * @param {number} [userId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceUpdateIgnoredMessages: async (authtoken: string, registrationtoken: string, ignoretokenrefresh: number, updateIgnoredMessageRequest: UpdateIgnoredMessageRequest, userId?: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authtoken' is not null or undefined
            assertParamExists('practiceUpdateIgnoredMessages', 'authtoken', authtoken)
            // verify required parameter 'registrationtoken' is not null or undefined
            assertParamExists('practiceUpdateIgnoredMessages', 'registrationtoken', registrationtoken)
            // verify required parameter 'ignoretokenrefresh' is not null or undefined
            assertParamExists('practiceUpdateIgnoredMessages', 'ignoretokenrefresh', ignoretokenrefresh)
            // verify required parameter 'updateIgnoredMessageRequest' is not null or undefined
            assertParamExists('practiceUpdateIgnoredMessages', 'updateIgnoredMessageRequest', updateIgnoredMessageRequest)
            const localVarPath = `/Practice/messages/ignore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }

            if (ignoretokenrefresh !== undefined && ignoretokenrefresh !== null) {
                localVarHeaderParameter['ignoretokenrefresh'] = String(JSON.stringify(ignoretokenrefresh));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIgnoredMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PracticeApi - functional programming interface
 * @export
 */
export const PracticeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PracticeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} providerId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceDeletePracticeProvider(providerId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LambdaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceDeletePracticeProvider(providerId, authtoken, registrationtoken, ignoretokenrefresh, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {UserFeedbackReq} [userFeedbackReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceFeedback(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, userFeedbackReq?: UserFeedbackReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceFeedback(authtoken, registrationtoken, ignoretokenrefresh, userFeedbackReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceGet(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceGet(authtoken, registrationtoken, ignoretokenrefresh, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceGetBillingDetails(customerId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceGetBillingDetails(customerId, authtoken, registrationtoken, ignoretokenrefresh, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async practiceGetBillingDetailsObsolete(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceGetBillingDetailsObsolete(authtoken, registrationtoken, ignoretokenrefresh, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceGetConstants(customerId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConstantsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceGetConstants(customerId, authtoken, registrationtoken, ignoretokenrefresh, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {MessageBuildQueryRequest} messageBuildQueryRequest 
         * @param {number} [customerId] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceGetMessage(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, messageBuildQueryRequest: MessageBuildQueryRequest, customerId?: number, userId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaginatedMessagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceGetMessage(authtoken, registrationtoken, ignoretokenrefresh, messageBuildQueryRequest, customerId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} messageId 
         * @param {number} attachmentId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceGetMessageAttachment(messageId: number, attachmentId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceGetMessageAttachment(messageId, attachmentId, authtoken, registrationtoken, ignoretokenrefresh, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceGetPmsDataStatus(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PmsDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceGetPmsDataStatus(authtoken, registrationtoken, ignoretokenrefresh, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceGetPmsSyncStatus(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PmsSyncStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceGetPmsSyncStatus(authtoken, registrationtoken, ignoretokenrefresh, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceGetPracticeInfo(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrellisPracticeInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceGetPracticeInfo(authtoken, registrationtoken, ignoretokenrefresh, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceGetPracticeProviders(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeProvidersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceGetPracticeProviders(authtoken, registrationtoken, ignoretokenrefresh, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceGetResources(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Resource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceGetResources(authtoken, registrationtoken, ignoretokenrefresh, customerId, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceGetTCRInfo(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TCRResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceGetTCRInfo(authtoken, registrationtoken, ignoretokenrefresh, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceGetUserInfo(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, userId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrellisUserInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceGetUserInfo(authtoken, registrationtoken, ignoretokenrefresh, customerId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} messageId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [userId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceMarkAsRead(messageId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, userId?: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkAsReadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceMarkAsRead(messageId, authtoken, registrationtoken, ignoretokenrefresh, userId, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {PracticeInfoReq} [practiceInfoReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practicePost(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, practiceInfoReq?: PracticeInfoReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LambdaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practicePost(authtoken, registrationtoken, ignoretokenrefresh, customerId, practiceInfoReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {TCRInfo} [tCRInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practicePostTCRInfo(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, tCRInfo?: TCRInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TCRResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practicePostTCRInfo(authtoken, registrationtoken, ignoretokenrefresh, customerId, tCRInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practicePutPmsCustomerToken(customerId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PmsCustomerTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practicePutPmsCustomerToken(customerId, authtoken, registrationtoken, ignoretokenrefresh, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {EligibilityDefaultProvider} [eligibilityDefaultProvider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practicePutPracticeProvider(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, eligibilityDefaultProvider?: EligibilityDefaultProvider, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUpdateProviderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practicePutPracticeProvider(authtoken, registrationtoken, ignoretokenrefresh, customerId, eligibilityDefaultProvider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {UpdateBillingRequest} [updateBillingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceUpdateBillingDetails(customerId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, updateBillingRequest?: UpdateBillingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NetSuiteViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceUpdateBillingDetails(customerId, authtoken, registrationtoken, ignoretokenrefresh, updateBillingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {UpdateIgnoredMessageRequest} updateIgnoredMessageRequest 
         * @param {number} [userId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceUpdateIgnoredMessages(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, updateIgnoredMessageRequest: UpdateIgnoredMessageRequest, userId?: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateIgnoredMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceUpdateIgnoredMessages(authtoken, registrationtoken, ignoretokenrefresh, updateIgnoredMessageRequest, userId, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PracticeApi - factory interface
 * @export
 */
export const PracticeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PracticeApiFp(configuration)
    return {
        /**
         * 
         * @param {number} providerId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceDeletePracticeProvider(providerId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: any): AxiosPromise<LambdaResponse> {
            return localVarFp.practiceDeletePracticeProvider(providerId, authtoken, registrationtoken, ignoretokenrefresh, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {UserFeedbackReq} [userFeedbackReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceFeedback(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, userFeedbackReq?: UserFeedbackReq, options?: any): AxiosPromise<number> {
            return localVarFp.practiceFeedback(authtoken, registrationtoken, ignoretokenrefresh, userFeedbackReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGet(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: any): AxiosPromise<DownloadsResponse> {
            return localVarFp.practiceGet(authtoken, registrationtoken, ignoretokenrefresh, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetBillingDetails(customerId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options?: any): AxiosPromise<BillingDetailsResponse> {
            return localVarFp.practiceGetBillingDetails(customerId, authtoken, registrationtoken, ignoretokenrefresh, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        practiceGetBillingDetailsObsolete(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: any): AxiosPromise<BillingDetailsResponse> {
            return localVarFp.practiceGetBillingDetailsObsolete(authtoken, registrationtoken, ignoretokenrefresh, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetConstants(customerId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options?: any): AxiosPromise<GetConstantsResponse> {
            return localVarFp.practiceGetConstants(customerId, authtoken, registrationtoken, ignoretokenrefresh, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {MessageBuildQueryRequest} messageBuildQueryRequest 
         * @param {number} [customerId] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetMessage(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, messageBuildQueryRequest: MessageBuildQueryRequest, customerId?: number, userId?: number, options?: any): AxiosPromise<GetPaginatedMessagesResponse> {
            return localVarFp.practiceGetMessage(authtoken, registrationtoken, ignoretokenrefresh, messageBuildQueryRequest, customerId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} messageId 
         * @param {number} attachmentId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetMessageAttachment(messageId: number, attachmentId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: any): AxiosPromise<string> {
            return localVarFp.practiceGetMessageAttachment(messageId, attachmentId, authtoken, registrationtoken, ignoretokenrefresh, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetPmsDataStatus(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: any): AxiosPromise<PmsDataResponse> {
            return localVarFp.practiceGetPmsDataStatus(authtoken, registrationtoken, ignoretokenrefresh, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetPmsSyncStatus(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: any): AxiosPromise<PmsSyncStatusResponse> {
            return localVarFp.practiceGetPmsSyncStatus(authtoken, registrationtoken, ignoretokenrefresh, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetPracticeInfo(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: any): AxiosPromise<TrellisPracticeInfoResponse> {
            return localVarFp.practiceGetPracticeInfo(authtoken, registrationtoken, ignoretokenrefresh, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetPracticeProviders(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: any): AxiosPromise<PracticeProvidersResponse> {
            return localVarFp.practiceGetPracticeProviders(authtoken, registrationtoken, ignoretokenrefresh, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetResources(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, search?: string, options?: any): AxiosPromise<Array<Resource>> {
            return localVarFp.practiceGetResources(authtoken, registrationtoken, ignoretokenrefresh, customerId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetTCRInfo(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: any): AxiosPromise<TCRResponse> {
            return localVarFp.practiceGetTCRInfo(authtoken, registrationtoken, ignoretokenrefresh, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceGetUserInfo(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, userId?: number, options?: any): AxiosPromise<TrellisUserInfoResponse> {
            return localVarFp.practiceGetUserInfo(authtoken, registrationtoken, ignoretokenrefresh, customerId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} messageId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [userId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceMarkAsRead(messageId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, userId?: number, customerId?: number, options?: any): AxiosPromise<MarkAsReadResponse> {
            return localVarFp.practiceMarkAsRead(messageId, authtoken, registrationtoken, ignoretokenrefresh, userId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {PracticeInfoReq} [practiceInfoReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practicePost(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, practiceInfoReq?: PracticeInfoReq, options?: any): AxiosPromise<LambdaResponse> {
            return localVarFp.practicePost(authtoken, registrationtoken, ignoretokenrefresh, customerId, practiceInfoReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {TCRInfo} [tCRInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practicePostTCRInfo(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, tCRInfo?: TCRInfo, options?: any): AxiosPromise<TCRResponse> {
            return localVarFp.practicePostTCRInfo(authtoken, registrationtoken, ignoretokenrefresh, customerId, tCRInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practicePutPmsCustomerToken(customerId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options?: any): AxiosPromise<PmsCustomerTokenResponse> {
            return localVarFp.practicePutPmsCustomerToken(customerId, authtoken, registrationtoken, ignoretokenrefresh, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {number} [customerId] 
         * @param {EligibilityDefaultProvider} [eligibilityDefaultProvider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practicePutPracticeProvider(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, eligibilityDefaultProvider?: EligibilityDefaultProvider, options?: any): AxiosPromise<CreateUpdateProviderResponse> {
            return localVarFp.practicePutPracticeProvider(authtoken, registrationtoken, ignoretokenrefresh, customerId, eligibilityDefaultProvider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {UpdateBillingRequest} [updateBillingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceUpdateBillingDetails(customerId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, updateBillingRequest?: UpdateBillingRequest, options?: any): AxiosPromise<NetSuiteViewModel> {
            return localVarFp.practiceUpdateBillingDetails(customerId, authtoken, registrationtoken, ignoretokenrefresh, updateBillingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authtoken Authorization Token
         * @param {string} registrationtoken Registration Token
         * @param {number} ignoretokenrefresh Ignore Token Refresh
         * @param {UpdateIgnoredMessageRequest} updateIgnoredMessageRequest 
         * @param {number} [userId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceUpdateIgnoredMessages(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, updateIgnoredMessageRequest: UpdateIgnoredMessageRequest, userId?: number, customerId?: number, options?: any): AxiosPromise<UpdateIgnoredMessageResponse> {
            return localVarFp.practiceUpdateIgnoredMessages(authtoken, registrationtoken, ignoretokenrefresh, updateIgnoredMessageRequest, userId, customerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PracticeApi - object-oriented interface
 * @export
 * @class PracticeApi
 * @extends {BaseAPI}
 */
export class PracticeApi extends BaseAPI {
    /**
     * 
     * @param {number} providerId 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practiceDeletePracticeProvider(providerId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practiceDeletePracticeProvider(providerId, authtoken, registrationtoken, ignoretokenrefresh, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {UserFeedbackReq} [userFeedbackReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practiceFeedback(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, userFeedbackReq?: UserFeedbackReq, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practiceFeedback(authtoken, registrationtoken, ignoretokenrefresh, userFeedbackReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practiceGet(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practiceGet(authtoken, registrationtoken, ignoretokenrefresh, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practiceGetBillingDetails(customerId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practiceGetBillingDetails(customerId, authtoken, registrationtoken, ignoretokenrefresh, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practiceGetBillingDetailsObsolete(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practiceGetBillingDetailsObsolete(authtoken, registrationtoken, ignoretokenrefresh, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practiceGetConstants(customerId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practiceGetConstants(customerId, authtoken, registrationtoken, ignoretokenrefresh, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {MessageBuildQueryRequest} messageBuildQueryRequest 
     * @param {number} [customerId] 
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practiceGetMessage(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, messageBuildQueryRequest: MessageBuildQueryRequest, customerId?: number, userId?: number, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practiceGetMessage(authtoken, registrationtoken, ignoretokenrefresh, messageBuildQueryRequest, customerId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} messageId 
     * @param {number} attachmentId 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practiceGetMessageAttachment(messageId: number, attachmentId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practiceGetMessageAttachment(messageId, attachmentId, authtoken, registrationtoken, ignoretokenrefresh, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practiceGetPmsDataStatus(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practiceGetPmsDataStatus(authtoken, registrationtoken, ignoretokenrefresh, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practiceGetPmsSyncStatus(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practiceGetPmsSyncStatus(authtoken, registrationtoken, ignoretokenrefresh, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practiceGetPracticeInfo(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practiceGetPracticeInfo(authtoken, registrationtoken, ignoretokenrefresh, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practiceGetPracticeProviders(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practiceGetPracticeProviders(authtoken, registrationtoken, ignoretokenrefresh, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {number} [customerId] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practiceGetResources(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, search?: string, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practiceGetResources(authtoken, registrationtoken, ignoretokenrefresh, customerId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practiceGetTCRInfo(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practiceGetTCRInfo(authtoken, registrationtoken, ignoretokenrefresh, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {number} [customerId] 
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practiceGetUserInfo(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, userId?: number, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practiceGetUserInfo(authtoken, registrationtoken, ignoretokenrefresh, customerId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} messageId 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {number} [userId] 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practiceMarkAsRead(messageId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, userId?: number, customerId?: number, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practiceMarkAsRead(messageId, authtoken, registrationtoken, ignoretokenrefresh, userId, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {number} [customerId] 
     * @param {PracticeInfoReq} [practiceInfoReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practicePost(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, practiceInfoReq?: PracticeInfoReq, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practicePost(authtoken, registrationtoken, ignoretokenrefresh, customerId, practiceInfoReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {number} [customerId] 
     * @param {TCRInfo} [tCRInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practicePostTCRInfo(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, tCRInfo?: TCRInfo, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practicePostTCRInfo(authtoken, registrationtoken, ignoretokenrefresh, customerId, tCRInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practicePutPmsCustomerToken(customerId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practicePutPmsCustomerToken(customerId, authtoken, registrationtoken, ignoretokenrefresh, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {number} [customerId] 
     * @param {EligibilityDefaultProvider} [eligibilityDefaultProvider] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practicePutPracticeProvider(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, customerId?: number, eligibilityDefaultProvider?: EligibilityDefaultProvider, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practicePutPracticeProvider(authtoken, registrationtoken, ignoretokenrefresh, customerId, eligibilityDefaultProvider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {UpdateBillingRequest} [updateBillingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practiceUpdateBillingDetails(customerId: number, authtoken: string, registrationtoken: string, ignoretokenrefresh: number, updateBillingRequest?: UpdateBillingRequest, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practiceUpdateBillingDetails(customerId, authtoken, registrationtoken, ignoretokenrefresh, updateBillingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authtoken Authorization Token
     * @param {string} registrationtoken Registration Token
     * @param {number} ignoretokenrefresh Ignore Token Refresh
     * @param {UpdateIgnoredMessageRequest} updateIgnoredMessageRequest 
     * @param {number} [userId] 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public practiceUpdateIgnoredMessages(authtoken: string, registrationtoken: string, ignoretokenrefresh: number, updateIgnoredMessageRequest: UpdateIgnoredMessageRequest, userId?: number, customerId?: number, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).practiceUpdateIgnoredMessages(authtoken, registrationtoken, ignoretokenrefresh, updateIgnoredMessageRequest, userId, customerId, options).then((request) => request(this.axios, this.basePath));
    }
}


