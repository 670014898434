import { ObservableObject } from '@legendapp/state'
import { endOfDay, format, startOfDay, sub } from 'date-fns'
import { RangeValue } from 'rc-picker/lib/interface'
import { ReactElement } from 'react'
import { redirect } from 'react-router-dom'

import {
  DataTableColumn,
  DataTableConfig,
  DataTableFilterBy,
  StatusDisplay,
} from 'ui'

import {
  BillingStatementFiles,
  BillingStatements,
  BillingStatements as BillingStatementsType,
} from 'trellis:api/statements/statements-client'
import { getStatusOverride } from 'trellis:constants/claimStatusDescriptionData'
import GlobalState from 'trellis:state/globalState'
import { TimeRangePickerProps } from 'antd'
import dayjs from 'dayjs'

const enDash = '–'

export const CVV_LEGAL_TEXT = 'By opting in, Customer acknowledges statements and associated features are provided as a service for Customer use, but Customer is and remains solely responsible for its own compliance with PCI DSS and all laws applicable to the secure handling and storage of cardholder data.'

export const loaderBillingStatements = (): Response => {
  const pms = GlobalState.Constants.supportedPMS
    .get()
    .find((c) => c.pmsName === GlobalState.PracticeInfo.p_Software.peek())

  if (!pms.statementsSupported && GlobalState.ActiveServices.TRELLIS_STATEMENTS.peek()) return redirect('/Practice/PracticeInfo')

  return null
}

export const getQueryConfig = (
  config$: ObservableObject<DataTableConfig<BillingStatementsType>>,
) => {
  return {
    Range: config$.pagination.pageLimit.peek(),
    Offset:
      (config$.pagination.currentPage.peek() - 1) *
      config$.pagination.pageLimit.peek(),
    SortColumn: config$.sortBy.dataField.peek(),
    SortDirection: config$.sortBy.value.peek(),
  }
}

export type BillingStatementsData = {
  StatementID: number
  ReceivedDate: string
  ResponsibleParty: string
  AccountNumber: string
  AmountDue: string
  Status: ReactElement | JSX.Element | string
  Address: string
}

type BillingStatementStatus = 'Mailed' | 'Pending' | 'Undeliverable' | null
const mapBillingStatmentStatus = (value: string) => {
  let mappedValue: BillingStatementStatus = null

  switch (value) {
    case 'N':
      mappedValue = 'Pending'
      break
    case 'U':
      mappedValue = 'Undeliverable'
      break
    case 'Y':
      mappedValue = 'Mailed'
      break
  }

  return mappedValue
}

export const billingStatementsColumns: DataTableColumn<BillingStatements>[] = [
  {
    dataField: 'BillingStatementID',
    title: 'Statement ID',
  },
  {
    dataField: 'StatementDate',
    render: (value) => <>{format(new Date(value), 'MM/dd/yyyy')}</>,
    title: 'Received Date',
  },
  {
    dataField: 'Recipient',
    title: 'Responsible Party',
  },
  {
    dataField: 'AccountNumber',
    title: 'Account Number',
  },
  {
    dataField: 'AmountDue',
    render: (value) => <>${value}</>,
    title: 'Amount Due',
  },
  {
    dataField: 'Mailed',
    filterType: 'search',
    render: (value) => (
      <StatusDisplay
        status={mapBillingStatmentStatus(value)}
        getStatusOverride={getStatusOverride}
      />
    ),
    title: 'Status',
  },
  {
    dataField: 'Address',
    hasSort: false,
    render: (value, row) => (
      <>
        <div>{value}</div>
        <div>
          {row.City}, {row.State} {row.Zip}
        </div>
      </>
    ),
    title: 'Address',
  },
]

export type BillingFilesData = {
  FileID: number
  ProcessDate: string
  Status: ReactElement | JSX.Element | string
  TotalStatements: number
  AdditionalPages: number
  TotalMailed: number
  Undeliverable: number
}

export const getMappedStatus = (value: string) => {
  switch (value) {
    case 'Error':
      return 'Failed'
    case 'Uploaded':
      return 'Uploaded'
    case 'Processing':
      return 'Processing'
    case 'Validated':
      return 'Printing in Progress'
    case 'Processed':
      return 'Printed and Mailed'
    case 'Duplicate':
      return 'Duplicate File Detected'
    case 'Unauthorized':
      return 'Unauthorized Submission'
    case 'Rejection':
      return 'Rejected/Expired'
    default:
      return value
  }
}

export const billingFilesColumns: DataTableColumn<BillingStatementFiles>[] = [
  {
    dataField: 'BillingStatementBatchID',
    title: 'File ID',
  },
  {
    dataField: 'ProcessDate',
    render: (value) => <>{format(new Date(value), 'MM/dd/yyyy')}</>,
    title: 'Process Date',
  },
  {
    dataField: 'StatusDescription',
    render: (value) => {
      const mappedValue = getMappedStatus(value)
      return (
        <StatusDisplay
          status={mappedValue}
          toolTip={mapFileStatusToolTip(value)}
          getStatusOverride={getStatusOverride}
        />
      )
    },
    title: 'Status',
  },
  {
    dataField: 'TotalStatements',
    render: (value) => <>{Number.isFinite(value) ? value : enDash}</>,
    title: 'Total Statements',
  },
  {
    dataField: 'AdditionalPages',
    render: (value) => <>{Number.isFinite(value) ? value : enDash}</>,
    title: 'Additional Pages',
  },
  {
    dataField: 'TotalMailed',
    render: (value) => <>{Number.isFinite(value) ? value : enDash}</>,
    title: 'Total Mailed',
  },
  {
    dataField: 'Undeliverable',
    render: (value) => <>{Number.isFinite(value) ? value : enDash}</>,
    title: 'Undeliverable',
  },
]

export const billingStatementsRangePresets: TimeRangePickerProps['presets'] = [
  {
    label: 'Today',
    value: [dayjs().startOf('day'), dayjs().endOf('day')],
  },
  {
    label: '7 Days',
    value: [dayjs().startOf('day').subtract(7, 'day'), dayjs().endOf('day')],
  },
  {
    label: '30 Days',
    value: [dayjs().startOf('day').subtract(30, 'day'), dayjs().endOf('day')],
  },
  {
    label: '60 Days',
    value: [dayjs().startOf('day').subtract(60, 'day'), dayjs().endOf('day')],
  },
  {
    label: '90 Days',
    value: [dayjs().startOf('day').subtract(90, 'day'), dayjs().endOf('day')],
  },
  {
    label: 'All Time',
    value: null,
  }
]

export const getFormattedDateRangeString = (
  initialDate: Date,
  finalDate: Date,
) => {
  const initialDateString = initialDate.toLocaleDateString().split(',').join('')
  const finalDateString = finalDate.toLocaleDateString().split(',').join('')

  return `${initialDateString}, ${finalDateString}`
}

const tagColors = {
  green: '#F1F8E7',
  red: '#f5182d1a',
  blue: '#729de41a',
  grey: '#F5F5F5',
  yellow: '#f0a51f1a',
}

export const FileFilterOptions: DataTableFilterBy = {
  dataField: 'Status',
  options: [
    {
      title: 'Failed',
      value: 'Error',
    },
    {
      title: 'Uploaded',
      value: 'Uploaded',
    },
    {
      title: 'Processing',
      value: 'Processing',
    },
    {
      title: 'Printing in Progress',
      value: 'Validated',
    },
    {
      title: 'Printed and Mailed',
      value: 'Processed',
    },
    {
      title: 'Duplicate File Detected',
      value: 'Duplicate',
    },
    {
      title: 'Unauthorized Submission',
      value: 'Unauthorized',
    },
    {
      title: 'Rejected/Expired',
      value: 'Rejection',
    },
  ],
  getStatusOverride: getStatusOverride,
}

export const StatementfilterOptions: DataTableFilterBy = {
  dataField: 'Status',
  options: [
    {
      title: 'Undeliverable',
      value: 'U',
    },
    {
      title: 'Mailed',
      value: 'Y',
    },
    {
      title: 'Pending',
      value: 'N',
    },
  ],
  getStatusOverride: getStatusOverride,
}

export const mapFileStatusToolTip = (value: string): string => {
  switch (value) {
    case 'Failed':
    case 'Error':
      return 'Processing failed. Please review and resend your statements in a new file.'
    case 'Uploaded':
      return 'Vyne has received this file and will begin processing it soon.'
    case 'Processing':
      return 'Vyne has received this file, and statements are being processed for printing.'
    case 'Validated':
    case 'Printing in Progress':
      return 'Deliverable statements are expected to be printed and mailed within 1 business day.'
    case 'Processed':
    case 'Printed and Mailed':
      return 'Statements printed & mailed. Please review statements for errors or address changes.'
    case 'Duplicate':
    case 'Duplicate File Detected':
      return 'The uploaded file is a duplicate of a previously uploaded file.'
    case 'Unauthorized Submission':
      return 'Statements processing is not currently enabled for your account. Contact support for assistance.'
    case 'Rejection':
    case 'Rejected/Expired':
      return 'Statements older than 7 days cannot be processed. The entire batch has been rejected.'
    default:
      return null
  }
}
