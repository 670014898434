import { observer } from '@legendapp/state/react'

import styles from './_downloads.module.scss'
import { DownloadItemContent } from './DownloadItemContent'
import { Download } from './utils/downloads-helpers'

export const DownloadItem = observer((download: Download) => {
  const { tagline } = download
  return (
    <article className={styles['download-item']}>
      {!download.inAdditionalUtilities ? (
        <>
          <p className={styles['download-item__tagline']}>{tagline}</p>
          <DownloadItemContent download={download} />
        </>
      ) : (
        <div className={styles['download-item__additional']}>
          <DownloadItemContent
            download={download}
            isAdditional={true}
          />
        </div>
      )}
    </article>
  )
})
