import { ExclamationCircleFilled } from '@ant-design/icons'
import { FC } from 'react'

import styles from './LoginErrorBox.module.scss'

interface LoginErrorBoxProps {
  loginError: JSX.Element
}

const LoginErrorBox: FC<LoginErrorBoxProps> = ({ loginError }) => {
  return (
    <>
      {loginError && (
        <div
          className={`
            ${styles['login-error-container']}
            mb-150
          `}
        >
          <ExclamationCircleFilled className='form__error-container-icon' />
          <>{loginError}</>
        </div>
      )}
    </>
  )
}

export default LoginErrorBox
