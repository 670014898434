import { Observable } from '@legendapp/state'
import { Tooltip } from 'antd'
import { FC } from 'react'

import styles from './SummaryCard.module.scss'
import { observer } from '@legendapp/state/react'

export interface SummaryCardProps {
  action?: (value: string | number) => void | null
  activeCard: Observable<string | number>
  color: 'blue' | 'green' | 'orange' | 'red'
  icon?: JSX.Element | null
  id: string | number
  summary?: JSX.Element | string | null
  title: JSX.Element | string
  total: number | string
  testId: string
}

export const SummaryCard: FC<SummaryCardProps> = observer(({
  action = null,
  activeCard,
  color,
  icon = null,
  id,
  summary = null,
  title,
  total,
  testId,
}) => {
  const isActive = activeCard.get() === id

  const handleClick = () => {
    action(id)
    if (!isActive) activeCard.set(id)
    else activeCard.set('default')
  }

  const tooltip = <>{!isActive ? 'Select' : 'Unselect'}</>

  return (
    <Tooltip
      mouseEnterDelay={1}
      title={id === 'default' && isActive ? null : tooltip}
    >
      <section
        data-testid={testId}
        className={`
          ${styles['summary-card']} ${styles['sc']}
          ${styles[`sc--${color}`]}
          ${
            isActive
              ? `${styles['sc--active']} ${styles[`sc--${color}-active`]}`
              : ''
          }
        `}
        {...(action && { onClick: handleClick })}
      >
        <div className={styles['sc__wrapper']}>
          {icon && <div className={styles['sc__icon']}>{icon}</div>}
          <div className={styles['sc__content-container']}>
            <h4 className={styles['sc__title']}>{title}</h4>
            <div className={styles['sc__summary-container']}>
              <p className={styles['sc__total']}>{total}</p>
              {summary && (
                <div className={styles['sc__summary']}>
                  <span className='mr-075'>|</span>
                  <span>{summary}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </Tooltip>
  )
})

export type SummaryCardsContainerItem = Omit<SummaryCardProps, 'activeCard'>

interface SummaryCardsContainerProps {
  activeCard: Observable<string | number>
  items: SummaryCardsContainerItem[]
  testId: string
}

export const SummaryCardsContainer: FC<SummaryCardsContainerProps> = ({
  activeCard,
  items,
  testId,
}) => {

  const root: HTMLElement = document.querySelector(':root')
  root?.style.setProperty('--summary-card-width', `${items && 100/items.length}%`)

  return (
    <div
      className={styles['summary-cards-container']}
      data-testid={testId}
    >
      {items?.map((item) => (
        <SummaryCard
          activeCard={activeCard}
          key={`summary-card--${item.id}`}
          {...item}
          data-testid={`summary-card--${item.id}`}
        />
      ))}
    </div>
  )
}
