import { FC } from 'react'

import { StructuredViewPlan } from '../utilities/eligibilityTyping'
import ReadOnlyInput from './readOnlyInput'

import './structuredViewPlanAndPlanDetails.scss'

type StructuredViewPlanProps = {
  type: 'Plan'
  details: StructuredViewPlan
}

const StructuredPlan: FC<StructuredViewPlanProps> = ({ type, details }) => {
  return (
    <section className='structured-plan-details'>
      <header className='structured-plan-details__header'>{type}</header>
      <article className='structured-plan-details__row'>
        <ReadOnlyInput
          label='Name'
          text={details.name || '--'}
          style={{ width: '100%' }}
        />
        <ReadOnlyInput
          label='Period'
          text={details.period || '--'}
          style={{ width: '100%' }}
        />
      </article>
      <article className='structured-plan-details__row'>
        <ReadOnlyInput
          label='Group Number'
          text={details.group_number || '--'}
          style={{ width: '100%' }}
        />
        <ReadOnlyInput
          label='Effective Date'
          text={details.effective_date || '--'}
          style={{ width: '100%' }}
        />
      </article>
      <article className='structured-plan-details__row'>
        <ReadOnlyInput
          label='Start Date'
          text={details.start_date || '--'}
          style={{ width: '100%' }}
        />
        <ReadOnlyInput
          label='End Date'
          text={details.end_date || '--'}
          style={{ width: '100%' }}
        />
      </article>
    </section>
  )
}

export default StructuredPlan
