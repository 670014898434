import { Col, Row } from 'antd'
import React from 'react'

import { AddressControls } from '../../../../_siteWide/address/addressControls'
import { FormInput } from '../../../../_siteWide/form/formInput'
import { FormMaskInput } from '../../../../_siteWide/form/formMaskInput'
import { FormSelect } from '../../../../_siteWide/form/formSelect'
import { Genders } from '../../../../../constants/general'
import { validateBirthday } from '../../../../../utilities/validators/birthdayValidators'
import { useClaimDetailContext } from '../../../context/claimDetailContext'

export const SubscriberControls = () => {
  const { claim, setClaim } = useClaimDetailContext()
  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <FormInput
            name='SubscriberName'
            label='Last, First'
            element={claim}
            setElement={setClaim}
            initialValue={claim.SubscriberName}
            section='patient'
            required
          />
        </Col>
        <Col span={6}>
          <FormMaskInput
            element={claim}
            format='##/##/####'
            initialValue={claim.SubscriberBirthDate}
            label='DOB'
            mask='_'
            name='SubscriberBirthDate'
            required
            section='patient'
            setElement={setClaim}
            validator={validateBirthday}
          />
        </Col>
        <Col span={6}>
          <FormSelect
            name='SUB_SEX'
            label='Gender'
            element={claim}
            setElement={setClaim}
            initialValue={claim.SUB_SEX}
            options={Genders}
            section='patient'
          />
        </Col>
      </Row>
      <AddressControls
        prefix='Subscriber'
        state={claim}
        setState={setClaim}
        section='patient'
      />
      <Row gutter={16}>
        <Col span={12}>
          <FormInput
            name='SubscriberSsn'
            label='ID/SSN'
            element={claim}
            setElement={setClaim}
            initialValue={claim.SubscriberSsn}
            section='patient'
            required
            useLabelForErrors
          />
        </Col>
        <Col span={12}>
          <FormInput
            name='SubscriberGroup'
            label='Group Number'
            element={claim}
            setElement={setClaim}
            initialValue={claim.SubscriberGroup}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormInput
            name='SUB_EMPLOYER'
            label='Employer Name'
            element={claim}
            setElement={setClaim}
            initialValue={claim.SUB_EMPLOYER}
          />
        </Col>
      </Row>
    </>
  )
}
