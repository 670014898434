import { Dialog } from '@progress/kendo-react-dialogs'
import React, { FC, useState } from 'react'

import { IAttachmentImageView } from '../../attachment.interface'
import AttachmentImageViewFull from './attachmentImageViewFull'

import '../../attachment.scss'

const AttachmentImageView: FC<IAttachmentImageView> = ({
  dialogTitle,
  attachmentImages,
  toggleImageView,
}) => {
  const [showImageFullView, toggleImageFullView] = useState<boolean>(false)
  const [image, setImage] = useState<File>()

  const showImageFull = (image: File) => {
    setImage(image)
    toggleImageFullView(true)
  }

  return (
    <Dialog
      title={dialogTitle}
      className='sent-attachment-image-container'
      onClose={() => toggleImageView(false)}
    >
      {
        <div
          className='container'
          style={{
            maxHeight: '90vh',
            maxWidth: '90vw',
            overflowY: 'auto',
            padding: '20px',
          }}
        >
          {attachmentImages.map((attachmentImage) => (
            <div className='row image-wrapper-row'>
              <div className='col-lg-6'>
                <div className={'row'}>
                  <label>Image Type:</label>
                  <span className='sent-attachment-image-properties'>
                    {attachmentImage.imageTypeName}
                  </span>
                </div>
                <div className={'row'}>
                  {attachmentImage.imageDateTaken && (
                    <>
                      <label>Date Taken:</label>
                      <span className='sent-attachment-image-properties'>
                        {attachmentImage.imageDateTaken?.toLocaleDateString()}
                      </span>
                    </>
                  )}
                </div>
                <div className={'row'}>
                  <div className='sent-attachment-image-preview'>
                    <img src={URL.createObjectURL(attachmentImage.imageData)} />
                  </div>
                </div>
              </div>
              <div className='col-lg-6 text-right'>
                <div className={'row'}>
                  <span className='link'>
                    <a
                      onClick={() => {
                        showImageFull(attachmentImage.imageData)
                      }}
                    >
                      + View Full Size
                    </a>
                  </span>
                </div>
              </div>
            </div>
          ))}
          {showImageFullView && (
            <AttachmentImageViewFull
              image={image}
              toggleImageView={toggleImageFullView}
            />
          )}
        </div>
      }
    </Dialog>
  )
}

export default AttachmentImageView
