import { Col, Empty, Modal, Row, Table } from 'antd'
import { FC, useEffect, useState } from 'react'

import { Button } from 'ui'

import poweredByPearlLogo from '../../../../assets/powered-by-pearl.png'

import './imageSyncMultiPatientModal.scss'

import { ColumnsType } from 'antd/lib/table'
import { isValid } from 'date-fns'

import { useGlobalContext } from '../../../../context/GlobalContextProvider'
import { formatDateFromString } from '../../../../utilities/general'
import { Urls } from '../../shared/sharedConstants'
import {
  ImageSyncMultiPatient,
  ImageSyncMultiPatientModalProps,
} from '../imageSync.interface'
import ImageSyncFooter from '../imageSyncFooter'

const ImageSyncMultiPatientModal: FC<ImageSyncMultiPatientModalProps> = ({
  customerSettings,
  imageSyncMultiPatientData,
  handleGetPearlPatientImages,
  isImageSyncListVisible,
  setImageSyncListVisible,
  isVisible,
  setIsVisible,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [imagesSelectedCount, setImagesSelectedCount] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  const { authentication: trellisAuthentication } = useGlobalContext()

  useEffect(() => {
    setLoading(true)
    setLoading(false)
  }, [])

  const handleRowSelection = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys)
    setImagesSelectedCount(selectedRowKeys.length)
  }

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: ImageSyncMultiPatient[],
    ) => {
      handleRowSelection(selectedRowKeys)
    },
  }

  const handleAddAndClose = async () => {
    if (selectedRowKeys?.length > 0) {
      const filteredData: ImageSyncMultiPatient[] =
        imageSyncMultiPatientData.filter((image) =>
          selectedRowKeys.some((selectedRow) => image.key === selectedRow),
        )

      if (filteredData) {
        setIsVisible(!isVisible)
        await handleGetPearlPatientImages(
          trellisAuthentication,
          customerSettings,
          filteredData[0].patientPearlId,
        )
      }
    }
  }

  const gridColumns: ColumnsType<ImageSyncMultiPatient> = [
    {
      title: 'First Name',
      dataIndex: 'patientFirstName',
      key: 'patientFirstName',
      sorter: (a, b) => a.patientFirstName.localeCompare(b.patientFirstName),
    },
    {
      title: 'Last Name',
      dataIndex: 'patientLastName',
      key: 'patientLastName',
      sorter: (a, b) => a.patientLastName.localeCompare(b.patientLastName),
    },
    {
      title: 'Date Of Birth',
      dataIndex: 'patientDateOfBirth',
      key: 'patientDateOfBirth',
      sorter: (a, b) =>
        new Date(a.patientDateOfBirth).valueOf() -
        new Date(b.patientDateOfBirth).valueOf(),
      render: (patientDateOfBirth: string) =>
        isValid(new Date(patientDateOfBirth))
          ? formatDateFromString(patientDateOfBirth, 'MM/dd/yyy')
          : '',
    },
  ]

  return (
    <Modal
      title='Select Patient'
      className='multi-patient-modal'
      open={isVisible}
      closable
      maskClosable={false}
      onCancel={() => {
        setImageSyncListVisible(!isImageSyncListVisible)
        setIsVisible(!isVisible)
      }}
      width={1000}
      footer={
        <ImageSyncFooter
          url={Urls.IMAGE_SYNC_LOGO_LEARN_MORE}
          logoPath={poweredByPearlLogo}
        />
      }
    >
      <Row className='multi-patient-modal__patient-info'>
        <Col span={12}>
          <p className='fs-125'>
            <b>Please select the correct patient</b>
          </p>
        </Col>
        <Col
          span={12}
          className='multi-patient-modal__header-actions'
        >
          <Button
            type='primary'
            disabled={loading || imagesSelectedCount == 0}
            label='Next'
            onClick={() => handleAddAndClose()}
          />
        </Col>
      </Row>
      <Row>&nbsp;</Row>
      <Row className='multi-patient-modal__grid'>
        <Col span={24}>
          <Table
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description='No patients were found'
                />
              ),
            }}
            size='middle'
            columns={gridColumns}
            dataSource={imageSyncMultiPatientData}
            loading={loading}
            rowClassName={(_, i) => i % 2 !== 0 && 'table__row--gray'}
            pagination={false}
            scroll={imageSyncMultiPatientData?.length > 5 && { y: 435 }}
            rowSelection={{
              type: 'radio',
              ...rowSelection,
            }}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default ImageSyncMultiPatientModal
