// package imports
import * as Sentry from '@sentry/react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC, useState } from 'react'
import { Outlet } from 'react-router-dom'

// app imports
import UnexpectedErrorMessage from 'trellis:features/error/error-messages/UnexpectedError'
import LoginSSO from 'trellis:features/users/login/LoginSSO'
import { LDFlags$ } from 'trellis:state/globalState'

const BaseLayout: FC = (): JSX.Element => {
  const [showOutlet, setShowOutlet] = useState<boolean>(false)

  LDFlags$.set(useFlags())

  return (
    <Sentry.ErrorBoundary
      fallback={({ componentStack, error, resetError }) => (
        <UnexpectedErrorMessage
          componentStack={componentStack}
          error={error}
          resetError={resetError}
        />
      )}
      key={location?.pathname}
    >
      {showOutlet ? <Outlet /> : <LoginSSO setShowOutlet={setShowOutlet} />}
    </Sentry.ErrorBoundary>
  )
}

export default BaseLayout
