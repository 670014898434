import {
  CheckCircleTwoTone,
  PauseCircleTwoTone,
  QuestionCircleOutlined,
  WarningTwoTone,
} from '@ant-design/icons'
import { Space } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { SortOrder } from 'antd/lib/table/interface'
import { compareAsc, compareDesc } from 'date-fns'
import { Dispatch, SetStateAction } from 'react'

import {
  AttachmentListItem,
  SentAttachmentListItem,
} from '../../../api/attachment-standalone/attachment-standalone-client'
import { formatDateFromString, showMessage } from '../../../utilities/general'
import AttachmentRowActionsMenu from '../AttachmentRowActionsMenu/AttachmentRowActionsMenu'
import CopyAttachmentIdButton from '../Common/_copyAttachmentIdButton'

import './AttachmentsGrid.scss'

const sortDatesOfService = (a: AttachmentListItem, b: AttachmentListItem) => {
  const initialService = a.datesOfService?.split(' - ')
  const finalService = b.datesOfService?.split(' - ')

  if (initialService[0] === 'Prior Auth') return 1
  else if (finalService[0] === 'Prior Auth') return -1
  else return compareAsc(new Date(initialService[0]), new Date(finalService[0]))
}

const renderDateOfService = (value: string) => {
  const dates = value?.split(' - ')

  if (dates?.length > 1) {
    return dates[0] === dates[1] ? dates[0] : value
  } else {
    return value
  }
}

const mapAttachmentStatus = (status: string) => {
  switch (status) {
    case 'Ready to Send':
      return (
        <div className='sa-status ready'>
          {getAttachmentIcon('ready')}
          <p className='sa-status--text'>Ready</p>
        </div>
      )
    case 'Holding':
    case 'On Hold':
      return (
        <div className='sa-status hold'>
          {getAttachmentIcon('holding')}
          <p className='sa-status--text'>Holding</p>
        </div>
      )
    case 'Needs Attention - Add Required':
      return (
        <div className='sa-status missing'>
          {getAttachmentIcon('missingInfo')}
          <p className='sa-status--text'>Needs Attention</p>
        </div>
      )
    default:
      return status
  }
}

export const getAttachmentIcon = (status: string) => {
  switch (status) {
    case 'ready':
      return <CheckCircleTwoTone twoToneColor='#91C73D' />
    case 'holding':
      return <PauseCircleTwoTone twoToneColor='#729DE4' />
    case 'missingInfo':
      return <WarningTwoTone twoToneColor='#F5222D' />
    default:
      return <QuestionCircleOutlined />
  }
}

const sortHelper = (
  dataIndex: string,
  sortOrder: SortOrder,
  a: AttachmentListItem | SentAttachmentListItem,
  b: AttachmentListItem | SentAttachmentListItem,
) => {
  if (sortOrder === 'descend') {
    return a[dataIndex as keyof typeof a] < b[dataIndex as keyof typeof b]
      ? 1
      : a[dataIndex as keyof typeof a] > b[dataIndex as keyof typeof b]
      ? -1
      : 0
  } else {
    return a[dataIndex as keyof typeof a] > b[dataIndex as keyof typeof b]
      ? 1
      : a[dataIndex as keyof typeof a] < b[dataIndex as keyof typeof b]
      ? -1
      : 0
  }
}

const sentSortHelper = (
  dataIndex: string,
  sortOrder: SortOrder,
  a: SentAttachmentListItem,
  b: SentAttachmentListItem,
) => {
  if (sortOrder === 'descend') {
    return a[dataIndex as keyof SentAttachmentListItem] <
      b[dataIndex as keyof SentAttachmentListItem]
      ? 1
      : a[dataIndex as keyof SentAttachmentListItem] >
        b[dataIndex as keyof SentAttachmentListItem]
      ? -1
      : 0
  } else {
    return a[dataIndex as keyof SentAttachmentListItem] >
      b[dataIndex as keyof SentAttachmentListItem]
      ? 1
      : a[dataIndex as keyof SentAttachmentListItem] <
        b[dataIndex as keyof SentAttachmentListItem]
      ? -1
      : 0
  }
}

const datesOfServiceHelper = (
  a: AttachmentListItem,
  b: AttachmentListItem,
  sortOrder: SortOrder,
) => {
  const initialService = a.datesOfService?.split(' - ')
  const finalService = b.datesOfService?.split(' - ')

  if (initialService[0] === 'Prior Auth')
    return sortOrder === 'descend' ? -1 : 1
  else if (finalService[0] === 'Prior Auth')
    return sortOrder === 'descend' ? 1 : -1
  else
    return sortOrder === 'descend'
      ? compareDesc(new Date(initialService[0]), new Date(finalService[0]))
      : compareAsc(new Date(initialService[0]), new Date(finalService[0]))
}

export const getSortedUnsentAttachments = (
  sortColumn: string,
  sortOrder: SortOrder,
  attachments: AttachmentListItem[],
) => {
  const attachmentsCopy = attachments

  switch (sortColumn) {
    case 'createDate':
      attachmentsCopy.sort((a, b) =>
        sortOrder === 'descend'
          ? compareDesc(new Date(a.createDate), new Date(b.createDate))
          : compareAsc(new Date(a.createDate), new Date(b.createDate)),
      )
      break
    case 'patientLastName':
      attachmentsCopy.sort((a, b) => sortHelper(sortColumn, sortOrder, a, b))
      break
    case 'subscriberLastName':
      attachmentsCopy.sort((a, b) => sortHelper(sortColumn, sortOrder, a, b))
      break
    case 'datesOfService':
      attachmentsCopy.sort((a, b) => datesOfServiceHelper(a, b, sortOrder))
      break
    case 'recipientName':
      attachmentsCopy.sort((a, b) => sortHelper(sortColumn, sortOrder, a, b))
      break
    case 'attachmentStatus':
      attachmentsCopy.sort((a, b) => sortHelper(sortColumn, sortOrder, a, b))
      break
    case 'providerTaxId':
      attachmentsCopy.sort((a, b) => sortHelper(sortColumn, sortOrder, a, b))
      break
    case 'lastModifiedDate':
      attachmentsCopy.sort((a, b) =>
        sortOrder === 'descend'
          ? compareDesc(
              new Date(a.lastModifiedDate),
              new Date(b.lastModifiedDate),
            )
          : compareAsc(
              new Date(a.lastModifiedDate),
              new Date(b.lastModifiedDate),
            ),
      )
      break
    default:
      showMessage('There was a problem sorting the unsent attachments.')
      break
  }

  return attachmentsCopy
}

export const getSortedSentAttachments = (
  sortColumn: string,
  sortOrder: SortOrder,
  attachments: SentAttachmentListItem[],
) => {
  const attachmentsCopy = attachments

  switch (sortColumn) {
    case 'sentDate':
      attachmentsCopy.sort((a, b) => sortHelper('sentDate', sortOrder, a, b))
      break
    case 'patientName':
      attachmentsCopy.sort((a, b) => sortHelper('patientName', sortOrder, a, b))
      break
    case 'subscriberLastName':
      attachmentsCopy.sort((a, b) =>
        sortHelper('subscriberLastName', sortOrder, a, b),
      )
      break
    case 'serviceDateDisplay':
      attachmentsCopy.sort((a, b) =>
        sortHelper('serviceDateDisplay', sortOrder, a, b),
      )
      break
    case 'recipientName':
      attachmentsCopy.sort((a, b) =>
        sortHelper('recipientName', sortOrder, a, b),
      )
      break
    case 'hmfId':
      attachmentsCopy.sort((a, b) => sortHelper('hmfId', sortOrder, a, b))
      break
    case 'providerTaxId':
      attachmentsCopy.sort((a, b) =>
        sortHelper('providerTaxId', sortOrder, a, b),
      )
      break
    default:
      showMessage('There was a problem sorting the sent attachments.')
      break
  }

  return attachmentsCopy
}

export const UnsentTableColumns = (
  isPrint: boolean,
  sentDates: [Date, Date],
  setAttachmentId: Dispatch<SetStateAction<number>>,
): ColumnsType<AttachmentListItem> => {
  const columns: ColumnsType<AttachmentListItem> = [
    {
      dataIndex: 'createDate',
      onCell: (record: AttachmentListItem) => ({
        onClick: () => setAttachmentId(record.attachmentId),
      }),
      title: 'Date Created',
      sorter: isPrint
        ? false
        : (a, b) => compareAsc(new Date(a.createDate), new Date(b.createDate)),
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: false,
    },
    {
      dataIndex: 'patientLastName',
      onCell: (record: AttachmentListItem) => ({
        onClick: () => setAttachmentId(record.attachmentId),
      }),
      title: 'Patient Name',
      sorter: isPrint
        ? false
        : (a, b) =>
            a.patientLastName > b.patientLastName
              ? 1
              : a.patientLastName < b.patientLastName
              ? -1
              : 0,
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: false,
      render: (_: string, record) =>
        `${record.patientFirstName || ''} ${record.patientLastName || ''}`,
    },
    {
      dataIndex: 'subscriberLastName',
      title: 'Subscriber Name',
      sorter: isPrint
        ? false
        : (a, b) =>
            a.subscriberLastName > b.subscriberLastName
              ? 1
              : a.subscriberLastName < b.subscriberLastName
              ? -1
              : 0,
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: false,
      render: (_: string, record) =>
        `${record.subscriberFirstName || ''} ${
          record.subscriberLastName || ''
        }`,
    },
    {
      dataIndex: 'datesOfService',
      onCell: (record: AttachmentListItem) => ({
        onClick: () => setAttachmentId(record.attachmentId),
      }),
      title: 'Service Date',
      sorter: isPrint ? false : (a, b) => sortDatesOfService(a, b),
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: false,
      render: (value: string) => renderDateOfService(value),
    },
    {
      dataIndex: 'recipientName',
      onCell: (record: AttachmentListItem) => ({
        onClick: () => setAttachmentId(record.attachmentId),
      }),
      title: 'Carrier',
      sorter: isPrint
        ? false
        : (a, b) =>
            a.recipientName > b.recipientName
              ? 1
              : a.recipientName < b.recipientName
              ? -1
              : 0,
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: false,
    },
    {
      dataIndex: 'attachmentStatus',
      onCell: (record: AttachmentListItem) => ({
        onClick: () => setAttachmentId(record.attachmentId),
      }),
      title: 'Status',
      sorter: isPrint
        ? false
        : (a, b) =>
            a.attachmentStatus > b.attachmentStatus
              ? 1
              : a.attachmentStatus < b.attachmentStatus
              ? -1
              : 0,
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: false,
      render: (value: string) => mapAttachmentStatus(value),
    },
    {
      dataIndex: 'providerTaxId',
      title: 'Tin',
      sorter: isPrint
        ? false
        : (a, b) =>
            a.providerTaxId > b.providerTaxId
              ? 1
              : a.providerTaxId < b.providerTaxId
              ? -1
              : 0,
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: false,
    },
    {
      dataIndex: 'lastModifiedDate',
      onCell: (record: AttachmentListItem) => ({
        onClick: () => setAttachmentId(record.attachmentId),
      }),
      title: 'Last Update',
      sorter: isPrint
        ? false
        : (a, b) =>
            compareAsc(
              new Date(a.lastModifiedDate),
              new Date(b.lastModifiedDate),
            ),
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: false,
    },
  ]

  if (!isPrint)
    columns.push({
      dataIndex: 'actions',
      title: '',
      width: 50,
      render: (_value, record: AttachmentListItem, index: number) => (
        <AttachmentRowActionsMenu
          index={index}
          tableItem={record}
          sentDates={sentDates}
        />
      ),
    })

  return columns
}

export const SentTableColumns = (
  isPrint: boolean,
  setSentAttachmentId: Dispatch<SetStateAction<string>>,
): ColumnsType<SentAttachmentListItem> => {
  const columns: ColumnsType<SentAttachmentListItem> = [
    {
      dataIndex: 'sentDate',
      onCell: (record: SentAttachmentListItem) => ({
        onClick: () => setSentAttachmentId(record.hmfId),
      }),
      showSorterTooltip: false,
      sorter: isPrint
        ? false
        : (a, b) =>
            a.sentDate > b.sentDate ? 1 : a.sentDate < b.sentDate ? -1 : 0,
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
      title: 'Sent Date',
      render: (value: string) => formatDateFromString(value, 'MM/dd/yyyy'),
    },
    {
      dataIndex: 'patientName',
      onCell: (record: SentAttachmentListItem) => ({
        onClick: () => setSentAttachmentId(record.hmfId),
      }),
      showSorterTooltip: false,
      sorter: isPrint
        ? false
        : (a, b) =>
            a.patientName > b.patientName
              ? 1
              : a.patientName < b.patientName
              ? -1
              : 0,
      sortDirections: ['descend', 'ascend', 'descend'],
      title: 'Patient Name',
    },
    {
      dataIndex: 'subscriberLastName',
      title: 'Subscriber Name',
      sorter: isPrint
        ? false
        : (a, b) =>
            a.subscriberLastName > b.subscriberLastName
              ? 1
              : a.subscriberLastName < b.subscriberLastName
              ? -1
              : 0,
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: false,
      render: (_: string, record) =>
        `${record.subscriberFirstName || ''} ${
          record.subscriberLastName || ''
        }`,
    },
    {
      dataIndex: 'serviceDateDisplay',
      onCell: (record: SentAttachmentListItem) => ({
        onClick: () => setSentAttachmentId(record.hmfId),
      }),
      render: (value: string) => renderDateOfService(value),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend', 'descend'],
      sorter: isPrint
        ? false
        : (a, b) =>
            a.serviceDateDisplay > b.serviceDateDisplay
              ? 1
              : a.serviceDateDisplay < b.serviceDateDisplay
              ? -1
              : 0,
      title: 'Service Date',
    },
    {
      dataIndex: 'recipientName',
      onCell: (record: SentAttachmentListItem) => ({
        onClick: () => setSentAttachmentId(record.hmfId),
      }),
      showSorterTooltip: false,
      sorter: isPrint
        ? false
        : (a, b) =>
            a.recipientName > b.recipientName
              ? 1
              : a.recipientName < b.recipientName
              ? -1
              : 0,
      sortDirections: ['descend', 'ascend', 'descend'],
      title: 'Carrier',
    },
    {
      onCell: (record: SentAttachmentListItem) => ({
        onClick: () => setSentAttachmentId(record.hmfId),
      }),
      title: 'Status',
      dataIndex: 'status',
      render: (_: string) => (
        <div className='sa-status sent'>
          <CheckCircleTwoTone twoToneColor='#91C73D' />
          <p className='sa-status--text'>Sent</p>
        </div>
      ),
    },
    {
      dataIndex: 'hmfId',
      render: (value) => {
        return (
          <Space size='middle'>
            <p>NEA#{value}</p>
            <CopyAttachmentIdButton attachmentId={value} />
          </Space>
        )
      },
      showSorterTooltip: false,
      sorter: isPrint
        ? false
        : (a, b) => (a.hmfId > b.hmfId ? 1 : a.hmfId < b.hmfId ? -1 : 0),
      sortDirections: ['descend', 'ascend', 'descend'],
      title: 'Attachment ID',
    },
    {
      dataIndex: 'providerTaxId',
      title: 'Tin',
      sorter: isPrint
        ? false
        : (a, b) =>
            a.providerTaxId > b.providerTaxId
              ? 1
              : a.providerTaxId < b.providerTaxId
              ? -1
              : 0,
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: false,
    },
  ]
  return columns
}
