type ClaimFormFieldsMap = {
  fieldName: string
  fieldLabel: string
}

export const claimFormFieldsMap: ClaimFormFieldsMap[] = [
  {
    fieldName: 'PatientName',
    fieldLabel: 'Patient Name',
  },
  {
    fieldName: 'PatientBirthDate',
    fieldLabel: 'Patient Date of Birth',
  },
  {
    fieldName: 'PAT_SEX',
    fieldLabel: 'Patient Gender',
  },
  {
    fieldName: 'PatientAddress',
    fieldLabel: 'Patient Address',
  },
  {
    fieldName: 'PatientCity',
    fieldLabel: 'Patient City',
  },
  {
    fieldName: 'PatientState',
    fieldLabel: 'Patient State',
  },
  {
    fieldName: 'PatientZip',
    fieldLabel: 'Patient Zip Code',
  },
  {
    fieldName: 'PatientRelationship',
    fieldLabel: 'Relationship to Policyholder/Subscriber',
  },
  {
    fieldName: 'Carrier',
    fieldLabel: 'Carrier',
  },
  {
    fieldName: 'CarrierAddress',
    fieldLabel: 'Carrier Address',
  },
  {
    fieldName: 'CarrierCity',
    fieldLabel: 'Carrier City',
  },
  {
    fieldName: 'CarrierState',
    fieldLabel: 'Carrier State',
  },
  {
    fieldName: 'CarrierZip',
    fieldLabel: 'Carrier Zip Code',
  },
  {
    fieldName: 'SubscriberName',
    fieldLabel: 'Subscriber Name',
  },
  {
    fieldName: 'SubscriberBirthDate',
    fieldLabel: 'Subscriber Date of Birth',
  },
  {
    fieldName: 'SUB_SEX',
    fieldLabel: 'Subscriber Gender',
  },
  {
    fieldName: 'SubscriberAddress',
    fieldLabel: 'Subscriber Address',
  },
  {
    fieldName: 'SubscriberCity',
    fieldLabel: 'Subscriber City',
  },
  {
    fieldName: 'SubscriberState',
    fieldLabel: 'Subscriber State',
  },
  {
    fieldName: 'SubscriberZip',
    fieldLabel: 'Subscriber Zip Code',
  },
  {
    fieldName: 'SubscriberSsn',
    fieldLabel: 'Subscriber ID/SSN',
  },
  {
    fieldName: 'SubscriberGroup',
    fieldLabel: 'Group Number',
  },
  {
    fieldName: 'SUB_EMPLOYER',
    fieldLabel: 'Employer Name',
  },
  {
    fieldName: 'CobName',
    fieldLabel: 'COB Name',
  },
  {
    fieldName: 'CobBirthDate',
    fieldLabel: 'COB Date of Birth',
  },
  {
    fieldName: 'CobSsn',
    fieldLabel: 'COB ID/SSN',
  },
  {
    fieldName: 'CobCarrier',
    fieldLabel: 'COB Carrier',
  },
  {
    fieldName: 'COB_RELATIONSHIP',
    fieldLabel: 'COB Relationship to Subscriber',
  },
  {
    fieldName: 'claim-type',
    fieldLabel: 'Claim Type',
  },
  {
    fieldName: 'PRIOR_AUTH',
    fieldLabel: 'Prior Authorization #',
  },
  {
    fieldName: 'ProcedureDate',
    fieldLabel: 'Procedure Date',
  },
  {
    fieldName: 'OralCavityCode',
    fieldLabel: 'Oral Cavity',
  },
  {
    fieldName: 'ToothNumber',
    fieldLabel: 'Tooth',
  },
  {
    fieldName: 'ToothSurfaces',
    fieldLabel: 'Surface',
  },
  {
    fieldName: 'ProcedureCode',
    fieldLabel: 'Code',
  },
  {
    fieldName: 'DiagPointer',
    fieldLabel: 'Pointer',
  },
  {
    fieldName: 'Quantity',
    fieldLabel: 'Quantity',
  },
  {
    fieldName: 'ProcedureCodeDesc',
    fieldLabel: 'Description',
  },
  {
    fieldName: 'ProcedureChargeAmount',
    fieldLabel: 'Fee',
  },
  {
    fieldName: 'Remarks',
    fieldLabel: 'Remarks',
  },
  {
    fieldName: 'CodeListQualifier',
    fieldLabel: 'Code List Qualifier',
  },
  {
    fieldName: 'DiagCodeA',
    fieldLabel: 'Diag Code A',
  },
  {
    fieldName: 'DiagCodeB',
    fieldLabel: 'Diag Code B',
  },
  {
    fieldName: 'DiagCodeC',
    fieldLabel: 'Diag Code C',
  },
  {
    fieldName: 'DiagCodeD',
    fieldLabel: 'Diag Code D',
  },
  {
    fieldName: 'COB_AMOUNT',
    fieldLabel: 'Primary Payment Amount',
  },
  {
    fieldName: 'PaymentDate',
    fieldLabel: 'Primary Payment Date',
  },
  {
    fieldName: 'PatientLiability',
    fieldLabel: 'Patient Liability Amount',
  },
  {
    fieldName: 'AdjustmentGroup',
    fieldLabel: 'Group Code',
  },
  {
    fieldName: 'AdjustmentReason',
    fieldLabel: 'Reason Code',
  },
  {
    fieldName: 'PLACE_OF_TREATMENT',
    fieldLabel: 'Place of Treatment',
  },
  {
    fieldName: 'PROSTHESIS_INITIAL_PLACEMENT',
    fieldLabel: 'Replacement of Prosthesis',
  },
  {
    fieldName: 'PRIOR_PLACEMENT_DATE',
    fieldLabel: 'Prosthesis Replacement Date',
  },
  {
    fieldName: 'ORTHO_FLAG',
    fieldLabel: 'Treatment of Orthodontics',
  },
  {
    fieldName: 'APPLIANCE_DATE',
    fieldLabel: 'Date Appliance Placed',
  },
  {
    fieldName: 'TREATMENT_MONTHS',
    fieldLabel: 'Treatment Remaining',
  },
  {
    fieldName: 'ACCIDENT_TYPE',
    fieldLabel: 'Treatment Resulting From',
  },
  {
    fieldName: 'ACCIDENT_ST',
    fieldLabel: 'Accident State',
  },
  {
    fieldName: 'ACCIDENT_DATE',
    fieldLabel: 'Accident Date',
  },
  {
    fieldName: 'SIGNATURE',
    fieldLabel: 'Release of Information by Provider',
  },
  {
    fieldName: 'ASSIGNMENT_SIGNATURE',
    fieldLabel: 'Assignment of Payment to Provider',
  },
  {
    fieldName: 'BillingName',
    fieldLabel: 'Billing Name',
  },
  {
    fieldName: 'BillingPhone',
    fieldLabel: 'Billing Phone Number',
  },
  {
    fieldName: 'BillingAddress',
    fieldLabel: 'Billing Address',
  },
  {
    fieldName: 'BillingCity',
    fieldLabel: 'Billing City',
  },
  {
    fieldName: 'BillingState',
    fieldLabel: 'Billing State',
  },
  {
    fieldName: 'BillingZip',
    fieldLabel: 'Billing Zip Code',
  },
  {
    fieldName: 'BillingLicense',
    fieldLabel: 'Billing License',
  },
  {
    fieldName: 'NpiGroup',
    fieldLabel: 'Billing Group NPI',
  },
  {
    fieldName: 'Tin',
    fieldLabel: ' Billing Tax ID',
  },
  {
    fieldName: 'BillingProviderId',
    fieldLabel: 'Billing Provider ID',
  },
  {
    fieldName: 'TreatingName',
    fieldLabel: 'Treating Name',
  },
  {
    fieldName: 'TreatmentAddress',
    fieldLabel: 'Treatment Address',
  },
  {
    fieldName: 'TreatmentCity',
    fieldLabel: 'Treatment Address',
  },
  {
    fieldName: 'TreatmentState',
    fieldLabel: 'Treatment State',
  },
  {
    fieldName: 'TreatmentZip',
    fieldLabel: 'Treatment Zip Code',
  },
  {
    fieldName: 'TreatingLicense',
    fieldLabel: 'Treating License',
  },
  {
    fieldName: 'IndividualNpi',
    fieldLabel: 'Individual NPI',
  },
  {
    fieldName: 'TreatmentProviderId',
    fieldLabel: 'Treatment Provider ID',
  },
  {
    fieldName: 'SPECIALTY',
    fieldLabel: 'Provider Specialty Code',
  },
]
