import { Col, Row } from 'antd'

import { stateSelectOptions } from '../../../constants/stateSelect'
import { validateZip } from '../../../utilities/validators/zipValidator'
import { FormInput } from '../form/formInput'
import { FormSelect } from '../form/formSelect'

export const AddressControls = ({
  prefix,
  readyOnly = false,
  section,
  setState,
  showAddress2 = false,
  state,
}: any) => {
  prefix = prefix || ''
  return (
    <>
      <Row>
        <Col span={24}>
          <FormInput
            disabled={readyOnly}
            name={`${prefix}Address`}
            label='Address'
            element={state}
            setElement={setState}
            initialValue={state[`${prefix}Address`]}
            section={section}
            required
          />
        </Col>
      </Row>
      {showAddress2 && (
        <Row>
          <Col span={24}>
            <FormInput
              disabled={readyOnly}
              name={`${prefix}Address2`}
              label='Address Line 2'
              element={state}
              setElement={setState}
              initialValue={state[`${prefix}Address2`]}
              section={section}
              variant={readyOnly ? 'borderless' : null}
            />
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col span={12}>
          <FormInput
            disabled={readyOnly}
            name={`${prefix}City`}
            label='City'
            element={state}
            setElement={setState}
            initialValue={state[`${prefix}City`]}
            section={section}
            required
          />
        </Col>
        <Col span={6}>
          <FormSelect
            disabled={readyOnly}
            name={`${prefix}State`}
            label='State'
            element={state}
            setElement={setState}
            initialValue={state[`${prefix}State`]}
            options={stateSelectOptions}
            filterOption={(input: string, option: any) =>
              option.children.toLowerCase().startsWith(input.toLowerCase())
            }
            optionFilterProp='children'
            showSearch
            section={section}
            required
          />
        </Col>
        <Col span={6}>
          <FormInput
            disabled={readyOnly}
            name={`${prefix}Zip`}
            label='Zip Code'
            element={state}
            setElement={setState}
            initialValue={state[`${prefix}Zip`]}
            validator={validateZip}
            required
          />
        </Col>
      </Row>
    </>
  )
}
