import { FC } from 'react'

import styles from './FormRadioInput.module.scss'

interface FormRadioInputProps {
  checked: boolean
  label: string
  name: string
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  value: string
}

export const FormRadioInput: FC<FormRadioInputProps> = ({
  checked,
  label,
  name,
  onChange,
  value,
}) => {
  return (
    <div className='flex-row items-center gap-050'>
      <input
        checked={checked}
        className={styles['form-control-group__radio-input']}
        name={name}
        onChange={onChange}
        type='radio'
        value={value}
      />
      <label
        className={styles['form-control-group__radio-label']}
        htmlFor={value}
      >
        {label}
      </label>
    </div>
  )
}
