import DOMPurify from 'dompurify'
import React from 'react'

const HtmlChunk = ({ data }: any) => {
  const embeddedHtml = DOMPurify.sanitize(data)
  return (
    <div
      className='m-2'
      dangerouslySetInnerHTML={{ __html: embeddedHtml }}
    />
  )
}

export default HtmlChunk
