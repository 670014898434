import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { FC } from 'react'

import styles from './AlertBanner.module.scss'

interface AlertBannerProps {
  message?: JSX.Element | string | null
  title?: JSX.Element | string | null
  type?: string | null
}

export const AlertBanner: FC<AlertBannerProps> = ({
  message = null,
  title = null,
  type = 'error',
}) => {
  const classSelectors = `
    ${styles['alert-banner']}
    ${styles[`alert-banner--${type}`]}
  `

  return (
    <div
      className={classSelectors}
      data-testid='alert-banner'
    >
      {type === 'error' ? (
        <ExclamationCircleOutlined
          style={{ color: 'var(--alert-banner-color)', fontSize: '1.625em' }}
        />
      ) : type === 'warning' ? (
        <WarningOutlined
          style={{ color: 'var(--alert-banner-color)', fontSize: '1.625em' }}
        />
      ) : type === 'success' ? (
        <CheckCircleOutlined
          style={{ color: 'var(--alert-banner-color)', fontSize: '1.625em' }}
        />
      ) : null}
      <section>
        <h6 className={styles['alert-banner__title']}>{title}</h6>
        <p className={styles['alert-banner__message']}>{message}</p>
      </section>
    </div>
  )
}
