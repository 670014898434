import Fuse from 'fuse.js'
import { useCallback, useMemo, useState } from 'react'
import { debounce } from 'throttle-debounce'

interface UseFuseArguments<ListType> {
  data: ListType[]
  filterCallBack?: (data: ListType[]) => ListType[]
  matchAllOnEmptyQuery: boolean
  options: Fuse.IFuseOptions<ListType>
  sortCallBack?: (data: ListType[]) => ListType[]
}

export const useFuse = <ListType>({
  data,
  filterCallBack,
  matchAllOnEmptyQuery,
  options,
  sortCallBack,
}: UseFuseArguments<ListType>) => {
  const [query, setQuery] = useState<string>('')

  const fuseOptions = {
    ...options,
    findAllMatches: true,
    ignoreLocation: true,
    includeMatches: true,
    includeScore: true,
    threshold: 0.3,
  }

  const fuse = useMemo(() => {
    if (filterCallBack) {
      const list: ListType[] = filterCallBack(data)
      return new Fuse<ListType>(list, fuseOptions)
    }

    return new Fuse<ListType>(data, fuseOptions)
  }, [data, options])

  const tokeniseStringWithQuotesBySpaces = (string: string): string[] =>
    string.match(/("[^"]*?"|[^"\s]+)+(?=\s*|\s*$)/g) ?? []

  const hits = useMemo<Fuse.FuseResult<ListType>[]>(() => {
    const normalizedSearchQuery = query.trim()
    if (!normalizedSearchQuery)
      return (fuse.getIndex() as any).docs
        .slice(0, data.length)
        .map((item: ListType, refIndex: number) => ({ item, refIndex }))

    const tokenisedSearchQuery = tokeniseStringWithQuotesBySpaces(
      normalizedSearchQuery,
    )
    if (tokenisedSearchQuery.length === 0)
      return (fuse.getIndex() as any).docs
        .slice(0, data.length)
        .map((item: ListType, refIndex: number) => ({ item, refIndex }))

    return fuse.search({
      $and: tokenisedSearchQuery.map((searchToken: string) => {
        const orFields: Fuse.Expression[] = options.keys.map(
          (value: Fuse.FuseOptionKey<ListType>) => {
            return { [value as string]: searchToken }
          },
        )

        return { $or: orFields }
      }),
    })
  }, [fuse, data.length, matchAllOnEmptyQuery, query])

  const hitItems = useMemo(() => {
    if (sortCallBack) {
      return sortCallBack(
        hits.map((hit: Fuse.FuseResult<ListType>) => hit.item),
      )
    }

    return hits.map((hit: Fuse.FuseResult<ListType>) => hit.item)
  }, [hits])

  const updateQuery = useCallback(debounce(100, setQuery), [])

  const onSearch = useCallback(
    (searchText: string) => updateQuery(searchText.trim()),
    [updateQuery],
  )

  return {
    hitItems,
    hits,
    onSearch,
    query,
    updateQuery,
  }
}
