import React, { useEffect } from 'react'

import AttachmentsList from '../../../attachment/attachmentsList'
import { useClaimDetailContext } from '../../context/claimDetailContext'

const AttachmentContent = () => {
  const {
    claim,
    sentAttachments,
    setIsCreatedFromSentAttachment,
  } = useClaimDetailContext()

  useEffect(() => {
    if (claim.Resend && claim.Carrier && sentAttachments?.length <= 0) {
      setIsCreatedFromSentAttachment(true)
    }
  }, [])

  return (
    <>
      <AttachmentsList />
    </>
  )
}

export default AttachmentContent
