import {
  BellTwoTone,
  CheckCircleOutlined,
  CheckCircleTwoTone,
  CloseCircleOutlined,
  CloseCircleTwoTone,
  CopyTwoTone,
  ExclamationCircleOutlined,
  ExclamationCircleTwoTone,
  InteractionTwoTone,
  PauseCircleOutlined,
  PrinterTwoTone,
  QuestionCircleTwoTone,
  StopTwoTone,
  WarningTwoTone,
} from '@ant-design/icons'
import React, { ReactNode } from 'react'

import { FilterByOptions, QueueClaimPendingIcon } from 'ui'

import { LDFlags$ } from 'trellis:state/globalState'

export enum QuickFilters {
  READY = 'In Queue',
  FAILED = 'Failed Validation',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  NOT_ELIGIBLE = 'Not Eligible',
  ELIGIBLE = 'Eligible',
}

export type StatusFilterOption = {
  Label: string
  Value: string
  Icon: ReactNode
  TagColor?: string
  Hidden?: boolean
}

const tagColors = {
  green: '#F1F8E7',
  red: '#f5182d1a',
  blue: '#729de41a',
  grey: '#F5F5F5',
  yellow: '#f0a51f1a',
}

export const unsentClaimFilterOptions: FilterByOptions = [
  {
    title: 'Failed',
    value: 'Failed Validation',
  },
  {
    title: 'Holding',
    value: 'Hold',
  },
  {
    title: 'Ready',
    value: 'In Queue',
  },
  {
    title: 'Needs Attention',
    value: 'Failed Validation,Pending,Other Coverage',
    hidden: LDFlags$.claimValidation.get(),
  },
  {
    title: 'Pending',
    value: 'Pending',
    hidden: LDFlags$.claimValidation.get()
  },

]

export const sentClaimFilterOptions: FilterByOptions = [
  {
    value: 'Accepted',
    title: 'Accepted',
  },
  {
    value: 'Rejected',
    title: 'Rejected',
  },
  {
    value: 'In Process',
    title: 'In Process',
  },
  {
    value: 'Disabled',
    title: 'Disabled',
  },
  {
    value: 'Printed',
    title: 'Printed',
  },
  {
    value: 'Duplicate',
    title: 'Duplicate',
  },
]

export const sentClaimFilters: StatusFilterOption[] = [
  {
    Label: 'Accepted',
    Value: 'Accepted',
    Icon: (
      <CheckCircleTwoTone
        twoToneColor='#91C73D'
        style={{ width: 14, height: 14 }}
      />
    ),
    TagColor: tagColors.green,
  },
  {
    Label: 'Rejected',
    Value: 'Rejected',
    Icon: (
      <CloseCircleTwoTone
        twoToneColor='#F5222D'
        style={{ width: 14, height: 14 }}
      />
    ),
    TagColor: tagColors.red,
  },
  {
    Label: 'In Process',
    Value: 'In Process',
    Icon: (
      <InteractionTwoTone
        twoToneColor='#729DE4'
        style={{ width: 14, height: 14 }}
      />
    ),
    TagColor: tagColors.blue,
  },
  {
    Label: 'Disabled',
    Value: 'Disabled',
    Icon: (
      <WarningTwoTone
        twoToneColor='#F0A51F'
        style={{ width: 14, height: 14 }}
      />
    ),
    TagColor: tagColors.yellow,
  },
  {
    Label: 'Printed',
    Value: 'Printed',
    Icon: (
      <PrinterTwoTone
        twoToneColor='#87878A'
        style={{ width: 14, height: 14 }}
      />
    ),
    TagColor: tagColors.grey,
  },
  {
    Label: 'Duplicate',
    Value: 'Duplicate',
    Icon: (
      <CopyTwoTone
        twoToneColor='#87878A'
        style={{ width: 14, height: 14 }}
      />
    ),
    TagColor: tagColors.grey,
  },
]

export const eligibilityFilters: StatusFilterOption[] = [
  {
    Label: 'Failed',
    Value: 'Failed',
    Icon: (
      <ExclamationCircleTwoTone
        twoToneColor='#F5222D'
        style={{ width: 14, height: 14 }}
      />
    ),
    TagColor: tagColors.red,
  },
  {
    Label: 'Not Eligible',
    Value: 'Not Eligible',
    Icon: (
      <CloseCircleTwoTone
        twoToneColor='#F5222D'
        style={{ width: 14, height: 14 }}
      />
    ),
    TagColor: tagColors.red,
  },
  {
    Label: 'Patient Response',
    Value: 'Patient Response',
    Icon: (
      <BellTwoTone
        twoToneColor='#729DE4'
        style={{ width: 14, height: 14 }}
      />
    ),
    TagColor: tagColors.blue,
  },
  {
    Label: 'Eligible',
    Value: 'Eligible',
    Icon: (
      <CheckCircleTwoTone
        twoToneColor='#91C73D'
        style={{ width: 14, height: 14 }}
      />
    ),
    TagColor: tagColors.green,
  },
  {
    Label: 'Pending Patient Response',
    Value: 'Pending Patient Response',
    Icon: (
      <BellTwoTone
        twoToneColor='#F0A51F'
        style={{ width: 14, height: 14 }}
      />
    ),
    TagColor: tagColors.yellow,
  },
  {
    Label: 'Unverified',
    Value: 'Unverified',
    Icon: (
      <QuestionCircleTwoTone
        twoToneColor='#87878A'
        style={{ width: 14, height: 14 }}
      />
    ),
    TagColor: tagColors.grey,
  },
  {
    Label: 'Unsupported Carrier',
    Value: 'Unsupported Carrier',
    Icon: (
      <StopTwoTone
        twoToneColor='#87878A'
        style={{ width: 14, height: 14 }}
      />
    ),
    TagColor: tagColors.grey,
  },
  {
    Label: 'Needs Attention',
    Value: 'Needs Attention',
    Icon: (
      <ExclamationCircleTwoTone
        twoToneColor='#F0A51F'
        style={{ width: 14, height: 14 }}
      />
    ),
    TagColor: tagColors.yellow,
  },
]

export const setQuickFilterClasses = (filter: string, currentStatusFilter: string) => {
//export const setQuickFilterClasses = (filter: string, filterState: any) => {
  switch (filter) {
    case 'Failed':
    case 'Failed Validation':
    case 'Rejected':
    case 'Not Eligible':
      return `status-filter-button failure ${
        filter === currentStatusFilter
          ? 'failure-active'
          : ''
      }`
    case 'In Queue':
    case 'Accepted':
    case 'Eligible':
      return `status-filter-button success ${
        filter === currentStatusFilter
          ? 'success-active'
          : ''
      }`
    default:
      return null
  }
}

export const setTagBorderColor = (filter: string) => {
  let borderColor: string

  switch (filter) {
    case 'In Queue':
    case 'Accepted':
      borderColor = 'var(--color-primary)'
      break
    case 'Failed Validation':
    case 'Failed Validation,Pending,Other Coverage':
    case 'Rejected':
      borderColor = 'var(--error-200)'
      break
    case 'Holding':
    case 'Pending':
      borderColor = 'var(--charcoal-200)'
      break
    default:
      borderColor = 'var(--charcoal-200)'
      break
  }
  return borderColor
}
