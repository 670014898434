import { AxiosPromise, AxiosResponse } from 'axios'

import GlobalState from 'trellis:state/globalState'
import trellisConfiguration from 'trellis:utilities/config'

import { IAuthenticatedApiModel } from '../../utilities/api'
import {
  ArchiveClaimsApiFactory,
  ClaimApiFactory,
  ClaimDetailsModel,
  ClaimPrintResponse,
  ClaimResourcesApiFactory,
  ClaimSaveSubmitResponse,
  ClaimSettingsApiFactory,
  ClaimStatusCodes,
  Configuration,
  SerialOption,
  UpdateClaimSettingsRequest,
} from './claim-client'
import { ApiTypes } from './claimApiTypes'

const trellisApi: string = trellisConfiguration.trellis_apiUrl
export interface SendResult {
  response: AxiosResponse<any>
  isQueuedSend: boolean
}
export interface IClaimsApi extends IClaimApi {
  GetClaims: (payload: any) => Promise<AxiosResponse<any>>
  UpdateClaimsStatus: (payload: any) => Promise<AxiosResponse<void>>
  UpdateClaimsIgnoreAttachmentRequirements: (payload: any) => AxiosPromise<void>
  DeleteClaims: (payload: any) => Promise<AxiosResponse<any>>
  ExportClaims: (payload: any) => Promise<AxiosResponse<any>>
  ProcessClaims: (payload: any) => Promise<SendResult>
  IgnoreClaims: (payload: any) => Promise<AxiosResponse<any>>
  PrintClaims: (payload: any) => Promise<AxiosResponse<any>>
  GetFilterDropdowns: (payload: any) => Promise<AxiosResponse<any>>
}
export interface IClaimApi {
  GetClaim: (claimId: number) => Promise<AxiosResponse<ClaimDetailsModel>>
  ProcessClaim: (
    claimId: number,
    claimDetailsModel: ClaimDetailsModel,
  ) => Promise<AxiosResponse<ClaimSaveSubmitResponse>>
  DeleteClaim: (claimId: number) => Promise<AxiosResponse<void>>
  UpdateClaim: (
    claimId: number,
    claimDetailsModel: ClaimDetailsModel,
  ) => Promise<AxiosResponse<ClaimSaveSubmitResponse>>
  PostClaim: (
    claimDetailsModel: ClaimDetailsModel,
  ) => Promise<AxiosResponse<number>>
  PrintClaimAdaForm: (
    claimId: number,
  ) => Promise<AxiosResponse<ClaimPrintResponse>>
  SubmitSolicitedClaimAttachment: (
    claimId: number,
    claimCustomerID: number,
  ) => Promise<AxiosResponse<void>>
  GetClaimStatusCodes: () => Promise<AxiosResponse<ClaimStatusCodes[]>>
}

export abstract class AbstractClaimApi implements IClaimApi {
  private configurationClaim: Configuration
  configuration: Configuration
  accessToken: string
  customerId: number

  constructor(authentication: IAuthenticatedApiModel, apiType: ApiTypes) {
    this.configuration = new Configuration({
      basePath: trellisApi + apiType,
      accessToken: authentication.AccessToken,
    })
    this.configurationClaim = new Configuration({
      basePath: trellisApi + ApiTypes.CLAIM,
      accessToken: authentication.AccessToken,
    })
    this.accessToken = authentication.AccessToken
    this.customerId = parseInt(authentication?.CustomerId)
  }

  public async GetClaim(claimId: number) {
    return await ClaimApiFactory(
      new Configuration({
        basePath: trellisApi + ApiTypes.CLAIM,
        accessToken: GlobalState.Auth.AccessToken.peek(),
      }),
    ).claimGetClaim(claimId)
  }

  public async ProcessClaim(
    claimId: number,
    claimDetailsModel: ClaimDetailsModel,
  ) {
    return await ClaimApiFactory(this.configurationClaim).claimUpdateClaim(
      claimId,
      claimDetailsModel,
    )
  }

  public async DeleteClaim(claimId: number) {
    return await ClaimApiFactory(this.configurationClaim).claimDeleteClaim(
      claimId,
    )
  }

  public async UpdateClaim(
    claimId: number,
    claimDetailsModel: ClaimDetailsModel,
  ) {
    return await ClaimApiFactory(this.configurationClaim).claimUpdateClaim(
      claimId,
      claimDetailsModel,
    )
  }

  public async PostClaim(claimDetailsModel: ClaimDetailsModel) {
    return await ClaimApiFactory(this.configurationClaim).claimPostClaim(
      claimDetailsModel,
    )
  }

  public async PrintClaimAdaForm(claimId: number) {
    return await ClaimApiFactory(
      this.configurationClaim,
    ).claimCreateClaimPrintAdaForm(claimId)
  }

  public async SubmitSolicitedClaimAttachment(
    claimId: number,
    claimCustomerID: number,
  ) {
    return await ClaimApiFactory(
      this.configurationClaim,
    ).claimResubmitAttachmentClaimId(claimId, claimCustomerID)
  }

  public async GetClaimStatusCodes() {
    return await ClaimResourcesApiFactory(
      this.configurationClaim,
    ).claimResourcesGetStatusCodes()
  }
}

export const GetClaim = async (claimId: number) => {
  return await ClaimApiFactory(
    new Configuration({
      basePath: trellisApi + ApiTypes.CLAIM,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).claimGetClaim(claimId)
}

export const UpdateClaim = async (
  claimId: number,
  claimDetailsModel: ClaimDetailsModel,
) => {
  return await ClaimApiFactory(
    new Configuration({
      basePath: trellisApi + ApiTypes.CLAIM,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).claimUpdateClaim(claimId, claimDetailsModel)
}

export const PostClaim = async (claimDetailsModel: ClaimDetailsModel) => {
  return await ClaimApiFactory(
    new Configuration({
      basePath: trellisApi + ApiTypes.CLAIM,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).claimPostClaim(claimDetailsModel)
}

export const SubmitSolicitedClaimAttachment = async (
  claimId: number,
  claimCustomerID: number,
) => {
  return await ClaimApiFactory(
    new Configuration({
      basePath: trellisApi + ApiTypes.CLAIM,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).claimResubmitAttachmentClaimId(claimId, claimCustomerID)
}

export const GetClaimStatusCodes = async () => {
  return await ClaimResourcesApiFactory(
    new Configuration({
      basePath: trellisApi + ApiTypes.CLAIM,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).claimResourcesGetStatusCodes()
}

export const PrintClaimAdaForm = async (claimId: number) => {
  return await ClaimApiFactory(
    new Configuration({
      basePath: trellisApi + ApiTypes.CLAIM,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).claimCreateClaimPrintAdaForm(claimId)
}

export const GetArchiveClaims = async (
  minDate: string,
  maxDate: string,
  archiveText: string,
) => {
  return await ArchiveClaimsApiFactory(
    new Configuration({
      basePath: trellisApi + ApiTypes.CLAIM,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).archiveClaimsSearchArchiveClaims(GlobalState.Auth.CustomerIdNumber.peek(), {
    MinDate: minDate,
    MaxDate: maxDate,
    ArchiveText: archiveText,
  })
}

export const GetLetterOfTimelyFiling = async (claimId: number) => {
  return await ArchiveClaimsApiFactory(
    new Configuration({
      basePath: trellisApi + ApiTypes.CLAIM,
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).archiveClaimsGetLetterOfTimelyFiling(
    GlobalState.Auth.CustomerIdNumber.peek(),
    claimId,
  )
}

const getSettingsConfig = () =>
  new Configuration({
    basePath: trellisApi + ApiTypes.CLAIM,
    accessToken: GlobalState.Auth.AccessToken.peek(),
  })

export const GetClaimSettings = async () => {
  const customerId = GlobalState.UserInfo.globalCustomerID.peek()

  return await ClaimSettingsApiFactory(
    getSettingsConfig(),
  ).claimSettingsGetClaimSettings(customerId)
}

export const UpdateClaimSettings = async (
  payload: UpdateClaimSettingsRequest,
) => {
  const customerId = GlobalState.UserInfo.globalCustomerID.peek()

  return await ClaimSettingsApiFactory(
    getSettingsConfig(),
  ).claimSettingsUpdateClaimSettings(customerId, payload)
}

export const UpdateSerialOption = async (payload: SerialOption) => {
  const customerId = GlobalState.UserInfo.globalCustomerID.peek()

  return await ClaimSettingsApiFactory(
    getSettingsConfig(),
  ).claimSettingsUpdateSerialOption(customerId, payload.Serial_ID, payload)
}
