import { MinusCircleOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { FC, useEffect } from 'react'

import { FormCurrencyInput } from '../../../../../_siteWide/form/formCurrencyInput'
import { FormInput } from '../../../../../_siteWide/form/formInput'
import { FormMaskInput } from '../../../../../_siteWide/form/formMaskInput'
import { FormNumber } from '../../../../../_siteWide/form/formNumber'
import { validateBirthday } from '../../../../../../utilities/validators/birthdayValidators'
import { validateProcedureCode } from '../../../../../../utilities/validators/procedureCodeValidator'
import { validateQuantityOneToOneHundred } from '../../../../../../utilities/validators/quantityOneToOneHundred'
import { useClaimDetailContext } from '../../../../context/claimDetailContext'
import { ClaimErrorsType, LineItemType } from '../../../../util/claimTypes'

const LineItem: FC<{ index: number }> = ({ index }) => {
  const { claim, setClaim } = useClaimDetailContext()

  useEffect(() => {
    const copy = { ...claim }
    copy.LineItems[index].SERVICE_DATE_String =
      claim.LineItems[index].ProcedureDate
    setClaim(copy)
  }, [claim.LineItems[index]?.ProcedureDate])

  //needed to give proper string format for claim api
  useEffect(() => {
    const copy = { ...claim }
    copy.LineItems[index].Quantity = copy.LineItems[index].Quantity.toString()
    setClaim(copy)
  }, [claim.LineItems[index]?.Quantity])

  const handleRemove = () => {
    const copy = { ...claim }

    const items: LineItemType[] = copy.LineItems.splice(index, 1).map(
      (lineItem): LineItemType => ({ ...lineItem }),
    )

    if (items.length > 1)
      items.map(
        (lineItem: LineItemType, i: number) => (lineItem.LineNumber = i + 1),
      )

    copy.errors = copy.errors.filter((e: ClaimErrorsType) => e.index !== index)

    // Reset Line Numbers and error indices (as needed) for remaining line items after a delete
    copy.LineItems.forEach((lineItem: LineItemType) => {
      if (lineItem.LineNumber > index + 1)
        lineItem.LineNumber = lineItem.LineNumber - 1
    })
    copy.errors.forEach((error: ClaimErrorsType) => {
      if (error.index > index) error.index = error.index - 1
    })

    setClaim(copy)
  }

  const count = claim?.LineItems?.length || 0
  const lineNumber = count === 1 ? 1 : claim.LineItems[index]?.LineNumber

  return (
    <Row
      className='form-row'
      gutter={16}
      wrap={false}
    >
      <Col span={1}>
        <Row className='form-row-icon-column'>
          <MinusCircleOutlined
            className='remove-item'
            onClick={handleRemove}
          />
          <strong className='display-text'>{lineNumber}</strong>
        </Row>
      </Col>
      <Col span={3}>
        <FormMaskInput
          element={claim}
          format='##/##/####'
          initialValue={claim.LineItems[index]?.ProcedureDate}
          mask='_'
          name={[index, 'ProcedureDate']}
          noFloat
          parentProperty='LineItems'
          section='procedures'
          setElement={setClaim}
          validator={validateBirthday}
        />
      </Col>
      <Col span={2}>
        <FormInput
          name={[index, 'OralCavityCode']}
          noFloat
          parentProperty='LineItems'
          element={claim}
          setElement={setClaim}
          initialValue={claim.LineItems[index]?.OralCavityCode}
        />
      </Col>
      <Col span={2}>
        <FormInput
          name={[index, 'ToothNumber']}
          noFloat
          parentProperty='LineItems'
          element={claim}
          setElement={setClaim}
          initialValue={claim.LineItems[index]?.ToothNumber}
        />
      </Col>
      <Col span={2}>
        <FormInput
          name={[index, 'ToothSurfaces']}
          noFloat
          parentProperty='LineItems'
          element={claim}
          setElement={setClaim}
          initialValue={claim.LineItems[index]?.ToothSurfaces}
        />
      </Col>
      <Col span={3}>
        <FormInput
          name={[index, 'ProcedureCode']}
          noFloat
          parentProperty='LineItems'
          element={claim}
          setElement={setClaim}
          initialValue={claim.LineItems[index]?.ProcedureCode}
          section='procedures'
          validator={validateProcedureCode}
          required
        />
      </Col>
      <Col span={2}>
        <FormInput
          name={[index, 'DiagPointer']}
          noFloat
          parentProperty='LineItems'
          element={claim}
          setElement={setClaim}
          initialValue={claim.LineItems[index]?.DiagPointer}
        />
      </Col>
      <Col span={2}>
        <FormNumber
          name={[index, 'Quantity']}
          noFloat
          min={1}
          parentProperty='LineItems'
          element={claim}
          setElement={setClaim}
          initialValue={claim.LineItems[index]?.Quantity}
          validator={validateQuantityOneToOneHundred}
        />
      </Col>
      <Col span={4}>
        <FormInput
          name={[index, 'ProcedureCodeDesc']}
          noFloat
          parentProperty='LineItems'
          element={claim}
          setElement={setClaim}
          initialValue={claim.LineItems[index]?.ProcedureCodeDesc}
        />
      </Col>
      <Col>
        <FormCurrencyInput
          element={claim}
          initialValue={claim.LineItems[index]?.ProcedureChargeAmount}
          name={[index, 'ProcedureChargeAmount']}
          noFloat
          parentProperty='LineItems'
          section='procedures'
          setElement={setClaim}
        />
      </Col>
    </Row>
  )
}

export default LineItem
