import trellisConfiguration from 'trellis:utilities/config'

import { IAuthenticatedApiModel } from '../../utilities/api'
import {
  Configuration,
  EligibilityPatient,
  EligibilityPatientsRequest,
  PatientsApiFactory,
} from './patients-client'

const trellisApi: string = trellisConfiguration.trellis_apiUrl

export const GetPatient = async (
  authentication: IAuthenticatedApiModel,
  patientId: string,
) => {
  return await PatientsApiFactory(
    new Configuration({
      basePath: trellisApi + 'services/patientsapi',
      accessToken: authentication.AccessToken,
    }),
  ).patientsGet(parseInt(authentication.CustomerId), patientId)
}

export const PostPatient = async (
  authentication: IAuthenticatedApiModel,
  eligibilityPatient: EligibilityPatient,
) => {
  return await PatientsApiFactory(
    new Configuration({
      basePath: trellisApi + 'services/patientsapi',
      accessToken: authentication.AccessToken,
    }),
  ).patientsPost(parseInt(authentication.CustomerId), eligibilityPatient)
}

export const PutPatient = async (
  authentication: IAuthenticatedApiModel,
  patientId: string,
  eligibilityPatient: EligibilityPatient,
) => {
  return await PatientsApiFactory(
    new Configuration({
      basePath: trellisApi + 'services/patientsapi',
      accessToken: authentication.AccessToken,
    }),
  ).patientsPut(
    parseInt(authentication.CustomerId),
    patientId,
    eligibilityPatient,
  )
}

export const SearchPatients = async (
  authentication: IAuthenticatedApiModel,
  req: EligibilityPatientsRequest,
) => {
  return await PatientsApiFactory(
    new Configuration({
      basePath: trellisApi + 'services/patientsapi',
      accessToken: authentication.AccessToken,
    }),
  ).patientsSearchPatients(parseInt(authentication.CustomerId), req)
}
