import { Select, Space } from 'antd'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { SearchBar, SearchStates } from 'ui'

import {
  RangesType,
  ResultsDisplayType,
  TableDataType,
} from './_carrierAttachmentTypes'

const { Option } = Select

type CarrierAttachmentSearchProps = {
  data: TableDataType[]
  filterListCallBack: (
    data: TableDataType[],
    searchSelect: string,
  ) => TableDataType[]
  loading: boolean
  onSearch: (value: string) => void
  searchSelect: string
  setResultsDisplay: Dispatch<SetStateAction<ResultsDisplayType>>
  setSearchSelect: Dispatch<SetStateAction<string>>
}

const CarrierAttachmentSearch: FC<CarrierAttachmentSearchProps> = ({
  data,
  filterListCallBack,
  loading,
  onSearch,
  searchSelect,
  setResultsDisplay,
  setSearchSelect,
}) => {
  const [searchInput, setSearchInput] = useState<SearchStates>('')

  const options: RangesType = [
    'All',
    'D0900-D0999',
    'D1500-D1599',
    'D2700-D2799',
    'D2900-D2999',
    'D3900-D3999',
    'D4200-D4299',
    'D4300-D4399',
    'D4900-D4999',
  ]

  useEffect(() => {
    if (searchInput || (!searchInput && !searchSelect)) {
      onSearch(searchInput as string)
      handleSearchInput()
    }

    if (searchSelect) filterListCallBack(data, searchSelect)
  }, [searchInput, searchSelect])

  const handleSearchInput = () => {
    if (searchSelect != null) setSearchSelect(null)
    setResultsDisplay('SearchResults')
  }

  const handleSearchSelect = (selection: string) => {
    setSearchInput('')
    setSearchSelect(selection)
    setResultsDisplay('SearchResults')
  }

  return (
    <Space
      size='middle'
      align='center'
    >
      <SearchBar
        state={searchInput}
        setState={setSearchInput}
        placeholder='Procedure Code'
        disabled={loading}
      />
      <p>or</p>
      <Select
        className='carrier-attachment-search__select'
        placeholder='Select Range'
        value={searchSelect}
        onChange={handleSearchSelect}
        disabled={loading}
      >
        {options.map((option) => (
          <Option
            key={option}
            value={option}
          >
            {option}
          </Option>
        ))}
      </Select>
    </Space>
  )
}

export default CarrierAttachmentSearch
