/* tslint:disable */
/* eslint-disable */
/**
 * Billing
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CancelRecurringPaymentRequest
 */
export interface CancelRecurringPaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof CancelRecurringPaymentRequest
     */
    'RecurringType'?: string | null;
}
/**
 * 
 * @export
 * @interface InternalPayment
 */
export interface InternalPayment {
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'PaymentSource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'PaymentStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'PaymentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'CardType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'First4Digits'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'Last4Digits'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'ExpDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'NameOnCard'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'ZipCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InternalPayment
     */
    'PaymentAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'DeviceSerialNum'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'ProcessorResponseCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'ProcessorResponseMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'ProcessorAuthCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'ProcessorHostReferenceNum'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'ProcessorTaskID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'ProcessorTransactionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'ProcessorTransactionDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InternalPayment
     */
    'ProcessorTransactionAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InternalPayment
     */
    'ProcessorProcessedAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InternalPayment
     */
    'ProcessorTotalAmount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'ProcessorAVSResponseCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'Note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'FirstDigit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'InternalPaymentGUID'?: string;
    /**
     * 
     * @type {number}
     * @memberof InternalPayment
     */
    'InternalPaymentID'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InternalPayment
     */
    'GlobalCustomerID'?: number;
    /**
     * 
     * @type {number}
     * @memberof InternalPayment
     */
    'InvoiceEntityID'?: number;
    /**
     * 
     * @type {number}
     * @memberof InternalPayment
     */
    'RecurringScheduleID'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InternalPayment
     */
    'WalletID'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InternalPayment
     */
    'Originator'?: string | null;
}
/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'InvoiceEntityId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'Tin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'FormattedTin'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'Balance'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'FormattedBalance'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'AsOfDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'PaymentPlan'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'NextInvoiceDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'ProductType'?: string | null;
    /**
     * 
     * @type {InvoiceAddress}
     * @memberof Invoice
     */
    'InvoiceAddress'?: InvoiceAddress;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'InvoiceTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'InvoiceTaxAmount'?: number;
    /**
     * 
     * @type {Array<InvoiceLineItem>}
     * @memberof Invoice
     */
    'LineItems'?: Array<InvoiceLineItem> | null;
}
/**
 * 
 * @export
 * @interface InvoiceAddress
 */
export interface InvoiceAddress {
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddress
     */
    'Address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddress
     */
    'City'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddress
     */
    'Phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddress
     */
    'PracticeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddress
     */
    'State'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddress
     */
    'Zip'?: string | null;
}
/**
 * 
 * @export
 * @interface InvoiceLineItem
 */
export interface InvoiceLineItem {
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineItem
     */
    'InvoiceNum'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineItem
     */
    'InvoiceDt'?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineItem
     */
    'AmountBilled'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineItem
     */
    'AmountTaxed'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineItem
     */
    'AmountTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineItem
     */
    'AmountPaid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineItem
     */
    'PayDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineItem
     */
    'PayMethod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineItem
     */
    'BillMsg'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineItem
     */
    'BILL_TYPE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineItem
     */
    'AvaTaxDocCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineItem
     */
    'CreditableAmount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineItem
     */
    'Address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineItem
     */
    'City'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineItem
     */
    'State'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineItem
     */
    'Zip'?: string | null;
}
/**
 * 
 * @export
 * @interface MakePaymentRequest
 */
export interface MakePaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof MakePaymentRequest
     */
    'DeviceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MakePaymentRequest
     */
    'Manifest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MakePaymentRequest
     */
    'InternalPaymentGuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof MakePaymentRequest
     */
    'CustomRecurringStartDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MakePaymentRequest
     */
    'Balance'?: number;
    /**
     * 
     * @type {string}
     * @memberof MakePaymentRequest
     */
    'CardType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MakePaymentRequest
     */
    'SaveNewCard'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MakePaymentRequest
     */
    'TsepToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MakePaymentRequest
     */
    'ReceiptEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MakePaymentRequest
     */
    'Zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MakePaymentRequest
     */
    'Note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MakePaymentRequest
     */
    'SelectedSavedCard'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MakePaymentRequest
     */
    'CardLastFour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MakePaymentRequest
     */
    'CardExpiration'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MakePaymentRequest
     */
    'CardNickname'?: string | null;
}
/**
 * 
 * @export
 * @interface PaymentViewModel
 */
export interface PaymentViewModel {
    /**
     * 
     * @type {string}
     * @memberof PaymentViewModel
     */
    'CustomRecurringStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentViewModel
     */
    'TsepBaseUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentViewModel
     */
    'DeviceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentViewModel
     */
    'Manifest'?: string | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface RecurringPayment
 */
export interface RecurringPayment {
    /**
     * 
     * @type {string}
     * @memberof RecurringPayment
     */
    'Tin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecurringPayment
     */
    'Frequency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecurringPayment
     */
    'Amount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecurringPayment
     */
    'FirstPayment'?: string;
    /**
     * 
     * @type {number}
     * @memberof RecurringPayment
     */
    'TransactionCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof RecurringPayment
     */
    'Status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecurringPayment
     */
    'Wallet'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RecurringPayment
     */
    'RecurringScheduleId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RecurringPayment
     */
    'RecurringType'?: string | null;
}
/**
 * 
 * @export
 * @interface RpReceiptContent
 */
export interface RpReceiptContent {
    /**
     * 
     * @type {WebServiceResult}
     * @memberof RpReceiptContent
     */
    'WSResult'?: WebServiceResult;
    /**
     * 
     * @type {number}
     * @memberof RpReceiptContent
     */
    'CustomerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RpReceiptContent
     */
    'ReceiptIdentifier'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RpReceiptContent
     */
    'IsRecurring'?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RpReceiptContent
     */
    'EmailParams'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {boolean}
     * @memberof RpReceiptContent
     */
    'IsGuestPayment'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof RpReceiptContent
     */
    'EmailSentMsg'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RpReceiptContent
     */
    'InternalPaymentGuid'?: string | null;
}
/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'TransactionDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'GlobalCustomerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'Tin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'CustomerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'ScheduleCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'TransactionAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'CardType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'LastFour'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'TransactionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'TransactionStatus'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'Credits'?: number | null;
}
/**
 * 
 * @export
 * @interface TsysPayResponse
 */
export interface TsysPayResponse {
    /**
     * 
     * @type {string}
     * @memberof TsysPayResponse
     */
    'ErrorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TsysPayResponse
     */
    'Success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TsysPayResponse
     */
    'SuccessMessage'?: string | null;
    /**
     * 
     * @type {WebServiceResult}
     * @memberof TsysPayResponse
     */
    'WSResult'?: WebServiceResult;
    /**
     * 
     * @type {string}
     * @memberof TsysPayResponse
     */
    'PaymentGuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof TsysPayResponse
     */
    'ReceiptIdentifier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TsysPayResponse
     */
    'InternalPaymentID'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TsysPayResponse
     */
    'GlobalCustomerID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TsysPayResponse
     */
    'IsOneTime'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TsysPayResponse
     */
    'IsPayAhead'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TsysPayResponse
     */
    'IsRecurring'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TsysPayResponse
     */
    'IncludeBalancePayAhead'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TsysPayResponse
     */
    'RecurringIncludeBalance'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TsysPayResponse
     */
    'InternalRecurringScheduleID'?: number;
    /**
     * 
     * @type {InternalPayment}
     * @memberof TsysPayResponse
     */
    'InternalPaymentInfo'?: InternalPayment;
    /**
     * 
     * @type {RpReceiptContent}
     * @memberof TsysPayResponse
     */
    'Receipt'?: RpReceiptContent;
}
/**
 * 
 * @export
 * @interface TsysPaymentInfo
 */
export interface TsysPaymentInfo {
    /**
     * 
     * @type {Array<Wallet>}
     * @memberof TsysPaymentInfo
     */
    'SavedCards'?: Array<Wallet> | null;
    /**
     * 
     * @type {PaymentViewModel}
     * @memberof TsysPaymentInfo
     */
    'PaymentViewModel'?: PaymentViewModel;
}
/**
 * 
 * @export
 * @interface Wallet
 */
export interface Wallet {
    /**
     * 
     * @type {number}
     * @memberof Wallet
     */
    'WalletID'?: number;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    'LastFour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    'Nickname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    'Expiration'?: string;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    'CardType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    'ProcessorCustomerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    'ProcessorWalletID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    'ProcessorTransactionGUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    'AddDate'?: string;
}
/**
 * 
 * @export
 * @interface WebServiceResult
 */
export interface WebServiceResult {
    /**
     * 
     * @type {number}
     * @memberof WebServiceResult
     */
    'Result'?: number;
    /**
     * 
     * @type {string}
     * @memberof WebServiceResult
     */
    'ReturnCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebServiceResult
     */
    'ReturnCodeDesc'?: string | null;
}

/**
 * BillingApi - axios parameter creator
 * @export
 */
export const BillingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {number} recurringScheduleId 
         * @param {CancelRecurringPaymentRequest} [cancelRecurringPaymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingCancelRecurring: async (customerId: number, recurringScheduleId: number, cancelRecurringPaymentRequest?: CancelRecurringPaymentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('billingCancelRecurring', 'customerId', customerId)
            // verify required parameter 'recurringScheduleId' is not null or undefined
            assertParamExists('billingCancelRecurring', 'recurringScheduleId', recurringScheduleId)
            const localVarPath = `/{customerId}/RecurringPayments/{recurringScheduleId}/Cancel`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"recurringScheduleId"}}`, encodeURIComponent(String(recurringScheduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelRecurringPaymentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingGetInvoices: async (customerId: number, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('billingGetInvoices', 'customerId', customerId)
            const localVarPath = `/{customerId}/Invoices`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingGetRecurringPayments: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('billingGetRecurringPayments', 'customerId', customerId)
            const localVarPath = `/{customerId}/RecurringPayments`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingGetTransactions: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('billingGetTransactions', 'customerId', customerId)
            const localVarPath = `/{customerId}/Transactions`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingGetTsysPaymentInfo: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('billingGetTsysPaymentInfo', 'customerId', customerId)
            const localVarPath = `/{customerId}/TsysPaymentInfo`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} invoiceEntityId 
         * @param {MakePaymentRequest} [makePaymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingMakePayment: async (customerId: number, invoiceEntityId: number, makePaymentRequest?: MakePaymentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('billingMakePayment', 'customerId', customerId)
            // verify required parameter 'invoiceEntityId' is not null or undefined
            assertParamExists('billingMakePayment', 'invoiceEntityId', invoiceEntityId)
            const localVarPath = `/{customerId}/Invoices/{invoiceEntityId}/Payment`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"invoiceEntityId"}}`, encodeURIComponent(String(invoiceEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(makePaymentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillingApi - functional programming interface
 * @export
 */
export const BillingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {number} recurringScheduleId 
         * @param {CancelRecurringPaymentRequest} [cancelRecurringPaymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingCancelRecurring(customerId: number, recurringScheduleId: number, cancelRecurringPaymentRequest?: CancelRecurringPaymentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TsysPayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingCancelRecurring(customerId, recurringScheduleId, cancelRecurringPaymentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingGetInvoices(customerId: number, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Invoice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingGetInvoices(customerId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingGetRecurringPayments(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecurringPayment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingGetRecurringPayments(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingGetTransactions(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Transaction>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingGetTransactions(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingGetTsysPaymentInfo(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TsysPaymentInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingGetTsysPaymentInfo(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} invoiceEntityId 
         * @param {MakePaymentRequest} [makePaymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingMakePayment(customerId: number, invoiceEntityId: number, makePaymentRequest?: MakePaymentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TsysPayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingMakePayment(customerId, invoiceEntityId, makePaymentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BillingApi - factory interface
 * @export
 */
export const BillingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillingApiFp(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {number} recurringScheduleId 
         * @param {CancelRecurringPaymentRequest} [cancelRecurringPaymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingCancelRecurring(customerId: number, recurringScheduleId: number, cancelRecurringPaymentRequest?: CancelRecurringPaymentRequest, options?: any): AxiosPromise<TsysPayResponse> {
            return localVarFp.billingCancelRecurring(customerId, recurringScheduleId, cancelRecurringPaymentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingGetInvoices(customerId: number, startDate?: string, endDate?: string, options?: any): AxiosPromise<Array<Invoice>> {
            return localVarFp.billingGetInvoices(customerId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingGetRecurringPayments(customerId: number, options?: any): AxiosPromise<Array<RecurringPayment>> {
            return localVarFp.billingGetRecurringPayments(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingGetTransactions(customerId: number, options?: any): AxiosPromise<Array<Transaction>> {
            return localVarFp.billingGetTransactions(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingGetTsysPaymentInfo(customerId: number, options?: any): AxiosPromise<TsysPaymentInfo> {
            return localVarFp.billingGetTsysPaymentInfo(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} invoiceEntityId 
         * @param {MakePaymentRequest} [makePaymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingMakePayment(customerId: number, invoiceEntityId: number, makePaymentRequest?: MakePaymentRequest, options?: any): AxiosPromise<TsysPayResponse> {
            return localVarFp.billingMakePayment(customerId, invoiceEntityId, makePaymentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BillingApi - object-oriented interface
 * @export
 * @class BillingApi
 * @extends {BaseAPI}
 */
export class BillingApi extends BaseAPI {
    /**
     * 
     * @param {number} customerId 
     * @param {number} recurringScheduleId 
     * @param {CancelRecurringPaymentRequest} [cancelRecurringPaymentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingCancelRecurring(customerId: number, recurringScheduleId: number, cancelRecurringPaymentRequest?: CancelRecurringPaymentRequest, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingCancelRecurring(customerId, recurringScheduleId, cancelRecurringPaymentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingGetInvoices(customerId: number, startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingGetInvoices(customerId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingGetRecurringPayments(customerId: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingGetRecurringPayments(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingGetTransactions(customerId: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingGetTransactions(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingGetTsysPaymentInfo(customerId: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingGetTsysPaymentInfo(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {number} invoiceEntityId 
     * @param {MakePaymentRequest} [makePaymentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingMakePayment(customerId: number, invoiceEntityId: number, makePaymentRequest?: MakePaymentRequest, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingMakePayment(customerId, invoiceEntityId, makePaymentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


